import React, { useState, useRef, useEffect } from 'react';
import { arrayOf, objectOf, string, bool, func } from 'prop-types';
import ReactSelect, { components } from 'react-select';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { IconProfilePlaceholder } from '~/assets/icons';
import i18next from 'i18next';
import { useTheme } from 'styled-components';

import {
  Container,
  Label,
  Error,
  OptionContainer,
  OptionAvatar,
  OptionLabel,
  ButtonAdd,
  IconPlus,
  SearchIcon,
  ArrowIcon,
} from './styles';

function Chips({ data, removeProps, hasImage }) {
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const StyledChip = withStyles({
    root: {
      backgroundColor: 'rgba(83,154,218,0.1)',
      fontSize: '1.3rem',
      width: '100%',
      minHeight: '4rem',
      borderRadius: '1rem',
      justifyContent: 'space-between',
    },

    deleteIcon: {
      color: '#539ADA',
    },
  })(Chip);

  return (
    <StyledChip
      avatar={
        hasImage ? (
          data?.avatar ? (
            <Avatar alt={data.label} src={data?.avatar} />
          ) : (
            <Avatar src={IconProfilePlaceholder} />
          )
        ) : undefined
      }
      key={1}
      label={data.label}
      onDelete={() => removeProps.onClick()}
      onMouseDown={onMouseDown}
    />
  );
}

Chips.defaultProps = {
  hasImage: true,
};

Chips.propTypes = {
  data: objectOf.isRequired,
  removeProps: objectOf.isRequired,
  hasImage: bool,
};
function Select({
  options,
  label,
  touched,
  error,
  onChange,
  value,
  margin,
  oneLineLabel,
  noImage,
  hasStore,
  hasModal,
  onStore,
  required,
}) {
  const theme = useTheme();

  const [inputValue, setInputSelect] = useState('');

  const handleInputChange = (e) => {
    setInputSelect(e);
  };

  const colourStyles = {
    control: () => ({
      label: 'control',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      borderRadius: '1rem',
      boxShadow: null,
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '6rem',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 200ms',

      minWidth: '100px',

      border: error && touched ? '1px solid red' : 'none',

      boxSizing: 'border-box',
      fontSize: '1.4rem',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#262A3A',
      '&:hover': { color: '#0E067D' },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.4rem',
      fontWeight: 400,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: isSelected ? '#fff' : '#606474',
      fontSize: '1.4rem',
      fontWeight: isSelected ? 700 : 400,
      cursor: 'pointer',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#262A3A',
      fontSize: '1.4rem',
      fontWeight: 500,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'rgba(92, 238, 167, 0.1)',
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: '1.2rem',
        color: '#262a3a',
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        gap: '0.4rem',
        marginTop: '0.2rem',
      };
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        position: 'absolute',
        height: `${value.length > 0 ? 30 : 60}px`,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
      };
    },
  };

  const isError = Boolean(touched && error);

  const convertedOptions = [...options];

  const formatOptions = (item) => {
    return (
      <OptionContainer>
        {!noImage && (
          <OptionAvatar
            src={item.avatar ? item.avatar : IconProfilePlaceholder}
            alt={item.label}
          />
        )}
        <OptionLabel>{item.label}</OptionLabel>
      </OptionContainer>
    );
  };

  const handleNew = () => {
    if (hasModal) {
      onStore(true);
    } else {
      onStore(inputValue);
    }
  };

  const createNew = () => {
    if (hasStore) {
      return (
        <ButtonAdd onClick={handleNew}>
          <IconPlus />
          {i18next.t('common.buttons.new')}
        </ButtonAdd>
      );
    }
    return i18next.t('common.noOptions');
  };

  const placeholderAdd = useRef(null);
  useEffect(() => {
    placeholderAdd.current.select.inputRef.style.minWidth = '100px';
    placeholderAdd.current.select.inputRef.placeholder = `${i18next.t(
      'common.typeIt'
    )}`;
  }, []);

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? <SearchIcon /> : <ArrowIcon />}
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <Container error={isError} label={label} margin={margin}>
      {label && (
        <Label oneLineLabel={oneLineLabel} required={required}>
          {label}
        </Label>
      )}

      <ReactSelect
        options={convertedOptions.map((item) => ({
          value: item.value,
          label: item.label,
        }))}
        ref={placeholderAdd}
        placeholder=""
        noOptionsMessage={createNew}
        maxMenuHeight={250}
        styles={colourStyles}
        formatOptionLabel={formatOptions}
        onInputChange={handleInputChange}
        onChange={(e) => onChange?.(e ? e.map((item) => item.value) : [])}
        value={options.filter((e) => value.includes(e.value))}
        components={{
          MultiValue: (props) => <Chips {...props} hasImage={!noImage} />,
          ClearIndicator: () => null,
          DropdownIndicator,
        }}
        isMulti
      />
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

export default Select;

Select.propTypes = {
  hasStore: bool,
  hasModal: bool,
  onStore: func,
  noImage: bool,
  options: arrayOf(objectOf(string)),
  label: string,
  touched: bool,
  error: string,
  onChange: func,
  value: string,
  margin: string,
  oneLineLabel: bool,
  required: bool,
};

Select.defaultProps = {
  hasStore: false,
  hasModal: false,
  onStore: null,
  noImage: false,
  options: [],
  label: '',
  touched: false,
  error: '',
  onChange: () => {},
  value: '',
  margin: '0',
  oneLineLabel: true,
  required: false,
};
