/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import Card from '~/components/Card';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import DemandActions from '~/store/ducks/demand';
import { PrimaryButton } from '~/components/Button';
import Spinner from '~/components/Spinner';
import InteractionMessages from './components/InteractionMessages';
import AttachFile from './components/AttachFile';
import SuccessModal from './components/SuccessModal';
import EvaluationModal from './components/EvaluationModal';

import Files from './components/Files';

import schema from './validationSchema';

import {
  Container,
  Header,
  Title,
  Text,
  WrapperForm,
  ReplyContainer,
  ReplyHeader,
  ReplyTitle,
} from './styles';

const ClientDemandDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [evaluationModalOpen, setEvaluationModalOpen] = useState(false);
  const { isLoading, demandDetails } = useSelector((state) => state.demands);
  const [replyAttachment, setReplyAttachment] = useState(null);

  const id = history.location.pathname.replace('/demands-history/details/', '');

  useEffect(() => {
    dispatch(DemandActions.getDemandInteractionsRequest(id));
  }, [id]);

  useEffect(() => {
    if (!demandDetails?.id) {
      dispatch(DemandActions.getDemandDetailsRequest(id));
      dispatch(DemandActions.getDemandInteractionsRequest(id));
    }
  }, [demandDetails]);

  const closeDemand = () => {
    dispatch(DemandActions.closeDemandRequest(id));
    setSuccessModalOpen(true);
  };

  const submit = (values) => {
    dispatch(
      DemandActions.createNewInteractionRequest(
        id,
        values.description,
        replyAttachment
      )
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>{`${i18next.t('callOpening.demandDetails.title')} ${
                  demandDetails.protocol
                }`}</Title>
              </Header>
            </Grid>

            <Grid container spacing={4}>
              <WrapperForm>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Header>
                      <Text>
                        {i18next.t(
                          'callOpening.demandDetails.demandInformation'
                        )}
                      </Text>
                      <Grid item xs={2}>
                        <PrimaryButton
                          label={i18next.t(
                            'callOpening.demandDetails.closeDemandButton'
                          )}
                          onClick={closeDemand}
                          padding="0 0"
                        />
                      </Grid>
                    </Header>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextInput
                      label={i18next.t('common.fields.name')}
                      placeholder="João Santos"
                      name="name"
                      value={demandDetails?.client?.name || ''}
                      editable={false}
                      editableui
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextInput
                      label={i18next.t('callOpening.demandDetails.cpfLabel')}
                      placeholder="Ex.: CPF/CNPJ"
                      mask={
                        demandDetails?.client?.cpf
                          ? demandDetails?.client?.cpf.length < 15
                            ? '999.999.999-99*'
                            : '99.999.999/9999-99'
                          : '999.999.999-99*'
                      }
                      name="cpfCnpj"
                      value={demandDetails?.client?.cpf || ''}
                      editable={false}
                      editableui
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextInput
                      label={i18next.t('common.fields.phone')}
                      placeholder="Ex.: (21) 99999-9999"
                      mask={
                        demandDetails?.client?.phone
                          ? demandDetails?.client?.phone.length < 15
                            ? '(99) 9999-9999*'
                            : '(99) 99999-9999'
                          : '(99) 99999-9999'
                      }
                      name="phone"
                      value={demandDetails?.client?.phone || ''}
                      editable={false}
                      editableui
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextInput
                      label={i18next.t('common.fields.email')}
                      placeholder="Ex.: meuemail@email.com.br"
                      name="email"
                      value={demandDetails?.client?.email || ''}
                      editable={false}
                      editableui
                    />
                  </Grid>
                  <Grid item container xs={12} sm={8}>
                    <Grid xs={12}>
                      <TextInput
                        label={i18next.t(
                          'callOpening.demandDetails.subjectLabel'
                        )}
                        placeholder="Insira o assunto da sua mensagem"
                        name="subject"
                        value={demandDetails?.subject || ''}
                        editable={false}
                        editableui
                      />
                    </Grid>
                    <Grid xs={12}>
                      <TextArea
                        label={i18next.t(
                          'callOpening.demandDetails.descriptionLabel'
                        )}
                        placeholder="Insira sua mensagem aqui"
                        name="description"
                        value={demandDetails?.description || ''}
                        editable={false}
                        editableui
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm>
                    <Files />
                  </Grid>
                </Grid>
              </WrapperForm>
            </Grid>

            <ReplyContainer>
              <Formik
                initialValues={{
                  description: '',
                }}
                onSubmit={submit}
                validationSchema={schema}
              >
                {({ values, touched, errors, handleChange, handleSubmit }) => (
                  <Card padding="4rem 3rem">
                    <Grid container xs gap={4}>
                      <Grid item xs={12} md={8} lg>
                        <Grid item xs={11}>
                          <ReplyHeader>
                            <ReplyTitle>Responder</ReplyTitle>
                          </ReplyHeader>
                        </Grid>

                        <Grid item xs={11}>
                          <TextInput
                            label="Responder ao atendente"
                            placeholder="Insira sua mensagem aqui"
                            name="description"
                            touched={touched.description}
                            error={errors.description}
                            onChange={handleChange}
                            value={values.description}
                            style={{ height: '11.7rem' }}
                            textArea
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={4}>
                        <AttachFile setAttachment={setReplyAttachment} />
                      </Grid>

                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        style={{ marginTop: '3rem' }}
                      >
                        <PrimaryButton
                          label={i18next.t(
                            'demandManagement.demandDetails.answer'
                          )}
                          padding="0 5.8rem"
                          width="null"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                    <InteractionMessages />
                  </Card>
                )}
              </Formik>
            </ReplyContainer>
          </Grid>
        </Container>
      )}
      <SuccessModal
        open={!!successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        setEvaluationModalOpen={setEvaluationModalOpen}
      />
      <EvaluationModal
        open={!!evaluationModalOpen}
        onClose={() => setEvaluationModalOpen(false)}
        name={demandDetails?.client?.name}
        protocol={demandDetails?.protocol}
        id={demandDetails?.id}
      />
    </>
  );
};

export default ClientDemandDetails;
