import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  getIventoriesRequest: ['page', 'limit', 'search'],
  getIventoriesSuccess: ['iventories'],
  getIventoriesFailure: [''],

  getIventoryDetailsRequest: ['id'],
  getIventoryDetailsSuccess: ['iventoryDetails'],
  getIventoryDetailsFailure: [''],

  deleteIventoryRequest: ['id'],
  deleteIventorySuccess: ['id'],
  deleteIventoryFailure: [''],

  getPeopleResponsibleEnumRequest: ['companyId'],
  getPeopleResponsibleEnumSuccess: ['peopleResponsibleEnum'],
  getPeopleResponsibleEnumFailure: [''],

  getDepartmentEnumRequest: ['companyId'],
  getDepartmentEnumSuccess: ['departmentEnum'],
  getDepartmentEnumFailure: [''],

  getBusinessCriticalityEnumRequest: [''],
  getBusinessCriticalityEnumSuccess: ['businessCriticalityEnum'],
  getBusinessCriticalityEnumFailure: [''],

  createDepartmentRequest: ['name', 'companyId', 'updateDepartments'],
  createDepartmentSuccess: [''],
  createDepartmentFailure: [''],

  createCollectionPurposeRequest: ['data', 'onSuccess'],
  createCollectionPurposeSuccess: ['purpose'],
  createCollectionPurposeFailure: [''],

  getCollectionPurposeRequest: ['page', 'limit', 'search'],
  getCollectionPurposeSuccess: ['collectionPurposes'],
  getCollectionPurposeFailure: [''],

  getCollectionPurposeEnumRequest: [],
  getCollectionPurposeEnumSuccess: ['purposesEnum'],
  getCollectionPurposeEnumFailure: [''],

  createCollectionFormsRequest: ['data', 'onSuccess'],
  createCollectionFormsSuccess: ['form'],
  createCollectionFormsFailure: [''],

  getCollectionFormsRequest: ['page', 'limit', 'search'],
  getCollectionFormsSuccess: ['collectionForms'],
  getCollectionFormsFailure: [''],

  getCollectionFormsEnumRequest: [''],
  getCollectionFormsEnumSuccess: ['formsEnum'],
  getCollectionFormsEnumFailure: [''],

  createCategoryOfDataCollectedRequest: ['data', 'onSuccess'],
  createCategoryOfDataCollectedSuccess: ['category'],
  createCategoryOfDataCollectedFailure: [''],

  getCategoryOfDataCollectedRequest: ['page', 'limit', 'search'],
  getCategoryOfDataCollectedSuccess: ['categoriesOfDataCollected'],
  getCategoryOfDataCollectedFailure: [''],

  getCategoryOfDataCollectedEnumRequest: ['page', 'limit', 'search'],
  getCategoryOfDataCollectedEnumSuccess: ['categoriesCollectedEnum'],
  getCategoryOfDataCollectedEnumFailure: [''],

  createTransparencyToTheHolderRequest: ['data', 'onSuccess'],
  createTransparencyToTheHolderSuccess: ['holder'],
  createTransparencyToTheHolderFailure: [''],

  getTransparencyToTheHolderRequest: ['page', 'limit', 'search'],
  getTransparencyToTheHolderSuccess: ['transparencyHolder'],
  getTransparencyToTheHolderFailure: [''],

  getTransparencyToTheHolderEnumRequest: [''],
  getTransparencyToTheHolderEnumSuccess: ['transparencyHolderEnum'],
  getTransparencyToTheHolderEnumFailure: [''],

  createCollectedDataRequest: ['data', 'onSuccess'],
  createCollectedDataSuccess: ['collectedData'],
  createCollectedDataFailure: [''],

  getCollectedDataRequest: ['page', 'limit', 'search'],
  getCollectedDataSuccess: ['collectedDatas'],
  getCollectedDataFailure: [''],

  getCollectedDataEnumRequest: [''],
  getCollectedDataEnumSuccess: ['collectedDataEnum'],
  getCollectedDataEnumFailure: [''],

  createLegalBasisAssessmentRequest: ['data', 'onSuccess'],
  createLegalBasisAssessmentSuccess: ['assessment'],
  createLegalBasisAssessmentFailure: [''],

  getLegalBasisAssessmentRequest: ['page', 'limit', 'search'],
  getLegalBasisAssessmentSuccess: ['legalBasisAssessments'],
  getLegalBasisAssessmentFailure: [''],

  getLegalBasisAssessmentEnumRequest: [''],
  getLegalBasisAssessmentEnumSuccess: ['legalBasisAssessmentEnum'],
  getLegalBasisAssessmentEnumFailure: [''],

  createProcessUpdateFrequencyRequest: ['data', 'onSuccess'],
  createProcessUpdateFrequencySuccess: ['frequency'],
  createProcessUpdateFrequencyFailure: [''],

  getProcessUpdateFrequenciesRequest: ['page', 'limit', 'search'],
  getProcessUpdateFrequenciesSuccess: ['frequencies'],
  getProcessUpdateFrequenciesFailure: [''],

  getProcessUpdateFrequenciesEnumRequest: [''],
  getProcessUpdateFrequenciesEnumSuccess: ['frequenciesEnum'],
  getProcessUpdateFrequenciesEnumFailure: [''],

  createInOutTransmissionRequest: ['data', 'onSuccess'],
  createInOutTransmissionSuccess: ['inOutTransmission'],
  createInOutTransmissionFailure: [''],

  getInOutTransmissionsRequest: ['page', 'limit', 'search'],
  getInOutTransmissionsSuccess: ['inOutTransmissions'],
  getInOutTransmissionsFailure: [''],

  getInOutTransmissionsEnumRequest: [''],
  getInOutTransmissionsEnumSuccess: ['inOutTransmissionsEnum'],
  getInOutTransmissionsEnumFailure: [''],

  createStorageFormRequest: ['data', 'onSuccess'],
  createStorageFormSuccess: ['storageForm'],
  createStorageFormFailure: [''],

  getStorageFormsRequest: ['page', 'limit', 'search'],
  getStorageFormsSuccess: ['storageForms'],
  getStorageFormsFailure: [''],

  getStorageFormsEnumRequest: [''],
  getStorageFormsEnumSuccess: ['storageFormsEnum'],
  getStorageFormsEnumFailure: [''],

  createIventoryRequest: ['data'],
  createIventorySuccess: [''],
  createIventoryFailure: [''],
});

export const IventoryTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  isGetLoading: false,
  error: false,
  peopleResponsibleEnum: [],
  departmentEnum: [],
  businessCriticalityEnum: [],
  iventoryOptions: {},
  iventories: {},
  purposesEnum: [],
  formsEnum: [],
  iventoryDetails: {},
  categoriesCollectedEnum: [],
  transparencyHolderEnum: [],
  collectedDataEnum: [],
  legalBasisAssessmentEnum: [],
  frequenciesEnum: [],
  inOutTransmissionsEnum: [],
  storageFormsEnum: [],
});

/* Reducers */

const createIventoryRequest = (state) => state.merge({ isLoading: true });

const createIventorySuccess = (state) => {
  return state.merge({
    isLoading: false,
    error: false,
  });
};

const createIventoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteIventoryRequest = (state) => state.merge({ isLoading: true });

const deleteIventorySuccess = (state, { id }) => {
  const fitred = [...state.iventories.rows.filter((item) => item.id !== id)];
  const countFormated = state.iventories.count - 1;
  return state.merge({
    isLoading: false,
    error: false,
    iventories: {
      ...state.businessUnits,
      rows: fitred,
      count: countFormated,
    },
  });
};

const deleteIventoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getIventoriesRequest = (state) => state.merge({ isLoading: true });
const getIventoriesSuccess = (state, { iventories }) =>
  state.merge({
    isLoading: false,
    error: false,
    iventories,
  });
const getIventoriesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    iventories: [],
  });

const getIventoryDetailsRequest = (state) => state.merge({ isLoading: true });
const getIventoryDetailsSuccess = (state, { iventoryDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    iventoryDetails,
  });
const getIventoryDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    iventoryDetails: {},
  });

const getPeopleResponsibleEnumRequest = (state) =>
  state.merge({ isLoading: true });
const getPeopleResponsibleEnumSuccess = (state, { peopleResponsibleEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    peopleResponsibleEnum,
  });
const getPeopleResponsibleEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    peopleResponsibleEnum: [],
  });

const getBusinessCriticalityEnumRequest = (state) =>
  state.merge({ isLoading: true });

const getBusinessCriticalityEnumSuccess = (
  state,
  { businessCriticalityEnum }
) =>
  state.merge({
    isLoading: false,
    error: false,
    businessCriticalityEnum,
  });

const getBusinessCriticalityEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    businessCriticalityEnum: [],
  });

const createDepartmentRequest = (state) => state.merge({ isLoading: true });
const createDepartmentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createDepartmentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDepartmentEnumRequest = (state) => state.merge({ isLoading: true });

const getDepartmentEnumSuccess = (state, { departmentEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    departmentEnum,
  });
const getDepartmentEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    departmentEnum: [],
  });

const createCollectionPurposeRequest = (state) =>
  state.merge({ isLoading: true });

const createCollectionPurposeSuccess = (state, { purpose }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [purpose, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createCollectionPurposeFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCollectionPurposeEnumRequest = (state) =>
  state.merge({ isGetLoading: true });
const getCollectionPurposeEnumSuccess = (state, { purposesEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    purposesEnum,
  });
const getCollectionPurposeEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    purposesEnum: [],
  });

const getCollectionPurposeRequest = (state) =>
  state.merge({ isGetLoading: true });
const getCollectionPurposeSuccess = (state, { collectionPurposes }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: collectionPurposes,
  });
const getCollectionPurposeFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const createCollectionFormsRequest = (state) =>
  state.merge({ isLoading: true });

const createCollectionFormsSuccess = (state, { form }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [form, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createCollectionFormsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCollectionFormsRequest = (state) =>
  state.merge({ isGetLoading: true });
const getCollectionFormsSuccess = (state, { collectionForms }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: collectionForms,
  });
const getCollectionFormsFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getCollectionFormsEnumRequest = (state) =>
  state.merge({ isGetLoading: true });
const getCollectionFormsEnumSuccess = (state, { formsEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    formsEnum,
  });
const getCollectionFormsEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    formsEnum: [],
  });

const createCategoryOfDataCollectedRequest = (state) =>
  state.merge({ isLoading: true });

const createCategoryOfDataCollectedSuccess = (state, { category }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [category, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createCategoryOfDataCollectedFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCategoryOfDataCollectedRequest = (state) =>
  state.merge({ isGetLoading: true });

const getCategoryOfDataCollectedSuccess = (
  state,
  { categoriesOfDataCollected }
) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: categoriesOfDataCollected,
  });
const getCategoryOfDataCollectedFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getCategoryOfDataCollectedEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getCategoryOfDataCollectedEnumSuccess = (
  state,
  { categoriesCollectedEnum }
) =>
  state.merge({
    isGetLoading: false,
    error: false,
    categoriesCollectedEnum,
  });
const getCategoryOfDataCollectedEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    categoriesCollectedEnum: [],
  });

const createTransparencyToTheHolderRequest = (state) =>
  state.merge({ isLoading: true });

const createTransparencyToTheHolderSuccess = (state, { holder }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [holder, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createTransparencyToTheHolderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getTransparencyToTheHolderRequest = (state) =>
  state.merge({ isGetLoading: true });

const getTransparencyToTheHolderSuccess = (state, { transparencyHolder }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: transparencyHolder,
  });
const getTransparencyToTheHolderFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getTransparencyToTheHolderEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getTransparencyToTheHolderEnumSuccess = (
  state,
  { transparencyHolderEnum }
) =>
  state.merge({
    isGetLoading: false,
    error: false,
    transparencyHolderEnum,
  });
const getTransparencyToTheHolderEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    transparencyHolderEnum: [],
  });

const createCollectedDataRequest = (state) => state.merge({ isLoading: true });

const createCollectedDataSuccess = (state, { collectedData }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [collectedData, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createCollectedDataFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCollectedDataRequest = (state) => state.merge({ isGetLoading: true });

const getCollectedDataSuccess = (state, { collectedDatas }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: collectedDatas,
  });
const getCollectedDataFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getCollectedDataEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getCollectedDataEnumSuccess = (state, { collectedDataEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    collectedDataEnum,
  });
const getCollectedDataEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    collectedDataEnum: [],
  });

const createLegalBasisAssessmentRequest = (state) =>
  state.merge({ isLoading: true });

const createLegalBasisAssessmentSuccess = (state, { assessment }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [assessment, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createLegalBasisAssessmentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getLegalBasisAssessmentRequest = (state) =>
  state.merge({ isGetLoading: true });

const getLegalBasisAssessmentSuccess = (state, { legalBasisAssessments }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: legalBasisAssessments,
  });
const getLegalBasisAssessmentFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getLegalBasisAssessmentEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getLegalBasisAssessmentEnumSuccess = (
  state,
  { legalBasisAssessmentEnum }
) =>
  state.merge({
    isGetLoading: false,
    error: false,
    legalBasisAssessmentEnum,
  });
const getLegalBasisAssessmentEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    legalBasisAssessmentEnum: [],
  });

const createProcessUpdateFrequencyRequest = (state) =>
  state.merge({ isLoading: true });

const createProcessUpdateFrequencySuccess = (state, { frequency }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [frequency, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createProcessUpdateFrequencyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getProcessUpdateFrequenciesRequest = (state) =>
  state.merge({ isGetLoading: true });

const getProcessUpdateFrequenciesSuccess = (state, { frequencies }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: frequencies,
  });
const getProcessUpdateFrequenciesFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getProcessUpdateFrequenciesEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getProcessUpdateFrequenciesEnumSuccess = (state, { frequenciesEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    frequenciesEnum,
  });
const getProcessUpdateFrequenciesEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    frequenciesEnum: [],
  });

const createInOutTransmissionRequest = (state) =>
  state.merge({ isLoading: true });

const createInOutTransmissionSuccess = (state, { inOutTransmission }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [inOutTransmission, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createInOutTransmissionFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getInOutTransmissionsRequest = (state) =>
  state.merge({ isGetLoading: true });

const getInOutTransmissionsSuccess = (state, { inOutTransmissions }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: inOutTransmissions,
  });
const getInOutTransmissionsFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

const getInOutTransmissionsEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getInOutTransmissionsEnumSuccess = (state, { inOutTransmissionsEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    inOutTransmissionsEnum,
  });
const getInOutTransmissionsEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    inOutTransmissionsEnum: [],
  });

const createStorageFormRequest = (state) => state.merge({ isLoading: true });

const createStorageFormSuccess = (state, { storageForm }) => {
  return state.merge({
    isLoading: false,
    error: false,
    iventoryOptions: {
      ...state.businessUnits,
      rows: [storageForm, ...state.iventoryOptions.rows],
      count: state.iventoryOptions.count + 1,
    },
  });
};

const createStorageFormFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getStorageFormsEnumRequest = (state) =>
  state.merge({ isGetLoading: true });

const getStorageFormsEnumSuccess = (state, { storageFormsEnum }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    storageFormsEnum,
  });
const getStorageFormsEnumFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    storageFormsEnum: [],
  });

const getStorageFormsRequest = (state) => state.merge({ isGetLoading: true });

const getStorageFormsSuccess = (state, { storageForms }) =>
  state.merge({
    isGetLoading: false,
    error: false,
    iventoryOptions: storageForms,
  });
const getStorageFormsFailure = (state) =>
  state.merge({
    isGetLoading: false,
    error: true,
    iventoryOptions: [],
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_IVENTORY_REQUEST]: createIventoryRequest,
  [Types.CREATE_IVENTORY_SUCCESS]: createIventorySuccess,
  [Types.CREATE_IVENTORY_FAILURE]: createIventoryFailure,

  [Types.DELETE_IVENTORY_REQUEST]: deleteIventoryRequest,
  [Types.DELETE_IVENTORY_SUCCESS]: deleteIventorySuccess,
  [Types.DELETE_IVENTORY_FAILURE]: deleteIventoryFailure,

  [Types.GET_IVENTORIES_REQUEST]: getIventoriesRequest,
  [Types.GET_IVENTORIES_SUCCESS]: getIventoriesSuccess,
  [Types.GET_IVENTORIES_FAILURE]: getIventoriesFailure,

  [Types.GET_IVENTORY_DETAILS_REQUEST]: getIventoryDetailsRequest,
  [Types.GET_IVENTORY_DETAILS_SUCCESS]: getIventoryDetailsSuccess,
  [Types.GET_IVENTORY_DETAILS_FAILURE]: getIventoryDetailsFailure,

  [Types.GET_PEOPLE_RESPONSIBLE_ENUM_REQUEST]: getPeopleResponsibleEnumRequest,
  [Types.GET_PEOPLE_RESPONSIBLE_ENUM_SUCCESS]: getPeopleResponsibleEnumSuccess,
  [Types.GET_PEOPLE_RESPONSIBLE_ENUM_FAILURE]: getPeopleResponsibleEnumFailure,

  [Types.GET_DEPARTMENT_ENUM_REQUEST]: getDepartmentEnumRequest,
  [Types.GET_DEPARTMENT_ENUM_SUCCESS]: getDepartmentEnumSuccess,
  [Types.GET_DEPARTMENT_ENUM_FAILURE]: getDepartmentEnumFailure,

  [Types.CREATE_DEPARTMENT_REQUEST]: createDepartmentRequest,
  [Types.CREATE_DEPARTMENT_SUCCESS]: createDepartmentSuccess,
  [Types.CREATE_DEPARTMENT_FAILURE]: createDepartmentFailure,

  [Types.GET_BUSINESS_CRITICALITY_ENUM_REQUEST]:
    getBusinessCriticalityEnumRequest,
  [Types.GET_BUSINESS_CRITICALITY_ENUM_SUCCESS]:
    getBusinessCriticalityEnumSuccess,
  [Types.GET_BUSINESS_CRITICALITY_ENUM_FAILURE]:
    getBusinessCriticalityEnumFailure,

  [Types.CREATE_COLLECTION_PURPOSE_REQUEST]: createCollectionPurposeRequest,
  [Types.CREATE_COLLECTION_PURPOSE_SUCCESS]: createCollectionPurposeSuccess,
  [Types.CREATE_COLLECTION_PURPOSE_FAILURE]: createCollectionPurposeFailure,

  [Types.GET_COLLECTION_PURPOSE_REQUEST]: getCollectionPurposeRequest,
  [Types.GET_COLLECTION_PURPOSE_SUCCESS]: getCollectionPurposeSuccess,
  [Types.GET_COLLECTION_PURPOSE_FAILURE]: getCollectionPurposeFailure,

  [Types.GET_COLLECTION_PURPOSE_ENUM_REQUEST]: getCollectionPurposeEnumRequest,
  [Types.GET_COLLECTION_PURPOSE_ENUM_SUCCESS]: getCollectionPurposeEnumSuccess,
  [Types.GET_COLLECTION_PURPOSE_ENUM_FAILURE]: getCollectionPurposeEnumFailure,

  [Types.CREATE_COLLECTION_FORMS_REQUEST]: createCollectionFormsRequest,
  [Types.CREATE_COLLECTION_FORMS_SUCCESS]: createCollectionFormsSuccess,
  [Types.CREATE_COLLECTION_FORMS_FAILURE]: createCollectionFormsFailure,

  [Types.GET_COLLECTION_FORMS_REQUEST]: getCollectionFormsRequest,
  [Types.GET_COLLECTION_FORMS_SUCCESS]: getCollectionFormsSuccess,
  [Types.GET_COLLECTION_FORMS_FAILURE]: getCollectionFormsFailure,

  [Types.GET_COLLECTION_FORMS_ENUM_REQUEST]: getCollectionFormsEnumRequest,
  [Types.GET_COLLECTION_FORMS_ENUM_SUCCESS]: getCollectionFormsEnumSuccess,
  [Types.GET_COLLECTION_FORMS_ENUM_FAILURE]: getCollectionFormsEnumFailure,

  [Types.CREATE_CATEGORY_OF_DATA_COLLECTED_REQUEST]:
    createCategoryOfDataCollectedRequest,
  [Types.CREATE_CATEGORY_OF_DATA_COLLECTED_SUCCESS]:
    createCategoryOfDataCollectedSuccess,
  [Types.CREATE_CATEGORY_OF_DATA_COLLECTED_FAILURE]:
    createCategoryOfDataCollectedFailure,

  [Types.GET_CATEGORY_OF_DATA_COLLECTED_REQUEST]:
    getCategoryOfDataCollectedRequest,
  [Types.GET_CATEGORY_OF_DATA_COLLECTED_SUCCESS]:
    getCategoryOfDataCollectedSuccess,
  [Types.GET_CATEGORY_OF_DATA_COLLECTED_FAILURE]:
    getCategoryOfDataCollectedFailure,

  [Types.GET_CATEGORY_OF_DATA_COLLECTED_ENUM_REQUEST]:
    getCategoryOfDataCollectedEnumRequest,
  [Types.GET_CATEGORY_OF_DATA_COLLECTED_ENUM_SUCCESS]:
    getCategoryOfDataCollectedEnumSuccess,
  [Types.GET_CATEGORY_OF_DATA_COLLECTED_ENUM_FAILURE]:
    getCategoryOfDataCollectedEnumFailure,

  [Types.CREATE_TRANSPARENCY_TO_THE_HOLDER_REQUEST]:
    createTransparencyToTheHolderRequest,
  [Types.CREATE_TRANSPARENCY_TO_THE_HOLDER_SUCCESS]:
    createTransparencyToTheHolderSuccess,
  [Types.CREATE_TRANSPARENCY_TO_THE_HOLDER_FAILURE]:
    createTransparencyToTheHolderFailure,

  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_REQUEST]:
    getTransparencyToTheHolderRequest,
  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_SUCCESS]:
    getTransparencyToTheHolderSuccess,
  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_FAILURE]:
    getTransparencyToTheHolderFailure,

  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_ENUM_REQUEST]:
    getTransparencyToTheHolderEnumRequest,
  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_ENUM_SUCCESS]:
    getTransparencyToTheHolderEnumSuccess,
  [Types.GET_TRANSPARENCY_TO_THE_HOLDER_ENUM_FAILURE]:
    getTransparencyToTheHolderEnumFailure,

  [Types.CREATE_COLLECTED_DATA_REQUEST]: createCollectedDataRequest,
  [Types.CREATE_COLLECTED_DATA_SUCCESS]: createCollectedDataSuccess,
  [Types.CREATE_COLLECTED_DATA_FAILURE]: createCollectedDataFailure,

  [Types.GET_COLLECTED_DATA_REQUEST]: getCollectedDataRequest,
  [Types.GET_COLLECTED_DATA_SUCCESS]: getCollectedDataSuccess,
  [Types.GET_COLLECTED_DATA_FAILURE]: getCollectedDataFailure,

  [Types.GET_COLLECTED_DATA_ENUM_REQUEST]: getCollectedDataEnumRequest,
  [Types.GET_COLLECTED_DATA_ENUM_SUCCESS]: getCollectedDataEnumSuccess,
  [Types.GET_COLLECTED_DATA_ENUM_FAILURE]: getCollectedDataEnumFailure,

  [Types.CREATE_LEGAL_BASIS_ASSESSMENT_REQUEST]:
    createLegalBasisAssessmentRequest,
  [Types.CREATE_LEGAL_BASIS_ASSESSMENT_SUCCESS]:
    createLegalBasisAssessmentSuccess,
  [Types.CREATE_LEGAL_BASIS_ASSESSMENT_FAILURE]:
    createLegalBasisAssessmentFailure,

  [Types.GET_LEGAL_BASIS_ASSESSMENT_REQUEST]: getLegalBasisAssessmentRequest,
  [Types.GET_LEGAL_BASIS_ASSESSMENT_SUCCESS]: getLegalBasisAssessmentSuccess,
  [Types.GET_LEGAL_BASIS_ASSESSMENT_FAILURE]: getLegalBasisAssessmentFailure,

  [Types.GET_LEGAL_BASIS_ASSESSMENT_ENUM_REQUEST]:
    getLegalBasisAssessmentEnumRequest,
  [Types.GET_LEGAL_BASIS_ASSESSMENT_ENUM_SUCCESS]:
    getLegalBasisAssessmentEnumSuccess,
  [Types.GET_LEGAL_BASIS_ASSESSMENT_ENUM_FAILURE]:
    getLegalBasisAssessmentEnumFailure,

  [Types.CREATE_PROCESS_UPDATE_FREQUENCY_REQUEST]:
    createProcessUpdateFrequencyRequest,
  [Types.CREATE_PROCESS_UPDATE_FREQUENCY_SUCCESS]:
    createProcessUpdateFrequencySuccess,
  [Types.CREATE_PROCESS_UPDATE_FREQUENCY_FAILURE]:
    createProcessUpdateFrequencyFailure,

  [Types.GET_PROCESS_UPDATE_FREQUENCIES_REQUEST]:
    getProcessUpdateFrequenciesRequest,
  [Types.GET_PROCESS_UPDATE_FREQUENCIES_SUCCESS]:
    getProcessUpdateFrequenciesSuccess,
  [Types.GET_PROCESS_UPDATE_FREQUENCIES_FAILURE]:
    getProcessUpdateFrequenciesFailure,

  [Types.GET_PROCESS_UPDATE_FREQUENCIES_ENUM_REQUEST]:
    getProcessUpdateFrequenciesEnumRequest,
  [Types.GET_PROCESS_UPDATE_FREQUENCIES_ENUM_SUCCESS]:
    getProcessUpdateFrequenciesEnumSuccess,
  [Types.GET_PROCESS_UPDATE_FREQUENCIES_ENUM_FAILURE]:
    getProcessUpdateFrequenciesEnumFailure,

  [Types.CREATE_IN_OUT_TRANSMISSION_REQUEST]: createInOutTransmissionRequest,
  [Types.CREATE_IN_OUT_TRANSMISSION_SUCCESS]: createInOutTransmissionSuccess,
  [Types.CREATE_IN_OUT_TRANSMISSION_FAILURE]: createInOutTransmissionFailure,

  [Types.GET_IN_OUT_TRANSMISSIONS_REQUEST]: getInOutTransmissionsRequest,
  [Types.GET_IN_OUT_TRANSMISSIONS_SUCCESS]: getInOutTransmissionsSuccess,
  [Types.GET_IN_OUT_TRANSMISSIONS_FAILURE]: getInOutTransmissionsFailure,

  [Types.GET_IN_OUT_TRANSMISSIONS_ENUM_REQUEST]:
    getInOutTransmissionsEnumRequest,
  [Types.GET_IN_OUT_TRANSMISSIONS_ENUM_SUCCESS]:
    getInOutTransmissionsEnumSuccess,
  [Types.GET_IN_OUT_TRANSMISSIONS_ENUM_FAILURE]:
    getInOutTransmissionsEnumFailure,

  [Types.CREATE_STORAGE_FORM_REQUEST]: createStorageFormRequest,
  [Types.CREATE_STORAGE_FORM_SUCCESS]: createStorageFormSuccess,
  [Types.CREATE_STORAGE_FORM_FAILURE]: createStorageFormFailure,

  [Types.GET_STORAGE_FORMS_REQUEST]: getStorageFormsRequest,
  [Types.GET_STORAGE_FORMS_SUCCESS]: getStorageFormsSuccess,
  [Types.GET_STORAGE_FORMS_FAILURE]: getStorageFormsFailure,

  [Types.GET_STORAGE_FORMS_ENUM_REQUEST]: getStorageFormsEnumRequest,
  [Types.GET_STORAGE_FORMS_ENUM_SUCCESS]: getStorageFormsEnumSuccess,
  [Types.GET_STORAGE_FORMS_ENUM_FAILURE]: getStorageFormsEnumFailure,
});
