import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import TextInput from '~/components/TextInput';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import { PrimaryButton } from '~/components/Button';

import BusinessUnitActions from '~/store/ducks/businessUnits';
import { Container, ButtonWrapper, Button } from './styles';

const CreateNewLocality = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const { businessUnitDetails } = useSelector((state) => state.businessUnits);
  const [dataNew, setDataNew] = useState({
    title: businessUnitDetails?.title,
  });

  const reloadList = () => {
    dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
  };

  const submit = () => {
    const data = {
      documents: [],
      deletedDocuments: [],
      companyId: userActive?.activeCompany?.id,
      actives: [],
      deletedActives: [],
      accessControl: [],
      location: {
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        cep: '',
        city: '',
        state: '',
      },
      sidebarInfo: {
        title: dataNew?.title,
        primaryFunction: '',
        responsible: [],
      },
      currentUserId: userActive.user.id,
    };

    dispatch(BusinessUnitActions.createBusinessUnitsRequest(data, reloadList));
    onClose();
  };

  useEffect(() => {
    dispatch(BusinessUnitActions.setSidebarMenuDataRequest(dataNew));
  }, [dataNew]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t('businessUnitAsset.sidebar.title')}
      noPadding
    >
      <Container>
        <TextInput
          label={i18next.t('businessUnitAsset.sidebar.titleLabel')}
          margin="0 0 1.8rem"
          required
          value={dataNew.title}
          onChange={(e) => setDataNew({ ...dataNew, title: e.target.value })}
        />
        <ButtonWrapper>
          <Button>
            <PrimaryButton
              label={i18next.t('common.buttons.save')}
              onClick={() => submit()}
            />
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default CreateNewLocality;

CreateNewLocality.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
