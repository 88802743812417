import React, { useState } from 'react';

import i18next from 'i18next';
import ProgressBar from '~/components/ProgressBar';
import history from '~/routes/history';

// import LanguageSelector from '~/components/LanguageSelector';

import Steps from './Steps';

import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  Title,
  FormWrapper,
  WrapperProgress,
  GoBackWrapper,
} from './styles';

function Initial() {
  const [step, setStep] = useState(1);

  return (
    <>
      <Container>
        <BG />
        <Logo />
        <Content>
          <TitleWrapper>
            <Title>{i18next.t('common.signUp.title1')}</Title>
            <Title>{i18next.t('common.signUp.title2')}</Title>
            <Title>{i18next.t('common.signUp.title3')}</Title>
          </TitleWrapper>

          <FormWrapper>
            {step > 2 && (
              <GoBackWrapper onClick={() => setStep((e) => e - 1)}>
                <span>{'<'}</span>
                <span>{i18next.t('common.buttons.goBack')}</span>
              </GoBackWrapper>
            )}

            <WrapperProgress>
              <ProgressBar
                label={`${i18next.t(
                  'common.signUp.stepLabel1'
                )} ${step} ${i18next.t('common.signUp.stepLabel2')} 3`}
                actualValue={step}
                total={3}
                initialColor="#539ADA"
                finalColor="#539ADA"
              />
            </WrapperProgress>

            <Steps
              step={step}
              onNext={() => {
                if (step === 3) {
                  history.push('/');
                } else {
                  setStep((e) => e + 1);
                }
              }}
            />
          </FormWrapper>
        </Content>
      </Container>
      {/* <LanguageSelector /> */}
    </>
  );
}

export default Initial;
