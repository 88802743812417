import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { IconDocument, IconDownload } from '~/assets/svgs';

export const Container = styled.div`
  max-height: 30rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  line-height: normal;
`;

export const WrapperItems = styled(PerfectScrollbar)`
  width: 100%;
  height: 18rem;
`;

export const WrapperItem = styled.div`
  border: 1px solid rgba(198, 204, 210, 0.3);
  border-radius: 10px;
  height: 5rem;
  padding: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.6rem 0;
`;

export const WrapperDocument = styled.div`
  display: flex;
  background-color: #e8f1f8;
  border-radius: 5px;
  margin: 1.6rem;
  padding: 0.3rem 0.7rem;
`;

export const DocumentIcon = styled(IconDocument)`
  stroke: #0e067d;
  width: 2rem;
`;

export const WrapperDownload = styled.div``;

export const DownloadIcon = styled(IconDownload)`
  fill: #606474;
  width: 5rem;
`;
