import styled from 'styled-components';
import { CircleCheckStep } from '~/assets/svgs';

export const Container = styled.div``;

export const Modal = styled.div`
  width: 548px;
  height: 485px;
  border-radius: 20px;
  padding: 40px;
  align-items: center;
  display: grid;
  justify-content: center;
  text-align: center;

  @media (max-width: 500px) {
    width: auto;
  }
`;

export const Title = styled.span`
  font-size: 36px;
  font-weight: 700;
  color: #00bc61;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  width: 300px;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  margin-top: 10%;
`;

export const ButtonsArea = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  margin-top: 40px;
`;

export const CheckImage = styled(CircleCheckStep)`
  display: grid;
  width: 100%;
  height: 160px;
`;
