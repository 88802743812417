import React, { useCallback, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { func, oneOfType, any, arrayOf, string } from 'prop-types';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import { byteToSize } from '~/utils/formatters';
import { TertiaryButton } from '~/components/Button';
import BusinessUnitActions from '~/store/ducks/businessUnits';

import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  IconUpload,
  DeleteButton,
  DeleteIcon,
  AttachIcon,
  WrapperAddButton,
  Row,
  FileName,
  FileSize,
  FileDate,
  Description,
  InputFile,
  ButtonName,
  ClipIcon,
} from './styles';

const FileItem = ({ file, onChange }) => {
  const inputRef = useRef(null);
  return file ? (
    <>
      <Row>
        <FileName>{file.name}</FileName>
        <FileSize>{file.size ? byteToSize(file.size) : ''}</FileSize>
      </Row>
      <FileDate>
        {file?.createdAt ? format(new Date(file.createdAt), 'dd/MM/yyyy') : ''}
      </FileDate>
    </>
  ) : (
    <>
      <TertiaryButton
        label={i18next.t('common.buttons.attach')}
        leftIcon={<AttachIcon />}
        style={{ height: '6rem' }}
        onClick={() => inputRef.current.click()}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={(e) => onChange(e.target.files[0])}
        multiple={false}
      />
    </>
  );
};

FileItem.propTypes = {
  file: oneOfType([any]).isRequired,
  onChange: func.isRequired,
};

function DocumentationEdit({
  documents,
  setDocuments,
  // setDeletedDocuments,
  localityId,
}) {
  const [doc, setDoc] = useState([]);
  const { isUpload } = useSelector((state) => state.businessUnits);

  const dispatch = useDispatch();

  const onSuccess = (item) => {
    setDocuments([...documents, item]);
    setDoc([]);
  };

  const handleSave = (item) => {
    dispatch(
      BusinessUnitActions.createLocalityEvidencesRequest(
        localityId,
        item.description,
        item.file,
        onSuccess
      )
    );
  };

  const onDelete = (id) => {
    setDocuments(documents.filter((item) => item.id !== id));
  };

  const handleDelete = (id) => {
    dispatch(BusinessUnitActions.deleteEvidenceRequest(id, onDelete));
  };

  const newActive = () => {
    if (doc.length < 1) {
      setDoc((e) => [...e, { description: '', file: null }]);
    }
  };

  // const deleteActive = (index) => {
  //   const newArray = [...doc];
  //   const removedId = newArray[index]?.id;
  //   if (removedId) {
  //     setDeletedDocuments((state) => [...state, removedId]);
  //   }
  //   newArray.splice(index, 1);
  //   setDoc([...newArray]);
  // };

  const updateDescription = (index, updatedDescription) => {
    const newArray = [...doc];
    newArray[index] = { ...newArray[index], description: updatedDescription };
    setDoc([...newArray]);
  };

  const updateFile = (index, updatedFile) => {
    const newArray = doc;
    newArray[index] = { ...newArray[index], file: updatedFile };
    setDoc([...newArray]);
  };

  const fileNameShow = (index) => {
    const name = doc[index].file.split('/');
    const teste = name[name.length - 1].split('_');
    return teste[teste.length - 1];
  };

  const onDrop = useCallback((acceptedFiles) => {
    updateFile(0, acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <Container>
      <Title>{i18next.t('businessUnitAsset.tabs.evidences.title')}</Title>

      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t('businessUnitAsset.tabs.evidences.descriptionLabel')}
          </Label>
        </Grid>
        <Grid item xs={7} />
        {documents.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <Description>{item.description}</Description>
            </Grid>
            <Grid item xs={2}>
              <FileItem
                file={
                  item?.id && !item.url
                    ? {
                        name: fileNameShow(index),
                        size: documents[index].file_size,
                        createdAt: documents[index].createdAt,
                      }
                    : item.url
                    ? {
                        name: item.url.split('_')[
                          item.url.split('_').length - 1
                        ],
                      }
                    : documents[index].file
                }
                onChange={(e) => updateFile(index, e)}
              />
            </Grid>
            <Grid item xs={5}>
              <DeleteButton onClick={() => handleDelete(item.id)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4} />
        <Grid item xs={7} />
        {doc.map((item, index) => (
          <>
            <Grid item xs={2}>
              <ID>
                {Number(index + documents.length + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={3}>
              <TextInput
                onChange={(e) => updateDescription(index, e.target.value)}
                value={item.description}
                placeholder="Insira uma descrição para o Anexo"
              />
            </Grid>
            <Grid item xs={3}>
              <InputFile {...getRootProps()}>
                <input
                  {...getInputProps()}
                  multiple={false}
                  name="file"
                  required
                />
                {!doc[0].file ? (
                  <ButtonName>
                    <ClipIcon />
                    {i18next.t('common.buttons.attach')}
                  </ButtonName>
                ) : (
                  doc[0].file.name
                )}
              </InputFile>
            </Grid>
            <Grid item xs={2}>
              {item.file && item.description && (
                <TertiaryButton
                  disabled={isUpload}
                  onClick={() => handleSave(item)}
                  label={i18next.t('common.buttons.attach')}
                  leftIcon={isUpload ? <IconUpload /> : <IconPlus />}
                />
              )}
              {/* <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton> */}
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        {doc.length === 0 && (
          <TertiaryButton
            onClick={newActive}
            label={i18next.t('common.buttons.add')}
            leftIcon={<IconPlus />}
          />
        )}
      </WrapperAddButton>
    </Container>
  );
}

export default DocumentationEdit;

DocumentationEdit.propTypes = {
  documents: arrayOf.isRequired,
  setDocuments: func.isRequired,
  setDeletedDocuments: func.isRequired,
  localityId: string.isRequired,
};
