import React from 'react';
import i18next from 'i18next';

import Tabs from '~/components/Tabs';
import Threats from './Tabs/Threats';
import Impact from './Tabs/Impact';
import Actives from './Tabs/Actives';
import Details from './Tabs/Details';

import { Container } from './styles';

function Resume() {
  return (
    <Container>
      <Tabs
        tabs={[
          i18next.t('risks.riskDetails.associations.threats.title'),
          i18next.t(
            'risks.riskDetails.associations.impactForInoperability.title'
          ),
          i18next.t('risks.riskDetails.associations.assets.label'),
          i18next.t('risks.riskDetails.associations.contingency.title'),
        ]}
      >
        <Threats />
        <Impact />
        <Actives />
        <Details />
      </Tabs>
    </Container>
  );
}

export default Resume;
