import styled from 'styled-components';

import { MathPlus, Attachment1, Attachment2 } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const MathPlusIcon = styled(MathPlus)`
  height: 2.4rem;
  width: 2.4rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  padding-left: 2.4rem;
  padding-right: 1.2rem;
  cursor: pointer;
  height: 6rem;

  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 500;
  ${({ theme }) => theme.notSelectable};

  :first-child {
    margin-top: 0.8rem;
  }
  :last-child {
    margin-bottom: 0.8rem;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  transition: 150ms ease;
`;

export const WrapperIcon = styled.div`
  width: 3.5rem;
  margin-right: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Attachment1Icon = styled(Attachment1).attrs(({ theme }) => ({
  // size: 24,
}))``;

export const Attachment2Icon = styled(Attachment2).attrs(({ theme }) => ({
  // size: 24,
}))``;

export const ButtonWrapper = styled.div`
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
`;
