import React, { useEffect, useState } from 'react';
import i18next from 'i18next';

import Warning from '~/components/CancelModal/Warning';
import CancelOptions from '~/components/CancelModal/CancelOptions';
import SucessCancel from '~/components/CancelModal/SucessCancel';
import FailureCancel from '~/components/CancelModal/FailureCancel';
import Card from '~/components/Card';
import ConfigActions from '~/store/ducks/config';
import Table from '~/components/Table/Secondary';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from '~/components/Spinner';
import { format } from 'date-fns';
import { dateSortMethod } from '~/utils';
import { Container, Title, HeaderCard, AreaSubtitle } from './styles';

const TableComp = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [openWarning, setOpenWarning] = useState(false);
  const [openCancelOptions, setOpenCancelOptions] = useState(false);
  const [openSucess, setOpenSucess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const { isLoading, subscriptions } = useSelector((state) => state.config);
  const { userActive } = useSelector((state) => state.auth);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
  };

  const openCancelModal = () => {
    setOpenWarning(false);
    setOpenCancelOptions(true);
  };

  useEffect(() => {
    dispatch(
      ConfigActions.getCompanySignatureRequest(
        userActive.activeCompany.id,
        currentPage - 1,
        10
      )
    );
  }, [currentPage]);

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Card>
        <AreaSubtitle>
          <Title>{i18next.t('configurations.signature.subtitle')}</Title>
        </AreaSubtitle>
        <HeaderCard>
          {/* <TertiaryButton
            style={{ width: 200, height: 40 }}
            onClick={() => setOpenWarning(true)}
            label={i18next.t('configurations.signature.buttonSignature')}
            // leftIcon={<IconPlus />}
          /> */}
        </HeaderCard>
        <Table
          columns={[
            {
              Header: i18next.t('configurations.signature.type'),
              accessor: 'period',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                switch (e.value.toLowerCase()) {
                  case '15_days':
                    return i18next.t(
                      'configurations.signature.period.biweekly'
                    );
                  case 'monthly':
                    return i18next.t('configurations.signature.period.monthly');
                  case 'semiannual':
                    return i18next.t(
                      'configurations.signature.period.semiannual'
                    );
                  case 'yearly':
                    return i18next.t('configurations.signature.period.yearly');
                  default:
                    return '-';
                }
              },
            },

            {
              Header: i18next.t('configurations.signature.meansOfPayment'),
              accessor: 'payment_method',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                switch (e.value.toLowerCase()) {
                  case 'voucher':
                    return i18next.t(
                      'configurations.signature.paymentMethod.voucher'
                    );
                  default:
                    return '-';
                }
              },
            },
            {
              Header: i18next.t('configurations.signature.responsible'),
              accessor: 'responsible',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('configurations.signature.value'),
              accessor: 'price',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                });
              },
            },
            {
              Header: i18next.t('configurations.signature.purchaseDate'),
              accessor: 'createdAt',
              Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
              sortType: (a, b) => {
                return dateSortMethod(a.values.date, b.values.date);
              },
            },
            {
              Header: i18next.t('configurations.signature.expirationDate'),
              accessor: 'expiratedAt',
              Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
              sortType: (a, b) => {
                return dateSortMethod(a.values.date, b.values.date);
              },
            },
            {
              Header: i18next.t('configurations.signature.status'),
              accessor: 'status',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                switch (e.value.toLowerCase()) {
                  case 'success':
                    return i18next.t('configurations.signature.state.success');
                  default:
                    return '-';
                }
              },
            },
            // {
            //   Header: i18next.t('businessUnitAsset.table.action'),
            //   accessor: 'action',
            //   Cell: (e) => {
            //     // if (e.row.original?.id) {
            //     //   return (
            //     //     <>
            //     //       <ActionButtonTable
            //     //         dataId={e.row.original?.id}
            //     //          dispatchDelete={}
            //     //       />
            //     //     </>
            //     //   );
            //     // }
            //     // return null;
            //   },
            // },
          ]}
          data={subscriptions?.rows}
          onRowClick={() => {}}
          total={subscriptions.count}
          pageCount={subscriptions.total_pages}
          handlePagination={handlePagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Card>
      <Warning
        open={!!openWarning}
        onClose={() => {
          setOpenWarning(false);
        }}
        cancelButton={openCancelModal}
      />
      <CancelOptions
        open={!!openCancelOptions}
        onClose={() => setOpenCancelOptions(false)}
      />
      <SucessCancel open={!!openSucess} onClose={() => setOpenSucess(false)} />
      <FailureCancel open={!!openError} onClose={() => setOpenError(false)} />
    </Container>
  );
};

export default TableComp;
