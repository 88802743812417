import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, objectOf } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';
import ConfigActions from '~/store/ducks/config';
import Modal from '~/components/Modal';

import { SecondarySelect } from '~/components/Select';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import schema from './validationSchema';

import { Container, WrapperButton } from './styles';

function EditModal({ open, newUserRole, onClose, item }) {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const { user, roleEnum, isLoading } = useSelector((state) => state.config);

  const onSuccess = () => {
    dispatch(ConfigActions.getUsersCompanyRequest(0));
    onClose();
  };

  function reloadRole() {
    dispatch(ConfigActions.getRolesRequest());
  }

  useEffect(() => {
    reloadRole();
  }, []);

  const submit = (values) => {
    const data = {
      user_edit_id: item?.user?.id,
      role_id: values.role,
      company_id: userActive.activeCompany.id,
    };

    dispatch(ConfigActions.updateUserRequest(data, onSuccess));
  };

  return (
    <Modal
      title={`${i18next.t('peopleAsset.editUserModal.titleEdit')}`}
      noPadding
      open={open}
      onClose={onClose}
    >
      {' '}
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            role: item?.role?.id,
            isActive: false,
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <Grid>
                <SecondarySelect
                  label={i18next.t('peopleAsset.selectNewUserRoleModal.title')}
                  shadow
                  placeholder={i18next.t(
                    'peopleAsset.selectNewUserRoleModal.placeholder'
                  )}
                  name="role"
                  options={roleEnum.map((role) => {
                    return {
                      value: role.id,
                      label:
                        role.type === 'ADMIN'
                          ? i18next.t('common.roles.administrator')
                          : role.type === 'GED'
                          ? i18next.t('common.roles.gedReviewer')
                          : role.type === 'TREATMENT_AGENT'
                          ? i18next.t('common.roles.internTreatmentAgent')
                          : role.type === 'DPO'
                          ? i18next.t('common.roles.dpo')
                          : role.type === 'PAYING_ADMIN'
                          ? i18next.t('common.roles.payingAdmin')
                          : role.type === 'OWNER'
                          ? i18next.t('common.roles.owner')
                          : i18next.t('common.roles.others'),
                    };
                  })}
                  value={values.role}
                  touched={touched.role}
                  error={errors.role}
                  onChange={(e) => setFieldValue('role', e)}
                />
              </Grid>
              <WrapperButton>
                <SecondaryButton
                  label={i18next.t('common.buttons.goBack')}
                  padding="0 5.8rem"
                  width="null"
                  onClick={onClose}
                />
                <PrimaryButton
                  label={
                    isLoading ? (
                      <SppinerIcon />
                    ) : (
                      i18next.t('common.buttons.save')
                    )
                  }
                  padding="0 5.8rem"
                  width="null"
                  onClick={handleSubmit}
                />
              </WrapperButton>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
}

export default EditModal;

EditModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  item: objectOf.isRequired,
};
