import React, { useState, useEffect } from 'react';
import { objectOf, string, number } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DemandActions from '~/store/ducks/demand';

import Card from '~/components/Card';
import { Text } from '~/styles/components';

import {
  Container,
  Title,
  WrapperItems,
  WrapperItem,
  WrapperDocument,
  WrapperDownload,
  DocumentIcon,
  DownloadIcon,
} from './styles';

const FilesItem = ({ item, ticketId }) => {
  const dispatch = useDispatch();

  const handleFileDownload = () => {
    dispatch(
      DemandActions.downloadDemandDocumentRequest(
        item?.title,
        item?.id,
        ticketId
      )
    );
  };

  return (
    <WrapperItem>
      <WrapperDocument>
        <DocumentIcon />
      </WrapperDocument>
      <Text width="100%" fontWeight={400} fontSize="1.2rem">
        {item.title}
      </Text>
      <WrapperDownload>
        <DownloadIcon onClick={handleFileDownload} />
      </WrapperDownload>
    </WrapperItem>
  );
};

FilesItem.propTypes = {
  item: objectOf(string).isRequired,
  ticketId: number.isRequired,
};

function Files() {
  const { demandDetails } = useSelector((state) => state.demands);
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (
      demandDetails &&
      demandDetails?.documents &&
      demandDetails?.documents.length
    ) {
      setDocuments(demandDetails.documents);
    }
  }, [demandDetails]);

  return (
    <Card>
      <Container>
        <Title>Arquivos anexados</Title>
        <WrapperItems>
          {documents.map((item) => (
            <FilesItem
              key={String(item.id)}
              item={item}
              ticketId={demandDetails?.id}
            />
          ))}
        </WrapperItems>
      </Container>
    </Card>
  );
}

export default Files;
