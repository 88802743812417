import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { bool } from 'prop-types';

import ClientMenu from '~/components/ClientMenu';

import history from '~/routes/history';
// import DateViewer from '~/components/DateViewer';
// import LanguageSelector from '~/components/LanguageSelector';

import Images from '~/assets/images';

import {
  Container,
  WrapperBack,
  BackIcon,
  BackText,
  PCSLogo,
  ClientMenuWrapper,
  LogoWrapper,
} from './styles';

export default function Header({ backButton }) {
  return (
    <Container>
      <Grid container spacing={4}>
        <Hidden lgUp>
          <Grid item xs="auto" />
        </Hidden>
        {backButton && (
          <Grid item xs="auto">
            <WrapperBack onClick={() => history.goBack()}>
              <BackIcon />
              <BackText>Voltar</BackText>
            </WrapperBack>
          </Grid>
        )}

        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <LogoWrapper onClick={() => history.push('/demands-history')}>
              <PCSLogo src={Images.PCSLogoIcon} />
              <PCSLogo src={Images.PCSLogoName} />
            </LogoWrapper>
          </Grid>
          <Grid item align="right" direction="row">
            <ClientMenuWrapper>
              {/* <DateViewer /> */}
              {/* <LanguageSelector privateLayout /> */}
              <ClientMenu />
            </ClientMenuWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

Header.propTypes = {
  backButton: bool,
};

Header.defaultProps = {
  backButton: false,
};
