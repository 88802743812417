import React, { useState } from 'react';
// import i18next from 'i18next';

import { Container } from './styles';

import ListDepartament from './components/ListDepartament';
import ProcessDetails from './components/ProcessDetails';
import DepartamentModal from './components/DepartamentModal';

const Departaments = () => {
  const [departamentModalOpen, setDepartamentModalOpen] = useState(false);
  const [editableDepartament, setEditableDepartament] = useState(null);
  const [activeDepartamentProcess, setActiveDepartamentProcess] = useState({
    departament: '',
    process: '',
  });

  const handleOpenModal = () => {
    setEditableDepartament(null);
    setDepartamentModalOpen(true);
  };

  const handleOpenEditModal = (departament) => {
    setEditableDepartament(departament);
    setDepartamentModalOpen(true);
  };

  return (
    <Container>
      <ListDepartament
        departament={activeDepartamentProcess}
        setActive={setActiveDepartamentProcess}
        handleOpenModal={handleOpenModal}
        handleOpenEditModal={handleOpenEditModal}
      />
      {!!activeDepartamentProcess.departament && (
        <ProcessDetails
          selectedDepartament={activeDepartamentProcess}
          handleOpenModal={handleOpenEditModal}
        />
      )}

      <DepartamentModal
        open={departamentModalOpen}
        selectedDepartament={editableDepartament}
        onClose={() => setDepartamentModalOpen(false)}
      />
    </Container>
  );
};

export default Departaments;
