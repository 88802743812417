import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';

import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import StepAnimated from '~/components/StepAnimated';

import SpinnerPrimary from '~/components/SppinerPrimary';
import schema from './validationSchema';

import { ForgotPasswordText, ForgotPasswordInstruction } from './styles';

function Step1({ onSubmit, setEmail }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const onSuccess = (email) => {
    onSubmit();
    setEmail(email);
  };

  const submit = (values) => {
    dispatch(AuthActions.forgotRequest(values.email, onSuccess));
  };

  return (
    <StepAnimated noStart>
      <ForgotPasswordText>
        {i18next.t('forgotPassword.step1.subtitle')}
      </ForgotPasswordText>
      <ForgotPasswordInstruction>
        {i18next.t('forgotPassword.step1.description')}
      </ForgotPasswordInstruction>

      <Formik
        initialValues={{ email: '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <TextInput
              placeholder={i18next.t('forgotPassword.step1.emailLabel')}
              name="email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onSubmit={handleSubmit}
              margin="0 0 3.5rem 0"
            />

            <PrimaryButton
              label={
                isLoading ? (
                  <SpinnerPrimary />
                ) : (
                  i18next.t('forgotPassword.step1.button')
                )
              }
              disabled={isLoading}
              margin="0 0 1.6rem"
              onClick={handleSubmit}
            />
          </>
        )}
      </Formik>
    </StepAnimated>
  );
}

Step1.propTypes = {
  onSubmit: func.isRequired,
  setEmail: func.isRequired,
};

export default Step1;
