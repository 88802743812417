import React from 'react';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any } from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import history from '~/routes/history';
import RisksActions from '~/store/ducks/risks';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  EyeIcon,
  CloseIcon,
} from './styles';

function ActionButton({ item }) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleRiskToggle = (currentId) => {
    dispatch(RisksActions.putRiskToggleRequest(currentId));
  };

  return (
    <>
      <Container>
        <MoreIcon onClick={handleClick} />
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '14rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row
          onClick={() =>
            item.id && history.push(`/risks-management/${item.id}`)
          }
        >
          <WrapperIcon>
            <EyeIcon />
          </WrapperIcon>
          {i18next.t('risks.risksTable.actionButton.view')}
        </Row>
        <Row onClick={() => handleRiskToggle(item.id)}>
          <WrapperIcon>
            <CloseIcon />
          </WrapperIcon>
          {i18next.t('risks.risksTable.actionButton.hide')}
        </Row>
      </Popover>
    </>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
};
