import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { any, oneOfType, objectOf } from 'prop-types';
import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';

import { SecondaryButton, PrimaryButton } from '~/components/Button';

import Spinner from '~/components/Spinner';
import Informations from './components/Informations';
import Document from './components/Document';
import RevisionModal from './components/RevisionModal';

import { Container, Header, HeaderLeft, HeaderRight, Title } from './styles';

const DocumentDetails = ({ match }) => {
  const { params } = match;
  const { id } = params;
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, revisionDocumentDetail } = useSelector(
    (state) => state.documents
  );

  useEffect(() => {
    dispatch(DocumentActions.getRevisionDocumentDetailsRequest(id));
  }, []);

  useEffect(() => {
    dispatch(DocumentActions.getRevisionDocumentDetailsRequest(id));
  }, [revisionDocumentDetail?.fileUrl]);

  function handleDocumentApprove() {
    dispatch(
      DocumentActions.approveRevisionDocumentRequest(
        revisionDocumentDetail?.data?.doc?.id
      )
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={6}>
            <Grid item xs={8}>
              <Header>
                <HeaderLeft>
                  <Title>{i18next.t('documentReview.title')}</Title>
                </HeaderLeft>
                <HeaderRight>
                  <SecondaryButton
                    width="16.6rem"
                    padding="0"
                    small
                    label={i18next.t(
                      'documentReview.documentDetails.askForReview'
                    )}
                    margin="0 1.5rem"
                    onClick={() => setOpenModal(true)}
                  />
                  <PrimaryButton
                    width="16.6rem"
                    padding="0"
                    small
                    label={i18next.t('documentReview.documentDetails.approve')}
                    margin="0"
                    onClick={handleDocumentApprove}
                  />
                </HeaderRight>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                <Grid item xs={8}>
                  <Document />
                </Grid>
                <Grid item xs={4}>
                  <Informations />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <RevisionModal
        id={id}
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default DocumentDetails;

DocumentDetails.propTypes = {
  match: objectOf(oneOfType([any])).isRequired,
};
