import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { func, objectOf } from 'prop-types';
import { useSelector } from 'react-redux';
import Accordion from '~/components/Accordion';
import FileInput from '~/components/FileInput';
import { MultiSelect, SecondarySelect } from '~/components/Select';
import NewUserTypeModalGeral from '~/components/NewUserTypeModal';
import InviteDPOModalGeral from '~/components/InviteDPOModal';
import CreateUserModalGeral from '~/components/CreateUserModal';

import Select from '~/components/Select/Secondary';
import { WrapperMulti } from './styles';

const Executors = ({ executionPlan, setExecutionPlan }) => {
  const [openNewUserTypeModal, setOpenNewUserTypeModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openInviteDPOModal, setOpenInviteDPOModal] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');
  const { peopleEnum } = useSelector((state) => state.people);
  const { departamentsEnum } = useSelector((state) => state.config);
  const { businessUnitsEnum } = useSelector((state) => state.businessUnits);

  return (
    <Accordion
      title={i18next.t(
        'processAsset.tabs.executionPlan.processExecutors.title'
      )}
    >
      <Grid container spacing={4} style={{ marginBottom: '3.2rem' }}>
        <Grid item xs={4}>
          <WrapperMulti>
            <MultiSelect
              label={i18next.t(
                'processAsset.tabs.executionPlan.processExecutors.responsiblesLabel'
              )}
              oneLineLabel={false}
              options={peopleEnum}
              hasStore
              hasModal
              onStore={setOpenNewUserTypeModal}
              onChange={(e) =>
                setExecutionPlan({
                  ...executionPlan,
                  responsibles: e,
                })
              }
              value={executionPlan.responsibles}
            />
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <SecondarySelect
              label={i18next.t(
                'processAsset.tabs.executionPlan.processExecutors.nature.label'
              )}
              oneLineLabel={false}
              noImage
              options={[
                {
                  value: '1',
                  label: i18next.t(
                    'processAsset.tabs.executionPlan.processExecutors.nature.human'
                  ),
                },
                {
                  value: '2',
                  label: i18next.t(
                    'processAsset.tabs.executionPlan.processExecutors.nature.digital'
                  ),
                },
              ]}
              onChange={(e) =>
                setExecutionPlan({
                  ...executionPlan,
                  processNature: e,
                })
              }
              value={executionPlan.processNature}
            />
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <FileInput
              label={i18next.t(
                'processAsset.tabs.executionPlan.processExecutors.graphicSchema'
              )}
              placeholder="Anexar um organograma/ou fluxograma"
              onChange={(e) =>
                setExecutionPlan({ ...executionPlan, graphicSchema: e })
              }
              value={executionPlan.graphicSchema}
            />
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <MultiSelect
            noImage
            label={i18next.t(
              'processAsset.tabs.executionPlan.processExecutors.locality'
            )}
            placeholder={i18next.t(
              'processAsset.tabs.executionPlan.processExecutors.locality'
            )}
            hasStore
            hasModal
            // onStore={setOpenCreateLocality}
            value={executionPlan.localities}
            onChange={(e) => {
              setExecutionPlan({ ...executionPlan, localities: e });
            }}
            options={businessUnitsEnum}
            margin="0 0 1.8rem"
          />
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <MultiSelect
              label={i18next.t(
                'processAsset.tabs.executionPlan.processExecutors.department.label'
              )}
              oneLineLabel={false}
              noImage
              options={departamentsEnum}
              onChange={(e) =>
                setExecutionPlan({
                  ...executionPlan,
                  departments: e,
                })
              }
              value={executionPlan.departments}
            />
          </WrapperMulti>
        </Grid>
      </Grid>
      <NewUserTypeModalGeral
        open={!!openNewUserTypeModal}
        onClose={() => setOpenNewUserTypeModal(false)}
        setOpenCreateUserModal={setOpenCreateUserModal}
        setNewUserRole={setNewUserRole}
        setOpenInviteDPOModal={setOpenInviteDPOModal}
      />
      <CreateUserModalGeral
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
      <InviteDPOModalGeral
        open={!!openInviteDPOModal}
        onClose={() => setOpenInviteDPOModal(false)}
      />
    </Accordion>
  );
};

export default Executors;

Executors.propTypes = {
  executionPlan: objectOf.isRequired,
  setExecutionPlan: func.isRequired,
};
