import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { css } from 'highcharts';

export const Container = styled.div``;

export const WrapperContent = styled.div`
  min-width: 22.5rem;
  height: 5.3rem;
  background: ${({ theme }) => theme.colors.base[100]};
  border-radius: 10px;

  ${({ active }) =>
    active &&
    css`
      filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.15));
      border-radius: 10px 10px 0 0;
    `};

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  cursor: pointer;

  :hover {
    filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.15));
  }
  transition: 200ms ease;

  @media (max-width: 670px) {
    min-width: 5rem;
  }
`;

export const UserImage = styled.img`
  object-fit: cover;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;

  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 670px) {
    margin-right: 5px;
  }
`;

export const Name = styled.div`
  flex: 1;
  margin: 1.7rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  align-items: flex-start;

  @media (max-width: 670px) {
    display: none;
  }
`;

export const ArrowDown = styled(IoIosArrowDown).attrs(({ theme }) => {
  return {
    size: '1.5rem',
    color: theme.colors.base[400],
  };
})``;

export const ArrowUp = styled(IoIosArrowUp).attrs(({ theme }) => {
  return {
    size: '1.5rem',
    color: theme.colors.base[400],
  };
})``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  padding-left: 2.4rem;
  padding-right: 1.2rem;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.2rem;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  ${({ theme }) => theme.notSelectable};

  :first-child {
    margin-top: 0.8rem;
  }
  :last-child {
    margin-bottom: 0.8rem;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  button {
    height: 4rem;
    font-size: 1.5rem;
  }

  transition: 150ms ease;
`;

export const CompanyLogoWrapper = styled.div`
  width: 1.8rem;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CompanyLogo = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: contain;
`;

export const NotificationDot = styled.div`
  height: 1.1rem;
  width: 1.1rem;
  border-radius: 50%;
  background: #539ada;
  position: absolute;
  top: 0;
  right: -0.8rem;
`;

export const CompanyNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyName = styled.span`
  color: #262a3a;
  font-weight: 400;
  font-size: 1.4rem;
`;

export const DemandBadgeWrapper = styled.div`
  display: flex;
  background: #4347a2;
  padding: 0.1rem 1rem;
  border-radius: 0.7rem;
  gap: 1rem;
  align-items: center;
`;

export const DemandAmount = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 700;
`;

export const DemandText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
`;
