import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getBusinessUnitsRequest: ['companyId', 'page', 'limit', 'query'],
  getBusinessUnitsSuccess: ['businessUnits'],
  getBusinessUnitsFailure: [''],

  searchBusinessUnitsRequest: ['query'],
  searchBusinessUnitsSuccess: ['results'],
  searchBusinessUnitsFailure: [''],

  createBusinessUnitsRequest: ['data', 'handleGoBack'],
  createBusinessUnitsSuccess: [''],
  createBusinessUnitsFailure: [''],

  updateBusinessUnitsRequest: ['data', 'id'],
  updateBusinessUnitsSuccess: [''],
  updateBusinessUnitsFailure: [''],

  deleteBusinessUnitsRequest: ['id'],
  deleteBusinessUnitsSuccess: ['id'],
  deleteBusinessUnitsFailure: [''],

  deleteEvidenceRequest: ['evidenceId', 'onSuccess'],
  deleteEvidenceSuccess: [''],
  deleteEvidenceFailure: [''],

  getBusinessUnitDetailsRequest: ['id'],
  getBusinessUnitDetailsSuccess: ['businessUnit'],
  getBusinessUnitDetailsFailure: [''],

  getBusinessUnitsEnumRequest: [''],
  getBusinessUnitsEnumSuccess: ['businessUnitsEnum'],
  getBusinessUnitsEnumFailure: [''],

  downloadBusinessUnitsRequest: [''],
  downloadBusinessUnitsSuccess: [''],
  downloadBusinessUnitsFailure: [''],

  uploadBusinessUnitsRequest: ['data'],
  uploadBusinessUnitsSuccess: [''],
  uploadBusinessUnitsFailure: [''],

  createLocalityEvidencesRequest: [
    'localityId',
    'description',
    'evidence',
    'onSuccess',
  ],
  createLocalityEvidencesSuccess: ['evidence'],
  createLocalityEvidencesFailure: [''],

  setSidebarMenuDataRequest: ['data'],
  // setUpPercentageSuccess: ['upPercentage'],
});

export const BusinessUnitsTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  isUpload: false,
  onDeleteEvidence: false,
  evidence: {},
  upPercentage: 0,
  error: false,
  fitred: {},
  sidebarMenuData: {},
  businessUnitDetails: {},
  businessUnitsEnum: [],
});

/* Reducers */

const getBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const getBusinessUnitsSuccess = (state, { businessUnits }) =>
  state.merge({
    isLoading: false,
    error: false,
    businessUnits,
  });

const getBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    businessUnits: {},
  });

const searchBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const searchBusinessUnitsSuccess = (state, { results }) =>
  state.merge({
    isLoading: false,
    error: false,
    businessUnits: results,
  });
const searchBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    businessUnits: {},
  });

const createBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const createBusinessUnitsSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const updateBusinessUnitsSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const deleteBusinessUnitsSuccess = (state, { id }) => {
  const fitred = [...state.businessUnits.rows.filter((item) => item.id !== id)];
  const countFormated = state.businessUnits.count - 1;
  return state.merge({
    isLoading: false,
    error: false,
    businessUnits: {
      ...state.businessUnits,
      rows: fitred,
      count: countFormated,
    },
  });
};
const deleteBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteEvidenceRequest = (state) =>
  state.merge({ onDeleteEvidence: true });
const deleteEvidenceSuccess = (state) =>
  state.merge({
    onDeleteEvidence: false,
    error: false,
  });

const deleteEvidenceFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getBusinessUnitDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const getBusinessUnitDetailsSuccess = (state, { businessUnit }) =>
  state.merge({
    isLoading: false,
    error: false,
    businessUnitDetails: businessUnit,
  });

const getBusinessUnitDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    businessUnitDetails: {},
  });

const getBusinessUnitsEnumRequest = (state) => state.merge({ isLoading: true });
const getBusinessUnitsEnumSuccess = (state, { businessUnitsEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    businessUnitsEnum,
  });
const getBusinessUnitsEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    businessUnitsEnum: [],
  });

const downloadBusinessUnitsRequest = (state) =>
  state.merge({ isLoading: true });
const downloadBusinessUnitsSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadBusinessUnitsRequest = (state) => state.merge({ isLoading: true });
const uploadBusinessUnitsSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadBusinessUnitsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const setSidebarMenuDataRequest = (state, { data }) =>
  state.merge({ sidebarMenuData: data });

const createLocalityEvidencesRequest = (state) =>
  state.merge({ isUpload: true });
const createLocalityEvidencesSuccess = (state, { evidence }) =>
  state.merge({
    isUpload: false,
    error: false,
    evidence,
  });
const createLocalityEvidencesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

// const setUpPercentageSuccess = (state, { upPercentage }) =>
//   state.merge({
//     upPercentage,
//   });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BUSINESS_UNITS_REQUEST]: getBusinessUnitsRequest,
  [Types.GET_BUSINESS_UNITS_SUCCESS]: getBusinessUnitsSuccess,
  [Types.GET_BUSINESS_UNITS_FAILURE]: getBusinessUnitsFailure,

  [Types.SEARCH_BUSINESS_UNITS_REQUEST]: searchBusinessUnitsRequest,
  [Types.SEARCH_BUSINESS_UNITS_SUCCESS]: searchBusinessUnitsSuccess,
  [Types.SEARCH_BUSINESS_UNITS_FAILURE]: searchBusinessUnitsFailure,

  [Types.CREATE_BUSINESS_UNITS_REQUEST]: createBusinessUnitsRequest,
  [Types.CREATE_BUSINESS_UNITS_SUCCESS]: createBusinessUnitsSuccess,
  [Types.CREATE_BUSINESS_UNITS_FAILURE]: createBusinessUnitsFailure,

  [Types.CREATE_LOCALITY_EVIDENCES_REQUEST]: createLocalityEvidencesRequest,
  [Types.CREATE_LOCALITY_EVIDENCES_SUCCESS]: createLocalityEvidencesSuccess,
  [Types.CREATE_LOCALITY_EVIDENCES_FAILURE]: createLocalityEvidencesFailure,

  [Types.UPDATE_BUSINESS_UNITS_REQUEST]: updateBusinessUnitsRequest,
  [Types.UPDATE_BUSINESS_UNITS_SUCCESS]: updateBusinessUnitsSuccess,
  [Types.UPDATE_BUSINESS_UNITS_FAILURE]: updateBusinessUnitsFailure,

  [Types.DELETE_BUSINESS_UNITS_REQUEST]: deleteBusinessUnitsRequest,
  [Types.DELETE_BUSINESS_UNITS_SUCCESS]: deleteBusinessUnitsSuccess,
  [Types.DELETE_BUSINESS_UNITS_FAILURE]: deleteBusinessUnitsFailure,

  [Types.DELETE_EVIDENCE_REQUEST]: deleteEvidenceRequest,
  [Types.DELETE_EVIDENCE_SUCCESS]: deleteEvidenceSuccess,
  [Types.DELETE_EVIDENCE_FAILURE]: deleteEvidenceFailure,

  [Types.GET_BUSINESS_UNIT_DETAILS_REQUEST]: getBusinessUnitDetailsRequest,
  [Types.GET_BUSINESS_UNIT_DETAILS_SUCCESS]: getBusinessUnitDetailsSuccess,
  [Types.GET_BUSINESS_UNIT_DETAILS_FAILURE]: getBusinessUnitDetailsFailure,

  [Types.GET_BUSINESS_UNITS_ENUM_REQUEST]: getBusinessUnitsEnumRequest,
  [Types.GET_BUSINESS_UNITS_ENUM_SUCCESS]: getBusinessUnitsEnumSuccess,
  [Types.GET_BUSINESS_UNITS_ENUM_FAILURE]: getBusinessUnitsEnumFailure,

  [Types.DOWNLOAD_BUSINESS_UNITS_REQUEST]: downloadBusinessUnitsRequest,
  [Types.DOWNLOAD_BUSINESS_UNITS_SUCCESS]: downloadBusinessUnitsSuccess,
  [Types.DOWNLOAD_BUSINESS_UNITS_FAILURE]: downloadBusinessUnitsFailure,

  [Types.UPLOAD_BUSINESS_UNITS_REQUEST]: uploadBusinessUnitsRequest,
  [Types.UPLOAD_BUSINESS_UNITS_SUCCESS]: uploadBusinessUnitsSuccess,
  [Types.UPLOAD_BUSINESS_UNITS_FAILURE]: uploadBusinessUnitsFailure,

  [Types.SET_SIDEBAR_MENU_DATA_REQUEST]: setSidebarMenuDataRequest,
  // [Types.SET_UP_PERCENTAGE_REQUEST]: setUpPercentageSuccess,
});
