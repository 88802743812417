import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { reducer as toastr } from 'react-redux-toastr';
import { reducer as auth } from './auth';
import { reducer as holders } from './holders';
import { reducer as iventory } from './iventory';
import { reducer as documents } from './documents';
import { reducer as tickets } from './tickets';
import { reducer as people } from './people';
import { reducer as demands } from './demand';
import { reducer as dashboard } from './dashboard';
import { reducer as businessUnits } from './businessUnits';
import { reducer as processes } from './processes';
import { reducer as technologies } from './technologies';
import { reducer as config } from './config';
import { reducer as risks } from './risks';
import { reducer as provider } from './provider';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    holders,
    iventory,
    documents,
    tickets,
    people,
    demands,
    dashboard,
    businessUnits,
    processes,
    technologies,
    risks,
    config,
    toastr,
    provider,
  });
