import React, { useMemo } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { arrayOf, objectOf, oneOfType, any, func, number } from 'prop-types';
import PageNavigator from '~/components/PageNavigator';
import {
  Container,
  Table,
  THead,
  HeadRow,
  Row,
  TH,
  TD,
  TBody,
  Sort,
  Sorted,
  SortedDesc,
  WrapperNavigator,
  TotalItems,
} from './styles';

const SecondaryTable = ({
  data,
  columns,
  onRowClick,
  pageCount,
  handlePagination,
  currentPage,
  setCurrentPage,
  total,
}) => {
  const dataMemo = useMemo(() => data, [data]);

  const columnsMemo = useMemo(() => columns, [columns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
  } = useTable(
    {
      columns: columnsMemo,
      data: dataMemo || [],
      manualPagination: true,
      pageCount,
    },
    useSortBy,
    usePagination
  );

  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TH {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <SortedDesc show={column.isSorted && column.isSortedDesc} />
                  <Sorted show={column.isSorted && !column.isSortedDesc} />
                  {!column.isSorted && !column.disableSortBy && <Sort />}
                </TH>
              ))}
            </HeadRow>
          ))}
        </THead>

        <TBody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Row
                {...row.getRowProps()}
                clickable={Boolean(onRowClick)}
                onClick={() => onRowClick?.(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                  );
                })}
              </Row>
            );
          })}
        </TBody>
      </Table>

      <WrapperNavigator>
        <TotalItems>Total: {total}</TotalItems>
        <PageNavigator
          count={pageCount}
          onChangePage={(newPage) => {
            setCurrentPage(newPage);
            handlePagination(newPage - 1);
            gotoPage(newPage);
          }}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          handlePagination={handlePagination}
        />
      </WrapperNavigator>
    </Container>
  );
};

export default SecondaryTable;

SecondaryTable.propTypes = {
  columns: arrayOf(objectOf(oneOfType([any]))).isRequired,
  data: arrayOf(objectOf(oneOfType([any]))).isRequired,
  onRowClick: func,
  pageCount: number,
  handlePagination: func,
  setCurrentPage: func,
  currentPage: number,
  total: number.isRequired,
};

SecondaryTable.defaultProps = {
  onRowClick: undefined,
  pageCount: 0,
  handlePagination: () => {},
  setCurrentPage: () => {},
  currentPage: 1,
};
