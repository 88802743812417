import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { dateSortMethod } from '~/utils';
// import { SecondaryButton } from '~/components/Button';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import TechnologyActions from '~/store/ducks/technologies';
import ActionButtonTable from '~/components/ActionButtonTable';

import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const TableComp = ({ search }) => {
  const dispatch = useDispatch();
  const { technologies } = useSelector((state) => state.technologies);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    // dispatch(ProcessActions.getProcessesRequest());

    dispatch(
      TechnologyActions.getTechnologiesRequest(currentPage - 1, 10, search)
    );
  }, [search, currentPage]);

  const handleTechnologyEdit = (id) => {
    history.push(`/assets-management/actives/${id}`);
    // dispatch(TechnologyActions.getTechnologyDetailsRequest(id));
  };

  // const reloadTechnologies = () => {
  //   dispatch(TechnologyActions.getTechnologiesRequest());
  // };

  const handleTechnologyDelete = (id) => {
    dispatch(TechnologyActions.deleteTechnologiesRequest(id));
  };

  const handlePagination = (newPage) => {
    dispatch(TechnologyActions.getTechnologiesRequest(newPage));
  };

  return (
    <Container>
      <Card padding="2rem 0">
        <Table
          columns={[
            {
              Header: i18next.t('technologiesAsset.table.name'),
              accessor: 'title',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('technologiesAsset.table.author'),
              accessor: 'User',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return `${e.cell.value.first_name} ${e.cell.value.last_name}`;
              },
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: (e) => {
                if (!e.value) {
                  return (
                    <span style={{ color: '#FF4B55' }}>
                      {i18next.t('configurations.user.desactived')}
                    </span>
                  );
                }
                return (
                  <span style={{ color: '#00BC61' }}>
                    {i18next.t('configurations.user.actived')}
                  </span>
                );
              },
            },
            {
              Header: i18next.t('technologiesAsset.table.date'),
              accessor: 'createdAt',
              Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
              sortType: (a, b) => {
                return dateSortMethod(a.values.date, b.values.date);
              },
            },
            {
              Header: i18next.t('technologiesAsset.table.action'),
              accessor: 'action',
              Cell: (e) => {
                if (e.row.original?.id) {
                  return (
                    <>
                      <ActionButtonTable
                        dataId={e.row.original?.id}
                        dispatchDelete={handleTechnologyDelete}
                      />
                    </>
                    // <SecondaryButton
                    //   onClick={() => handleTechnologyEdit(e.row.original?.id)}
                    //   label={i18next.t('technologiesAsset.table.actionButton')}
                    //   small
                    //   width="8.6rem"
                    //   padding="0"
                    // />
                  );
                }
                return null;
              },
            },
          ]}
          data={technologies.rows || []}
          onRowClick={(item) => {
            handleTechnologyEdit(item.id);
          }}
          total={technologies?.count}
          pageCount={technologies?.total_page}
          handlePagination={handlePagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Card>
    </Container>
  );
};

export default TableComp;

TableComp.defaultProps = {
  search: '',
};

TableComp.propTypes = {
  search: string,
};
