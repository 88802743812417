import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 1.2rem;
  background-color: white;
  border: 1px solid rgba(198, 204, 210, 0.3);
`;

export const WrapperInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  padding-top: 1.7rem;
  font-size: 1.4rem;
  padding-bottom: 0;
  text-align: center;
  border: none;
`;
