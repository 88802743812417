import styled from 'styled-components';
import { IconSort, IconSorted, IconSortedDesc } from '~/assets/svgs';

export const Container = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const HeadRow = styled.tr`
  height: 46px;
  padding: 0 20px;
`;

export const Row = styled.tr`
  height: 46px;
  padding: 0 20px;
  background-color: #00000000;
  position: relative;
  :hover {
    background-color: #00000007;
  }
  transition: 100ms ease;
`;

export const TH = styled.th`
  padding: 5px 5px;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.1rem;
  font-weight: 400;
  text-align: left;

  position: relative;
  :first-child {
    padding-left: 30px;
  }
  :last-child {
    padding-right: 10px;
  }
`;

export const TD = styled.td`
  padding: 5px 5px;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.2rem;
  font-weight: 400;
  white-space: nowrap;

  :first-child {
    padding-left: 30px;
  }
  :last-child {
    padding-right: 10px;
  }
`;

export const Sort = styled(IconSort)`
  fill: #979797;
  opacity: 0;
  ${TH}:hover & {
    opacity: 1;
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
  transition: 200ms ease;
`;

export const Sorted = styled(IconSorted)`
  fill: #979797;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: 200ms ease;
`;

export const SortedDesc = styled(IconSortedDesc)`
  fill: #979797;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 1rem;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: 200ms ease;
`;

export const WrapperNavigator = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
  padding: 0 30px;
`;
