import { Formik } from 'formik';
import i18next from 'i18next';
import React, { useEffect } from 'react';

import Tabs from '~/components/Tabs';
import { Grid } from '@material-ui/core';
import { PrimaryButton } from '~/components/Button';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import IventoryActions from '~/store/ducks/iventory';
import ProcessActions from '~/store/ducks/processes';
import ConfigActions from '~/store/ducks/config';
import ProcessIdentification from './ProcessIdentification';

// import validationSchema from './validationSchema';

import { Container, Footer } from './styles';
import Controls from './Controls';

const IventoryData = ({ onBack, editIventory }) => {
  const dispatch = useDispatch();

  const submit = (values) => {
    // console.log(values);
    dispatch(IventoryActions.createIventoryRequest(values));
  };

  const { iventoryDetails } = useSelector((state) => state.iventory);
  const { processesEnum } = useSelector((state) => state.processes);

  useEffect(() => {
    dispatch(ConfigActions.getDepartamentsRequest());
    dispatch(IventoryActions.getCollectionPurposeEnumRequest());
    dispatch(IventoryActions.getCollectionFormsEnumRequest());
    dispatch(IventoryActions.getCategoryOfDataCollectedEnumRequest());
    dispatch(IventoryActions.getTransparencyToTheHolderEnumRequest());
    dispatch(IventoryActions.getCollectedDataEnumRequest());
    dispatch(IventoryActions.getLegalBasisAssessmentEnumRequest());
    dispatch(IventoryActions.getProcessUpdateFrequenciesEnumRequest());
    dispatch(IventoryActions.getInOutTransmissionsEnumRequest());
    dispatch(IventoryActions.getStorageFormsEnumRequest());

    if (processesEnum.length === 0)
      dispatch(ProcessActions.getProcessesEnumRequest());

    if (editIventory && !iventoryDetails.id) {
      dispatch(IventoryActions.getIventoryDetailsRequest(editIventory));
    }
  }, []);

  console.log(iventoryDetails);
  console.log(
    iventoryDetails.process
      ? [
          ...iventoryDetails.collection_process.collection_categories?.map(
            (item) => item.category.id
          ),
        ]
      : []
  );

  return (
    <Container>
      <Formik
        initialValues={{
          processIdentification: {
            departament_id: iventoryDetails.departament?.id ?? '',
            process_id: iventoryDetails.process?.id ?? '',
            purposeOfCollection: iventoryDetails.process
              ? [
                  ...iventoryDetails.collection_process.collection_purpose?.map(
                    (item) => item.purpose.id
                  ),
                ]
              : [],
            collectionForms: iventoryDetails.process
              ? [
                  ...iventoryDetails.collection_process.collection_forms?.map(
                    (item) => item.form.id
                  ),
                ]
              : [],
            categoryOfDataCollected: iventoryDetails.process
              ? [
                  ...iventoryDetails.collection_process.collection_categories?.map(
                    (item) => item.category.id
                  ),
                ]
              : [],
            transparencyToTheHolder: iventoryDetails.process
              ? [
                  ...iventoryDetails.legal_framework.holder_transparency?.map(
                    (item) => item.transparency.id
                  ),
                ]
              : [],
            collectedData: iventoryDetails.process
              ? [
                  ...iventoryDetails.legal_framework.collected_data?.map(
                    (item) => item.data_collected.id
                  ),
                ]
              : [],
            retentionTime: iventoryDetails.process
              ? iventoryDetails.legal_framework.retention_time?.time
              : '',
            timeUnit: iventoryDetails.process
              ? iventoryDetails.legal_framework.retention_time?.unit_time
              : '',
            legalBasis: iventoryDetails.process
              ? [
                  ...iventoryDetails.legal_framework.legal_basis_assessment?.map(
                    (item) => item.assessment.id
                  ),
                ]
              : [],
            providers: [],
          },
          controls: {},
        }}
        onSubmit={submit}
        // validationSchema={validationSchema}
        enableReinitialize
      >
        {({ errors, touched, handleSubmit }) => (
          <>
            <Tabs
              sm
              tabs={[
                i18next.t('iventory.tab.processDetails.label'),
                i18next.t('iventory.tab.controls.label'),
              ]}
              tabsError={[
                !!errors.processIdentification &&
                  !!touched.processIdentification,
                !!errors.controls && !!touched.controls,
              ]}
            >
              <ProcessIdentification />
              <Controls />
            </Tabs>

            <Footer>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item xs={12} md={2} lg={2}>
                  <PrimaryButton
                    label={i18next.t('common.buttons.cancel')}
                    onClick={onBack}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <PrimaryButton
                    type="submit"
                    label={i18next.t('common.buttons.save')}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </Footer>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default IventoryData;

IventoryData.propTypes = {
  onBack: func.isRequired,
  editIventory: string.isRequired,
};
