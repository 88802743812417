import React from 'react';
import { func, string } from 'prop-types';

import i18next from 'i18next';
import { Container, Add, DropText } from './styles';

const AddButton = ({ onClick, children }) => {
  return (
    <Container onClick={onClick}>
      <Add />
      <DropText>{children}</DropText>
    </Container>
  );
};

export default AddButton;

AddButton.propTypes = {
  onClick: func.isRequired,
  children: string,
};

AddButton.defaultProps = {
  children: i18next.t('common.buttons.add'),
};
