import React from 'react';

import { Container, InfoIcon } from './styles';

const informativeAbout = ({ description }) => {
  return (
    <Container>
      <InfoIcon />
      <span>{description}</span>
    </Container>
  );
};

export default informativeAbout;
