import styled from 'styled-components';
import CustomScroll from '~/components/CustomScroll';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: #322e39;
`;

export const Scroll = styled(CustomScroll)`
  flex: 1;
  margin-top: 0rem;
`;

export const NoData = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: #322e39;
`;
