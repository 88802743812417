import React from 'react';
import { func, bool } from 'prop-types';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';

import Modal from '~/components/Modal';
import { PrimaryButton, SecondaryButton } from '~/components/Button';

import { Container, WrapperButton, Title, TitleContainer } from './styles';

function SuccessModal({ onClose, open, setEvaluationModalOpen }) {
  const history = useHistory();

  function handleCloseModal() {
    onClose();
    history.push('/demands-history');
  }

  function handleOpenEvaluationModal() {
    onClose();
    setEvaluationModalOpen(true);
  }

  return (
    <Modal
      title={i18next.t('callOpening.closeDemandModal.title')}
      open={open}
      onClose={onClose}
    >
      <Container>
        <TitleContainer>
          <Title>{i18next.t('callOpening.closeDemandModal.subtitle')}</Title>
        </TitleContainer>

        <WrapperButton>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleCloseModal()}
          />
          <PrimaryButton
            label={i18next.t('common.buttons.evaluate')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleOpenEvaluationModal()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default SuccessModal;

SuccessModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  setEvaluationModalOpen: func.isRequired,
};
