import styled from 'styled-components';

export const Container = styled.div.attrs({ layout: true })`
  width: 100%;
  max-width: 527px;
`;

export const Desciption = styled.p`
  max-width: 38.1rem;
  font-size: ${({ theme }) => theme.fontSizes.text.md};
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  line-height: 2.7rem;
  margin: 3.2rem 0;
  color: ${({ theme }) => theme.colors.cardText};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  gap: 2.4rem;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
