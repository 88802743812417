import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperArrow = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 3.8rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f8f9ff;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  border-radius: 0.5rem;
`;

export const IconBack = styled(IoIosArrowBack).attrs({ size: 15 })`
  color: ${(props) => (props.disabled ? '#AAA' : '#262A3A')};
`;

export const IconForward = styled(IoIosArrowBack).attrs({ size: 15 })`
  color: ${(props) => (props.disabled ? '#AAA' : '#262A3A')};
  transform: rotate(180deg);
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f8f9ff;
  border-radius: 5px;

  padding: 0.3rem;
  margin: 0 1.5rem;
`;

export const Page = styled.div`
  cursor: pointer;
  width: 3.3rem;
  height: 3.4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const PageText = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  z-index: 2;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

export const ActiveWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.blueSecondary};
  border-radius: 5px;
  z-index: 1;
`;
