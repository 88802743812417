import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div``;

export const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 1.6rem;
`;

export const ButtonUpload = styled.div`
  height: 3.9rem;
  padding: 2.2rem;

  background: ${({ theme }) => theme.colors.blueSecondary};
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 400;
  cursor: pointer;

  :hover {
    box-shadow: 0px 4px 10px rgba(83, 154, 218, 0.4);
    transform: scale(1.007);
  }

  transition: 300ms ease;
`;
