import styled from 'styled-components';
import { IconArrowDown } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  margin-top: 3.5rem;
  width: 100%;
`;

export const SearchWrapper = styled.div`
  width: 30.6%;
`;

export const SelectorAndDateAndUser = styled.div`
  display: flex;
`;

export const ProgressBarContainer = styled.div`
  width: 35%;
  height: 30px;
`;

export const ButtonText = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  color: #606474;
`;

export const ButtonReturn = styled.span`
  width: auto;
  align-items: center;
  /* background-color: red; */
  display: flex;
  cursor: pointer;
`;

export const Arrow = styled(IconArrowDown)`
  transform: rotate(90deg);
  margin-right: 10px;
  color: #606474;
`;
