import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  signInRequest: ['email', 'password', 'token', 'IsActiveAccount'],
  signInSuccess: ['userActive'],
  signInFailure: [''],

  deleteUserRequest: ['description', 'password'],
  deleteUserSuccess: [''],
  deleteUserFailure: [''],

  signInClientRequest: ['email', 'password', 'Redirects'],
  signInClientSuccess: ['clientActive'],
  signInClientFailure: [''],

  selectCompanyRequest: ['id', 'role'],
  selectCompanySuccess: ['id'],
  selectCompanyFailure: [''],

  createCompanyRequest: ['data', 'handleGoBack'],
  createCompanySuccess: [''],
  createCompanyFailure: [''],

  getCompaniesRequest: ['page', 'limit'],
  getCompaniesSuccess: ['companies'],
  getCompaniesFailure: [''],

  getMyCompaniesRequest: ['page', 'limit'],
  getMyCompaniesSuccess: ['myCompanies'],
  getMyCompaniesFailure: [''],

  getAssociatedCompaniesRequest: ['page', 'limit'],
  getAssociatedCompaniesSuccess: ['associatedCompanies'],
  getAssociatedCompaniesFailure: [''],

  activeCompanyRequest: ['id', 'voucher', 'callback'],
  activeCompanySuccess: [''],
  activeCompanyFailure: [''],

  forgotRequest: ['email', 'onSubmit'],
  forgotSuccess: [''],
  forgotFailure: [''],

  forgotHashValidationRequest: ['hash', 'email', 'onSubmit'],
  forgotHashValidationSuccess: [''],
  forgotHashValidationFailure: [''],

  passwordResetRequest: ['password', 'hash', 'onSubmit'],
  passwordResetSuccess: [''],
  passwordResetFailure: [''],

  signUpCompanyEmailValidationRequest: ['email', 'onSubmit'],
  signUpCompanyEmailValidationSuccess: [''],
  signUpCompanyEmailValidationFailure: [''],

  resendConfirmEmailRequest: ['email'],
  resendConfirmEmailSuccess: [''],
  resendConfirmEmailFailure: [''],

  signUpCompanyRequest: ['data', 'onSubmit'],
  signUpCompanySuccess: [''],
  signUpCompanyFailure: [''],

  signUpRequest: ['data', 'onSubmit'],
  signUpSuccess: [''],
  signUpFailure: [''],

  signUpHashValidationRequest: ['hash', 'onSubmit'],
  signUpHashValidationSuccess: [''],
  signUpHashValidationFailure: [''],

  signUpPasswordSetRequest: ['hash', 'password', 'onSubmit', 'setUserInfo'],
  signUpPasswordSetSuccess: [''],
  signUpPasswordSetFailure: [''],

  setCompanyPlanRequest: [''],
  setCompanyPlanSuccess: [''],
  setCompanyPlanFailure: [''],

  createPaymentIntentRequest: [
    'token',
    'priceId',
    'email',
    'companyId',
    'onSubmit',
  ],
  createPaymentIntentSuccess: ['paymentIntent'],
  createPaymentIntentFailure: [''],

  signUpDpoRequest: ['data', 'onSubmit'],
  signUpDpoSuccess: [''],
  signUpDpoFailure: [''],

  updateUserDataRequest: ['id', 'image', 'data'],
  updateUserDataSuccess: ['userUpdated'],
  updateUserDataFailure: [''],

  setNewClientPasswordRequest: ['data', 'onSubmit'],
  setNewClientPasswordSuccess: [''],
  setNewClientPasswordFailure: [''],

  getCompanyDemandsRequest: [''],
  getCompanyDemandsSuccess: ['demands'],
  getCompanyDemandsFailure: [''],

  activeUserInCompanyRequest: ['inviteToken'],
  activeUserInCompanySuccess: [''],
  activeUserInCompanyFailure: [''],

  switchCompanyRequest: ['companyId'],

  switchClientCompanyRequest: ['companyId'],

  reloadUserDataRequest: ['updatedUserActive'],

  signOutRequest: [''],

  clientSignOutRequest: [''],

  newCompanyRequest: ['data'],

  clearCompanyActiveRequest: [''],

  logoutCompanyRequest: [''],

  enterTheCompanyRequest: ['company', 'goDashboard'],
});

export const AuthTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  authChecked: false,
  userSignedIn: false,
  clientSignedIn: false,
  isLoading: false,
  isLoadingUser: false,
  isLoadingDeleteAccount: false,
  error: false,
  userActive: {},
  clientActive: {},
  companyId: null,
  clientCompanyId: null,
  companiesDemands: {},
  paymentIntent: {},
  companies: {},
  myCompanies: {},
  associatedCompanies: {},
  newCompany: {},
});

/* Reducers */

const signInRequest = (state) => state.merge({ isLoading: true });
const signInSuccess = (state, { userActive }) =>
  state.merge({
    userSignedIn: true,
    isLoading: false,
    userActive,
  });
const signInFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    userSignedIn: false,
    userActive: {},
    companyId: null,
  });

const signInClientRequest = (state) => state.merge({ isLoading: true });
const signInClientSuccess = (state, { clientActive }) =>
  state.merge({
    clientSignedIn: true,
    isLoading: false,
    clientActive,
  });
const signInClientFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    clientSignedIn: false,
    clientActive: {},
    clientCompanyId: null,
  });

const forgotRequest = (state) => state.merge({ isLoading: true });
const forgotSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const forgotFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const selectCompanyRequest = (state) => state.merge({ isLoading: true });
const selectCompanySuccess = (state, { id, role }) =>
  state.merge({
    isLoading: false,
    error: false,
    companyId: id,
    userActive: {
      ...state.userActive,
      user: { ...state.userActive.user, role },
    },
  });
const selectCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const forgotHashValidationRequest = (state) => state.merge({ isLoading: true });
const forgotHashValidationSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const forgotHashValidationFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const passwordResetRequest = (state) => state.merge({ isLoading: true });
const passwordResetSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const passwordResetFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signUpCompanyEmailValidationRequest = (state) =>
  state.merge({ isLoading: true });
const signUpCompanyEmailValidationSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpCompanyEmailValidationFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const resendConfirmEmailRequest = (state) => state.merge({ isLoading: true });
const resendConfirmEmailSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const resendConfirmEmailFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signUpCompanyRequest = (state) => state.merge({ isLoading: true });
const signUpCompanySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signUpRequest = (state) => state.merge({ isLoading: true });
const signUpSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signUpHashValidationRequest = (state) => state.merge({ isLoading: true });
const signUpHashValidationSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpHashValidationFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signUpPasswordSetRequest = (state) => state.merge({ isLoading: true });
const signUpPasswordSetSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpPasswordSetFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const setCompanyPlanRequest = (state) => state.merge({ isLoading: true });
const setCompanyPlanSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const setCompanyPlanFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const createPaymentIntentRequest = (state) => state.merge({ isLoading: true });
const createPaymentIntentSuccess = (state, { paymentIntent }) =>
  state.merge({
    isLoading: false,
    error: false,
    paymentIntent,
  });
const createPaymentIntentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    paymentIntent: {},
  });

const signUpDpoRequest = (state) => state.merge({ isLoading: true });
const signUpDpoSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const signUpDpoFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const setNewClientPasswordRequest = (state) => state.merge({ isLoading: true });
const setNewClientPasswordSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const setNewClientPasswordFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCompanyDemandsRequest = (state) => state.merge({ isLoading: true });
const getCompanyDemandsSuccess = (state, { demands }) =>
  state.merge({
    isLoading: false,
    error: false,
    companiesDemands: demands,
  });
const getCompanyDemandsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const signOutRequest = (state) =>
  state.merge({
    authChecked: false,
    isLoading: false,
    error: false,
    userSignedIn: false,
    userActive: {},
    companyId: null,
  });

const clientSignOutRequest = (state) =>
  state.merge({
    authChecked: false,
    isLoading: false,
    error: false,
    clientSignedIn: false,
    clientActive: {},
  });

const switchCompanyRequest = (state, { companyId }) =>
  state.merge({
    companyId,
  });

const newCompanyRequest = (state, { data }) => {
  return state.merge({
    newCompany: data.company,
  });
};

const clearCompanyActiveRequest = (state) => {
  return state.merge({
    newCompany: {},
  });
};

const switchClientCompanyRequest = (state, { companyId }) =>
  state.merge({
    clientCompanyId: companyId,
  });

const reloadUserDataRequest = (state, { updatedUserActive }) =>
  state.merge({
    userActive: updatedUserActive,
  });

const createCompanyRequest = (state) => state.merge({ isLoading: true });
const createCompanySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getCompaniesRequest = (state) => state.merge({ isLoading: true });
const getCompaniesSuccess = (state, { companies }) =>
  state.merge({
    isLoading: false,
    error: false,
    companies,
  });
const getCompaniesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    companies: {},
  });

const getMyCompaniesRequest = (state) => state.merge({ isLoading: true });
const getMyCompaniesSuccess = (state, { myCompanies }) =>
  state.merge({
    isLoading: false,
    error: false,
    myCompanies,
  });
const getMyCompaniesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    myCompanies: {},
  });

const getAssociatedCompaniesRequest = (state) =>
  state.merge({ isLoading: true });
const getAssociatedCompaniesSuccess = (state, { associatedCompanies }) =>
  state.merge({
    isLoading: false,
    error: false,
    associatedCompanies,
  });
const getAssociatedCompaniesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    myCompanies: {},
  });

const activeCompanyRequest = (state) => state.merge({ isLoading: true });
const activeCompanySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const activeCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const activeUserInCompanyRequest = (state) => state.merge({ isLoading: true });
const activeUserInCompanySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const activeUserInCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteUserRequest = (state) =>
  state.merge({ isLoadingDeleteAccount: true });

const deleteUserSuccess = (state) =>
  state.merge({
    isLoadingDeleteAccount: false,
    error: false,
    userActive: {},
  });

const deleteUserFailure = (state) =>
  state.merge({
    isLoadingDeleteAccount: false,
    error: true,
  });

const updateUserDataRequest = (state) => state.merge({ isLoadingUser: true });
const updateUserDataSuccess = (state, { userUpdated }) =>
  state.merge({
    isLoadingUser: false,
    error: false,
    userActive: {
      ...state.userActive,
      user: userUpdated,
    },
  });

const updateUserDataFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const enterTheCompanyRequest = (state, { company }) =>
  state.merge({
    userActive: {
      ...state.userActive,
      activeCompany: company,
    },
  });

const logoutCompanyRequest = (state) =>
  state.merge({
    userActive: {
      ...state.userActive,
      activeCompany: null,
      roleId: '',
    },
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CREATE_COMPANY_REQUEST]: createCompanyRequest,
  [Types.CREATE_COMPANY_SUCCESS]: createCompanySuccess,
  [Types.CREATE_COMPANY_FAILURE]: createCompanyFailure,

  [Types.DELETE_USER_REQUEST]: deleteUserRequest,
  [Types.DELETE_USER_SUCCESS]: deleteUserSuccess,
  [Types.DELETE_USER_FAILURE]: deleteUserFailure,

  [Types.GET_COMPANIES_REQUEST]: getCompaniesRequest,
  [Types.GET_COMPANIES_SUCCESS]: getCompaniesSuccess,
  [Types.GET_COMPANIES_FAILURE]: getCompaniesFailure,

  [Types.GET_MY_COMPANIES_REQUEST]: getMyCompaniesRequest,
  [Types.GET_MY_COMPANIES_SUCCESS]: getMyCompaniesSuccess,
  [Types.GET_MY_COMPANIES_FAILURE]: getMyCompaniesFailure,

  [Types.GET_ASSOCIATED_COMPANIES_REQUEST]: getAssociatedCompaniesRequest,
  [Types.GET_ASSOCIATED_COMPANIES_SUCCESS]: getAssociatedCompaniesSuccess,
  [Types.GET_ASSOCIATED_COMPANIES_FAILURE]: getAssociatedCompaniesFailure,

  [Types.ACTIVE_COMPANY_REQUEST]: activeCompanyRequest,
  [Types.ACTIVE_COMPANY_SUCCESS]: activeCompanySuccess,
  [Types.ACTIVE_COMPANY_FAILURE]: activeCompanyFailure,

  [Types.SIGN_IN_REQUEST]: signInRequest,
  [Types.SIGN_IN_SUCCESS]: signInSuccess,
  [Types.SIGN_IN_FAILURE]: signInFailure,

  [Types.UPDATE_USER_DATA_REQUEST]: updateUserDataRequest,
  [Types.UPDATE_USER_DATA_SUCCESS]: updateUserDataSuccess,
  [Types.UPDATE_USER_DATA_FAILURE]: updateUserDataFailure,

  [Types.SIGN_IN_CLIENT_REQUEST]: signInClientRequest,
  [Types.SIGN_IN_CLIENT_SUCCESS]: signInClientSuccess,
  [Types.SIGN_IN_CLIENT_FAILURE]: signInClientFailure,

  [Types.SELECT_COMPANY_REQUEST]: selectCompanyRequest,
  [Types.SELECT_COMPANY_SUCCESS]: selectCompanySuccess,
  [Types.SELECT_COMPANY_FAILURE]: selectCompanyFailure,

  [Types.FORGOT_REQUEST]: forgotRequest,
  [Types.FORGOT_SUCCESS]: forgotSuccess,
  [Types.FORGOT_FAILURE]: forgotFailure,

  [Types.FORGOT_HASH_VALIDATION_REQUEST]: forgotHashValidationRequest,
  [Types.FORGOT_HASH_VALIDATION_SUCCESS]: forgotHashValidationSuccess,
  [Types.FORGOT_HASH_VALIDATION_FAILURE]: forgotHashValidationFailure,

  [Types.PASSWORD_RESET_REQUEST]: passwordResetRequest,
  [Types.PASSWORD_RESET_SUCCESS]: passwordResetSuccess,
  [Types.PASSWORD_RESET_FAILURE]: passwordResetFailure,

  [Types.SIGN_UP_COMPANY_EMAIL_VALIDATION_REQUEST]:
    signUpCompanyEmailValidationRequest,
  [Types.SIGN_UP_COMPANY_EMAIL_VALIDATION_SUCCESS]:
    signUpCompanyEmailValidationSuccess,
  [Types.SIGN_UP_COMPANY_EMAIL_VALIDATION_FAILURE]:
    signUpCompanyEmailValidationFailure,

  [Types.RESEND_CONFIRM_EMAIL_REQUEST]: resendConfirmEmailRequest,
  [Types.RESEND_CONFIRM_EMAIL_SUCCESS]: resendConfirmEmailSuccess,
  [Types.RESEND_CONFIRM_EMAIL_FAILURE]: resendConfirmEmailFailure,

  [Types.SIGN_UP_COMPANY_REQUEST]: signUpCompanyRequest,
  [Types.SIGN_UP_COMPANY_SUCCESS]: signUpCompanySuccess,
  [Types.SIGN_UP_COMPANY_FAILURE]: signUpCompanyFailure,

  [Types.SIGN_UP_REQUEST]: signUpRequest,
  [Types.SIGN_UP_SUCCESS]: signUpSuccess,
  [Types.SIGN_UP_FAILURE]: signUpFailure,

  [Types.SIGN_UP_HASH_VALIDATION_REQUEST]: signUpHashValidationRequest,
  [Types.SIGN_UP_HASH_VALIDATION_SUCCESS]: signUpHashValidationSuccess,
  [Types.SIGN_UP_HASH_VALIDATION_FAILURE]: signUpHashValidationFailure,

  [Types.SIGN_UP_PASSWORD_SET_REQUEST]: signUpPasswordSetRequest,
  [Types.SIGN_UP_PASSWORD_SET_SUCCESS]: signUpPasswordSetSuccess,
  [Types.SIGN_UP_PASSWORD_SET_FAILURE]: signUpPasswordSetFailure,

  [Types.SET_COMPANY_PLAN_REQUEST]: setCompanyPlanRequest,
  [Types.SET_COMPANY_PLAN_SUCCESS]: setCompanyPlanSuccess,
  [Types.SET_COMPANY_PLAN_FAILURE]: setCompanyPlanFailure,

  [Types.CREATE_PAYMENT_INTENT_REQUEST]: createPaymentIntentRequest,
  [Types.CREATE_PAYMENT_INTENT_SUCCESS]: createPaymentIntentSuccess,
  [Types.CREATE_PAYMENT_INTENT_FAILURE]: createPaymentIntentFailure,

  [Types.SIGN_UP_DPO_REQUEST]: signUpDpoRequest,
  [Types.SIGN_UP_DPO_SUCCESS]: signUpDpoSuccess,
  [Types.SIGN_UP_DPO_FAILURE]: signUpDpoFailure,

  [Types.SET_NEW_CLIENT_PASSWORD_REQUEST]: setNewClientPasswordRequest,
  [Types.SET_NEW_CLIENT_PASSWORD_SUCCESS]: setNewClientPasswordSuccess,
  [Types.SET_NEW_CLIENT_PASSWORD_FAILURE]: setNewClientPasswordFailure,

  [Types.GET_COMPANY_DEMANDS_REQUEST]: getCompanyDemandsRequest,
  [Types.GET_COMPANY_DEMANDS_SUCCESS]: getCompanyDemandsSuccess,
  [Types.GET_COMPANY_DEMANDS_FAILURE]: getCompanyDemandsFailure,

  [Types.ACTIVE_USER_IN_COMPANY_REQUEST]: activeUserInCompanyRequest,
  [Types.ACTIVE_USER_IN_COMPANY_SUCCESS]: activeUserInCompanySuccess,
  [Types.ACTIVE_USER_IN_COMPANY_FAILURE]: activeUserInCompanyFailure,

  [Types.SIGN_OUT_REQUEST]: signOutRequest,

  [Types.SWITCH_COMPANY_REQUEST]: switchCompanyRequest,

  [Types.SWITCH_CLIENT_COMPANY_REQUEST]: switchClientCompanyRequest,

  [Types.RELOAD_USER_DATA_REQUEST]: reloadUserDataRequest,

  [Types.CLIENT_SIGN_OUT_REQUEST]: clientSignOutRequest,

  [Types.NEW_COMPANY_REQUEST]: newCompanyRequest,

  [Types.ENTER_THE_COMPANY_REQUEST]: enterTheCompanyRequest,

  [Types.CLEAR_COMPANY_ACTIVE_REQUEST]: clearCompanyActiveRequest,

  [Types.LOGOUT_COMPANY_REQUEST]: logoutCompanyRequest,
});
