import styled from 'styled-components';

import { IconShowPassword, IconHidePassword } from '~/assets/svgs/index';

export const PasswordWrapper = styled.div`
  position: relative;
`;

export const ShowPasswordIcon = styled(IconShowPassword)`
  position: absolute;
  top: 5rem;
  right: 1.5rem;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(IconHidePassword)`
  position: absolute;
  top: 5rem;
  right: 1.5rem;
  cursor: pointer;
`;
