import React from 'react';
import { arrayOf, objectOf, string } from 'prop-types';
import ReactSelect from 'react-select';

function Select({ options, placeholder }) {
  const colourStyles = {
    control: (styles, { isFocused }) => ({
      label: 'control',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      borderColor: isFocused ? '#C6CCD2' : 'transparent',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '1rem',
      boxShadow: null,
      cursor: 'default',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 54,
      outline: '0 !important',
      position: 'relative',
      transition: 'all 200ms',
      '&:hover': {
        borderColor: '#C6CCD2',
      },
      boxSizing: 'border-box',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#262A3A',
      '&:hover': { color: '#0E067D' },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.4rem',
      fontWeight: 400,
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: '6rem',
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: isSelected ? '#fff' : '#606474',
      fontSize: '1.4rem',
      fontWeight: isSelected ? 700 : 400,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#262A3A',
      fontSize: '1.4rem',
      fontWeight: 500,
    }),
  };

  return (
    <ReactSelect
      options={[...options]}
      placeholder={placeholder}
      styles={colourStyles}
    />
  );
}

export default Select;

Select.propTypes = {
  options: arrayOf(objectOf(string)),
  placeholder: string,
};

Select.defaultProps = {
  options: [],
  placeholder: 'Selecione',
};
