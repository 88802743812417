import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { func } from 'prop-types';
import i18next from 'i18next';

import { byteToSize } from '~/utils/formatters';

import {
  Container,
  Dropzone,
  FileIcon,
  DropText,
  WrapperFile,
  Row,
  FileName,
  FileSize,
  FileDate,
  Column,
  DeleteButton,
  DeleteIcon,
} from './styles';

const AttachFile = ({ setDocument, setError }) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setDocument(acceptedFiles[0]);
      setError(false);
    },
    [setDocument]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf',
  });

  const deleteDocument = () => {
    setFile(null);
    setDocument(null);
  };

  return (
    <Container>
      <Grid item xs>
        {file ? (
          <WrapperFile>
            <Column>
              <Row>
                <FileName>
                  {file.name.length > 20
                    ? `${file.name.slice(0, 10)}...${file.name.slice(
                        file.name.length - 10
                      )}`
                    : file.name}
                </FileName>
                <FileSize>{byteToSize(file.size)}</FileSize>
              </Row>
              <FileDate>{format(new Date(), 'dd/MM/yyyy')}</FileDate>
            </Column>
            <DeleteButton onClick={() => deleteDocument()}>
              <DeleteIcon />
            </DeleteButton>
          </WrapperFile>
        ) : (
          <Dropzone {...getRootProps()} isDragActive={isDragActive}>
            <input {...getInputProps()} multiple={false} />
            <FileIcon />
            <DropText>
              {i18next.t('documents.newDocumentModal.attachInstruction')}
            </DropText>
          </Dropzone>
        )}
      </Grid>
    </Container>
  );
};

export default AttachFile;

AttachFile.propTypes = {
  setDocument: func.isRequired,
  setError: func.isRequired,
};
