import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'styled-components';
import '~/config/ReactotronConfig';
import { PersistGate } from 'redux-persist/integration/react';
import { ptBR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import './i18n';

// import SmallFreePlanWarningModal from '~/components/FreePlanWarningModal/Small';

// import { SocketProvider } from '~/hooks/socket';
import Routes from './routes';
import store, { persistor } from './store';
import GlobalStyles from './styles/global';
import Theme from './styles/theme';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

function App() {
  const theme = createTheme(
    {
      palette: {
        primary: { main: '#195BBE' },
      },
      typography: {
        fontSize: 20,
      },
    },
    ptBR
  );

  // const [freePlanWarningOpened, setFreePlanWarningOpened] = useState(false);
  // const [isPlanWarningOpen, setIsFreePlanWarningOpen] = useState(false);

  // useEffect(() => {
  //   if (!freePlanWarningOpened) {
  //     setTimeout(() => {
  //       setIsFreePlanWarningOpen(true);
  //     }, 120000);
  //   }
  // }, []);

  // function closeFreePlanModal() {
  //   setIsFreePlanWarningOpen(false);
  //   setFreePlanWarningOpened(true);
  // }

  return (
    <Provider store={store}>
      {/* <SocketProvider> */}
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Theme>
            <Routes />
            <GlobalStyles />
            {/* {isPlanWarningOpen && (
                // eslint-disable-next-line react/jsx-no-bind
                <SmallFreePlanWarningModal onClose={closeFreePlanModal} />
              )} */}
            <ReduxToastr
              closeOnToastrClick
              position="top-right"
              preventDuplicates
              progressBar
              timeOut={4000}
            />
          </Theme>
        </ThemeProvider>
      </PersistGate>
      {/* </SocketProvider> */}
    </Provider>
  );
}

export default App;
