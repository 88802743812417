import styled from 'styled-components';

export const Container = styled.div`
  width: 60vw;
  max-width: 55rem;
  padding: 4.5rem 3.8rem;
`;

export const WrapperTop = styled.div`
  margin-bottom: 4rem;
`;

export const InputTitle = styled.span`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  gap: 15;
`;
