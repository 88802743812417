import SignInBg from './sign-in-bg.png';
import PCSLogo from './pcs-logo.png';
import PCSLogoIcon from './pcs-logoicon.png';
import PCSLogoName from './pcs-logoname.png';
import MagaluLogo from './magaluLogo.png';
import ShellLogo from './logoShell.png';
import PCSEnterprise from './pcs-enterprise.png';

export default {
  SignInBg,
  PCSLogo,
  MagaluLogo,
  ShellLogo,
  PCSLogoIcon,
  PCSLogoName,
  PCSEnterprise,
};
