import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { format } from 'date-fns';

import { dateSortMethod, formatters } from '~/utils';
import HoldersActions from '~/store/ducks/holders';
import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import SearchInput from '~/components/TextInput/Search';
import Spinner from '~/components/Spinner';
import ActionButton from './components/ActionButton';
import EditModal from './components/EditModal';

import {
  Container,
  Header,
  Title,
  HeaderActions,
  SearchWrapper,
  HeaderRight,
  DownloadButton,
  DownloadIcon,
  DownloadText,
} from './styles';

const Holders = () => {
  const dispatch = useDispatch();
  const { isLoading, holders } = useSelector((state) => state.holders);
  const searchInputRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selectedHolder, setSelectedHolder] = useState(null);

  useEffect(() => {
    dispatch(HoldersActions.getHoldersRequest());
  }, []);

  function handleDownload() {
    dispatch(HoldersActions.downloadHoldersRequest());
  }

  function handlePagination(newPage) {
    dispatch(HoldersActions.getHoldersRequest(newPage, null));
  }

  useEffect(() => {
    if (searchValue === '') {
      dispatch(HoldersActions.getHoldersRequest());
    } else {
      dispatch(HoldersActions.getHoldersRequest(null, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>{i18next.t('holders.title')}</Title>
                <HeaderActions>
                  <SearchWrapper>
                    <SearchInput
                      inputRef={searchInputRef}
                      placeholder={i18next.t('common.search')}
                      value={searchValue}
                      onChange={setSearchValue}
                      delay={1500}
                      hasDebounce
                    />
                  </SearchWrapper>
                  <HeaderRight>
                    <DownloadButton onClick={handleDownload}>
                      <DownloadIcon />
                      <DownloadText>
                        {i18next.t('common.buttons.export')}
                      </DownloadText>
                    </DownloadButton>
                    {/* <UploadButton>
                      <UploadIcon />
                    </UploadButton> */}
                  </HeaderRight>
                </HeaderActions>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Card padding="2rem 0">
                <Table
                  columns={[
                    {
                      Header: i18next.t('holders.table.id'),
                      accessor: 'code',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('holders.table.name'),
                      accessor: 'name',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('holders.table.phone'),
                      accessor: 'phone',
                      Cell: (e) =>
                        e.value ? formatters.toPhone(e.value) : <span>-</span>,
                    },
                    {
                      Header: i18next.t('holders.table.email'),
                      accessor: 'email',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('holders.table.createdAt'),
                      accessor: 'createdAt',
                      Cell: (e) =>
                        e.value ? (
                          format(new Date(e.value), 'dd/MM/yyyy')
                        ) : (
                          <span>-</span>
                        ),
                      sortType: (a, b) => {
                        return dateSortMethod(a.values.date, b.values.date);
                      },
                    },
                    {
                      Header: i18next.t('holders.table.action'),
                      accessor: 'action',
                      Cell: (e) =>
                        e.row.original?.id ? (
                          <ActionButton
                            item={e.row.original}
                            setOpenModal={setOpenModal}
                            setSelectedHolder={setSelectedHolder}
                          />
                        ) : (
                          <div />
                        ),
                    },
                  ]}
                  data={holders && holders.rows.length > 0 ? holders.rows : []}
                  total={holders?.count}
                  pageCount={holders?.total_pages}
                  handlePagination={handlePagination}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}

      <EditModal
        user={selectedHolder}
        open={!!openModal}
        onClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default Holders;
