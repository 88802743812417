import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getMainRisksRequest: ['page'],
  getMainRisksSuccess: ['mainRisks'],
  getMainRisksFailure: [''],

  getNextRevisionsRequest: [''],
  getNextRevisionsSuccess: ['nextRevisions'],
  getNextRevisionsFailure: [''],

  getPendingRevisionRequest: [''],
  getPendingRevisionSuccess: ['pendingRevision'],
  getPendingRevisionFailure: [''],

  getTotalTicketsCompletedRequest: [''],
  getTotalTicketsCompletedSuccess: [
    'openTickets',
    'answeredTickets',
    'totalHolders',
    'totalProcesses',
  ],
  getTotalTicketsCompletedFailure: [''],
});

export const DashboardTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  mainRisks: {
    count: null,
    rows: [],
  },
  nextRevisions: {},
  pendingRevision: {},
  openTickets: {},
  answeredTickets: {},
  totalHolders: {},
  totalProcesses: {},
});

/* Reducers */

const getMainRisksRequest = (state) => state.merge({ isLoading: true });
const getMainRisksSuccess = (state, { mainRisks }) =>
  state.merge({
    isLoading: false,
    error: false,
    mainRisks,
  });
const getMainRisksFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    mainRisks: {
      count: null,
      rows: [],
    },
  });

const getNextRevisionsRequest = (state) => state.merge({ isLoading: true });
const getNextRevisionsSuccess = (state, { nextRevisions }) =>
  state.merge({
    isLoading: false,
    error: false,
    nextRevisions,
  });
const getNextRevisionsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    nextRevisions: {},
  });

const getPendingRevisionRequest = (state) => state.merge({ isLoading: true });
const getPendingRevisionSuccess = (state, { pendingRevision }) =>
  state.merge({
    isLoading: false,
    error: false,
    pendingRevision,
  });
const getPendingRevisionFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    pendingRevision: {},
  });

const getTotalTicketsCompletedRequest = (state) =>
  state.merge({ isLoading: true });
const getTotalTicketsCompletedSuccess = (
  state,
  { openTickets, answeredTickets, totalHolders, totalProcesses }
) =>
  state.merge({
    isLoading: false,
    error: false,
    openTickets,
    answeredTickets,
    totalHolders,
    totalProcesses,
  });
const getTotalTicketsCompletedFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    openTickets: {},
    answeredTickets: {},
    totalHolders: {},
    totalProcesses: {},
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_MAIN_RISKS_REQUEST]: getMainRisksRequest,
  [Types.GET_MAIN_RISKS_SUCCESS]: getMainRisksSuccess,
  [Types.GET_MAIN_RISKS_FAILURE]: getMainRisksFailure,

  [Types.GET_NEXT_REVISIONS_REQUEST]: getNextRevisionsRequest,
  [Types.GET_NEXT_REVISIONS_SUCCESS]: getNextRevisionsSuccess,
  [Types.GET_NEXT_REVISIONS_FAILURE]: getNextRevisionsFailure,

  [Types.GET_PENDING_REVISION_REQUEST]: getPendingRevisionRequest,
  [Types.GET_PENDING_REVISION_SUCCESS]: getPendingRevisionSuccess,
  [Types.GET_PENDING_REVISION_FAILURE]: getPendingRevisionFailure,

  [Types.GET_TOTAL_TICKETS_COMPLETED_REQUEST]: getTotalTicketsCompletedRequest,
  [Types.GET_TOTAL_TICKETS_COMPLETED_SUCCESS]: getTotalTicketsCompletedSuccess,
  [Types.GET_TOTAL_TICKETS_COMPLETED_FAILURE]: getTotalTicketsCompletedFailure,
});
