import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TextArea from '~/components/TextArea';
import Spinner from '~/components/Spinner';
import { PrimaryButton } from '~/components/Button';
import DemandActions from '~/store/ducks/demand';
import AttachFile from './components/AttachFile';

import schema from './validationSchema';

import {
  Container,
  Header,
  Title,
  Wrapper,
  Subtitle,
  ButtonWrapper,
} from './styles';

const InteractionDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.demands);

  function handleGoBack() {
    history.goBack();
  }

  function submit(values) {
    dispatch(
      DemandActions.createNewInteractionRequest(
        history.location.state.id,
        values.interactionDescription,
        values.file,
        handleGoBack
      )
    );
  }

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Grid item xs={12}>
        <Header>
          <Title>
            Detalhes da interação - Protocolo {history.location.state.id}
          </Title>
        </Header>
      </Grid>
      <Wrapper>
        <Formik
          enableReinitialize
          initialValues={{
            interactionDescription: '',
            file: null,
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({
            values,
            touched,
            errors,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Subtitle>Criar Interação</Subtitle>
                  <TextArea
                    label="Descrição da Interação"
                    placeholder="Descrição..."
                    name="interactionDescription"
                    value={values.interactionDescription}
                    touched={touched.interactionDescription}
                    error={errors.interactionDescription}
                    onChange={handleChange}
                    editableui
                    textArea
                  />
                </Grid>
                <Grid item container xs={12} sm={4}>
                  <Grid item xs={12}>
                    <Subtitle>Anexar arquivos</Subtitle>
                  </Grid>
                  <Grid item xs={12}>
                    <AttachFile onDone={(e) => setFieldValue('file', e)} />
                  </Grid>
                </Grid>
                <ButtonWrapper>
                  <PrimaryButton label="Enviar" onClick={handleSubmit} />
                </ButtonWrapper>
              </Grid>
            </>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
};

export default InteractionDetails;
