import React from 'react';
import { Item, Minus, Title } from './styles';

const { objectOf } = require('prop-types');

function Chips({ data, removeProps }) {
  return (
    <Item>
      <Minus onClick={() => removeProps.onClick()} />
      <Title>{data.label}</Title>
    </Item>
  );
}

export default Chips;

Chips.propTypes = {
  data: objectOf.isRequired,
  removeProps: objectOf.isRequired,
};
