import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import Card from '~/components/Card';

import {
  Header,
  Title,
  DownloadButton,
  DownloadIcon,
  WrapperInfo,
  Responsible,
  CreatedAt,
} from './styles';

function Document() {
  const { revisionDocumentDetail } = useSelector((state) => state.documents);

  const url = revisionDocumentDetail?.fileUrl;

  return (
    <Card padding="0">
      <Header>
        <Title>{revisionDocumentDetail?.data?.doc?.name}</Title>
        <DownloadButton>
          <DownloadIcon />
          {i18next.t('documentReview.documentDetails.download')}
        </DownloadButton>
      </Header>
      <WrapperInfo>
        <Responsible>
          {`${i18next.t('documentReview.documentDetails.responsible')}: ${
            revisionDocumentDetail?.data?.participants[0]?.user?.first_name
          }`}
        </Responsible>

        {revisionDocumentDetail?.data ? (
          <CreatedAt>
            {i18next.t('documentReview.documentDetails.createdAt')}:{' '}
            {format(
              new Date(
                `${revisionDocumentDetail?.data?.last_version?.createdAt}`
              ),
              'dd/MM/yyyy'
            )}
          </CreatedAt>
        ) : null}
      </WrapperInfo>

      {revisionDocumentDetail?.data?.last_version?.file_extension === 'pdf' ? (
        <div>
          <iframe src={url} title="file" width="100%" height="600" />
        </div>
      ) : (
        <div>
          {/* <DocViewer
            style={{ height: 800 }}
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: `${revisionDocumentDetail?.fileUrl}`,
                fileType: `${revisionDocumentDetail?.data?.last_version?.file_extension}`,
              },
            ]}
          /> */}
        </div>
      )}
    </Card>
  );
}

export default Document;
