import React from 'react';
import { objectOf, func, bool } from 'prop-types';

import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import DocumentActions from '~/store/ducks/documents';

import { Container, WrapperButton, Title, TitleContainer } from './styles';

function ReopenModal({ document, onClose, open, reloadDocuments }) {
  const dispatch = useDispatch();

  function handleDeleteDocument() {
    dispatch(
      DocumentActions.deleteDocumentRequest(document?.id, reloadDocuments)
    );
    onClose();
  }

  return (
    <Modal title="" open={open} onClose={onClose}>
      <Container>
        <TitleContainer>
          <Title>{`${i18next.t('documents.deleteDocumentModal.question')} "${
            document?.name
          }"?`}</Title>
        </TitleContainer>

        <WrapperButton>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            padding="0 5.8rem"
            width="null"
            onClick={() => onClose()}
          />
          <PrimaryButton
            label={i18next.t('common.buttons.delete')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleDeleteDocument()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default ReopenModal;

ReopenModal.propTypes = {
  document: objectOf.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  reloadDocuments: func.isRequired,
};
