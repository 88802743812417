import React from 'react';
import { element, func, bool, string } from 'prop-types';
import { Modal } from '@material-ui/core';
import i18next from 'i18next';

import {
  Container,
  Card,
  MotionCard,
  Header,
  CloseButton,
  CloseIcon,
  BigCloseIcon,
  WrapperContent,
  CloseIconRed,
} from './styles';

function TermsModal({
  children,
  open,
  noCloseButton,
  onClose,
  noCloseText,
  title,
  noPadding,
  motion,
  color,
}) {
  const CardComp = motion ? MotionCard : Card;
  return (
    <Modal open={open}>
      <Container open={open}>
        <CardComp style={{ backgroundColor: color }}>
          {(!noCloseButton || title) && (
            <Header position={title ? 'space-between' : 'flex-end'}>
              {title}
              {!noCloseButton && (
                <>
                  {noCloseText ? (
                    <CloseIconRed onClick={onClose} />
                  ) : (
                    <CloseButton onClick={onClose}>
                      <CloseIcon />
                      {i18next.t('common.buttons.close')}
                    </CloseButton>
                  )}
                </>
              )}
              {!noCloseText && <BigCloseIcon onClick={onClose} />}
            </Header>
          )}
          <WrapperContent noPadding={noPadding}>{children}</WrapperContent>
        </CardComp>
      </Container>
    </Modal>
  );
}

export default TermsModal;

TermsModal.propTypes = {
  children: element.isRequired,
  noCloseButton: bool,
  noCloseText: bool,
  onClose: func,
  open: bool,
  title: string,
  noPadding: bool,
  motion: bool,
  color: string,
};

TermsModal.defaultProps = {
  onClose: () => {},
  noCloseButton: false,
  noCloseText: false,
  open: false,
  title: '',
  noPadding: false,
  motion: false,
  color: '',
};
