import React, { useCallback, useRef, useState } from 'react';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import TermsModal from '~/components/Modal';
import { func, bool, string } from 'prop-types';
import i18next from 'i18next';

import { PrimaryButton } from '~/components/Button';
import {
  Button,
  CropperContainer,
  IconMinus,
  IconPlus,
  IconRoteteLeft,
  IconRoteteRight,
  OptionsWrapper,
  RotateOptions,
  ZoomOptions,
} from './styles';
import getImageCrop from '../../utils/ImageCrop';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function ImageCrop({
  isOpen,
  onClose,
  title,
  fileName,
  nonCroppedImage,
  setImage,
}) {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState(null);
  const imgRef = useRef(null);
  const [size, setSize] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const handleCloseModal = () => {
    setScale(1);
    setRotate(0);
    onClose();
  };

  const handlePlusScale = () => {
    setScale(scale + 0.1);
  };

  const handleMinusScale = () => {
    setScale(scale - 0.1);
  };

  const handleRotateRight = () => {
    setRotate(Math.min(180, Math.max(-180, Number(rotate + 1))));
  };

  const handleRotateLeft = () => {
    setRotate(Math.min(180, Math.max(-180, Number(rotate - 1))));
  };

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setSize({ width, height });
    setCrop(centerAspectCrop(width, height, 4 / 3));
  }

  const handleCreateCroppedImage = useCallback(async () => {
    try {
      const newCroppedImage = await getImageCrop(
        nonCroppedImage,
        croppedAreaPixels,
        size,
        scale,
        rotate
      );
      setImage(
        new File([newCroppedImage], `${fileName}-${new Date().getTime()}`, {
          type: 'image/jpeg',
        })
      );
    } catch (e) {
      throw new Error(e);
    }
  }, [croppedAreaPixels, scale, rotate]);

  const handleSave = async () => {
    await handleCreateCroppedImage();
    handleCloseModal();
  };

  return (
    <TermsModal
      open={isOpen}
      title={title}
      onClose={handleCloseModal}
      noCloseText
    >
      <CropperContainer>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCroppedAreaPixels(c)}
          // onLoad={(c) => setCroppedAreaPixels(c)}
          aspect={4 / 3}
        >
          <img
            ref={imgRef}
            src={nonCroppedImage}
            alt="Cropp"
            onLoad={onImageLoad}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
          />
        </ReactCrop>
        <OptionsWrapper>
          <RotateOptions>
            <Button onClick={handleRotateLeft}>
              <IconRoteteLeft />
            </Button>
            <Button onClick={handleRotateRight}>
              <IconRoteteRight />
            </Button>
          </RotateOptions>
          <ZoomOptions>
            <Button onClick={handlePlusScale}>
              <IconPlus />
            </Button>
            <Button onClick={handleMinusScale}>
              <IconMinus />
            </Button>
          </ZoomOptions>
        </OptionsWrapper>
        <PrimaryButton
          label={i18next.t('common.buttons.save')}
          onClick={handleSave}
        />
      </CropperContainer>
    </TermsModal>
  );
}

export default ImageCrop;

ImageCrop.propTypes = {
  onClose: func,
  isOpen: bool,
  title: string,
  nonCroppedImage: string,
  fileName: string,
  setImage: func,
};

ImageCrop.defaultProps = {
  onClose: null,
  setImage: null,
  isOpen: false,
  title: '',
  fileName: '',
  nonCroppedImage: '',
};
