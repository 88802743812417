import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import AuthActions from '~/store/ducks/auth';
import history from '~/routes/history';
import { IconProfilePlaceholder } from '~/assets/icons';
import i18next from 'i18next';
import {
  Container,
  WrapperContent,
  UserImage,
  Name,
  ArrowDown,
  ArrowUp,
  Row,
} from './styles';

function ProfileMenu() {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSignOut() {
    dispatch(AuthActions.signOutRequest());
    history.push('/');
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleToConfig = () => {
    history.push('/configs/user');
  };

  return (
    <Container>
      <WrapperContent active onClick={handleClick}>
        <UserImage
          src={
            userActive?.user.avatar
              ? `${
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                    : process.env.REACT_APP_API_PROD_ENDPOINT
                }/public/image?image=${userActive.user.avatar}`
              : IconProfilePlaceholder
          }
        />
        <Name>{userActive.user?.first_name}</Name>
        {!open ? <ArrowDown /> : <ArrowUp />}
      </WrapperContent>

      <Popover
        id={id}
        PaperProps={{
          style: {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '0 0 10px 10px',
            zIndex: 0,
            width: '23rem',
            paddingVertical: '0.8rem',
          },
        }}
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={handleToConfig}>{i18next.t('menu.config')}</Row>
        <Row onClick={() => handleSignOut()}>
          {i18next.t('privateLayout.header.logouTofAccount')}
        </Row>
      </Popover>
    </Container>
  );
}

export default ProfileMenu;
