import styled from 'styled-components';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blue};

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const Subtitle = styled.h1`
  color: ${({ theme }) => theme.colors.base[400]};

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 2rem;
`;
