import React from 'react';
import { format } from 'date-fns';
import { number, string, instanceOf } from 'prop-types';

import { Column } from '~/styles/components';
import ActionButton from './components/ActionButton';

import { Container, DateText, Type } from './styles';

function ReviewItem({ id, name, date }) {
  return (
    <Container>
      <Column justify="center">
        <DateText>Data: {format(new Date(date), 'dd/MM/yyyy')}</DateText>
        <Type>{name}</Type>
      </Column>
      <ActionButton itemId={id} />
    </Container>
  );
}

export default ReviewItem;

ReviewItem.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  date: instanceOf(Date).isRequired,
};
