import styled from 'styled-components';
import { IconMosaic } from '~/assets/svgs';

export const WrapperGreetings = styled.div`
  h1 {
    color: ${({ theme }) => theme.colors.base[500]};
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 3.6rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  h3 {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.1rem;
    max-width: 35.8rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
`;

export const WrapperMosaic = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #f7f7f7;

  border-radius: 5px;

  ${({ theme }) => theme.clickable}
`;

export const MosaicButton = styled(IconMosaic)``;
