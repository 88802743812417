import { func, string } from 'prop-types';
import React from 'react';
import { boolean } from 'yup';
import { Action, CloseIcon, Container, Message, Title } from './styles';

export function MessageBox({
  isOpen,
  title,
  message,
  linkText,
  action,
  onClose,
}) {
  const handleAction = () => {
    action();
    onClose();
  };

  if (!isOpen) return <></>;
  return (
    <Container>
      <CloseIcon onClick={() => onClose(false)} />
      <Title>{title}</Title>
      <Message>{message}</Message>
      <Action onClick={handleAction}>{linkText}</Action>
    </Container>
  );
}

MessageBox.propTypes = {
  isOpen: boolean.isRequired,
  title: string.isRequired,
  linkText: string.isRequired,
  message: string.isRequired,
  onClose: func.isRequired,
  action: func.isRequired,
};
