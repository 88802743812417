import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { bool, func } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import history from '~/routes/history';
// import DateViewer from '~/components/DateViewer';

import AuthActions from '~/store/ducks/auth';
// import LanguageSelector from '~/components/LanguageSelector';
// import ChatNotification from '~/components/ChatNotification';
import Images from '~/assets/images';
import i18next from 'i18next';
import ProfileMenu from '~/components/ProfileMenu';
import {
  Container,
  WrapperBack,
  BackIcon,
  BackText,
  WrapperMenu,
  PCSLogo,
  IconMenu,
  Hamburger,
  LogOutIcon,
  LogOutButton,
  LogOutText,
  ClientMenuWrapper,
  MenuWrapper,
} from './styles';

export default function Header({
  backButton,
  drawerOpened,
  setDrawerOpened,
  noDrawer,
}) {
  const dispatch = useDispatch();
  const { clientActive } = useSelector((state) => state.auth);

  function handleClientSignOut() {
    dispatch(AuthActions.clientSignOutRequest());
    history.push('/login');
  }

  return (
    <Container noDrawer={noDrawer}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Hidden lgUp>
          <Grid item xs="auto">
            <WrapperMenu>
              <IconMenu onClick={() => setDrawerOpened(true)}>
                <Hamburger open={drawerOpened} />
              </IconMenu>
            </WrapperMenu>
          </Grid>
        </Hidden>
        {backButton && (
          <Grid item xs="auto">
            <WrapperBack onClick={() => history.goBack()}>
              <BackIcon />
              <BackText>Voltar</BackText>
            </WrapperBack>
          </Grid>
        )}
        {clientActive?.token !== undefined ? (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              <PCSLogo src={Images.PCSLogoIcon} />
              <PCSLogo src={Images.PCSLogoName} />
            </Grid>
            <Grid item align="right" direction="row">
              <ClientMenuWrapper>
                {/* <DateViewer /> */}
                <LogOutButton onClick={() => handleClientSignOut()}>
                  <LogOutIcon />
                  <LogOutText>
                    {i18next.t('privateLayout.header.logouTofAccount')}
                  </LogOutText>
                </LogOutButton>
              </ClientMenuWrapper>
            </Grid>
          </Grid>
        ) : (
          <>
            <MenuWrapper backButton={backButton}>
              {/* <DateViewer /> */}
              {/* <LanguageSelector privateLayout /> */}
              {/* <ChatNotification />  */}
              <ProfileMenu />
            </MenuWrapper>
          </>
        )}
      </Grid>
    </Container>
  );
}

Header.propTypes = {
  backButton: bool,
  drawerOpened: bool.isRequired,
  setDrawerOpened: func.isRequired,
  noDrawer: bool.isRequired,
};

Header.defaultProps = {
  backButton: false,
};
