import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Images from '~/assets/images/';

import SignInForm from './Form';

import { Container, WrapperSignIn, WrapperImage, Image } from './styles';

function SignInClient() {
  return (
    <Container>
      <AnimatePresence>
        <WrapperSignIn layoutId="sign_in_form">
          <SignInForm />
        </WrapperSignIn>
        <WrapperImage layoutId="image">
          <Image src={Images.SignInBg} alt="bg" />
        </WrapperImage>
      </AnimatePresence>
    </Container>
  );
}

export default SignInClient;
