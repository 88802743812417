import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin: 2.4rem 0;
`;

export const Hr = styled.hr`
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin-top: 6.9rem;
  width: 100%;
`;
