import React, { useEffect, useRef, useState } from 'react';
import i18next from 'i18next';

import { Grid } from '@material-ui/core';
import SearchInput from '~/components/TextInput/Search';
import { TertiaryButton } from '~/components/Button';
import Card from '~/components/Card';
import { format } from 'date-fns';
import { dateSortMethod } from '~/utils';
import Table from '~/components/Table/Secondary';
import { func } from 'prop-types';
// import TecnologyActions from '~/store/ducks/technologies';

import IventoryActions from '~/store/ducks/iventory';

import { useDispatch, useSelector } from 'react-redux';
// import ActionButtonTable from './components/ActionButtonTable';
import { Container, Header, IconPlus } from './styles';

const IventoryList = ({ handleSwitchNewIventory, setEditableIventory }) => {
  const searchInputRef = useRef(null);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const limit = 10;

  const handlePagination = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handleEdit = (id) => {
    handleSwitchNewIventory();
    setEditableIventory(id);
  };

  const { iventories } = useSelector((state) => state.iventory);

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(
      IventoryActions.getIventoriesRequest(currentPage, limit, searchValue)
    );
  }, [currentPage, searchValue]);

  // function handleDelete(event) {
  //   dispatch(IventoryActions.deleteIventoryRequest(event.row.original?.id));
  // }

  return (
    <Container>
      <Header>
        <SearchInput
          inputRef={searchInputRef}
          placeholder={i18next.t('common.search')}
          value={searchValue}
          onChange={setSearchValue}
          delay={1000}
          hasDebounce
        />
        <TertiaryButton
          onClick={handleSwitchNewIventory}
          label={i18next.t('iventory.new')}
          leftIcon={<IconPlus />}
        />
      </Header>

      <Grid item xs={12}>
        <Card padding="2rem 0">
          <Table
            columns={[
              {
                Header: i18next.t('iventory.table.department'),
                accessor: 'departament.name',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('iventory.table.process'),
                accessor: 'process.title',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('iventory.table.responsible'),
                accessor: 'author.first_name',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('iventory.table.lastModified'),
                accessor: 'createdAt',
                Cell: (e) => format(new Date(e.value), 'dd/MM/yyyy'),
                sortType: (a, b) => {
                  return dateSortMethod(a.values.date, b.values.date);
                },
              },
              // {
              //   Header: i18next.t('iventory.table.actions.label'),
              //   accessor: 'action',
              //   Cell: (e) => {
              //     if (e) {
              //       return (
              //         <>
              //           <ActionButtonTable
              //           // handleEdit={() => handleEdit(e)}
              //           // handleDelete={() => {}}
              //           // handleShow={() => {}}
              //           // handleExport={() => {}}
              //           />
              //         </>
              //       );
              //     }
              //     return null;
              //   },
              // },
            ]}
            // onRowClick={(original) => {
            //   console.log(original);
            // }}
            data={iventories.rows || []}
            onRowClick={(item) => {
              handleEdit(item.id);
            }}
            total={iventories?.count}
            pageCount={iventories?.total_page}
            setCurrentPage={setCurrentPage}
            handlePagination={handlePagination}
            currentPage={currentPage + 1}
          />
        </Card>
      </Grid>
    </Container>
  );
};

export default IventoryList;

IventoryList.propTypes = {
  handleSwitchNewIventory: func.isRequired,
  setEditableIventory: func.isRequired,
};
