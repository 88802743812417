import React, { useState, useEffect, useRef } from 'react';
import { element, bool } from 'prop-types';
import { useLocation } from 'react-router-dom';
import history from '~/routes/history';
import AuthActions from '~/store/ducks/auth';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { Sessions } from './sessions';
import AccordionItem from './components/AccordionItem';
import Header from './components/Header';

import {
  Container,
  WrapperContent,
  PaddingTop,
  Drawer,
  Scroll,
  LogoRow,
  Logo,
  ContainerItem,
  WrapperItem,
  Indicator,
  Arrow,
  ButtonBack,
  Content,
  FooterDrawer,
  LogOutIcon,
  LogoutCompanyButton,
  LogoutLabel,
} from './styles';

export default function PrivateLayout({ children, noDrawer, backButton }) {
  const { pathname } = useLocation();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const [sessionMenu, setSessionMenu] = useState([]);

  const dispatch = useDispatch();
  const session = Sessions();

  useEffect(() => {
    setSessionMenu(session);
  }, [session]);

  const handleLoggoutCompany = () => {
    dispatch(AuthActions.logoutCompanyRequest());
  };

  const menuDrawer = useRef();

  const handleClick = (path) => {
    history.push(path);
  };

  useEffect(() => {
    const catchElement = document.querySelector('#menu-drawer');
    catchElement.addEventListener('click', function (event) {
      if (event.target.id !== 'menu-drawer') {
        setDrawerOpened(false);
      }
    });
  }, []);

  const show = () => {
    setDrawerOpened(true);
  };

  const hide = () => {
    setDrawerOpened(false);
  };

  return (
    <Content>
      {drawerOpened && !noDrawer ? (
        <ButtonBack onClick={hide}>
          <Arrow />
        </ButtonBack>
      ) : null}

      <Container id="menu-drawer" ref={menuDrawer}>
        <Drawer
          onClick={() => show('drawer')}
          drawerOpened={drawerOpened}
          noDrawer={noDrawer}
        >
          <Scroll
            options={{
              maxScrollbarLength: 0.1,
              suppressScrollX: true,
            }}
          >
            <LogoRow>
              <Logo />
            </LogoRow>
            {sessionMenu.map((item) =>
              item.accordion ? (
                <AccordionItem
                  data={item}
                  onClick={handleClick}
                  active={
                    item.accordion_items.findIndex((accordionItem) =>
                      accordionItem.path.includes(pathname)
                    ) !== -1
                  }
                />
              ) : (
                <ContainerItem>
                  <WrapperItem
                    active={item.path === pathname}
                    onClick={() => {
                      handleClick(item.path);
                    }}
                  >
                    <item.icon active={item.path === pathname} />
                    <p>{item.name}</p>
                  </WrapperItem>
                  {item.path === pathname && (
                    <Indicator
                      layoutId="drawerIndicator"
                      initial={false}
                      transition={{
                        duration: 100,
                        type: 'spring',
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </ContainerItem>
              )
            )}
          </Scroll>
          <FooterDrawer>
            <LogoutCompanyButton onClick={handleLoggoutCompany}>
              <LogOutIcon />
              <LogoutLabel>
                {i18next.t('privateLayout.logoutCompany')}
              </LogoutLabel>
            </LogoutCompanyButton>
          </FooterDrawer>
        </Drawer>

        <WrapperContent noDrawer={noDrawer}>
          <Header
            backButton={backButton}
            drawerOpened={drawerOpened}
            setDrawerOpened={setDrawerOpened}
            noDrawer={noDrawer}
          />
          <PaddingTop />
          {children}
        </WrapperContent>
      </Container>
    </Content>
  );
}

PrivateLayout.propTypes = {
  children: element.isRequired,
  noDrawer: bool,
  backButton: bool,
};

PrivateLayout.defaultProps = {
  noDrawer: false,
  backButton: false,
};
