import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any } from 'prop-types';
import i18next from 'i18next';
import PeopleActions from '~/store/ducks/people';
import ExclusionModal from '../../../../../components/ModalWithButtons';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  DeleteIcon,
  Content,
} from './styles';

function ActionButton({ item }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  function reloadPeople() {
    dispatch(PeopleActions.getPeopleRequest(String(0), ''));
  }

  const handleDeletePerson = () => {
    dispatch(PeopleActions.deletePersonRequest(item.id, reloadPeople));
    setDeleteUserModal(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container onClick={handleClick}>
      <Content>
        <MoreIcon />
      </Content>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={() => setDeleteUserModal(true)}>
          <WrapperIcon>
            <DeleteIcon />
          </WrapperIcon>
          {i18next.t('peopleAsset.table.action.delete')}
        </Row>
      </Popover>
      <ExclusionModal
        open={!!deleteUserModal}
        title={i18next.t('peopleAsset.modalExclusion.title')}
        description={i18next.t('peopleAsset.modalExclusion.description')}
        Button1Text={i18next.t('peopleAsset.modalExclusion.cancelButton')}
        Button2Text={i18next.t('peopleAsset.modalExclusion.deleteButton')}
        Button1Function={() => setDeleteUserModal(false)}
        Button2Function={() => handleDeletePerson()}
        noCloseText
        onClose={() => setDeleteUserModal(false)}
      />
    </Container>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
};
