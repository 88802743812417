import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

import i18next from 'i18next';
import { dateSortMethod } from '~/utils';
import ActionButtonTable from '~/components/ActionButtonTable';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import BusinessUnitActions from '~/store/ducks/businessUnits';

import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Spinner from '~/components/Spinner';
import { Container } from './styles';

const TableComp = ({ searchValue }) => {
  const { userActive } = useSelector((state) => state.auth);
  const history = useHistory();
  const { businessUnits, isLoading } = useSelector(
    (state) => state.businessUnits
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      BusinessUnitActions.getBusinessUnitsRequest(
        userActive.activeCompany.id,
        currentPage - 1,
        10,
        searchValue
      )
    );
  }, [searchValue, currentPage]);

  const handleBusinessUnitLoad = (id) => {
    history.push(`/assets-management/business-units/${id}`);
  };

  const handleDeleteBusinessUnitsRequest = (id) => {
    dispatch(BusinessUnitActions.deleteBusinessUnitsRequest(id));
  };

  const handlePagination = (newPage) => {
    dispatch(BusinessUnitActions.getBusinessUnitsRequest(newPage));
  };

  if (isLoading) {
    return <Spinner loading />;
  }

  return (
    <Container>
      <Card padding="2rem 0">
        <Table
          columns={[
            {
              Header: i18next.t('businessUnitAsset.table.name'),
              accessor: 'title',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('businessUnitAsset.table.author'),
              accessor: 'author.first_name',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('businessUnitAsset.table.date'),
              accessor: 'createdAt',
              Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
              sortType: (a, b) => {
                return dateSortMethod(a.values.date, b.values.date);
              },
            },
            {
              Header: i18next.t('businessUnitAsset.table.action'),
              accessor: 'action',
              Cell: (e) => {
                if (e.row.original.id) {
                  return (
                    <>
                      <ActionButtonTable
                        dataId={e.row.original?.id}
                        dispatchDelete={handleDeleteBusinessUnitsRequest}
                      />
                      {/* <SecondaryButton
                        onClick={() => handleBusinessUnitLoad(e.row.original?.id)}
                        label={i18next.t('businessUnitAsset.table.actionButton')}
                        small
                        width="8.6rem"
                        padding="0"
                      /> */}
                    </>
                  );
                }
                return null;
              },
            },
          ]}
          data={
            businessUnits?.rows && businessUnits.rows.length > 0
              ? businessUnits.rows
              : []
          }
          onRowClick={(item) => {
            handleBusinessUnitLoad(item.id);
          }}
          total={businessUnits?.count}
          pageCount={businessUnits?.total_pages}
          handlePagination={handlePagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Card>
    </Container>
  );
};

export default TableComp;

TableComp.defaultProps = {
  searchValue: '',
};

TableComp.propTypes = {
  searchValue: string,
};
