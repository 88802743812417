import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  background: ${({ color }) => color};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 2.5rem;
  width: 7.7rem;

  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
`;
