import React from 'react';
import { bool } from 'prop-types';

import { Container } from './styles';

function Spinner({ loading }) {
  return (
    <>
      {loading ? (
        <Container>
          <div className="loader" />
        </Container>
      ) : (
        <div />
      )}
    </>
  );
}

export default Spinner;

Spinner.propTypes = {
  loading: bool.isRequired,
};
