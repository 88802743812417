import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { func, objectOf, oneOfType, any } from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';

import TextInput from '~/components/TextInput';
import { PrimaryButton, SecondaryButton } from '~/components/Button';

import schema from './validationSchema';

import { Container, WrapperBottom, Divider } from './styles';

function EditFolderNameModal({ folder, onClose }) {
  const dispatch = useDispatch();

  function reloadFolders() {
    dispatch(DocumentActions.getFoldersRequest());
  }

  const submit = useCallback((values) => {
    dispatch(
      DocumentActions.editFolderNameRequest(
        folder.id,
        values.name,
        reloadFolders
      )
    );
    onClose();
  }, []);

  return (
    <Container>
      <Formik
        initialValues={{
          name: folder?.name ? folder.name : '',
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <TextInput
              label={i18next.t('documents.newFolderName')}
              placeholder="Ex: documentação"
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
            <WrapperBottom>
              <SecondaryButton
                type="secondary"
                label={i18next.t('common.buttons.cancel')}
                padding="0"
                onClick={onClose}
              />
              <Divider />
              <PrimaryButton
                label={i18next.t('common.buttons.save')}
                padding="0"
                onClick={handleSubmit}
              />
            </WrapperBottom>
          </>
        )}
      </Formik>
    </Container>
  );
}

export default EditFolderNameModal;

EditFolderNameModal.propTypes = {
  folder: objectOf(oneOfType([any])).isRequired,
  onClose: func.isRequired,
};
