import { CgSpinnerTwo } from 'react-icons/cg';
import { FiCopy } from 'react-icons/fi';
import styled, { css, keyframes } from 'styled-components';
import { IconImage } from '~/assets/svgs';

export const Container = styled.div`
  background-color: #fff;
  padding: 3.3rem 6.3rem;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin: 2.2rem 0;
`;

export const TopWrapper = styled.div`
  display: flex;
  gap: 2rem;
`;

export const ColorTitle = styled.span`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: normal;
`;

export const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputColorCintainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 3.3rem;
  height: 3.3rem;
  margin: 1rem;
  border: 1px solid #ebebeb;
  border-radius: 5px;
`;

export const InputColor = styled.input`
  cursor: pointer;
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
`;

export const DropzoneAvatar = styled.div`
  width: 8.2rem;
  height: 8.2rem;
  /* margin: 0 auto; */
  background: #fafbfb;
  border-radius: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-bottom: 2.1rem;
  cursor: pointer;
  overflow: hidden;

  text-align: center;
  color: #606474;
  font-weight: 400;
  font-size: 1.8rem;

  ${({ hasImage }) =>
    hasImage &&
    css`
      box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
    `};

  ${({ isDragActive, isDragReject }) =>
    isDragActive &&
    !isDragReject &&
    css`
      transform: scale(0.98);
      -webkit-box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
      box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
      border-color: #0e067d;
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      border-color: #e74c3c;
    `};

  transition: 200ms ease;
`;

export const ImageIcon = styled(IconImage)``;

export const Image = styled.img`
  border-radius: 7px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Instructions = styled.span`
  margin-top: 1rem;
  color: #c6ccd2;
  font-weight: 500;
  font-size: 1rem;
`;

export const Padding = styled.div`
  margin-top: 4rem;

  @media (min-width: 800px) {
    padding: 0 3.8rem;
  }
`;

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  `;

export const SppinerIcon = styled(CgSpinnerTwo).attrs({
  size: 24,
  color: '#ffff',
})`
  animation: ${rotate} 2s linear infinite;
`;

export const CopyButton = styled.div`
  max-width: 28%;
  display: flex;
  border-radius: 5px;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin: 1rem;
  background-color: ${({ active }) =>
    active ? 'rgba(92, 238, 167, 0.15)' : '#f0f0f0'};

  color: ${({ active }) => (active ? 'rgba(92, 238, 167, 1)' : '#606474')};
  border: 1px solid
    ${({ active }) => (active ? 'rgba(92, 238, 167, 1)' : 'transparent')};
  font-size: 1.2rem;

  margin-bottom: 2rem;
  cursor: pointer;

  /* &:hover {
    border: 1px solid #c6ccd2;
  } */
`;

export const CopyIcon = styled(FiCopy)`
  &:hover {
    color: '#ffff';
  }
`;
