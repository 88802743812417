import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { formatters } from '~/utils';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import ConfigActions from '~/store/ducks/config';
import { IconProfilePlaceholder } from '~/assets/icons';
import ActionButton from '../ActionButton';
import { Container, UserImage } from './styles';
import EditModal from '../EditModal';

const TableComp = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { listUsers } = useSelector((state) => state.config);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [itemEdit, setItemEdit] = useState();

  const handlePagination = (activePage) => {
    dispatch(ConfigActions.getUsersCompanyRequest(activePage));
  };

  useEffect(() => {
    dispatch(ConfigActions.getUsersCompanyRequest(currentPage - 1));
  }, [dispatch]);

  const getDetails = (item) => {
    setItemEdit(item);
    setOpenEditModal(true);
  };

  return (
    <>
      <Container>
        <Card padding="2rem 0">
          <Table
            columns={[
              {
                Header: '',
                accessor: 'user.avatar[0].url',
                Cell: (e) => {
                  return (
                    <UserImage
                      src={
                        e.value
                          ? `${
                              process.env.NODE_ENV === 'development'
                                ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                                : process.env.REACT_APP_API_PROD_ENDPOINT
                            }/public/image?image=${e.value}`
                          : IconProfilePlaceholder
                      }
                    />
                  );
                },
              },
              {
                Header: i18next.t('configurations.user.nameLabel'),
                accessor: 'user.first_name',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('configurations.user.phoneLabel'),
                accessor: 'user.phone',
                Cell: (e) => formatters.toPhone(e.value),
              },
              {
                Header: i18next.t('configurations.user.roleLabel'),
                accessor: 'role.type',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  switch (e.value) {
                    case 'ADMIN':
                      return i18next.t('common.roles.administrator');
                    case 'GED':
                      return i18next.t('common.roles.gedReviewer');
                    case 'TREATMENT_AGENT':
                      return i18next.t('common.roles.internTreatmentAgent');
                    case 'DPO':
                      return i18next.t('common.roles.dpo');
                    case 'PAYING_ADMIN':
                      return i18next.t('common.roles.payingAdmin');
                    case 'OWNER':
                      return i18next.t('common.roles.owner');
                    default:
                      return '-';
                  }
                },
              },
              {
                Header: i18next.t('configurations.user.emailLabel'),
                accessor: 'user.email',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },

              {
                Header: 'Status',
                accessor: 'status',
                Cell: (e) => {
                  if (!e.value) {
                    return (
                      <span style={{ color: '#FF4B55' }}>
                        {i18next.t('configurations.user.desactived')}
                      </span>
                    );
                  }
                  return (
                    <span style={{ color: '#00BC61' }}>
                      {i18next.t('configurations.user.actived')}
                    </span>
                  );
                },
              },
              {
                Header: i18next.t('peopleAsset.table.action.label'),
                accessor: 'action',
                Cell: (e) => {
                  if (!e.value) {
                    return <ActionButton item={e.row.original} />;
                  }
                  return null;
                },
              },
            ]}
            data={listUsers && listUsers?.count > 0 ? listUsers?.rows : []}
            onRowClick={(item) => {
              getDetails(item);
            }}
            total={listUsers?.count}
            pageCount={listUsers?.total_pages}
            handlePagination={handlePagination}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Card>
      </Container>
      <EditModal
        open={!!openEditModal}
        item={itemEdit}
        onClose={() => setOpenEditModal(false)}
      />
    </>
  );
};

export default TableComp;
