import styled from 'styled-components';

import { IconCheck } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const CheckIcon = styled(IconCheck)`
  width: 8rem;
  height: 8rem;
`;

export const EmailSentText = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  margin-top: 4rem;
`;

export const CodeInstructionText = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2rem;
  text-align: center;

  margin-bottom: 4.5rem;
  margin-top: 1rem;
`;

export const WrapperValidation = styled.div`
  width: 100%;
  margin-bottom: 5.6rem;
`;
