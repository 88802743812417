import styled from 'styled-components';

export const Container = styled.div`
  width: 527px;
  padding: 4.5rem 3.8rem;
  display: grid;
`;

export const Title = styled.span`
  font-size: 36px;
  font-weight: 700;
  line-height: 54px;
  color: white;
  text-align: center;
`;

export const Subtitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 28.2px;
  color: white;
  text-align: center;
  margin: 15px;
`;

export const Description = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: white;
`;

export const ButtonsArea = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  margin-top: 10%;
`;
