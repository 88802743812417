import { FaWindowClose } from 'react-icons/fa';

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.base[200]};
  border-radius: 0.5rem;
  padding: 2rem;
  position: relative;
  margin-bottom: 2rem;
`;

export const Title = styled.h3`
  font-style: normal;
  color: #262a3a;
  font-weight: 500;
  font-size: 1.3rem;
`;

export const Message = styled.span`
  font-style: normal;
  color: #262a3a;
  font-weight: 400;
  line-height: 20px;
  font-size: 1.3rem;
`;

export const CloseIcon = styled(FaWindowClose).attrs({
  size: '12px',
  color: '#D80027',
})`
  right: 1rem;
  top: 1rem;
  position: absolute;
  cursor: pointer;
  :hover {
    transform: scale(0.9);
  }
`;

export const Action = styled.a`
  right: 1.2rem;
  bottom: 1rem;
  color: #0e067d;
  font-weight: 500;
  font-size: 1.3rem;
  position: absolute;
  cursor: pointer;

  transition: transform 0.3s;

  :hover {
    transform: scale(1.04);
  }
`;
