import React from 'react';
import i18next from 'i18next';

import Card from '~/components/Card';

import { Container, Title, WrapperContent, Row, Tag } from './styles';

function Captions() {
  return (
    <Card padding="1.6rem 10%">
      <Container>
        <Title>{i18next.t('risks.captions.title')}</Title>
        <WrapperContent>
          <Row>
            <Tag color="#E7E6F2" />
            {i18next.t('risks.captions.catastrophic')}
          </Row>
          <Row>
            <Tag color="#FFEAE5" />
            {i18next.t('risks.captions.high')}
          </Row>
          <Row>
            <Tag color="#FFF9E5" />
            {i18next.t('risks.captions.moderate')}
          </Row>
          <Row>
            <Tag color="#E6F8EB" />
            {i18next.t('risks.captions.possible')}
          </Row>
          <Row>
            <Tag color="#E8F7FE" />
            {i18next.t('risks.captions.insignificant')}
          </Row>
        </WrapperContent>
      </Container>
    </Card>
  );
}

export default Captions;
