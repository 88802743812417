import { AiOutlinePlusCircle } from 'react-icons/ai';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 1.5rem 0 1.7rem;
  display: flex;
  justify-content: space-between;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;
