import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { arrayOf, func } from 'prop-types';
import { useSelector } from 'react-redux';
import { SecondarySelect } from '~/components/Select';
import { TertiaryButton } from '~/components/Button';

import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  DeleteButton,
  DeleteIcon,
  WrapperAddButton,
} from './styles';

function Actives({ actives, setActives, setDeletedActives }) {
  // const dispatch = useDispatch();
  const { processesEnum } = useSelector((state) => state.processes);
  const { peopleEnum } = useSelector((state) => state.people);
  const { technologiesEnum } = useSelector((state) => state.technologies);

  // useEffect(() => {
  //   dispatch(PeopleActions.getPeopleRequest());
  // }, []);

  const types = [
    {
      label: i18next.t('businessUnitAsset.tabs.assets.user'),
      value: 'user',
    },
    {
      label: i18next.t('businessUnitAsset.tabs.assets.process'),
      value: 'process',
    },
    {
      label: i18next.t('businessUnitAsset.tabs.assets.asset'),
      value: 'technology',
    },
  ];

  const newActive = () => {
    setActives((e) => [...e, { type: null, asset_id: null }]);
  };

  const deleteActive = (index) => {
    const newArray = [...actives];
    const removedId = newArray[index]?.id;
    if (removedId) {
      setDeletedActives((state) => [...state, removedId]);
    }
    newArray.splice(index, 1);
    setActives([...newArray]);
  };

  const updateType = (index, updatedType) => {
    const newArray = [...actives];
    newArray[index] = { ...newArray[index], type: updatedType };
    setActives([...newArray]);
  };

  const updateActive = (index, updatedActive) => {
    const newArray = [...actives];
    newArray[index] = { ...newArray[index], asset_id: updatedActive };
    setActives([...newArray]);
  };

  return (
    <Container>
      <Title>{i18next.t('businessUnitAsset.tabs.assets.label')}</Title>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>{i18next.t('businessUnitAsset.tabs.assets.typeLabel')}</Label>
        </Grid>
        <Grid item xs={4}>
          <Label>{i18next.t('businessUnitAsset.tabs.assets.assetLabel')}</Label>
        </Grid>
        <Grid item xs={3} />
        {actives.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <SecondarySelect
                options={types}
                onChange={(e) => updateType(index, e)}
                value={actives[index].type}
              />
            </Grid>
            <Grid item xs={4}>
              <SecondarySelect
                options={
                  actives[index].type === 'user'
                    ? peopleEnum
                    : actives[index].type === 'process'
                    ? processesEnum
                    : actives[index].type === 'technology'
                    ? technologiesEnum
                    : []
                }
                onChange={(e) => updateActive(index, e)}
                value={actives[index].asset_id}
              />
            </Grid>
            <Grid item xs={3}>
              <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        <TertiaryButton
          onClick={newActive}
          label={i18next.t('common.buttons.add')}
          leftIcon={<IconPlus />}
        />
      </WrapperAddButton>
    </Container>
  );
}

export default Actives;

Actives.propTypes = {
  actives: arrayOf.isRequired,
  setActives: func.isRequired,
  setDeletedActives: func.isRequired,
};
