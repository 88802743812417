import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import BusinessUnitsActions, {
  BusinessUnitsTypes,
} from '../ducks/businessUnits';

function* getBusinessUnits({ companyId, page, limit, query }) {
  try {
    const response = yield call(
      api.get,
      `/api/locality/list-by-companie/${companyId}?page=${page}&limit=${limit}&search=${query}`
    );

    yield put(BusinessUnitsActions.getBusinessUnitsSuccess(response.data.data));
  } catch (error) {
    yield put(BusinessUnitsActions.getBusinessUnitsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchBusinessUnits({ query }) {
  try {
    const response = yield call(api.get, `/business_units?search=${query}`);

    yield put(BusinessUnitsActions.searchBusinessUnitsSuccess(response.data));
  } catch (error) {
    yield put(BusinessUnitsActions.searchBusinessUnitsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createBusinessUnit({
  data: { documents, companyId, actives, accessControl, location, sidebarInfo },
  handleGoBack,
}) {
  try {
    const formData = convertFormData({
      title: sidebarInfo.title,
      company_id: companyId,
    });

    formData.append(
      'primary_function',
      sidebarInfo.primaryFunction.length > 0 ? sidebarInfo.primaryFunction : ''
    );

    formData.append(
      'address',
      JSON.stringify({
        cep: location.cep,
        public_area: location.street,
        number: location.number,
        complement: location.complement,
        district: location.neighborhood,
        city: location.city,
        state: location.state,
      })
    );

    formData.append(
      'responsibles',
      sidebarInfo.responsible.length > 0
        ? JSON.stringify(sidebarInfo.responsible)
        : []
    );

    formData.append('assets', JSON.stringify(actives) ?? []);

    formData.append('access_controls', JSON.stringify(accessControl) ?? []);

    formData.append(
      'docs',
      documents
        ? JSON.stringify(
            documents.reduce((acc, item) => {
              if (item.description && item.file)
                acc.push({
                  description: item.description,
                  file_name: item.file.name,
                });

              return acc;
            }, [])
          )
        : []
    );

    if (documents) {
      documents.map((document) => {
        if (document.file) formData.append('evidences', document.file);
        return document.description;
      });
    }

    const response = yield call(api.post, '/api/locality', formData);

    yield put(BusinessUnitsActions.createBusinessUnitsSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Unidade de Negócio criada!',
      })
    );

    handleGoBack(response.data.data);
  } catch (error) {
    yield put(BusinessUnitsActions.createBusinessUnitsFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação da unidade de negócio',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* updateBusinessUnit({
  data: {
    documents,
    companyId,
    deletedDocuments,
    actives,
    // deletedActives,
    accessControl,
    location,
    sidebarInfo,
    // currentUserId,
  },

  id,
}) {
  try {
    const formData = new FormData();
    formData.append('title', sidebarInfo.title ?? '');
    formData.append('primary_function', sidebarInfo.primaryFunction ?? '');
    formData.append('company_id', companyId);
    formData.append(
      'address',
      JSON.stringify({
        cep: location.cep,
        public_area: location.street,
        number: location.number,
        complement: location.complement,
        district: location.neighborhood,
        city: location.city,
        state: location.state,
      })
    );

    formData.append(
      'responsibles',
      JSON.stringify(sidebarInfo.responsible) ?? []
    );

    formData.append('deletedDocuments', JSON.stringify(deletedDocuments) ?? []);

    formData.append('assets', JSON.stringify(actives) ?? []);

    formData.append('access_controls', JSON.stringify(accessControl) ?? []);

    formData.append(
      'docs',
      documents
        ? JSON.stringify(
            documents.reduce((acc, item) => {
              if (item.description && item.file) {
                acc.push({
                  description: item.description,
                  file_name: item.file.name,
                });
              } else if (item.id) {
                acc.push({
                  id: item.id,
                  description: item.description,
                  url: item.url,
                });
              }

              return acc;
            }, [])
          )
        : []
    );

    if (documents) {
      documents.map((document) => {
        if (document.file) formData.append('evidences', document.file);
        return document.description;
      });
    }

    yield call(api.put, `/api/locality/${id}`, formData);

    yield put(BusinessUnitsActions.updateBusinessUnitsSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Unidade de Negócio atualizada!',
      })
    );
  } catch (error) {
    yield put(BusinessUnitsActions.updateBusinessUnitsFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha ao atualizar unidade de negócio',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getBusinessUnitDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/locality/${id}`);

    yield put(
      BusinessUnitsActions.getBusinessUnitDetailsSuccess(response.data.data)
    );
  } catch (error) {
    yield put(BusinessUnitsActions.getBusinessUnitDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getBusinessUnitsEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/locality/list-by-companie/${userActive.activeCompany.id}`
    );

    const formatResponse = yield response.data.data.rows.map((item) => ({
      value: item.id,
      label: item.title,
    }));

    yield put(BusinessUnitsActions.getBusinessUnitsEnumSuccess(formatResponse));
  } catch (error) {
    yield put(BusinessUnitsActions.getBusinessUnitsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de unidades',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadBusinessUnits() {
  try {
    const response = yield call(api.get, `/business_units/download_xlsx`, {
      params: {
        business_unit: ['title', 'createdAt'],
        responsible: ['name'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Ativos-UnidadesDeNegocio.xlsx');

    yield put(BusinessUnitsActions.downloadBusinessUnitsSuccess());
  } catch (error) {
    yield put(BusinessUnitsActions.downloadBusinessUnitsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadBusinessUnits({ data }) {
  try {
    yield call(api.post, '/business_units/upload_xlsx', data);
    yield put(BusinessUnitsActions.updateBusinessUnitsSuccess());
  } catch (error) {
    yield put(BusinessUnitsActions.updateBusinessUnitsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no upload dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createLocalityEvidences({
  localityId,
  description,
  evidence,
  onSuccess,
}) {
  try {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (e) => {
        console.log(Math.round((e.loaded * 100) / e.total));
      },
    };

    const formData = new FormData();
    formData.append('description', description);
    formData.append('evidence', evidence);
    const response = yield call(
      api.post,
      `/api/locality/add-evidence/${localityId}`,
      formData,
      config
    );

    const formResponse = {
      id: response.data.data.id,
      url: response.data.data.url,
      description: response.data.data.description,
    };

    yield put(
      BusinessUnitsActions.createLocalityEvidencesSuccess(formResponse)
    );
    onSuccess(formResponse);

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Arquivo anexado!',
      })
    );
  } catch (error) {
    yield put(BusinessUnitsActions.createLocalityEvidencesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no upload dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteBusinessUnit({ id }) {
  try {
    yield call(api.delete, `/api/locality/${id}`);
    yield put(BusinessUnitsActions.deleteBusinessUnitsSuccess(id));
  } catch (error) {
    yield put(BusinessUnitsActions.deleteBusinessUnitsFailure());
  }
}

function* deleteEvidence({ evidenceId, onSuccess }) {
  try {
    yield call(api.delete, `/api/locality/remove-evidence/${evidenceId}`);
    yield put(BusinessUnitsActions.deleteEvidenceSuccess());
    onSuccess(evidenceId);
  } catch (error) {
    yield put(BusinessUnitsActions.deleteEvidenceFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no deletar anexo',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(BusinessUnitsTypes.GET_BUSINESS_UNITS_REQUEST, getBusinessUnits),
  takeLatest(
    BusinessUnitsTypes.SEARCH_BUSINESS_UNITS_REQUEST,
    searchBusinessUnits
  ),
  takeLatest(
    BusinessUnitsTypes.CREATE_BUSINESS_UNITS_REQUEST,
    createBusinessUnit
  ),
  takeLatest(
    BusinessUnitsTypes.UPDATE_BUSINESS_UNITS_REQUEST,
    updateBusinessUnit
  ),
  takeLatest(
    BusinessUnitsTypes.GET_BUSINESS_UNIT_DETAILS_REQUEST,
    getBusinessUnitDetails
  ),
  takeLatest(
    BusinessUnitsTypes.GET_BUSINESS_UNITS_ENUM_REQUEST,
    getBusinessUnitsEnum
  ),
  takeLatest(
    BusinessUnitsTypes.DOWNLOAD_BUSINESS_UNITS_REQUEST,
    downloadBusinessUnits
  ),
  takeLatest(
    BusinessUnitsTypes.DELETE_BUSINESS_UNITS_REQUEST,
    deleteBusinessUnit
  ),
  takeLatest(BusinessUnitsTypes.DELETE_EVIDENCE_REQUEST, deleteEvidence),
  takeLatest(
    BusinessUnitsTypes.UPLOAD_BUSINESS_UNITS_REQUEST,
    uploadBusinessUnits
  ),
  takeLatest(
    BusinessUnitsTypes.CREATE_LOCALITY_EVIDENCES_REQUEST,
    createLocalityEvidences
  ),
]);
