/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from '~/layouts/PrivateLayout';
// import { useSelector } from 'react-redux';
import history from './history';

import store from '../store';

const PrivateRoute = ({
  component: Component,
  backButton,
  noDrawer,
  CustomLayout,
  ...rest
}) => {
  // const { companyData } = useSelector((state) => state.config);
  const { userSignedIn } = store.getState().auth;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (userSignedIn) {
          if (CustomLayout) {
            return (
              <div>
                <CustomLayout {...rest}>
                  <Component {...props} />
                </CustomLayout>
              </div>
            );
          }
          // if (
          //   history.location.pathname === '/configs/company' &&
          //   companyData.bylaw === null
          // ) {
          //   return (
          //     <PrivateLayout backButton={backButton} noDrawer>
          //       <Component {...props} />
          //     </PrivateLayout>
          //   );
          // }
          if (history.location.pathname !== '/sign-in/company-select') {
            return (
              <PrivateLayout backButton={backButton} noDrawer={noDrawer}>
                <Component {...props} />
              </PrivateLayout>
            );
          }
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  backButton: PropTypes.bool,
  noDrawer: PropTypes.bool,
  CustomLayout: PropTypes.element,
};

PrivateRoute.defaultProps = {
  backButton: false,
  noDrawer: false,
  CustomLayout: undefined,
};

export default PrivateRoute;
