import { func, string } from 'prop-types';
import React from 'react';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import Card from '~/components/Card';

import HistoryItem from '../HistoryItem';

import { Container, Title, Scroll, NoData } from './styles';

function History({ setNewTreatment, riskId, setOpenModal }) {
  const { riskHistory } = useSelector((state) => state.risks);
  return (
    <Card>
      <Container>
        <Title>{i18next.t('risks.riskDetails.evaluationsHistory.title')}</Title>
        <Scroll>
          {riskHistory.length > 0 ? (
            riskHistory?.map((item) => (
              <HistoryItem
                key={item.id}
                item={item}
                setNewTreatment={setNewTreatment}
                riskId={riskId}
                setOpenModal={setOpenModal}
              />
            ))
          ) : (
            <NoData>
              {i18next.t('risks.riskDetails.evaluationsHistory.noData')}
            </NoData>
          )}
        </Scroll>
      </Container>
    </Card>
  );
}

export default History;

History.propTypes = {
  setNewTreatment: func.isRequired,
  setOpenModal: func.isRequired,
  riskId: string.isRequired,
};
