import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import { any, func, oneOfType, string } from 'prop-types';
import React from 'react';
import FileInput from '~/components/FileInput';
import { SecondarySelect } from '~/components/Select';
import TextInput from '~/components/TextInput';
import { Container } from './styles';

export function HasComponent({ label, value, onChange }) {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <SecondarySelect
            options={[
              {
                label: i18next.t('iventory.tab.controls.hasComponent.yesLabel'),
                value: true,
              },
              {
                label: i18next.t('iventory.tab.controls.hasComponent.noLabel'),
                value: false,
              },
            ]}
            label={label}
            value={value.itHas}
            onChange={(e) => onChange({ ...value, itHas: e })}
            sm
          />
        </Grid>
        {value.itHas && (
          <>
            <Grid item xs={4}>
              <TextInput
                sm
                label={i18next.t(
                  'iventory.tab.controls.hasComponent.description'
                )}
                placeholder={i18next.t(
                  'iventory.tab.controls.hasComponent.description'
                )}
                value={value.description}
                onChange={(e) =>
                  onChange({ ...value, description: e.target.value })
                }
                // error={errors.handlingPersonalData?.iNOUTTransmission}
                // onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={value.file ? 4 : 2}>
              <FileInput
                sm
                label={i18next.t(
                  'risks.riskDetails.createEvaluationModal.evidenceLabel'
                )}
                value={value.file}
                onChange={(e) => onChange({ ...value, file: e })}
                // error={errors.evidence}
                // touched={touched.evidence}
                name="evidence"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
}

HasComponent.propTypes = {
  label: string,
  onChange: func,
  value: oneOfType([any]),
};

HasComponent.defaultProps = {
  label: '',
  onChange: null,
  value: {},
};
