import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { IconArrowDown } from '~/assets/svgs';

export const Container = styled.div`
  margin: 2.5rem 0;

  position: relative;
`;

export const WrapperTop = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;

  ${({ opened }) =>
    !opened &&
    css`
      :hover {
        transform: scale(1.05) translateX(3%);
      }
    `}

  transition: 200ms ease;
`;

export const TopText = styled.div`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.base[500]};
  font-weight: 400;
  margin-left: 1.5rem;
  flex: 1;
  transition: 200ms ease;
`;

export const IconArrow = styled(IconArrowDown)`
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 200ms ease;

  position: absolute;
  right: 0;
  top: 1.8rem;
`;

export const WrapperAccordion = styled.div`
  display: flex;
  flex-direction: column;

  /* max-height: ${({ qty }) => qty * 4.6}rem; */
  overflow: hidden;

  ${({ opened }) =>
    !opened &&
    css`
      max-height: 0;
      line-height: 0;
    `}

  transition: 200ms ease;
`;

export const AccordionItem = styled.div`
  cursor: pointer;
  line-height: 2.4rem;
  padding: 0.9rem 0;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const AccordionText = styled.div`
  font-size: 1.2rem;
  color: ${({ theme, active }) =>
    active ? theme.colors.blue : theme.colors.base[500]};
  font-weight: ${({ theme, active }) =>
    active ? theme.fontWeight.bold : theme.fontWeight.regular};
  flex: 1;
  :hover {
    transform: scale(1.05) translateX(3%);
  }

  transition: 200ms ease;
`;

export const Indicator = styled(motion.div)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blue};
  width: 0.5rem;
  height: 3.1rem;
  right: -6%;
  border-radius: 0.25rem;
`;

export const TopIndicator = styled(motion.div)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blue};
  width: 0.5rem;
  height: 2rem;
  right: -6%;
  border-radius: 0.25rem;
  top: 1rem;
`;
