import styled from 'styled-components';
import { IconDownload, IconUpload } from '~/assets/svgs';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderActions = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const SearchWrapper = styled.div`
  width: 30%;
`;

export const DownloadButton = styled.div`
  width: 12rem;
  height: 5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 1rem;
  margin-right: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.clickable};
`;

export const DownloadIcon = styled(IconDownload)`
  fill: ${({ theme }) => theme.colors.blueSecondary};
`;

export const DownloadText = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #606474;
  margin-left: 1rem;
`;

export const UploadButton = styled(DownloadButton)`
  margin-right: 0;
`;

export const UploadIcon = styled(IconUpload)`
  fill: ${({ theme }) => theme.colors.blueSecondary};
`;
