import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import i18next from 'i18next';

import { useDispatch, useSelector } from 'react-redux';
import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import { SecondaryButton } from '~/components/Button';
import { dateSortMethod } from '~/utils';
import DocumentActions from '~/store/ducks/documents';
import Spinner from '~/components/Spinner';
import SearchInput from '~/components/TextInput/Search';

import {
  Container,
  Header,
  Title,
  HeaderRight,
  HeaderActions,
  SearchWrapper,
} from './styles';

const DemandManagement = () => {
  const dispatch = useDispatch();
  const { revisionDocuments, isLoading } = useSelector(
    (state) => state.documents
  );
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchValue === '') {
      dispatch(DocumentActions.getAllRevisionDocumentsRequest());
    } else {
      dispatch(DocumentActions.searchRevisionDocumentsRequest(searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  function handlePagination(newPage) {
    dispatch(DocumentActions.getAllRevisionDocumentsRequest(newPage));
  }

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>{i18next.t('documentReview.title')}</Title>
                <HeaderActions>
                  <SearchWrapper>
                    <SearchInput
                      inputRef={searchInputRef}
                      placeholder={i18next.t('common.search')}
                      value={searchValue}
                      onChange={setSearchValue}
                      delay={1500}
                      hasDebounce
                    />
                  </SearchWrapper>
                  <HeaderRight>
                    {/* <DownloadButton>
                      <DownloadIcon />
                      <DownloadText>Exportar</DownloadText>
                    </DownloadButton> */}
                  </HeaderRight>
                </HeaderActions>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Card padding="2rem 0">
                <Table
                  columns={[
                    {
                      Header: i18next.t('documentReview.table.name'),
                      accessor: 'name',
                    },
                    {
                      Header: i18next.t('documentReview.table.editedBy'),
                      accessor: 'last_editor.first_name',
                    },
                    {
                      Header: i18next.t('documentReview.table.version'),
                      accessor: 'version',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return `v${e.value}`;
                      },
                    },
                    {
                      Header: i18next.t('documentReview.table.lastUpdate'),
                      accessor: 'updatedAt',
                      Cell: (e) => format(new Date(e.value), 'dd/MM/yyyy'),
                      sortType: (a, b) => {
                        return dateSortMethod(
                          a.values.due_date,
                          b.values.due_date
                        );
                      },
                    },
                    {
                      Header: i18next.t('documentReview.table.status'),
                      accessor: 'status',
                      Cell: (e) => {
                        switch (e.value) {
                          case 'WAITING_REVIEW':
                            return i18next.t(
                              'documentReview.table.needAnalysis'
                            );
                          case 'NEED_CHANGES':
                            return i18next.t('documentReview.table.review');
                          case 'APPROVED':
                            return i18next.t('documentReview.table.approved');
                          default:
                            return i18next.t(
                              'documentReview.table.dataNotFound'
                            );
                        }
                      },
                    },
                    {
                      Header: i18next.t('documentReview.table.action'),
                      accessor: 'action',
                      Cell: (e) => (
                        <SecondaryButton
                          onClick={() =>
                            dispatch(
                              DocumentActions.getRevisionDocumentDetailsRequest(
                                e.row.original.id
                              )
                            )
                          }
                          label={i18next.t('documentReview.table.view')}
                          small
                          width="8.6rem"
                          padding="0"
                        />
                      ),
                    },
                  ]}
                  data={revisionDocuments ? revisionDocuments?.data?.rows : []}
                  total={revisionDocuments?.data?.count}
                  pageCount={revisionDocuments?.data?.total_pages}
                  handlePagination={handlePagination}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default DemandManagement;
