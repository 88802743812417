import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@material-ui/core/Popover';
import Avatar from '@material-ui/core/Avatar';
import AuthActions from '~/store/ducks/auth';
import DemandActions from '~/store/ducks/demand';
import history from '~/routes/history';
import i18next from 'i18next';
import { SecondaryButton } from '~/components/Button';

import {
  Container,
  WrapperContent,
  Name,
  ArrowDown,
  ArrowUp,
  Row,
  CompanyLogoWrapper,
  CompanyLogo,
  SwitchCompanyText,
} from './styles';

function ProfileMenu() {
  const dispatch = useDispatch();
  const { clientActive, clientCompanyId } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentCompanyId, setCurrentCompanyId] = useState(clientCompanyId);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSignOut() {
    dispatch(AuthActions.clientSignOutRequest());
    history.push('/login');
  }

  useEffect(() => {
    if (currentCompanyId !== clientCompanyId) {
      dispatch(DemandActions.getDemandsHistoryRequest());
      setCurrentCompanyId(clientCompanyId);
    }
  }, [clientCompanyId]);

  function handleCompanyChange(companyId) {
    dispatch(AuthActions.switchClientCompanyRequest(companyId));
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <WrapperContent onClick={handleClick}>
          <Name>Menu</Name>
          {!open ? <ArrowDown /> : <ArrowUp />}
        </WrapperContent>
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '23rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <SwitchCompanyText>
          {i18next.t('companySwitchMenu.title')}
        </SwitchCompanyText>
        {clientActive?.companies.map((item) => (
          <Row key={item.id} onClick={() => handleCompanyChange(item.id)}>
            {item.logo ? (
              <CompanyLogoWrapper>
                <CompanyLogo
                  src={`${
                    process.env.NODE_ENV === 'development'
                      ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                      : process.env.REACT_APP_API_PROD_ENDPOINT
                  }/users/avatar?avatar=${item.logo}`}
                />
              </CompanyLogoWrapper>
            ) : (
              <CompanyLogoWrapper>
                <Avatar>{item.fantasy_name.charAt(0)}</Avatar>
              </CompanyLogoWrapper>
            )}

            {item.fantasy_name}
          </Row>
        ))}
        <Row>
          <SecondaryButton
            height="10"
            label="Sair"
            onClick={() => handleSignOut()}
            style={{ borderColor: '#539ada', color: '#539ada' }}
          />
        </Row>
      </Popover>
    </>
  );
}

export default ProfileMenu;
