import styled from 'styled-components';

export const Container = styled.div.attrs({ layout: true })`
  max-width: 1120px;
  width: 100%;
  margin: auto;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: #262a3a;
  line-height: 3.6rem;
  margin-bottom: 3rem;
`;
