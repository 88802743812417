import React from 'react';

import i18next from 'i18next';
import { Container, Title } from './styles';
import CompanyData from './CompanyData';

const Company = () => {
  return (
    <Container>
      <Title>{i18next.t('configurations.company.title')}</Title>
      <CompanyData />
    </Container>
  );
};

export default Company;
