import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

export const ReplyHeader = styled.div`
  margin-bottom: 2rem;
`;

export const ReplyContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

export const ReplyTitle = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 600;
  line-height: 4.1rem;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-bottom: 2rem;
  flex-grow: 3;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperForm = styled.div`
  display: flex;
  padding: 21px 32px 100px 32px;
  background: #ffffff;
  width: 95vw;
  border-radius: 10px;
`;
