import i18next from 'i18next';

export default function convertImpact(impactValue) {
  switch (impactValue) {
    case 'negligible':
      return i18next.t('common.impacts.negligible');
    case 'little':
      return i18next.t('common.impacts.little');
    case 'medium':
      return i18next.t('common.impacts.medium');
    case 'larger':
      return i18next.t('common.impacts.larger');
    case 'extreme':
      return i18next.t('common.impacts.extreme');
    default:
      return '';
  }
}
