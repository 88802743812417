import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import HoldersActions from '~/store/ducks/holders';
// import { useSocket } from '~/hooks/socket';

import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import { dateSortMethod } from '~/utils';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import Spinner from '~/components/Spinner';
import Chat from './components/Chat';
import Files from './components/Files';
import ReopenDemand from '../components/ReopenDemand';

import schema from './validationSchema';

import {
  Container,
  Header,
  Title,
  Text,
  HeaderRight,
  PriorityDueDateWrapper,
  WrapperPriority,
  WrapperDueDate,
  Label,
  WrapperButton,
  WrapperForm,
  WrapperCard,
  CheckIcon,
} from './styles';

const DemandDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, lastTicket, tickets } = useSelector(
    (state) => state.holders
  );
  const [openModal, setOpenModal] = useState(false);
  // const { emitAttendance } = useSocket();
  const [currentPage, setCurrentPage] = useState(1);

  const id = history.location.pathname.replace('/holders/demands/', '');

  useEffect(() => {
    dispatch(HoldersActions.loadLastestTicketRequest(id));
    dispatch(HoldersActions.loadTicketsRequest(id));
  }, [id]);

  // useEffect(() => {
  //   const interval = setInterval(() => emitAttendance(id), 5000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  function handleButton() {
    if (lastTicket?.status === 'open') {
      history.push(`/holders/demands/attendance-detail/${lastTicket.id}`);
    } else {
      setOpenModal(!openModal);
    }
  }

  function handlePagination(newPage) {
    dispatch(HoldersActions.loadTicketsRequest(id, newPage));
  }

  function convertPriority(priority) {
    let value;
    switch (priority) {
      case 'low':
        value = i18next.t('demandManagement.table.priorityTag.low');
        break;
      case 'medium':
        value = i18next.t('demandManagement.table.priorityTag.medium');
        break;
      case 'high':
        value = i18next.t('demandManagement.table.priorityTag.high');
        break;
      default:
        value = '-';
    }

    return value;
  }

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>
                  {i18next.t('demandManagement.demandDetails.title')}
                </Title>
              </Header>
            </Grid>
            <Formik
              enableReinitialize
              initialValues={{
                name: lastTicket?.client?.name || '',
                cpfCnpj: lastTicket?.client?.cpf || '',
                email: lastTicket?.client?.email || '',
                phone: lastTicket?.client?.phone || '',
                subject: lastTicket?.subject || '',
                dueDate: null,
                description: lastTicket?.description,
              }}
              validationSchema={schema}
            >
              {({ values, touched, errors, handleChange }) => (
                <Grid container spacing={5}>
                  <Grid item md={8} xs={12}>
                    <WrapperForm>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Header>
                            <Text>
                              {i18next.t(
                                'demandManagement.demandDetails.subtitle'
                              )}
                            </Text>
                            <HeaderRight>
                              <WrapperButton>
                                <PrimaryButton
                                  label={
                                    lastTicket?.status === 'open'
                                      ? i18next.t(
                                          'demandManagement.demandDetails.answer'
                                        )
                                      : i18next.t(
                                          'demandManagement.demandDetails.reOpen'
                                        )
                                  }
                                  padding="0 5.8rem"
                                  width="null"
                                  onClick={handleButton}
                                />
                              </WrapperButton>
                            </HeaderRight>
                          </Header>
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            label={i18next.t('common.fields.name')}
                            placeholder="João Santos"
                            name="name"
                            value={values.name}
                            touched={touched.name}
                            error={errors.name}
                            onChange={handleChange}
                            editable={false}
                            editableui
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            label={i18next.t('common.fields.cpfCnpj')}
                            placeholder="Ex.: CPF/CNPJ"
                            mask={
                              values.cpfCnpj.length < 15
                                ? '999.999.999-99*'
                                : '99.999.999/9999-99'
                            }
                            name="cpfCnpj"
                            value={values.cpfCnpj}
                            touched={touched.cpfCnpj}
                            error={errors.cpfCnpj}
                            onChange={handleChange}
                            editable={false}
                            editableui
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            label={i18next.t('common.fields.email')}
                            placeholder="Ex.: meuemail@email.com.br"
                            name="email"
                            value={values.email}
                            touched={touched.email}
                            error={errors.email}
                            onChange={handleChange}
                            editable={false}
                            editableui
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextInput
                            label={i18next.t('common.fields.phone')}
                            placeholder="Ex.: (21) 99999-9999"
                            mask={
                              values.phone.length < 15
                                ? '(99) 9999-9999*'
                                : '(99) 99999-9999'
                            }
                            name="phone"
                            value={values.phone}
                            touched={touched.phone}
                            error={errors.phone}
                            onChange={handleChange}
                            editable={false}
                            editableui
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label={i18next.t('common.fields.subject')}
                            placeholder="Insira o assunto da sua mensagem"
                            name="subject"
                            value={values.subject}
                            touched={touched.subject}
                            error={errors.subject}
                            onChange={handleChange}
                            editable={false}
                            editableui
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            label={i18next.t('common.fields.description')}
                            placeholder="Insira sua mensagem aqui"
                            name="description"
                            value={values.description}
                            touched={touched.description}
                            error={errors.description}
                            onChange={handleChange}
                            editable={false}
                            editableui
                            textArea
                          />
                        </Grid>
                      </Grid>
                    </WrapperForm>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <PriorityDueDateWrapper>
                      <WrapperPriority>
                        <Label>
                          {i18next.t('demandManagement.demandDetails.priority')}
                        </Label>
                        <p>{convertPriority(lastTicket?.priority)}</p>
                      </WrapperPriority>
                      <WrapperDueDate>
                        <Label>
                          {i18next.t('demandManagement.demandDetails.deadline')}
                        </Label>
                        <p>
                          {lastTicket?.due_date ? (
                            format(
                              new Date(lastTicket.due_date.replace(/-/g, '/')),
                              'dd/MM/yyyy'
                            )
                          ) : (
                            <p>-</p>
                          )}
                        </p>
                      </WrapperDueDate>
                    </PriorityDueDateWrapper>
                    <WrapperCard>
                      <Chat />
                    </WrapperCard>
                    <WrapperCard height="25rem">
                      <Files />
                    </WrapperCard>
                  </Grid>
                </Grid>
              )}
            </Formik>
            <Grid item xs={12}>
              <Header>
                <Title>Histórico do titular</Title>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Card padding="2rem 0">
                <Table
                  columns={[
                    { Header: 'Protocolo', accessor: 'id' },
                    { Header: 'Responsável', accessor: 'responsible.name' },
                    {
                      Header: 'Data',
                      accessor: 'createdAt',
                      Cell: (e) => format(new Date(e.value), 'dd/MM/yyyy'),
                      sortType: (a, b) => {
                        return dateSortMethod(a.values.date, b.values.date);
                      },
                    },
                    {
                      Header: 'Horário',
                      accessor: 'updatedAt',
                      Cell: (e) => format(new Date(e.value), 'HH:mm'),
                      sortType: (a, b) => {
                        return dateSortMethod(
                          a.values.schedule,
                          b.values.schedule
                        );
                      },
                    },
                    { Header: 'Assunto', accessor: 'subject' },
                    {
                      Header: 'Status do e-mail',
                      accessor: 'status',
                      Cell: (e) => <CheckIcon />,
                    },
                  ]}
                  data={tickets.rows}
                  total={tickets?.count}
                  pageCount={tickets?.total_pages}
                  handlePagination={handlePagination}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}

      <ReopenDemand
        id={lastTicket?.id}
        open={!!openModal}
        onClose={() => setOpenModal(false)}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default DemandDetails;
