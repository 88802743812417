import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import RisksActions from '~/store/ducks/risks';

import SearchInput from '~/components/TextInput/Search';
import Spinner from '~/components/Spinner';

import Table from './components/Table';
import ImpactGrid from './components/ImpactGrid';
import Captions from './components/Captions';

import {
  Container,
  Header,
  Title,
  HeaderRight,
  DownloadButton,
  DownloadIcon,
  HeaderActions,
  SearchWrapper,
  DownloadText,
  TitleWrapper,
} from './styles';

const RisksManagement = () => {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.risks);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchValue === '') {
      dispatch(RisksActions.getRisksRequest());
    } else {
      dispatch(RisksActions.searchRisksRequest(searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  useEffect(() => {
    dispatch(RisksActions.getRisksRequest());
    dispatch(RisksActions.getRisksMatrixRequest());
  }, []);

  const handleDownload = () => {
    dispatch(RisksActions.downloadRisksRequest());
  };

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Header>
            <TitleWrapper>
              <Title>{i18next.t('risks.title')}</Title>
            </TitleWrapper>
          </Header>
        </Grid>
        <Grid item xs={12}>
          <HeaderActions>
            <SearchWrapper>
              <SearchInput
                inputRef={searchInputRef}
                placeholder={i18next.t('common.search')}
                value={searchValue}
                onChange={setSearchValue}
                delay={1500}
                hasDebounce
              />
            </SearchWrapper>
            <HeaderRight>
              <DownloadButton onClick={handleDownload}>
                <DownloadIcon />
                <DownloadText>
                  {i18next.t('common.buttons.export')}
                </DownloadText>
              </DownloadButton>
            </HeaderRight>
          </HeaderActions>
        </Grid>
        <Grid item md={7} xs={12}>
          <ImpactGrid />
        </Grid>
        <Grid item md={5} xs={12}>
          <Captions />
        </Grid>
        <Grid item xs={12}>
          <Table />
        </Grid>
      </Grid>
    </Container>
  );
};

export default RisksManagement;
