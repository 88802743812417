import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import DashboardActions, { DashboardTypes } from '../ducks/dashboard';

function* getMainRisks({ page }) {
  try {
    const { userActive, companyId } = yield select((state) => state.auth);

    let response;
    if (page) {
      response = yield call(api.get, `/risks?page=${page}`, {
        headers: {
          Authorization: `Bearer ${userActive?.token}`,
          companyId,
        },
      });
    } else {
      response = yield call(api.get, '/risks', {
        headers: {
          Authorization: `Bearer ${userActive?.token}`,
          companyId,
        },
      });
    }

    yield put(DashboardActions.getMainRisksSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.getMainRisksFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getNextRevisions() {
  try {
    const { userActive, companyId } = yield select((state) => state.auth);

    const response = yield call(api.get, '/risks/next_revisions', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });

    yield put(DashboardActions.getNextRevisionsSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.getNextRevisionsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getPendingRevisions() {
  try {
    const { userActive, companyId } = yield select((state) => state.auth);

    const response = yield call(api.get, '/documents/needs_review', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });

    yield put(DashboardActions.getPendingRevisionSuccess(response.data));
  } catch (error) {
    yield put(DashboardActions.getPendingRevisionFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getTotalCompletedTickets() {
  try {
    const { userActive, companyId } = yield select((state) => state.auth);

    const openTickets = yield call(api.get, '/tickets/stats', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });
    const answeredTickets = yield call(api.get, '/tickets/attends', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });
    const totalHolders = yield call(api.get, '/tickets/client_register', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });
    const totalProcesses = yield call(api.get, '/tickets/process_created', {
      headers: {
        Authorization: `Bearer ${userActive?.token}`,
        companyId,
      },
    });

    yield put(
      DashboardActions.getTotalTicketsCompletedSuccess(
        openTickets.data,
        answeredTickets.data,
        totalHolders.data,
        totalProcesses.data
      )
    );
  } catch (error) {
    yield put(DashboardActions.getTotalTicketsCompletedFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(DashboardTypes.GET_MAIN_RISKS_REQUEST, getMainRisks),
  takeLatest(DashboardTypes.GET_NEXT_REVISIONS_REQUEST, getNextRevisions),
  takeLatest(DashboardTypes.GET_PENDING_REVISION_REQUEST, getPendingRevisions),
  takeLatest(
    DashboardTypes.GET_TOTAL_TICKETS_COMPLETED_REQUEST,
    getTotalCompletedTickets
  ),
]);
