import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  background: ${({ status }) =>
    status === 'open'
      ? '#E8F1F8'
      : status === 'respond'
      ? 'rgba(23, 247, 112, 0.1)'
      : 'rgba(247, 23, 23, 0.1)'};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 2.5rem;
  padding: 0 1rem;
  min-width: 7.7rem;

  font-size: 1.2rem;
  color: ${({ status }) =>
    status === 'open'
      ? '#0E067D'
      : status === 'respond'
      ? '#0ACF59'
      : '#FF2900'};

  font-weight: 400;
`;
