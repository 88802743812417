/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-alert */
import React from 'react';
import {
  oneOfType,
  number,
  string,
  bool,
  func,
  element,
  objectOf,
  any,
} from 'prop-types';

import {
  Container,
  WrapperInput,
  TextArea,
  Input,
  Error,
  WrapperRight,
  WrapperLeft,
  Label,
} from './styles';

export default function TextInput({
  required,
  width,
  name,
  autoComplete,
  placeholder,
  touched,
  error,
  value,
  onChange,
  onSubmit,
  type,
  margin,
  rightElement,
  leftElement,
  label,
  mask,
  editable,
  inputProps,
  onClick,
  editableui,
  textArea,
  style,
  onBlur,
  sm,
  ...rest
}) {
  const isError = Boolean(touched && error);
  return (
    <>
      <Container error={isError} margin={margin} label={label}>
        {label && (
          <Label sm required={required}>
            {label}
          </Label>
        )}
        <WrapperInput onClick={onClick}>
          {leftElement && <WrapperLeft>{leftElement}</WrapperLeft>}
          {textArea ? (
            <TextArea
              inputProps={inputProps}
              readOnly={!editable}
              required={required}
              width={width}
              name={name}
              autoComplete={autoComplete}
              placeholder={placeholder}
              style={style}
              value={value}
              onChange={onChange}
              onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              type={type}
              error={isError}
              mask={mask}
              maskPlaceholder={null}
              editableui={editable === false ? editableui : true}
            />
          ) : (
            <Input
              onBlur={onBlur}
              inputProps={inputProps}
              readOnly={!editable}
              required={required}
              width={width}
              name={name}
              autoComplete={autoComplete}
              placeholder={placeholder}
              value={value}
              style={style}
              onChange={onChange}
              onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              type={type}
              error={isError}
              mask={mask}
              maskPlaceholder={null}
              editableui={editable === false ? editableui : true}
              {...rest}
            />
          )}
          {rightElement && <WrapperRight>{rightElement}</WrapperRight>}
        </WrapperInput>
        <Error error={isError}>{error}</Error>
      </Container>
    </>
  );
}

TextInput.propTypes = {
  required: bool,
  sm: bool,
  width: string,
  name: string,
  autoComplete: string,
  placeholder: string,
  touched: bool,
  error: string,
  value: string,
  type: string,
  onChange: func,
  onSubmit: func,
  onBlur: func,
  margin: oneOfType([string, number]),
  rightElement: element,
  leftElement: element,
  label: string,
  mask: string,
  editable: bool,
  inputProps: objectOf(oneOfType([any])),
  onClick: func,
  editableui: bool,
  textArea: bool,
  style: objectOf(oneOfType([any])),
};

TextInput.defaultProps = {
  required: false,
  sm: false,
  width: '200px',
  name: '',
  autoComplete: '',
  placeholder: '',
  touched: false,
  error: '',
  value: '',
  type: 'text',
  onChange: () => {},
  onSubmit: () => {},
  onBlur: null,
  margin: '0.2rem 0',
  rightElement: null,
  leftElement: null,
  label: '',
  mask: null,
  editable: true,
  inputProps: {},
  onClick: undefined,
  editableui: undefined,
  textArea: false,
  style: {},
};
