import React from 'react';

import i18next from 'i18next';

import Table from './components/Table';

import { Container, Header, TitleWrapper, Title } from './styles';

const CompanyUsers = () => {
  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('configurations.signature.title')}</Title>
        </TitleWrapper>
      </Header>
      <Table />
    </Container>
  );
};

export default CompanyUsers;
