/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-alert */
import React from 'react';
import {
  oneOfType,
  number,
  string,
  bool,
  func,
  element,
  objectOf,
  any,
} from 'prop-types';

import { Container, WrapperInput, Input } from './styles';

export default function TextInput({
  required,
  width,
  name,
  autoComplete,
  placeholder,
  touched,
  error,
  value,
  onChange,
  onSubmit,
  type,
  margin,
  label,
  mask,
  editable,
  inputProps,
  onClick,
  editableui,
}) {
  const isError = Boolean(touched && error);
  return (
    <>
      <Container error={isError} margin={margin} label={label}>
        <WrapperInput onClick={onClick}>
          <Input
            inputProps={inputProps}
            readOnly={!editable}
            required={required}
            width={width}
            name={name}
            autoComplete={autoComplete}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
            type={type}
            error={isError}
            mask={mask}
            maskPlaceholder={null}
            editableui={editable === false ? editableui : true}
          />
        </WrapperInput>
      </Container>
    </>
  );
}

TextInput.propTypes = {
  required: bool,
  width: string,
  name: string,
  autoComplete: string,
  placeholder: string,
  touched: bool,
  error: string,
  value: string,
  type: string,
  onChange: func,
  onSubmit: func,
  margin: oneOfType([string, number]),
  rightElement: element,
  leftElement: element,
  label: string,
  mask: string,
  editable: bool,
  inputProps: objectOf(oneOfType([any])),
  onClick: func,
  editableui: bool,
  textArea: bool,
};

TextInput.defaultProps = {
  required: false,
  width: '200px',
  name: '',
  autoComplete: '',
  placeholder: '',
  touched: false,
  error: '',
  value: '',
  type: 'text',
  onChange: () => {},
  onSubmit: () => {},
  margin: '0.2rem 0',
  rightElement: null,
  leftElement: null,
  label: '',
  mask: null,
  editable: true,
  inputProps: {},
  onClick: undefined,
  editableui: undefined,
  textArea: false,
};
