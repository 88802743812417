import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector, useDispatch } from 'react-redux';

import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';

import Spinner from '~/components/Spinner';
import Notes from './components/Notes';

import UpdateDocument from './components/UpdateDocument';
import History from './components/History';
import Participants from './components/Participants';
import Document from './components/Document';

import { Container, Header, HeaderTop, Title, ButtonUpload } from './styles';

const DocumentDetails = ({ match }) => {
  const dispatch = useDispatch();
  const { params } = match;
  const { id } = params;

  const [openUpdateDocumentModal, setOpenUpdateDocumentModal] = useState(false);
  const { documentDetails, isLoading } = useSelector(
    (state) => state.documents
  );

  useEffect(() => {
    dispatch(DocumentActions.getDocumentDetailsRequest(id));
  }, []);

  const handleFileUpload = () => {
    setOpenUpdateDocumentModal(true);
  };

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={9}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Header>
                    <HeaderTop>
                      <Title>{i18next.t('documents.title')}</Title>
                      {!documentDetails?.default && (
                        <ButtonUpload onClick={handleFileUpload}>
                          {i18next.t('common.buttons.completeUpload')}
                        </ButtonUpload>
                        // </Permission>
                      )}
                    </HeaderTop>
                  </Header>
                </Grid>
                <Grid item xs={12}>
                  <Document />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={4}>
                <Notes />
                <Participants />
                <History />
              </Grid>
            </Grid>
          </Grid>
          <UpdateDocument
            id={documentDetails?.data?.doc?.id}
            open={!!openUpdateDocumentModal}
            onClose={() => setOpenUpdateDocumentModal(false)}
          />
        </Container>
      )}
    </>
  );
};

export default DocumentDetails;
