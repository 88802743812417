import React from 'react';
import { objectOf, string, number } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import TicketActions from '~/store/ducks/tickets';

import Card from '~/components/Card';
import { Text } from '~/styles/components';

import Permission from '~/components/Permission';

import {
  Container,
  Title,
  WrapperItems,
  WrapperItem,
  WrapperDocument,
  WrapperDownload,
  DocumentIcon,
  DownloadIcon,
  NoData,
} from './styles';

const FilesItem = ({ item, ticketId }) => {
  const dispatch = useDispatch();
  return (
    <Permission action="demandDocument.download">
      <WrapperItem
        onClick={() =>
          dispatch(
            TicketActions.downloadTicketDocumentRequest(
              item.title,
              item?.id,
              ticketId
            )
          )
        }
      >
        <WrapperDocument>
          <DocumentIcon />
        </WrapperDocument>
        <Text width="100%" fontWeight={400} fontSize="1.2rem">
          {item.title === 'documentPhoto' ? 'Documento com foto' : item.title}
        </Text>

        <WrapperDownload>
          <DownloadIcon />
        </WrapperDownload>
      </WrapperItem>
    </Permission>
  );
};

FilesItem.propTypes = {
  item: objectOf(string).isRequired,
  ticketId: number.isRequired,
};

function Files() {
  const { ticketDetails } = useSelector((state) => state.tickets);

  return (
    <Card>
      <Container>
        <Title>{i18next.t('demandManagement.demandDetails.attachments')}</Title>
        <WrapperItems>
          {ticketDetails && ticketDetails.documents.length > 0 ? (
            ticketDetails.documents.map((item) => (
              <FilesItem item={item} ticketId={ticketDetails?.id} />
            ))
          ) : (
            <NoData>{i18next.t('common.noData')}</NoData>
          )}
        </WrapperItems>
      </Container>
    </Card>
  );
}

export default Files;
