import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';

import { TertiaryButton } from '~/components/Button';
import SearchInput from '~/components/TextInput/Search';
import Spinner from '~/components/Spinner';

// import UploadButtom from '~/components/UploadButtom';
import Table from './components/Table';

import {
  Container,
  Header,
  TitleWrapper,
  Title,
  IconPlus,
  ActionsWrapper,
  HeaderRight,
  // DownloadButton,
  // DownloadIcon,
  // DownloadText,
  SearchWrapper,
  // UploadButton,
  // UploadIcon,
  // UploaddText,
} from './styles';

const BusinessUnit = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const { isLoading } = useSelector((state) => state.businessUnits);
  const searchInputRef = useRef(null);
  // const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  // function handleDownload() {
  //   dispatch(BusinessUnitActions.downloadBusinessUnitsRequest());
  // }

  // const validationDataBusinesUnit = (data) => {
  //   const businessUnits = data.filter((item) => {
  //     if (item.titulo && item.titulo.length > 4) {
  //       return {
  //         titulo: item.titulo,
  //         cep: item.cep,
  //         logradouro: item.logradouro,
  //         numero: item.numero,
  //         complemento: item.complemento,
  //         bairro: item.bairro,
  //         cidade: item.cidade,
  //         estado: item.estado,
  //         funcao: item.funcao,
  //       };
  //     }
  //     return {};
  //   });

  //   dispatch(BusinessUnitActions.uploadBusinessUnitsRequest(businessUnits));
  // };

  // useEffect(() => {
  //   if (csvData.length > 0) {
  //     validationDataBusinesUnit(csvData);
  //   }
  // }, [csvData]);

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('businessUnitAsset.title')}</Title>
          <TertiaryButton
            onClick={() =>
              history.push('/assets-management/business-units/new')
            }
            label={i18next.t('common.buttons.new')}
            leftIcon={<IconPlus />}
          />
        </TitleWrapper>
        <ActionsWrapper>
          <SearchWrapper>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('common.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
          </SearchWrapper>
          <HeaderRight>
            {/* <DownloadButton onClick={handleDownload}>
              <DownloadIcon />
              <DownloadText>{i18next.t('common.buttons.export')}</DownloadText>
            </DownloadButton> */}
            {/* <UploadButtom setCsvData={setCsvData} /> */}
          </HeaderRight>
        </ActionsWrapper>
      </Header>
      <Table searchValue={searchValue} />
    </Container>
  );
};

export default BusinessUnit;
