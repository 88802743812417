import { AiOutlinePlusCircle } from 'react-icons/ai';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6.7rem 5%;
  /* height: 100vh; */

  max-width: 600px;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Title = styled.p`
  width: 26.4rem;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 3.3rem;
  color: #262a3a;
  margin-bottom: 0.9rem;
`;

export const Text = styled.p`
  width: 34.9rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.0374rem;
  color: #262a3a;
  margin-bottom: 3.9rem;
`;

export const WrapperCaptcha = styled.div`
  margin-bottom: 0.5rem;
  transition: all 300ms ease;
  align-self: center;
`;

export const DocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  gap: 1rem;
  margin: 2rem 0;
`;

export const Subtitle = styled.strong`
  font-size: 1.2rem;
  margin-top: 1rem;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const ContentFileModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 1rem;
  max-width: 600px;
`;

export const WrapperButton = styled.div`
  display: flex;
  margin: 2rem;
  gap: 2rem;
`;

export const WrapperFile = styled.div``;

export const WrapperDesc = styled.div``;

export const Mandatory = styled.span`
  color: ${({ theme }) => theme.colors.primary[400]};
`;

export const FileDescription = styled.span`
  font-size: 1.2rem;
  font-weight: normal;
`;

export const CaptchaError = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CheckboxWrapper = styled.div`
  margin-bottom: 2.4rem;

  & + div {
    margin-bottom: 4.9rem;
  }

  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CheckBox = styled.input`
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 6px;
  border: 1px solid #b8baba;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
`;

export const CheckBoxLabel = styled.span`
  ly: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const CheckboxButton = styled.button`
  border: none;
  background: none;

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export const DocumentTextWrapper = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  height: 51rem;
  overflow-y: scroll;

  border: 1px solid #b8baba;

  border-radius: 1.5rem;
  padding: 2.5rem 3.4rem 2rem 2.8rem;

  p {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.colors.base[400]};
  }

  & + div {
    margin-top: 2.5rem;
    margin-left: 2.5rem;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    margin: 1.2rem 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;
