import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div``;

export const Scroll = styled(PerfectScrollbar).attrs({
  options: {
    suppressScrollY: true,
    useBothWheelAxes: false,
  },
})`
  position: static;
  height: 5.2rem;
  width: 100%;
  padding: 0 2rem;
  @media (max-width: 600px) {
    height: auto;
  }
`;

export const WrapperTabs = styled.div`
  display: flex;
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const Tab = styled.div`
  height: 5.2rem;
  min-width: 15.6rem;
  max-width: 30rem;

  @media (max-width: 600px) {
    min-width: 11rem;
    max-width: 11rem;
  }

  margin: 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
  color: ${({ theme, isError }) => (isError ? 'red' : theme.colors.base[400])};
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    css`
      background-color: #539ada;
      color: ${({ theme }) => theme.colors.white};
      cursor: default;
    `};
  transition: 200ms ease;
  p {
    font-size: ${({ sm }) => (sm ? 1.2 : 1.4)}rem;
    font-weight: ${({ sm }) => (sm ? 500 : 400)};
    /* white-space: pre-wrap;
    text-overflow: -o-ellipsis-lastline; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    width: 90%;
  }

  @media (max-width: 500px) {
    p {
      font-size: 1rem;
    }
  }
`;

export const WrapperContent = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 2.5rem;
`;
