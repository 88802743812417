import { func } from 'prop-types';
import React from 'react';
import { IconMove } from '~/assets/svgs';

import { Container, Label, Button } from './styles';

const MoveButton = ({ onClick }) => {
  return (
    <Container>
      <Label>Mover</Label>
      <Button onClick={onClick}>
        <IconMove />
      </Button>
    </Container>
  );
};

export default MoveButton;

MoveButton.propTypes = {
  onClick: func,
};

MoveButton.defaultProps = {
  onClick: () => {},
};
