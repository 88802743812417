// import i18next from 'i18next';
import { arrayOf, func, objectOf } from 'prop-types';
import React, { useState } from 'react';
import ConfigActions from '~/store/ducks/config';
import { useDispatch } from 'react-redux';
import ActionButton from '../ActionButton';
import Option from '../Option';

import {
  CheckFatterWrapper,
  Container,
  Content,
  FatherCheck,
  IconPlus,
  LabelCheck,
  NewOptionButton,
  Title,
  WrapperChildrenOptions,
  WrapperFatherOption,
} from './styles';

const DrawerOptions = ({
  item,
  activeOption,
  setActiveOption,
  handleOpenEditModal,
}) => {
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);

  const handleSwitchOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  const checked = item.processes.some((it) => {
    return activeOption.process === it.id;
  });

  const handleDeleteDepartament = (id) => {
    setActiveOption({});
    dispatch(ConfigActions.deleteDepartamentRequest(id));
  };

  const handleNewProcess = () => {
    setActiveOption({ departament: item.id, process: null });
  };

  const handleEditDepartament = () => {
    handleOpenEditModal(item);
  };

  return (
    <Container>
      <WrapperFatherOption onClick={handleSwitchOpen}>
        <Content>
          <CheckFatterWrapper>
            <FatherCheck type="checkbox" checked={checked} id={item?.name} />
            <LabelCheck active={checked} htmlFor={item?.name} />
          </CheckFatterWrapper>
          <Title>{item?.name}</Title>
        </Content>
        <ActionButton
          dataId={item.id}
          dispatchDelete={handleDeleteDepartament}
          dispatchEdit={handleEditDepartament}
        />
      </WrapperFatherOption>

      <WrapperChildrenOptions open={openDrawer} length={item?.processes.length}>
        {item.processes.map((process, index) => (
          <Option
            departamentId={item.id}
            activeOption={activeOption}
            setActiveOption={setActiveOption}
            item={process}
            position={index}
          />
        ))}
        <NewOptionButton onClick={handleNewProcess}>
          <IconPlus />
          Adicionar Processo
        </NewOptionButton>
      </WrapperChildrenOptions>
    </Container>
  );
};

export default DrawerOptions;

DrawerOptions.propTypes = {
  item: objectOf.isRequired,
  activeOption: arrayOf.isRequired,
  setActiveOption: func.isRequired,
  handleOpenEditModal: func.isRequired,
};
