import React from 'react';
import Grid from '@material-ui/core/Grid';
import { arrayOf, func } from 'prop-types';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import { TertiaryButton } from '~/components/Button';

import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  DeleteButton,
  DeleteIcon,
  WrapperAddButton,
} from './styles';

function AccessControl({ accessControl, setAccessControl }) {
  const newActive = () => {
    setAccessControl((e) => [...e, {}]);
  };

  const deleteActive = (index) => {
    const newArray = [...accessControl];
    newArray.splice(index, 1);
    setAccessControl(newArray);
  };

  const updateControl = (index, updatedControl) => {
    const newArray = accessControl;
    newArray[index] = { title: updatedControl };
    setAccessControl([...newArray]);
  };
  return (
    <Container>
      <Title>{i18next.t('businessUnitAsset.tabs.accessControl.label')}</Title>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t(
              'businessUnitAsset.tabs.accessControl.accessControlLabel'
            )}
          </Label>
        </Grid>
        <Grid item xs={7} />
        {accessControl.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                value={accessControl[index].title}
                onChange={(e) => updateControl(index, e.target.value)}
              />
            </Grid>
            <Grid item xs={7}>
              <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        <TertiaryButton
          onClick={newActive}
          label={i18next.t('common.buttons.add')}
          leftIcon={<IconPlus />}
        />
      </WrapperAddButton>
    </Container>
  );
}

export default AccessControl;

AccessControl.propTypes = {
  accessControl: arrayOf.isRequired,
  setAccessControl: func.isRequired,
};
