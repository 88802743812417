import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { arrayOf, func, objectOf, number, bool } from 'prop-types';

import TextArea from '~/components/TextArea';
import Modal from '~/components/Modal';
import FileInput from '~/components/FileInput';
import { TertiaryButton } from '~/components/Button';

import TreatModal from '../TreatModal';
import RisksModal from '../RisksModal';

import { WrapperFileInput } from './styles';

const ResidualRiskSession = ({
  residualRisk,
  setResidualRisk,
  currentResidualRisk,
  index,
  newTreatment,
}) => {
  const [treatModalOpened, setTreatModalOpened] = useState(false);
  const [risksModalOpened, setRisksModalOpened] = useState(false);

  const handleDescriptionChange = (value) => {
    const updatedResidualRisk = [...residualRisk];
    updatedResidualRisk[index] = {
      ...updatedResidualRisk[index],
      description: value,
    };
    setResidualRisk(updatedResidualRisk);
  };

  const handleEvidenceChange = (value) => {
    const updatedResidualRisk = [...residualRisk];
    updatedResidualRisk[index] = {
      ...updatedResidualRisk[index],
      evidence: value,
    };
    setResidualRisk(updatedResidualRisk);
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextArea
            label={i18next.t(
              'risks.riskDetails.createEvaluationModal.posEvaluationDescription'
            )}
            placeholder="Descrição"
            onChange={(e) => handleDescriptionChange(e.target.value)}
            name="description"
            value={currentResidualRisk.description}
            editable={newTreatment}
          />
        </Grid>

        <Grid item xs={4}>
          {newTreatment && (
            <FileInput
              label={i18next.t(
                'risks.riskDetails.createEvaluationModal.attachEvidence'
              )}
              value={currentResidualRisk.evidence}
              onChange={(e) => handleEvidenceChange(e)}
            />
          )}
        </Grid>
        <Grid item xs={8}>
          <WrapperFileInput>
            <TertiaryButton
              onClick={() => setTreatModalOpened(true)}
              label={
                newTreatment
                  ? i18next.t(
                      'risks.riskDetails.createEvaluationModal.treatResidualRiskButton'
                    )
                  : i18next.t(
                      'risks.riskDetails.createEvaluationModal.treatedResidualRiskButton'
                    )
              }
            />
          </WrapperFileInput>
        </Grid>
      </Grid>

      <Modal open={treatModalOpened} noCloseButton noPadding>
        <TreatModal
          onClose={() => setTreatModalOpened(false)}
          onTreat={() => {
            setTreatModalOpened(false);
            setRisksModalOpened(true);
          }}
          newTreatment={newTreatment}
        />
      </Modal>

      <Modal open={risksModalOpened} noCloseButton motion>
        <RisksModal
          onClose={() => setRisksModalOpened(false)}
          residualRisk={residualRisk}
          setResidualRisk={setResidualRisk}
          currentResidualRisk={currentResidualRisk}
          index={index}
          newTreatment={newTreatment}
        />
      </Modal>
    </>
  );
};

export default ResidualRiskSession;

ResidualRiskSession.propTypes = {
  residualRisk: arrayOf.isRequired,
  setResidualRisk: func.isRequired,
  currentResidualRisk: objectOf.isRequired,
  index: number.isRequired,
  newTreatment: bool.isRequired,
};
