import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.3rem;
`;

export const Content = styled.div`
  margin-top: 2rem;
  flex: 1;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 1.2rem 0.9rem;
  height: 70vh;

  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.4rem;
  margin-right: 1.6rem;
  font-weight: 500;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const DepartamentsWrapper = styled.div`
  margin-top: 2.1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
