import React from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import { func, objectOf } from 'prop-types';

import Executors from './Items/Executors';
import Steps from './Items/Steps';

import { Container } from './styles';

const ExecutionPlan = ({ executionPlan, setExecutionPlan }) => {
  return (
    <AnimateSharedLayout>
      <Container layout>
        <Executors
          executionPlan={executionPlan}
          setExecutionPlan={setExecutionPlan}
        />
        <Steps
          executionPlan={executionPlan}
          setExecutionPlan={setExecutionPlan}
        />
      </Container>
    </AnimateSharedLayout>
  );
};

export default ExecutionPlan;

ExecutionPlan.propTypes = {
  executionPlan: objectOf.isRequired,
  setExecutionPlan: func.isRequired,
};
