import styled from 'styled-components';

export const Container = styled.div``;

export const NumberBox = styled.div`
  background: ${({ theme }) => theme.colors.blueSecondary};
  color: ${({ theme }) => theme.colors.base[100]};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 2rem;
  max-width: 6rem;
  margin: auto;
  margin-top: 2.5rem;
  border-radius: 10px;
`;
