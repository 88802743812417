import { BsFillPlusCircleFill } from 'react-icons/bs';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Padding = styled.div`
  padding-top: 2.5rem;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin: 2.4rem 0;
`;

export const Footer = styled.div`
  margin: 4.1rem 0 2rem;
`;

export const Hr = styled.hr`
  border: 1px solid #ebebeb;
  border-radius: 5px;
  margin: 2rem 0;
  width: 100%;
`;

export const ThirdPartiesWrapper = styled.div``;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px dashed #c6ccd2;
  border-radius: 10px;

  background: ${({ theme }) => theme.colors.base[100]};
  color: ${({ theme }) => theme.colors.base[400]};
  border-radius: 5px;
  gap: 1rem;
  padding: 2.4rem 17.1rem;
  margin: 2rem;
  font-size: 1.6rem;
  width: 150px;
  cursor: pointer;

  :hover {
    filter: brightness(1.1);
  }
`;

export const WrapperButton = styled.div``;

export const PlusIcon = styled(BsFillPlusCircleFill).attrs({
  size: 18,
})`
  color: ${({ theme }) => theme.colors.blueSecondary};
  min-width: 2rem;
`;
