import React from 'react';
import Popover from '@material-ui/core/Popover';
import { number } from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  EyeIcon,
  CalendarIcon,
} from './styles';

function ActionButton({ itemId }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <MoreIcon onClick={handleClick} />
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={() => history.push(`/risks-management/${itemId}`)}>
          <WrapperIcon>
            <EyeIcon />
          </WrapperIcon>
          Revisar
        </Row>
      </Popover>
    </>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  itemId: number.isRequired,
};
