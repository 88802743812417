import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-right: 4rem;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;
