import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 600;
  margin-bottom: 1.9rem;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 400;
`;

export const Tag = styled.div`
  width: 4.7rem;
  height: 1.8rem;

  background: ${({ color }) => color};
  border-radius: 0.5rem;
  margin-right: 1.5rem;
`;
