import React from 'react';
import { Grid } from '@material-ui/core';
import { objectOf, string } from 'prop-types';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Card from '~/components/Card';
import { Column, Text, Row } from '~/styles/components';

import {
  Container,
  Title,
  WrapperItems,
  WrapperItem,
  ItemIcon,
} from './styles';

const HistoryItem = ({ item }) => {
  return (
    <WrapperItem>
      <Row align="center">
        <ItemIcon />
        <Column>
          <Text fontWeight={400} fontSize="1.2rem">
            {format(new Date(item.createdAt), "MMMM dd '|' HH:mm", {
              locale: pt,
            })}
          </Text>
          <Text
            fontWeight={400}
            fontSize="1.2rem"
            color={(theme) => theme.colors.base[400]}
          >
            {`${i18next.t('documents.documentDetails.by')}: ${
              item?.author?.first_name
            }`}
          </Text>
        </Column>
      </Row>
    </WrapperItem>
  );
};

HistoryItem.propTypes = {
  item: objectOf(string).isRequired,
};

function History() {
  const { documentDetails } = useSelector((state) => state.documents);

  return (
    <Grid item xs={12}>
      <Card>
        <Container>
          <Title>{i18next.t('documents.documentDetails.changeHistory')}</Title>
          <WrapperItems>
            {documentDetails?.data?.change_history &&
              documentDetails?.data?.change_history.map((item) => (
                <HistoryItem item={item} />
              ))}
          </WrapperItems>
        </Container>
      </Card>
    </Grid>
  );
}

export default History;
