import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getRisksRequest: ['page'],
  getRisksSuccess: ['risks'],
  getRisksFailure: [''],

  searchRisksRequest: ['query'],
  searchRisksSuccess: ['result'],
  searchRisksFailure: [''],

  getRisksMatrixRequest: [''],
  getRisksMatrixSuccess: ['risksMatrix'],
  getRisksMatrixFailure: [''],

  getRiskDetailRequest: ['id'],
  getRiskDetailSuccess: ['riskDetails'],
  getRiskDetailFailure: [''],

  putRiskToggleRequest: ['id'],
  putRiskToggleSuccess: ['risks'],
  putRiskToggleFailure: [''],

  getRiskHistoryRequest: ['id'],
  getRiskHistorySuccess: ['riskHistory'],
  getRiskHistoryFailure: [''],

  getRiskAssociationsRequest: ['id'],
  getRiskAssociationsSuccess: ['riskAssociations'],
  getRiskAssociationsFailure: [''],

  treatRiskRequest: ['data', 'residualRisk', 'id', 'loadData'],
  treatRiskSuccess: [''],
  treatRiskFailure: [''],

  downloadRisksRequest: [''],
  downloadRisksSuccess: [''],
  downloadRisksFailure: [''],

  getRiskTreatmentDetailsRequest: ['riskId', 'treatmentId', 'setOpenModal'],
  getRiskTreatmentDetailsSuccess: ['riskTreatmentDetails'],
  getRiskTreatmentDetailsFailure: [''],
});

export const RiskTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  risks: {
    count: 0,
    rows: [],
  },
  risksMatrix: [],
  riskDetails: {},
  riskHistory: [],
  riskAssociations: {},
  riskTreatmentDetails: {},
});

/* Reducers */

const getRisksRequest = (state) => state.merge({ isLoading: true });
const getRisksSuccess = (state, { risks }) =>
  state.merge({
    isLoading: false,
    error: false,
    risks,
  });
const getRisksFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    risks: {
      count: 0,
      rows: [],
    },
  });

const searchRisksRequest = (state) => state.merge({ isLoading: true });
const searchRisksSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    risks: result,
  });
const searchRisksFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    risks: {
      count: null,
      rows: [],
    },
  });

const getRisksMatrixRequest = (state) => state.merge({ isLoading: true });
const getRisksMatrixSuccess = (state, { risksMatrix }) =>
  state.merge({
    isLoading: false,
    error: false,
    risksMatrix,
  });
const getRisksMatrixFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    risksMatrix: [],
  });

const getRiskDetailRequest = (state) => state.merge({ isLoading: true });
const getRiskDetailSuccess = (state, { riskDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    riskDetails,
  });
const getRiskDetailFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    riskDetails: {},
  });

const putRiskToggleRequest = (state) => state.merge({ isLoading: true });
const putRiskToggleSuccess = (state, { risks }) =>
  state.merge({
    isLoading: false,
    error: false,
    risks,
  });
const putRiskToggleFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getRiskHistoryRequest = (state) => state.merge({ isLoading: true });
const getRiskHistorySuccess = (state, { riskHistory }) =>
  state.merge({
    isLoading: false,
    error: false,
    riskHistory,
  });

const getRiskHistoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    riskHistory: [],
  });

const getRiskAssociationsRequest = (state) => state.merge({ isLoading: true });
const getRiskAssociationsSuccess = (state, { riskAssociations }) =>
  state.merge({
    isLoading: false,
    error: false,
    riskAssociations,
  });

const getRiskAssociationsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    riskAssociations: {},
  });

const treatRiskRequest = (state) => state.merge({ isLoading: true });
const treatRiskSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const treatRiskFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadRisksRequest = (state) => state.merge({ isLoading: true });
const downloadRisksSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });

const downloadRisksFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getRiskTreatmentDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const getRiskTreatmentDetailsSuccess = (state, { riskTreatmentDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    riskTreatmentDetails,
  });

const getRiskTreatmentDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    riskTreatmentDetails: {},
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_RISKS_REQUEST]: getRisksRequest,
  [Types.GET_RISKS_SUCCESS]: getRisksSuccess,
  [Types.GET_RISKS_FAILURE]: getRisksFailure,

  [Types.SEARCH_RISKS_REQUEST]: searchRisksRequest,
  [Types.SEARCH_RISKS_SUCCESS]: searchRisksSuccess,
  [Types.SEARCH_RISKS_FAILURE]: searchRisksFailure,

  [Types.GET_RISKS_MATRIX_REQUEST]: getRisksMatrixRequest,
  [Types.GET_RISKS_MATRIX_SUCCESS]: getRisksMatrixSuccess,
  [Types.GET_RISKS_MATRIX_FAILURE]: getRisksMatrixFailure,

  [Types.GET_RISK_DETAIL_REQUEST]: getRiskDetailRequest,
  [Types.GET_RISK_DETAIL_SUCCESS]: getRiskDetailSuccess,
  [Types.GET_RISK_DETAIL_FAILURE]: getRiskDetailFailure,

  [Types.PUT_RISK_TOGGLE_REQUEST]: putRiskToggleRequest,
  [Types.PUT_RISK_TOGGLE_SUCCESS]: putRiskToggleSuccess,
  [Types.PUT_RISK_TOGGLE_FAILURE]: putRiskToggleFailure,

  [Types.GET_RISK_HISTORY_REQUEST]: getRiskHistoryRequest,
  [Types.GET_RISK_HISTORY_SUCCESS]: getRiskHistorySuccess,
  [Types.GET_RISK_HISTORY_FAILURE]: getRiskHistoryFailure,

  [Types.GET_RISK_ASSOCIATIONS_REQUEST]: getRiskAssociationsRequest,
  [Types.GET_RISK_ASSOCIATIONS_SUCCESS]: getRiskAssociationsSuccess,
  [Types.GET_RISK_ASSOCIATIONS_FAILURE]: getRiskAssociationsFailure,

  [Types.TREAT_RISK_REQUEST]: treatRiskRequest,
  [Types.TREAT_RISK_SUCCESS]: treatRiskSuccess,
  [Types.TREAT_RISK_FAILURE]: treatRiskFailure,

  [Types.DOWNLOAD_RISKS_REQUEST]: downloadRisksRequest,
  [Types.DOWNLOAD_RISKS_SUCCESS]: downloadRisksSuccess,
  [Types.DOWNLOAD_RISKS_FAILURE]: downloadRisksFailure,

  [Types.GET_RISK_TREATMENT_DETAILS_REQUEST]: getRiskTreatmentDetailsRequest,
  [Types.GET_RISK_TREATMENT_DETAILS_SUCCESS]: getRiskTreatmentDetailsSuccess,
  [Types.GET_RISK_TREATMENT_DETAILS_FAILURE]: getRiskTreatmentDetailsFailure,
});
