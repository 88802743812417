import React from 'react';
import Popover from '@material-ui/core/Popover';

import i18next from 'i18next';

import { func, string } from 'prop-types';
import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  DeleteIcon,
  Content,
} from './styles';

function ActionButton({ dataId, dispatchDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDispacthDelete = () => {
    dispatchDelete(dataId);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container onClick={handleClick}>
      <Content>
        <MoreIcon />
      </Content>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={() => handleDispacthDelete()}>
          <WrapperIcon>
            <DeleteIcon />
          </WrapperIcon>
          {i18next.t('peopleAsset.table.action.delete')}
        </Row>
      </Popover>
    </Container>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  dataId: string.isRequired,
  dispatchDelete: func.isRequired,
};
