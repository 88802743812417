import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
`;

export const WrapperBar = styled.div`
  flex: 1;
  height: 0.6rem;
  display: flex;
  border-radius: 10px;
  background-color: ${({ color }) =>
    color.includes('#')
      ? `${color}3D`
      : color.replace(')', ', 0.24)').replace('rgb', 'rgba')};
  overflow: hidden;
`;

export const Bar = styled.div`
  flex: ${({ size }) => size};
  background-color: ${({ color }) => color};
  border-radius: 10px;

  transition: all 300ms ease;
`;
