import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import history from '~/routes/history';
// import LanguageSelector from '~/components/LanguageSelector';

import getQueryStringParams from '~/utils/getQueryStringParams';

// import Steps from './Steps';

import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  LogoWrapper,
  Title,
  FormWrapper,
  // WrapperProgress,
  GoBackWrapper,
} from './styles';
import Registration from './Components/Registration';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SignUpUser = () => {
  const location = useLocation();
  const params = getQueryStringParams(location.search);

  const handleSigInRedirect = useCallback(() => {
    history.push('/confirm-email');
  }, []);

  const [
    step,
    //  setStep
  ] = useState(() => {
    if (params.step === '3') {
      return 3;
    }
    return 1;
  });

  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (recaptchaRef?.current) recaptchaRef.current.execute();
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <Container>
        <BG />
        <LogoWrapper>
          <Logo />
          <TitleWrapper>
            <Title>{i18next.t('common.signUp.title1')}</Title>
            <Title>{i18next.t('common.signUp.title2')}</Title>
            <Title>{i18next.t('common.signUp.title3')}</Title>
          </TitleWrapper>
        </LogoWrapper>
        <Content>
          <FormWrapper>
            <GoBackWrapper
              onClick={() => {
                if (step === 1) {
                  history.push('/');
                }
              }}
            >
              <span>{'<'}</span>
              <span>{i18next.t('common.buttons.goBack')}</span>
            </GoBackWrapper>
            <Registration onSubmit={handleSigInRedirect} />
          </FormWrapper>
        </Content>
      </Container>
      {/* <LanguageSelector /> */}
    </Elements>
  );
};

export default SignUpUser;
