/* eslint-disable no-param-reassign */
import axios from 'axios';
import store from '~/store';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
      : process.env.REACT_APP_API_PROD_ENDPOINT,
});

api.interceptors.request.use(
  (config) => {
    const convertedAuth = store.getState().auth;
    if (convertedAuth.userActive.token) {
      config.headers.Authorization = `Bearer ${convertedAuth.userActive.token}`;
    } else if (convertedAuth.clientActive.token) {
      config.headers.Authorization = `Bearer ${convertedAuth.clientActive.token}`;
    }
    if (convertedAuth.companyId) {
      config.headers.companyId = convertedAuth.companyId;
    } else if (convertedAuth.clientCompanyId) {
      config.headers.companyId = convertedAuth.clientCompanyId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      error?.response?.error === 'Token invalid'
    ) {
      localStorage.removeItem('persist:root');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
