import React, { useState } from 'react';
import { string, func, number, bool, objectOf } from 'prop-types';

import useDebounce from '~/utils/useDebounce';

import { Container, SearchIcon, Input } from './styles';

function Search({
  placeholder,
  value,
  onChange,
  delay,
  hasDebounce,
  style,
  inputRef,
  variant,
}) {
  const [displayValue, setDisplayValue] = useState(value);
  const debouncedChange = useDebounce(onChange, delay);

  const handleChange = ({ target }) => {
    setDisplayValue(target.value);
    if (hasDebounce) {
      debouncedChange(target.value);
    }
  };

  return (
    <Container style={style} variant={variant}>
      <SearchIcon />
      <Input
        ref={inputRef}
        placeholder={placeholder}
        value={displayValue}
        onChange={(e) => handleChange(e)}
      />
    </Container>
  );
}

export default Search;

Search.propTypes = {
  placeholder: string,
  value: string,
  onChange: func,
  delay: number,
  hasDebounce: bool,
  style: objectOf,
  inputRef: objectOf,
  variant: string,
};

Search.defaultProps = {
  placeholder: 'Pesquisar',
  value: null,
  onChange: () => {},
  delay: 0,
  hasDebounce: false,
  style: {},
  inputRef: {},
  variant: 'primary',
};
