import styled from 'styled-components';

export const Container = styled.div`
  height: 15rem;
  background: #ffffff;
  border-radius: 10px;
  padding: 2.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 4px 20px #c9c9c9;

  &:hover {
    /* box-shadow: 0px 4px 80px rgba(38, 42, 58, 0.08); */
    box-shadow: 8px 8px 11px #e6e6e8, -8px -8px 11px #ffffff;
    padding: 2.3rem;
    background: #fcfcfc;
  }

  &:active {
    transform: scale(1.01);
  }

  transition: 200ms ease;

  ${({ theme }) => theme.notSelectable}
`;

export const WrapperTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-size: 1.4rem;
  font-weight: 400;
  color: #c6ccd2;
`;

export const WrapperBottom = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const Subtitle = styled.div`
  color: rgba(14, 6, 125, 0.3);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;
`;

export const Title = styled.div`
  color: #0e067d;
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
`;

export const DocumentAmount = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: #c6ccd2;
  margin-top: 1rem;
  text-align: right;
`;
