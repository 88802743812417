import * as Yup from 'yup';
import i18next from 'i18next';

export default Yup.object().shape({
  name: Yup.string().required(i18next.t('common.validation.required')),
  managers: Yup.array().required(i18next.t('common.validation.required')),
  description: Yup.string().required(i18next.t('common.validation.required')),
  processes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(i18next.t('common.validation.required')),
      responsibles: Yup.array().required(
        i18next.t('common.validation.required')
      ),
    })
  ),
});
