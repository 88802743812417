import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';

import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import i18next from 'i18next';
import PageNavigator from '~/components/PageNavigator';
import Table from '~/components/Table/Tertiary';
import Card from '~/components/Card';
import { dateSortMethod } from '~/utils';
import DocumentActions from '~/store/ducks/documents';
import history from '~/routes/history';
import { SecondarySelect } from '~/components/Select';
import ActionButton from '../../components/ActionButton';
import DeleteActionModal from '../../components/DeleteActionModal';
import MoveDocumentModal from '../../components/MoveDocumentModal';

import {
  Container,
  WrapperLogo,
  Logo,
  NoData,
  WrapperNavigator,
  TotalItems,
  AreaSelect,
} from './styles';

const AllDocuments = ({ reloadDocuments, id, search }) => {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openMoveDocumentModal, setOpenMoveDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { folderDetails } = useSelector((state) => state.documents);
  const [currentPage, setCurrentPage] = useState(0);
  const [limitPage, setLimitPage] = useState(6);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(
      DocumentActions.getFolderDetailsRequest(id, currentPage, limitPage)
    );
  }, [limitPage]);

  useEffect(() => {
    if (search === '') {
      dispatch(
        DocumentActions.getFolderDetailsRequest(id, currentPage , limitPage)
      );
    } else {
      dispatch(DocumentActions.searchFolderDetailsRequest(id, search));
    }
  }, [search, currentPage]);

  return (
    <>
      <Container>
        {folderDetails && folderDetails?.data?.count > 0 ? (
          <Card padding="2rem 0">
            <Table
              columns={[
                {
                  Header: i18next.t('documents.table.name'),
                  accessor: 'name',
                  Cell: (e) => (
                    <WrapperLogo>
                      <Logo src="https://cdn.iconscout.com/icon/free/png-256/word-2-190833.png" />
                      {e?.row?.original?.name}
                    </WrapperLogo>
                  ),
                },
                {
                  Header: i18next.t('documents.table.date'),
                  accessor: 'createdAt',
                  Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
                  sortType: (a, b) => {
                    return dateSortMethod(a.values.date, b.values.date);
                  },
                },
                {
                  Header: i18next.t('documents.table.responsible'),
                  accessor: 'author.first_name',
                },
                {
                  Header: i18next.t('documents.table.lastView'),
                  accessor: 'last_view',
                  Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
                  sortType: (a, b) => {
                    return dateSortMethod(a.values.date, b.values.date);
                  },
                },
                {
                  Header: '',
                  accessor: 'options',
                  Cell: (e) => (
                    <ActionButton
                      folderId={folderDetails?.id}
                      item={e.row.original}
                      setOpenDeleteModal={setOpenDeleteModal}
                      setOpenMoveDocumentModal={setOpenMoveDocumentModal}
                      setSelectedDocument={setSelectedDocument}
                    />
                  ),
                },
              ]}
              data={
                folderDetails?.data?.count > 0 ? folderDetails?.data?.rows : []
              }
              onRowClick={(item) => {
                history.push(
                  `/document-management/documents/document-details/${item.id}`
                );
              }}
            />
            <WrapperNavigator>
              <TotalItems>Total: {folderDetails?.data?.count}</TotalItems>

              <PageNavigator
                count={folderDetails?.data?.total_pages}
                onChangePage={(newPage) => handlePagination(newPage)}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                handlePagination={handlePagination}
              />
              <AreaSelect>
                <SecondarySelect
                  label={i18next.t('documents.limitPerPage')}
                  placeholder="ex: 6"
                  name="limit"
                  options={[
                    { label: '6', value: 6 },
                    { label: '12', value: 12 },
                    { label: '24', value: 24 },
                    { label: '36', value: 36 },
                  ]}
                  value={limitPage}
                  onChange={(e) => setLimitPage(e)}
                />
              </AreaSelect>
            </WrapperNavigator>
          </Card>
        ) : (
          <NoData>{i18next.t('documents.noDocumentsInFolder')}</NoData>
        )}
      </Container>
      <DeleteActionModal
        document={selectedDocument}
        open={!!openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        setOpenModal={setOpenDeleteModal}
        reloadDocuments={reloadDocuments}
      />
      <MoveDocumentModal
        oldId={id}
        document={selectedDocument}
        open={!!openMoveDocumentModal}
        onClose={() => setOpenMoveDocumentModal(false)}
        setOpenMoveDocumentModal={setOpenMoveDocumentModal}
        reloadDocuments={reloadDocuments}
      />
    </>
  );
};

export default AllDocuments;

AllDocuments.propTypes = {
  reloadDocuments: func.isRequired,
  search: string.isRequired,
};
