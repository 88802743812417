import styled from 'styled-components';

export const Container = styled.div``;

export const BottomWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 7rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
`;

export const Subtitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 600;

  margin-bottom: 2rem;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 21px 32px 100px 32px;
  background: #ffffff;
  border-radius: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 5rem;
  align-items: flex-end;
`;
