import React from 'react';
import { arrayOf, objectOf, string, bool, func } from 'prop-types';
import ReactSelect from 'react-select';

import { Container, Label, Error } from './styles';

function Select({
  options,
  placeholder,
  label,
  touched,
  error,
  onChange,
  value,
  margin,
  oneLineLabel,
  disabled,
}) {
  const colourStyles = {
    control: () => ({
      label: 'control',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: '1rem',
      boxShadow: null,
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '5rem',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 200ms',
      border: '1px solid rgba(198, 204, 210, 0.3)',
      boxSizing: 'border-box',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#262A3A',
      '&:hover': { color: '#0E067D' },
      opacity: disabled ? 0 : 1,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.4rem',
      fontWeight: 400,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: isSelected ? '#fff' : '#606474',
      fontSize: '1.4rem',
      fontWeight: isSelected ? 700 : 400,
      cursor: 'pointer',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#262A3A',
      fontSize: '1.4rem',
      fontWeight: 500,
      opacity: disabled ? 0.3 : 1,
    }),
  };

  const isError = Boolean(touched && error);

  return (
    <Container error={isError} label={label} margin={margin}>
      {label && <Label oneLineLabel={oneLineLabel}>{label}</Label>}

      <ReactSelect
        options={[...options]}
        placeholder={placeholder}
        styles={colourStyles}
        onChange={(e) => onChange(e.value)}
        value={options.find(
          (e) => JSON.stringify(e.value) === JSON.stringify(value)
        )}
        isDisabled={disabled}
      />
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

export default Select;

Select.propTypes = {
  options: arrayOf(objectOf(string)),
  placeholder: string,
  label: string,
  touched: bool,
  error: string,
  onChange: func,
  value: string,
  margin: string,
  oneLineLabel: bool,
  disabled: bool,
};

Select.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  label: '',
  touched: false,
  error: '',
  onChange: () => {},
  value: '',
  margin: '0',
  oneLineLabel: true,
  disabled: false,
};
