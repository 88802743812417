import React from 'react';
import { string, number } from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';

import building from '~/assets/svgs/building.svg';

import { PrimaryButton } from '~/components/Button';

import { Container, ImageWrapper, Image, CompanyName } from './styles';

const CompanyCard = ({ name, id, role, logo }) => {
  const dispatch = useDispatch();

  const handleSelect = () => {
    dispatch(AuthActions.selectCompanyRequest(id, role));
  };

  return (
    <Container>
      <ImageWrapper>
        <Image
          src={
            logo
              ? `${
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                    : process.env.REACT_APP_API_PROD_ENDPOINT
                }users/avatar?avatar=${logo}`
              : building
          }
          alt={name}
        />
      </ImageWrapper>
      <CompanyName>
        {name.length > 15 ? `${name.substring(0, 15)} ...` : name}
      </CompanyName>

      <PrimaryButton
        label={i18next.t('common.buttons.go')}
        margin="0 0"
        padding="0 0"
        small
        onClick={handleSelect}
      />
    </Container>
  );
};

export default CompanyCard;

CompanyCard.propTypes = {
  name: string.isRequired,
  id: number.isRequired,
  role: string.isRequired,
  logo: string.isRequired,
};
