import styled from 'styled-components';
import { FiMoreHorizontal } from 'react-icons/fi';

export const Container = styled.div``;

export const RowTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const Total = styled.div`
  color: ${({ theme }) => theme.colors.base[500]}80;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: contain;
  margin-right: 1.2rem;
`;

export const MoreIcon = styled(FiMoreHorizontal).attrs({
  size: '3rem',
  color: '#2E3A59',
})``;

export const NoData = styled.p`
  color: #606474;
`;

export const WrapperNavigator = styled.div`
  display: flex;
  width: 100%;
  margin: 5rem 0 3.2rem 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const TotalItems = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const AreaSelect = styled.div`
  width: 130px;
`;
