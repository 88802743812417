import styled, { css } from 'styled-components';
import { CgFile } from 'react-icons/cg';
import { IoTrashOutline } from 'react-icons/io5';

export const Container = styled.div.attrs({ layout: true })`
  width: 80vw;
  max-width: 100%;
  margin-bottom: 2rem;
`;

export const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafbfb;
  border: 2px dashed #c6ccd2;
  box-sizing: border-box;
  border-radius: 10px;
  height: 25rem;
  max-width: 80%;
  cursor: pointer;

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      box-shadow: inset 6px 6px 15px #f5f5f5, inset -6px -6px 15px #ffffff;
      transform: scale(0.99);
      cursor: drop;
    `};

  transition: 200ms ease;
`;

export const FileIcon = styled(CgFile).attrs({ color: '#606474', size: 24 })``;

export const DropText = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 400;
  margin-top: 0.8rem;
`;

export const WrapperFile = styled.div`
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
  margin-right: 2rem;
  flex: 1;
  overflow: hidden;
`;

export const FileSize = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
  white-space: nowrap;
`;

export const FileDate = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #c4c4c4;
`;

export const Column = styled.div``;

export const DeleteButton = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: #f7f7f7;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ theme }) => theme.clickable};
  margin-left: 3rem;
`;

export const DeleteIcon = styled(IoTrashOutline).attrs({ size: 20 })``;
