import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import {
  dateSortMethod,
  // formatters
} from '~/utils';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import ProvidersActions from '~/store/ducks/provider';
// import { IconProfilePlaceholder } from '~/assets/icons';
import { format } from 'date-fns';
import { func, string } from 'prop-types';
import ActionButton from '../ActionButton';
import { Container } from './styles';

const TableComp = ({ search, setEditProvider, setProviderModal }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const { providers } = useSelector((state) => state.provider);
  const limit = 10;

  const handlePagination = (nextPage) => {
    setCurrentPage(nextPage);
  };

  const handleEditProvider = (providerEdit) => {
    setEditProvider(providerEdit);
    setProviderModal(true);
  };

  const handleDelete = (id) => {
    dispatch(ProvidersActions.deleteProviderRequest(id));
  };

  useEffect(() => {
    dispatch(ProvidersActions.getProvidersRequest(currentPage, limit, search));
  }, [search, currentPage]);

  return (
    <>
      <Container>
        <Card padding="2rem 0">
          <Table
            columns={[
              {
                Header: i18next.t('thirdPartyManagement.tab.name'),
                accessor: 'name',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('thirdPartyManagement.tab.creationDate'),
                accessor: 'createdAt',
                Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
                sortType: (a, b) => {
                  return dateSortMethod(a.values.date, b.values.date);
                },
              },
              {
                Header: i18next.t('thirdPartyManagement.tab.email'),
                accessor: 'email',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('thirdPartyManagement.tab.site'),
                accessor: 'site',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('peopleAsset.table.action.label'),
                accessor: 'action',
                Cell: (e) => {
                  if (!e.value) {
                    return (
                      <ActionButton
                        dataId={e.row.original?.id}
                        dispatchDelete={handleDelete}
                      />
                    );
                  }
                  return null;
                },
              },
            ]}
            data={providers.rows || []}
            onRowClick={handleEditProvider}
            total={providers?.count ?? 0}
            pageCount={providers.total_page}
            handlePagination={handlePagination}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage + 1}
          />
        </Card>
      </Container>
    </>
  );
};

TableComp.propTypes = {
  search: string,
  setEditProvider: func.isRequired,
  setProviderModal: func.isRequired,
};

TableComp.defaultProps = {
  search: null,
};

export default TableComp;
