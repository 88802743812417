/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-alert */
import React from 'react';
import { oneOfType, number, string, bool, func } from 'prop-types';

import { Container, Input, Error, Label } from './styles';

export default function TextArea({
  required,
  width,
  name,
  autoComplete,
  placeholder,
  touched,
  error,
  value,
  onChange,
  onSubmit,
  type,
  margin,
  label,
  mask,
  editable,
  maxHeight,
  maxlength,
  sm,
}) {
  const isError = Boolean(touched && error);
  return (
    <>
      <Container error={isError} margin={margin} label={label}>
        {label && (
          <Label sm={sm} required={required}>
            {label}
          </Label>
        )}
        <Input
          maxlength={maxlength}
          readOnly={!editable}
          required={required}
          width={width}
          name={name}
          autoComplete={autoComplete}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
          type={type}
          error={isError}
          mask={mask}
          maskPlaceholder={null}
          maxHeight={maxHeight}
        />
        <Error error={isError}>{error}</Error>
      </Container>
    </>
  );
}

TextArea.propTypes = {
  sm: false,
  required: bool,
  width: string,
  name: string,
  autoComplete: string,
  placeholder: string,
  touched: bool,
  error: string,
  value: string,
  type: string,
  onChange: func,
  onSubmit: func,
  margin: oneOfType([string, number]),
  label: string,
  mask: string,
  editable: bool,
  maxHeight: string,
  maxlength: string,
};

TextArea.defaultProps = {
  required: false,
  maxlength: '10000',
  width: '200px',
  name: '',
  autoComplete: '',
  placeholder: '',
  touched: false,
  error: '',
  value: '',
  type: 'text',
  onChange: () => {},
  onSubmit: () => {},
  margin: '0.2rem 0',
  label: '',
  mask: null,
  editable: true,
  maxHeight: null,
  sm: bool,
};
