import styled from 'styled-components';

export const Container = styled.div`
  width: 527px;
  padding: 4.5rem 3.8rem;
`;

export const WrapperTop = styled.div`
  margin-bottom: 2rem;
`;

export const Description = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
`;

export const ButtonsArea = styled.div`
  height: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 10%;
`;

export const RadioButtonArea = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const RadioArea = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
`;

export const RadioButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 21px;
  margin-left: 10px;
  color: #606474;
`;

export const FormArea = styled.div``;
