import React from 'react';
import Grid from '@material-ui/core/Grid';
import { objectOf, oneOfType, any, func, bool } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';

import { toPhone } from '~/utils/formatters';
import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';

import schema from './validationSchema';

import { Container, WrapperButton } from './styles';

function EditModal({ user, onClose, open, setOpenModal }) {
  return (
    <Modal title="Informações básicas" noPadding open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={{
          id: user?.id || '',
          name: user?.name || '',
          cpf: user?.cpf || '',
          email: user?.email || '',
          phone: user?.phone ? toPhone(user.phone) : '',
        }}
        validationSchema={schema}
      >
        {({ values }) => (
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={2}>
                <TextInput
                  label="ID"
                  placeholder="12"
                  name="id"
                  value={values.id}
                  editable={false}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  label={i18next.t('common.fields.name')}
                  placeholder="João Santos"
                  name="name"
                  value={values.name}
                  editable={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label="CPF"
                  placeholder="Ex.: CPF"
                  mask="999.999.999-99*"
                  name="cpf"
                  value={values.cpf}
                  editable={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label={i18next.t('common.fields.email')}
                  placeholder="Ex.: meuemail@email.com.br"
                  name="email"
                  value={values.email}
                  editable={false}
                />
              </Grid>
              <Grid item xs={6}>
                <TextInput
                  label={i18next.t('common.fields.phone')}
                  placeholder="Ex.: (21) 99999-9999"
                  mask={
                    values.phone.length < 15
                      ? '(99) 9999-9999*'
                      : '(99) 99999-9999'
                  }
                  name="phone"
                  value={values.phone}
                  editable={false}
                />
              </Grid>
            </Grid>
            <WrapperButton>
              <PrimaryButton
                label="OK"
                padding="0 5.8rem"
                width="null"
                onClick={() => setOpenModal(false)}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default EditModal;

EditModal.propTypes = {
  user: objectOf(oneOfType([any])).isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  setOpenModal: func.isRequired,
};
