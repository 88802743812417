import styled, { css } from 'styled-components';
import { IconDocument, IconDownload } from '~/assets/svgs/index';

export const Container = styled.div`
  width: 100%;
  margin-top: 7.1rem;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;

  margin-bottom: 2.3rem;
`;

export const MessageContainer = styled.div`
  margin-bottom: 2rem;

  width: fit-content;
  ${(props) =>
    props.isResponsibleMsg
      ? css`
          margin-right: auto;
          span {
            margin-left: 8.3rem;
          }
        `
      : css`
          margin-left: auto;
        `};

  strong {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 4.1rem;
    color: ${({ theme }) => theme.colors.base[400]};
  }
  span {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 4.1rem;
    color: ${({ theme }) => theme.colors.base[400]};
  }
`;

export const MessageTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3rem;
`;

export const MessageText = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.base[400]};

  padding: 1.4rem 5.4rem 2.1rem 2rem;
  border-radius: 0.5rem;

  background: ${(props) =>
    props.isResponsibleMsg ? '#F2F9FF' : 'rgba(251, 237, 215, 0.48)'};

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const UserAvatar = styled.img`
  height: 5rem;
  width: 5rem;
  border-radius: 0.5rem;
  object-fit: cover;
`;

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: auto;

  margin-top: 2.5rem;
`;

export const File = styled.div`
  background: white;
  border-radius: 1rem;
  width: 25.1rem;

  border: 1px solid rgba(198, 204, 210, 0.3);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  padding: 0 1.6rem;

  span {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 4.1rem;
    color: ${({ theme }) => theme.colors.base[500]};

    flex-grow: 2;
  }

  transition: all 0.2s;

  &:hover {
    box-shadow: 0 0 1rem rgba(198, 204, 210, 0.7);
    cursor: pointer;
  }
`;

export const DocumentIcon = styled(IconDocument).attrs(() => ({
  color: 'red',
  size: 32,
}))`
  align-self: center;
  padding: 0.4rem 0.4rem;
  border-radius: 0.5rem;
  background: #e8f1f8;
`;

export const DownloadIcon = styled(IconDownload)`
  fill: #606474;
`;
