import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoChevronDown, IoSearch } from 'react-icons/io5';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
  padding-bottom: ${({ error }) => (error ? 2.5 : 0)}rem;
  transition: all 300ms ease;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionAvatar = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const OptionLabel = styled.span`
  margin-left: 1.5rem;
  font-weight: 500;
  font-size: 1.4rem;
`;

export const Label = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  ${({ oneLineLabel }) =>
    oneLineLabel &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4rem;
    `};

  width: 100%;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: red;
      }
    `}
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;

export const ButtonAdd = styled.div`
  padding: 1rem 0;
  color: white;
  background: ${({ theme }) => theme.colors.blueSecondary};
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  font-size: 1.4rem;
  font-weight: 400;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.blueSecondary};
    border: 1px solid ${({ theme }) => theme.colors.blueSecondary};
    transform: scale(1.02);
  }
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 16 })``;

export const SearchIcon = styled(IoSearch).attrs({
  size: 16,
})``;

export const ArrowIcon = styled(IoChevronDown).attrs({
  size: 16,
})``;
