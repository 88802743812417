import styled from 'styled-components';

export const Container = styled.div`
  width: 72.5rem;
  /* max-width: 60rem; */
  padding: 4.5rem 3.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  /* width: 350px; */
`;

export const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
`;

export const Subtitle = styled.h1`
  font-size: 18px;
  text-align: center;
  font-weight: 400;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.6rem;

  button {
    margin-right: 1.5rem;
  }
`;
