import styled from 'styled-components';

export const Container = styled.div`
  width: 72.5rem;
  /* max-width: 60rem; */
  padding: 4.5rem 3.8rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.6rem;

  button {
    margin-right: 1.5rem;
  }
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const ScoreQuestion = styled.h3`
  margin-top: 2.5rem;
  text-align: center;

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 600;
  font-size: 2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.base[500]};
`;

export const ScoreWrapper = styled.div`
  margin: 4.2rem auto 4.6rem;

  display: flex;
  justify-content: space-around;
`;

export const ScoreIconButton = styled.button`
  background: none;
  border: ${(props) =>
    props.selected ? '5px solid #539ada' : '5px solid #fff'};
  border-radius: 2rem;

  transition: border-color 0.2s;

  padding: 1.5rem;
`;

export const ScoreIcon = styled.img``;

export const OpinionQuestion = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 4.1rem;
  color: ${({ theme }) => theme.colors.base[400]};
`;
