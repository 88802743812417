import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { IconLogout } from '~/assets/svgs/index';

export const Container = styled.div`
  background: linear-gradient(#fafafc 80%, #ffffff00);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3.8rem 3.5%;
  z-index: 100;
  display: flex;
  align-items: center;

  transition: 300ms ease;
`;

export const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export const BackIcon = styled(IoIosArrowBack).attrs({
  size: '1.4rem',
  color: '#200E32',
})`
  margin-right: 2rem;
`;

export const BackText = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.8rem;
  font-weight: 400;
`;

export const PCSLogo = styled.img``;

export const LogOutButton = styled.button`
  background: ${({ theme }) => theme.colors.blueSecondary};
  color: white;
  border: none;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  height: 4rem;
  width: 8rem;
  border-radius: 8px;
  padding-left: 0.8rem;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
  }
`;

export const LogOutText = styled.span`
  margin-left: 0.8rem;
`;

export const LogOutIcon = styled(IconLogout).attrs(({ theme }) => ({
  size: 24,
}))``;

export const ClientMenuWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
