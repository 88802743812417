import * as Yup from 'yup';

export default Yup.object().shape({
  password: Yup.string()
    .min(6, 'Mínimo de 6 dígitos')
    .required('Campo obrigatório'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não correspondem')
    .required('Campo obrigatório'),
});
