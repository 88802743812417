import React, { useEffect } from 'react';
import history from '~/routes/history';
import { Formik } from 'formik';
import i18next from 'i18next';
import TextInput from '~/components/TextInput';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from '~/store/ducks/auth';
import UserHeader from '~/components/UserHeader';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import schema from './validationSchema';

import {
  Modal,
  Title,
  Subtitle,
  Content,
  ButtonsArea,
  FormArea,
  Container,
} from './styles';

function Step1() {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(AuthActions.clearCompanyActiveRequest());
  }, []);

  const companyData = (company) => {
    history.push('/pay-checkout', { company });
  };

  const submit = (values) => {
    const data = {
      ...values,
      cnpj: values.document.replace(/\D/g, ''),
    };

    dispatch(AuthActions.createCompanyRequest(data, companyData));
  };

  const navigatePage = () => {
    history.push({
      pathname: '/panel',
    });
  };

  return (
    <>
      <Container>
        <UserHeader progress progressStatus={1} />
        <Content>
          <Title>{i18next.t('configurations.checkoutStep1.title')}</Title>
          <br />
          <Subtitle>
            {i18next.t('configurations.checkoutStep1.subtitle')}
          </Subtitle>
          <Modal>
            <Formik
              initialValues={{
                name: '',
                document: '',
              }}
              onSubmit={submit}
              validationSchema={schema}
            >
              {({ values, touched, errors, handleChange, handleSubmit }) => (
                <FormArea>
                  <TextInput
                    placeholder={i18next.t(
                      'configurations.checkoutStep1.NameCompany'
                    )}
                    name="name"
                    value={values.name}
                    touched={touched.name}
                    error={errors.name}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    margin="0 0 2.1rem 0"
                  />
                  <TextInput
                    placeholder={i18next.t(
                      'configurations.checkoutStep1.DocumentCompany'
                    )}
                    name="document"
                    mask="99.999.999/9999-99"
                    value={values.document}
                    touched={touched.document}
                    error={errors.document}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    margin="0 0 2.1rem 0"
                  />
                  <ButtonsArea>
                    <SecondaryButton
                      style={{ width: '45%' }}
                      type="submit"
                      label={i18next.t('common.buttons.goBack')}
                      onClick={navigatePage}
                    />
                    <PrimaryButton
                      style={{ width: '45%' }}
                      type="submit"
                      disabled={isLoading}
                      label={
                        isLoading ? (
                          <SppinerIcon />
                        ) : (
                          i18next.t('common.buttons.next')
                        )
                      }
                      onClick={handleSubmit}
                    />
                  </ButtonsArea>
                </FormArea>
              )}
            </Formik>
          </Modal>
        </Content>
      </Container>
    </>
  );
}

export default Step1;
