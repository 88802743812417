import i18next from 'i18next';

const months = [
  i18next.t('dashboard.tickets.jan'),
  i18next.t('dashboard.tickets.feb'),
  i18next.t('dashboard.tickets.mar'),
  i18next.t('dashboard.tickets.apr'),
  i18next.t('dashboard.tickets.may'),
  i18next.t('dashboard.tickets.jun'),
  i18next.t('dashboard.tickets.jul'),
  i18next.t('dashboard.tickets.aug'),
  i18next.t('dashboard.tickets.sep'),
  i18next.t('dashboard.tickets.oct'),
  i18next.t('dashboard.tickets.nov'),
  i18next.t('dashboard.tickets.dec'),
];

export default {
  chart: {
    type: 'areaspline',
    height: 250,
  },
  title: {
    text: '',
  },
  xAxis: {
    categories: [
      months[0],
      months[1],
      months[2],
      months[3],
      months[4],
      months[5],
      months[6],
      months[7],
      months[8],
      months[9],
      months[10],
      months[11],
    ],
  },
  yAxis: {
    title: null,
    labels: {
      enabled: false,
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    /* crosshairs: true, */
    /* shared: true */
  },
  plotOptions: {
    areaspline: {
      marker: {
        symbol: 'circle',
        enabled: true,
        fillColor: '#262A3A',
        height: 16,
        lineWidth: 4,
        lineColor: '#fff',
        radius: 10,
      },
      lineWidth: 4,
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, 'rgba(255, 193, 33, 0.1)'],
          [1, 'rgba(255, 255, 255, 0)'],
        ],
      },
    },
  },
};
