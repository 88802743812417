import React, { useState, useRef, useEffect, useCallback } from 'react';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import ProgressBar from '~/components/ProgressBar';
import history from '~/routes/history';
// import LanguageSelector from '~/components/LanguageSelector';

import getQueryStringParams from '~/utils/getQueryStringParams';

// import Steps from './Steps';

import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  LogoWrapper,
  Title,
  FormWrapper,
  // WrapperProgress,
  GoBackWrapper,
} from './styles';
import Step1 from './Steps/Step1';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SignUpCompany = () => {
  const location = useLocation();
  const params = getQueryStringParams(location.search);
  // const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);

  const handleSigInRedirect = useCallback(() => {
    history.push('/confirm-email');
  }, []);

  const [
    step,
    //  setStep
  ] = useState(() => {
    if (params.step === '3') {
      return 3;
    }
    return 1;
  });

  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (recaptchaRef?.current) recaptchaRef.current.execute();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Container>
        <BG />
        <LogoWrapper>
          <Logo />
          <TitleWrapper>
            <Title>{i18next.t('common.signUp.title1')}</Title>
            <Title>{i18next.t('common.signUp.title2')}</Title>
            <Title>{i18next.t('common.signUp.title3')}</Title>
          </TitleWrapper>
        </LogoWrapper>
        <Content>
          <FormWrapper>
            <GoBackWrapper
              onClick={() => {
                if (step === 1) {
                  history.push('/');
                }
                // } else {
                //   setStep((e) => e - 1);
                // }
              }}
            >
              <span>{'<'}</span>
              <span>{i18next.t('common.buttons.goBack')}</span>
            </GoBackWrapper>
            {/* <WrapperProgress>
              <ProgressBar
                label={`${i18next.t(
                  'common.signUp.stepLabel1'
                )} ${step} ${i18next.t('common.signUp.stepLabel2')} 8`}
                actualValue={step}
                total={8}
                initialColor="#539ADA"
                finalColor="#539ADA"
              />
            </WrapperProgress> */}
            {/* <Steps
              step={step}
              recaptchaCompleted={recaptchaCompleted}
              onNext={() => {
                if (step === 8) {
                  history.push('/');
                } else {
                  setStep((e) => e + 1);
                }
              }}
              handleGoBack={() => {
                setStep((e) => e - 1);
              }}
            /> */}
            <Step1
              // recaptchaCompleted={recaptchaCompleted}
              onSubmit={handleSigInRedirect}
            />
          </FormWrapper>
        </Content>
        {/* {step === 1 && (
          <ReCAPTCHA
            theme="light"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={() => setRecaptchaCompleted(true)}
            size="invisible"
          />
        )} */}
      </Container>
      {/* <LanguageSelector /> */}
    </Elements>
  );
};

export default SignUpCompany;
