import styled from 'styled-components';

export const Container = styled.div`
  width: 55vw;
  max-width: 80rem;
  padding: 4.5rem 3.8rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  margin-top: 2rem;
  width: 350px;
`;

export const Title = styled.h1`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5.6rem;

  button {
    margin-right: 1.5rem;
  }
`;
