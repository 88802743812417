import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Images from '~/assets/images/';
import SignInForm from '~/pages/SignIn/Form';
import SignUpCompany from '~/pages/SignUp/Company';
// import SignUpForm from '~/pages/SignUp/Main/Form';
// import LanguageSelector from '~/components/LanguageSelector';

import {
  Container,
  WrapperSignIn,
  WrapperImage,
  Image,
  WrapperSignUpForm,
} from './styles';

function Initial() {
  return (
    <>
      <Container>
        <AnimatePresence>
          <WrapperSignIn layoutId="sign_in_form">
            <SignInForm />
          </WrapperSignIn>
          <WrapperImage layoutId="image">
            <Image src={Images.SignInBg} alt="bg" />
          </WrapperImage>
          <WrapperSignUpForm layoutId="sign_up_form">
            {/* <SignUpForm /> */}
            <SignUpCompany />
          </WrapperSignUpForm>
        </AnimatePresence>
      </Container>
      {/* <LanguageSelector /> */}
    </>
  );
}

export default Initial;
