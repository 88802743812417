import styled from 'styled-components';
import { motion } from 'framer-motion';

import Images from '~/assets/images/';

export const Container = styled.div`
  display: flex;
  width: 100%;
  /* height: 100vh; */
  justify-content: space-between;
`;

export const WrapperCallOpening = styled(motion.div)`
  flex: 2;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const WrapperImage = styled(motion.div)`
  flex: 3;
  display: flex;
  justify-content: flex-end;
  background-image: url(${Images.SignInBg});
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position-y: -25rem;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex: 1;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const ImageText = styled.p`
  width: 36.5rem;
  height: 43.2rem;
  font-weight: bold;
  font-size: 7.2rem;
  line-height: 10.8rem;
  color: #ffffff;
  margin: 17rem 8.5rem;
`;
