import { put, all, takeLatest, call } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import api from '~/services/api';
import translateError from '~/utils/translateError';
import RiskActions, { RiskTypes } from '../ducks/risks';

function* getRisks({ page }) {
  try {
    let response;
    if (page) {
      response = yield call(api.get, `/risks?page=${page}`);
    } else {
      response = yield call(api.get, '/risks');
    }

    yield put(
      RiskActions.getRisksSuccess({ ...response.data, currentPage: page || 0 })
    );
  } catch (error) {
    yield put(RiskActions.getRisksFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchRisks({ query }) {
  try {
    const response = yield call(api.get, `/risks?search=${query}`);

    yield put(RiskActions.searchRisksSuccess(response.data));
  } catch (error) {
    yield put(RiskActions.searchRisksFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRisksMatrix() {
  try {
    const response = yield call(api.get, '/risks/matrix');

    yield put(RiskActions.getRisksMatrixSuccess(response.data));
  } catch (error) {
    yield put(RiskActions.getRisksMatrixFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRiskDetails({ id }) {
  try {
    const response = yield call(api.get, `/risks/${id}`);

    yield put(RiskActions.getRiskDetailSuccess(response.data));
  } catch (error) {
    yield put(RiskActions.getRiskDetailFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* putRiskToggle({ id }) {
  try {
    yield call(api.put, `/risks/${id}/toggle_show`);
    const response = yield call(api.get, '/risks');

    yield put(RiskActions.putRiskToggleSuccess(response.data));
  } catch (error) {
    yield put(RiskActions.putRiskToggleFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRiskHistory({ id }) {
  try {
    const response = yield call(api.get, `/risks/${id}/assessment`);

    yield put(RiskActions.getRiskHistorySuccess(response.data));
  } catch (error) {
    yield put(RiskActions.getRiskHistoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRiskAssociations({ id }) {
  try {
    const response = yield call(api.get, `/risks/${id}/association`);

    yield put(RiskActions.getRiskAssociationsSuccess(response.data));
  } catch (error) {
    yield put(RiskActions.getRiskAssociationsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* treatRisk({ data, residualRisk, id, loadData }) {
  try {
    const convertedResidualRisks = residualRisk.map((rRisk) => {
      return {
        description: rRisk.description,
        business_units: JSON.stringify(rRisk.business_units),
        processes: JSON.stringify(rRisk.processes),
        assets: JSON.stringify(rRisk.assets),
        users: JSON.stringify(rRisk.users),
      };
    });

    let formData;

    if (data.residual_risk_after_mitigation) {
      formData = convertFormData({
        description: data.description,
        reanalysis: data.reanalysis,
        probability: data.probability,
        impact: data.impact,
        detection: data.detection,
        residual_risk_after_mitigation: data.residual_risk_after_mitigation,
        risk_result: data.risk_result,
        result_detection: Number(data.rfd),
        residual_risk: JSON.stringify(convertedResidualRisks),
      });
    } else {
      formData = convertFormData({
        description: data.description,
        reanalysis: data.reanalysis,
        probability: data.probability,
        impact: data.impact,
        detection: data.detection,
        residual_risk_after_mitigation: data.residual_risk_after_mitigation,
        risk_result: data.risk_result,
        result_detection: Number(data.rfd),
      });
    }

    if (data.evidence) formData.append('attachment', data.evidence);

    residualRisk.map((risk) => {
      if (risk.evidence) formData.append('evidence', risk.evidence);
      return null;
    });

    yield call(api.post, `/risks/${id}/assessment`, formData);

    yield put(RiskActions.treatRiskSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Avaliação realizada com sucesso!',
      })
    );

    loadData();
  } catch (error) {
    yield put(RiskActions.treatRiskFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* downloadRisks() {
  try {
    const response = yield call(api.get, `/risks/download_docx`, {
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Riscos.docx');

    yield put(RiskActions.downloadRisksSuccess());
  } catch (error) {
    yield put(RiskActions.downloadRisksFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRiskTreatmentDetails({ riskId, treatmentId, setOpenModal }) {
  try {
    const response = yield call(
      api.get,
      `/risks/${riskId}/assessment/${treatmentId}`
    );

    yield put(RiskActions.getRiskTreatmentDetailsSuccess(response.data));
    setOpenModal(true);
  } catch (error) {
    yield put(RiskActions.getRiskTreatmentDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(RiskTypes.GET_RISKS_REQUEST, getRisks),
  takeLatest(RiskTypes.SEARCH_RISKS_REQUEST, searchRisks),
  takeLatest(RiskTypes.GET_RISKS_MATRIX_REQUEST, getRisksMatrix),
  takeLatest(RiskTypes.GET_RISK_DETAIL_REQUEST, getRiskDetails),
  takeLatest(RiskTypes.PUT_RISK_TOGGLE_REQUEST, putRiskToggle),
  takeLatest(RiskTypes.GET_RISK_HISTORY_REQUEST, getRiskHistory),
  takeLatest(RiskTypes.GET_RISK_ASSOCIATIONS_REQUEST, getRiskAssociations),
  takeLatest(RiskTypes.TREAT_RISK_REQUEST, treatRisk),
  takeLatest(RiskTypes.DOWNLOAD_RISKS_REQUEST, downloadRisks),
  takeLatest(
    RiskTypes.GET_RISK_TREATMENT_DETAILS_REQUEST,
    getRiskTreatmentDetails
  ),
]);
