import styled from 'styled-components';

export const Container = styled.div`
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 10px;
  height: ${({ height }) => height};
  flex: 1;

  padding: ${({ padding }) => padding};

  :hover {
    transform: scale(1.01);
    box-shadow: 0 0 30px #00000004;
  }
  transition: 200ms ease;
`;
