// import i18next from 'i18next';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import i18next from 'i18next';
import { func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HoverButton from '~/components/Button/MainButtons/HoverButton';
import { MultiSelect } from '~/components/Select';
import TextArea from '~/components/TextArea';
import TextInput from '~/components/TextInput';
import ConfigActions from '~/store/ducks/config';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import schema from './validationSchema';

import {
  Container,
  Title,
  Content,
  DepartamentDetails,
  FormContaier,
  WrapperFotterButton,
  DepartamentItem,
  DepartamentTitle,
  WrapperManager,
  Manager,
  EditIcon,
  Spinner,
} from './styles';

const ProcessDetails = ({ selectedDepartament, handleOpenModal }) => {
  const dispatch = useDispatch();

  const { peopleEnum } = useSelector((state) => state.people);

  const { departament, process, isLoading, isLoadingDetails } = useSelector(
    (state) => state.config
  );

  useEffect(() => {
    dispatch(
      ConfigActions.getDepartamentDetailsRequest(
        selectedDepartament.departament
      )
    );

    if (selectedDepartament.process) {
      dispatch(
        ConfigActions.getDepartamentProcessDetailsRequest(
          selectedDepartament.process
        )
      );
    } else {
      dispatch(ConfigActions.clearProcessDetailsRequest());
    }
  }, [selectedDepartament]);

  const submit = (values) => {
    if (process.id) {
      dispatch(
        ConfigActions.updateDepartamentProcessRequest(process.id, values)
      );
    } else {
      dispatch(
        ConfigActions.createDepartamentProcessRequest(
          selectedDepartament.departament,
          values
        )
      );
    }
  };

  return (
    <Container>
      <Title>Processo</Title>
      {isLoadingDetails ? (
        <Spinner />
      ) : (
        <Content>
          <DepartamentDetails>
            <EditIcon onClick={() => handleOpenModal(departament)} />
            <DepartamentItem>
              <DepartamentTitle>
                {i18next.t('configurations.departments.details.departament')}
              </DepartamentTitle>
              {departament?.name}
            </DepartamentItem>
            <DepartamentItem>
              <DepartamentTitle>
                {i18next.t('configurations.departments.details.description')}
              </DepartamentTitle>
              {departament.description}
            </DepartamentItem>
            <DepartamentItem>
              <DepartamentTitle>
                {i18next.t('configurations.departments.details.managers')}
              </DepartamentTitle>
            </DepartamentItem>
            <WrapperManager>
              {departament?.managers?.map((manager) => (
                <Manager>
                  {manager.person.first_name} {manager.person.last_name}
                </Manager>
              ))}
            </WrapperManager>
          </DepartamentDetails>

          <FormContaier>
            <Formik
              initialValues={{
                name: process?.name,
                responsibles: process?.responsibles?.map(
                  (responsible) => responsible.person.id
                ),
                description: process?.description,
              }}
              onSubmit={submit}
              validationSchema={schema}
              enableReinitialize
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                handleSubmit,
              }) => (
                <>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextInput
                        label={i18next.t(
                          'configurations.departments.registrationModal.name'
                        )}
                        required
                        placeholder={i18next.t('common.typeIt')}
                        name="name"
                        value={values.name}
                        touched={touched.name}
                        error={errors.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MultiSelect
                        label={i18next.t(
                          'configurations.departments.registrationModal.processes.responsible'
                        )}
                        options={peopleEnum}
                        required
                        hasModal
                        value={values.responsibles}
                        error={errors.responsibles}
                        touched={touched.responsibles}
                        onChange={(e) => setFieldValue('responsibles', e)}
                        margin="0 0 1.8rem"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextArea
                        label={i18next.t(
                          'configurations.departments.registrationModal.description'
                        )}
                        maxlength="7"
                        maxHeight="3rem"
                        name="solution"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                        onChange={(e) =>
                          setFieldValue('description', e.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                  <WrapperFotterButton>
                    <HoverButton
                      type="submit"
                      width="22rem"
                      padding="0"
                      onClick={handleSubmit}
                      disabled={isLoading}
                      label={
                        isLoading ? (
                          <SppinerIcon />
                        ) : process.id ? (
                          i18next.t('common.buttons.update')
                        ) : (
                          i18next.t('common.buttons.save')
                        )
                      }
                    />
                  </WrapperFotterButton>
                </>
              )}
            </Formik>
          </FormContaier>
        </Content>
      )}
    </Container>
  );
};

export default ProcessDetails;

ProcessDetails.propTypes = {
  selectedDepartament: string.isRequired,
  handleOpenModal: func.isRequired,
};
