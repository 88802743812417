import React from 'react';
import { func, bool, string } from 'prop-types';
import { Modal } from '@material-ui/core';
import i18next from 'i18next';
import { PrimaryButton, SecondaryButton } from '../Button';

import {
  Container,
  Card,
  MotionCard,
  Header,
  CloseButton,
  CloseIcon,
  BigCloseIcon,
  WrapperContent,
  ButtonsArea,
  Description,
} from './styles';

function ModalWithButtons({
  description,
  open,
  noCloseButton,
  onClose,
  noCloseText,
  title,
  noPadding,
  motion,
  color,
  Button1Text,
  Button2Text,
  Button1Function,
  Button2Function,
}) {
  const CardComp = motion ? MotionCard : Card;
  return (
    <Modal open={open}>
      <Container open={open}>
        <CardComp style={{ backgroundColor: color }}>
          {(!noCloseButton || title) && (
            <Header position={title ? 'space-between' : 'flex-end'}>
              {title}
              {!noCloseButton && (
                <>
                  {noCloseText ? (
                    <CloseButton onClick={onClose}>
                      <CloseIcon />
                    </CloseButton>
                  ) : (
                    <CloseButton onClick={onClose}>
                      <CloseIcon />
                      {i18next.t('common.buttons.close')}
                    </CloseButton>
                  )}
                </>
              )}
              {!noCloseText && <BigCloseIcon onClick={onClose} />}
            </Header>
          )}
          <WrapperContent noPadding={noPadding}>
            <Description>{description}</Description>
          </WrapperContent>
          <ButtonsArea>
            <SecondaryButton
              label={Button1Text}
              type="secondary"
              onClick={Button1Function}
            />

            <PrimaryButton
              label={Button2Text}
              style={{ marginLeft: 30 }}
              onClick={Button2Function}
            />
          </ButtonsArea>
        </CardComp>
      </Container>
    </Modal>
  );
}

export default ModalWithButtons;

ModalWithButtons.propTypes = {
  description: string,
  Button1Text: string,
  Button2Text: string,
  Button1Function: func,
  Button2Function: func,
  noCloseButton: bool,
  noCloseText: bool,
  onClose: func,
  open: bool,
  title: string,
  noPadding: bool,
  motion: bool,
  color: string,
};

ModalWithButtons.defaultProps = {
  onClose: () => {},
  noCloseButton: false,
  noCloseText: false,
  open: false,
  title: '',
  noPadding: false,
  motion: false,
  color: '',
  Button1Text: '',
  Button2Text: '',
  description: '',
  Button1Function: () => {},
  Button2Function: () => {},
};
