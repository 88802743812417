import React, { useCallback } from 'react';
import { string, bool, func } from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { byteToSize } from '~/utils/formatters';

import {
  Container,
  Label,
  Error,
  GrayWrapper,
  Placeholder,
  FileIcon,
  FileName,
  FileSize,
} from './styles';

function FileInput({
  placeholder,
  label,
  touched,
  error,
  onChange,
  value,
  margin,
  oneLineLabel,
  sm,
}) {
  const isError = Boolean(touched && error);

  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container error={isError} label={label} margin={margin}>
      {label && (
        <Label sm={sm} oneLineLabel={oneLineLabel}>
          {label}
        </Label>
      )}

      <GrayWrapper {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} multiple={false} />

        {value ? (
          <FileName>
            {value.name}
            <br />
            <FileSize>{byteToSize(value.size)}</FileSize>
          </FileName>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <FileIcon />
      </GrayWrapper>
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

export default FileInput;

FileInput.propTypes = {
  sm: false,
  placeholder: string,
  label: string,
  touched: bool,
  error: string,
  onChange: func,
  value: string,
  margin: string,
  oneLineLabel: bool,
};

FileInput.defaultProps = {
  sm: bool,
  placeholder: 'Selecione',
  label: '',
  touched: false,
  error: '',
  onChange: () => {},
  value: '',
  margin: '0',
  oneLineLabel: true,
};
