import styled from 'styled-components';
import { MdMoreVert } from 'react-icons/md';
import { AiOutlineEye } from 'react-icons/ai';

import { IconCloseSquare } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const MoreIcon = styled(MdMoreVert).attrs(({ theme }) => ({
  color: theme.colors.base[400],
  size: 24,
}))`
  cursor: pointer;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  padding-left: 2.4rem;
  padding-right: 1.2rem;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.2rem;
  font-weight: 400;
  ${({ theme }) => theme.notSelectable};

  :first-child {
    margin-top: 0.8rem;
  }
  :last-child {
    margin-bottom: 0.8rem;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  transition: 150ms ease;
`;

export const WrapperIcon = styled.div`
  width: 1.8rem;
  margin-right: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EyeIcon = styled(AiOutlineEye).attrs(({ theme }) => ({
  color: theme.colors.base[400],
  size: 24,
}))``;

export const CloseIcon = styled(IconCloseSquare)`
  stroke: ${({ theme }) => theme.colors.base[400]};
`;
