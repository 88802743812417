import React from 'react';
import { Grid } from '@material-ui/core';
import { objectOf, string, number } from 'prop-types';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Card from '~/components/Card';
import { Column, Text, Row } from '~/styles/components';
import ActionButton from './ActionButton';

import {
  Container,
  Title,
  WrapperItems,
  WrapperItem,
  ItemIcon,
} from './styles';

const RevisionItem = ({ item, id }) => {
  return (
    <WrapperItem>
      <Row align="center">
        <ItemIcon />
        <Column>
          <Text>
            {format(new Date(item.updatedAt), "MMMM dd '|' HH:mm", {
              locale: pt,
            })}
          </Text>
        </Column>
      </Row>
      <ActionButton itemId={id} />
    </WrapperItem>
  );
};

RevisionItem.propTypes = {
  item: objectOf(string).isRequired,
  id: number.isRequired,
};

function NeedRevision(props) {
  const { pendingRevision } = useSelector((state) => state.dashboard);

  return (
    <Grid item {...props}>
      <Title>{i18next.t('dashboard.needRevision.title')}</Title>
      <Card height="91%">
        <Container>
          <WrapperItems>
            {pendingRevision && pendingRevision.length > 0 ? (
              pendingRevision.map((item) => (
                <RevisionItem item={item} id={item?.id} />
              ))
            ) : (
              <p>{i18next.t('dashboard.needRevision.noData')}</p>
            )}
          </WrapperItems>
        </Container>
      </Card>
    </Grid>
  );
}

export default NeedRevision;
