import styled from 'styled-components';

import { Background404, PowerPlug, Polygons1, Polygons2 } from '~/assets/svgs';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: #fafafc;
  background: url(Background404);
  position: relative;
`;

export const Header = styled.div`
  height: 6rem;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 12rem;
`;

export const ContainerBG = styled(Background404)`
  position: absolute;
  top: 10%;
  left: 10%;
  opacity: 0.2;
  width: 80%;
  z-index: 1;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const NotFoundTextWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -4rem;
`;

export const Poligons1 = styled(Polygons1)`
  margin-right: 2rem;
  z-index: 1;
`;

export const NotFoundText = styled.h2`
  z-index: 1;
  color: ${({ theme }) => theme.colors.blue};
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 7rem;
  text-align: center;
`;

export const Poligons2 = styled(Polygons2)`
  margin-left: 2rem;
  z-index: 1;
`;

export const PowerPlugIcon = styled(PowerPlug)`
  opacity: 0.25;
  z-index: 1;
`;

export const Button = styled.div`
  background: #4347a2;
  box-shadow: 0.7rem 0.7rem 1.8rem #ebebed, -0.7rem -0.7rem 1.8rem #ffffff;
  cursor: pointer;
  height: 5rem;
  padding: 0 16rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -3rem;

  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 1.6rem;

  :hover {
    box-shadow: 1rem 1rem 2rem #e1e1e3, -1rem -1rem 2rem #ffffff;
    transform: scale(1.01);
  }

  transition: 300ms ease;
`;
