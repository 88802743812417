import styled from 'styled-components';
import CustomScroll from '~/components/CustomScroll';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: space-between;
  height: 1rem;
  margin-bottom: 2rem;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const Title = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  color: #322e39;
  margin-top: 1rem;
`;

export const Scroll = styled(CustomScroll)`
  flex: 1;
  margin-top: 1rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.span`
  font-size: 1.4rem;
  color: #b2b2b2;
`;
