import React, { cloneElement, useState } from 'react';
import { element, arrayOf, string, bool } from 'prop-types';

import { Container, Scroll, WrapperTabs, Tab, WrapperContent } from './styles';

function Tabs({ children, tabs, tabsError, sm }) {
  const [selectedTab, setSelectedTab] = useState(0);

  function handleNext() {
    if (tabs.length > selectedTab + 1) {
      setSelectedTab(selectedTab + 1);
    }
  }

  return (
    <Container>
      <Scroll>
        <WrapperTabs>
          {tabs.map((tab, index) => (
            <Tab
              onClick={() => setSelectedTab(index)}
              selected={index === selectedTab}
              isError={tabsError.length > 0 ? tabsError[index] : false}
              sm={sm}
            >
              <p>{tab}</p>
            </Tab>
          ))}
        </WrapperTabs>
      </Scroll>
      <WrapperContent>
        {cloneElement(children[selectedTab], { handleNext })}
      </WrapperContent>
    </Container>
  );
}

export default Tabs;

Tabs.defaultProps = {
  sm: false,
  tabsError: [],
};

Tabs.propTypes = {
  sm: bool,
  tabsError: arrayOf(bool),
  children: element.isRequired,
  tabs: arrayOf(string).isRequired,
};
