import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import { TertiaryButton } from '~/components/Button';
import SearchInput from '~/components/TextInput/Search';
import ConfigActions from '~/store/ducks/config';
import CreateUserModalGeral from './components/CreateUserModal';
import HistoryList from './components/HistoryList';
// import Spinner from '~/components/Spinner';

import Table from './components/Table';

import {
  Container,
  Header,
  TitleWrapper,
  Title,
  IconPlus,
  ActionsWrapper,
  SearchAndNewButton,
  AreaHistoryList,
  HistoryIcon,
} from './styles';

const CompanyUsers = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');

  const searchUsers = () => {
    dispatch(ConfigActions.searchUsersCompanyRequest(searchValue));
  };

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('configurations.user.titleUserCompany')}</Title>
        </TitleWrapper>
        <ActionsWrapper>
          <SearchAndNewButton>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('common.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
            <TertiaryButton
              onClick={() => setOpenCreateUserModal(true)}
              label={i18next.t('common.buttons.new')}
              leftIcon={<IconPlus />}
            />
          </SearchAndNewButton>
          <AreaHistoryList>
            <TertiaryButton
              onClick={() => setOpenList(true)}
              label={i18next.t('common.buttons.invitationHistory')}
              leftIcon={<HistoryIcon />}
            />
          </AreaHistoryList>
        </ActionsWrapper>
      </Header>
      <Table />
      <CreateUserModalGeral
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
      <HistoryList open={!!openList} onClose={() => setOpenList(false)} />
    </Container>
  );
};

export default CompanyUsers;
