import styled, { css } from 'styled-components';
import { FiCamera } from 'react-icons/fi';

export const Container = styled.div.attrs({ layout: true })`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Dropzone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafbfb;
  border: 1px solid #c6ccd2;
  box-sizing: border-box;
  border-radius: 50%;
  height: 10rem;
  max-width: 10rem;
  cursor: pointer;

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      box-shadow: inset 6px 6px 15px #f5f5f5, inset -6px -6px 15px #ffffff;
      transform: scale(0.99);
      cursor: drop;
    `};

  ${({ filePresent }) =>
    filePresent &&
    css`
      border: none;
    `};

  img {
    object-fit: cover;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
  }

  transition: 200ms ease;
`;

export const AvatarIcon = styled(FiCamera).attrs({
  color: '#606474',
  size: 24,
})``;

export const AvatarImage = styled.img``;
