import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  .ql-editor {
    min-height: 22rem;
    max-height: 60rem;
  }
`;

export const Label = styled.div`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const RichTextLabel = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
  margin-bottom: 0.8rem;
`;
