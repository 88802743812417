import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { string, func, object, oneOfType } from 'prop-types';

import {
  Icon,
  Name,
  Error,
  DownloadButton,
  DownloadWrapper,
  Container,
  Content,
} from './styles';

const Dropzone = ({ label, onSetFile, name, error, value }) => {
  const [file, setFile] = useState(value);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles);
      if (onSetFile) {
        onSetFile(acceptedFiles);
      }
    },
    [onSetFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const baseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_API_HOMOLOG_ENDPOINT;
    }
    return process.env.REACT_APP_API_PROD_ENDPOINT;
  };

  return (
    <Content>
      {typeof file === 'string' && (
        <DownloadWrapper href={`${baseUrl()}${value}`} target="_blank">
          <DownloadButton />
        </DownloadWrapper>
      )}
      <Container
        name={name}
        {...getRootProps()}
        isDragActive={isDragActive}
        hasFile={Boolean(file)}
        error={error}
      >
        <input {...getInputProps()} multiple={false} />
        <Icon hasFile={Boolean(file)} />
        <Name hasFile={Boolean(file)}>{label}</Name>
        <Error error={error}>{error}</Error>
      </Container>
    </Content>
  );
};

export default Dropzone;

Dropzone.propTypes = {
  label: string.isRequired,
  name: string.isRequired,
  onSetFile: func,
  error: string.isRequired,
  value: oneOfType([object, null]),
};

Dropzone.defaultProps = {
  onSetFile: null,
  value: null,
};
