import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import i18next from 'i18next';

import Card from '~/components/Card';
import { SecondarySelect } from '~/components/Select';

import chartOptions from './chart';

import { Container } from './styles';

function NumberOfTickets(props) {
  const { openTickets, answeredTickets, totalHolders, totalProcesses } =
    useSelector((state) => state.dashboard);
  const [selectionOption, setSelectOption] = useState('openTickets');
  const [optChart, setOptChart] = useState({});

  useEffect(() => {
    switch (selectionOption) {
      case 'openTickets':
        setOptChart({
          ...chartOptions,
          series: [
            {
              data: Object.values(openTickets),
              lineColor: '#539ADA',
            },
          ],
        });
        break;
      case 'answeredTickets':
        setOptChart({
          ...chartOptions,
          series: [
            {
              data: Object.values(answeredTickets),
              lineColor: '#539ADA',
            },
          ],
        });
        break;
      case 'totalHolders':
        setOptChart({
          ...chartOptions,
          series: [
            {
              data: Object.values(totalHolders),
              lineColor: '#539ADA',
            },
          ],
        });
        break;
      case 'totalProcesses':
        setOptChart({
          ...chartOptions,
          series: [
            {
              data: Object.values(totalProcesses),
              lineColor: '#539ADA',
            },
          ],
        });
        break;
      default:
        setOptChart({
          ...chartOptions,
          series: [
            {
              data: Object.values(openTickets),
              lineColor: '#539ADA',
            },
          ],
        });
    }
  }, [
    selectionOption,
    openTickets,
    answeredTickets,
    totalHolders,
    totalProcesses,
  ]);

  return (
    <Grid item {...props}>
      <Card>
        <Container>
          <SecondarySelect
            options={[
              {
                value: 'openTickets',
                label: i18next.t('dashboard.tickets.open'),
              },
              {
                value: 'answeredTickets',
                label: i18next.t('dashboard.tickets.answered'),
              },
              {
                value: 'totalHolders',
                label: i18next.t('dashboard.tickets.holders'),
              },
              {
                value: 'totalProcesses',
                label: i18next.t('dashboard.tickets.processes'),
              },
            ]}
            placeholder="Tickets em aberto"
            value={selectionOption}
            onChange={(e) => setSelectOption(e)}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={optChart}
            allowChartUpdate
            immutable={false}
          />
        </Container>
      </Card>
    </Grid>
  );
}

export default NumberOfTickets;
