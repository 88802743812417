import React from 'react'; // , { useEffect }
// import { useDispatch, useSelector } from 'react-redux';
import {
  func,
  bool,
  //  string
} from 'prop-types';
import i18next from 'i18next';
// import ConfigActions from '~/store/ducks/config';
import Modal from '~/components/Modal';
// import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import {
  Container,
  // , WrapperButton
} from './styles';

import Table from './Table';

function HistoryList({ open, onClose }) {
  // const dispatch = useDispatch();
  // const { userActive } = useSelector((state) => state.auth);
  // const { roleEnum, isLoading } = useSelector((state) => state.config);

  // const onSuccess = () => {
  //   onClose();
  // };

  return (
    <Modal
      noCloseText
      title={`${i18next.t('configurations.invitationHistory.title')}`}
      noPadding
      open={open}
      onClose={onClose}
    >
      <Container>
        <Table />
      </Container>
    </Modal>
  );
}

export default HistoryList;

HistoryList.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
