import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: space-between;
`;

export const WrapperSignIn = styled(motion.div)`
  flex: 2;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const WrapperImage = styled(motion.div)`
  flex: 3;
  display: flex;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex: 1;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Image = styled.img`
  flex: 1;
  object-fit: cover;
`;

export const WrapperSignUpForm = styled(motion.div)`
  flex: 0;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;
