import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { objectOf, oneOfType, any } from 'prop-types';
// import { useSocket } from '~/hooks/socket';
import ChatList from './components/ChatList';
import MessagesBox from './components/MessagesBox';

import { Container, Title } from './styles';

const Chat = ({ location }) => {
  // const { emitMessages, emitAttendance } = useSocket();

  // useEffect(() => {
  //   emitMessages(location?.state?.id);
  //   emitAttendance(location?.state?.id);
  // }, []);

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Title>{i18next.t('demandManagement.chat.title')}</Title>
        </Grid>
        <Grid container spacing={5}>
          <Grid item md={3} xs={12}>
            <ChatList />
          </Grid>
          <Grid item md={9} xs={12}>
            <Grid container spacing={4}>
              <MessagesBox
                protocol={String(location?.state?.protocol)}
                id={String(location?.state?.id)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Chat;

Chat.propTypes = {
  location: objectOf(oneOfType([any])).isRequired,
};
