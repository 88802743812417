import React, { useCallback, useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import i18next from 'i18next';

import { useLocation } from 'react-router-dom';
import TextInput from '~/components/TextInput';
import { PrimaryButton, TertiaryButton } from '~/components/Button';
import { Column } from '~/styles/components';
import Spinner from '~/components/Spinner';
import getQueryStringParams from '~/utils/getQueryStringParams';
import TermsModal from '~/components/TermsModal';
import { default as Modal } from '~/components/Modal';
import DemandActions from '~/store/ducks/demand';
// import AttachFiles from '~/components/AttachFiles';
import HoverButton from '~/components/Button/MainButtons/HoverButton';
import SuccessModal from '../SuccessModal';

import AttachFile from '../AttachFile';
import schema from './validationSchema';

import {
  Container,
  Title,
  Text,
  WrapperCaptcha,
  CaptchaError,
  RowWrapper,
  CheckboxWrapper,
  CheckBox,
  CheckBoxLabel,
  CheckboxButton,
  DocumentTextWrapper,
  DocsWrapper,
  Subtitle,
  IconPlus,
  ContentFileModal,
  WrapperButton,
  WrapperFile,
  FileDescription,
  WrapperDesc,
  Mandatory,
} from './styles';
import File from '../File';

function Form() {
  const location = useLocation();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);
  const { isLoading } = useSelector((state) => state.demands);
  const [successModalOpen, setSucessModalOpen] = useState(false);

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [fileModal, setFileModal] = useState(false);
  const [newFile, setNewFile] = useState({
    description: '',
    file: null,
  });
  const [newFileError, setNewFileError] = useState({});

  const params = getQueryStringParams(location.search);

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);

  function handleFormReset() {
    formRef.current.resetForm();
    formRef.current.values.file = null;
  }

  const submit = useCallback((values) => {
    const data = {
      ...values,
      companyId: params.companyid,
      termsAgreement,
      privacyAgreement,
    };

    dispatch(
      DemandActions.openNewDemandRequest(
        data,
        setSucessModalOpen,
        handleFormReset
      )
    );
  }, []);

  const { values, touched, errors, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      innerRef: formRef,
      initialValues: {
        name: '',
        cpf: '',
        phone: '',
        email: '',
        subject: '',
        description: '',
        document: null,
        additionalFiles: [],
        captcha: false,
      },
      onSubmit: submit,
      validationSchema: schema,
    });

  const handleOpenFileModal = () => {
    setFileModal(true);
  };

  const handleCloseFileModal = () => {
    setNewFile({});
    setFileModal(false);
    setNewFileError({});
  };

  const handleNewFileDescription = (e) => {
    setNewFile((state) => ({
      ...state,
      description: e.target.value,
    }));
  };

  const handleNewFile = (e) => {
    setNewFile((state) => ({
      ...state,
      file: e,
    }));
  };

  const handleDeleteFile = (index) => {
    const newArray = [...values?.additionalFiles];
    newArray.splice(index, 1);
    setFieldValue('additionalFiles', newArray);
  };

  const handleAddFile = () => {
    const descriptionAlreadyExist = values.additionalFiles.find(
      (item) => item.description === newFile.description
    );
    if (!descriptionAlreadyExist) {
      const newAttachment = [...values.additionalFiles, newFile];
      setFieldValue('additionalFiles', newAttachment);
      setNewFile({});
      setFileModal(false);
      setNewFileError({});
    } else {
      setNewFileError((state) => ({
        ...state,
        description: 'Já existe um arquivo com essa descrição',
      }));
    }
  };

  return (
    <>
      <Spinner loading={isLoading} />
      <Container>
        <Title>{i18next.t('callOpening.demandOpening.title')}</Title>
        <Text>{i18next.t('callOpening.demandOpening.subtitle')}</Text>

        <>
          <TextInput
            placeholder={i18next.t('callOpening.demandOpening.nameLabel')}
            name="name"
            value={values.name}
            touched={touched.name}
            error={errors.name}
            onChange={handleChange}
            margin="0 0 2rem 0"
          />
          <RowWrapper>
            <TextInput
              placeholder={i18next.t('callOpening.demandOpening.cpfLabel')}
              mask={values.cpf.length < 15 && '999.999.999-99'}
              name="cpf"
              value={values.cpf}
              touched={touched.cpf}
              error={errors.cpf}
              onChange={handleChange}
              margin="0 2rem 2rem 0"
            />
            <TextInput
              placeholder={i18next.t('callOpening.demandOpening.phoneLabel')}
              mask={
                values.phone.length < 15 ? '(99) 9999-9999*' : '(99) 99999-9999'
              }
              name="phone"
              value={values.phone}
              touched={touched.phone}
              error={errors.phone}
              onChange={handleChange}
              margin="0 0 2rem 0"
            />
          </RowWrapper>
          <TextInput
            placeholder={i18next.t('callOpening.demandOpening.emailLabel')}
            name="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            margin="0 0 2rem 0"
          />
          <TextInput
            placeholder={i18next.t('callOpening.demandOpening.subjectLabel')}
            name="subject"
            value={values.subject}
            touched={touched.subject}
            error={errors.subject}
            onChange={handleChange}
            margin="0 0 2rem 0"
          />
          <TextInput
            placeholder={i18next.t(
              'callOpening.demandOpening.descriptionLabel'
            )}
            name="description"
            value={values.description}
            touched={touched.description}
            error={errors.description}
            onChange={handleChange}
            onSubmit={handleSubmit}
            margin="0 0 2rem 0"
            textArea
          />

          <DocsWrapper>
            <Subtitle>Anexos:</Subtitle>
            <WrapperDesc>
              <FileDescription>Documento com foto: </FileDescription>
              <Mandatory>*</Mandatory>
            </WrapperDesc>

            <WrapperDesc />
            <AttachFile
              placeholder="Documento do com foto"
              onDone={(e) => setFieldValue('document', e)}
            />
            {values.additionalFiles &&
              values.additionalFiles.map((item, index) => (
                <WrapperFile>
                  <FileDescription>{item.description}:</FileDescription>
                  <File
                    key={item.description}
                    file={item.file}
                    onDelete={() => handleDeleteFile(index)}
                  />
                </WrapperFile>
              ))}
            <TertiaryButton
              label="Adicionar anexo"
              leftIcon={<IconPlus />}
              onClick={handleOpenFileModal}
            />
          </DocsWrapper>
          <CheckboxWrapper>
            <CheckBox
              type="checkbox"
              id="terms"
              name="terms"
              checked={termsAgreement}
              onChange={() => setTermsAgreement((e) => !e)}
            />
            <CheckBoxLabel>
              Li e concordo com o{' '}
              <CheckboxButton
                type="button"
                onClick={() => setIsTermsModalOpen(true)}
              >
                Termo de uso
              </CheckboxButton>
            </CheckBoxLabel>
          </CheckboxWrapper>
          <CheckboxWrapper>
            <CheckBox
              type="checkbox"
              id="privacy"
              name="privacy"
              checked={privacyAgreement}
              onChange={() => setPrivacyAgreement((e) => !e)}
            />
            <CheckBoxLabel>
              Li e concordo com o{' '}
              <CheckboxButton
                type="button"
                onClick={() => setIsPrivacyModalOpen(true)}
              >
                Política de Privacidade
              </CheckboxButton>
            </CheckBoxLabel>
          </CheckboxWrapper>

          <WrapperCaptcha>
            <ReCAPTCHA
              theme="light"
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={() => setFieldValue('captcha', true)}
              size="invisible"
            />
            <CaptchaError error={errors.captcha && touched.captcha}>
              {errors.captcha}
            </CaptchaError>
          </WrapperCaptcha>
          {values.captcha && (
            <Column width="100%">
              <PrimaryButton
                disabled={!termsAgreement}
                label={i18next.t('common.buttons.send')}
                margin="0 0 1.6rem"
                onClick={handleSubmit}
              />
            </Column>
          )}
        </>
      </Container>
      <SuccessModal
        open={!!successModalOpen}
        onClose={() => setSucessModalOpen(false)}
      />
      <TermsModal
        open={isTermsModalOpen}
        title="Termo de uso"
        checkboxSelected={termsAgreement}
        onClose={() => setIsTermsModalOpen(false)}
      >
        <DocumentTextWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            sagittis ac neque vel hendrerit. Fusce quis augue mollis, finibus
            tortor eget, sodales lacus. Sed leo magna, fringilla eu lorem non,
            convallis accumsan arcu. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. In dignissim eget urna quis tempor. Vestibulum
            finibus ipsum in erat dictum rhoncus. Sed pellentesque tempor nulla,
            non aliquam urna venenatis vel. Proin semper neque ut mauris tempor
            tristique.
          </p>

          <p>
            Sed nulla quam, condimentum nec malesuada nec, hendrerit vel augue.
            Donec eget odio accumsan, laoreet nisl id, semper nisl. Vivamus
            vulputate sed velit sed suscipit. Pellentesque aliquam mi non nisi
            egestas, nec fringilla risus pellentesque. Maecenas ultrices
            condimentum feugiat. Cras ultrices in leo aliquet sagittis.
            Vestibulum cursus nibh orci, in condimentum arcu finibus non. Mauris
            placerat iaculis sapien, eget ornare lorem blandit consequat.
            Quisque at venenatis enim. Vivamus tempus vehicula mi, vel porttitor
            ipsum vestibulum eget. Vestibulum finibus, lectus eu euismod
            interdum, sapien ipsum consectetur urna, quis fermentum massa leo et
            lectus. Curabitur nec purus mi. Morbi leo risus, ultrices ac risus
            ac, convallis euismod arcu. Donec rutrum dolor vel congue tempor.
            Aenean dictum erat et nunc tristique gravida.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setTermsAgreement((e) => !e)}
            checked={termsAgreement}
          />
          <CheckBoxLabel>Li e concordo com os Termos de uso</CheckBoxLabel>
        </CheckboxWrapper>
      </TermsModal>
      <TermsModal
        open={isPrivacyModalOpen}
        title="Política de privacidade"
        checkboxSelected={privacyAgreement}
        onClose={() => setIsPrivacyModalOpen(false)}
      >
        <DocumentTextWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            sagittis ac neque vel hendrerit. Fusce quis augue mollis, finibus
            tortor eget, sodales lacus. Sed leo magna, fringilla eu lorem non,
            convallis accumsan arcu. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. In dignissim eget urna quis tempor. Vestibulum
            finibus ipsum in erat dictum rhoncus. Sed pellentesque tempor nulla,
            non aliquam urna venenatis vel. Proin semper neque ut mauris tempor
            tristique.
          </p>

          <p>
            Sed nulla quam, condimentum nec malesuada nec, hendrerit vel augue.
            Donec eget odio accumsan, laoreet nisl id, semper nisl. Vivamus
            vulputate sed velit sed suscipit. Pellentesque aliquam mi non nisi
            egestas, nec fringilla risus pellentesque. Maecenas ultrices
            condimentum feugiat. Cras ultrices in leo aliquet sagittis.
            Vestibulum cursus nibh orci, in condimentum arcu finibus non. Mauris
            placerat iaculis sapien, eget ornare lorem blandit consequat.
            Quisque at venenatis enim. Vivamus tempus vehicula mi, vel porttitor
            ipsum vestibulum eget. Vestibulum finibus, lectus eu euismod
            interdum, sapien ipsum consectetur urna, quis fermentum massa leo et
            lectus. Curabitur nec purus mi. Morbi leo risus, ultrices ac risus
            ac, convallis euismod arcu. Donec rutrum dolor vel congue tempor.
            Aenean dictum erat et nunc tristique gravida.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setPrivacyAgreement((e) => !e)}
            checked={privacyAgreement}
          />
          <CheckBoxLabel>
            Li e concordo com a Política de privacidade
          </CheckBoxLabel>
        </CheckboxWrapper>
      </TermsModal>
      <Modal
        open={fileModal}
        noCloseText
        title="Adicionar anexo"
        checkboxSelected={privacyAgreement}
        onClose={handleCloseFileModal}
      >
        <ContentFileModal>
          <TextInput
            placeholder="Descrição"
            name="description"
            value={newFile.description}
            touched
            error={newFileError.description}
            onChange={(e) => handleNewFileDescription(e)}
            margin="0 0 2rem 0"
          />
          <AttachFile placeholder="Arquivo" onDone={(e) => handleNewFile(e)} />
          <WrapperButton>
            <HoverButton label="Cancelar" onClick={handleCloseFileModal} />
            <HoverButton
              disabled={!newFile.description || !newFile.file}
              onClick={handleAddFile}
              label="Adicionar"
            />
          </WrapperButton>
        </ContentFileModal>
      </Modal>
    </>
  );
}

export default Form;
