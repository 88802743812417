import { addMinutes, addHours, addDays, addWeeks, addMonths } from 'date-fns';

const incrementDate = (date, amount, incrementType) => {
  switch (incrementType) {
    case 'minutes': {
      return addMinutes(date, amount);
    }
    case 'hour':
      return addHours(date, amount);
    case 'day':
      return addDays(date, amount);
    case 'week':
      return addWeeks(date, amount);
    case 'month':
      return addMonths(date, amount);
    default:
      return '';
  }
};

export default incrementDate;
