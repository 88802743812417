import React, { useState, useEffect } from 'react';

import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { MultiSelect, SecondarySelect } from '~/components/Select';
import TechnologyActions from '~/store/ducks/technologies';
import BusinessUnitActions from '~/store/ducks/businessUnits';
import NewUserTypeModalGeral from '~/components/NewUserTypeModal';
import CreateNewLocality from '~/components/CreateNewLocality';
import InviteDPOModalGeral from '~/components/InviteDPOModal';
import CreateUserModalGeral from '~/components/CreateUserModal';
import PeopleActions from '~/store/ducks/people';
import ConfigActions from '~/store/ducks/config';

import { Container, Scroll, WrapperBottom, BottomText, Title } from './styles';

const Info = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { technologyDetails, sidebarMenuSubmit } = useSelector(
    (state) => state.technologies
  );

  const { businessUnitsEnum } = useSelector((state) => state.businessUnits);
  const [openNewUserTypeModal, setOpenNewUserTypeModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openCreateLocality, setOpenCreateLocality] = useState(false);
  const [openInviteDPOModal, setOpenInviteDPOModal] = useState(false);
  const { peopleEnum } = useSelector((state) => state.people);
  const { departamentsEnum } = useSelector((state) => state.config);
  const [newUserRole, setNewUserRole] = useState('');

  const id = history.location.pathname.replace(
    '/assets-management/actives/',
    ''
  );

  const [data, setData] = useState({ status: true });
  useEffect(() => {
    if (id !== 'new') {
      setData({
        title: technologyDetails?.title,
        primaryFunction: technologyDetails?.primary_function,
        responsible:
          technologyDetails?.technology_responsibles?.map(
            (user) => user.person_id
          ) || [],
        businessUnit: technologyDetails?.locality_id,
        department: technologyDetails?.departament_id,
        status: technologyDetails?.status,
      });
    }
  }, [technologyDetails]);

  useEffect(() => {
    dispatch(TechnologyActions.setSidebarMenuDataRequest(data));
  }, [data]);

  useEffect(() => {
    if (peopleEnum.length === 0) dispatch(PeopleActions.getPeopleEnumRequest());
    if (businessUnitsEnum.length === 0)
      dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
    if (departamentsEnum.length === 0)
      dispatch(ConfigActions.getDepartamentsEnumRequest());
  }, []);

  return (
    <Container>
      <Scroll>
        <Title>{i18next.t('technologiesAsset.sidebar.title')}</Title>
        <SecondarySelect
          label={i18next.t('technologiesAsset.tabs.process.status')}
          name="impact"
          value={data.status}
          onChange={(e) => setData({ ...data, status: e })}
          options={[
            {
              label: i18next.t('technologiesAsset.tabs.process.active'),
              value: true,
            },
            {
              label: i18next.t('technologiesAsset.tabs.process.disabled'),
              value: false,
            },
          ]}
        />
        <TextInput
          label={i18next.t('technologiesAsset.sidebar.titleLabel')}
          margin="0 0 1.8rem"
          required
          error={
            !data.title &&
            sidebarMenuSubmit &&
            `${i18next.t('common.validation.required')}`
          }
          touched={sidebarMenuSubmit}
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
        <TextArea
          label={i18next.t('technologiesAsset.sidebar.primaryFunctionLabel')}
          placeholder="Descreva a função"
          margin="0 0 1.8rem"
          maxHeight="30rem"
          value={data.primaryFunction}
          onChange={(e) =>
            setData({ ...data, primaryFunction: e.target.value })
          }
        />
        <MultiSelect
          label={i18next.t('technologiesAsset.sidebar.responsiblesLabel')}
          options={peopleEnum}
          required
          hasStore
          hasModal
          onStore={setOpenNewUserTypeModal}
          value={data.responsible}
          error={
            !data.responsible &&
            sidebarMenuSubmit &&
            `${i18next.t('common.validation.required')}`
          }
          touched={sidebarMenuSubmit}
          onChange={(e) => setData({ ...data, responsible: e })}
          margin="0 0 1.8rem"
        />
        <SecondarySelect
          label={i18next.t('technologiesAsset.sidebar.businessUnitLabel')}
          // required
          hasStore
          hasModal
          onStore={setOpenCreateLocality}
          value={data.businessUnit}
          // error={
          //   !data.businessUnit &&
          //   sidebarMenuSubmit &&
          //   `${i18next.t('common.validation.required')}`
          // }
          touched={sidebarMenuSubmit}
          onChange={(e) => setData({ ...data, businessUnit: e })}
          options={businessUnitsEnum}
          margin="0 0 1.8rem"
        />
        <SecondarySelect
          label={i18next.t('technologiesAsset.sidebar.department')}
          required
          hasStore
          hasModal
          onStore={setOpenCreateLocality}
          value={data.department}
          error={
            !data.department &&
            sidebarMenuSubmit &&
            `${i18next.t('common.validation.required')}`
          }
          touched={sidebarMenuSubmit}
          onChange={(e) => setData({ ...data, department: e })}
          options={departamentsEnum}
          margin="0 0 1.8rem"
        />
      </Scroll>

      {false && (
        <WrapperBottom>
          <BottomText>
            {i18next.t('technologiesAsset.sidebar.identifier')}:{' '}
            {`${technologyDetails.code}`}
          </BottomText>
          <BottomText>
            {i18next.t('technologiesAsset.sidebar.creationDate')}:{' '}
            {format(
              new Date(`${technologyDetails.createdAt}T00:00`),
              'dd/MM/yyyy'
            )}
          </BottomText>
          {/* <BottomText>
            {i18next.t('technologiesAsset.sidebar.creationTime')}:{' '}
            {format(new Date(technologyDetails.createdAt), 'HH:mm')}
          </BottomText> */}
          {technologyDetails.last_updated[0] && (
            <>
              <BottomText>
                {i18next.t('technologiesAsset.sidebar.editBy')}:{' '}
                {technologyDetails?.last_updated[0]?.user.name.split(' ')[0]}
              </BottomText>
              <BottomText>
                {i18next.t('technologiesAsset.sidebar.editDate')}:{' '}
                {format(
                  new Date(`${technologyDetails?.updatedAt}`),
                  'dd/MM/yyyy'
                )}
              </BottomText>
            </>
          )}
        </WrapperBottom>
      )}
      <NewUserTypeModalGeral
        open={!!openNewUserTypeModal}
        onClose={() => setOpenNewUserTypeModal(false)}
        setOpenCreateUserModal={setOpenCreateUserModal}
        setNewUserRole={setNewUserRole}
        setOpenInviteDPOModal={setOpenInviteDPOModal}
      />
      <CreateUserModalGeral
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
      <InviteDPOModalGeral
        open={!!openInviteDPOModal}
        onClose={() => setOpenInviteDPOModal(false)}
      />
      <CreateNewLocality
        open={!!openCreateLocality}
        onClose={() => setOpenCreateLocality(false)}
      />
    </Container>
  );
};

export default Info;
