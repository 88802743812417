import React from 'react';
import i18next from 'i18next';

import history from '~/routes/history';
import Images from '~/assets/images';
// import LanguageSelector from '~/components/LanguageSelector';

import {
  Container,
  Header,
  Logo,
  ContainerBG,
  ContentWrapper,
  NotFoundTextWrapper,
  Poligons1,
  NotFoundText,
  Poligons2,
  PowerPlugIcon,
  Button,
} from './styles';

const NotFound = () => {
  return (
    <>
      <Container>
        <Header>
          <Logo src={Images.PCSLogo} alt="PCS" />
        </Header>
        <ContainerBG />
        <ContentWrapper>
          <NotFoundTextWrapper>
            <Poligons1 />
            <NotFoundText>
              {i18next.t('notFound.message1')}
              <br />
              {i18next.t('notFound.message2')}
            </NotFoundText>
            <Poligons2 />
          </NotFoundTextWrapper>
          <PowerPlugIcon />
          <Button onClick={() => history.push('/')}>
            {i18next.t('notFound.button')}
          </Button>
        </ContentWrapper>
      </Container>
      {/* <LanguageSelector /> */}
    </>
  );
};

export default NotFound;
