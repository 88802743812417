import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { WrapperGreetings } from './styles';

export default function Greetings(props) {
  const { userActive } = useSelector((state) => state.auth);
  return (
    <Grid item {...props}>
      <WrapperGreetings>
        <h1>{`${i18next.t('dashboard.hello')}, ${
          userActive?.user?.first_name
        }`}</h1>
        <h3>{i18next.t('dashboard.welcomeMessage')}</h3>
        {/* <WrapperMosaic>
          <MosaicButton />
        </WrapperMosaic> */}
      </WrapperGreetings>
    </Grid>
  );
}
