import React, { useEffect, useState } from 'react';
import i18next from 'i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Tabs from '~/components/Tabs';
import TechnologyActions from '~/store/ducks/technologies';

import ProcessActions from '~/store/ducks/processes';
import PeopleActions from '~/store/ducks/people';
import BusinessUnitActions from '~/store/ducks/businessUnits';
// import DocumentActions from '~/store/ducks/documents';
import { PrimaryButton } from '~/components/Button';
import { Formik } from 'formik';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import Impact from './Tabs/Impact';
import Actives from './Tabs/Actives';
import PCN from './Tabs/PCN';
import AssociateProcess from './Tabs/AssociateProcess';

import { Container, ButtonWrapper, Button, Title } from './styles';
import validationSchema from './validationSchema';

// import validationSchema from './validationSchema';

const Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sidebarMenuData, technologyDetails } = useSelector(
    (state) => state.technologies
  );

  const { peopleEnum } = useSelector((state) => state.people);
  const { businessUnitsEnum } = useSelector((state) => state.businessUnits);
  const { processesEnum } = useSelector((state) => state.processes);
  const {
    isLoading,
    // , technologiesEnum
  } = useSelector((state) => state.technologies);
  // const { documentsEnum } = useSelector((state) => state.documents);

  const [id, setId] = useState(
    history.location.pathname.replace('/assets-management/actives/', '')
  );

  useEffect(() => {
    if (id !== 'new') {
      dispatch(TechnologyActions.getTechnologyDetailsRequest(id));
    }
  }, []);

  const updateId = (active) => {
    history.push(`/assets-management/actives/${active.id}`);
    setId(active.id);
  };

  const submit = (values) => {
    const alter = true;

    dispatch(TechnologyActions.setSidebarMenuSubmitRequest(alter));

    if (
      sidebarMenuData.title &&
      // sidebarMenuData.businessUnit &&
      sidebarMenuData.responsible
    ) {
      const data = {
        sidebarData: sidebarMenuData,
        processes: values.process,
        impact: values.impact,
        // actives: values.contingencyAssets?.actives,
        // pcn: values.pcn,
      };

      if (id !== 'new') {
        dispatch(TechnologyActions.updateTechnologiesRequest(data, id));
      } else {
        dispatch(TechnologyActions.createTechnologiesRequest(data, updateId));
      }
    }
  };

  useEffect(() => {
    if (peopleEnum.length === 0) dispatch(PeopleActions.getPeopleEnumRequest());
  }, []);

  useEffect(() => {
    if (businessUnitsEnum.length === 0)
      dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
  }, []);

  useEffect(() => {
    if (processesEnum.length === 0)
      dispatch(ProcessActions.getProcessesEnumRequest());
  }, []);

  // useEffect(() => {
  //   if (technologiesEnum.length === 0)
  //     dispatch(TechnologyActions.getTechnologiesEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (documentsEnum.length === 0)
  //     dispatch(DocumentActions.getDocumentsEnumRequest());
  // }, []);

  return (
    <Formik
      initialValues={{
        process: {
          associatedProcesses:
            id !== 'new'
              ? technologyDetails?.associated_processes_technologies?.map(
                  (process) => process.process_id
                ) || []
              : [],
          repository:
            id !== 'new'
              ? technologyDetails?.storage_technologies &&
                technologyDetails?.storage_technologies[0].repository
              : '',
          internationalStorage:
            id !== 'new' &&
            technologyDetails?.storage_technologies &&
            technologyDetails?.storage_technologies[0].international_storage,
          externalTreatment:
            id !== 'new' &&
            technologyDetails?.storage_technologies &&
            technologyDetails?.storage_technologies[0].external_storage,
        },
        impact: {
          maxTime:
            id !== 'new' &&
            technologyDetails?.impact_inoperability_technologies?.length
              ? {
                  time: technologyDetails?.impact_inoperability_technologies[0]
                    .maximum_time,
                  typeOfTimeMeasurement:
                    technologyDetails?.impact_inoperability_technologies[0]
                      .unit_of_time,
                }
              : { time: 1 },
          impact:
            id !== 'new'
              ? technologyDetails?.impact_inoperability_technologies?.length &&
                technologyDetails?.impact_inoperability_technologies[0].impact
              : '',
          description:
            id !== 'new'
              ? technologyDetails?.impact_inoperability_technologies?.length &&
                technologyDetails?.impact_inoperability_technologies[0]
                  .consequence
              : '',
        },
        // contingencyAssets: {
        //   actives:
        //     id !== 'new'
        //       ? technologyDetails?.ContingencyDetailsTechnologies &&
        //         technologyDetails?.ContingencyDetailsTechnologies.map(
        //           (active) => ({
        //             asset_id: active?.active_id,
        //             asset_type: active?.active_type,
        //             impact: active?.impact,
        //             operation_time: {
        //               time: active.maximum_time,
        //               typeOfTimeMeasurement: active.unit_of_time,
        //             },
        //             solution: active?.consequence,
        //           })
        //         )
        //       : [],
        // },
        // pcn: {
        //   defaultProcess:
        //     id !== 'new' && technologyDetails?.id
        //       ? [
        //           ...technologyDetails.standard_stages.map((stage) => ({
        //             description: stage.description,
        //             asset_id: stage.technology_stage_id,
        //             document_id: stage.document_id,
        //           })),
        //         ]
        //       : [],
        //   activationProcess: {
        //     actuation:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.responsible_contingency_activation.map(
        //             (responsible) => responsible.id
        //           )
        //         : [],
        //     tests:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.responsible_contingency_test.map(
        //             (responsible) => responsible.id
        //           )
        //         : [],
        //     revisionDate:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.revision_date
        //         : null,
        //     period:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.revision_period
        //         : null,
        //     processes:
        //       id !== 'new' && technologyDetails?.id
        //         ? [
        //             ...technologyDetails.contingency_stages.map((stage) => ({
        //               description: stage.description,
        //               asset_id: stage.technology_stage_id,
        //               document_id: stage.document_id,
        //             })),
        //           ]
        //         : [],
        //   },
        //   operationalResponse: {
        //     processes:
        //       id !== 'new' && technologyDetails?.id
        //         ? [
        //             ...technologyDetails.operational_stages.map((stage) => ({
        //               description: stage.description,
        //               asset_id: stage.technology_stage_id,
        //               document_id: stage.document_id,
        //             })),
        //           ]
        //         : [],
        //     return:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.responsible_operational.map(
        //             (responsible) => responsible.id
        //           )
        //         : '',
        //     tests:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.responsible_operational_test.map(
        //             (responsible) => responsible.id
        //           )
        //         : '',
        //     communication:
        //       id !== 'new' && technologyDetails?.id
        //         ? technologyDetails.responsible_operational_communication.map(
        //             (responsible) => responsible.id
        //           )
        //         : '',
        //   },
        // },
      }}
      validationSchema={validationSchema}
      onSubmit={submit}
      enableReinitialize
    >
      {({ handleSubmit, errors }) => (
        <Container>
          <Title>
            {id === 'new'
              ? i18next.t('technologiesAsset.newTitle')
              : i18next.t('technologiesAsset.editTitle')}
          </Title>
          <Tabs
            tabs={[
              i18next.t('technologiesAsset.tabs.process.label'),
              i18next.t('technologiesAsset.tabs.impact.label'),
              // i18next.t('technologiesAsset.tabs.contingencyAssets.label'),
              // i18next.t('technologiesAsset.tabs.pcn.label'),
            ]}
            tabsError={[false, false, errors.contingencyAssets, false]}
          >
            <AssociateProcess />
            <Impact />
            <Actives />
            <PCN />
          </Tabs>
          <ButtonWrapper>
            <Button>
              <PrimaryButton
                type="submit"
                label={
                  isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
                }
                onClick={handleSubmit}
              />
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </Formik>
  );
};

export default Details;
export { default as ActivesLayout } from '../Layout';
