/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ClientLayout from '~/layouts/ClientLayout';
import history from './history';

import store from '../store';

const ClientRoute = ({
  component: Component,
  backButton,
  noDrawer,
  ...rest
}) => {
  const { clientSignedIn } = store.getState().auth;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (clientSignedIn) {
          return (
            <ClientLayout backButton={backButton} noDrawer={noDrawer}>
              <Component {...props} />
            </ClientLayout>
          );
        }
        if (history.location.pathname.includes('/demands-history')) {
          return (
            <Redirect to={`/login?redirect=${history.location.pathname}`} />
          );
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

ClientRoute.propTypes = {
  component: PropTypes.func.isRequired,
  backButton: PropTypes.bool,
  noDrawer: PropTypes.bool,
};

ClientRoute.defaultProps = {
  backButton: false,
  noDrawer: false,
};

export default ClientRoute;
