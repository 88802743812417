import StringMask from 'string-mask';

export const toPhone = (value) =>
  new StringMask('(00) 00009-0000', { reverse: true }).apply(value);

export const byteToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const formaterCnpj = (value) => {
  return value.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    '$1.$2.$3/$4-$5'
  );
};

export const getNumbers = (value) => {
  return value.replace(/\D/g, '');
};

export default {
  toPhone,
  byteToSize,
};
