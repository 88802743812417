import * as Yup from 'yup';
import { isValid as isValidCpf } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';

export default Yup.object().shape({
  id: Yup.string().required('Campo obrigatório'),
  name: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Campo inválido')
    .test('cpf', 'CPF inválido', (cpf) => cpf?.length > 14 || isValidCpf(cpf))
    .test(
      'cpf',
      'CNPJ inválido',
      (cnpj) => cnpj?.length < 15 || isValidCnpj(cnpj)
    ),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .min(14, 'Telefone inválido')
    .required('Campo obrigatório'),
  departure: Yup.string().required('Campo obrigatório'),
  role: Yup.string().required('Campo obrigatório'),
  permission: Yup.string().required('Campo obrigatório'),
  file: Yup.mixed().nullable(),
  // .test(
  //   'fileSize',
  //   'File too large',
  //   (value) => value && value.size <= FILE_SIZE
  // )
  // .test(
  //   'fileFormat',
  //   'Unsupported Format',
  //   (value) => value && SUPPORTED_FORMATS.includes(value.type)
  // ),
});
