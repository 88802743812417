import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 2%;
  margin-bottom: 0.5rem;
`;

export const Title = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: #262a3a;
`;

export const Alert = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: #0e067d;
  background: rgba(92, 238, 167, 0.25);
  border-radius: 4px;
  padding: 0.5rem 2rem;
  margin-left: 1rem;
`;

export const Scroll = styled(PerfectScrollbar)`
  width: 100%;
  height: 53rem;
`;
