import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

function Steps({ step, onNext }) {
  const [hash, setHash] = useState();
  const [email, setEmail] = useState();

  switch (step) {
    case 1:
      return <Step1 onSubmit={onNext} setEmail={setEmail} />;
    case 2:
      return <Step2 onSubmit={onNext} setHash={setHash} email={email} />;
    case 3:
      return <Step3 onSubmit={onNext} hash={hash} email={email} />;

    default:
      return <div />;
  }
}
Steps.propTypes = {
  step: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Steps;
