export default {
  translations: {
    common: {
      search: 'Buscar',
      typeIt: 'Digite aqui',
      selectIt: 'Selecionar',
      total: 'Total',
      noData: 'Sem dados',
      noOptions: 'Nenhuma opção encontrada',
      createAccountSuccess:
        'Realize a confirmação da sua conta PCS através da caixa de entrada do email cadastrado!',
      resendEmail:
        'Um novo email de confirmação de conta foi enviado para o email cadastrado!',
      fields: {
        name: 'Nome',
        lastName: 'Sobrenome',
        cpfCnpj: 'CPF/CNPJ',
        phone: 'Telefone',
        email: 'E-mail',
        duedate: 'Prazo',
        subject: 'Assunto',
        description: 'Descrição',
        role: 'Cargo',
        department: 'Departamentos',
        profile: 'Perfil de usuário',
        socialReason: 'Razão social',
        fantasyName: 'Nome Fantasia',
        cnpj: 'CNPJ',
        cpf: 'CPF',
        document: 'Documento',
        password: 'Senha',
      },
      signUp: {
        title1: 'Crie,',
        title2: 'entre e',
        title3: 'conheça',
        stepLabel1: 'Etapa',
        stepLabel2: 'de',
      },
      validation: {
        required: 'Campo obrigatório',
        phone: 'Telefone inválido',
        email: 'E-mail inválido',
        cnpj: 'CNPJ inválido',
        minPassword: 'Mínimo de 8 dígitos',
        matchPassword: 'As senhas não correspondem',
        invalidNumber: 'Número inválido',
      },
      buttons: {
        goBack: 'Voltar',
        continue: 'Avançar',
        return: 'Retornar',
        next: 'Próximo',
        deleteAccount: 'Excluir conta',
        cancel: 'Cancelar',
        edit: 'Editar',
        register: 'Cadastrar',
        answer: 'Responder',
        export: 'Exportar',
        import: 'Importar',
        add: 'Adicionar',
        close: 'Fechar',
        new: 'Novo',
        move: 'Mover',
        evaluate: 'Avaliar',
        delete: 'Excluir',
        send: 'Enviar',
        save: 'Salvar',
        update: 'Atualizar',
        attach: 'Anexar',
        completeUpload: 'Fazer upload',
        dropFile: 'Solte o arquivo',
        go: 'Entrar',
        invitationHistory: 'Histórico de convites',
      },
      roles: {
        administrator: 'Administrador',
        gedReviewer: 'Revisor GED',
        dpo: 'DPO Externo',
        internTreatmentAgent: 'Agente de Tratamento',
        owner: 'Proprietário(a)',
        payingAdmin: 'Administrador de Pagamento',
        others: 'Outros',
      },
      risks: {
        catastrophic: 'Catastrófico',
        high: 'Elevado',
        moderate: 'Moderado',
        possible: 'Possível',
        insignificant: 'Insignificante',
      },
      impacts: {
        negligible: 'Desprezível',
        little: 'Pequeno',
        medium: 'Médio',
        larger: 'Maior',
        extreme: 'Extremo',
      },
      typeOfTimeMeasurement: {
        minutes: 'Minuto(s)',
        hour: 'Hora(s)',
        day: 'Dia(s)',
        week: 'Semana(s)',
        month: 'Mês(es)',
      },
    },
    languages: {
      portuguese: 'Português BR',
      english: 'Inglês EUA',
      french: 'Francês',
      german: 'Alemão',
    },
    panel: {
      info: {
        CompaniesIparticipateInfo:
          'Empresas das quais você participa como um usuário. ',
      },
      companyCard: {
        entryButton: 'Entrar',
        activeButton: 'Ativar',
      },
      tabs: {
        allCompanies: 'Todas',
        myCompanies: 'Minhas empresas',
        associatedCompanies: 'Empresas que faço parte',
      },
    },
    privateLayout: {
      logoutCompany: 'Sair da empresa',
      header: {
        logouTofAccount: 'Sair',
      },
    },
    cropModal: {
      title: 'Ajustar imagem',
    },
    dashboard: {
      hello: 'Olá',
      welcomeMessage: 'Seja bem vindo ao PCS, o que você deseja fazer hoje?',
      risksTitle: 'Principais Riscos',
      riskTable: {
        active: 'Ativo',
        threat: 'Ameaça',
        occurence: 'Data da ocorrência',
        risk: 'Risco',
      },
      tickets: {
        open: 'Tickets em aberto',
        answered: 'Tickets atendidos',
        holders: 'Titulares cadastrados',
        processes: 'Processos cadastrados',
        jan: 'Jan',
        feb: 'Fev',
        mar: 'Mar',
        apr: 'Abr',
        may: 'Mai',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Ago',
        sep: 'Set',
        oct: 'Out',
        nov: 'Nov',
        dec: 'Dez',
      },
      needRevision: {
        title: 'Necessita revisão',
        noData: 'Sem dados',
      },
      nextRevisions: {
        title: 'Próximas revisões de ativos',
        noData: 'sem próximas revisões',
      },
    },
    panelLayout: {
      title: 'Olá',
      subtitle: 'Entre ou cadastre uma nova empresa',
      newEnterprise: 'Nova Empresa',
      signupMessage: 'preencha os dados cadastrais ao lado',
    },
    step2: {
      title: 'Sucesso',
      subtitle:
        'Sua compra foi feito com sucesso, aguarde até validarmos o seu perfil',
    },
    demandManagement: {
      title: 'Gestão de demandas',
      table: {
        date: 'Data',
        protocol: 'Protocolo',
        subject: 'Assunto',
        holder: 'Titular',
        responsible: 'Responsável',
        deadline: 'Prazo',
        priority: 'Prioridade',
        status: 'Status',
        priorityTag: {
          low: 'Baixa',
          medium: 'Média',
          high: 'Alta',
        },
        statusTag: {
          open: 'Aberto',
          answered: 'Respondido',
          closed: 'Fechado',
        },
      },
      demandDetails: {
        title: 'Detalhes do atendimento',
        subtitle: 'Informações básicas',
        answer: 'Responder',
        reOpen: 'Reabrir demanda',
        chat: 'Chat',
        attachments: 'Arquivos anexados',
        attach: 'Anexar arquivos',
        attachInstruction: 'Arraste ou adicione',
        newAnswerInstructions: 'Responder ao atendente',
        interactions: 'Interações',
        priority: 'Prioridade',
        deadline: 'Prazo',
      },
      chat: {
        title: 'Chat',
        noData: 'Sem usuários',
        access: 'Ver',
        collaborators: 'Colaboradores',
        search: 'Pesquisar por colaborador',
        chatTitle: 'Grupo | Protocolo',
        attachToDemand: 'Anexar à demanda',
        attachToChat: 'Anexar ao chat',
        instruction: 'Escreva algo...',
      },
    },
    holders: {
      title: 'Titulares',
      table: {
        id: 'ID',
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        createdAt: 'Criado em',
        action: 'Ação',
      },
    },
    documents: {
      title: 'Gestão de documentos',
      new: 'Novo',
      search: 'Pesquisar pasta',
      noData: 'Nenhuma pasta encontrada!',
      newFolder: 'Nova pasta',
      editFolderName: 'Editar nome da pasta',
      newFolderName: 'Insira o nome',
      category: 'Categoria',
      archives: 'Arquivos',
      description: 'Você está na pasta de',
      searchDocuments: 'Pesquisar documentos',
      myDocuments: 'Meus documentos',
      sharedDocuments: 'Documentos compartilhados',
      recentArchives: 'Arquivos recentes',
      noDocumentsInFolder: 'Sem documentos na pasta!',
      limitPerPage: 'Limite por página',
      tabs: {
        allDocuments: 'Todos Documentos',
        myDocuments: 'Meus Documentos',
        companyFolders: 'Pastas da empresa',
        PCSFolders: 'Pastas PCS',
      },
      files: 'Arquivos',
      file: 'Arquivo',
      noFiles: 'Pasta vazia',
      table: {
        name: 'Nome',
        date: 'Data',
        responsible: 'Responsável',
        lastView: 'Última visualização',
        view: 'Visualizar',
        moveArchive: 'Mover arquivo',
        delete: 'Excluir',
      },
      newDocumentModal: {
        title: 'Novo documento',
        nameInstruction: 'Insira o título',
        placeholder: 'Ex: documentação',
        attachInstruction: 'Arraste ou adicione',
        error: 'Você não selecionou um arquivo para upload!',
      },
      updateDocumentModal: {
        title: 'Upload de documento',
        attachInstruction: 'Arraste ou adicione',
        error: 'Você não selecionou um arquivo para upload!',
      },
      moveDocumentModal: {
        title: 'Movimentar arquivo',
        chooseFolder: 'Escolha a pasta:',
      },
      deleteDocumentModal: {
        question: 'Gostaria de excluir',
      },
      deleteFolderModal: {
        question: 'Gostaria de excluir a pasta: ',
      },
      documentDetails: {
        download: 'Baixar doc',
        creationDate: 'Data de criação',
        responsible: 'Responsável',
        changeHistory: 'Histórico de alterações',
        participants: 'Participantes',
        by: 'Por',
      },
    },
    documentReview: {
      title: 'Gestão de documentos',
      table: {
        name: 'Nome do arquivo',
        editedBy: 'Editado por',
        version: 'Versão',
        lastUpdate: 'Última alteração',
        status: 'Status',
        action: 'Ação',
        view: 'Visualizar',
        needAnalysis: 'Requer análise',
        review: 'Revisar',
        approved: 'Aprovado',
        dataNotFound: 'Dado não encontrado.',
      },
      documentDetails: {
        askForReview: 'solicitar revisão',
        approve: 'aprovar',
        download: 'Baixar doc',
        responsible: 'Responsável',
        createdAt: 'Data de criação',
        informations: 'Informações',
      },
    },
    iventory: {
      title: 'Registros de data mapping',
      new: 'Novo',
      table: {
        department: 'Departamento',
        process: 'Processo',
        responsible: 'Responsável',
        lastModified: 'Última Modificação',

        actions: {
          label: 'Ações',
          edit: 'Editar',
          delete: 'Excluir',
          show: 'Visualizar',
          export: 'Exportar',
        },
      },
      createOptionModal: {
        date: 'Data',
        name: 'Nome',
      },
      tab: {
        title: 'Formulário de data mapping',
        titleEdit: 'Editar data mapping',
        placeholder1: 'Digite aqui',
        placeholder2: 'Selecione',
        placeholder3: 'Descrição aqui',
        next: 'Próximo',
        registerLater: 'Cadastrar depois',
        processDetails: {
          label: 'Detalhes do Processo',
          department: 'Departamento',
          process: 'Processo',

          collectionProcess: {
            label: 'Processo de coleta',
            purposeOfCollection: 'Finalidade de coleta/Tratamento',
            collectionForms: 'Formas de coleta',
            categoryOfDataCollected: 'Categoria de dados coletados',
          },
          legalFramework: {
            label: 'Enquadramento jurídico',
            transparencyToTheHolder: 'Transparência ao titular ',
            collectedData: 'Dados coletados',
            retentionTime: 'Tempo de retenção',
            timeUnit: 'Unidade de tempo',

            legalBasis: 'Avaliação de Base Legal (Art. 7)',
            processUpdateFrequency: 'Periodicidade de atualização do processo',
            alignedAdjustments:
              'Adequações alinhadas pelo Comitê de Privacidade?',
          },
          dataInMotion: {
            label: 'Dados em Movimento',
            iNTransmission: 'Transmissão IN (Entrada) ',
            oUTTransmission: 'Transmissão OUT (Saída) ',
            collectionMedium: 'Meio de coleta',
            storageforms: 'Formas de armazenamento',
          },

          thirdParties: {
            label: 'Fornecedor/Terceiros',
            partner: 'Parceiro',
            shareType: 'Tipo de compartilhamento',
          },
        },
        controls: {
          label: 'Controles',
          informationSecurity: {
            label: 'Controle de Segurança da Informação/T.I',
            haveAccessControl: 'Possui controle de acesso?',
            haveAuthentication: 'Possui autentificação?',
            haveEncryption: 'Possui criptografia?',
            haveMasking: 'Possui mascaramento de dados pessoais?',
            haveAnonymization: 'Possui pseudoanonimização?',
            haveBackup: 'Possui backup (cópia de segurança)?',
            haveDLP: 'Possui proteção DLP (Prevenção de roubo dados)?',
            classificationOfData: 'Qual a classificação de dados/informação?',
            hasAntivirusProtection: 'Possui proteção antivirus?',
            note: 'Observação',
          },
          hasComponent: {
            yesLabel: 'Sim',
            noLabel: 'Não',
            description: 'Descrição',
          },
        },
      },
      collectionPurpose: 'Finalidade de coleta/tratamento',
      collectionForms: 'Formas de coleta',
      categoryOfDataCollected: 'Categoria de dados coletados',
      transparencyToTheHolder: 'Transparência ao titular',
      collectedData: 'Dados coletados',
      legalBasisAssessment: 'Avaliação de Base Legal (Art.7)',
      processUpdateFrequency: 'Periodicidade de atualização do processo',
      InOutTransmission: 'Transmissão IN/OUT (Entrada e saída)',
      collectionMedium: 'Meio de coleta',
      storageForms: 'Formas de armazenamento',
    },
    configurations: {
      departments: {
        title: 'Departamentos',
        details: {
          departament: 'Departamento',
          description: 'Descrição',
          managers: 'Gestores',
        },
        registrationModal: {
          label: 'Cadastrar departamento',
          label2: 'Editar departamento',
          editLabel: 'Editar departamento',
          name: 'Nome',
          managers: 'Gestores',
          description: 'Descrição',
          placeholderDescription: 'Ex: Atendimento ao Cliente',
          processes: {
            name: 'Nome',
            responsible: 'Responsaveis',
            description: 'Descrição',
          },
        },
        categoryModal: {
          label: 'Cadastrar categoria',
          name: 'Nome',
        },
      },
      cancellationModal: {
        title: 'Deseja salvar os dados modificados?',
        yesLabel: 'Sim',
        noLabel: 'Não',
      },
      checkoutStep1: {
        title: 'Dados da empresa',
        subtitle: 'Cadastre os dados básicos da empresa',
        NameCompany: 'Nome da Empresa',
        DocumentCompany: 'CNPJ',
      },
      checkoutPay: {
        title: 'Ativação da empresa',
        subtitle: 'Utilize um voucher para ativar sua empresa',
        credit: 'Cartão de Crédito',
        ticket: 'Boleto bancário',
        debit: 'Cartão de Débito',
        voucher: 'Voucher',
        label: 'Insira o código',
        buyVoucher: 'Adquirir voucher',
      },
      checkoutVoucher: {
        title: 'Ativação da empresa',
        subtitle: 'Insira o código que recebeu em seu e-mail.',
        label: 'Código',
      },
      checkoutStep3: {
        title: 'Sucesso',
        subtitle:
          'Sua compra foi feito com sucesso, aguarde até validarmos o seu perfil',
      },
      voucher: {
        title:
          'Digite o código que foi encaminhado de um dos nossos consultores',
        placeholder: 'Digite aqui',
        label: 'Insira o código',
      },

      user: {
        title: 'Informações Pessoais',
        titleUserCompany: 'Usuários da empresa',
        attachmentInsert: 'Insira sua foto',
        attachmentDrop: 'Solte a foto',
        attachmentError: 'Formato não suportado',
        attachmentDescription:
          'A foto deve ser em .jpg ou .png, e ter a resolução de até 1200x1200px.',
        id: 'ID',
        nameLabel: 'Nome',
        phoneLabel: 'Telefone',
        emailLabel: 'E-mail',
        roleLabel: 'Cargo',
        cnpjLabel: 'CNPJ',
        actionLabel: 'Acão',
        actived: 'Ativo',
        desactived: 'Desativado',
        waiting: 'Aguardando',
      },
      signature: {
        title: 'Assinatura',
        subtitle: 'Pagamentos',
        type: 'Tipo',
        purchaseDate: 'Data da compra',
        meansOfPayment: 'Meio de pagamento',
        responsible: 'Responsável',
        value: 'Valor',
        status: 'Status',
        expirationDate: 'Data de validade',
        actionLabel: 'Acão',
        buttonSignature: 'Contratar assinatura',
        state: {
          success: 'Sucesso',
        },
        period: {
          biweekly: 'Quinzenal',
          monthly: 'Mensal',
          semiannual: 'Semestral',
          yearly: 'Anual',
        },
        paymentMethod: {
          voucher: 'Voucher',
        },
      },
      cancelModal: {
        title: 'Aviso',
        description: 'As compras podem ser canceladas em até 7 dias úteis.',
        buttonReturn: 'Voltar',
        buttonCancel: 'Quero cancelar',
      },
      cancelOptionsModal: {
        title: 'Cancelamento',
        description:
          'Você esta prestes a cancelar uma compra. Gostariamos de saber o motivo.',
        option1: 'Não cumprio com as minhas expectativas.',
        option2: 'Motivo 2',
        option3: 'Motivo 3',
        option4: 'Motivo 4',
        buttonReturn: 'Voltar',
        buttonCancel: 'Quero cancelar',
      },
      sucessCancel: {
        title: 'Sucesso',
        subtitle: 'Sua compra foi cancelado com sucesso!',
        description:
          'Foi enviado um e-mail com as informações sobre o cancelamento, para os e-mails de cadastro da empresa.',
        buttonClose: 'Fechar janela',
      },

      successPay: {
        title: 'Pagamento realizado com sucesso!',
        description:
          'Será enviado um e-mail nos próximos dias com as informações para os próximos passos.',
        buttonClose: 'Fechar janela',
      },
      failureCancel: {
        title: 'Erro',
        subtitle: 'Erro ao processar sua solicitação',
        description:
          'Por favor tente novamente ou contate nossa equipe de suporte.',
        buttonSupport: 'Suporte',
        buttonTry: 'Tentar novamente',
      },
      failurePay: {
        title: 'Erro ao processar sua solicitação',
        description:
          'Por favor tente novamente ou contate nossa equipe de suporte.',
        buttonSupport: 'Suporte',
        buttonTry: 'Tentar novamente',
      },
      invitationHistory: {
        title: 'Histórico de convites',
        buttonSubmit: 'Reenviar convite',
        email: 'Email',
        status: 'Status',
        data: 'Data',
      },
      company: {
        title: 'Informações sobre a empresa',
        tabs: {
          company: {
            label: 'Empresa',
            nameLabel: 'Nome da Empresa',
            socialReasonLabel: 'Razão social',
            fantasyNameLabel: 'Nome Fantasia',
            cnpjLabel: 'CNPJ',
            emailLabel: 'E-mail',
            phoneLabel: 'Telefone',
            attachments: {
              title: 'Anexar arquivos',
              subtitle:
                'Anexe abaixo os seguintes itens para cadastro da empresa:',
              description1: 'Estatuto da empresa',
              description2: 'Última alteração contratual',
              description3: 'Comprovante de endereço',
              byLawLabel: 'Anexe o estatuto da empresa',
              lastContractualLabel: 'Última alteração contratual',
              addressProofLabel: 'Comprovante de endereço',
            },
          },
          legalAttachments: {
            label: 'Responsáveis legais',
          },
          contingencyAssets: {
            label: 'Ativos de contingência',
            addAssetQuestionLabel: 'Adicionar ativo de contigência',
            howLongLabel: 'Quanto tempo ele pode operar em razão do outro?',
            solutionLabel: 'Solução que ele irá ofertar',
          },
          dpoStep: {
            title: 'DPO/Encarregado de dados',
            nameLabel: 'Nome Completo',
            emailLabel: 'E-mail',
          },
        },
        attachmentInsert: 'Insira sua logo aqui',
        attachmentDrop: 'Solte a logo',
        attachmentError: 'Formato não suportado',
        attachmentDescription:
          'A logo da empresa deve ser em .jpg ou .png, e ter a resolução de até 1200x1200px.',
        companyAttachmentsTitle: 'Anexar arquivos - Empresa',
        legalAttachmentsTitle: 'Anexar arquivos - Responsável legal',
        linkToOpenDemand: 'Copiar link para abertura de chamado',
        bylaw: 'Anexe o estatuto da empresa',
        last_contractual_alteration: 'Última alteração contratual',
        address_proof: 'Comprovante de endereço',
        id_document: 'Documento de identificação',
        cpf_document: 'CPF',
        legal_address_proof: 'Comprovante de endereço',
        letter_of_attorney: 'Procuração',
      },
    },
    notFound: {
      message1: 'Página',
      message2: 'não encontrada',
      button: 'Voltar para  o início',
    },
    confirmEmail: {
      title: 'Confirme o seu cadastro',
      confirmReceipt: 'Já confirmei minha conta',
      resend: 'Enviar e-mail de confirmação',
    },
    login: {
      emailLabel: 'Seu e-mail',
      passwordLabel: 'Sua senha',
      rememberPassword: 'Lembrar senha',
      forgotPassword: 'Esqueceu a senha?',
      loginButton: 'Entrar',
      signupButton: 'Cadastre-se',
      selectCompanyTitle: 'Olá, seja bem-vindo!',
      selectCompanyDescription: 'Selecione qual empresa você deseja iniciar.',
      erroEmailActivation: 'Sua conta ainda não foi validada por e-mail!',
      erroMessageEmailActivation:
        'Caso não tenha recebido o e-mail, solicite um novo link de confirmação abaixo.',
    },
    welcomeModal: {
      titleLabel: 'Bem vindo',
      question:
        'Complete seu cadastro para continuar navegando pelo nosso sistema!',
      skip: 'Pular',
      confimLabel: 'Completar',
    },
    forgotPassword: {
      step1: {
        subtitle: 'Esqueci minha senha',
        description: 'Por favor, insira seu e-mail de acesso',
        emailLabel: 'Seu e-mail',
        button: 'Recuperar senha',
      },
      step2: {
        subtitle: 'E-mail enviado com sucesso',
        description: 'Informe o código que foi enviado para seu e-mail',
        buttonValidate: 'Validar',
        buttonSupport: 'Falar com o suporte',
      },
      step3: {
        passwordLabel: 'Senha',
        passwordConfirmationLabel: 'Repetir senha',
        button: 'Atualizar senha',
      },
    },
    companySignUp: {
      whoAmIPage: {
        title: 'Olá, seja Bem-vindo!',
        subtitle: 'No que podemos te ajudar?',
        company: 'Sou empresa',
        dpo: 'Sou DPO',
        alreadySignedUp: 'Já possuo cadastro',
        copyright: 'Direitos reservados à PCS',
      },
      step1: {
        nameLabel: 'Nome',
        lastNameLabel: 'Sobrenome',
        emailLabel: 'Seu e-mail',
        phoneLabel: 'Telefone',
        documentLabel: 'CPF/CNPJ',
        cnpjLabel: 'CNPJ',
        termsLabel: 'Li e concordo com o',
        termsButton: '  Termo de uso',
        privacyPolicyLabel: 'Li e concordo com a',
        privacyPolicyButton: 'Política de Privacidade.',
        title: 'Termo de uso e Política de Privacidade.',
        privacyLabel: 'Aceito receber publicidade',
        privacyButton: 'do PCS e seus parceiros',
        fantasyNameLabel: 'Digite a Razão Social',
        buttonSubmit: 'Cadastrar',
      },
      step2: {
        companySteps: {
          label: 'Empresa',
          socialReasonLabel: 'Razão social',
          fantasyNameLabel: 'Nome Fantasia',
          cnpjLabel: 'CNPJ',
          emailLabel: 'E-mail',
          phoneLabel: 'Telefone',
          attachments: {
            title: 'Anexar arquivos',
            subtitle:
              'Anexe abaixo os seguintes itens para cadastro da empresa:',
            description1: 'Estatuto da empresa',
            description2: 'Última alteração contratual',
            description3: 'Comprovante de endereço',
            byLawLabel: 'Anexe o estatuto da empresa',
            lastContractualLabel: 'Última alteração contratual',
            addressProofLabel: 'Comprovante de endereço',
          },
        },
        legalResponsibleSteps: {
          label: 'Responsável legal',
          nameLabel: 'Nome completo',
          roleLabel: 'Cargo',
          cpfLabel: 'CPF',
          emailLabel: 'E-mail',
          phoneLabel: 'Telefone',
          attachments: {
            title: 'Anexar arquivos',
            subtitle:
              'Anexe abaixo os seguintes itens para cadastro do responsável legal:',
            description1: 'Documentos de Identificação (RG ou CNH)',
            description2: 'CPF',
            description3: 'Comprovante de endereço',
            description4: 'Procuração',
            identificationLabel: 'Documento de Identificação',
            letterOfAttorney: 'Procuração',
            addressProofLabel: 'Comprovante de endereço',
          },
        },
        dpoSteps: {
          label: 'DPO/Encarregado de dados (Opcional)',
          title: 'DPO/Encarregado de dados',
          nameLabel: 'Nome Completo',
          emailLabel: 'E-mail',
        },
      },
      step3: {
        title: 'E-mail enviado com sucesso',
        description:
          'Agora, passa no teu e-mail que tem um código para você lá',
        validateButton: 'Validar',
        supportButton: 'Falar com o suporte',
      },
      step4: {
        passwordLabel: 'Senha',
        passwordConfirmationLabel: 'Repetir senha',
      },
    },
    clientSignUp: {
      title: 'Seja bem vindo!',
      subtitle: 'Como é seu primeiro acesso, é necessário definir sua senha.',
      passwordLabel: 'Crie uma senha',
      passwordConfirmationLabel: 'Repetir senha',
      button: 'Definir senha',
    },
    dpoSignUp: {
      step1: {
        nameLabel: 'Seu nome completo',
        emailLabel: 'Seu e-mail',
      },
      step2: {
        passwordLabel: 'Crie uma senha',
        passwordConfirmationLabel: 'Repetir senha',
      },
    },
    callOpening: {
      title: 'Abra sua demanda de forma rápida.',
      demandOpening: {
        title: 'Formulário de abertura de demanda',
        subtitle:
          'Preencha os campos abaixo para que um analista possa realizar seu atendimento.',
        nameLabel: 'Seu nome completo',
        cpfLabel: 'Seu CPF',
        phoneLabel: 'Telefone',
        emailLabel: 'E-mail',
        subjectLabel: 'Assunto',
        descriptionLabel: 'Descrição',
        attachmentInstruction: 'Insira um arquivo relacionado a demanda',
      },
      demandOpeningSuccessModal: {
        title: 'Demanda aberta com sucesso!',
        subtitle: 'Acompanhe as próximas etapas através do seu email.',
        button: 'OK!',
      },
      demandHistory: {
        title: 'Histórico de demandas',
        date: 'Data',
        protocol: 'Protocolo',
        problem: 'Problema',
        responsible: 'Atendente',
        status: 'Status',
      },
      demandDetails: {
        title: 'Detalhes da demanda - Protocolo',
        demandInformation: 'Informações básicas da demanda',
        nameLabel: 'Nome',
        cpfLabel: 'CPF',
        phoneLabel: 'Telefone',
        emailLabel: 'E-mail',
        subjectLabel: 'Assunto',
        descriptionLabel: 'Descrição',
        closeDemandButton: 'Encerrar chamado',
        attachmentsTitle: 'Arquivos anexados',
      },
      answerDemand: {
        title: 'Responder',
        subtitle: 'Responder ao atendente',
        attachmentTitle: 'Anexar arquivos',
        attachmentInstructions: 'Arraste ou adicione',
      },
      interactions: {
        title: 'Interações ',
      },
      closeDemandModal: {
        title: 'Demanda encerrada com sucesso!',
        subtitle: 'Gostaria de avaliar este atendimento?',
      },
      evaluationModal: {
        title: 'Demanda encerrada com sucesso!',
        description:
          'Prezado {{name}}, acreditamos que todas as suas dúvidas referentes ao protocolo {{protocol}} tenham sido atendidas.',

        question: 'Você ficou satisfeito com o atendimento?',
        commentLabel:
          'Em poucas palavras, diga o que te motivou nesta avaliação ao atendimento',
        commentPlaceholder: 'Insira seu comentário aqui',
        horrible: 'Péssimo',
        bad: 'Ruim',
        medium: 'Médio',
        good: 'Bom',
        excelent: 'Excelente',
      },
    },
    peopleAsset: {
      title: 'Pessoas',
      table: {
        id: 'ID',
        name: 'Nome',
        phone: 'Telefone',
        email: 'E-mail',
        function: 'Função',
        action: {
          label: 'Ação',
          edit: 'Editar',
          delete: 'Excluir',
        },
      },
      modalExclusion: {
        title: 'Gestão de pessoas',
        description: 'Tem certeza que deseja excluir usuário? ',
        cancelButton: 'Cancelar',
        deleteButton: 'Excluir',
      },
      selectNewUserRoleModal: {
        title: 'Perfil de usuário',
        subtitle: 'Selecione o perfil que você deseja criar',
        placeholder: 'Selecionar',
      },
      selectSystemUser: {
        title: 'Usuario de sistema',
        subtitle: 'Informe se é um usuario de sistema',
        placeholder: 'Selecionar',
      },
      inviteDPOModal: {
        title: 'DPO/Encarregado de dados',
        name: 'Nome completo',
        email: 'E-mail',
      },
      createNewUserModal: {
        systemUser: 'Usuário de sistema',
        title: 'Informações básicas',
        userProfileLabel: 'Perfil de usuário',
        hr: 'RH',
        sales: 'Vendas',
        purchases: 'Compras',
        attendance: 'Atendimento',
        directors: 'Diretoria',
        inviteEmail: 'Enviar convite',
      },
      editUserModal: {
        title: 'Informações básicas',
        titleEdit: 'Editar usuário',
      },
    },
    businessUnitAsset: {
      title: 'Localidade',
      newTitle: 'Nova localidade',
      editTitle: 'Editar localidade',
      table: {
        name: 'Nome da Localidade',
        author: 'Autor',
        responsible: 'Responsável',
        date: 'Data',
        action: 'Ação',
        actionButton: 'Editar',
      },
      sidebar: {
        title: 'Informações básicas do ativo',
        titleLabel: 'Título',
        primaryFunctionLabel: 'Função primária',
        responsiblesLabel: 'Responsáveis pelo ativo',
        identifier: 'Identificador',
        creationDate: 'Data de criação',
        creationTime: 'Horário de criação',
      },
      tabs: {
        location: {
          label: 'Localização da unidade de negócio',
          streetLabel: 'Logradouro',
          numberLabel: 'Número',
          complementLabel: 'Complemento',
          districtLabel: 'Bairro',
          cityLabel: 'Cidade',
          stateLabel: 'Estado',
          cepLabel: 'CEP',
        },
        assets: {
          label: 'Ativos associados',
          typeLabel: 'Tipo',
          assetLabel: 'Ativo',
          user: 'Pessoa',
          process: 'Processo',
          locality: 'Localidade',
          asset: 'Tecnologia',
        },
        accessControl: {
          label: 'Controles de acesso',
          accessControlLabel: 'Controle de acesso',
        },
        evidences: {
          label: 'Evidências da documentação',
          title: 'Anexar documentação',
          descriptionLabel: 'Descrição do documento',
        },
      },
    },
    processAsset: {
      title: 'Processos',
      newTitle: 'Novo processo',
      editTitle: 'Editar processo',
      table: {
        name: 'Nome do processo',
        author: 'Autor',
        status: 'Status',
        date: 'Data',
        action: 'Ação',
        actionButton: 'Editar',
      },
      sidebar: {
        title: 'Informações do processo de negócio',
        titleLabel: 'Título',
        primaryFunctionLabel: 'Função primária',
        responsiblesLabel: 'Responsáveis pelo processo',
        businessUnitLabel: 'Localidade',
        identifier: 'Identificador',
        creationDate: 'Data de criação',
        creationTime: 'Horário de criação',
      },
      tabs: {
        executionPlan: {
          label: 'Plano de execução do processo',
          processExecutors: {
            title: 'Executores do processo',
            responsiblesLabel: 'Responsáveis pela execução do processo',
            locality: 'Localidade',
            nature: {
              label: 'Natureza do processo',
              human: 'Humano',
              digital: 'Digital',
            },
            linkedDepartments: 'Departamentos Vinculados',
            department: {
              label: 'Departamento',
              audit: 'Auditoria',
              commercial: 'Comercial',
              accounting: 'Contabilidade',
              bills_to_pay: 'Contas a Pagar',
              bills_to_receive: 'Contas a Receber',
              personal_department: 'Departamento Pessoal',
              finance: 'Financeiro',
              legal: 'Jurídico',
              marketing: 'Marketing',
              production: 'Produção',
              quality: 'Qualidade',
              rh: 'RH',
              information_security: 'Segurança da Informação',
              patrimony_security: 'Segurança Patrimonial',
              it: 'TI',
            },
            graphicSchema: 'Esquema gráfico',
          },
          processSteps: {
            title: 'Etapas do processo',
            descriptionLabel: 'Descrição da etapa',
            assetLabel: 'Associar Ativo (opcional)',
            documentLabel: 'Associar Documento (opcional)',
            moveLabel: 'Mover',
            addAnotherLabel: 'Adicionar outro',
          },
        },
        contingencyDetails: {
          label: 'Estratégia de contigência',
          maxTimeLabel: 'Tempo máximo sem operar',
          impactLabel: 'Impacto',
          consequencesLabel:
            'Consequências causadas pela inoperância do processo',
        },
        associatedActives: {
          label: 'Ameaças associadas',
          typeLabel: 'Tipo',
          assetLabel: 'Ativo',
          user: 'Pessoa',
          process: 'Processo',
          asset: 'Tecnologia',
          createModal: {
            titleModal: 'Cadastrar processo',
            titleLabel: 'Título',
          },
        },
        associatedThreats: {
          label: 'Ameaças associadas',
          typeLabel: 'Tipo de ameaça',
          threatLabel: 'Ameaça',
          threats: {
            nature: 'Naturais',
            human: 'Humanas',
            technologies: 'Tecnológicas',
            physics: 'Físicas',
            economy: 'Politicas/Econômicas',
          },
        },
      },
    },
    technologiesAsset: {
      title: 'Tecnologias',
      newTitle: 'Nova Tecnologia',
      editTitle: 'Editar Tecnologia',
      table: {
        name: 'Nome da tecnologia',
        author: 'Autor',
        status: 'Status',
        date: 'Data',
        action: 'Ação',
        actionButton: 'Editar',
      },
      sidebar: {
        title: 'Informações básicas do ativo',
        titleLabel: 'Título',
        primaryFunctionLabel: 'Função primária',
        responsiblesLabel: 'Responsáveis pelo ativo',
        businessUnitLabel: 'Localidade',
        department: 'Departamento',
        identifier: 'Identificador',
        creationDate: 'Data de criação',
        creationTime: 'Horário de criação',
        editBy: 'Editado por',
        editDate: 'Última Edição',
      },
      tabs: {
        process: {
          label: 'Associar processos',
          processLabel: 'Processo',
          natureLabel: 'Natureza',
          repository: 'Repositório',
          repositoryInfo:
            'Caso a empresa possua sistemas e/ou recursos para armazenamento fora do Brasil. Ex: Google Drive, OneDrive, Salesforce e etc...',
          internationalStorage:
            'Possui Tratamento/Armazenamento Internacional dos Dados?',
          externalTreatment: 'Possui Tratamento Externo dos Dados?',
          externalTreatmentInfo:
            'Caso a empresa possua atividades terceirizadas. Ex: Contabilidade sendo realizadas externamente.',
          status: 'Status',
          yesLabel: 'Sim',
          noLabel: 'Não',
          active: 'Ativo',
          disabled: 'Desativado',
          department: {
            audit: 'Auditoria',
            commercial: 'Comercial',
            accounting: 'Contabilidade',
            bills_to_pay: 'Contas a Pagar',
            bills_to_receive: 'Contas a Receber',
            personal_department: 'Departamento Pessoal',
            finance: 'Financeiro',
            legal: 'Jurídico',
            marketing: 'Marketing',
            production: 'Produção',
            quality: 'Qualidade',
            rh: 'RH',
            information_security: 'Segurança da Informação',
            patrimony_security: 'Segurança Patrimonial',
            it: 'TI',
          },
        },
        impact: {
          label: 'Impacto por inoperância',
          title: 'Impacto por inoperância do ativo',
          maxTime: 'Tempo máximo sem operar',
          time: 'Tempo',
          unitOfMeasurement: 'Unidade de tempo',
          impactLabel: 'Impacto',
          consequencesLabel: 'Consequências causadas pela inoperância do ativo',
        },
        contingencyAssets: {
          label: 'Ativos de contingência',
          type: 'Tipo',
          assetLabel: 'Associar Ativo (Componente)',
          addAssetQuestionLabel: 'Adicionar ativo de contigência',
          maxTime: 'Tempo máximo sem operar',
          unitOfMeasurement: 'Unidade de tempo',
          howLongLabel: 'Quanto tempo ele pode operar em razão do outro?',
          solutionLabel: 'Solução que ele irá ofertar',
        },
        pcn: {
          label: 'Processo de continuidade de negócio (PCN)',
          common: {
            descriptionLabel: 'Descrição da etapa*',
            type: 'Tipo*',
            assetLabel: 'Associar Ativo (Componente)*',
            documentLabel: 'Associar Documento (opcional)',
            moveLabel: 'Mover',
            addAnotherLabel: 'Adicionar outro',
          },
          standardProcess: {
            title: 'Processo padrão',
          },
          contingencyActivation: {
            title: 'Processo de ativação da contingência',
            contingencyTriggerResponsiblesLabel:
              'Responsáveis pelo acionamento da contigência',
            contingencyTesteResponsiblesLabel:
              'Responsáveis pelos testes em contigência',
            revisionDateLabel: 'Data de inicio da revisão',
            revisionPeriodLabel: 'Período da revisão',
            weekly: 'Semanal',
            yearly: 'Anual',
          },
          operationalResponse: {
            title: 'Processo de retorno operacional',
            responsiblesOperationalResponse:
              'Responsáveis pelo retorno operacional',
            responsiblesTestOperationalResponse:
              'Responsáveis pelos testes pós retorno operacional',
            reponsiblesComunicationOperationalResponse:
              'Responsáveis pela comunicação pós retorno operacional',
          },
        },
      },
    },
    risks: {
      title: 'Gestão de Riscos',
      board: {
        probability: {
          title: 'Probabilidade',
          veryLow: 'Muito baixa',
          low: 'Baixa',
          medium: 'Média',
          high: 'Alta',
          veryHigh: 'Muito alta',
        },
        impact: {
          title: 'Impacto',
          negligible: 'Desprezível',
          little: 'Pequena',
          medium: 'Média',
          bigger: 'Maior',
          extreme: 'Extremo',
        },
      },
      captions: {
        title: 'Legenda',
        insignificant: 'Insignificante',
        possible: 'Possível',
        moderate: 'Moderado',
        high: 'Elevado',
        catastrophic: 'Catastrófico',
      },
      risksTable: {
        id: 'ID',
        asset: 'Ativo',
        businessUnit: 'Localidade',
        threat: 'Ameaça',
        risk: 'Risco',
        impact: 'Impacto',
        status: {
          label: 'Status',
          unmitigated: 'Não tratado',
          mitigated: 'Mitigado',
        },
        action: 'Ação',
        noData: 'Não há dados no momento...',
        actionButton: {
          view: 'Visualizar',
          hide: 'Ocultar',
        },
      },
      riskDetails: {
        title: 'Risco',
        createEvaluation: 'Realizar avaliação',
        manifestationProbability: 'Probabilidade de manifestação',
        processImpact: 'Impacto sobre o(s) processo(s)',
        resultRisk: 'Risco (Resultado)',
        associations: {
          goToButton: 'Ir para',
          threats: {
            title: 'Ameaças associadas',
            label: 'Ameaças',
            threatType: 'Tipo de ameaça',
            threat: 'Ativo',
          },
          impactForInoperability: {
            title: 'Impacto por inoperância do ativo',
            label: 'Impacto',
            maxTime: 'Tempo máximo sem operar',
            impact: 'Impacto',
            consequences: 'Consequências causadas pela inoperância do ativo',
          },
          assets: {
            label: 'Ativos de contingência',
            addAsset: 'Adicionar ativo de contigência?',
            howLong: 'Quanto tempo ele pode operar em razão do outro?',
            solution: 'Solução que ele irá ofertar',
          },
          contingency: {
            title: 'Detalhes de contingência',
            label: 'Processo de contingência',
            maxTime: 'Tempo máximo sem operar',
            impact: 'Impacto',
            consequences: 'Consequências causadas pela inoperância do processo',
          },
        },
        elements: {
          title: 'Elementos impactados pelo risco',
          businessUnit: 'Localidade',
          processes: 'Processos',
          people: 'Pessoas',
          technologies: 'Tecnologias',
          noData: 'Não há dados no momento...',
        },
        evaluationsHistory: {
          title: 'Histórico de avaliações de risco',
          noData: 'Não há dados no momento...',
        },
        createEvaluationModal: {
          title: 'Avaliação de risco',
          descriptionLabel: 'Descrição da avaliação (pré)',
          reanalisysLabel: 'Reanálisar em até',
          evidenceLabel: 'Anexar evidência',
          probabilityLabel: 'Probabilidade de manifestação',
          impactLabel: 'Impacto',
          riskLabel: 'Risco (Resultado)',
          detectionLevelLabel: 'Facilidade de detecção',
          rfdLabel: 'R.F.D (Resultado)',
          residualRiskQuestion: 'Existe um risco residual pós mitigação?',
          residualRisk: 'Riscos residuais pós mitigação',
          yesLabel: 'Sim',
          noLabel: 'Não',
          posEvaluationDescription: 'Descrição da avaliação (pós)',
          attachEvidence: 'Anexar evidência',
          addResidualRiskButton: 'Adicionar risco residual',
          treatResidualRiskButton: 'Tratar risco residual',
          treatedResidualRiskButton: 'Riscos residuais tratados',
        },
        treatResidualRiskModal: {
          question: 'Deseja tratar o risco residual?',
          questionView: 'Deseja visualizar os riscos tratados?',
          treatButton: 'Tratar',
          viewButton: 'Visualizar',
          title1: `Informe quais elementos serão`,
          title2: `associados ao risco residual`,
          associatedElements: 'Elementos associados ao risco residual',
          registerButton: 'Registrar',
          noData: 'Nenhum dado encontrado.',
        },
      },
    },
    thirdPartyManagement: {
      label: 'Gestão de terceiros',
      tab: {
        name: 'Nome',
        creationDate: 'Data de criação ',
        email: 'Email',
        site: 'Site',
      },
      modal: {
        createTitle: 'Cadastrar fornecedor',
        editTitle: 'Editar fornecedor',
        name: 'Nome',
        phone: 'Celular',
        landline: 'Telefone',
        document: 'CPF/CNPJ',
        email: 'Email',
        site: 'Site',
        address: 'Endereço',

        street: 'Logradouro',
        number: 'Número',
        complement: 'Complemento',
        district: 'Bairro',
        city: 'Cidade',
        state: 'Estado',
        country: 'País',
        cep: 'CEP',
      },
    },
    menu: {
      home: 'Início',
      holdersManagement: 'Gestão de titulares',
      demandManagement: 'Gestão de demandas',
      holders: 'Titulares',
      documentManagement: 'Gestão de documentos',
      documents: 'Documentos',
      documentReview: 'Revisão documental',
      assetManagement: 'Gestão de ativos',
      people: 'Pessoas',
      businessUnit: 'Localidades',
      processes: 'Processos',
      technologies: 'Tecnologias',
      riskManagement: 'Gestão de riscos',
      thirdPartyManagement: 'Gestão de terceiros',
      config: 'Configurações',
      iventory: 'Inventário de dados',
      iventoryRecords: 'Registros de data mapping',
      collectionPurpose: 'Finalidade de coleta/tratamento',
      collectionForms: 'Formas de coleta',
      categoryOfDataCollected: 'Categoria de dados coletados',
      transparencyToTheHolder: 'Transparência ao titular ',
      collectedData: 'Dados coletados',
      legalBasisAssessment: 'Avaliação de Base Legal (Art.7)',
      processUpdateFrequency: 'Periodicidade de atualização do processo',
      InOutTransmission: 'Transmissão IN/OUT (Entrada e saída)',
      collectionMedium: 'Meio de coleta',
      storageForms: 'Formas de armazenamento',
      departments: 'Departamentos',
      companyData: 'Dados da empresa',
      personalInformation: 'Informações pessoais',
      companyUsers: 'Usuários da empresa',
      signature: 'Assinatura',
    },
    companySwitchMenu: {
      title: 'Trocar de Empresa',
    },
    profileMenu: {
      demands: 'Demandas',
    },
    APIerrors: {
      400: 'Algum erro aconteceu! Tente novamente!',
      404: 'Não encontramos o que você estava procurando!',
      401: 'Você não possui permissão para essa ação!',
      500: 'Aconteceu um erro interno do sistema. Tente novamente mais tarde!',
      1001: 'Email já existe!',
      1002: 'Hash inválido!',
      1003: 'Usuário não encontrado!',
      1004: 'Senha anterior está incorreta!',
      1005: 'Empresa não encontrada!',
      1006: 'Usuário já existe na empresa!',
      1007: 'Usuário não é um DPO!',
      1008: 'Usuário ainda não definiu sua senha!',
      1009: 'Necessário confirmar ativação de conta no email cadastrado!',
      1010: 'Email e senha não conferem!',
      1011: 'E-mail já cadastrado, esqueceu sua senha? Tente recuperá-la clicando em recuperar senha abaixo',
      1012: 'Empresa já cadastrada, favor entrar em contato com o nosso suporte para maiores informações',
      1013: 'Empresa já está ativada',
      1014: 'Usuário não localizado',
      1016: 'Data mapping não localizado',
      2015: 'Voucher já utilizado',
      2001: 'Senha inválida!',
      2002: 'Voucher inválido',
      3004: 'Tecnologia não encontrada!',
      3005: 'Estágio de contingência não existe!',
      4004: 'Localidade não existe!',
      5003: 'Titular não vinculado a empresa atual!',
      5004: 'Titular não encontrado!',
      6001: 'Email já existe!',
      6002: 'Seu perfil já possui uma empresa cadastrada com esse nome!',
      7001: 'Arquivo obrigatório não enviado!',
      7002: 'Nota não encontrada!',
      7003: 'Pasta não encontrada!',
      7004: 'Documento operacional não encontrado!',
      7005: 'Token de download inválido!',
      7006: 'Arquivo não encontrado no servidor de armazenamento!',
      7007: 'Empresa já possui pasta com este nome!',
      7008: 'Pasta para mover arquivos ao deletar não encontrada',
      9003: 'Avaliação de risco não encontrada!',
      9004: 'Risco não encontrado!',
      8004: 'Processo não encontrado!',
      1101: 'Empresa não informada!',
      1102: 'Empresa não encontrada!',
      1103: 'Documento obrigatório não informado!',
      1104: 'Chamado não existe!',
      1105: 'Documento do chamado não encontrado!',
      1106: 'Documento do chamado não encontrado no servidor de armazenamento',
      1107: 'Resposta do chamado não encontrada!',
      'title is a required field': 'Título é um campo obrigatório!',
      'primary_function is a required field':
        'Função primária é um campo obrigatório!',
      'responsible is a required field': 'Responsáveis é um campo obrigatório!',
      'email is a required field': 'Email é um campo obrigatório!',
      'name is a required field': 'Nome é um campo obrigatório!',
      'role is a required field': 'Cargo é um campo obrigatório',
      'role must be one of the following values: company, gedReviewer, collaborator, dpo, treatmentAgent':
        'Cargo deve ser um dos seguintes valores: Administrador, Revisor GED, Colaborador, DPO Externo e Agente de Tratamento',
    },
  },
};
