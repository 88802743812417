import { FiMinus, FiPlus, FiRotateCcw, FiRotateCw } from 'react-icons/fi';
import styled from 'styled-components';

export const CropperContainer = styled.div`
  /* position: relative; */
  margin: 1rem 0;
  left: 10rem;
  overflow: hidden;
  img {
    border-radius: 5px;
    max-width: 300px;
    max-height: 350px;
  }

  @media screen and (min-width: 30em) {
    img {
      max-width: 500px;
      max-height: 450px;
    }
  }

  @media screen and (min-width: 90em) {
    img {
      max-width: 900px;
      max-height: 600px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  margin: 1rem;
`;

export const RotateOptions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;
export const ZoomOptions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const Button = styled.div`
  height: 6rem;
  width: 6rem;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 1rem;
  ${({ theme }) => theme.clickable};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;

export const IconRoteteRight = styled(FiRotateCw).attrs({ size: 18 })`
  color: ${({ theme }) => theme.colors.blue};
`;

export const IconRoteteLeft = styled(FiRotateCcw).attrs({ size: 18 })`
  color: ${({ theme }) => theme.colors.blue};
`;

export const IconPlus = styled(FiPlus).attrs({ size: 18 })`
  color: ${({ theme }) => theme.colors.blue};
`;

export const IconMinus = styled(FiMinus).attrs({ size: 18 })`
  color: ${({ theme }) => theme.colors.blue};
`;
