import React, { useState } from 'react';
import { string, element, bool } from 'prop-types';

import { Container, Header, Title, Arrow, WrapperContent } from './styles';

const Accordion = ({ title, children, initialState }) => {
  const [opened, setOpened] = useState(initialState);
  return (
    <Container>
      <Header layout onClick={() => setOpened((e) => !e)}>
        <Title>{title}</Title>
        <Arrow opened={opened} />
      </Header>
      {opened && (
        <WrapperContent
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {children}
        </WrapperContent>
      )}
    </Container>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: string,
  children: element.isRequired,
  initialState: bool,
};

Accordion.defaultProps = {
  title: 'title',
  initialState: true,
};
