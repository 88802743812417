import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import translateError from '~/utils/translateError';
import api from '~/services/api';

import ProviderActions, { ProviderTypes } from '../ducks/provider';

function* getProviders({ page, limit, search }) {
  try {
    const { userActive } = yield select((state) => state.auth);

    const response = yield call(
      api.get,
      `/api/provider/select/${userActive.activeCompany.id}?limit=${limit}&page=${page}&search=${search}`
    );

    yield put(ProviderActions.getProvidersSuccess(response.data));
  } catch (error) {
    yield put(ProviderActions.getProvidersFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados dos fornecedores',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getProvidersEnum() {
  try {
    const { userActive } = yield select((state) => state.auth);

    const response = yield call(
      api.get,
      `/api/provider/select/${userActive.activeCompany.id}`
    );

    yield put(
      ProviderActions.getProvidersEnumSuccess(
        response.data.map((d) => ({ label: d.name, value: d.id }))
      )
    );
  } catch (error) {
    yield put(ProviderActions.getProvidersEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados dos fornecedores',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* detailsProvider({ id }) {
  try {
    const response = yield call(api.get, `/api/provider/read/${id}`);

    yield put(ProviderActions.detailsProviderSuccess(response.data));
  } catch (error) {
    yield put(ProviderActions.detailsProviderFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createProvider({ data, onSuccess }) {
  try {
    const response = yield call(api.post, '/api/provider', data);

    yield put(ProviderActions.createProviderSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Fornecedor criado com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ProviderActions.createProviderFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação do fornecedor',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* updateProvider({ id, data, onSuccess }) {
  try {
    const response = yield call(api.put, `/api/provider/${id}`, data);

    yield put(ProviderActions.updateProviderSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Fornecedor atualizado com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ProviderActions.updateProviderFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização do fornecedor',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* deleteProvider({ id }) {
  try {
    yield call(api.delete, `/api/provider/destroy/${id}`);

    yield put(ProviderActions.deleteProviderSuccess(id));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Fornecedor deletado!',
      })
    );
  } catch (error) {
    yield put(ProviderActions.deleteProviderFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao deletar fornecedor',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(ProviderTypes.GET_PROVIDERS_REQUEST, getProviders),
  takeLatest(ProviderTypes.GET_PROVIDERS_ENUM_REQUEST, getProvidersEnum),
  takeLatest(ProviderTypes.DETAILS_PROVIDER_REQUEST, detailsProvider),
  takeLatest(ProviderTypes.CREATE_PROVIDER_REQUEST, createProvider),
  takeLatest(ProviderTypes.UPDATE_PROVIDER_REQUEST, updateProvider),
  takeLatest(ProviderTypes.DELETE_PROVIDER_REQUEST, deleteProvider),
]);
