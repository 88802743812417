import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import TextInput from '~/components/TextInput';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import { PrimaryButton } from '~/components/Button';

import ProcessActions from '~/store/ducks/processes';
import { Container, ButtonWrapper, Button } from './styles';

const CreateNewProcess = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const { businessUnitDetails } = useSelector((state) => state.businessUnits);
  const [dataNew, setDataNew] = useState({
    title: businessUnitDetails?.title,
  });

  const reloadList = () => {
    dispatch(ProcessActions.getProcessesEnumRequest());
  };

  const submit = () => {
    const data = {
      execution: {
        localities: null,
        responsibles: [],
        departments: null,
        process_phases: null,
        process_nature: '',
      },
      graphicSchema: null,
      contingency: {
        consequences: null,
        impact: null,
        time_unit: null,
        max_time_without_operating: null,
      },

      companyId: userActive?.activeCompany?.id,
      threats: [],
      sideBar: {
        title: dataNew?.title,
      },
    };

    dispatch(ProcessActions.createProcessRequest(data, reloadList));
    onClose();
  };

  useEffect(() => {
    dispatch(ProcessActions.setSidebarMenuDataRequest(dataNew));
  }, [dataNew]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t(
        'processAsset.tabs.associatedActives.createModal.titleModal'
      )}
      noPadding
    >
      <Container>
        <TextInput
          label={i18next.t(
            'processAsset.tabs.associatedActives.createModal.titleLabel'
          )}
          margin="0 0 1.8rem"
          required
          value={dataNew.title}
          onChange={(e) => setDataNew({ ...dataNew, title: e.target.value })}
        />
        <ButtonWrapper>
          <Button>
            <PrimaryButton
              label={i18next.t('common.buttons.save')}
              onClick={() => submit()}
            />
          </Button>
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};

export default CreateNewProcess;

CreateNewProcess.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
