import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import TermsModal from '~/components/Modal';
import { SppinerIcon } from '../../pages/Configurations/Company/CompanyData/styles';
import { ButtonWrapper } from './styles';

const CancellationModal = ({
  open,
  onClose,
  title,
  handleNo,
  handleYes,
  isSubmitting,
}) => {
  return (
    <TermsModal open={open} noCloseText onClose={onClose} title={title}>
      <ButtonWrapper>
        <Grid container spacing={4} justify="flex-end">
          <Grid item xs={12} md={6}>
            <SecondaryButton
              label={i18next.t('configurations.cancellationModal.noLabel')}
              type="secondary"
              onClick={handleNo}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryButton
              type="submit"
              disabled={isSubmitting}
              label={
                isSubmitting ? (
                  <SppinerIcon />
                ) : (
                  i18next.t('configurations.cancellationModal.yesLabel')
                )
              }
              onClick={handleYes}
            />
            <SppinerIcon />
          </Grid>
        </Grid>
      </ButtonWrapper>
    </TermsModal>
  );
};

export default CancellationModal;

CancellationModal.defaultProps = {
  open: true,
  title: '',
};

CancellationModal.propTypes = {
  open: bool,
  onClose: func.isRequired,
  title: string,
  handleNo: func.isRequired,
  handleYes: func.isRequired,
  isSubmitting: bool.isRequired,
};
