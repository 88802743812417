import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import axios from 'axios';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';

import api from '~/services/api';
import DemandActions, { DemandTypes } from '../ducks/demand';

function* openNewDemand({
  data: {
    name,
    cpf,
    phone,
    email,
    subject,
    description,
    document,
    additionalFiles,
    companyId,
    termsAgreement,
    privacyAgreement,
  },
  setSucessModalOpen,
  handleFormReset,
}) {
  try {
    const formData = convertFormData({
      name,
      cpf,
      phone,
      email,
      subject,
      description,
      terms_of_use: termsAgreement,
      privacy_policy: privacyAgreement,
    });

    if (document) formData.append('documentPhoto', document);

    if (additionalFiles.length > 0) {
      additionalFiles.map((item) =>
        formData.append(item.description, item.file)
      );
    }

    const demandOpeningApi = axios.create({
      baseURL:
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
          : process.env.REACT_APP_API_PROD_ENDPOINT,
    });

    yield call(demandOpeningApi.post, '/tickets', formData, {
      headers: { companyid: companyId },
    });

    yield put(DemandActions.openNewDemandSuccess());

    setSucessModalOpen(true);
    handleFormReset();

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Demanda aberta!',
      })
    );
  } catch (error) {
    yield put(DemandActions.openNewDemandFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na abertura da demanda',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getDemandsHistory() {
  try {
    const { clientActive, clientCompanyId } = yield select(
      (state) => state.auth
    );

    const response = yield call(api.get, '/client/tickets', {
      headers: {
        Authorization: `Bearer ${clientActive?.token}`,
        companyId: clientCompanyId,
      },
    });

    yield put(DemandActions.getDemandsHistorySuccess(response.data));
  } catch (error) {
    yield put(DemandActions.getDemandsHistoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchDemandsHistory({ query }) {
  try {
    const response = yield call(api.get, `/client/tickets?search=${query}`);

    yield put(DemandActions.getDemandsHistorySuccess(response.data));
  } catch (error) {
    yield put(DemandActions.getDemandsHistoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: 'Tente novamente!',
      })
    );
  }
}

function* getDemandDetails({ protocol }) {
  try {
    const response = yield call(api.get, `/client/tickets/${protocol}`);

    yield put(DemandActions.getDemandDetailsSuccess(response.data));
    yield put(push(`/demands-history/details/${response.data.id}`));
  } catch (error) {
    yield put(DemandActions.getDemandDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getDemandInteractions({ protocol }) {
  try {
    const response = yield call(
      api.get,
      `/client/tickets/${protocol}/interactions`
    );

    yield put(DemandActions.getDemandInteractionsSuccess(response.data));
  } catch (error) {
    yield put(DemandActions.getDemandInteractionsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* closeDemand({ protocol }) {
  try {
    yield call(api.get, `/client/tickets/${protocol}/close`);

    yield put(DemandActions.closeDemandSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Demanda fechada!',
      })
    );
  } catch (error) {
    yield put(DemandActions.closeDemandFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no fechamento da demanda',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getInteractionDetails({ protocol, interactionId }) {
  try {
    const response = yield call(
      api.get,
      `/client/tickets/${protocol}/interactions/${interactionId}`
    );

    yield put(DemandActions.getInteractionDetailsSuccess(response.data));
    yield put(push(`/demands-history/interaction/${interactionId}`));
  } catch (error) {
    yield put(DemandActions.getInteractionDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createNewInteraction({ protocol, interactionDescription, file }) {
  try {
    let data = {};
    if (file) {
      data = convertFormData({
        description: interactionDescription,
      });

      data.append('attachment', file);
    } else {
      data = {
        description: interactionDescription,
      };
    }
    yield call(api.post, `/client/tickets/${protocol}/interactions`, data);

    yield put(DemandActions.createNewInteractionSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Nova interação criada!',
      })
    );
    yield put(push(`/demands-history`));
  } catch (error) {
    yield put(DemandActions.createNewInteractionFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação de nova interação',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* downloadDemandDocument({ title, documentId, demandId }) {
  try {
    const response = yield call(
      api.get,
      `/client/tickets/${demandId}/document/${documentId}/download`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, `${title}`);

    yield put(DemandActions.downloadDemandDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Download iniciado!',
      })
    );
  } catch (error) {
    yield put(DemandActions.downloadDemandDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao baixar documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* evaluateDemand({ score, comment, demandId }) {
  try {
    yield call(api.post, `/client/tickets/${demandId}/assessment`, {
      assessment_rate: score,
      assessment_comment: comment,
    });

    yield put(DemandActions.evaluateDemandSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Sua avaliação foi enviada!',
      })
    );
  } catch (error) {
    yield put(DemandActions.evaluateDemandFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na avaliação da demanda',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

export default all([
  takeLatest(DemandTypes.OPEN_NEW_DEMAND_REQUEST, openNewDemand),
  takeLatest(DemandTypes.GET_DEMANDS_HISTORY_REQUEST, getDemandsHistory),
  takeLatest(DemandTypes.SEARCH_DEMANDS_HISTORY_REQUEST, searchDemandsHistory),
  takeLatest(DemandTypes.GET_DEMAND_DETAILS_REQUEST, getDemandDetails),
  takeLatest(
    DemandTypes.GET_DEMAND_INTERACTIONS_REQUEST,
    getDemandInteractions
  ),
  takeLatest(DemandTypes.CLOSE_DEMAND_REQUEST, closeDemand),
  takeLatest(
    DemandTypes.GET_INTERACTION_DETAILS_REQUEST,
    getInteractionDetails
  ),
  takeLatest(DemandTypes.CREATE_NEW_INTERACTION_REQUEST, createNewInteraction),
  takeLatest(
    DemandTypes.DOWNLOAD_DEMAND_DOCUMENT_REQUEST,
    downloadDemandDocument
  ),
  takeLatest(DemandTypes.EVALUATE_DEMAND_REQUEST, evaluateDemand),
]);
