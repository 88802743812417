import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';
import { SecondarySelect } from '~/components/Select';
import TextInput from '~/components/TextInput';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import ConfigActions from '~/store/ducks/config';
import schema from './validationSchema';

// import CreateDepartment from '../../../../../Configurations/Departaments/components/CreateDepartamentModal';
// import CategoryModal from '../../../../../Configurations/Departaments/components/CategoryModal';

import { Container, WrapperBottom, Divider } from './styles';

function NewFolderModal({ onClose }) {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const { departaments } = useSelector((state) => state.config);
  const [departamentModal, setDepartamentModal] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [editDepartament, setEditDepartament] = useState({});

  const handleCloseDepartamentModal = () => {
    setDepartamentModal(false);
    setEditDepartament({});
  };

  const createDepartament = () => {
    setDepartamentModal(true);
  };

  function reloadFolders() {
    dispatch(DocumentActions.getFoldersRequest());
  }

  function reloadDepartaments() {
    dispatch(
      ConfigActions.getDepartamentsRequest(userActive?.activeCompany?.id)
    );
  }

  useEffect(() => {
    reloadDepartaments();
  }, []);
  const submit = (values) => {
    dispatch(
      DocumentActions.createFolderRequest(
        values?.name,
        userActive?.activeCompany?.id,
        values?.department,
        reloadFolders
      )
    );
    onClose();
  };

  return (
    <Container>
      <Formik
        initialValues={{
          name: '',
          department: '',
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <TextInput
              label={i18next.t('documents.newFolderName')}
              placeholder="Ex: documentação"
              name="name"
              value={values?.name}
              touched={touched?.name}
              error={errors?.name}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />

            <SecondarySelect
              label={i18next.t('common.fields.department')}
              placeholder="Ex.: Manutenção"
              name="department"
              // hasModal
              // hasStore
              // onStore={createDepartament}
              options={departaments.map((depart) => {
                return {
                  value: depart?.id,
                  label: depart?.name,
                };
              })}
              value={values?.department}
              touched={touched?.department}
              error={errors?.department}
              onChange={(e) => setFieldValue('department', e)}
            />
            <WrapperBottom>
              <SecondaryButton
                type="secondary"
                label={i18next.t('common.buttons.cancel')}
                padding="0"
                onClick={onClose}
              />
              <Divider />
              <PrimaryButton
                label={i18next.t('common.buttons.register')}
                padding="0"
                onClick={handleSubmit}
              />
            </WrapperBottom>
          </>
        )}
      </Formik>
      {/* <CreateDepartment
        open={!!departamentModal}
        openCateory={setCategoryModal}
        editDepartament={editDepartament}
        onClose={handleCloseDepartamentModal}
      />
      <CategoryModal
        open={categoryModal}
        onClose={() => setCategoryModal(false)}
      /> */}
    </Container>
  );
}

export default NewFolderModal;

NewFolderModal.propTypes = {
  onClose: func.isRequired,
};
