import React, { useState, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
// import PeopleActions from '~/store/ducks/people';
import ConfigActions from '~/store/ducks/config';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  DeleteIcon,
  Content,
} from './styles';

function ActionButton({ item }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { userActive } = useSelector((state) => state.auth);

  const handleClick = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const reloadTable = () => {
    dispatch(ConfigActions.getUsersCompanyRequest(0));
  };

  const handleStatusUser = () => {
    const dataToggle = {
      user_edit_id: item.user?.id,
      company_id: userActive.activeCompany.id,
    };
    dispatch(ConfigActions.updateUserStatusRequest(dataToggle, reloadTable));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container onClick={handleClick}>
      <Content>
        <MoreIcon />
      </Content>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={() => handleStatusUser()}>
          <WrapperIcon>
            <DeleteIcon />
          </WrapperIcon>
          {item.status === true ? 'Desativar' : 'Ativar'}
        </Row>
      </Popover>
    </Container>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
};
