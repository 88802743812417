import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  max-width: 82rem;
  height: 550px;
  padding: 4.5rem 3.8rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.6rem;
`;
