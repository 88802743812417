import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
`;

export const WrapperSignIn = styled(motion.div)`
  flex: 0;
  position: relative;
  overflow: hidden;
`;

export const WrapperImage = styled(motion.div)`
  flex: 1;
  display: flex;
  overflow: hidden;
  @media (max-width: 1200px) {
    flex: 1;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

export const Image = styled.img`
  flex: 1;
  object-fit: cover;
`;

export const WrapperCompanySelect = styled(motion.div)`
  flex: 3;
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 16rem;
  position: relative;
  overflow: hidden;
`;

export const WrapperSelectText = styled.div``;

export const WrapperCompanyCards = styled.div`
  display: grid;
  grid-gap: 30px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-items: center;
  padding: 30px;
`;

export const Title = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 3.5rem;
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  line-height: 3.5rem;
  color: ${({ theme }) => theme.colors.cardText};
`;

export const Subtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 3.5rem;
  color: ${({ theme }) => theme.colors.cardText};
`;
