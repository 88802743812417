/* eslint-disable no-nested-ternary */
import { FaCloudDownloadAlt } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { IconFile } from '~/assets/svgs';

export const Container = styled.div`
  width: 20.6rem;
  min-width: 20.6rem;
  height: 10.7rem;
  ${({ error }) =>
    error &&
    css`
      height: 12rem;
    `}

  position: relative;

  background: ${({ hasFile }) =>
    hasFile ? 'rgba(92, 238, 167, 0.15)' : '#fafbfb'};
  border: 2px dashed
    ${({ hasFile, error }) =>
      error ? 'red' : hasFile ? 'rgba(92, 238, 167, 1)' : '#c6ccd2'};
  box-sizing: border-box;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 2.6rem;
  cursor: pointer;

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      transform: scale(0.98);
      -webkit-box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
      box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
    `}

  :focus {
    transform: scale(0.98);
    -webkit-box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
      inset -0.7rem -0.7rem 2.6rem #ffffff;
    box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
      inset -0.7rem -0.7rem 2.6rem #ffffff;
  }

  transition: all 300ms ease;
`;

export const Icon = styled(IconFile)`
  ${({ hasFile }) =>
    hasFile &&
    css`
      path {
        fill: rgba(92, 238, 167, 1);
      }
    `}

  transition: all 300ms ease;
`;

export const Name = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  color: ${({ hasFile, theme }) =>
    hasFile ? 'rgba(92, 238, 167, 1)' : theme.colors.base[400]};
  line-height: 1.8rem;
  max-width: 11rem;
  text-align: center;
  margin-top: 1.7rem;
`;

export const DownloadWrapper = styled.a`
  padding: 0.2rem 0.4rem;
  background: white;
  border: 2px solid ${({ theme }) => theme.colors.base[300]};
  border-radius: 10px;
  top: 85%;
  left: 75%;
  cursor: pointer;
  z-index: 999;
  position: absolute;
  color: ${({ theme }) => theme.colors.blue};
  transition: all 300ms ease;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(92, 238, 167, 1);
    border-color: rgba(92, 238, 167, 1);
    color: white;
  }
`;

export const DownloadButton = styled(FaCloudDownloadAlt)`
  font-size: 20px;
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  color: red;
  line-height: 1.8rem;
  margin-top: 0.5rem;
  max-height: 0;
  ${({ error }) =>
    error &&
    css`
      max-height: 3.6rem;
    `}

  transition: 200ms ease;
`;

export const Content = styled.div`
  width: 20.6rem;
  min-width: 20.6rem;
  position: relative;
  /* background-color: red; */
  height: 10.7rem;
`;
