import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { func, objectOf } from 'prop-types';

import { Container, Dropzone, AvatarIcon, AvatarImage } from './styles';

const AttachFile = ({ avatar, setAvatar }) => {
  const baseImgUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_API_HOMOLOG_ENDPOINT;
    }
    return process.env.REACT_APP_API_PROD_ENDPOINT;
  };

  const [file, setFile] = useState(avatar ? { preview: avatar } : null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      );
      setAvatar(acceptedFiles[0]);
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container>
      <Dropzone
        {...getRootProps()}
        isDragActive={isDragActive}
        filePresent={!!file}
      >
        <input
          {...getInputProps()}
          multiple={false}
          accept="image/png, image/gif, image/jpeg"
        />
        {file ? (
          <AvatarImage src={file.preview} alt="avatar" />
        ) : (
          <AvatarIcon />
        )}
      </Dropzone>
    </Container>
  );
};

export default AttachFile;

AttachFile.propTypes = {
  avatar: objectOf.isRequired,
  setAvatar: func.isRequired,
};
