import React from 'react';
import Grid from '@material-ui/core/Grid';
import cepPromise from 'cep-promise';
import { func, objectOf } from 'prop-types';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';

import { Container, Title } from './styles';

const Location = ({ values, errors, touched, handleChange, setFieldValue }) => {
  const handleCep = async (postalCode) => {
    try {
      const res = await cepPromise(postalCode);
      setFieldValue('street', res.street);
      setFieldValue('neighborhood', res.neighborhood);
      setFieldValue('city', res.city);
      setFieldValue('state', res.state);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Title>{i18next.t('businessUnitAsset.tabs.location.label')}</Title>
      <Grid container spacing={4} style={{ marginBottom: '10rem' }}>
        <Grid item xs={4}>
          <TextInput
            label={i18next.t('businessUnitAsset.tabs.location.streetLabel')}
            placeholder="Ex: Rua das borboletas"
            name="street"
            value={values.street}
            error={errors.street}
            touched={touched.street}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            mask="9999999"
            label={i18next.t('businessUnitAsset.tabs.location.numberLabel')}
            placeholder="Ex: 33"
            name="number"
            value={values.number}
            error={errors.number}
            touched={touched.number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label={i18next.t('businessUnitAsset.tabs.location.complementLabel')}
            placeholder="Ex: Loja"
            name="complement"
            value={values.complement}
            error={errors.complement}
            touched={touched.complement}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label={i18next.t('businessUnitAsset.tabs.location.districtLabel')}
            placeholder="Ex: Village"
            name="neighborhood"
            value={values.neighborhood}
            error={errors.neighborhood}
            touched={touched.neighborhood}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label={i18next.t('businessUnitAsset.tabs.location.cityLabel')}
            placeholder="Ex: Angra dos Reis"
            name="city"
            value={values.city}
            error={errors.city}
            touched={touched.city}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <TextInput
            label={i18next.t('businessUnitAsset.tabs.location.stateLabel')}
            placeholder="Ex: Rio de Janeiro"
            name="state"
            value={values.state}
            error={errors.state}
            touched={touched.state}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={4}>
            <TextInput
              label={i18next.t('businessUnitAsset.tabs.location.cepLabel')}
              placeholder="Ex: 23999-000"
              mask="99999-999"
              name="cep"
              value={values.cep}
              error={errors.cep}
              touched={touched.cep}
              onChange={(e) => {
                handleChange(e);
                if (e.target.value.length === 9) {
                  handleCep(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Location;

Location.propTypes = {
  values: objectOf.isRequired,
  errors: objectOf.isRequired,
  touched: objectOf.isRequired,
  handleChange: func.isRequired,
  setFieldValue: func.isRequired,
};
