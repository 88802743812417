import React from 'react';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import { SecondaryButton } from '~/components/Button';
import { Container, Title, Subtitle, Description, ButtonsArea } from './styles';

const SucessCancel = ({ open, onClose }) => {
  return (
    <Modal noCloseButton open={open} noPadding color="#00bc61">
      <Container>
        <Title> {i18next.t('configurations.sucessCancel.title')}</Title>
        <Subtitle>
          {' '}
          {i18next.t('configurations.sucessCancel.subtitle')}
        </Subtitle>
        <Description>
          {i18next.t('configurations.sucessCancel.description')}
        </Description>
        <ButtonsArea>
          <SecondaryButton
            style={{ width: '45%', padding: 0, backgroundColor: 'white' }}
            label={i18next.t('configurations.sucessCancel.buttonClose')}
            onClick={onClose}
          />
        </ButtonsArea>
      </Container>
    </Modal>
  );
};

export default SucessCancel;
