import React from 'react';
import { element } from 'prop-types';
import i18next from 'i18next';
// import LanguageSelector from '~/components/LanguageSelector';

import history from '~/routes/history';
// import DateViewer from '~/components/DateViewer';
import ProfileMenu from '~/components/ProfileMenu';
// import ChatNotification from '~/components/ChatNotification';

import Info from './components/Info';

import {
  Container,
  WrapperLeft,
  WrapperRight,
  WrapperBack,
  BackIcon,
  BackText,
  WrapperHeader,
  WrapperChildren,
} from './styles';

const Details = ({ children }) => {
  return (
    <Container>
      <WrapperLeft>
        <WrapperBack onClick={() => history.goBack()}>
          <BackIcon />
          <BackText>{i18next.t('common.buttons.goBack')}</BackText>
        </WrapperBack>
        <Info />
      </WrapperLeft>
      <WrapperRight>
        <WrapperHeader>
          {/* <DateViewer /> */}
          {/* <LanguageSelector privateLayout /> */}
          {/* <ChatNotification /> */}
          <ProfileMenu />
        </WrapperHeader>
        <WrapperChildren>{children}</WrapperChildren>
      </WrapperRight>
    </Container>
  );
};

export default Details;

Details.propTypes = {
  children: element.isRequired,
};
