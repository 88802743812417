import styled from 'styled-components';

export const Container = styled.div``;

export const UserImage = styled.img`
  object-fit: cover;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;

  @media (max-width: 670px) {
    margin-right: 5px;
  }
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 20;

  :after {
    content: '';
    border-radius: 50%;
    width: 10em;
    height: 10em;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(83, 154, 218, 0.2);
    border-right: 1.1em solid rgba(83, 154, 218, 0.2);
    border-bottom: 1.1em solid rgba(83, 154, 218, 0.2);
    border-left: 1.1em solid #539ada;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
