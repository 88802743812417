import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { Formik } from 'formik';
import { any, oneOfType, objectOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TicketActions from '~/store/ducks/tickets';
// import { useSocket } from '~/hooks/socket';

import Card from '~/components/Card';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import Spinner from '~/components/Spinner';
import Permission from '~/components/Permission';
import Chat from './components/Chat';
import Files from './components/Files';
import InteractionMessages from './components/InteractionMessages';

import AttachFile from './components/AttachFile';

import schema from './validationSchema';

import {
  Container,
  Header,
  Title,
  Text,
  WrapperForm,
  WrapperCard,
  ReplyHeader,
  ReplyContainer,
  ReplyTitle,
} from './styles';

const ServiceDetails = ({ match }) => {
  const { params } = match;
  const { id } = params;
  const dispatch = useDispatch();
  const { isLoading, ticketDetails } = useSelector((state) => state.tickets);
  // const { emitAttendance } = useSocket();
  const [replyAttachment, setReplyAttachment] = useState(null);

  useEffect(() => {
    dispatch(TicketActions.getTicketDetailsRequest(id));
    dispatch(TicketActions.getTicketHistoryRequest(id));
    // emitAttendance(id);
  }, []);

  const submitReply = (values) => {
    const data = {
      id,
      message: values.description,
      file: replyAttachment,
    };
    dispatch(TicketActions.ticketReplyRequest(data));
  };

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>{`${i18next.t('callOpening.demandDetails.title')} #${
                  ticketDetails?.protocol
                }`}</Title>
              </Header>
            </Grid>
            {ticketDetails.client && (
              <Grid container spacing={5}>
                <Grid item md={8} xs={12}>
                  <WrapperForm>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <Header>
                          <Text>
                            {i18next.t(
                              'demandManagement.demandDetails.subtitle'
                            )}
                          </Text>
                        </Header>
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label={i18next.t('common.fields.name')}
                          placeholder="João Santos"
                          name="name"
                          value={ticketDetails?.client.name || ''}
                          editable={false}
                          editableui
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label={i18next.t('common.fields.cpfCnpj')}
                          placeholder="Ex.: CPF/CNPJ"
                          mask={
                            ticketDetails?.client?.cpf
                              ? ticketDetails?.client.cpf.length < 15
                                ? '999.999.999-99*'
                                : '99.999.999/9999-99'
                              : '999.999.999-99*'
                          }
                          name="cpfCnpj"
                          value={ticketDetails?.client.cpf || ''}
                          editable={false}
                          editableui
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label={i18next.t('common.fields.email')}
                          placeholder="Ex.: meuemail@email.com.br"
                          name="email"
                          value={ticketDetails?.client.email || ''}
                          editable={false}
                          editableui
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput
                          label={i18next.t('common.fields.phone')}
                          placeholder="Ex.: (21) 99999-9999"
                          mask={
                            ticketDetails?.client.phone
                              ? ticketDetails?.client.phone.length < 15
                                ? '(99) 9999-9999*'
                                : '(99) 99999-9999'
                              : '(99) 99999-9999'
                          }
                          name="phone"
                          value={ticketDetails?.client.phone || ''}
                          editable={false}
                          editableui
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          label={i18next.t('common.fields.subject')}
                          placeholder="Insira o assunto da sua mensagem"
                          name="subject"
                          value={ticketDetails?.subject || ''}
                          editable={false}
                          editableui
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          label={i18next.t('common.fields.description')}
                          placeholder="Insira sua mensagem aqui"
                          name="description"
                          value={ticketDetails?.description || ''}
                          editable={false}
                          editableui
                          textArea
                        />
                      </Grid>
                    </Grid>
                  </WrapperForm>
                </Grid>
                <Grid item md={4} xs={12}>
                  <WrapperCard>
                    <Chat />
                  </WrapperCard>
                  <WrapperCard height="25rem">
                    <Files />
                  </WrapperCard>
                </Grid>
              </Grid>
            )}

            <ReplyContainer>
              <Formik
                initialValues={{
                  description: '',
                }}
                onSubmit={submitReply}
                validationSchema={schema}
              >
                {({ values, touched, errors, handleChange, handleSubmit }) => (
                  <Card padding="4rem 3rem">
                    <Grid container xs gap={4}>
                      <Grid item xs={12} md={8} lg>
                        <Grid item xs={11}>
                          <ReplyHeader>
                            <ReplyTitle>Responder</ReplyTitle>
                          </ReplyHeader>
                        </Grid>

                        <Grid item xs={11}>
                          <TextInput
                            label="Responder ao atendente"
                            placeholder="Insira sua mensagem aqui"
                            name="description"
                            value={values.description}
                            touched={touched.description}
                            error={errors.description}
                            onChange={handleChange}
                            style={{ height: '11.7rem' }}
                            textArea
                          />
                        </Grid>
                      </Grid>

                      <Permission action="demandDocument.upload">
                        <Grid item xs={4}>
                          <AttachFile setAttachment={setReplyAttachment} />
                        </Grid>
                      </Permission>
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        style={{ marginTop: '3rem' }}
                      >
                        <PrimaryButton
                          label={i18next.t(
                            'demandManagement.demandDetails.answer'
                          )}
                          padding="0 5.8rem"
                          width="null"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                    <InteractionMessages />
                  </Card>
                )}
              </Formik>
            </ReplyContainer>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default ServiceDetails;

ServiceDetails.propTypes = {
  match: objectOf(oneOfType([any])).isRequired,
};
