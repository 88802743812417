import i18next from 'i18next';
import * as Yup from 'yup';

export default Yup.object().shape({
  first_name: Yup.string().required('Campo obrigatório'),
  last_name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .required(i18next.t('common.validation.required'))
    .test(
      'minLenght',
      i18next.t('common.validation.phone'),
      (val) => val.replace(/\D/g, '').length >= 11
    ),
});
