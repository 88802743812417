import styled from 'styled-components';

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 2.4rem;
  line-height: normal;
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  line-height: normal;
`;

export const From = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  line-height: normal;
`;

export const PostedAt = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;
  text-align: right;
`;

export const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.base[200]};
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
`;
