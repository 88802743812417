import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getPeopleRequest: ['page', 'search'],
  getPeopleSuccess: ['people'],
  getPeopleFailure: [''],

  searchPeopleRequest: ['query'],
  searchPeopleSuccess: ['result'],
  searchPeopleFailure: [''],

  getPersonDetailsRequest: ['id'],
  getPersonDetailsSuccess: ['person'],
  getPersonDetailsFailure: [''],

  createPersonRequest: ['data', 'reloadPeople'],
  createPersonSuccess: [''],
  createPersonFailure: [''],

  inviteNewDpoRequest: ['name', 'email', 'reloadPeople'],
  inviteNewDpoSuccess: [''],
  inviteNewDpoFailure: [''],

  updatePersonRequest: ['data', 'reloadTable'],
  updatePersonSuccess: [''],
  updatePersonFailure: [''],

  deletePersonRequest: ['id', 'reloadPeople'],
  deletePersonSuccess: [''],
  deletePersonFailure: [''],

  getPeopleEnumRequest: [''],
  getPeopleEnumSuccess: ['peopleEnum'],
  getPeopleEnumFailure: [''],

  downloadPeopleRequest: [''],
  downloadPeopleSuccess: [''],
  downloadPeopleFailure: [''],

  uploadPeopleRequest: ['data'],
  uploadPeopleSuccess: [''],
  uploadPeopleFailure: [''],
});

export const PeopleTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  people: {
    count: null,
    rows: [],
  },
  person: {},
  peopleEnum: [],
});

/* Reducers */

const getPeopleRequest = (state) => state.merge({ isLoading: true });
const getPeopleSuccess = (state, { people }) => {
  // console.log(people);
  return state.merge({
    isLoading: false,
    error: false,
    people,
  });
};
const getPeopleFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    people: {
      count: null,
      rows: [],
    },
  });

const searchPeopleRequest = (state) => state.merge({ isLoading: true });
const searchPeopleSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    people: result,
  });
const searchPeopleFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    people: {
      count: null,
      rows: [],
    },
  });

const getPersonDetailsRequest = (state) => state.merge({ isLoading: true });
const getPersonDetailsSuccess = (state, { person }) =>
  state.merge({
    isLoading: false,
    error: false,
    person,
  });
const getPersonDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    person: {
      id: null,
      name: null,
      email: null,
      avatar: null,
      phone: null,
      office: null,
      cpfCnpj: null,
      department: null,
      role: null,
      createdAt: null,
      updatedAt: null,
      userSystem: false
    },
  });

const createPersonRequest = (state) => state.merge({ isLoading: true });
const createPersonSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createPersonFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const inviteNewDpoRequest = (state) => state.merge({ isLoading: true });
const inviteNewDpoSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const inviteNewDpoFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updatePersonRequest = (state) => state.merge({ isLoading: true });
const updatePersonSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updatePersonFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deletePersonRequest = (state) => state.merge({ isLoading: true });
const deletePersonSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const deletePersonFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getPeopleEnumRequest = (state) => state.merge({ isLoading: true });
const getPeopleEnumSuccess = (state, { peopleEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    peopleEnum,
  });
const getPeopleEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    peopleEnum: [],
  });

const downloadPeopleRequest = (state) => state.merge({ isLoading: true });
const downloadPeopleSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadPeopleFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadPeopleRequest = (state) => state.merge({ isLoading: true });
const uploadPeopleSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadPeopleFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PEOPLE_REQUEST]: getPeopleRequest,
  [Types.GET_PEOPLE_SUCCESS]: getPeopleSuccess,
  [Types.GET_PEOPLE_FAILURE]: getPeopleFailure,

  [Types.SEARCH_PEOPLE_REQUEST]: searchPeopleRequest,
  [Types.SEARCH_PEOPLE_SUCCESS]: searchPeopleSuccess,
  [Types.SEARCH_PEOPLE_FAILURE]: searchPeopleFailure,

  [Types.GET_PERSON_DETAILS_REQUEST]: getPersonDetailsRequest,
  [Types.GET_PERSON_DETAILS_SUCCESS]: getPersonDetailsSuccess,
  [Types.GET_PERSON_DETAILS_FAILURE]: getPersonDetailsFailure,

  [Types.CREATE_PERSON_REQUEST]: createPersonRequest,
  [Types.CREATE_PERSON_SUCCESS]: createPersonSuccess,
  [Types.CREATE_PERSON_FAILURE]: createPersonFailure,

  [Types.INVITE_NEW_DPO_REQUEST]: inviteNewDpoRequest,
  [Types.INVITE_NEW_DPO_SUCCESS]: inviteNewDpoSuccess,
  [Types.INVITE_NEW_DPO_FAILURE]: inviteNewDpoFailure,

  [Types.UPDATE_PERSON_REQUEST]: updatePersonRequest,
  [Types.UPDATE_PERSON_SUCCESS]: updatePersonSuccess,
  [Types.UPDATE_PERSON_FAILURE]: updatePersonFailure,

  [Types.DELETE_PERSON_REQUEST]: deletePersonRequest,
  [Types.DELETE_PERSON_SUCCESS]: deletePersonSuccess,
  [Types.DELETE_PERSON_FAILURE]: deletePersonFailure,

  [Types.GET_PEOPLE_ENUM_REQUEST]: getPeopleEnumRequest,
  [Types.GET_PEOPLE_ENUM_SUCCESS]: getPeopleEnumSuccess,
  [Types.GET_PEOPLE_ENUM_FAILURE]: getPeopleEnumFailure,

  [Types.DOWNLOAD_PEOPLE_REQUEST]: downloadPeopleRequest,
  [Types.DOWNLOAD_PEOPLE_SUCCESS]: downloadPeopleSuccess,
  [Types.DOWNLOAD_PEOPLE_FAILURE]: downloadPeopleFailure,

  [Types.UPLOAD_PEOPLE_REQUEST]: uploadPeopleRequest,
  [Types.UPLOAD_PEOPLE_SUCCESS]: uploadPeopleSuccess,
  [Types.UPLOAD_PEOPLE_FAILURE]: uploadPeopleFailure,
});
