import styled from 'styled-components';

export const ForgotPasswordText = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.blue};

  margin-top: 4rem;
`;

export const ForgotPasswordInstruction = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2rem;

  margin-bottom: 4.5rem;
  margin-top: 1rem;
`;
