import styled from 'styled-components';
import { IconSearch } from '~/assets/svgs';

export const Container = styled.div`
  background-color: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 0.5rem;
  box-shadow: ${({ variant }) =>
    variant === 'secondary' ? '' : 'rgba(149, 157, 165, 0.2) 0px 8px 24px'};
  border: ${({ variant }) =>
    variant === 'secondary' ? '1px solid #EBEBEB' : ''};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5rem;
`;

export const SearchIcon = styled(IconSearch)`
  fill: #606474;
  margin: 0 7%;
`;

export const Input = styled.input.attrs({ type: 'text' })`
  flex: 1;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;

  ::placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: 1.4rem;
  }
`;
