import * as Yup from 'yup';

import { isValid as isValidCpf } from '@fnando/cpf';

export default Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF inválido', (cpf) => isValidCpf(cpf)),
  document: Yup.mixed().required('Arquivo obrigatório'),
  phone: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  subject: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
  captcha: Yup.bool().oneOf([true], 'Captcha obrigatório'),
});
