import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';

export const Container = styled.div`
  width: 527px;
  padding: 4.5rem 6.5rem;
  display: grid;

  @media (max-width: 700px) {
    width: auto;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 28.2px;
  text-align: center;
  margin: 15px;
  padding: 10px 0;
`;

export const Description = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
`;

export const ButtonsArea = styled.div`
  height: 100%;
  justify-content: center;
  display: flex;
  margin-top: 10%;
`;

export const FailureIcon = styled(FaTimesCircle).attrs({
  size: '10rem',
})`
  color: #ff4b55;
  cursor: pointer;
`;

export const IconArea = styled.div`
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: grid;
`;
