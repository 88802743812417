import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  border-radius: 10px;
  height: ${({ height }) => height};
  overflow: auto;
  display: block;
  padding: ${({ padding }) => padding};
  max-width: 90vw;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  /* :hover {
    transform: scale(1.01);
    box-shadow: 0 0 30px #00000004;
  } */

  transition: 200ms ease;
`;
