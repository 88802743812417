import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RiCloseCircleLine } from 'react-icons/ri';
import { motion } from 'framer-motion';

export const Container = styled(PerfectScrollbar)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background-color: #0a1d3b66;
  position: relative;
  opacity: ${({ open }) => (open ? 1 : 0)};

  transition: 300ms ease;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 2rem;
  position: relative;
  max-width: 90vw;
`;

export const MotionCard = styled(motion.div).attrs({ layout: true })`
  background-color: #fff;
  border-radius: 2rem;
  position: relative;
  max-width: 90vw;
`;

export const Header = styled.div`
  margin: 4.5rem 3.8rem 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.4rem;
  font-weight: 700;

  ${(props) =>
    props.position === 'space-between'
      ? `justify-content: space-between;`
      : `justify-content: flex-end;`}

  @media (max-width: 720px) {
    padding: 2.5rem 2rem 0;
  }
`;

export const CloseButton = styled.div`
  height: 5rem;
  background-color: #f8f8f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  padding: 0 2.6rem;

  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 400;

  :hover {
    background-color: #f7f7f7;
    transform: scale(1.02);
  }

  transition: all 200ms ease;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const CloseIcon = styled(RiCloseCircleLine).attrs({
  size: '2rem',
})`
  color: #2e3a59;
  cursor: pointer;
`;

export const BigCloseIcon = styled(RiCloseCircleLine).attrs({
  size: '3rem',
})`
  color: #2e3a59;
  cursor: pointer;
  @media (min-width: 720px) {
    display: none;
  }
`;

export const WrapperContent = styled.div`
  margin-top: 30px;
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 0 3.8rem 3.8rem;
      @media (max-width: 720px) {
        padding: 0 2rem 2rem;
      }
    `};
`;

export const ButtonsArea = styled.div`
  width: 100%;
  padding: 0px 40px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 30px;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`;
