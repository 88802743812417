import styled from 'styled-components';
import { FaTimes, FaEquals } from 'react-icons/fa';

export const Form = styled.form`
  width: 80vw;
  max-width: 82rem;
  padding: 4.5rem 0;
`;

export const BottomRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const WrapperInput = styled.div`
  flex: 1;
  height: 9rem;
`;

export const WrapperMath = styled.div`
  height: 3.3rem;
  width: 3.3rem;
  background: #539ada66;
  margin: 0 1.7rem;
  margin-bottom: 1.1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MultiplyIcon = styled(FaTimes).attrs({
  color: '#539ada',
  size: 18,
})``;

export const EqualsIcon = styled(FaEquals).attrs({
  color: '#539ada',
  size: 18,
})``;

export const Label = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  line-height: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const WrapperResult = styled.div`
  background-color: #ececec;
  width: 100%;
  height: 6rem;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: #bebebf;
  border-radius: 1rem;
  padding: 0 1.5rem;
`;

export const RiskMitigationTitle = styled.h2`
  margin-top: 3rem;
`;
