import React from 'react';

import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { func, object, oneOfType } from 'prop-types';

import { byteToSize } from '~/utils/formatters';

import {
  WrapperFile,
  Row,
  FileName,
  FileSize,
  FileDate,
  Column,
  DeleteButton,
  DeleteIcon,
} from './styles';

const File = ({ file, onDelete }) => {
  return (
    <div>
      <Grid item xs={12}>
        <WrapperFile>
          <Column>
            <Row>
              <FileName>
                {file.name.length > 48
                  ? `${file.name.slice(0, 24)}...${file.name.slice(
                      file.name.length - 24
                    )}`
                  : file.name}
              </FileName>
              <FileSize>{byteToSize(file.size)}</FileSize>
            </Row>
            <FileDate>{format(new Date(), 'dd/MM/yyyy')}</FileDate>
          </Column>
          <DeleteButton onClick={onDelete}>
            <DeleteIcon />
          </DeleteButton>
        </WrapperFile>
      </Grid>
    </div>
  );
};

export default File;

File.propTypes = {
  file: oneOfType([object, null]),
  onDelete: func,
};

File.defaultProps = {
  file: null,
  onDelete: null,
};
