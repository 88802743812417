import { createStore, applyMiddleware, compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import { seamlessImmutableReconciler, seamlessImmutableTransformCreator } from 'redux-persist-seamless-immutable';

import Reactotron from '~/config/ReactotronConfig';
import history from '~/routes/history';
import rootReducer from '~/store/ducks';
import rootSaga from '~/store/sagas';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
  stateReconciler: seamlessImmutableReconciler,
  transforms: [
    seamlessImmutableTransformCreator({
      whitelistPerReducer: {
        auth: [
          'authChecked',
          'userSignedIn',
          'clientSignedIn',
          'userActive',
          'clientActive',
          'companyId',
          'clientCompanyId',
        ],
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: Reactotron.createSagaMonitor(),
});

const middlewares = [sagaMiddleware, routerMiddleware(history)];

const store = createStore(
  persistedReducer,
  compose(Reactotron.createEnhancer(), applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
