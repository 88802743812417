import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { func, oneOfType, any, arrayOf } from 'prop-types';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import { byteToSize } from '~/utils/formatters';
import { TertiaryButton } from '~/components/Button';

import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  DeleteButton,
  DeleteIcon,
  AttachIcon,
  WrapperAddButton,
  Row,
  FileName,
  FileSize,
  FileDate,
} from './styles';

const FileItem = ({ file, onChange }) => {
  const inputRef = useRef(null);
  return file ? (
    <>
      <Row>
        <FileName>{file.name}</FileName>
        <FileSize>{file.size ? byteToSize(file.size) : ''}</FileSize>
      </Row>
      <FileDate>
        {format(
          file?.createdAt ? new Date(file.createdAt) : new Date(),
          'dd/MM/yyyy'
        )}
      </FileDate>
    </>
  ) : (
    <>
      <TertiaryButton
        label={i18next.t('common.buttons.attach')}
        leftIcon={<AttachIcon />}
        style={{ height: '6rem' }}
        onClick={() => inputRef.current.click()}
      />
      <input
        type="file"
        style={{ display: 'none' }}
        ref={inputRef}
        onChange={(e) => onChange(e.target.files[0])}
        multiple={false}
      />
    </>
  );
};

FileItem.propTypes = {
  file: oneOfType([any]).isRequired,
  onChange: func.isRequired,
};

function Documentation({ documents, setDocuments, setDeletedDocuments }) {
  const newActive = () => {
    setDocuments((e) => [...e, { description: '', file: null }]);
  };

  const deleteActive = (index) => {
    const newArray = [...documents];
    const removedId = newArray[index]?.id;
    if (removedId) {
      setDeletedDocuments((state) => [...state, removedId]);
    }
    newArray.splice(index, 1);
    setDocuments([...newArray]);
  };

  const updateDescription = (index, updatedDescription) => {
    const newArray = [...documents];
    newArray[index] = { ...newArray[index], description: updatedDescription };
    setDocuments([...newArray]);
  };

  const updateFile = (index, updatedFile) => {
    const newArray = documents;
    newArray[index] = { ...newArray[index], file: updatedFile };
    setDocuments([...newArray]);
  };

  const fileNameShow = (index) => {
    const name = documents[index].file.split('/');
    const teste = name[name.length - 1].split('_');
    return teste[teste.length - 1];
  };

  return (
    <Container>
      <Title>{i18next.t('businessUnitAsset.tabs.evidences.title')}</Title>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t('businessUnitAsset.tabs.evidences.descriptionLabel')}
          </Label>
        </Grid>
        <Grid item xs={7} />
        {documents.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                onChange={(e) => updateDescription(index, e.target.value)}
                value={item.description}
                placeholder="Insira uma descrição para o Anexo"
              />
            </Grid>
            <Grid item xs={item.file ? 4 : 2}>
              <FileItem
                file={
                  item?.id && !item.url
                    ? {
                        name: fileNameShow(index),
                        size: documents[index].file_size,
                        createdAt: documents[index].createdAt,
                      }
                    : item.url
                    ? {
                        name: item.url.split('_')[
                          item.url.split('_').length - 1
                        ],
                      }
                    : documents[index].file
                }
                onChange={(e) => updateFile(index, e)}
              />
            </Grid>
            <Grid item xs={documents[index].file ? 3 : 5}>
              <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        <TertiaryButton
          onClick={newActive}
          label={i18next.t('common.buttons.add')}
          leftIcon={<IconPlus />}
        />
      </WrapperAddButton>
    </Container>
  );
}

export default Documentation;

Documentation.propTypes = {
  documents: arrayOf.isRequired,
  setDocuments: func.isRequired,
  setDeletedDocuments: func.isRequired,
};
