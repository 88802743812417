import styled from 'styled-components';
import { CircleCheck } from '~/assets/svgs/index';

export const Container = styled.div`
  margin-top: 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: space-between;
  height: 1rem;
  margin-bottom: 2rem;
`;

export const ReplyHeader = styled.div`
  margin-bottom: 2rem;
`;

export const ReplyContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
`;

export const ReplyTitle = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 600;
  line-height: 4.1rem;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 500;
`;

export const Subtitle = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 600;

  margin-bottom: 2rem;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
`;

export const WrapperSelect = styled.div`
  width: 16rem;
  height: 5rem;
  margin-right: 1.6rem;
  display: flex;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperForm = styled.div`
  display: flex;
  padding: 21px 32px 100px 32px;
  background: #ffffff;
  border-radius: 10px;
`;

export const WrapperCard = styled.div`
  height: ${({ height }) => height || '30rem'};
  margin-top: 2rem;
`;

export const CheckIcon = styled(CircleCheck).attrs(({ theme }) => ({
  color: theme.colors.base[400],
  size: 24,
}))`
  align-self: center;
`;

export const DeadlinePlaceholderWrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(198, 204, 210, 0.3);
  background: white;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  border-radius: 8px;
`;

export const DeadlinePlaceholder = styled.span`
  font-size: 1.4rem;
  color: #606474;
  font-weight: 400;
  line-height: 2.1rem;
`;
