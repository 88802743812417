import React, { useEffect } from 'react';
import history from '~/routes/history';
import i18next from 'i18next';
import { PrimaryButton } from '~/components/Button';
import UserHeader from '~/components/UserHeader';

import {
  Title,
  Subtitle,
  Content,
  Container,
  CheckImage,
  Modal,
  ButtonsArea,
} from './styles';

const Step3 = () => {
  useEffect(() => {
    window.onpopstate = () => {
      history.go(1);
    };
  }, []);

  const navigatePage = () => {
    history.push({
      pathname: '/panel',
    });
  };

  return (
    <Container>
      <UserHeader />
      <Content>
        <Modal>
          <CheckImage />
          <Title>{i18next.t('configurations.checkoutStep3.title')}</Title>
          <Subtitle>
            {i18next.t('configurations.checkoutStep3.subtitle')}
          </Subtitle>
          <ButtonsArea>
            <PrimaryButton
              style={{
                width: 25,
              }}
              type="submit"
              label={i18next.t('common.buttons.return')}
              onClick={navigatePage}
            />
          </ButtonsArea>
        </Modal>
      </Content>
    </Container>
  );
};

export default Step3;
