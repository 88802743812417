import React, { useEffect, useState, useRef } from 'react';

import i18next from 'i18next';

import { useHistory } from 'react-router-dom';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
// import ProcessActions from '~/store/ducks/processes';
import { TertiaryButton } from '~/components/Button';
import SearchInput from '~/components/TextInput/Search';
import Spinner from '~/components/Spinner';
// import UploadButtom from '~/components/UploadButtom';

import Table from './components/Table';

import {
  Container,
  Header,
  Title,
  IconPlus,
  TitleWrapper,
  ActionsWrapper,
  SearchWrapper,
  // HeaderRight,
  // DownloadButton,
  // DownloadIcon,
  // DownloadText,
} from './styles';

const BusinessUnit = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { isLoading } = useSelector((state) => state.processes);
  const searchInputRef = useRef(null);
  // const [csvData, setCsvData] = useState([]);
  const [loading, setLoadin] = useState(true);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  // useEffect(() => {
  //   if (csvData.length > 0) {
  //     validationDataBusinesProccess(csvData);
  //   }
  // }, [csvData]);

  // function handleDownload() {
  //   dispatch(ProcessActions.downloadProcessesRequest());
  // }

  if (isLoading && loading) {
    setLoadin(false);
    return <Spinner loading={isLoading} />;
  }

  // const validationDataBusinesProccess = (data) => {
  //   const businessUnitsProccess = data.filter((item) => {
  //     if (item.processo && item.processo.length > 4) {
  //       return {
  //         processo: item.processo,
  //         descricao: item.descricao,
  //       };
  //     }
  //   });
  //   // console.log(businessUnitsProccess);
  //   dispatch(ProcessActions.uploadProcessesRequest(businessUnitsProccess));
  // };

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('processAsset.title')}</Title>
          <TertiaryButton
            onClick={() =>
              history.push('/assets-management/business-process/new')
            }
            label={i18next.t('common.buttons.new')}
            leftIcon={<IconPlus />}
          />
        </TitleWrapper>
        <ActionsWrapper>
          <SearchWrapper>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('common.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
          </SearchWrapper>
          {/* <HeaderRight>
            <DownloadButton onClick={handleDownload}>
              <DownloadIcon />
              <DownloadText>{i18next.t('common.buttons.export')}</DownloadText>
            </DownloadButton>
            <UploadButtom setCsvData={setCsvData} />
          </HeaderRight> */}
        </ActionsWrapper>
      </Header>
      <Table search={searchValue} />
    </Container>
  );
};

export default BusinessUnit;
