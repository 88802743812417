import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import Card from '~/components/Card';

import GridTag from '../GridTag';

import {
  Container,
  WrapperHorizontalLabel,
  HorizontalLabel,
  WrapperVerticalLabel,
  VerticalLabel,
  WrapperContent,
  Table,
  TR,
  TH,
  TD,
} from './styles';

function ImpactGrid() {
  const [data, setData] = useState([]);
  const { risksMatrix } = useSelector((state) => state.risks);

  useEffect(() => {
    if (risksMatrix.length > 0) {
      const rows = [
        i18next.t('risks.board.probability.veryHigh'),
        i18next.t('risks.board.probability.high'),
        i18next.t('risks.board.probability.medium'),
        i18next.t('risks.board.probability.low'),
        i18next.t('risks.board.probability.veryLow'),
      ];
      const tempRisks = [];
      rows.forEach((row, index) => {
        tempRisks.push([row, ...risksMatrix[index]]);
      });
      setData(tempRisks);
    }
  }, [risksMatrix]);

  return (
    <Card padding="1.4rem 1.1rem">
      <Container>
        <WrapperVerticalLabel>
          <VerticalLabel>
            {i18next.t('risks.board.probability.title')}
          </VerticalLabel>
        </WrapperVerticalLabel>
        <WrapperContent>
          <Table style={{ width: '100%' }}>
            <thead>
              <TR>
                <TH />
                <TH colSpan={5}>
                  <WrapperHorizontalLabel>
                    <HorizontalLabel>
                      {i18next.t('risks.board.impact.title')}
                    </HorizontalLabel>
                  </WrapperHorizontalLabel>
                </TH>
              </TR>
            </thead>
            <tbody>
              <TR>
                <TD />
                <TD>{i18next.t('risks.board.impact.negligible')}</TD>
                <TD>{i18next.t('risks.board.impact.little')}</TD>
                <TD>{i18next.t('risks.board.impact.medium')}</TD>
                <TD>{i18next.t('risks.board.impact.bigger')}</TD>
                <TD>{i18next.t('risks.board.impact.extreme')}</TD>
              </TR>
              {data.map((row, rowIndex) => (
                <TR key={row[0]}>
                  {row.map((item, itemIndex) =>
                    itemIndex === 0 ? (
                      <TD align="left" key={item}>
                        {item}
                      </TD>
                    ) : (
                      <TD key={item}>
                        <GridTag rowIndex={rowIndex} itemIndex={itemIndex}>
                          {item}
                        </GridTag>
                      </TD>
                    )
                  )}
                </TR>
              ))}
            </tbody>
          </Table>
        </WrapperContent>
      </Container>
    </Card>
  );
}

export default ImpactGrid;
