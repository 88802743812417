import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import { any, func, number, oneOfType } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SecondarySelect } from '~/components/Select';
import ProvidersActions from '~/store/ducks/provider';
import { Container, NumberBox } from './styles';

export function ThirdPartieCard({ position, value, setFieldValue }) {
  const dispatch = useDispatch();
  const { providersEnum } = useSelector((state) => state.provider);

  useEffect(() => {
    dispatch(ProvidersActions.getProvidersEnumRequest());
  }, []);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={1}>
          <NumberBox>
            {position + 1 < 10 ? `0${position + 1}` : position + 1}
          </NumberBox>
        </Grid>
        <Grid item md={3}>
          <SecondarySelect
            options={[...providersEnum]}
            value={value.provider_id}
            onChange={(e) =>
              setFieldValue(
                `processIdentification.providers[${position}].provider_id`,
                e
              )
            }
            label={i18next.t(
              'iventory.tab.processDetails.thirdParties.partner'
            )}
            sm
          />
        </Grid>
        <Grid item md={3}>
          <SecondarySelect
            options={[
              { label: 'Opção 1', value: '1' },
              { label: 'Opção 2', value: '2' },
            ]}
            value={value.share_type}
            onChange={(e) =>
              setFieldValue(
                `processIdentification.providers[${position}].share_type`,
                e
              )
            }
            label={i18next.t(
              'iventory.tab.processDetails.thirdParties.shareType'
            )}
            sm
          />
        </Grid>
      </Grid>
    </Container>
  );
}

ThirdPartieCard.propTypes = {
  position: number,
  setFieldValue: func,
  value: oneOfType([any]),
};

ThirdPartieCard.defaultProps = {
  position: 0,
  setFieldValue: null,
  value: {},
};
