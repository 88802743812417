import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';

export const Container = styled.button`
  height: 11rem;
  width: 90%;
  border: 1px solid rgba(198, 204, 210, 0.3);
  border-radius: 10px;
  margin: 0.7rem 0;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  background: none;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.base[400]};
  }
`;

export const Content = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  margin-right: 1.5rem;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 400;
  margin-right: 1.8rem;
`;

export const State = styled.div`
  background-color: ${({ color }) => color.background};
  height: 3rem;
  padding: 0 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ color }) => color.color};
  border-radius: 1rem;
  flex-grow: 1;
`;

export const Arrow = styled(FiArrowRight).attrs(({ theme }) => ({
  color: theme.colors.base[400],
  size: 20,
}))`
  margin: 0 1rem;
`;
