import React from 'react';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import Spinner from '~/components/Spinner';

import Form from './Form';

import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  Title,
  FormWrapper,
} from './styles';

const SignUpClient = () => {
  const { isLoading } = useSelector((state) => state.auth);

  return (
    <>
      <Spinner loading={isLoading} />
      <Container>
        <BG />
        <Logo />
        <Content>
          <TitleWrapper>
            <Title>{i18next.t('common.signUp.title1')}</Title>
            <Title>{i18next.t('common.signUp.title2')}</Title>
            <Title>{i18next.t('common.signUp.title3')}</Title>
          </TitleWrapper>

          <FormWrapper>
            <Form />
          </FormWrapper>
        </Content>
      </Container>
    </>
  );
};

export default SignUpClient;
