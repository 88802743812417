import React, { useState, useEffect, useRef } from 'react';

import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TertiaryButton } from '~/components/Button';
import SearchInput from '~/components/TextInput/Search';
import TechnologyActions from '~/store/ducks/technologies';
import Spinner from '~/components/Spinner';

// import UploadButtom from '~/components/UploadButtom';
import Table from './components/Table';

import {
  Container,
  Header,
  Title,
  IconPlus,
  TitleWrapper,
  ActionsWrapper,
  SearchWrapper,
  HeaderRight,
  // DownloadButton,
  // DownloadIcon,
  // DownloadText,
} from './styles';

const BusinessUnit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const { isLoading } = useSelector((state) => state.technologies);
  const searchInputRef = useRef(null);
  // const [csvData, setCsvData] = useState([]);
  const [loading, setLoadin] = useState(true);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  // useEffect(() => {
  //   if (csvData.length > 0) {
  //     validationDataBusinesTech(csvData);
  //   }
  // }, [csvData]);

  // function handleDownload() {
  //   dispatch(TechnologyActions.downloadTechnologiesRequest());
  // }

  if (isLoading && loading) {
    setLoadin(false);
    return <Spinner loading={isLoading} />;
  }

  // const validationDataBusinesTech = (data) => {
  //   const businesTech = data.filter((item) => {
  //     if (item.tecnologia && item.tecnologia.length > 4) {
  //       return {
  //         tecnologia: item.tecnologia,
  //         descricao: item.descricao,
  //       };
  //     }
  //   });
  //   dispatch(TechnologyActions.uploadTechnologiesRequest(businesTech));
  // };

  const buttonAdd = () => {
    const alter = false;
    history.push('/assets-management/actives/new');
    dispatch(TechnologyActions.setSidebarMenuSubmitRequest(alter));
  };

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('technologiesAsset.title')}</Title>
          <TertiaryButton
            onClick={buttonAdd}
            label={i18next.t('common.buttons.new')}
            leftIcon={<IconPlus />}
          />
        </TitleWrapper>
        <ActionsWrapper>
          <SearchWrapper>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('common.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
          </SearchWrapper>
          <HeaderRight>
            {/* <DownloadButton onClick={handleDownload}>
              <DownloadIcon />
              <DownloadText>{i18next.t('common.buttons.export')}</DownloadText>
            </DownloadButton> */}
            {/* <UploadButtom setCsvData={setCsvData} /> */}
          </HeaderRight>
        </ActionsWrapper>
      </Header>
      <Table search={searchValue} />
    </Container>
  );
};

export default BusinessUnit;
