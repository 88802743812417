import React from 'react';
import { string, func, bool } from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import HoldersActions from '~/store/ducks/holders';

import Modal from '~/components/Modal';
import TextArea from '~/components/TextArea';
import { PrimaryButton, SecondaryButton } from '~/components/Button';

import schema from './validationSchema';

import { Container, WrapperButton } from './styles';

function ReopenModal({ id, onClose, open, setOpenModal }) {
  const dispatch = useDispatch();

  const submit = (values) => {
    dispatch(HoldersActions.reOpenTicketRequest(id, values.justification));
  };

  return (
    <Modal
      title="Justificativa da reabertura da demanda"
      noPadding
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          justification: '',
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleSubmit, handleChange }) => (
          <Container>
            <TextArea
              label="Justifique"
              name="justification"
              value={values.justification}
              touched={touched.justification}
              error={errors.justification}
              onChange={handleChange}
            />

            <WrapperButton>
              <SecondaryButton
                label={i18next.t('common.buttons.cancel')}
                padding="0 5.8rem"
                width="null"
                onClick={() => setOpenModal(false)}
              />
              <PrimaryButton
                label="Reabrir"
                padding="0 5.8rem"
                width="null"
                onClick={handleSubmit}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default ReopenModal;

ReopenModal.propTypes = {
  id: string.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  setOpenModal: func.isRequired,
};
