import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  max-width: 82rem;
  padding: 4.5rem 3.8rem;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8.1rem;
`;
