import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  useDispatch,
  // useDispatch,
  useSelector,
} from 'react-redux';
import { func, bool, objectOf } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import { PhonInput } from '~/components/PhoneInput';
import ProvidersActions from '~/store/ducks/provider';
import schema from './validationSchema';
import { Container, Title, WrapperButton } from './styles';

function CreateModal({ open, onClose, editOption }) {
  const dispatch = useDispatch();
  const { isLoading, provider } = useSelector((state) => state.provider);
  const { userActive } = useSelector((state) => state.auth);

  useEffect(() => {
    if (editOption?.id) {
      dispatch(ProvidersActions.detailsProviderRequest(editOption.id));
    } else {
      dispatch(ProvidersActions.clearDetailsProviderRequest());
    }
  }, [editOption]);

  const onSuccess = () => {
    onClose();
  };

  const submit = (values) => {
    const data = {
      ...values,
      provider: {
        ...values.provider,
        company_id: userActive.activeCompany.id,
      },
    };

    if (editOption?.id) {
      dispatch(
        ProvidersActions.updateProviderRequest(editOption.id, data, onSuccess)
      );
    } else {
      dispatch(ProvidersActions.createProviderRequest(data, onSuccess));
    }
  };

  return (
    <Modal
      title={
        editOption?.id
          ? i18next.t('thirdPartyManagement.modal.editTitle')
          : i18next.t('thirdPartyManagement.modal.createTitle')
      }
      noPadding
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          provider: {
            name: provider?.name,
            landline: provider?.landline,
            email: provider?.email,
            cell_phone: provider?.cell_phone,
            cpf_cnpj: provider?.cpf_cnpj,
            site: provider?.site,
          },
          adresses: {
            street: provider?.provider_adresses
              ? provider?.provider_adresses[0].street
              : '',
            number: provider?.provider_adresses
              ? provider?.provider_adresses[0].number
              : '',
            neighborhood_sector: provider?.provider_adresses
              ? provider?.provider_adresses[0].neighborhood_sector
              : '',
            complement: provider?.provider_adresses
              ? provider?.provider_adresses[0].complement
              : '',
            city: provider?.provider_adresses
              ? provider?.provider_adresses[0].city
              : '',
            state: provider?.provider_adresses
              ? provider?.provider_adresses[0].state
              : '',
            country: provider?.provider_adresses
              ? provider?.provider_adresses[0].country
              : '',
            zip_code: provider?.provider_adresses
              ? provider?.provider_adresses[0].zip_code
              : '',
          },
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.name')}
                  placeholder={i18next.t('common.typeIt')}
                  name="provider.name"
                  value={values.provider?.name}
                  touched={touched.provider}
                  error={errors.provider?.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhonInput
                  label={i18next.t('thirdPartyManagement.modal.landline')}
                  sm
                  name="provider.landline"
                  touched={touched.provider}
                  error={errors.provider?.landline}
                  value={values.provider.landline}
                  onChange={(e) => setFieldValue('provider.landline', e)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.email')}
                  placeholder={i18next.t('thirdPartyManagement.modal.email')}
                  name="provider.email"
                  value={values.provider.email}
                  error={errors.provider?.email}
                  touched={touched.provider}
                  onChange={handleChange}
                  editable
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <PhonInput
                  label={i18next.t('thirdPartyManagement.modal.phone')}
                  sm
                  name="provider.cell_phone"
                  touched={touched.provider}
                  error={errors.provider?.cell_phone}
                  value={values.provider.cell_phone}
                  onChange={(e) => setFieldValue('provider.cell_phone', e)}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.document')}
                  placeholder={i18next.t('thirdPartyManagement.modal.document')}
                  mask={
                    values.cpf_cnpj?.length <= 14
                      ? '999.999.999-999'
                      : '99.999.999/9999-99'
                  }
                  name="provider.cpf_cnpj"
                  value={values.provider.cpf_cnpj}
                  error={errors.provider?.cpf_cnpj}
                  touched={touched.provider}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.site')}
                  placeholder={i18next.t('thirdPartyManagement.modal.site')}
                  name="provider.site"
                  value={values.provider.site}
                  error={errors.provider?.site}
                  touched={touched.provider}
                  onChange={handleChange}
                  editable
                />
              </Grid>
            </Grid>

            <Title>{i18next.t('thirdPartyManagement.modal.address')}</Title>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.street')}
                  placeholder={i18next.t('thirdPartyManagement.modal.street')}
                  name="adresses.street"
                  value={values.adresses.street}
                  error={errors.adresses?.street}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.number')}
                  placeholder={i18next.t('thirdPartyManagement.modal.number')}
                  name="adresses.number"
                  type="number"
                  value={values.adresses.number}
                  error={errors.adresses?.number}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.district')}
                  placeholder={i18next.t('thirdPartyManagement.modal.district')}
                  name="adresses.neighborhood_sector"
                  value={values.adresses.neighborhood_sector}
                  error={errors.adresses?.neighborhood_sector}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.complement')}
                  placeholder={i18next.t(
                    'thirdPartyManagement.modal.complement'
                  )}
                  name="adresses.complement"
                  type="complement"
                  value={values.adresses.complement}
                  error={errors.adresses?.complement}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.city')}
                  placeholder={i18next.t('thirdPartyManagement.modal.city')}
                  name="adresses.city"
                  value={values.adresses.city}
                  error={errors.adresses?.city}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.state')}
                  placeholder={i18next.t('thirdPartyManagement.modal.state')}
                  name="adresses.state"
                  value={values.adresses.state}
                  error={errors.adresses?.state}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.country')}
                  placeholder={i18next.t('thirdPartyManagement.modal.country')}
                  name="adresses.country"
                  value={values.adresses.country}
                  error={errors.adresses?.country}
                  touched={touched.adresses}
                  onChange={handleChange}
                  editable
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label={i18next.t('thirdPartyManagement.modal.cep')}
                  placeholder={i18next.t('thirdPartyManagement.modal.cep')}
                  name="adresses.zip_code"
                  value={values.adresses.zip_code}
                  error={errors.adresses?.zip_code}
                  touched={touched.adresses}
                  mask="99999-999"
                  onChange={handleChange}
                  editable
                />
              </Grid>
            </Grid>

            <WrapperButton>
              <PrimaryButton
                label={
                  isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
                }
                padding="0 5.8rem"
                width="null"
                onClick={handleSubmit}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateModal;

CreateModal.propTypes = {
  editOption: objectOf.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
};
