import React from 'react';
import { string, element } from 'prop-types';
import { Container } from './styles';

const Card = ({ padding, height, children }) => {
  return (
    <Container padding={padding} height={height}>
      <div
        style={{ width: '100%', marginTop: 20 }}
      >
        {children}
      </div>
    </Container>
  );
};

export default Card;

Card.propTypes = {
  padding: string,
  height: string,
  children: element.isRequired,
};

Card.defaultProps = {
  padding: '2rem 2.5rem',
  height: '100%',
};
