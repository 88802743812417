import { FaMinusSquare } from 'react-icons/fa';
import styled from 'styled-components';

export const Item = styled.div`
  border: 0.5px solid #d5d5d5;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.base[100]};
`;

export const Title = styled.span`
  border-left: 0.5px solid #d5d5d5;
  padding: 0.1rem 0.5rem;
  font-weight: 400;
  font-size: 12px;
`;

export const Minus = styled(FaMinusSquare).attrs({
  size: 15,
  color: 'red',
})``;
