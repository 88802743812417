export const naturalOptions = [
  {
    label: 'Avalanche/deslizamento de Terra',
    value: 'Avalanche/deslizamento de Terra',
  },
  {
    label: 'Inundação (interno)',
    value: 'Inundacao (interno)',
  },
  {
    label: 'Enchente ou Alagamento (externo)',
    value: 'Enchente ou Alagamento (externo)',
  },
  {
    label: 'Incêndio',
    value: 'Incendio',
  },
  {
    label: 'Terremoto',
    value: 'Terremoto',
  },
  {
    label: 'Raio',
    value: 'Raio',
  },
  {
    label: 'Vendaval',
    value: 'Vendaval',
  },
  {
    label: 'Tempestade',
    value: 'Tempestade',
  },
  {
    label: 'Chuva de Granizo',
    value: 'Chuva de Granizo',
  },
];

export const humanOptions = [
  {
    label: 'Acesso de pessoas não autorizadas',
    value: 'Acesso de pessoas nao autorizadas',
  },
  {
    label: 'Ameaça de bomba',
    value: 'Ameaca de bomba',
  },
  {
    label: 'Ataque terrorista',
    value: 'Ataque terrorista',
  },
  {
    label: 'Atos vandalismo',
    value: 'Atos vandalismo',
  },
  {
    label: 'Dano intencional aos sistemas/dados',
    value: 'Dano intencional aos sistemas/dados',
  },
  {
    label: 'Dano não intencional aos sistemas/dados',
    value: 'Dano nao intencional aos sistemas/dados',
  },
  {
    label: 'Desvio fraudulento de recursos',
    value: 'Desvio fraudulento de recursos',
  },
  {
    label: 'Distúrbio civil',
    value: 'Disturbio civil',
  },
  {
    label: 'Greve em prestador de serviço',
    value: 'Greve em prestador de servico',
  },
  {
    label: 'Greve interna',
    value: 'Greve interna',
  },
  {
    label: 'Paralisação de transporte',
    value: 'Paralisacao de transporte',
  },
  {
    label: 'Proximidade à zona de alta criminalidade',
    value: 'Proximidade a zona de alta criminalidade',
  },
  {
    label: 'Proximidade de presídio/delegacia',
    value: 'Proximidade de presidio/delegacia',
  },
  {
    label: 'Queda de balão nas instalações (fogo)',
    value: 'Queda de balao nas instalacoes (fogo)',
  },
  {
    label: 'Roubo e/ou furto de patrimônio',
    value: 'Roubo e/ou furto de patrimonio',
  },
  {
    label: 'Sabotagem (dados)',
    value: 'Sabotagem (dados)',
  },
  {
    label: 'Sabotagem (instalações)',
    value: 'Sabotagem (instalacoes)',
  },
  {
    label: 'Sequestro de dados',
    value: 'Sequestro de dados',
  },
  {
    label: 'Sequestro de elemento chave ao Processo',
    value: 'Sequestro de elemento chave ao Processo',
  },
  {
    label: 'Vírus de computador (intencional)',
    value: 'Virus de computador (intencional)',
  },
  {
    label: 'Vírus de computador (não intencional)',
    value: 'Virus de computador (nao intencional)',
  },
];

export const techOptions = [
  {
    label: 'Falha de sistema de climatização',
    value: 'Falha de sistema de climatizacao',
  },
  {
    label: 'Falha de Software Aplicativo',
    value: 'Falha de Software Aplicativo',
  },
  {
    label: 'Falha de software Operacional',
    value: 'Falha de software Operacional',
  },
  {
    label: 'Falha em correio eletrônico',
    value: 'Falha em correio eletronico',
  },
  {
    label: 'Falha em hardware (servidor)',
    value: 'Falha em hardware (servidor)',
  },
  {
    label: 'Falha em rede LAN',
    value: 'Falha em rede LAN',
  },
  {
    label: 'Falha em rede WAN',
    value: 'Falha em rede WAN',
  },
  {
    label: 'Falha em telecomunicações - Dados',
    value: 'Falha em telecomunicacoes - Dados',
  },
  {
    label: 'Falha em telecomunicações – Voz -Imagem',
    value: 'Falha em telecomunicacoes – Voz - Imagem',
  },
  {
    label: 'Falha em workstation (hardware)',
    value: 'Falha em workstation (hardware)',
  },
  {
    label: 'Falha na entrada de dados',
    value: 'Falha na entrada de dados',
  },
];

export const physicOptions = [
  {
    label: 'Acidente aéreo',
    value: 'Acidente aereo',
  },
  {
    label: 'Acidente Ferroviário',
    value: 'Acidente Ferroviario',
  },
  {
    label: 'Acidente Marítimo',
    value: 'Acidente Maritimo',
  },
  {
    label: 'Acidente Nuclear',
    value: 'Acidente Nuclear',
  },
  {
    label: 'Acidente Químico',
    value: 'Acidente Quimico',
  },
  {
    label: 'Acidente Rodoviário',
    value: 'Acidente Rodoviario',
  },
  {
    label: 'Black-out',
    value: 'Blackout',
  },
  {
    label: 'Explosão',
    value: 'Explosao',
  },
  {
    label: 'Falha em instalação elétrica interna (quadros, disjuntores,...)',
    value: 'Falha em instalacao eletrica interna (quadros, disjuntores,...)',
  },
  {
    label: 'Problema estrutural (de construção)',
    value: 'Problema estrutural (de construcao)',
  },
  {
    label: 'Proximidade de instalação de armazenamento de combustíveis',
    value: 'Proximidade de instalacao de armazenamento de combustiveis',
  },
  {
    label: 'Proximidade de instalação de eletricidade',
    value: 'Proximidade de instalacao de eletricidade',
  },
  {
    label: 'Proximidade de instalação de gás',
    value: 'Proximidade de instalacao de gas',
  },
  {
    label: 'Proximidade de instalação militar',
    value: 'Proximidade de instalacao militar',
  },
  {
    label: 'Rompimento de tubulação interna (água, gás, vapor)',
    value: 'Rompimento de tubulacao interna (agua, gas, vapor)',
  },
  {
    label: 'Vazamento de gás',
    value: 'Vazamento de gas',
  },
];

export const politicalEconomicsOptions = [
  {
    label: 'Crise de Energia Elétrica',
    value: 'Crise de Energia Eletrica',
  },
  {
    label: 'Crise Regulatória',
    value: 'Crise Regulatoria',
  },
];
