import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { func, objectOf } from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Accordion from '~/components/Accordion';
import AddMore from '~/components/Button/AddButton';
import ProcessCard from '~/components/ProcessCard';
import { Container, Divider } from './styles';

const Steps = ({ executionPlan, setExecutionPlan }) => {
  const addNew = () => {
    const newProcess = [...executionPlan.processes, { description: '' }];
    setExecutionPlan({ ...executionPlan, processes: newProcess });
  };

  const updateProcess = (index, updated) => {
    const newProcesses = executionPlan.processes;
    newProcesses[index] = { ...newProcesses[index], ...updated };
    setExecutionPlan({ ...executionPlan, processes: newProcesses });
  };

  const moveCardHandler = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const dragItem = executionPlan.processes[source.index];
    const newProcesses = [...executionPlan.processes];
    newProcesses.splice(source.index, 1);
    newProcesses.splice(destination.index, 0, dragItem);
    setExecutionPlan((oldState) => ({ ...oldState, processes: newProcesses }));
  };

  const removeItem = (planIndex) => {
    executionPlan.processes.splice(planIndex, 1);
    setExecutionPlan({ ...executionPlan });
  };

  return (
    <Accordion
      title={i18next.t('processAsset.tabs.executionPlan.processSteps.title')}
    >
      <Divider />
      <DragDropContext onDragEnd={moveCardHandler}>
        <Droppable droppableId="Etapas do processo">
          {(provided) => (
            <Container
              ref={provided.innerRef}
              {...provided.droppableProps}
              container
              spacing={4}
              style={{ marginBottom: '10rem' }}
            >
              {executionPlan.processes.map((process, index) => (
                <ProcessCard
                  index={index}
                  onRemove={removeItem}
                  process={process}
                  updateProcess={updateProcess}
                />
              ))}
              {provided.placeholder}
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                <AddMore onClick={addNew} />
              </Grid>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </Accordion>
  );
};

export default Steps;

Steps.propTypes = {
  executionPlan: objectOf.isRequired,
  setExecutionPlan: func.isRequired,
};
