import Cookie from 'js-cookie';
import CryptoJS from 'crypto-js';

export const getAuth = () => {
  const email = Cookie.get('email') || '';
  const passwordStringfy = Cookie.get('password');

  const password = passwordStringfy
    ? CryptoJS.enc.Base64.parse(passwordStringfy).toString(CryptoJS.enc.Utf8)
    : '';

  return { email, password };
};

export const rememberAuth = (email, password) => {
  Cookie.set('email', email, { expires: 999 });

  const passwordStringfy = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(password)
  );
  
  Cookie.set('password', passwordStringfy, { expires: 9999 });
};

export const clearAuth = () => {
  Cookie.remove('email');
  Cookie.remove('password');
};
