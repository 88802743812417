import { func, number, objectOf } from 'prop-types';
import React from 'react';
import { boolean } from 'yup';
import PageNavigator from '~/components/PageNavigator';
import Spinner from '~/components/Spinner';
import CompanyCard from '../../../CompanyCard';

import {
  ButtonTitle,
  Content,
  NewCardButton,
  PlusIcon,
  TotalItems,
  WrapperNavigator,
  AreaCard,
} from './styles';

export default function MyCompanies({
  onNewCompany,
  isLoading,
  companies,
  currentPage,
  setCurrentPageTab,
  addNew,
}) {
  const handlePagination = (newPage) => {
    setCurrentPageTab(newPage);
  };
  return (
    <AreaCard>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Content>
          {currentPage === 1 && addNew && (
            <NewCardButton onClick={onNewCompany}>
              <PlusIcon />
              <ButtonTitle>Nova empresa</ButtonTitle>
            </NewCardButton>
          )}
          {companies?.data?.rows.map((item) => (
            <CompanyCard key={item.company.id} company={item.company} />
          ))}
        </Content>
      )}
      <WrapperNavigator>
        <TotalItems>Total: {companies?.data?.count}</TotalItems>
        <PageNavigator
          count={companies?.data?.total_pages}
          onChangePage={(newPage) => handlePagination(newPage)}
          setCurrentPage={(newPage) => handlePagination(newPage)}
          currentPage={currentPage}
          handlePagination={handlePagination}
        />
      </WrapperNavigator>
    </AreaCard>
  );
}

MyCompanies.propTypes = {
  onNewCompany: func.isRequired,
  isLoading: boolean.isRequired,
  companies: objectOf.isRequired,
  currentPage: number.isRequired,
  setCurrentPageTab: func.isRequired,
  addNew: boolean,
};

MyCompanies.defaultProps = {
  addNew: false,
};
