import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func, string } from 'prop-types';
import { Grid } from '@material-ui/core';

import i18next from 'i18next';

import Modal from '~/components/Modal';

import { PrimaryButton } from '~/components/Button';
import DocumentActions from '~/store/ducks/documents';
import AttachFile from '../../../FolderDetails/components/components/AttachFile';

import { Container, WrapperTop, ErrorMessage, Title } from './styles';

const UpdateDocument = ({ open, onClose, id }) => {
  const dispatch = useDispatch();
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(false);

  const submit = () => {
    if (document) {
      dispatch(DocumentActions.uploadDocumentRequest(document, id));

      dispatch(DocumentActions.getDocumentDetailsRequest(id));

      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <Modal open={open} onClose={onClose} noTitle noPadding>
      <Container>
        <WrapperTop>
          <Title> {i18next.t('documents.updateDocumentModal.title')}</Title>
        </WrapperTop>
        {error && (
          <ErrorMessage>
            {i18next.t('documents.newDocumentModal.error')}
          </ErrorMessage>
        )}
        <AttachFile setDocument={setDocument} setError={setError} />

        <Grid container spacing={3} justify="center">
          <Grid item xs={12} md={6} lg={4}>
            <PrimaryButton
              label={i18next.t('common.buttons.save')}
              type="submit"
              onClick={submit}
            />
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default UpdateDocument;

UpdateDocument.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  id: string.isRequired,
};
