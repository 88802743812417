import React from 'react';
import VerificationInput from 'react-verification-input';
import './styles.css';
import { func, string } from 'prop-types';

function ValidationCodeInput({ setFieldValue, value, name, error }) {
  return (
    <VerificationInput
      placeholder=""
      length={4}
      removeDefaultStyles
      characters={{
        className: 'characters',
      }}
      character={{
        className: error ? 'character-error' : 'character',
        classNameInactive: 'character--inactive',
        classNameSelected: 'character--selected',
      }}
      input={{
        onChange: (e) => setFieldValue(name, e.toUpperCase()),
        value,
        name,
      }}
    />
  );
}

export default ValidationCodeInput;

ValidationCodeInput.propTypes = {
  setFieldValue: func,
  value: string,
  name: string,
  error: string,
};

ValidationCodeInput.defaultProps = {
  setFieldValue: () => {},
  value: null,
  name: null,
  error: null,
};
