export default function convertProbability(probabilityValue) {
  switch (probabilityValue) {
    case 'very_low':
      return 'Muito Baixa';
    case 'low':
      return 'Baixa';
    case 'medium':
      return 'Médio';
    case 'high':
      return 'Elevado';
    case 'very_high':
      return 'Muito Alta';
    default:
      return '';
  }
}
