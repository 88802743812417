import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getAllTicketsRequest: ['page'],
  getAllTicketsSuccess: ['tickets'],
  getAllTicketsFailure: [''],

  searchTicketsRequest: ['query'],
  searchTicketsSuccess: ['result'],
  searchTicketsFailure: [''],

  getTicketDetailsRequest: ['id'],
  getTicketDetailsSuccess: ['ticket'],
  getTicketDetailsFailure: [''],

  getTicketHistoryRequest: ['id'],
  getTicketHistorySuccess: ['ticketHistory'],
  getTicketHistoryFailure: [''],

  ticketReplyRequest: ['data'],
  ticketReplySuccess: [''],
  ticketReplyFailure: [''],

  downloadTicketsRequest: [''],
  downloadTicketsSuccess: [''],
  downloadTicketsFailure: [''],

  uploadTicketDocumentRequest: ['file', 'ticketId'],
  uploadTicketDocumentSuccess: [''],
  uploadTicketDocumentFailure: [''],

  downloadTicketDocumentRequest: ['documentName', 'documentId', 'ticketId'],
  downloadTicketDocumentSuccess: [''],
  downloadTicketDocumentFailure: [''],

  uploadChatDocumentRequest: ['file', 'fileType', 'ticketId'],
  uploadChatDocumentSuccess: [''],
  uploadChatDocumentFailure: [''],

  downloadChatDocumentRequest: ['fileName', 'messageId', 'ticketId'],
  downloadChatDocumentSuccess: [''],
  downloadChatDocumentFailure: [''],
});

export const TicketTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  tickets: {
    count: null,
    rows: [],
  },
  ticketDetails: {
    id: null,
    subject: null,
    description: null,
    priority: null,
    due_date: null,
    status: null,
    attachment: null,
    createdAt: null,
    updatedAt: null,
    client_id: null,
    responsible_id: null,
    client: {
      id: null,
      name: null,
      cpf: null,
      email: null,
      phone: null,
      createdAt: null,
      updatedAt: null,
      company_id: null,
    },
    replies: [],
    documents: [],
  },
  ticketHistory: {},
});

/* Reducers */

const getAllTicketsRequest = (state) => state.merge({ isLoading: true });
const getAllTicketsSuccess = (state, { tickets }) =>
  state.merge({
    isLoading: false,
    error: false,
    tickets,
  });
const getAllTicketsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    tickets: {
      count: null,
      rows: [],
    },
  });

const searchTicketsRequest = (state) => state.merge({ isLoading: true });
const searchTicketsSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    tickets: result,
  });
const searchTicketsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    tickets: {
      count: null,
      rows: [],
    },
  });

const getTicketDetailsRequest = (state) => state.merge({ isLoading: true });
const getTicketDetailsSuccess = (state, { ticket }) =>
  state.merge({
    isLoading: false,
    error: false,
    ticketDetails: ticket,
  });
const getTicketDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    ticketDetails: {
      id: null,
      subject: null,
      description: null,
      priority: null,
      due_date: null,
      status: null,
      attachment: null,
      createdAt: null,
      updatedAt: null,
      client_id: null,
      responsible_id: null,
      client: {
        id: null,
        name: null,
        cpf: null,
        email: null,
        phone: null,
        createdAt: null,
        updatedAt: null,
        company_id: null,
      },
      replies: [],
      documents: [],
    },
  });

const getTicketHistoryRequest = (state) => state.merge({ isLoading: true });
const getTicketHistorySuccess = (state, { ticketHistory }) =>
  state.merge({
    isLoading: false,
    error: false,
    ticketHistory,
  });
const getTicketHistoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    ticketHistory: {},
  });

const ticketReplyRequest = (state) => state.merge({ isLoading: true });
const ticketReplySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const ticketReplyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadTicketsRequest = (state) => state.merge({ isLoading: true });
const downloadTicketsSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadTicketsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadTicketDocumentRequest = (state) => state.merge({ isLoading: true });
const uploadTicketDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadTicketDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadTicketDocumentRequest = (state) =>
  state.merge({ isLoading: true });
const downloadTicketDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadTicketDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadChatDocumentRequest = (state) => state.merge({ isLoading: true });
const uploadChatDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadChatDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadChatDocumentRequest = (state) => state.merge({ isLoading: true });
const downloadChatDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadChatDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_ALL_TICKETS_REQUEST]: getAllTicketsRequest,
  [Types.GET_ALL_TICKETS_SUCCESS]: getAllTicketsSuccess,
  [Types.GET_ALL_TICKETS_FAILURE]: getAllTicketsFailure,

  [Types.SEARCH_TICKETS_REQUEST]: searchTicketsRequest,
  [Types.SEARCH_TICKETS_SUCCESS]: searchTicketsSuccess,
  [Types.SEARCH_TICKETS_FAILURE]: searchTicketsFailure,

  [Types.GET_TICKET_DETAILS_REQUEST]: getTicketDetailsRequest,
  [Types.GET_TICKET_DETAILS_SUCCESS]: getTicketDetailsSuccess,
  [Types.GET_TICKET_DETAILS_FAILURE]: getTicketDetailsFailure,

  [Types.GET_TICKET_HISTORY_REQUEST]: getTicketHistoryRequest,
  [Types.GET_TICKET_HISTORY_SUCCESS]: getTicketHistorySuccess,
  [Types.GET_TICKET_HISTORY_FAILURE]: getTicketHistoryFailure,

  [Types.TICKET_REPLY_REQUEST]: ticketReplyRequest,
  [Types.TICKET_REPLY_SUCCESS]: ticketReplySuccess,
  [Types.TICKET_REPLY_FAILURE]: ticketReplyFailure,

  [Types.DOWNLOAD_TICKETS_REQUEST]: downloadTicketsRequest,
  [Types.DOWNLOAD_TICKETS_SUCCESS]: downloadTicketsSuccess,
  [Types.DOWNLOAD_TICKETS_FAILURE]: downloadTicketsFailure,

  [Types.UPLOAD_TICKET_DOCUMENT_REQUEST]: uploadTicketDocumentRequest,
  [Types.UPLOAD_TICKET_DOCUMENT_SUCCESS]: uploadTicketDocumentSuccess,
  [Types.UPLOAD_TICKET_DOCUMENT_FAILURE]: uploadTicketDocumentFailure,

  [Types.DOWNLOAD_TICKET_DOCUMENT_REQUEST]: downloadTicketDocumentRequest,
  [Types.DOWNLOAD_TICKET_DOCUMENT_SUCCESS]: downloadTicketDocumentSuccess,
  [Types.DOWNLOAD_TICKET_DOCUMENT_FAILURE]: downloadTicketDocumentFailure,

  [Types.UPLOAD_CHAT_DOCUMENT_REQUEST]: uploadChatDocumentRequest,
  [Types.UPLOAD_CHAT_DOCUMENT_SUCCESS]: uploadChatDocumentSuccess,
  [Types.UPLOAD_CHAT_DOCUMENT_FAILURE]: uploadChatDocumentFailure,

  [Types.DOWNLOAD_CHAT_DOCUMENT_REQUEST]: downloadChatDocumentRequest,
  [Types.DOWNLOAD_CHAT_DOCUMENT_SUCCESS]: downloadChatDocumentSuccess,
  [Types.DOWNLOAD_CHAT_DOCUMENT_FAILURE]: downloadChatDocumentFailure,
});
