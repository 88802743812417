import React from 'react';
import { number, func } from 'prop-types';
import { usePagination } from '@material-ui/lab/Pagination';

import {
  Container,
  WrapperArrow,
  IconBack,
  IconForward,
  WrapperButtons,
  Page,
  PageText,
  ActiveWrapper,
} from './styles';

export default function PageNavigator({
  count,
  onChangePage,
  currentPage,
  setCurrentPage,
  handlePagination,
}) {
  const { items } = usePagination({
    count,
    onChange: (e, newPage) => {
      onChangePage(newPage);
    },
  });

  const setNextPage = () => {
    if (currentPage < count) {
      handlePagination(currentPage);
      setCurrentPage(currentPage + 1);
    }
  };

  const setPreviousPage = () => {
    if (currentPage > 1) {
      handlePagination(currentPage - 2);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Container>
      {items
        .filter((item) => item.type === 'previous')
        .map(({ page, type, selected, ...item }) => (
          <WrapperArrow
            {...item}
            onClick={setPreviousPage}
            disabled={currentPage <= 1}
            key={page}
          >
            <IconBack />
          </WrapperArrow>
        ))}

      <WrapperButtons>
        {items
          .filter(
            ({ type }) =>
              type === 'start-ellipsis' ||
              type === 'end-ellipsis' ||
              type === 'page'
          )
          .map(({ page, selected, type, ...item }) => {
            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
              return <PageText key={page}>...</PageText>;
            }
            return (
              <Page {...item} key={page}>
                {currentPage === page && (
                  <ActiveWrapper
                    layoutId="pagination"
                    initial={false}
                    transition={{
                      type: 'spring',
                      stiffness: 500,
                      damping: 30,
                    }}
                  />
                )}
                <PageText>{page}</PageText>
              </Page>
            );
          })}
      </WrapperButtons>
      {items
        .filter((item) => item.type === 'next')
        .map(({ page, type, selected, ...item }) => (
          <WrapperArrow
            {...item}
            onClick={setNextPage}
            disabled={currentPage >= count}
            key={page}
          >
            <IconForward />
          </WrapperArrow>
        ))}
    </Container>
  );
}

PageNavigator.propTypes = {
  count: number.isRequired,
  onChangePage: func.isRequired,
  setCurrentPage: func.isRequired,
  currentPage: number.isRequired,
  handlePagination: func.isRequired,
};
