import React from 'react';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import {
  Container,
  Title,
  Subtitle,
  Description,
  ButtonsArea,
  SuccessIcon,
  IconArea,
} from './styles';

const SuccessCancel = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} noPadding color="#fffff">
      <Container>
        <IconArea>
          <SuccessIcon />
        </IconArea>
        <Title> {i18next.t('configurations.successPay.title')} </Title>
        <Description>
          {i18next.t('configurations.successPay.description')}
        </Description>
      </Container>
    </Modal>
  );
};

export default SuccessCancel;
