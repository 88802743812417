import React from 'react';
import { Grid } from '@material-ui/core';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { SecondaryButton, PrimaryButton } from '~/components/Button';

import { Container, WrapperTop, Description, ButtonsArea } from './styles';

const Warning = ({ open, onClose, cancelButton }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t('configurations.cancelModal.title')}
      noPadding
    >
      <Container>
        <Description>
          {i18next.t('configurations.cancelModal.description')}
        </Description>
        <ButtonsArea>
          <SecondaryButton
            style={{ width: '45%', padding: 0 }}
            label={i18next.t('configurations.cancelModal.buttonReturn')}
            onClick={onClose}
          />
          <PrimaryButton
            style={{ width: '45%', padding: 0 }}
            label={i18next.t('configurations.cancelModal.buttonCancel')}
            onClick={cancelButton}
          />
        </ButtonsArea>
      </Container>
    </Modal>
  );
};

export default Warning;
