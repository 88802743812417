import React, { useState } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { func, string } from 'prop-types';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';

import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import StepAnimated from '~/components/StepAnimated';

import schema from './validationSchema';

import { PasswordWrapper, HidePasswordIcon, ShowPasswordIcon } from './styles';

function Step3({ onSubmit, hash }) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const submit = (values) => {
    dispatch(AuthActions.passwordResetRequest(values.password, hash, onSubmit));
  };

  return (
    <StepAnimated>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <PasswordWrapper>
              <TextInput
                placeholder={i18next.t('forgotPassword.step3.passwordLabel')}
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                touched={touched.password}
                error={errors.password}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 3.5rem 0"
              />
              {showPassword ? (
                <HidePasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </PasswordWrapper>

            <PasswordWrapper>
              <TextInput
                placeholder={i18next.t(
                  'forgotPassword.step3.passwordConfirmationLabel'
                )}
                name="passwordConfirmation"
                type={showPasswordConfirmation ? 'text' : 'password'}
                value={values.passwordConfirmation}
                touched={touched.passwordConfirmation}
                error={errors.passwordConfirmation}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 3.5rem 0"
              />
              {showPasswordConfirmation ? (
                <HidePasswordIcon
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                />
              )}
            </PasswordWrapper>

            <PrimaryButton
              label={i18next.t('forgotPassword.step3.button')}
              margin="0 0 1.6rem"
              onClick={handleSubmit}
              type="submit"
            />
          </>
        )}
      </Formik>
    </StepAnimated>
  );
}

Step3.propTypes = {
  onSubmit: func.isRequired,
  hash: string.isRequired,
};

export default Step3;
