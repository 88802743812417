import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import React from 'react';
import TextArea from '~/components/TextArea';
import { HasComponent } from './HasComponent';
import { Container, Title } from './styles';

const Controls = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    values,
    // errors, touched, handleChange, handleBlur,
    setFieldValue,
  } = useFormikContext();

  return (
    <Container>
      <Title>
        {i18next.t('iventory.tab.controls.informationSecurity.label')}
      </Title>
      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveAccessControl'
            )}
            value={values.controls.accessControl}
            onChange={(e) => setFieldValue('controls.accessControl', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveAuthentication'
            )}
            value={values.controls.authentication}
            onChange={(e) => setFieldValue('controls.authentication', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveEncryption'
            )}
            value={values.controls.encryption}
            onChange={(e) => setFieldValue('controls.encryption', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveMasking'
            )}
            value={values.controls.masking}
            onChange={(e) => setFieldValue('controls.masking', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveAnonymization'
            )}
            value={values.controls.anonymization}
            onChange={(e) => setFieldValue('controls.anonymization', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveBackup'
            )}
            value={values.controls.backup}
            onChange={(e) => setFieldValue('controls.backup', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.haveDLP'
            )}
            value={values.controls.dlp}
            onChange={(e) => setFieldValue('controls.dlp', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.classificationOfData'
            )}
            value={values.controls.classificationOfData}
            onChange={(e) => setFieldValue('controls.classificationOfData', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12}>
          <HasComponent
            label={i18next.t(
              'iventory.tab.controls.informationSecurity.hasAntivirusProtection'
            )}
            value={values.controls.antivirusProtection}
            onChange={(e) => setFieldValue('controls.antivirusProtection', e)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextArea
            sm
            label="Observação"
            placeholder="Descrição aqui"
            name="controls.description"
            value={values.controls?.description}
            onChange={(e) =>
              setFieldValue('controls.description', e.target.value)
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Controls;
