import styled from 'styled-components';
import { IconBuilding as Building, IconDPO as DPO } from '~/assets/svgs/';

export const WrapperContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin: 0 5%;
  height: 100%;
  max-width: 600px;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const WrapperText = styled.div`
  margin: 1rem;
`;

export const WrapperButtons = styled.div`
  width: 100%;

  button:first-child {
    margin-bottom: 4rem;
  }
`;

export const Button = styled.button`
  border: none;
  width: 100%;
  height: 16rem;
  background: #f7f7f7;
  border-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin: 3.5rem 0; */
  padding: 2.8rem;

  cursor: pointer;

  :hover {
    transform: scale(1.02);
  }

  :focus {
    transform: scale(1.02);
  }

  transition: all 200ms ease;

  @media (max-width: 450px) {
    flex-direction: column;
    height: auto;
    align-items: center;
  }
`;

export const WrapperIcon = styled.div`
  background-color: #fff;
  border-radius: 1rem;
  width: 10.4rem;
  height: 10.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3.2rem;

  transition: all 200ms ease;

  @media (max-width: 450px) {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const IconBuilding = styled(Building)`
  width: 7.5rem;
  height: 7.5rem;
`;

export const IconDPO = styled(DPO)`
  width: 7.5rem;
  height: 7.5rem;
`;

export const WrapperButtonText = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 450px) {
    align-items: center;
  }
`;

export const Footer = styled.p`
  margin: 1rem 0;
  width: 100%;
  text-align: center;

  button {
    border: none;
    background: transparent;
    width: auto;
    font-size: 1.3rem;
    font-weight: 400;
    color: #262a3a;
    text-decoration: underline;
  }
`;
