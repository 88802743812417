import React from 'react';
import { bool, func, string } from 'prop-types';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';

import { SecondaryButton, PrimaryButton } from '~/components/Button';
import Modal from '~/components/Modal';
import TextArea from '~/components/TextArea';

import schema from './validationSchema';

import { Container, Footer } from './styles';

function RevisionModal({ id, open, onClose }) {
  const dispatch = useDispatch();
  const submit = (values) => {
    dispatch(
      DocumentActions.askForDocumentRevisionRequest(id, values?.description)
    );
    onClose();
  };

  return (
    <Formik
      initialValues={{ description: '' }}
      onSubmit={submit}
      validationSchema={schema}
    >
      {({ values, touched, errors, handleChange, handleSubmit }) => (
        <Modal
          open={open}
          onClose={onClose}
          title="Solicitação de revisão"
          noPadding
        >
          <Container>
            <TextArea
              label="Descrição da revisão"
              name="description"
              value={values.description}
              touched={touched.description}
              error={errors.description}
              onChange={handleChange}
            />
            <Footer>
              <SecondaryButton
                width="16.6rem"
                padding="0"
                label={i18next.t('common.buttons.cancel')}
                margin="0 1.5rem"
                onClick={onClose}
              />
              <PrimaryButton
                width="16.6rem"
                padding="0"
                label="Enviar"
                margin="0"
                onClick={handleSubmit}
              />
            </Footer>
          </Container>
        </Modal>
      )}
    </Formik>
  );
}

export default RevisionModal;

RevisionModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  // id: string.isRequired,
};
