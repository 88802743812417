import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.3rem;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-right: 1.5rem;
`;

export const NoData = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: #322e39;
`;
