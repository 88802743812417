import React, { cloneElement } from 'react';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { string, func, oneOf, bool, element } from 'prop-types';
import i18next from 'i18next';

const DateInput = ({
  formatMask,
  value,
  onChange,
  children,
  readOnly,
  type = 'dateTime',
  name,
  shouldHaveMinDate,
}) => {
  const DateComp =
    type === 'date'
      ? DatePicker
      : type === 'dateTime'
      ? DateTimePicker
      : TimePicker;
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <DateComp
        clearable={false}
        value={value}
        onChange={onChange}
        name={name}
        disabled={readOnly}
        format={formatMask}
        cancellabel={i18next.t('common.buttons.cancel')}
        okLabel="Confirmar"
        minDate={shouldHaveMinDate ? new Date() : ''}
        TextFieldComponent={(e) =>
          cloneElement(children, {
            value: String(e.value),
            onClick: e.onClick,
            editable: !e.InputProps?.readOnly,
            editableui: true,
          })
        }
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateInput;

DateInput.propTypes = {
  formatMask: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  readOnly: bool,
  type: oneOf(['date', 'dateTime', 'time']),
  children: element.isRequired,
  name: string,
  shouldHaveMinDate: bool,
};

DateInput.defaultProps = {
  readOnly: false,
  type: 'date',
  name: null,
  shouldHaveMinDate: false,
};
