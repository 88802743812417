import React from 'react';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import { SecondaryButton, PrimaryButton } from '~/components/Button';
import { Container, Title, Subtitle, Description, ButtonsArea } from './styles';

const SucessCancel = ({ open, onClose }) => {
  return (
    <Modal
      noCloseButton
      open={open}
      noPadding
      color="#FF4B55
    "
    >
      <Container>
        <Title> {i18next.t('configurations.failureCancel.title')}</Title>
        <Subtitle>
          {' '}
          {i18next.t('configurations.failureCancel.subtitle')}
        </Subtitle>
        <Description>
          {i18next.t('configurations.failureCancel.description')}
        </Description>
        <ButtonsArea>
          <PrimaryButton
            style={{
              width: '45%',
              padding: 0,
              backgroundColor: 'transparent',
              border: '1px solid white',
            }}
            label={i18next.t('configurations.failureCancel.buttonSupport')}
            onClick={onClose}
          />

          <SecondaryButton
            style={{ width: '45%', padding: 0, backgroundColor: 'white' }}
            label={i18next.t('configurations.failureCancel.buttonTry')}
            onClick={onClose}
          />
        </ButtonsArea>
      </Container>
    </Modal>
  );
};

export default SucessCancel;
