import React, { useState, useRef } from 'react';
import i18next from 'i18next';
import Modal from '~/components/Modal';

import Tabs from '~/components/Tabs';
import { TertiaryButton } from '~/components/Button';
// import Spinner from '~/components/Spinner';
import SearchInput from '~/components/TextInput/Search';
import NewFolderModal from './components/NewFolderModal';

import GlobalFolders from './components/Tabs/GlobalFolders';
import CompanyFolders from './components/Tabs/CompanyFolders';

import {
  Container,
  Header,
  TitleWrapper,
  SearchWrapper,
  Title,
  Content,
  IconPlus,
} from './styles';

const Folders = () => {
  const [newFolderModal, setNewFolderModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  return (
    <>
      <Container>
        <Header>
          <TitleWrapper>
            <Title>{i18next.t('documents.title')}</Title>

            <TertiaryButton
              onClick={() => setNewFolderModal(true)}
              label={i18next.t('documents.new')}
              leftIcon={<IconPlus />}
            />
          </TitleWrapper>

          <SearchWrapper>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('documents.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
          </SearchWrapper>
        </Header>
        <Content>
          <Tabs
            tabs={[
              i18next.t('documents.tabs.companyFolders'),
              i18next.t('documents.tabs.PCSFolders'),
            ]}
          >
            <CompanyFolders search={searchValue} />
            <GlobalFolders search={searchValue} />
          </Tabs>
        </Content>
      </Container>

      <Modal
        title={i18next.t('documents.newFolder')}
        open={newFolderModal}
        onClose={() => setNewFolderModal(false)}
      >
        <NewFolderModal onClose={() => setNewFolderModal(false)} />
      </Modal>
    </>
  );
};

export default Folders;
