import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: 1%;
`;

export const Scroll = styled(PerfectScrollbar).attrs({
  options: { suppressScrollX: true, useBothWheelAxes: false },
})`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 6.5rem;
  padding-top: 7.5rem;
  height: 100%;
`;

export const WrapperBottom = styled.div`
  border-top: 1px solid #c6ccd2;
  padding: 1.5rem 0;
`;

export const BottomText = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  line-height: 3.2rem;
  margin: 0.25rem 0;
`;

export const Title = styled.div`
  font-weight: 600;
  color: #322e39;
  font-size: 1.6rem;
  margin-bottom: 4.7rem;
`;
