import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import i18next from 'i18next';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { MultiSelect } from '~/components/Select';

import BusinessUnitActions from '~/store/ducks/businessUnits';
// import iventory from '~/store/ducks/iventory';
import config from '~/store/ducks/config';
import NewUserTypeModalGeral from '~/components/NewUserTypeModal';
import InviteDPOModalGeral from '~/components/InviteDPOModal';
import CreateUserModalGeral from '~/components/CreateUserModal';
import PeopleActions from '~/store/ducks/people';

import { setDate } from 'date-fns/esm';
import { Container, Scroll, WrapperBottom, BottomText, Title } from './styles';

const Info = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { businessUnitDetails } = useSelector((state) => state.businessUnits);
  // const { peopleResponsibleEnum } = useSelector((state) => state.iventory);
  // const { companyId } = useSelector((state) => state.auth);
  const { listUsers } = useSelector((state) => state.config);
  const [openNewUserTypeModal, setOpenNewUserTypeModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openInviteDPOModal, setOpenInviteDPOModal] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');
  const [propleFormated, setPeopleFormated] = useState([]);
  const { people } = useSelector((state) => state.people);

  useEffect(() => {
    dispatch(PeopleActions.getPeopleRequest());
  }, []);

  useEffect(() => {
    if (people.length > 0) {
      setPeopleFormated(
        people.map((item) => ({
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        }))
      );
    }
  }, [people]);

  const id = history.location.pathname.replace(
    '/assets-management/business-units/',
    ''
  );

  const [data, setData] = useState({
    title: '',
    primaryFunction: '',
    responsible: [],
  });

  useEffect(() => {
    if (businessUnitDetails && id !== 'new') {
      let resp;
      if (businessUnitDetails?.responsibles) {
        resp = businessUnitDetails?.responsibles.map((item) => item.person_id);
      }
      setData({
        title: businessUnitDetails?.title ? businessUnitDetails?.title : '',
        primaryFunction: businessUnitDetails?.primary_function
          ? businessUnitDetails?.primary_function
          : '',
        responsible: resp,
      });
    }
  }, [businessUnitDetails]);

  const handleResponsible = (e) => {
    setData({ ...data, responsible: e });
  };

  // console.log(
  //   businessUnitDetails?.responsibles.filter((item) => {
  //     return item;
  //   })
  // );

  useEffect(() => {
    if (listUsers.rows) {
      setPeopleFormated(
        listUsers.rows.map((element) => {
          return { value: element.user.id, label: element.user.first_name };
        })
      );
    }
  }, [listUsers]);

  // useEffect(() => {
  //   setData({ ...data, responsible });
  // }, [responsible]);

  useEffect(() => {
    dispatch(BusinessUnitActions.setSidebarMenuDataRequest(data));
  }, [data]);

  useEffect(() => {
    // dispatch(iventory.getPeopleResponsibleEnumRequest(companyId));
    dispatch(config.getUsersCompanyRequest(0));
  }, []);

  return (
    <Container>
      <Scroll>
        <Title>{i18next.t('businessUnitAsset.sidebar.title')}</Title>

        <TextInput
          label={i18next.t('businessUnitAsset.sidebar.titleLabel')}
          margin="0 0 1.8rem"
          required
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />
        <TextArea
          label={i18next.t('businessUnitAsset.sidebar.primaryFunctionLabel')}
          placeholder="Descreva a função"
          margin="0 0 1.8rem"
          maxHeight="30rem"
          value={data.primaryFunction}
          onChange={(e) =>
            setData({ ...data, primaryFunction: e.target.value })
          }
        />
        <MultiSelect
          label={i18next.t('businessUnitAsset.sidebar.responsiblesLabel')}
          options={propleFormated}
          value={data.responsible}
          hasStore
          hasModal
          onStore={setOpenNewUserTypeModal}
          onChange={handleResponsible}
        />
      </Scroll>
      <WrapperBottom>
        {id !== 'new' && false && (
          <>
            <BottomText>
              {i18next.t('businessUnitAsset.sidebar.identifier')}:{' '}
              {`${businessUnitDetails?.unit?.code}`}
            </BottomText>
            {businessUnitDetails?.unit?.createdAt && (
              <>
                <BottomText>
                  {i18next.t('businessUnitAsset.sidebar.creationDate')}:{' '}
                  {format(
                    new Date(`${businessUnitDetails?.unit?.createdAt}T00:00`),
                    'dd/MM/yyyy'
                  )}
                </BottomText>

                {/* <BottomText>
                  {i18next.t('businessUnitAsset.sidebar.creationTime')}:{' '}
                  {format(
                    new Date(businessUnitDetails?.unit?.createdAt),
                    'HH:mm'
                  )}
                </BottomText> */}
                {businessUnitDetails?.last_updated?.length > 0 && (
                  <>
                    <BottomText>
                      {i18next.t('technologiesAsset.sidebar.editBy')}:{' '}
                      {
                        businessUnitDetails?.last_updated[0]?.user.name.split(
                          ' '
                        )[0]
                      }
                    </BottomText>
                    <BottomText>
                      {i18next.t('technologiesAsset.sidebar.editDate')}:{' '}
                      {format(
                        new Date(`${businessUnitDetails?.unit?.updatedAt}`),
                        'dd/MM/yyyy'
                      )}
                    </BottomText>
                  </>
                )}
              </>
            )}
          </>
        )}
      </WrapperBottom>
      <NewUserTypeModalGeral
        open={!!openNewUserTypeModal}
        onClose={() => setOpenNewUserTypeModal(false)}
        setOpenCreateUserModal={setOpenCreateUserModal}
        setNewUserRole={setNewUserRole}
        setOpenInviteDPOModal={setOpenInviteDPOModal}
      />
      <CreateUserModalGeral
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
      <InviteDPOModalGeral
        open={!!openInviteDPOModal}
        onClose={() => setOpenInviteDPOModal(false)}
      />
    </Container>
  );
};

export default Info;
