import React from 'react';
import { bool } from 'prop-types';

function FolderIcon({ active }) {
  return active ? (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0875 3.66013H12.39C15.1575 3.66013 16.5075 5.13763 16.5 8.16763V11.8201C16.5 14.7151 14.715 16.5001 11.8125 16.5001H6.18C3.2925 16.5001 1.5 14.7151 1.5 11.8126V6.18013C1.5 3.07513 2.88 1.50013 5.6025 1.50013H6.7875C7.48575 1.49263 8.1375 1.81513 8.565 2.36263L9.225 3.24013C9.435 3.50263 9.75 3.66013 10.0875 3.66013ZM5.5275 11.4676H12.4725C12.78 11.4676 13.0275 11.2126 13.0275 10.9051C13.0275 10.5901 12.78 10.3426 12.4725 10.3426H5.5275C5.2125 10.3426 4.965 10.5901 4.965 10.9051C4.965 11.2126 5.2125 11.4676 5.5275 11.4676Z"
        fill="#4347A2"
      />
    </svg>
  ) : (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03844 1.5H5.5896C2.79743 1.5 1.5 3.13158 1.5 6.17135V11.8287C1.5 14.7271 3.27264 16.5 6.17179 16.5H11.815C14.7086 16.5 16.4802 14.7261 16.4802 11.8287L16.5 8.22794C16.5 5.19205 15.2329 3.70595 12.3972 3.70595H10.3463C9.99738 3.70525 9.66794 3.54031 9.45737 3.26036L8.79606 2.37964C8.38144 1.82609 7.73013 1.5 7.03844 1.5ZM5.5896 2.5886H7.03844C7.38793 2.5886 7.71703 2.75337 7.92672 3.03333L8.58869 3.91491C9.00445 4.4677 9.65454 4.79317 10.3452 4.79455L12.3972 4.79455C14.591 4.79455 15.4128 5.75844 15.4128 8.22494L15.393 11.8256C15.393 14.1248 14.1081 15.4114 11.815 15.4114H6.17179C3.87277 15.4114 2.58716 14.1256 2.58716 11.8287V6.17135C2.58716 3.68529 3.45925 2.5886 5.5896 2.5886ZM13.0069 10.9078C13.0069 10.6072 12.7635 10.3635 12.4633 10.3635H5.51022L5.43645 10.3685C5.17113 10.4046 4.96663 10.6323 4.96663 10.9078C4.96663 11.2085 5.21 11.4521 5.51022 11.4521H12.4633L12.5371 11.4472C12.8024 11.4111 13.0069 11.1834 13.0069 10.9078Z"
        fill="#606474"
      />
    </svg>
  );
}
export default FolderIcon;

FolderIcon.propTypes = {
  active: bool.isRequired,
};
