import React, { useState, useEffect, useRef } from 'react';
import { any, oneOfType, objectOf } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';

import DocumentActions from '~/store/ducks/documents';

import SearchInput from '~/components/TextInput/Search';
import { TertiaryButton } from '~/components/Button';
import Tabs from '~/components/Tabs';

import { useLocation } from 'react-router-dom';
import CreateNewDocument from './components/CreateNewDocument';
import AllDocs from './components/Tabs/AllDocs';
import MyDocs from './components/Tabs/MyDocs';

import {
  Container,
  Header,
  TitleWrapper,
  SearchWrapper,
  HeaderSubtitle,
  Title,
  Content,
  IconPlus,
} from './styles';

const FolderDetails = ({ match }) => {
  const { params } = match;
  const dispatch = useDispatch();
  const [openCreateNewDocumentModal, setOpenCreateNewDocumentModal] =
    useState(false);
  const { isLoading, folderName } = useSelector((state) => state.documents);
  const { id } = params;
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (searchValue === '') {
      dispatch(DocumentActions.getFolderDetailsRequest(id));
    } else {
      dispatch(DocumentActions.searchFolderDetailsRequest(id, searchValue));
    }
  }, [searchValue]);

  return (
    <>
      <Container>
        <Header>
          <TitleWrapper>
            <Title>{i18next.t('documents.title')}</Title>
            {id !== '0' && (
              <TertiaryButton
                onClick={() => setOpenCreateNewDocumentModal(true)}
                label={i18next.t('documents.new')}
                leftIcon={<IconPlus />}
              />
            )}
          </TitleWrapper>
          <HeaderSubtitle>
            {`${i18next.t('documents.description')} "${folderName}"`}
          </HeaderSubtitle>
          <SearchWrapper>
            <SearchInput
              inputRef={searchInputRef}
              placeholder={i18next.t('documents.searchDocuments')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1500}
              hasDebounce
            />
          </SearchWrapper>
        </Header>
        <Content>
          {location?.state?.global === undefined ? (
            <Tabs
              tabs={[
                i18next.t('documents.tabs.allDocuments'),
                i18next.t('documents.tabs.myDocuments'),
              ]}
            >
              <AllDocs id={id} search={searchValue} isLoading={isLoading} />
              <MyDocs id={id} search={searchValue} isLoading={isLoading} />
            </Tabs>
          ) : (
            <AllDocs id={id} search={searchValue} isLoading={isLoading} />
          )}
        </Content>
      </Container>

      <CreateNewDocument
        open={!!openCreateNewDocumentModal}
        folderId={id}
        onClose={() => setOpenCreateNewDocumentModal(false)}
      />
    </>
  );
};

export default FolderDetails;

FolderDetails.propTypes = {
  match: objectOf(oneOfType([any])).isRequired,
};
