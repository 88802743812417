import { AiOutlinePlus } from 'react-icons/ai';
import styled from 'styled-components';

export const AreaCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10%;
  min-height: 70vh;

  width: auto;
`;

export const Container = styled.div`
  max-width: 1120px;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
`;

export const TabWrapper = styled.div`
  margin-top: 4rem;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: grid;

  grid-template-columns: repeat(5, 135px);
  gap: 2.2rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 135px);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(3, 135px);
  }
`;

export const NewCardButton = styled.div`
  height: 18.4rem;
  width: 13.5rem;
  background: ${({ theme }) => theme.colors.base[100]};
  border-radius: 16px;
  padding: 1.1rem 1.3rem;
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.02);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.138rem;

  cursor: pointer;

  :hover {
    filter: brightness(1.1);
    transform: scale(0.99);
    box-shadow: 0px 0px 0px 0px;
  }
`;

export const PlusIcon = styled(AiOutlinePlus).attrs({
  size: '26',
  color: '#539ADA',
})``;

export const ButtonTitle = styled.strong`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const WrapperNavigator = styled.div`
  display: flex;
  width: 100%;
  margin: 5rem 0 3.2rem 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const TotalItems = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.8rem;
  font-weight: 600;
`;

export const AreaLoadingOrOnlyMyCompanies = styled.div`
  width: auto;
  height: auto;
  background: white;
  padding: 20px;
`;
