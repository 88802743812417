import { Avatar } from '@material-ui/core';
import { objectOf, string } from 'prop-types';
import React from 'react';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthActions from '~/store/ducks/auth';
import {
  CloudOffIcon,
  CompanyDetails,
  CompanyImg,
  Container,
  EnterButton,
  Title,
  ImageWrapper,
  Status,
} from './styles';

export default function CompanyCard({ company }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { active } = company?.status[0];
  const cardColor =
    active && company?.styles[0]?.primary_color
      ? company?.styles[0]?.primary_color
      : '#CCCCCC';

  const nameFormatedImg = company.name.match(/\b(\w)/gi);

  const goDashboard = () => {
    history.push({
      pathname: '/dashboard',
    });
  };

  const handleDashboard = () => {
    if (active) {
      dispatch(AuthActions.enterTheCompanyRequest(company, goDashboard));
    } else {
      history.push('/pay-checkout', { company });
    }
  };

  const baseImgUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_API_HOMOLOG_ENDPOINT;
    }
    return process.env.REACT_APP_API_PROD_ENDPOINT;
  };

  const logo = company?.logos[0]?.url
    ? `${baseImgUrl()}/public/image?image=${company.logos[0].url}`
    : null;

  return (
    <Container cardColor={cardColor}>
      {active ? (
        <ImageWrapper>
          {logo ? (
            <CompanyImg src={logo} />
          ) : (
            <Avatar>{nameFormatedImg}</Avatar>
          )}{' '}
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          <CloudOffIcon />
        </ImageWrapper>
      )}
      <CompanyDetails active={active}>
        <Title>{company.name}</Title>
        {!active && <Status>Aguardando pagamento</Status>}
      </CompanyDetails>
      <EnterButton active={active} onClick={handleDashboard}>
        {active
          ? i18next.t('panel.companyCard.entryButton')
          : i18next.t('panel.companyCard.activeButton')}
      </EnterButton>
    </Container>
  );
}

CompanyCard.propTypes = {
  company: objectOf.isRequired,
};
