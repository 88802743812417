import React from 'react';
import { objectOf, func } from 'prop-types';
import i18next from 'i18next';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Accordion from '~/components/Accordion';
import AddMore from '~/components/Button/AddButton';
import ProcessCard from '~/components/ProcessCard';
import { useFormikContext } from 'formik';
import { Container, AddMoreWrapper } from './styles';

const DefaultProcess = () => {
  const {
    values,
    //  handleChange, touched, errors, handleBlur,
    setFieldValue,
  } = useFormikContext();

  const addNew = () => {
    const newProcess = [
      ...values.pcn?.defaultProcess,
      { description: '', assets_id: '', document_id: '' },
    ];
    setFieldValue('pcn.defaultProcess', newProcess);
  };

  const updateProcess = (index, updated) => {
    const newProcesses = values.pcn?.defaultProcess;
    newProcesses[index] = { ...newProcesses[index], ...updated };
    setFieldValue('pcn.defaultProcess', newProcesses);
  };

  const moveCardHandler = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const dragItem = values.pcn?.defaultProcess[source.index];
    const newProcesses = [...values.pcn?.defaultProcess];
    newProcesses.splice(source.index, 1);
    newProcesses.splice(destination.index, 0, dragItem);

    // setPcn((oldState) => ({ ...oldState, defaultProcess: newProcesses }));
    setFieldValue('pcn.defaultProcess', newProcesses);
  };

  const removeItem = (pcnIndex) => {
    const result = values.pcn?.defaultProcess;
    result.splice(pcnIndex, 1);
    setFieldValue('pcn.defaultProcess', result);
  };

  return (
    <Accordion
      title={i18next.t('technologiesAsset.tabs.pcn.standardProcess.title')}
    >
      <DragDropContext onDragEnd={moveCardHandler}>
        <Droppable droppableId="Processo padrão">
          {(provided) => (
            <Container
              ref={provided.innerRef}
              {...provided.droppableProps}
              container
              spacing={4}
              style={{ marginBottom: '10rem' }}
            >
              {values.pcn?.defaultProcess.map((item, index) => (
                <ProcessCard
                  index={index}
                  onRemove={removeItem}
                  process={item}
                  updateProcess={updateProcess}
                />
              ))}
              {provided.placeholder}
              <AddMoreWrapper style={{ marginTop: '2rem' }}>
                <AddMore onClick={addNew} />
              </AddMoreWrapper>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </Accordion>
  );
};

export default DefaultProcess;

DefaultProcess.propTypes = {
  pcn: objectOf.isRequired,
  setPcn: func.isRequired,
};
