import styled from 'styled-components';
import { IconPlus } from '~/assets/svgs';

export const Container = styled.div`
  width: 80vw;
  max-width: 82rem;
  padding: 4.5rem 3.8rem;
`;

export const WrapperTop = styled.div`
  margin-bottom: 2rem;
`;

export const PlusButton = styled(IconPlus)`
  @media (min-width: 960px) {
    margin-top: 4rem;
  }
  cursor: pointer;
  height: 4rem;
  width: 4rem;
`;

export const DPOSContainer = styled.div`
  display: flex;
  flex: row;
  flex-wrap: wrap;
  gap: 10;
  margin-bottom: 3rem;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-weight: 500;
  font-size: 1.5rem;
`;

export const Title = styled.p`
  color: #262a3a;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
`;
