import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getTechnologiesRequest: ['page', 'limit', 'search'],
  getTechnologiesSuccess: ['technologies'],
  getTechnologiesFailure: [''],

  searchTechnologiesRequest: ['query'],
  searchTechnologiesSuccess: ['results'],
  searchTechnologiesFailure: [''],

  createTechnologiesRequest: ['data', 'handleGoBack'],
  createTechnologiesSuccess: [''],
  createTechnologiesFailure: [''],

  updateTechnologiesRequest: ['data', 'id'],
  updateTechnologiesSuccess: [''],
  updateTechnologiesFailure: [''],

  deleteTechnologiesRequest: ['id'],
  deleteTechnologiesSuccess: ['id'],
  deleteTechnologiesFailure: [''],

  getTechnologyDetailsRequest: ['id'],
  getTechnologyDetailsSuccess: ['technologyDetails'],
  getTechnologyDetailsFailure: [''],

  getTechnologiesEnumRequest: [''],
  getTechnologiesEnumSuccess: ['technologiesEnum'],
  getTechnologiesEnumFailure: [''],

  downloadTechnologiesRequest: [''],
  downloadTechnologiesSuccess: [''],
  downloadTechnologiesFailure: [''],

  uploadTechnologiesRequest: ['data'],
  uploadTechnologiesSuccess: [''],
  uploadTechnologiesFailure: [''],

  setSidebarMenuDataRequest: ['data'],

  setSidebarMenuSubmitRequest: ['alter'],
  // setSideBarMenuSubmitReturn: [''],
});

export const TechnologiesTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  technologies: {},
  sidebarMenuData: {},
  sidebarMenuSubmit: false,
  technologyDetails: {},
  technologiesEnum: [],
});

/* Reducers */
const setSidebarMenuSubmitRequest = (state, { alter }) => {
  return state.merge({ sidebarMenuSubmit: alter });
};

const getTechnologiesRequest = (state) => state.merge({ isLoading: true });
const getTechnologiesSuccess = (state, { technologies }) =>
  state.merge({
    isLoading: false,
    error: false,
    technologies,
  });
const getTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    technologies: {},
  });

const searchTechnologiesRequest = (state) => state.merge({ isLoading: true });
const searchTechnologiesSuccess = (state, { results }) =>
  state.merge({
    isLoading: false,
    error: false,
    technologies: results,
  });
const searchTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    technologies: {},
  });

const createTechnologiesRequest = (state) => state.merge({ isLoading: true });
const createTechnologiesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateTechnologiesRequest = (state) => state.merge({ isLoading: true });
const updateTechnologiesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteTechnologiesRequest = (state) => state.merge({ isLoading: true });
const deleteTechnologiesSuccess = (state, { id }) => {
  const fitred = [...state.technologies.rows.filter((item) => item.id !== id)];
  const countFormated = state.technologies.count - 1;

  return state.merge({
    isLoading: false,
    error: false,
    technologies: {
      ...state.technologies,
      rows: fitred,
      count: countFormated,
    },
  });
};
const deleteTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getTechnologyDetailsRequest = (state) => state.merge({ isLoading: true });
const getTechnologyDetailsSuccess = (state, { technologyDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    technologyDetails,
  });
const getTechnologyDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    technologyDetails: {},
  });

const getTechnologiesEnumRequest = (state) => state.merge({ isLoading: true });
const getTechnologiesEnumSuccess = (state, { technologiesEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    technologiesEnum,
  });
const getTechnologiesEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    technologiesEnum: [],
  });

const downloadTechnologiesRequest = (state) => state.merge({ isLoading: true });
const downloadTechnologiesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadTechnologiesRequest = (state) => state.merge({ isLoading: true });
const uploadTechnologiesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadTechnologiesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const setSidebarMenuDataRequest = (state, { data }) =>
  state.merge({ sidebarMenuData: data });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_TECHNOLOGIES_REQUEST]: getTechnologiesRequest,
  [Types.GET_TECHNOLOGIES_SUCCESS]: getTechnologiesSuccess,
  [Types.GET_TECHNOLOGIES_FAILURE]: getTechnologiesFailure,

  [Types.SEARCH_TECHNOLOGIES_REQUEST]: searchTechnologiesRequest,
  [Types.SEARCH_TECHNOLOGIES_SUCCESS]: searchTechnologiesSuccess,
  [Types.SEARCH_TECHNOLOGIES_FAILURE]: searchTechnologiesFailure,

  [Types.CREATE_TECHNOLOGIES_REQUEST]: createTechnologiesRequest,
  [Types.CREATE_TECHNOLOGIES_SUCCESS]: createTechnologiesSuccess,
  [Types.CREATE_TECHNOLOGIES_FAILURE]: createTechnologiesFailure,

  [Types.UPDATE_TECHNOLOGIES_REQUEST]: updateTechnologiesRequest,
  [Types.UPDATE_TECHNOLOGIES_SUCCESS]: updateTechnologiesSuccess,
  [Types.UPDATE_TECHNOLOGIES_FAILURE]: updateTechnologiesFailure,

  [Types.DELETE_TECHNOLOGIES_REQUEST]: deleteTechnologiesRequest,
  [Types.DELETE_TECHNOLOGIES_SUCCESS]: deleteTechnologiesSuccess,
  [Types.DELETE_TECHNOLOGIES_FAILURE]: deleteTechnologiesFailure,

  [Types.GET_TECHNOLOGY_DETAILS_REQUEST]: getTechnologyDetailsRequest,
  [Types.GET_TECHNOLOGY_DETAILS_SUCCESS]: getTechnologyDetailsSuccess,
  [Types.GET_TECHNOLOGY_DETAILS_FAILURE]: getTechnologyDetailsFailure,

  [Types.GET_TECHNOLOGIES_ENUM_REQUEST]: getTechnologiesEnumRequest,
  [Types.GET_TECHNOLOGIES_ENUM_SUCCESS]: getTechnologiesEnumSuccess,
  [Types.GET_TECHNOLOGIES_ENUM_FAILURE]: getTechnologiesEnumFailure,

  [Types.DOWNLOAD_TECHNOLOGIES_REQUEST]: downloadTechnologiesRequest,
  [Types.DOWNLOAD_TECHNOLOGIES_SUCCESS]: downloadTechnologiesSuccess,
  [Types.DOWNLOAD_TECHNOLOGIES_FAILURE]: downloadTechnologiesFailure,

  [Types.UPLOAD_TECHNOLOGIES_REQUEST]: uploadTechnologiesRequest,
  [Types.UPLOAD_TECHNOLOGIES_SUCCESS]: uploadTechnologiesSuccess,
  [Types.UPLOAD_TECHNOLOGIES_FAILURE]: uploadTechnologiesFailure,

  [Types.SET_SIDEBAR_MENU_DATA_REQUEST]: setSidebarMenuDataRequest,

  [Types.SET_SIDEBAR_MENU_SUBMIT_REQUEST]: setSidebarMenuSubmitRequest,
  // [Types.SET_SIDEBAR_MENU_SUBMIT_RETURN]: setSideBarMenuSubmitReturn,
});
