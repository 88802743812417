import styled from 'styled-components';

export const Container = styled.div`
  width: 90vw;
  max-width: 90rem;
  padding: 4.5rem 3.8rem;
  max-height: 80vh;
  overflow: auto;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.6rem;
`;

export const Title = styled.div`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin: 2rem 0;
`;
