import React from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import getQueryStringParams from '~/utils/getQueryStringParams';

import TextInput from '~/components/TextInput';
import StepAnimated from '~/components/StepAnimated';
import { PrimaryButton } from '~/components/Button';


import schema from './validationSchema';

const Step1 = ({ recaptchaCompleted, onSubmit, setDpoData }) => {
  const location = useLocation();

  // const recaptchaRef = useRef(null);

  const params = getQueryStringParams(location.search);

  // useEffect(() => {
  //   recaptchaRef.current.execute();
  // }, []);

  function submit(values) {
    if (recaptchaCompleted) {
      setDpoData({
        name: values.name,
        email: values.email,
        token: params.token,
      });
      onSubmit();
    }
  }
  
  return (
    <StepAnimated noStart>
      <Formik
        initialValues={{ name: '', email: params?.email || '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <TextInput
              placeholder={i18next.t('dpoSignUp.step1.nameLabel')}
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onChange={handleChange}
              onSubmit={handleSubmit}
              margin="0 0 2.1rem 0"
            />
            <TextInput
              placeholder={i18next.t('dpoSignUp.step1.emailLabel')}
              name="email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onSubmit={handleSubmit}
              editable={false}
              margin="0 0 3.5rem 0"
            />
            {/* <WrapperCaptcha>
              <ReCAPTCHA
                theme="light"
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={() => setFieldValue('captcha', true)}
                size="invisible"
              />
              <CaptchaError error={errors.captcha && touched.captcha}>
                {errors.captcha}
              </CaptchaError>
            </WrapperCaptcha> */}
            <PrimaryButton
              label={i18next.t('common.buttons.continue')}
              onClick={handleSubmit}
            />
          </>
        )}
      </Formik>
    </StepAnimated>
  );
};

export default Step1;

Step1.propTypes = {
  onSubmit: func.isRequired,
  setDpoData: func.isRequired,
};
