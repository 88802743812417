import { put, all, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import api from '~/services/api';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import i18next from 'i18next';
import AuthActions, { AuthTypes } from '../ducks/auth';

function* signIn({ email, password, token, IsActiveAccount }) {
  try {
    if (token) {      
      const response = yield call(
        api.post,
        `/public/auth/login?invite_token=${token}`,
        {
          password,
          email,
        }
      );

      yield put(AuthActions.signInSuccess(response.data));
    } else {
      const response = yield call(api.post, `/public/auth/login`, {
        password,
        email,
      });

      yield put(AuthActions.signInSuccess(response.data));
    }

    // if (response.data.user.role !== 'dpo') {
    yield put(push('/panel'));
    // } else {
    // yield put(push('/sign-in/company-select'));
    // }
  } catch (error) {
    yield put(AuthActions.signInFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      IsActiveAccount(error.response.data.errorCode);

      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no login',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signInClient({ email, password, Redirects }) {
  try {
    const response = yield call(api.post, '/client/signin', {
      password,
      email,
    });

    yield put(AuthActions.signInClientSuccess(response.data));

    Redirects();
  } catch (error) {
    yield put(AuthActions.signInClientFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no login',
          message: translateError(
            `APIerrors.${error.response?.data?.errorCode}`
          ),
        })
      );
    }
  }
}

function* createCompany({ data: { name, cnpj }, handleGoBack }) {
  try {
    const response = yield call(api.post, '/api/company/create', {
      name,
      cnpj,
    });

    yield put(AuthActions.createCompanySuccess(response.data));

    handleGoBack(response.data);
  } catch (error) {
    yield put(AuthActions.createCompanyFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no login',
          message: translateError(
            `APIerrors.${error.response?.data?.errorCode}`
          ),
        })
      );
    }
  }
}

function* activeCompany({ id, voucher, callback }) {
  try {
    yield call(api.post, '/api/payment-checkout/voucher', {
      voucher,
      company_id: id,
    });

    yield put(AuthActions.activeCompanySuccess(id));

    callback();
  } catch (error) {
    yield put(AuthActions.activeCompanyFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no login',
          message: translateError(
            `APIerrors.${error.response?.data?.errorCode}`
          ),
        })
      );
    }
  }
}

function* selectCompany({ id }) {
  try {
    yield put(AuthActions.selectCompanySuccess(id));

    yield put(push('/dashboard'));
  } catch (error) {
    yield put(AuthActions.selectCompanyFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha na seleção de empresa',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* forgot({ email, onSubmit }) {
  try {
    yield call(api.post, `/public/forgot-password/${email}`);

    yield put(AuthActions.forgotSuccess());
    onSubmit(email);
  } catch (error) {
    yield put(AuthActions.forgotFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na recuperação de senha',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* hashVerification({ hash, email, onSubmit }) {
  try {
    yield call(api.post, '/public/reset-code-validate', {
      code: hash,
      email,
    });

    yield put(AuthActions.forgotHashValidationSuccess());
    onSubmit();
  } catch (error) {
    yield put(AuthActions.forgotHashValidationFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na validação do código',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* passwordReset({ password, hash, onSubmit }) {
  try {
    yield call(api.post, '/public/reset-password', {
      new_password: password,
      code: hash,
    });

    yield put(AuthActions.passwordResetSuccess());
    onSubmit();
  } catch (error) {
    yield put(AuthActions.passwordResetFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na mudança de senha',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signupCompanyEmailValidation({ email, onSubmit }) {
  try {
    yield call(api.post, '/users/company_email', {
      email,
    });

    yield put(AuthActions.signUpCompanyEmailValidationSuccess());

    onSubmit();
  } catch (error) {
    yield put(AuthActions.signUpCompanyEmailValidationFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro!',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* resendConfirmEmail({ email }) {
  try {
    yield call(api.post, `/public/resend-confirm-email/${email}`);

    yield put(AuthActions.resendConfirmEmailSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Email enviado!',
        message: i18next.t('common.resendEmail'),
      })
    );
  } catch (error) {
    yield put(AuthActions.resendConfirmEmailFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro!',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* companySignUp({
  data: {
    leaderData,
    companyData,
    responsibleData,
    dpos,
    termsAgreement,
    privacyAgreement,
  },
  onSubmit,
}) {
  try {
    const data = convertFormData({
      name: leaderData.name,
      email: leaderData.email,
      company: JSON.stringify({
        name: companyData.name,
        fantasy_name: companyData.fantasy_name,
        cnpj: companyData.cnpj,
        email: companyData.email,
        phone: companyData.phone,
        terms_of_use: termsAgreement,
        privacy_policy: privacyAgreement,
      }),
      legal_responsible: JSON.stringify({
        name: responsibleData.name,
        cpf: responsibleData.cpf,
        office: responsibleData.office,
        email: responsibleData.email,
        phone: responsibleData.phone,
      }),
      dpo: JSON.stringify(dpos),
    });

    if (companyData.bylaw) data.append('bylaw', companyData.bylaw);
    if (companyData?.last_contractual_alteration !== undefined)
      data.append(
        'last_contractual_alteration',
        companyData.last_contractual_alteration
      );
    if (companyData?.address_proof !== undefined)
      data.append('company_address_proof', companyData.address_proof);
    if (responsibleData?.id_document !== undefined)
      data.append('id_document', responsibleData.id_document);
    if (responsibleData?.cpf_document !== undefined)
      data.append('cpf_document', responsibleData.cpf_document);
    if (responsibleData?.legal_address_proof !== undefined)
      data.append('legal_address_proof', responsibleData.legal_address_proof);
    if (responsibleData?.letter_of_attorney !== undefined)
      data.append('letter_of_attorney', responsibleData.letter_of_attorney);

    yield call(api.post, '/users/company', data);

    yield put(AuthActions.signUpCompanySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Cadastro efetuado com sucesso!',
        message: 'Verifique seu email.',
      })
    );
    onSubmit();
  } catch (error) {
    yield put(AuthActions.signUpCompanyFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no cadastro',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signUpHashValidation({ hash, onSubmit }) {
  try {
    yield call(api.post, '/users/new_user_hash', {
      hash,
    });

    yield put(AuthActions.signUpHashValidationSuccess());
    onSubmit();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Código válido!',
        message: 'Defina sua senha agora.',
      })
    );
  } catch (error) {
    yield put(AuthActions.signUpHashValidationFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na validação de código',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signUpPasswordSet({ hash, password, onSubmit, setUserInfo }) {
  try {
    const response = yield call(api.post, '/users/set_password', {
      hash,
      password,
    });

    setUserInfo(response.data);

    yield put(AuthActions.signUpPasswordSetSuccess());
    onSubmit();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Cadastro concluído!',
        message: 'Já pode acessar a plataforma PCS!',
      })
    );
  } catch (error) {
    yield put(AuthActions.signUpPasswordSetFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na definição de senha',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* setCompanyPlan() {
  try {
    yield call(api.post, '/company/plan', {
      lookup_key: 'teste',
    });

    yield put(AuthActions.setCompanyPlanSuccess());
  } catch (error) {
    yield put(AuthActions.setCompanyPlanFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na escolha do plano',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* createPaymentIntent({ token, priceId, email, companyId, onSubmit }) {
  try {
    const response = yield call(
      api.post,
      '/company/plan',
      {
        priceId,
        email,
        companyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          companyId,
        },
      }
    );

    yield put(AuthActions.createPaymentIntentSuccess(response.data));

    onSubmit();
  } catch (error) {
    yield put(AuthActions.createPaymentIntentFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error?.response?.data?.validations?.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação do processo de pagamento',
          message: translateError(
            `APIerrors.${error?.response?.data?.errorCode}`
          ),
        })
      );
    }
  }
}

function* signUp({ data, onSubmit }) {
  try {
    if (data.token) {
      yield call(api.post, `/public/auth/register?invite_token=${data.token}`, {
        ...data,
      });
    } else {
      yield call(api.post, `/public/auth/register`, {
        ...data,
      });
    }

    yield put(AuthActions.signUpSuccess());
    onSubmit();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Cadastro concluído!',
        message: i18next.t('common.createAccountSuccess'),
      })
    );
  } catch (error) {
    yield put(AuthActions.signUpDpoFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no cadastro',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signUpDpo({ data: { name, email, password, token }, onSubmit }) {
  try {
    yield call(api.post, '/users/set_password', {
      name,
      email,
      password,
      hash: token,
    });

    yield put(AuthActions.signUpDpoSuccess());
    onSubmit();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Cadastro concluído!',
        message: 'Já pode acessar a plataforma PCS!',
      })
    );
  } catch (error) {
    yield put(AuthActions.signUpDpoFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no cadastro',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* signUpClientPasswordSet({ data: { hash, password }, onSubmit }) {
  try {
    yield call(api.post, '/client/set_password', {
      hash,
      password,
    });

    yield put(AuthActions.setNewClientPasswordSuccess());
    onSubmit();
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Cadastro concluído!',
        message: 'Já pode acessar a plataforma PCS!',
      })
    );
  } catch (error) {
    yield put(AuthActions.setNewClientPasswordFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na definição de senha',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getCompaniesDemands() {
  try {
    const response = yield call(api.get, '/tickets/numberOfTicketsOpen');

    yield put(AuthActions.getCompanyDemandsSuccess(response.data));
  } catch (error) {
    yield put(AuthActions.getCompanyDemandsFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro ao carregar as demandas das empresas!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro ao carregar as demandas das empresas!',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getCompanies({ page, limit }) {
  try {
    const response = yield call(
      api.get,
      `/api/user/list-all-companies?page=${page}&limit=${limit}`
    );

    yield put(AuthActions.getCompaniesSuccess(response.data));
  } catch (error) {
    yield put(AuthActions.getCompaniesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getMyCompanies({ page, limit }) {
  try {
    const response = yield call(
      api.get,
      `/api/user/my-companies?page=${page}&limit=${limit}`
    );

    yield put(AuthActions.getMyCompaniesSuccess(response.data));
  } catch (error) {
    yield put(AuthActions.getMyCompaniesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getAssociatedCompanies({ page, limit }) {
  try {
    const response = yield call(
      api.get,
      `/api/user/associated-companies?page=${page}&limit=${limit}`
    );

    yield put(AuthActions.getAssociatedCompaniesSuccess(response.data));
  } catch (error) {
    yield put(AuthActions.getAssociatedCompaniesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* setUserInCompany({ inviteToken }) {
  try {
    yield call(api.post, `api/company/add-user/${inviteToken}`);

    yield put(AuthActions.activeUserInCompanySuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Convite aceito!',
        message: 'Você foi inserido em uma nova empresa!',
      })
    );
  } catch (error) {
    yield put(AuthActions.activeUserInCompanyFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateUserData({ id, image, data }) {
  try {
    const formData = new FormData();
    formData.append('first_name', data.first_name);
    formData.append('last_name', data.last_name);
    formData.append('phone', data.phone.replace(/\D/g, ''));
    formData.append('user_id', id);
    if (image) formData.append('avatar', image);
    const response = yield call(api.put, '/api/user/update-details', formData);
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Atualização realizada com sucesso.',
      })
    );
    yield put(AuthActions.updateUserDataSuccess(response.data.data));
  } catch (error) {
    yield put(AuthActions.updateUserDataFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização dos dados',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* enterCompany() {
  yield put(push('/dashboard'));
}

function* logoutCompany() {
  yield put(push('/panel'));
}

function* deleteCurrentUserAccount({ description, password }) {
  try {
    yield call(api.delete, `/api/user/delete`, {
      data: {
        reason: description,
        password,
      },
    });

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Conta excluída.',
      })
    );

    yield put(AuthActions.signOutRequest());
  } catch (error) {
    yield put(AuthActions.deleteUserFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização dos dados',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

export default all([
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_IN_CLIENT_REQUEST, signInClient),
  takeLatest(AuthTypes.SELECT_COMPANY_REQUEST, selectCompany),
  takeLatest(AuthTypes.FORGOT_REQUEST, forgot),
  takeLatest(AuthTypes.FORGOT_HASH_VALIDATION_REQUEST, hashVerification),
  takeLatest(AuthTypes.PASSWORD_RESET_REQUEST, passwordReset),
  takeLatest(
    AuthTypes.SIGN_UP_COMPANY_EMAIL_VALIDATION_REQUEST,
    signupCompanyEmailValidation
  ),
  takeLatest(AuthTypes.RESEND_CONFIRM_EMAIL_REQUEST, resendConfirmEmail),
  takeLatest(AuthTypes.SIGN_UP_COMPANY_REQUEST, companySignUp),
  takeLatest(AuthTypes.SIGN_UP_HASH_VALIDATION_REQUEST, signUpHashValidation),
  takeLatest(AuthTypes.SIGN_UP_PASSWORD_SET_REQUEST, signUpPasswordSet),
  takeLatest(AuthTypes.SET_COMPANY_PLAN_REQUEST, setCompanyPlan),
  takeLatest(AuthTypes.CREATE_PAYMENT_INTENT_REQUEST, createPaymentIntent),
  takeLatest(AuthTypes.SIGN_UP_DPO_REQUEST, signUpDpo),
  takeLatest(AuthTypes.SIGN_UP_REQUEST, signUp),
  takeLatest(
    AuthTypes.SET_NEW_CLIENT_PASSWORD_REQUEST,
    signUpClientPasswordSet
  ),
  takeLatest(AuthTypes.GET_COMPANY_DEMANDS_REQUEST, getCompaniesDemands),
  takeLatest(AuthTypes.CREATE_COMPANY_REQUEST, createCompany),
  takeLatest(AuthTypes.GET_COMPANIES_REQUEST, getCompanies),
  takeLatest(AuthTypes.GET_MY_COMPANIES_REQUEST, getMyCompanies),
  takeLatest(
    AuthTypes.GET_ASSOCIATED_COMPANIES_REQUEST,
    getAssociatedCompanies
  ),
  takeLatest(AuthTypes.ACTIVE_COMPANY_REQUEST, activeCompany),
  takeLatest(AuthTypes.UPDATE_USER_DATA_REQUEST, updateUserData),
  takeLatest(AuthTypes.ENTER_THE_COMPANY_REQUEST, enterCompany),
  takeLatest(AuthTypes.LOGOUT_COMPANY_REQUEST, logoutCompany),
  takeLatest(AuthTypes.DELETE_USER_REQUEST, deleteCurrentUserAccount),
  takeLatest(AuthTypes.ACTIVE_USER_IN_COMPANY_REQUEST, setUserInCompany),
]);
