import styled from 'styled-components';

import { IconShowPassword, IconHidePassword } from '~/assets/svgs/index';

export const CheckboxWrapper = styled.div`
  margin-bottom: 2.4rem;

  & + div {
    margin-bottom: 2.4rem;
  }

  display: flex;
  align-items: flex-start;
  gap: 1rem;
`;

export const CheckBox = styled.input`
  min-height: 2rem;
  min-width: 2rem;
  border-radius: 6px;
  border: 1px solid #b8baba;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
`;

export const CheckBoxLabel = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const CheckboxButton = styled.span`
  border: none;
  background: none;
  cursor: pointer;

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.1rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export const DocumentTextWrapper = styled.div`
  margin-top: 2.5rem;
  width: 100%;
  height: 51rem;

  border: 1px solid #b8baba;

  border-radius: 1.5rem;
  /* padding: 2.5rem 3.4rem 2rem 2.8rem; */

  p {
    font-family: ${({ theme }) => theme.fonts.Poppins};
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.colors.base[400]};
  }

  & + div {
    margin-top: 2.5rem;
    margin-left: 2.5rem;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    margin: 1.2rem 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const PasswordWrapper = styled.div`
  position: relative;
`;

export const ShowPasswordIcon = styled(IconShowPassword)`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(IconHidePassword)`
  position: absolute;
  top: 2rem;
  right: 1.5rem;
  cursor: pointer;
`;
