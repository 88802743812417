import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-bottom: 2rem;
`;
