import React from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import StepAnimated from '~/components/StepAnimated';
import { PrimaryButton } from '~/components/Button';
import AuthActions from '~/store/ducks/auth';
import getQueryStringParams from '~/utils/getQueryStringParams';

import schema from './validationSchema';

import { Title, Subtitle } from './styles';

const Form = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = getQueryStringParams(location.search);

  function redirectUser() {
    history.push('/login');
  }

  function submit(values) {
    const data = {
      hash: params.token,
      password: values.password,
    };

    dispatch(AuthActions.setNewClientPasswordRequest(data, redirectUser));
  }
  return (
    <StepAnimated noStart>
      <Title>{i18next.t('clientSignUp.title')}</Title>
      <Subtitle>{i18next.t('clientSignUp.subtitle')}</Subtitle>
      <Formik
        initialValues={{ password: '', confirm_password: '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <TextInput
              type="password"
              placeholder={i18next.t('clientSignUp.passwordLabel')}
              name="password"
              value={values.password}
              touched={touched.password}
              error={errors.password}
              onChange={handleChange}
              onSubmit={handleSubmit}
              margin="0 0 3.1rem 0"
            />
            <TextInput
              type="password"
              placeholder={i18next.t('clientSignUp.passwordConfirmationLabel')}
              name="confirm_password"
              value={values.confirm_password}
              touched={touched.confirm_password}
              error={errors.confirm_password}
              onChange={handleChange}
              onSubmit={handleSubmit}
              margin="0 0 4.5rem 0"
            />
            <PrimaryButton
              label={i18next.t('clientSignUp.button')}
              onClick={handleSubmit}
            />
          </>
        )}
      </Formik>
    </StepAnimated>
  );
};

export default Form;

Form.propTypes = {
  onSubmit: func.isRequired,
};
