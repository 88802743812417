import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div``;

export const Title = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 600;
  /* margin-top: 3rem; */
`;

export const WrapperItems = styled(PerfectScrollbar)`
  width: 100%;
  flex: 1;
`;
