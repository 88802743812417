import { css } from 'styled-components';
import { breakpoints } from './variables';

export const mixinRespondTo = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (max-width: ${breakpoints[label]}) {
        ${css(...args)};
      }
    `;
    return accumulator;
  },
  {}
);

export const mixinWrapper = css`
  width: 100%;
  max-width: 1280px;
  margin: auto;
  padding: 0 5%;

  ${mixinRespondTo.md`
    padding: 0 8%;
  `}
`;
