import React, { useEffect, useState } from 'react';
import history from '~/routes/history';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthActions from '~/store/ducks/auth';
import ListCompanies from './components/ListCompanies';
import { Container } from './styles';
import Checkout1 from './CheckoutStep1';

export default function UserPanel() {
  const dispatch = useDispatch();
  const [newCompany, setNewCompany] = useState(false);
  const { inviteToken } = useParams('invite_token');

  useEffect(() => {
    if (inviteToken) {
      dispatch(AuthActions.activeUserInCompanyRequest(inviteToken));
    }
  }, []);

  const handleNewCompany = () => {
    setNewCompany(true);
  };

  if (newCompany) {
    return history.push({
      pathname: '/checkout',
    });
  }

  return (
    <Container>
      {newCompany ? (
        <Checkout1 />
      ) : (
        <ListCompanies onNewCompany={handleNewCompany} />
      )}
    </Container>
  );
}
