import React from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useSelector } from 'react-redux';

import Card from '~/components/Card';

import { Title, Content, From, PostedAt, Box } from './styles';

function Notes() {
  const { documentDetails } = useSelector((state) => state.documents);

  return (
    <Grid item xs={12}>
      <Card>
        <Title>Notas</Title>

        {documentDetails?.data?.notes?.map((item) => (
          <Box>
            <Content>{item?.description}</Content>
            <From>Por: {item?.user?.first_name}</From>
            <PostedAt>
              {format(new Date(item?.createdAt), "EEEE 'às' HH:mm", {
                locale: ptBR,
              })}
            </PostedAt>
          </Box>
        ))}
      </Card>
    </Grid>
  );
}

export default Notes;
