import React, { useState, useEffect } from 'react';
import {
  bool,
  objectOf,
  oneOfType,
  any,
  string,
  element,
  number,
  arrayOf,
  func,
} from 'prop-types';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';

import { useSelector } from 'react-redux';
import SearchInput from '~/components/TextInput/Search';
import { PrimaryButton, SecondaryButton } from '~/components/Button';

import {
  Container,
  Title,
  WrapperSearch,
  WrapperSession,
  SessionHeader,
  SessionTitle,
  Arrow,
  WrapperItem,
  ItemIndicator,
  ItemColumn,
  ItemTitle,
  ItemSubtitle,
  WrapperCheck,
  CheckIndicator,
  WrapperBottomButtons,
} from './styles';

const Item = ({
  item,
  handleBusinessRiskSelection,
  handleProcessRiskSelection,
  handleAssetRiskSelection,
  handlePeopleRiskSelection,
  type,
  selected,
}) => {
  function handleSelectionClick() {
    switch (type) {
      case 'businessUnit':
        handleBusinessRiskSelection(item.id);
        break;
      case 'processes':
        handleProcessRiskSelection(item.id);
        break;
      case 'asset':
        handleAssetRiskSelection(item.id);
        break;
      case 'users':
        handlePeopleRiskSelection(item.id);
        break;
      default:
        return null;
    }
    return null;
  }

  return (
    <WrapperItem
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ItemIndicator />
      <WrapperCheck onClick={handleSelectionClick}>
        <CheckIndicator selected={selected} />
      </WrapperCheck>
      <ItemColumn>
        <ItemTitle>{type === 'users' ? item.name : item.title}</ItemTitle>
        <ItemSubtitle>{item.subtitle}</ItemSubtitle>
      </ItemColumn>
    </WrapperItem>
  );
};

Item.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
  selected: bool.isRequired,
  handleBusinessRiskSelection: func.isRequired,
  handleProcessRiskSelection: func.isRequired,
  handleAssetRiskSelection: func.isRequired,
  handlePeopleRiskSelection: func.isRequired,
  type: string.isRequired,
};

const Session = ({ title, children }) => {
  const [open, setOpen] = useState(true);
  return (
    <WrapperSession layout>
      <SessionHeader layout onClick={() => setOpen((e) => !e)}>
        <SessionTitle>{title}</SessionTitle>
        <Arrow open={open} />
      </SessionHeader>
      <AnimatePresence>{open && children}</AnimatePresence>
    </WrapperSession>
  );
};

Session.propTypes = {
  title: string.isRequired,
  children: element.isRequired,
};

const RisksModal = ({
  onClose,
  residualRisk,
  setResidualRisk,
  currentResidualRisk,
  index,
  newTreatment,
}) => {
  const { riskAssociations } = useSelector((state) => state.risks);
  const [businessUnitArray, setBusinessUnitArray] = useState(
    riskAssociations?.business_units?.length > 0
      ? riskAssociations?.business_units
      : []
  );
  const [processesArray, setProcessesArray] = useState(
    riskAssociations?.processes?.length > 0 ? riskAssociations?.processes : []
  );
  const [assetsArray, setAssetsArray] = useState(
    riskAssociations?.assets?.length > 0 ? riskAssociations?.assets : []
  );
  const [usersArray, setUsersArray] = useState(
    riskAssociations?.users?.length > 0 ? riskAssociations?.users : []
  );
  const [searchValue, setSearchValue] = useState('');

  const handleBusinessRiskSelection = (id) => {
    const updatedResidualRisk = [...residualRisk];
    const riskIndex = updatedResidualRisk[index].business_units.findIndex(
      (risk) => risk === id
    );
    if (riskIndex === -1) {
      updatedResidualRisk[index].business_units.push(id);
    } else {
      updatedResidualRisk[index].business_units.splice(riskIndex, 1);
    }
    setResidualRisk(updatedResidualRisk);
  };

  const handleProcessRiskSelection = (id) => {
    const updatedResidualRisk = [...residualRisk];
    const riskIndex = updatedResidualRisk[index].processes.findIndex(
      (risk) => risk === id
    );
    if (riskIndex === -1) {
      updatedResidualRisk[index].processes.push(id);
    } else {
      updatedResidualRisk[index].processes.splice(riskIndex, 1);
    }
    setResidualRisk(updatedResidualRisk);
  };

  const handleAssetRiskSelection = (id) => {
    const updatedResidualRisk = [...residualRisk];
    const riskIndex = updatedResidualRisk[index].assets.findIndex(
      (risk) => risk === id
    );
    if (riskIndex === -1) {
      updatedResidualRisk[index].assets.push(id);
    } else {
      updatedResidualRisk[index].assets.splice(riskIndex, 1);
    }
    setResidualRisk(updatedResidualRisk);
  };

  const handlePeopleRiskSelection = (id) => {
    const updatedResidualRisk = [...residualRisk];
    const riskIndex = updatedResidualRisk[index].users.findIndex(
      (risk) => risk === id
    );
    if (riskIndex === -1) {
      updatedResidualRisk[index].users.push(id);
    } else {
      updatedResidualRisk[index].users.splice(riskIndex, 1);
    }
    setResidualRisk(updatedResidualRisk);
  };

  useEffect(() => {
    if (searchValue === '') {
      setBusinessUnitArray(riskAssociations?.business_units);
      setProcessesArray(riskAssociations?.processes);
      setAssetsArray(riskAssociations?.assets);
      setUsersArray(riskAssociations?.users);
    } else {
      setBusinessUnitArray(
        riskAssociations?.business_units?.filter((businessUnit) =>
          businessUnit.title.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
      setProcessesArray(
        riskAssociations?.processes?.filter((process) =>
          process.title.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
      setAssetsArray(
        riskAssociations?.assets?.filter((asset) =>
          asset.title.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
      setUsersArray(
        riskAssociations?.users?.filter((user) =>
          user.name.toUpperCase().includes(searchValue.toUpperCase())
        )
      );
    }
  }, [searchValue]);

  return (
    <AnimateSharedLayout>
      <Container>
        {newTreatment ? (
          <>
            <Title>
              {i18next.t('risks.riskDetails.treatResidualRiskModal.title1')}
              <br />
              {i18next.t(
                'risks.riskDetails.treatResidualRiskModal.title2'
              )}{' '}
            </Title>
            <WrapperSearch>
              <SearchInput
                placeholder={i18next.t('common.search')}
                value={searchValue}
                onChange={setSearchValue}
                delay={1000}
                hasDebounce
              />
            </WrapperSearch>
          </>
        ) : (
          <Title>
            {i18next.t(
              'risks.riskDetails.treatResidualRiskModal.associatedElements'
            )}
          </Title>
        )}

        <Session title={i18next.t('risks.riskDetails.elements.businessUnit')}>
          {businessUnitArray?.length > 0 ? (
            businessUnitArray.map((item) => (
              <Item
                key={item.id}
                item={item}
                selected={currentResidualRisk.business_units.find(
                  (risk) => risk === item.id
                )}
                handleBusinessRiskSelection={
                  newTreatment ? handleBusinessRiskSelection : () => {}
                }
                handleProcessRiskSelection={
                  newTreatment ? handleProcessRiskSelection : () => {}
                }
                handleAssetRiskSelection={
                  newTreatment ? handleAssetRiskSelection : () => {}
                }
                handlePeopleRiskSelection={
                  newTreatment ? handlePeopleRiskSelection : () => {}
                }
                type="businessUnit"
              />
            ))
          ) : (
            <p>
              {i18next.t('risks.riskDetails.treatResidualRiskModal.noData')}
            </p>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.processes')}>
          {processesArray?.length > 0 ? (
            processesArray.map((item) => (
              <Item
                key={item.id}
                item={item}
                selected={currentResidualRisk.processes.find(
                  (risk) => risk === item.id
                )}
                handleBusinessRiskSelection={
                  newTreatment ? handleBusinessRiskSelection : () => {}
                }
                handleProcessRiskSelection={
                  newTreatment ? handleProcessRiskSelection : () => {}
                }
                handleAssetRiskSelection={
                  newTreatment ? handleAssetRiskSelection : () => {}
                }
                handlePeopleRiskSelection={
                  newTreatment ? handlePeopleRiskSelection : () => {}
                }
                type="processes"
              />
            ))
          ) : (
            <p>
              {i18next.t('risks.riskDetails.treatResidualRiskModal.noData')}
            </p>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.people')}>
          {usersArray?.length > 0 ? (
            usersArray.map((item) => (
              <Item
                key={item.id}
                item={item}
                selected={currentResidualRisk.users.find(
                  (risk) => risk === item.id
                )}
                handleBusinessRiskSelection={
                  newTreatment ? handleBusinessRiskSelection : () => {}
                }
                handleProcessRiskSelection={
                  newTreatment ? handleProcessRiskSelection : () => {}
                }
                handleAssetRiskSelection={
                  newTreatment ? handleAssetRiskSelection : () => {}
                }
                handlePeopleRiskSelection={
                  newTreatment ? handlePeopleRiskSelection : () => {}
                }
                type="users"
              />
            ))
          ) : (
            <p>
              {i18next.t('risks.riskDetails.treatResidualRiskModal.noData')}
            </p>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.technologies')}>
          {assetsArray?.length > 0 ? (
            assetsArray.map((item) => (
              <Item
                key={item.id}
                item={item}
                selected={currentResidualRisk.assets.find(
                  (risk) => risk === item.id
                )}
                handleBusinessRiskSelection={
                  newTreatment ? handleBusinessRiskSelection : () => {}
                }
                handleProcessRiskSelection={
                  newTreatment ? handleProcessRiskSelection : () => {}
                }
                handleAssetRiskSelection={
                  newTreatment ? handleAssetRiskSelection : () => {}
                }
                handlePeopleRiskSelection={
                  newTreatment ? handlePeopleRiskSelection : () => {}
                }
                type="asset"
              />
            ))
          ) : (
            <p>
              {i18next.t('risks.riskDetails.treatResidualRiskModal.noData')}
            </p>
          )}
        </Session>
        <WrapperBottomButtons>
          <Grid container spacing={4} justify="flex-end">
            <Grid item xs={4}>
              <SecondaryButton
                label={
                  newTreatment
                    ? i18next.t('common.buttons.cancel')
                    : i18next.t('common.buttons.close')
                }
                onClick={onClose}
              />
            </Grid>
            {newTreatment && (
              <Grid item xs={4}>
                <PrimaryButton
                  label={i18next.t(
                    'risks.riskDetails.treatResidualRiskModal.registerButton'
                  )}
                  onClick={onClose}
                />
              </Grid>
            )}
          </Grid>
        </WrapperBottomButtons>
      </Container>
    </AnimateSharedLayout>
  );
};

export default RisksModal;

RisksModal.propTypes = {
  onClose: bool.isRequired,
  residualRisk: arrayOf.isRequired,
  setResidualRisk: func.isRequired,
  currentResidualRisk: objectOf.isRequired,
  index: number.isRequired,
  newTreatment: bool.isRequired,
};
