import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  height: ${(props) => (props.error ? 8.5 : 6) + (props.label ? 3 : 0)}rem;
  width: 100%;
  margin: ${(props) => props.margin};
  transition: all 300ms ease;
`;

export const WrapperInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
  border-radius: 0.5rem;

  /* :hover {
    transform: scale(1.01);
  } */

  transition: all 300ms ease;
`;

export const TextArea = styled.textarea`
  background: ${({ theme }) => theme.colors.base[200]};
  border: none;
  flex: 1;
  height: 6rem;
  width: 100%;
  padding: 1.7rem;
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.colors.red}` : 'none'};
  border-radius: 1rem;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  caret-color: ${({ theme }) => theme.colors.blue};

  /* :focus {
    transform: scale(1.01);
  } */

  transition: transform all 300ms ease;

  ${({ editableui }) =>
    !editableui &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.colors.base[500]};
    `}
`;

export const Input = styled(InputMask)`
  background: ${({ theme }) => theme.colors.base[200]};
  border: none;
  flex: 1;
  height: 6rem;
  width: 100%;
  padding: 0 1.7rem;
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.colors.red}` : 'none'};
  border-radius: 0.5rem;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  caret-color: ${({ theme }) => theme.colors.blue};

  /* :focus {
    transform: scale(1.01);
  } */

  transition: transform all 300ms ease;

  ${({ editableui }) =>
    !editableui &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.colors.base[500]};
    `}
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;

export const WrapperRight = styled.div`
  margin-left: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperLeft = styled.div`
  margin-right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.div`
  font-size: ${({ sm }) => (sm ? 1.2 : 1.4)}rem;
  color: ${({ theme, sm }) =>
    sm ? theme.colors.base[400] : theme.colors.base[500]};
  line-height: 1.4rem;
  font-weight: ${({ sm }) => (sm ? 500 : 400)};
  padding-bottom: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: red;
      }
    `}
`;
