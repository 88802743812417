import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';

import OutlineButton from '~/components/Button/MainButtons/Outline';
import { Container, Header, Title, NoData } from './styles';
import { IconEdit } from '../Threats/styles';

const Actives = () => {
  const { riskDetails } = useSelector((state) => state.risks);

  return (
    <Container>
      <Header>
        <Title>
          {i18next.t('risks.riskDetails.associations.assets.label')}
        </Title>
        <OutlineButton
          onClick={() => {}}
          label={i18next.t('common.buttons.edit')}
          leftIcon={<IconEdit />}
        />
      </Header>
      <Grid container spacing={4} style={{ marginBottom: '10rem' }}>
        {riskDetails?.contingency_asset ? (
          riskDetails?.contingency_asset.map((asset) => (
            <>
              <Grid item xs={4}>
                <TextInput
                  label={i18next.t(
                    'risks.riskDetails.associations.assets.addAsset'
                  )}
                  value={asset.asset.title}
                  editable={false}
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  label={i18next.t(
                    'risks.riskDetails.associations.assets.howLong'
                  )}
                  placeholder="selecionar"
                  value={asset.operation_time}
                  editable={false}
                />
              </Grid>
              <Grid item xs={5} />
              <Grid item xs={12}>
                <TextArea
                  label={i18next.t(
                    'risks.riskDetails.associations.assets.solution'
                  )}
                  placeholder="Descrição aqui"
                  value={asset.solution}
                  editable={false}
                />
              </Grid>
            </>
          ))
        ) : (
          <NoData>{i18next.t('risks.risksTable.noData')}</NoData>
        )}
      </Grid>
    </Container>
  );
};

export default Actives;
