/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import history from '~/routes/history';
import store from '../store';

const GuestRoute = ({ component: Component, ...rest }) => {
  const { userSignedIn, clientSignedIn } = store.getState().auth;

  const token = history.location.search.replace('?invite_token=', '');

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userSignedIn) return <Component {...props} />;
        if (clientSignedIn) return <Redirect to="/demands-history" />;
        if (token) return <Redirect to={`/panel/${token}`} />;
        return <Redirect to="/panel" />;
      }}
    />
  );
};

GuestRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default GuestRoute;
