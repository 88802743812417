import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.div`
  font-size: 1.4rem;
  color: #262a3a;
  font-weight: 400;
  line-height: 1.4rem;
  margin-bottom: 1.6rem;
`;

export const Button = styled.div`
  height: 6rem;
  width: 6rem;
  background: #fafbfb;
  border-radius: 1rem;
  ${({ theme }) => theme.clickable};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
`;
