import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { objectOf } from 'prop-types';

import { Container, DateOfDay, Hour } from './styles';

const DateViewer = ({ insertedDate }) => {
  const [date, setDate] = useState(new Date(insertedDate));

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Container>
      <DateOfDay>Dia {format(date, 'dd/MM/yyyy')}</DateOfDay>
      <Hour>{format(date, 'HH:mm')}</Hour>
    </Container>
  );
};

export default DateViewer;

DateViewer.propTypes = {
  insertedDate: objectOf(Date),
};

DateViewer.defaultProps = {
  insertedDate: null,
};
