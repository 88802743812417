import React, { useState } from 'react';
import { objectOf, func, bool, oneOfType, any } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import DocumentActions from '~/store/ducks/documents';
import { TertiarySelect } from '~/components/Select';

import {
  Container,
  WrapperButton,
  Title,
  TitleContainer,
  MoveToFolderText,
} from './styles';

function DeleteFolderModal({ folder, onClose, open }) {
  const dispatch = useDispatch();
  const [newFolder, setNewFolder] = useState(null);
  const { folders } = useSelector((state) => state.documents);

  function reloadFolders() {
    dispatch(DocumentActions.getFoldersRequest());
  }
  function handleDeleteFolder() {
    if (folder?.docs_amount === 0) {
      dispatch(
        DocumentActions.deleteFolderRequest(folder.id, null, reloadFolders)
      );
      onClose();
    } else if (newFolder) {
      dispatch(
        DocumentActions.deleteFolderRequest(folder.id, newFolder, reloadFolders)
      );
      onClose();
    }
  }

  return (
    <Modal title="" open={open} onClose={onClose}>
      <Container>
        <TitleContainer>
          <Title>{`${i18next.t('documents.deleteFolderModal.question')} "${
            folder?.name
          }"?`}</Title>
        </TitleContainer>

        {folder?.docs_amount > 0 && (
          <>
            <MoveToFolderText>
              Você precisa escolher a pasta para onde os documentos da pasta
              atual serão movidos ao deletar:
            </MoveToFolderText>
            <TertiarySelect
              options={folders?.rows?.map((item) => {
                return {
                  value: item?.id,
                  label: item?.name,
                };
              })}
              onChange={(e) => setNewFolder(e)}
            />
          </>
        )}

        <WrapperButton>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            padding="0 5.8rem"
            width="null"
            onClick={() => onClose()}
          />
          <PrimaryButton
            label={i18next.t('common.buttons.delete')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleDeleteFolder()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default DeleteFolderModal;

DeleteFolderModal.propTypes = {
  folder: objectOf(oneOfType([any])).isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
};
