import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  max-height: 30rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  line-height: normal;
`;

export const WrapperItems = styled(PerfectScrollbar)`
  width: 100%;
  flex: 1;
`;

export const WrapperItem = styled.div`
  border: 1px solid rgba(198, 204, 210, 0.3);
  border-radius: 10px;
  height: 5rem;
  padding: 0 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.6rem 0;
`;

export const Avatar = styled.img`
  object-fit: cover;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 1rem;
  margin-right: 1.5rem;
`;
