import { Grid } from '@material-ui/core';
import { AiFillPlusCircle } from 'react-icons/ai';
import { RiCloseCircleFill } from 'react-icons/ri';
import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  max-width: 82rem;
  padding: 4.5rem 3.8rem;
  max-height: 80vh;
  overflow: auto;
`;

export const ProcessWrapper = styled.div`
  margin-top: 4.9rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.4rem;
`;

export const WrapperFotterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 3.4rem;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.4rem;
  font-weight: 700;
`;

export const GridContainer = styled(Grid)`
  border: ${({ theme }) => `1px solid  ${theme.colors.base[300]}`};
  border-radius: 5px;
  padding-top: 3rem;
  position: relative;
`;

export const NewProcessButton = styled.button`
  background: ${({ theme }) => theme.colors.base[100]};

  border: 1px solid #4347a2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1.2rem 7.5rem;
  cursor: pointer;
  gap: 1.85rem;

  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;

  color: ${({ theme }) => theme.colors.base[400]};
  transition: transform 300ms;

  :hover {
    box-shadow: 0px 4px 10px rgba(83, 154, 218, 0.4);
    transform: scale(1.007);
  }
  :active {
    transform: scale(0.98);
  }
`;

export const IconPlus = styled(AiFillPlusCircle).attrs({
  size: '2rem',
  color: '#4347a2',
})``;

export const TrashIcon = styled(RiCloseCircleFill).attrs({
  size: '2rem',
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.base[500]};

  position: absolute;
  top: 1rem;
  right: 1rem;

  :hover {
    color: ${({ theme }) => theme.colors.primary[400]};
  }
`;
