import React from 'react';
import { AnimatePresence } from 'framer-motion';
import i18next from 'i18next';
// import LanguageSelector from '~/components/LanguageSelector';
import CallOpeningForm from './components/Form';

import {
  Container,
  WrapperCallOpening,
  WrapperImage,
  ImageText,
} from './styles';

function CallOpening() {
  return (
    <>
      <Container>
        <AnimatePresence>
          <WrapperCallOpening>
            <CallOpeningForm />
          </WrapperCallOpening>
          <WrapperImage layoutId="image">
            <ImageText>{i18next.t('callOpening.title')}</ImageText>
          </WrapperImage>
        </AnimatePresence>
      </Container>
      {/* <LanguageSelector /> */}
    </>
  );
}

export default CallOpening;
