import React from 'react';
import i18next from 'i18next';
import { AnimatePresence } from 'framer-motion';

import Images from '~/assets/images/';

import CompanyCard from '~/components/CompanyCard';
import SignInForm from '~/pages/SignIn/Form';

import store from '~/store';

import {
  Container,
  WrapperSignIn,
  WrapperImage,
  Image,
  WrapperSelectText,
  WrapperCompanySelect,
  WrapperCompanyCards,
  Title,
  Subtitle,
} from './styles';

function Initial() {
  const { userActive } = store.getState().auth;

  return (
    <Container>
      <AnimatePresence>
        <WrapperSignIn layoutId="sign_in_form">
          <SignInForm />
        </WrapperSignIn>
        <WrapperImage layoutId="image">
          <Image src={Images.SignInBg} alt="bg" />
        </WrapperImage>
        <WrapperCompanySelect layoutId="company_select">
          <WrapperSelectText>
            <Title>{i18next.t('login.selectCompanyTitle')}</Title>
            <Subtitle>{i18next.t('login.selectCompanyDescription')}</Subtitle>
          </WrapperSelectText>
          <WrapperCompanyCards>
            {userActive.companies.map((item) => (
              <CompanyCard
                key={item.company.id}
                name={item.company.fantasy_name}
                id={item.company.id}
                role={item.role}
                logo={item.company.logo}
              />
            ))}
          </WrapperCompanyCards>
        </WrapperCompanySelect>
      </AnimatePresence>
    </Container>
  );
}

export default Initial;
