import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getFoldersRequest: ['page', 'limit'],
  getFoldersSuccess: ['folders'],
  getFoldersFailure: [''],

  getFoldersGlobalRequest: ['page', 'limit'],
  getFoldersGlobalSuccess: ['folders'],
  getFoldersGlobalFailure: [''],

  searchFoldersRequest: ['query', 'page', 'limit'],
  searchFoldersSuccess: ['result'],
  searchFoldersFailure: [''],

  searchMyFolderDetailsRequest: ['id', 'query'],
  searchMyFolderDetailsSuccess: ['result'],
  searchMyFolderDetailsFailure: [''],

  searchFoldersGlobalRequest: ['query', 'page', 'limit'],
  searchFoldersGlobalSuccess: ['result'],
  searchFoldersGlobalFailure: [''],

  createFolderRequest: ['name', 'companyId', 'departmentId', 'reloadFolders'],
  createFolderSuccess: ['folder'],
  createFolderFailure: [''],

  editFolderNameRequest: ['folderId', 'newName', 'reloadFolders'],
  editFolderNameSuccess: [''],
  editFolderNameFailure: [''],

  deleteFolderRequest: ['folderId', 'newFolderId', 'reloadFolders'],
  deleteFolderSuccess: [''],
  deleteFolderFailure: [''],

  createNewDocumentRequest: [
    'title',
    'folderId',
    'document',
    'reloadDocuments',
  ],
  createNewDocumentSuccess: [''],
  createNewDocumentFailure: [''],

  getFolderDetailsRequest: ['id', 'page', 'limit'],
  getFolderDetailsSuccess: ['folderDetails', 'name'],
  getFolderDetailsFailure: [''],

  searchFolderDetailsRequest: ['id', 'query'],
  searchFolderDetailsSuccess: ['result'],
  searchFolderDetailsFailure: [''],

  getMyDocumentsRequest: ['id', 'page', 'limit'],
  getMyDocumentsSuccess: ['myDocuments'],
  getMyDocumentsFailure: [''],

  moveDocumentRequest: [
    'documentId',
    'oldFolderId',
    'newFolderId',
    'reloadDocuments',
  ],
  moveDocumentSuccess: [''],
  moveDocumentFailure: [''],

  deleteDocumentRequest: ['documentId', 'reloadDocuments'],
  deleteDocumentSuccess: [''],
  deleteDocumentFailure: [''],

  getDocumentDetailsRequest: ['documentId'],
  getDocumentDetailsSuccess: ['document'],
  getDocumentDetailsFailure: [''],

  getSharedDocumentDetailsRequest: ['documentId'],
  getSharedDocumentDetailsSuccess: ['document'],
  getSharedDocumentDetailsFailure: [''],

  getAllRevisionDocumentsRequest: ['page'],
  getAllRevisionDocumentsSuccess: ['revisionDocuments'],
  getAllRevisionDocumentsFailure: [''],

  searchRevisionDocumentsRequest: ['query'],
  searchRevisionDocumentsSuccess: ['result'],
  searchRevisionDocumentsFailure: [''],

  getRevisionDocumentDetailsRequest: ['documentId'],
  getRevisionDocumentDetailsSuccess: ['revisionDocumentDetail'],
  getRevisionDocumentDetailsFailure: [''],

  approveRevisionDocumentRequest: ['documentId'],
  approveRevisionDocumentSuccess: [''],
  approveRevisionDocumentFailure: [''],

  askForDocumentRevisionRequest: ['documentId', 'justification'],
  askForDocumentRevisionSuccess: [''],
  askForDocumentRevisionFailure: [''],

  downloadLastestDocumentRequest: ['idDoc'],
  downloadLastestDocumentSuccess: [''],
  downloadLastestDocumentFailure: [''],

  uploadDocumentRequest: ['document', 'documentId'],
  uploadDocumentSuccess: [''],
  uploadDocumentFailure: [''],

  getDocumentsEnumRequest: [''],
  getDocumentsEnumSuccess: ['documentsEnum'],
  getDocumentsEnumFailure: [''],
});

export const DocumentTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  folders: [],
  folderName: '',
  folderDetails: {
    id: null,
    name: null,
    createdAt: null,
    updatedAt: null,
    company_id: null,
    documents: [],
  },
  myDocuments: {
    count: null,
    rows: [],
  },
  documentDetails: {
    id: null,
    title: null,
    last_view: null,
    actived: null,
    createdAt: null,
    updatedAt: null,
    user_id: null,
    folder_id: null,
    document_versions: [],
    note: {
      id: null,
      text: null,
      createdAt: null,
      updatedAt: null,
      document_id: null,
      user_id: null,
      user_name: null,
    },
    users: [],
    fileContent: null,
  },
  revisionDocuments: {
    count: null,
    rows: [],
  },
  revisionDocumentDetail: {
    id: null,
    file: null,
    status: null,
    version: null,
    createdAt: null,
    updatedAt: null,
    user_id: null,
    document_id: null,
    document: {
      id: null,
      title: null,
    },
    user: {
      id: null,
      name: null,
    },
    fileContent: null,
  },
  documentsEnum: [],
});

/* Reducers */

const getFoldersRequest = (state) => state.merge({ isLoading: true });
const getFoldersSuccess = (state, { folders }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders,
  });
const getFoldersFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folders: [],
  });

const getFoldersGlobalRequest = (state) => state.merge({ isLoading: true });

const getFoldersGlobalSuccess = (state, { folders }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders,
  });

const getFoldersGlobalFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folders: [],
  });

const searchFoldersRequest = (state) => state.merge({ isLoading: true });

const searchFoldersSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders: result,
  });

const searchFoldersFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folders: [],
  });

const searchFoldersGlobalRequest = (state) => state.merge({ isLoading: true });

const searchFoldersGlobalSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders: result,
  });
const searchFoldersGlobalFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folders: [],
  });

const createFolderRequest = (state) => state.merge({ isLoading: true });
const createFolderSuccess = (state, { folder }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders: [state.folders, [folder]],
  });
const createFolderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const editFolderNameRequest = (state) => state.merge({ isLoading: true });
const editFolderNameSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const editFolderNameFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteFolderRequest = (state) => state.merge({ isLoading: true });
const deleteFolderSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const deleteFolderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const createNewDocumentRequest = (state) => state.merge({ isLoading: true });
const createNewDocumentSuccess = (state, { folder }) =>
  state.merge({
    isLoading: false,
    error: false,
    folders: [state.folders, [folder]],
  });
const createNewDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getFolderDetailsRequest = (state) => state.merge({ isLoading: true });
const getFolderDetailsSuccess = (state, { folderDetails, name }) =>
  state.merge({
    isLoading: false,
    error: false,
    folderDetails,
    folderName: name,
  });
const getFolderDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folderDetails: {
      id: null,
      name: null,
      createdAt: null,
      updatedAt: null,
      company_id: null,
      documents: [],
    },
  });

const searchFolderDetailsRequest = (state) => state.merge({ isLoading: true });
const searchFolderDetailsSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    folderDetails: result,
  });
const searchFolderDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folderDetails: {
      id: null,
      name: null,
      createdAt: null,
      updatedAt: null,
      company_id: null,
      documents: [],
    },
  });

const searchMyFolderDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const searchMyFolderDetailsSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    folderDetails: result,
  });
const searchMyFolderDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    folderDetails: {
      id: null,
      name: null,
      createdAt: null,
      updatedAt: null,
      company_id: null,
      documents: [],
    },
  });

const getMyDocumentsRequest = (state) => state.merge({ isLoading: true });
const getMyDocumentsSuccess = (state, { myDocuments }) =>
  state.merge({
    isLoading: false,
    error: false,
    myDocuments,
  });
const getMyDocumentsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    myDocuments: {
      count: null,
      rows: [],
    },
  });

const moveDocumentRequest = (state) => state.merge({ isLoading: true });
const moveDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const moveDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteDocumentRequest = (state) => state.merge({ isLoading: true });
const deleteDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const deleteDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDocumentDetailsRequest = (state) => state.merge({ isLoading: true });
const getDocumentDetailsSuccess = (state, { document }) =>
  state.merge({
    isLoading: false,
    error: false,
    documentDetails: document,
  });
const getDocumentDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    documentDetails: {
      id: null,
      title: null,
      last_view: null,
      actived: null,
      createdAt: null,
      updatedAt: null,
      user_id: null,
      folder_id: null,
      document_versions: [],
      note: {
        id: null,
        text: null,
        createdAt: null,
        updatedAt: null,
        document_id: null,
        user_id: null,
        user_name: null,
      },
      users: [],
      fileContent: null,
    },
  });

const getSharedDocumentDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const getSharedDocumentDetailsSuccess = (state, { document }) =>
  state.merge({
    isLoading: false,
    error: false,
    documentDetails: document,
  });
const getSharedDocumentDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    documentDetails: {
      id: null,
      title: null,
      last_view: null,
      actived: null,
      createdAt: null,
      updatedAt: null,
      user_id: null,
      folder_id: null,
      document_versions: [],
      note: {
        id: null,
        text: null,
        createdAt: null,
        updatedAt: null,
        document_id: null,
        user_id: null,
        user_name: null,
      },
      users: [],
      fileContent: null,
    },
  });

const getAllRevisionDocumentsRequest = (state) =>
  state.merge({ isLoading: true });
const getAllRevisionDocumentsSuccess = (state, { revisionDocuments }) =>
  state.merge({
    isLoading: false,
    error: false,
    revisionDocuments,
  });
const getAllRevisionDocumentsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    revisionDocuments: {
      count: null,
      rows: [
        {
          id: null,
          file: null,
          status: null,
          version: null,
          createdAt: null,
          updatedAt: null,
          user_id: null,
          document_id: null,
          document: {
            id: null,
            title: null,
          },
          user: {
            id: null,
            name: null,
          },
        },
      ],
    },
  });

const searchRevisionDocumentsRequest = (state) =>
  state.merge({ isLoading: true });
const searchRevisionDocumentsSuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: false,
    revisionDocuments: result,
  });
const searchRevisionDocumentsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    revisionDocuments: {
      count: null,
      rows: [
        {
          id: null,
          file: null,
          status: null,
          version: null,
          createdAt: null,
          updatedAt: null,
          user_id: null,
          document_id: null,
          document: {
            id: null,
            title: null,
          },
          user: {
            id: null,
            name: null,
          },
        },
      ],
    },
  });

const getRevisionDocumentDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const getRevisionDocumentDetailsSuccess = (state, { revisionDocumentDetail }) =>
  state.merge({
    isLoading: false,
    error: false,
    revisionDocumentDetail,
  });
const getRevisionDocumentDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    revisionDocumentDetail: {
      id: null,
      file: null,
      status: null,
      version: null,
      createdAt: null,
      updatedAt: null,
      user_id: null,
      document_id: null,
      document: {
        id: null,
        title: null,
      },
      user: {
        id: null,
        name: null,
      },
      fileContent: null,
    },
  });

const approveRevisionDocumentRequest = (state) =>
  state.merge({ isLoading: true });
const approveRevisionDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const approveRevisionDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const askForDocumentRevisionRequest = (state) =>
  state.merge({ isLoading: true });
const askForDocumentRevisionSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const askForDocumentRevisionFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadLastestDocumentRequest = (state) =>
  state.merge({ isLoading: true });
const downloadLastestDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadLastestDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadDocumentRequest = (state) => state.merge({ isLoading: true });
const uploadDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDocumentsEnumRequest = (state) => state.merge({ isLoading: true });
const getDocumentsEnumSuccess = (state, { documentsEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    documentsEnum,
  });
const getDocumentsEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    documentsEnum: [],
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_FOLDERS_REQUEST]: getFoldersRequest,
  [Types.GET_FOLDERS_SUCCESS]: getFoldersSuccess,
  [Types.GET_FOLDERS_FAILURE]: getFoldersFailure,

  [Types.GET_FOLDERS_GLOBAL_REQUEST]: getFoldersGlobalRequest,
  [Types.GET_FOLDERS_GLOBAL_SUCCESS]: getFoldersGlobalSuccess,
  [Types.GET_FOLDERS_GLOBAL_FAILURE]: getFoldersGlobalFailure,

  [Types.SEARCH_FOLDERS_REQUEST]: searchFoldersRequest,
  [Types.SEARCH_FOLDERS_SUCCESS]: searchFoldersSuccess,
  [Types.SEARCH_FOLDERS_FAILURE]: searchFoldersFailure,

  [Types.SEARCH_FOLDERS_GLOBAL_REQUEST]: searchFoldersGlobalRequest,
  [Types.SEARCH_FOLDERS_GLOBAL_SUCCESS]: searchFoldersGlobalSuccess,
  [Types.SEARCH_FOLDERS_GLOBAL_FAILURE]: searchFoldersGlobalFailure,

  [Types.CREATE_FOLDER_REQUEST]: createFolderRequest,
  [Types.CREATE_FOLDER_SUCCESS]: createFolderSuccess,
  [Types.CREATE_FOLDER_FAILURE]: createFolderFailure,

  [Types.EDIT_FOLDER_NAME_REQUEST]: editFolderNameRequest,
  [Types.EDIT_FOLDER_NAME_SUCCESS]: editFolderNameSuccess,
  [Types.EDIT_FOLDER_NAME_FAILURE]: editFolderNameFailure,

  [Types.DELETE_FOLDER_REQUEST]: deleteFolderRequest,
  [Types.DELETE_FOLDER_SUCCESS]: deleteFolderSuccess,
  [Types.DELETE_FOLDER_FAILURE]: deleteFolderFailure,

  [Types.CREATE_NEW_DOCUMENT_REQUEST]: createNewDocumentRequest,
  [Types.CREATE_NEW_DOCUMENT_SUCCESS]: createNewDocumentSuccess,
  [Types.CREATE_NEW_DOCUMENT_FAILURE]: createNewDocumentFailure,

  [Types.GET_FOLDER_DETAILS_REQUEST]: getFolderDetailsRequest,
  [Types.GET_FOLDER_DETAILS_SUCCESS]: getFolderDetailsSuccess,
  [Types.GET_FOLDER_DETAILS_FAILURE]: getFolderDetailsFailure,

  [Types.SEARCH_FOLDER_DETAILS_REQUEST]: searchFolderDetailsRequest,
  [Types.SEARCH_FOLDER_DETAILS_SUCCESS]: searchFolderDetailsSuccess,
  [Types.SEARCH_FOLDER_DETAILS_FAILURE]: searchFolderDetailsFailure,

  [Types.SEARCH_MY_FOLDER_DETAILS_REQUEST]: searchMyFolderDetailsRequest,
  [Types.SEARCH_MY_FOLDER_DETAILS_SUCCESS]: searchMyFolderDetailsSuccess,
  [Types.SEARCH_MY_FOLDER_DETAILS_FAILURE]: searchMyFolderDetailsFailure,

  [Types.GET_MY_DOCUMENTS_REQUEST]: getMyDocumentsRequest,
  [Types.GET_MY_DOCUMENTS_SUCCESS]: getMyDocumentsSuccess,
  [Types.GET_MY_DOCUMENTS_FAILURE]: getMyDocumentsFailure,

  [Types.MOVE_DOCUMENT_REQUEST]: moveDocumentRequest,
  [Types.MOVE_DOCUMENT_SUCCESS]: moveDocumentSuccess,
  [Types.MOVE_DOCUMENT_FAILURE]: moveDocumentFailure,

  [Types.DELETE_DOCUMENT_REQUEST]: deleteDocumentRequest,
  [Types.DELETE_DOCUMENT_SUCCESS]: deleteDocumentSuccess,
  [Types.DELETE_DOCUMENT_FAILURE]: deleteDocumentFailure,

  [Types.GET_DOCUMENT_DETAILS_REQUEST]: getDocumentDetailsRequest,
  [Types.GET_DOCUMENT_DETAILS_SUCCESS]: getDocumentDetailsSuccess,
  [Types.GET_DOCUMENT_DETAILS_FAILURE]: getDocumentDetailsFailure,

  [Types.GET_SHARED_DOCUMENT_DETAILS_REQUEST]: getSharedDocumentDetailsRequest,
  [Types.GET_SHARED_DOCUMENT_DETAILS_SUCCESS]: getSharedDocumentDetailsSuccess,
  [Types.GET_SHARED_DOCUMENT_DETAILS_FAILURE]: getSharedDocumentDetailsFailure,

  [Types.GET_ALL_REVISION_DOCUMENTS_REQUEST]: getAllRevisionDocumentsRequest,
  [Types.GET_ALL_REVISION_DOCUMENTS_SUCCESS]: getAllRevisionDocumentsSuccess,
  [Types.GET_ALL_REVISION_DOCUMENTS_FAILURE]: getAllRevisionDocumentsFailure,

  [Types.SEARCH_REVISION_DOCUMENTS_REQUEST]: searchRevisionDocumentsRequest,
  [Types.SEARCH_REVISION_DOCUMENTS_SUCCESS]: searchRevisionDocumentsSuccess,
  [Types.SEARCH_REVISION_DOCUMENTS_FAILURE]: searchRevisionDocumentsFailure,

  [Types.GET_REVISION_DOCUMENT_DETAILS_REQUEST]:
    getRevisionDocumentDetailsRequest,
  [Types.GET_REVISION_DOCUMENT_DETAILS_SUCCESS]:
    getRevisionDocumentDetailsSuccess,
  [Types.GET_REVISION_DOCUMENT_DETAILS_FAILURE]:
    getRevisionDocumentDetailsFailure,

  [Types.APPROVE_REVISION_DOCUMENT_REQUEST]: approveRevisionDocumentRequest,
  [Types.APPROVE_REVISION_DOCUMENT_SUCCESS]: approveRevisionDocumentSuccess,
  [Types.APPROVE_REVISION_DOCUMENT_FAILURE]: approveRevisionDocumentFailure,

  [Types.ASK_FOR_DOCUMENT_REVISION_REQUEST]: askForDocumentRevisionRequest,
  [Types.ASK_FOR_DOCUMENT_REVISION_SUCCESS]: askForDocumentRevisionSuccess,
  [Types.ASK_FOR_DOCUMENT_REVISION_FAILURE]: askForDocumentRevisionFailure,

  [Types.DOWNLOAD_LASTEST_DOCUMENT_REQUEST]: downloadLastestDocumentRequest,
  [Types.DOWNLOAD_LASTEST_DOCUMENT_SUCCESS]: downloadLastestDocumentSuccess,
  [Types.DOWNLOAD_LASTEST_DOCUMENT_FAILURE]: downloadLastestDocumentFailure,

  [Types.UPLOAD_DOCUMENT_REQUEST]: uploadDocumentRequest,
  [Types.UPLOAD_DOCUMENT_SUCCESS]: uploadDocumentSuccess,
  [Types.UPLOAD_DOCUMENT_FAILURE]: uploadDocumentFailure,

  [Types.GET_DOCUMENTS_ENUM_REQUEST]: getDocumentsEnumRequest,
  [Types.GET_DOCUMENTS_ENUM_SUCCESS]: getDocumentsEnumSuccess,
  [Types.GET_DOCUMENTS_ENUM_FAILURE]: getDocumentsEnumFailure,
});
