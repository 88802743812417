import React from 'react';
import { string } from 'prop-types';
import i18next from 'i18next';

import { Container, Wrapper } from './styles';

const StatusTag = ({ item }) => {
  function convertItemStatus(status) {
    let converted;
    switch (status) {
      case 'open':
        converted = i18next.t('demandManagement.table.statusTag.open');
        break;
      case 'respond':
        converted = i18next.t('demandManagement.table.statusTag.answered');
        break;
      default:
        converted = i18next.t('demandManagement.table.statusTag.closed');
    }
    return converted;
  }

  return (
    <Container>
      <Wrapper status={item}>{convertItemStatus(item)}</Wrapper>
    </Container>
  );
};

export default StatusTag;

StatusTag.propTypes = {
  item: string.isRequired,
};
