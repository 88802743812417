import React, { useState } from 'react';
import { Formik } from 'formik';
import { func, objectOf } from 'prop-types';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import StepAnimated from '~/components/StepAnimated';
import { PrimaryButton } from '~/components/Button';
import AuthActions from '~/store/ducks/auth';

import schema from './validationSchema';

import { PasswordWrapper, HidePasswordIcon, ShowPasswordIcon } from './styles';

const Step2 = ({ onSubmit, dpoData }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  function submit(values) {
    const data = {
      name: dpoData.name,
      email: dpoData.email,
      token: dpoData.token,
      password: values.password,
    };
    dispatch(AuthActions.signUpDpoRequest(data, onSubmit));
  }
  return (
    <StepAnimated noStart>
      <Formik
        initialValues={{ password: '', confirm_password: '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <PasswordWrapper>
              <TextInput
                type="password"
                placeholder={i18next.t('dpoSignUp.step2.passwordLabel')}
                name="password"
                value={values.password}
                touched={touched.password}
                error={errors.password}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 3.1rem 0"
              />
              {showPassword ? (
                <HidePasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </PasswordWrapper>

            <PasswordWrapper>
              <TextInput
                type="password"
                placeholder={i18next.t(
                  'dpoSignUp.step2.passwordConfirmationLabel'
                )}
                name="confirm_password"
                value={values.confirm_password}
                touched={touched.confirm_password}
                error={errors.confirm_password}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 4.5rem 0"
              />
              {showPasswordConfirmation ? (
                <HidePasswordIcon
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                />
              )}
            </PasswordWrapper>

            <PrimaryButton
              label={i18next.t('common.buttons.go')}
              onClick={handleSubmit}
            />
          </>
        )}
      </Formik>
    </StepAnimated>
  );
};

export default Step2;

Step2.propTypes = {
  onSubmit: func.isRequired,
  dpoData: objectOf.isRequired,
};
