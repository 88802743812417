import React, { useState, useEffect } from 'react';

// import Immutable from 'seamless-immutable';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import Tabs from '~/components/Tabs';
// import ProcessActions from '~/store/ducks/processes';
// import PeopleActions from '~/store/ducks/people';
import BusinessUnitActions from '~/store/ducks/businessUnits';
// import TechnologiesActions from '~/store/ducks/technologies';

import { PrimaryButton } from '~/components/Button';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import Spinner from '~/components/Spinner';
import Location from './Tabs/Location';
import Actives from './Tabs/Actives';
import AccessControl from './Tabs/AccessControl';
import Documentation from './Tabs/Documentation';

import { Container, ButtonWrapper, Button, Title } from './styles';
import DocumentationEdit from './Tabs/DocumentationEdit';

const Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userActive } = useSelector((state) => state.auth);
  const { sidebarMenuData, isLoading, businessUnitDetails } = useSelector(
    (state) => state.businessUnits
  );
  // const { peopleEnum } = useSelector((state) => state.people);
  // const { businessUnitsEnum } = useSelector((state) => state.businessUnits);
  // const { processesEnum } = useSelector((state) => state.processes);
  // const { technologiesEnum } = useSelector((state) => state.technologies);

  const [id, setId] = useState(
    history.location.pathname.replace('/assets-management/business-units/', '')
  );

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(BusinessUnitActions.getBusinessUnitDetailsRequest(id));
    }
  }, []);

  const [deletedDocuments, setDeletedDocuments] = useState([]);
  const [deletedActives, setDeletedActives] = useState([]);
  const [actives, setActives] = useState([]);

  const [accessControl, setAccessControl] = useState([]);

  useEffect(() => {
    if (
      id !== 'new' &&
      businessUnitDetails?.evidences &&
      businessUnitDetails?.evidences.length > 0
    ) {
      setDocuments(businessUnitDetails?.evidences);
      setAccessControl(businessUnitDetails?.access_coltrols);
      setActives(
        businessUnitDetails?.assets.map((asset) => ({
          type: asset.type,
          asset_id: asset.asset_id,
        }))
      );
    }
  }, [businessUnitDetails]);

  // useEffect(() => {
  //   if (peopleEnum.length === 0) dispatch(PeopleActions.getPeopleEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (businessUnitsEnum.length === 0)
  //     dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (processesEnum.length === 0)
  //     dispatch(ProcessActions.getProcessesEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (technologiesEnum.length === 0)
  //     dispatch(TechnologiesActions.getTechnologiesEnumRequest());
  // }, []);

  const updateId = (businessId) => {
    setId(businessId);
    history.push(`/assets-management/business-units/${businessId}`);
  };

  const submit = (values) => {
    const accessControlFiltered = accessControl.filter((item) => item !== '');
    const activesFiltered = actives.filter(
      (item) => item.type !== null && item.active !== null
    );

    const data = {
      documents,
      deletedDocuments,
      companyId: userActive.activeCompany.id,
      actives: activesFiltered,
      deletedActives,
      accessControl: accessControlFiltered,
      location: values,
      sidebarInfo: sidebarMenuData,
      currentUserId: userActive.user.id,
    };

    if (id !== 'new') {
      dispatch(BusinessUnitActions.updateBusinessUnitsRequest(data, id));
    } else {
      dispatch(BusinessUnitActions.createBusinessUnitsRequest(data, updateId));
    }
  };

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        street:
          id !== 'new' && businessUnitDetails?.address?.public_area
            ? businessUnitDetails?.address?.public_area
            : '',
        number:
          id !== 'new' && businessUnitDetails?.address?.number
            ? businessUnitDetails?.address?.number
            : '',
        complement:
          id !== 'new' && businessUnitDetails?.address?.complement
            ? businessUnitDetails?.address?.complement
            : '',
        neighborhood:
          id !== 'new' && businessUnitDetails?.address?.district
            ? businessUnitDetails?.address?.district
            : '',
        city:
          id !== 'new' && businessUnitDetails?.address?.city
            ? businessUnitDetails?.address?.city
            : '',
        state:
          id !== 'new' && businessUnitDetails?.address?.state
            ? businessUnitDetails?.address?.state
            : '',
        cep:
          id !== 'new' && businessUnitDetails?.address?.cep
            ? businessUnitDetails?.address?.cep
            : '',
      },
      onSubmit: submit,
      enableReinitialize: true,
    });

  if (id !== 'new' && isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Title>
        {id === 'new'
          ? i18next.t('businessUnitAsset.newTitle')
          : i18next.t('businessUnitAsset.editTitle')}
      </Title>
      <Tabs
        tabs={[
          i18next.t('businessUnitAsset.tabs.location.label'),
          i18next.t('businessUnitAsset.tabs.assets.label'),
          i18next.t('businessUnitAsset.tabs.accessControl.label'),
          i18next.t('businessUnitAsset.tabs.evidences.label'),
        ]}
      >
        <Location
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />

        <Actives
          actives={actives}
          setActives={setActives}
          setDeletedActives={setDeletedActives}
        />

        <AccessControl
          accessControl={accessControl}
          setAccessControl={setAccessControl}
        />

        {id === 'new' ? (
          <Documentation
            documents={documents}
            setDocuments={setDocuments}
            setDeletedDocuments={setDeletedDocuments}
          />
        ) : (
          <DocumentationEdit
            localityId={id}
            documents={documents}
            setDocuments={setDocuments}
            setDeletedDocuments={setDeletedDocuments}
          />
        )}
      </Tabs>

      <ButtonWrapper>
        <Button>
          <PrimaryButton
            label={
              isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
            }
            onClick={handleSubmit}
          />
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default Details;
export { default as BusinessUnitsLayout } from '../Layout';
