import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SecondarySelect } from '~/components/Select';
import { TertiaryButton } from '~/components/Button';
import { useFormikContext } from 'formik';
import ProcessActions from '~/store/ducks/processes';
import TextInput from '~/components/TextInput';
import CreateNewProcess from '~/components/CreateNewProcess';
import InformativeAbout from '~/components/informativeAbout';

import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  DeleteButton,
  DeleteIcon,
  WrapperAddButton,
  Nature,
} from './styles';

function AssociateProcess() {
  const dispatch = useDispatch();
  const { processesEnum } = useSelector((state) => state.processes);
  const [openNewProcess, setOpenNewProcess] = useState(false);
  const {
    values,
    //  handleChange, touched, errors, handleBlur,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (processesEnum.length === 0)
      dispatch(ProcessActions.getProcessesEnumRequest());
  }, []);

  const newActive = () => {
    setFieldValue('process.associatedProcesses', [
      ...values?.process?.associatedProcesses,
      null,
    ]);
  };

  const deleteActive = (index) => {
    const newArray = [...values?.process?.associatedProcesses];
    newArray.splice(index, 1);
    setFieldValue('process.associatedProcesses', newArray);
  };

  const updateProcess = (index, updatedType) => {
    const newArray = [...values?.process?.associatedProcesses];
    newArray[index] = updatedType;
    setFieldValue('process.associatedProcesses', [...newArray]);
  };

  const convertDepartment = (department) => {
    let string = '';
    switch (department) {
      case '1':
        string = i18next.t(
          'processAsset.tabs.executionPlan.processExecutors.nature.human'
        );
        break;
      case '2':
        string = i18next.t(
          'processAsset.tabs.executionPlan.processExecutors.nature.digital'
        );
        break;
      default:
        break;
    }

    return string;
  };

  return (
    <Container>
      <Title>{i18next.t('technologiesAsset.tabs.process.label')}</Title>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t('technologiesAsset.tabs.process.processLabel')}
          </Label>
        </Grid>
        <Grid item xs={4}>
          <Label>
            {i18next.t('technologiesAsset.tabs.process.natureLabel')}
          </Label>
        </Grid>
        <Grid item xs={3} />
        {values?.process?.associatedProcesses.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <SecondarySelect
                options={processesEnum}
                hasStore
                hasModal
                onStore={setOpenNewProcess}
                onChange={(e) => updateProcess(index, e)}
                value={item}
              />
            </Grid>
            <Grid item xs={4}>
              <Nature>
                {convertDepartment(
                  processesEnum.filter((process) => process.value === item)[0]
                    ?.nature
                )}
              </Nature>
            </Grid>
            <Grid item xs={3}>
              <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        <TertiaryButton
          onClick={newActive}
          label={i18next.t('common.buttons.add')}
          leftIcon={<IconPlus />}
        />
      </WrapperAddButton>

      <Grid container spacing={4}>
        <Grid item xs={5}>
          <TextInput
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 13,
                }}
              >
                {i18next.t('technologiesAsset.tabs.process.repository')}
                <InformativeAbout
                  description={i18next.t(
                    'technologiesAsset.tabs.process.repositoryInfo'
                  )}
                />
              </div>
            }
            placeholder={i18next.t('common.typeIt')}
            value={values?.process?.repository}
            onChange={(e) =>
              setFieldValue('process.repository', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={4}>
          <SecondarySelect
            label={i18next.t(
              'technologiesAsset.tabs.process.internationalStorage'
            )}
            placeholder="selecionar"
            name="impact"
            value={values?.process?.internationalStorage}
            onChange={(e) => setFieldValue('process.internationalStorage', e)}
            options={[
              {
                label: i18next.t('technologiesAsset.tabs.process.yesLabel'),
                value: true,
              },
              {
                label: i18next.t('technologiesAsset.tabs.process.noLabel'),
                value: false,
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <SecondarySelect
            label={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 13,
                }}
              >
                {i18next.t('technologiesAsset.tabs.process.externalTreatment')}
                <InformativeAbout
                  description={i18next.t(
                    'technologiesAsset.tabs.process.externalTreatmentInfo'
                  )}
                />
              </div>
            }
            placeholder="selecionar"
            name="impact"
            value={values?.process?.externalTreatment}
            onChange={(e) => setFieldValue('process.externalTreatment', e)}
            options={[
              {
                label: i18next.t('technologiesAsset.tabs.process.yesLabel'),
                value: true,
              },
              {
                label: i18next.t('technologiesAsset.tabs.process.noLabel'),
                value: false,
              },
            ]}
          />
        </Grid>
      </Grid>
      <CreateNewProcess
        open={!!openNewProcess}
        onClose={() => setOpenNewProcess(false)}
      />
    </Container>
  );
}

export default AssociateProcess;
