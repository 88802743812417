import styled from 'styled-components';
import { IconAdd } from '~/assets/svgs';

export const Container = styled.div`
  height: 7rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px dashed #eceef0;
  border-radius: 1rem;
  cursor: pointer;
`;

export const Add = styled(IconAdd)`
  fill: #539ada;
  margin-right: 1.6rem;
`;

export const DropText = styled.div`
  color: #262a3a;
  font-weight: 400;
  font-size: 1.6rem;
  transition: 200ms ease;
`;
