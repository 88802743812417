import React, { useState } from 'react';
import { arrayOf, objectOf, string, bool, func } from 'prop-types';
import ReactSelect from 'react-select';

import i18next from 'i18next';
import {
  Container,
  Label,
  Error,
  PlusIcon,
  ExpandWrapper,
  ExpandIcon,
  SelectWrapper,
  ExpandLessIcon,
  OptionContainer,
  OptionLabel,
  Square,
  SquareSelected,
} from './styles';
import Chips from './Chips';

function MultiMappinSelect({
  options,
  placeholder,
  label,
  touched,
  error,
  onChange,
  value,
  margin,
  oneLineLabel,
  disabled,
}) {
  const [expand, setExpand] = useState(false);

  const colourStyles = {
    control: () => ({
      label: 'control',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      borderRadius: '1rem',
      boxShadow: null,
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '6rem',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 200ms',

      minWidth: '100px',

      border: error && touched ? '1px solid red' : 'none',

      boxSizing: 'border-box',
      fontSize: '1.4rem',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#262A3A',
      '&:hover': { color: '#0E067D' },
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.4rem',
      fontWeight: 400,
    }),
    option: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.2rem',
      backgroundColor: '#ffff',
      cursor: 'pointer',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#262A3A',
      fontSize: '1.4rem',
      fontWeight: 500,
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: 'rgba(92, 238, 167, 0.1)',
        display: 'inline-block',
      };
    },
    multiValueLabel: (styles) => {
      return {
        ...styles,
        fontSize: '1.2rem',
        color: '#262a3a',
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        height: !expand ? '3rem' : 'auto',
        gap: '0.4rem',
      };
    },
  };

  const isError = Boolean(touched && error);

  const formatOptions = (item) => {
    return (
      <OptionContainer>
        {!value.includes(item.value) ? <Square /> : <SquareSelected />}
        <OptionLabel>{item.label}</OptionLabel>
      </OptionContainer>
    );
  };

  return (
    <Container error={isError} label={label} margin={margin}>
      {label && <Label oneLineLabel={oneLineLabel}>{label}</Label>}

      <ExpandWrapper>
        <SelectWrapper>
          <ReactSelect
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            formatOptionLabel={formatOptions}
            options={options}
            placeholder={placeholder}
            styles={colourStyles}
            onChange={(e) => onChange(e?.map((item) => item.value))}
            isMulti
            noOptionsMessage={() => {
              return i18next.t('common.noData');
            }}
            components={{
              MultiValue: (props) => <Chips {...props} />,
              // ClearIndicator: () => <PlusIcon />,
              DropdownIndicator: () => <PlusIcon />,
            }}
            value={options.filter((e) => value.includes(e.value))}
            isDisabled={disabled}
          />
        </SelectWrapper>
        {!expand ? (
          <ExpandIcon onClick={() => setExpand(true)} />
        ) : (
          <ExpandLessIcon onClick={() => setExpand(false)} />
        )}
      </ExpandWrapper>
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

export default MultiMappinSelect;

MultiMappinSelect.propTypes = {
  options: arrayOf(objectOf(string)),
  placeholder: string,
  label: string,
  touched: bool,
  error: string,
  onChange: func,
  value: string,
  margin: string,
  oneLineLabel: bool,
  disabled: bool,
};

MultiMappinSelect.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  label: '',
  touched: false,
  error: '',
  onChange: () => {},
  value: '',
  margin: '0',
  oneLineLabel: true,
  disabled: false,
};
