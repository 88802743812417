import React from 'react';
import { func, element, string, oneOfType, any, bool } from 'prop-types';

import { Container, WrapperIcon } from './styles';

function New({
  onClick,
  leftIcon,
  label,
  style,
  className,
  disabled,
  ...rest
}) {
  return (
    <Container
      onClick={!disabled ? onClick : null}
      style={style}
      className={className}
      disabled={disabled}
      {...rest}
    >
      {leftIcon && <WrapperIcon>{leftIcon}</WrapperIcon>}
      {label}
    </Container>
  );
}

export default New;

New.propTypes = {
  onClick: func,
  leftIcon: element,
  label: string,
  disabled: bool,
  style: oneOfType([any]),
  className: string,
};

New.defaultProps = {
  onClick: () => {},
  disabled: false,
  leftIcon: undefined,
  label: 'Label',
  style: {},
  className: null,
};
