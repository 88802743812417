import React, { useState, useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import i18next from 'i18next';
import ProgressBar from '~/components/ProgressBar';
import history from '~/routes/history';
// import LanguageSelector from '~/components/LanguageSelector';

import Steps from './Steps';

import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  Title,
  FormWrapper,
  WrapperProgress,
  GoBackWrapper,
} from './styles';

const SignUpDPO = () => {
  const [step, setStep] = useState(1);
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(true);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    recaptchaRef.current.execute();
  }, []);

  return (
    <>
      <Container>
        <BG />
        <Logo />
        <Content>
          <TitleWrapper>
            <Title>{i18next.t('common.signUp.title1')}</Title>
            <Title>{i18next.t('common.signUp.title2')}</Title>
            <Title>{i18next.t('common.signUp.title3')}</Title>
          </TitleWrapper>

          <FormWrapper>
            {step > 1 && (
              <GoBackWrapper onClick={() => setStep((e) => e - 1)}>
                <span>{'<'}</span>
                <span>{i18next.t('common.buttons.goBack')}</span>
              </GoBackWrapper>
            )}

            <WrapperProgress>
              <ProgressBar
                label={`${i18next.t(
                  'common.signUp.stepLabel1'
                )} ${step} ${i18next.t('common.signUp.stepLabel2')} 2`}
                actualValue={step}
                total={2}
                initialColor="#539ADA"
                finalColor="#539ADA"
              />
            </WrapperProgress>

            <Steps
              step={step}
              recaptchaCompleted={recaptchaCompleted}
              onNext={() => {
                if (step === 2) {
                  history.push('/');
                } else {
                  setStep((e) => e + 1);
                }
              }}
            />
          </FormWrapper>
        </Content>
        {step === 1 && (
          <ReCAPTCHA
            theme="light"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={() => setRecaptchaCompleted(true)}
            size="invisible"
          />
        )}
      </Container>
      {/* <LanguageSelector /> */}
    </>
  );
};

export default SignUpDPO;
