import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { dateSortMethod } from '~/utils';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import ConfigActions from '~/store/ducks/config';
import { format } from 'date-fns';
import HoverButton from '~/components/Button/MainButtons/HoverButton';
import {
  Container,
  // ,
  //  InviteIcon
} from './styles';

const TableComp = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { listHistory, sendEmail } = useSelector((state) => state.config);
  // const [openEditModal, setOpenEditModal] = useState(false);
  // const [itemEdit, setItemEdit] = useState();
  const { userActive } = useSelector((state) => state.auth);

  const handlePagination = (activePage) => {
    dispatch(ConfigActions.getInvitationHistoryRequest(activePage));
  };

  useEffect(() => {
    dispatch(ConfigActions.getInvitationHistoryRequest(currentPage - 1));
  }, [dispatch]);

  const reloadTable = () => {
    dispatch(ConfigActions.getInvitationHistoryRequest(0));
  };

  const resendEmail = (user) => {
    const data = {
      user_email: user?.email,
      role_id: user?.role?.id,
      company_id: userActive.activeCompany.id,
    };

    dispatch(ConfigActions.createCompanyUserRequest(data, reloadTable));
  };

  return (
    <>
      {/* {isLoading ? (
        <Container style={{ height: 550 }}>
          <Spinner loading={isLoading} />
        </Container>
      ) : ( */}
      <Container>
        <Card>
          <Table
            columns={[
              {
                Header: i18next.t('configurations.invitationHistory.email'),
                accessor: 'email',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('configurations.invitationHistory.status'),
                accessor: 'status',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value === 'PENDING' ? (
                    <span style={{ color: '#FF4B55' }}>
                      {i18next.t('configurations.user.waiting')}
                    </span>
                  ) : (
                    <span style={{ color: '#00BC61' }}>
                      {i18next.t('configurations.user.actived')}
                    </span>
                  );
                },
              },
              {
                Header: i18next.t('configurations.invitationHistory.data'),
                accessor: 'createdAt',
                Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
                sortType: (a, b) => {
                  return dateSortMethod(a.values.date, b.values.date);
                },
              },
              {
                Header: '',
                accessor: 'action',
                Cell: (e) => {
                  if (!e.value) {
                    return (
                      <HoverButton
                        disabled={e.row.original.email === sendEmail}
                        style={{ width: 140, height: 35 }}
                        onClick={() => resendEmail(e.row.original)}
                        label={
                          e.row.original.email === sendEmail ? (
                            <strong>Enviando...</strong>
                          ) : (
                            i18next.t(
                              'configurations.invitationHistory.buttonSubmit'
                            )
                          )
                        }
                      />
                    );
                  }
                  return null;
                },
              },
            ]}
            data={
              listHistory && listHistory?.count > 0 ? listHistory?.rows : []
            }
            // onRowClick={(item) => {
            //   getDetails(item);
            // }}
            total={listHistory?.count}
            pageCount={listHistory?.total_pages}
            handlePagination={handlePagination}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Card>
      </Container>
      {/* )} */}
    </>
  );
};

export default TableComp;
