import React from 'react';
import { Grid } from '@material-ui/core';
import { objectOf, string } from 'prop-types';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { IconProfilePlaceholder } from '~/assets/icons';

import Card from '~/components/Card';
import { Text, Row } from '~/styles/components';

import { Container, Title, WrapperItems, WrapperItem, Avatar } from './styles';

const ParticipantsItem = ({ item }) => {
  return (
    <WrapperItem>
      <Row align="center">
        <Avatar
          src={
            item?.user.avatar[0]?.url
              ? `${
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                    : process.env.REACT_APP_API_PROD_ENDPOINT
                }/public/image?image=${item?.user?.avatar[0]?.url}`
              : IconProfilePlaceholder
          }
          width="3.2rem"
          height="3.2rem"
        />
        <Text
          fontWeight={400}
          fontSize="1.2rem"
          color={(theme) => theme.colors.base[400]}
        >
          {item?.user?.first_name}
        </Text>
      </Row>
    </WrapperItem>
  );
};

ParticipantsItem.propTypes = {
  item: objectOf(string).isRequired,
};

function Participants() {
  const { documentDetails } = useSelector((state) => state.documents);
  return (
    <Grid item xs={12}>
      <Card>
        <Container>
          <Title>{i18next.t('documents.documentDetails.participants')}</Title>
          <WrapperItems>
            {documentDetails?.data?.participants.map((item) => (
              <ParticipantsItem item={item} />
            ))}
          </WrapperItems>
        </Container>
      </Card>
    </Grid>
  );
}

export default Participants;
