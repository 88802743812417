import React, { useEffect, useState } from 'react';

import i18next from 'i18next';
import history from '~/routes/history';

import AuthActions from '~/store/ducks/auth';

import { differenceInSeconds } from 'date-fns';
import { useDispatch } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import {
  Container,
  BG,
  Content,
  TitleWrapper,
  Logo,
  Title,
  FormWrapper,
  BoxContainer,
  TitleModal,
} from './styles';

function ConfirmEmailReceipt() {
  const dispatch = useDispatch();

  const [activeTimer, setActiveTimer] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const [totalSeconds, setTotalSeconds] = useState(60);

  const userEmail = localStorage.getItem(
    '@horus-pcs:create-account-email-1.0.0'
  );

  useEffect(() => {
    let interval;

    if (activeTimer) {
      interval = setInterval(() => {
        const secondsDifference = differenceInSeconds(
          new Date(),
          new Date(startDate)
        );
        if (secondsDifference >= totalSeconds) {
          setTotalSeconds(totalSeconds);
          setActiveTimer(false);
        } else {
          setTotalSeconds(totalSeconds - secondsDifference);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [activeTimer]);

  const handleCountDown = () => {
    dispatch(AuthActions.resendConfirmEmailRequest(userEmail));

    setStartDate(new Date());
    setActiveTimer(true);
  };

  return (
    <Container>
      <BG />
      <Content>
        <TitleWrapper>
          <Logo />
          <Title>{i18next.t('common.signUp.title1')}</Title>
          <Title>{i18next.t('common.signUp.title2')}</Title>
          <Title>{i18next.t('common.signUp.title3')}</Title>
        </TitleWrapper>

        <BoxContainer>
          <TitleModal>{i18next.t('confirmEmail.title')}</TitleModal>
          <FormWrapper>
            <PrimaryButton
              style={{ padding: 10 }}
              disabled={activeTimer}
              label={
                activeTimer
                  ? `${totalSeconds} s`
                  : i18next.t('confirmEmail.resend')
              }
              onClick={handleCountDown}
            />
            <SecondaryButton
              style={{
                padding: 10,
              }}
              onClick={() => {
                history.push('/');
              }}
              label={i18next.t('confirmEmail.confirmReceipt')}
            />
          </FormWrapper>
        </BoxContainer>
      </Content>
    </Container>
  );
}

export default ConfirmEmailReceipt;
