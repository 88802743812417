import React from 'react';
import { element, string } from 'prop-types';

function CustomScroll({ children, className }) {
  return (
    <div
      className={className}
    >
      {children}
    </div>
  );
}

export default CustomScroll;

CustomScroll.propTypes = {
  children: element.isRequired,
  className: string,
};

CustomScroll.defaultProps = {
  className: undefined,
};
