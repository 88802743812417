import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IconDownload } from '~/assets/svgs';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.3rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SearchWrapper = styled.div`
  margin-top: 3rem;
  width: 25%;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 1.6rem;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const DownloadButton = styled.div`
  width: 12rem;
  height: 5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 1rem;
  margin-right: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.clickable};
`;

export const DownloadIcon = styled(IconDownload)`
  fill: ${({ theme }) => theme.colors.blueSecondary};
`;

export const DownloadText = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #606474;
  margin-left: 1rem;
`;
