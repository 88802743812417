import React from 'react';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any, func, number } from 'prop-types';
import i18next from 'i18next';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  MoveIcon,
  DeleteIcon,
  Content,
} from './styles';

function ActionButton({
  folderId,
  item,
  setOpenDeleteModal,
  setOpenMoveDocumentModal,
  setSelectedDocument,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenDeleteModel = () => {
    setSelectedDocument(item);
    setOpenDeleteModal(true);
  };

  const handleOpenMoveDocumentModel = () => {
    setSelectedDocument(item);
    setOpenMoveDocumentModal(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container onClick={handleClick}>
      <Content>
        <MoreIcon />
      </Content>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {folderId !== 0 && (
          <Row onClick={() => handleOpenMoveDocumentModel()}>
            <WrapperIcon>
              <MoveIcon />
            </WrapperIcon>
            {i18next.t('documents.table.moveArchive')}
          </Row>
        )}

        {folderId !== 0 && (
          <Row onClick={() => handleOpenDeleteModel()}>
            <WrapperIcon>
              <DeleteIcon />
            </WrapperIcon>
            {i18next.t('documents.table.delete')}
          </Row>
        )}
      </Popover>
    </Container>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  folderId: number.isRequired,
  item: objectOf(oneOfType([any])).isRequired,
  setOpenDeleteModal: func.isRequired,
  setOpenMoveDocumentModal: func.isRequired,
  setSelectedDocument: func.isRequired,
};
