import React from 'react';
import { number } from 'prop-types';

import { Container, Wrapper } from './styles';

const GridTag = ({ children, rowIndex, itemIndex }) => {
  const blue = 'rgba(23, 179, 247, 0.1)';
  const green = 'rgba(5, 183, 55, 0.1)';
  const yellow = 'rgba(255, 198, 0, 0.1)';
  const red = 'rgba(255, 41, 0, 0.1)';
  const purple = 'rgba(14, 6, 125, 0.1)';

  const colors = [
    [yellow, yellow, red, purple, purple],
    [green, yellow, yellow, red, purple],
    [green, green, yellow, yellow, red],
    [blue, green, green, yellow, yellow],
    [blue, blue, green, green, yellow],
  ];
  return (
    <Container>
      <Wrapper color={colors[rowIndex][itemIndex - 1]}>{children}</Wrapper>
    </Container>
  );
};

export default GridTag;

GridTag.propTypes = {
  children: number.isRequired,
  rowIndex: number.isRequired,
  itemIndex: number.isRequired,
};
