import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  border-radius: 10px;
  margin-top: 1.6rem;
  margin-right: 3rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.6rem 1.5rem 1.3rem 1.5rem;
  ${({ theme }) => theme.clickable};
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  margin-right: 1.2rem;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  color: #262a3a;
  font-weight: 500;
`;

export const State = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  color: ${({ color }) => color.color};
  flex-grow: 1;
`;

export const Circle = styled.div`
  background-color: ${({ color }) => color.color};
  padding: 2px;
  margin-right: 4px;
  border-radius: 3px;
`;

export const Date = styled.div`
  font-size: 1rem;
  line-height: 2.4rem;
  text-align: justify;
  color: #555b71;
`;

export const Text = styled.div`
  font-size: 1.3rem;
  line-height: 1.8rem;
  text-align: justify;
  color: #606474;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1rem;
`;
