import styled from 'styled-components';

export const Container = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.blue};
  border-radius: 0.5rem;
  background: transparent;
  font-size: ${({ small }) => (small ? 1.4 : 1.8)}rem;
  font-weight: ${({ small }) => (small ? 400 : 600)};
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;

  height: ${({ small }) => (small ? 4 : 6)}rem;
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding};
  transition: all 0.2s ease;
  margin: ${(props) => props.margin};

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;
