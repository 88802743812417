import React, { useState, useEffect } from 'react';
import { string, objectOf, oneOfType, any, element } from 'prop-types';
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import BusinessUnitActions from '~/store/ducks/businessUnits';
import ProcessActions from '~/store/ducks/processes';
import TechnologyActions from '~/store/ducks/technologies';

import Search from '~/components/TextInput/Search';

import {
  Container,
  Title,
  WrapperSearch,
  WrapperSession,
  SessionHeader,
  SessionTitle,
  Arrow,
  WrapperItem,
  ItemIndicator,
  ItemColumn,
  ItemTitle,
  ItemSubtitle,
  WrapperIcon,
  EyeIcon,
  NoData,
  ContentTitle,
} from './styles';

const Item = ({ item, type }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEyeClick = () => {
    switch (type) {
      case 'businessUnit':
        dispatch(BusinessUnitActions.getBusinessUnitDetailsRequest(item?.id));
        break;
      case 'process':
        dispatch(ProcessActions.getProcessDetailsRequest(item?.id));
        break;
      case 'asset':
        dispatch(TechnologyActions.getTechnologyDetailsRequest(item?.id));
        break;
      case 'people':
        history.push('/assets-management/people');
        break;
      default:
    }
  };

  return (
    <WrapperItem
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <ItemIndicator />
      <ItemColumn>
        <ItemTitle>{type === 'people' ? item?.name : item?.title}</ItemTitle>
        <ItemSubtitle>{item?.subtitle}</ItemSubtitle>
      </ItemColumn>
      <WrapperIcon>
        <EyeIcon onClick={handleEyeClick} />
      </WrapperIcon>
    </WrapperItem>
  );
};

Item.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
  type: string.isRequired,
};

const Session = ({ title, children }) => {
  const [open, setOpen] = useState(true);
  return (
    <WrapperSession layout>
      <SessionHeader layout onClick={() => setOpen((e) => !e)}>
        <SessionTitle>{title}</SessionTitle>
        <Arrow open={open} />
      </SessionHeader>
      <AnimatePresence>{open && children}</AnimatePresence>
    </WrapperSession>
  );
};

Session.propTypes = {
  title: string.isRequired,
  children: element.isRequired,
};

const Elements = () => {
  const { riskAssociations } = useSelector((state) => state.risks);
  const [businessUnitArray, setBusinessUnitArray] = useState([]);
  const [processesArray, setProcessesArray] = useState([]);
  const [assetsArray, setAssetsArray] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue === '') {
      setBusinessUnitArray(
        riskAssociations?.business_units
          ? [...riskAssociations?.business_units]
          : []
      );
      setProcessesArray(
        riskAssociations?.processes ? [...riskAssociations?.processes] : []
      );
      setAssetsArray(
        riskAssociations?.assets ? [...riskAssociations?.assets] : []
      );
      setUsersArray(
        riskAssociations?.users ? [...riskAssociations?.users] : []
      );
    } else {
      if ([...riskAssociations?.business_units].length > 0) {
        setBusinessUnitArray([
          ...riskAssociations?.business_units.filter((businessUnit) =>
            businessUnit.title.toUpperCase().includes(searchValue.toUpperCase())
          ),
        ]);
      }
      if ([...riskAssociations?.processes].length > 0) {
        setProcessesArray([
          ...riskAssociations?.processes.filter((process) =>
            process.title.toUpperCase().includes(searchValue.toUpperCase())
          ),
        ]);
      }
      if ([...riskAssociations?.assets].length > 0) {
        setAssetsArray([
          ...riskAssociations?.assets.filter((asset) =>
            asset.title.toUpperCase().includes(searchValue.toUpperCase())
          ),
        ]);
      }
      if ([...riskAssociations?.users].length > 0) {
        setUsersArray([
          ...riskAssociations?.users.filter((user) =>
            user.name.toUpperCase().includes(searchValue.toUpperCase())
          ),
        ]);
      }
    }
  }, [searchValue, riskAssociations]);

  return (
    <AnimateSharedLayout>
      <Container>
        <ContentTitle>
          <Title>{i18next.t('risks.riskDetails.elements.title')}</Title>
          <WrapperSearch>
            <Search
              placeholder={i18next.t('common.search')}
              value={searchValue}
              onChange={setSearchValue}
              delay={1000}
              hasDebounce
            />
          </WrapperSearch>
        </ContentTitle>
        <Session title={i18next.t('risks.riskDetails.elements.businessUnit')}>
          {businessUnitArray.length > 0 ? (
            businessUnitArray.map((item) => (
              <Item item={item} type="businessUnit" />
            ))
          ) : (
            <NoData>{i18next.t('risks.riskDetails.elements.noData')}</NoData>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.processes')}>
          {processesArray.length > 0 ? (
            processesArray.map((item) => <Item item={item} type="process" />)
          ) : (
            <NoData>{i18next.t('risks.riskDetails.elements.noData')}</NoData>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.people')}>
          {usersArray.length > 0 ? (
            usersArray.map((item) => <Item item={item} type="people" />)
          ) : (
            <NoData>{i18next.t('risks.riskDetails.elements.noData')}</NoData>
          )}
        </Session>
        <Session title={i18next.t('risks.riskDetails.elements.technologies')}>
          {assetsArray.length > 0 ? (
            assetsArray.map((item) => <Item item={item} type="asset" />)
          ) : (
            <NoData>{i18next.t('risks.riskDetails.elements.noData')}</NoData>
          )}
        </Session>
      </Container>
    </AnimateSharedLayout>
  );
};

export default Elements;
