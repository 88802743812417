import styled from 'styled-components';

export const Container = styled.div`
  min-height: 7rem;
  border: 1px solid rgba(198, 204, 210, 0.3);
  border-radius: 10px;
  margin: 0.7rem 0;
  margin-right: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10;
  padding: 1rem 1.4rem 0;
  width: 95%;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 70%;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const Avatar = styled.img`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 400;
  margin-right: 1.8rem;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const State = styled.div`
  background-color: ${({ color }) => color.background};
  height: 2.8rem;
  padding: 0 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ color }) => color.color};
  border-radius: 1rem;
  flex-grow: 1;
`;

export const Circle = styled.div`
  background-color: ${({ color }) => color.color};
  padding: 3px;
  margin-right: 4px;
  border-radius: 3px;
`;
