import * as Yup from 'yup';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import i18next from 'i18next';

export default Yup.object().shape({
  name: Yup.string().required(i18next.t('common.validation.required')),
  document: Yup.string()
    .required(i18next.t('common.validation.required'))
    .test('isCNPJ', i18next.t('common.validation.cnpj'), (document) =>
      isValidCnpj(document)
    ),
});
