import React, { useEffect, useState } from 'react';
import history from '~/routes/history';
import i18next from 'i18next';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { Formik } from 'formik';
import AuthActions from '~/store/ducks/auth';
import UserHeader from '~/components/UserHeader';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import Images from '~/assets/images';
import Voucher from './components/Voucher';
import schema from './validationSchema';
import getStripe from '../../../services/getStripe';
import SuccessModal from './components/payModal/SuccessPay';
import FailureModal from './components/payModal/FailurePay';

import {
  Modal,
  Title,
  Subtitle,
  Content,
  ButtonAreaCancelAndNext,
  FormArea,
  Container,
  PCSLogo,
  ModalSecondary,
  AreaLogo,
  ButtonArea,
} from './styles';

function Step1() {
  const location = useLocation();
  const company = location.state;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [data, setData] = useState();
  const { userActive } = useSelector((state) => state.auth);
  const { result } = useParams('result');
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  const handleCheckout = async () => {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        { price: process.env.REACT_APP_STRIPE_PRICE_ID, quantity: 1 },
      ],
      mode: 'subscription',

      successUrl: `${
        process.env.REACT_APP_PROFILE === 'develop'
          ? 'http://localhost:3000/pay-checkout/success'
          : process.env.REACT_APP_PROFILE === 'homolog'
          ? 'https://homolog-app.privacycontrolsuite.com/pay-checkout/success'
          : 'https://app.privacycontrolsuite.com/pay-checkout/success'
      }`,
      cancelUrl: `${
        process.env.REACT_APP_PROFILE === 'develop'
          ? 'http://localhost:3000/pay-checkout/failure'
          : process.env.REACT_APP_PROFILE === 'homolog'
          ? 'https://homolog-app.privacycontrolsuite.com/pay-checkout/failure'
          : 'https://app.privacycontrolsuite.com/pay-checkout/failure'
      }`,
      customerEmail: `${userActive?.user?.email}`,
    });
    console.warn('error', error);
  };

  localStorage.getItem('DataCompanies');

  useEffect(() => {
    if (company) {
      localStorage.setItem('DataCompanies', JSON.stringify(company));

      dispatch(AuthActions.newCompanyRequest(company));
    } else if (
      JSON.parse(localStorage.getItem('DataCompanies')) !== null &&
      company === undefined
    ) {
      dispatch(
        AuthActions.newCompanyRequest(
          JSON.parse(localStorage.getItem('DataCompanies'))
        )
      );
    } else if (JSON.parse(localStorage.getItem('DataCompanies')) == null) {
      history.push('/panel');
    }
  }, []);

  // dispatch(AuthActions.newCompanyRequest(company));

  const redirect = () => {
    history.push('/finish-checkout');
  };

  const submit = (values) => {
    const companyId = company
      ? company?.company?.id
      : JSON.parse(localStorage?.DataCompanies)?.company?.id;
    switch (values.payForm) {
      case 'voucher':
        dispatch(
          AuthActions.activeCompanyRequest(companyId, values.voucher, redirect)
        );
        localStorage.removeItem('DataCompanies');
        break;

      default:
      // code block
    }
    // if (values.payForm === 'voucher') {
    //   console.log(values?.voucher);
    // }
    // history.push({
    //   pathname: '/finish-checkout',
    // });
  };

  const navigatePage = () => {
    history.push({
      pathname: '/panel',
    });
    localStorage.removeItem('DataCompanies');
  };

  useEffect(() => {
    if (result === 'success') {
      setOpenSuccess(true);
    } else if (result === 'failure') {
      setOpenFailure(true);
    }
  }, []);

  return (
    <>
      <SuccessModal />
      <Container>
        <UserHeader progress progressStatus={2} />
        <Content>
          <Title>{i18next.t('configurations.checkoutPay.title')}</Title>
          <br />
          <Subtitle>
            {i18next.t('configurations.checkoutPay.subtitle')}
          </Subtitle>
          <Modal>
            <Formik
              initialValues={{
                payForm: 'voucher',
                voucher: '',
              }}
              onSubmit={submit}
              validationSchema={schema}
            >
              {({ values, handleChange, handleSubmit }) => (
                <FormArea>
                  <ModalSecondary>
                    <AreaLogo>
                      <PCSLogo src={Images.PCSEnterprise} />
                      <ButtonArea>
                        <PrimaryButton
                          label={i18next.t(
                            'configurations.checkoutPay.buyVoucher'
                          )}
                          onClick={() => handleCheckout()}
                        />
                      </ButtonArea>
                    </AreaLogo>
                    <Voucher />
                  </ModalSecondary>
                  <ButtonAreaCancelAndNext>
                    <SecondaryButton
                      type="submit"
                      label={i18next.t('common.buttons.cancel')}
                      onClick={navigatePage}
                    />
                    <PrimaryButton
                      type="submit"
                      disabled={isLoading}
                      label={
                        isLoading ? (
                          <SppinerIcon />
                        ) : (
                          i18next.t('common.buttons.next')
                        )
                      }
                      onClick={handleSubmit}
                    />
                  </ButtonAreaCancelAndNext>
                </FormArea>
              )}
            </Formik>
          </Modal>
        </Content>
      </Container>
      <SuccessModal
        open={!!openSuccess}
        onClose={() => setOpenSuccess(false)}
      />
      <FailureModal
        open={!!openFailure}
        onClose={() => setOpenFailure(false)}
      />
    </>
  );
}

export default Step1;
