import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, objectOf } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import { useParams } from 'react-router-dom';
import IventoryActions from '~/store/ducks/iventory';
import schema from './validationSchema';
import { Container, WrapperButton } from './styles';

function CreateOptionModal({ open, onClose, editOption }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.iventory);

  const { iventoryOption } = useParams();

  useEffect(() => {
    // dispatch(
    // get
    // );
  }, []);

  const onSuccess = () => {
    onClose();
  };

  const submit = (values) => {
    const data = {
      id: editOption?.id ?? '',
      title: values.name,
    };
    if (editOption?.id) {
      // dispatch((data, onSuccess));
    } else {
      switch (iventoryOption) {
        case 'collection_purpose':
          dispatch(
            IventoryActions.createCollectionPurposeRequest(data, onSuccess)
          );
          break;
        case 'collection_forms':
          dispatch(
            IventoryActions.createCollectionFormsRequest(data, onSuccess)
          );
          break;
        case 'category_of_data_collected':
          dispatch(
            IventoryActions.createCategoryOfDataCollectedRequest(
              data,
              onSuccess
            )
          );
          break;
        case 'transparency_to_the_holder':
          dispatch(
            IventoryActions.createTransparencyToTheHolderRequest(
              data,
              onSuccess
            )
          );
          break;
        case 'collected_data':
          dispatch(IventoryActions.createCollectedDataRequest(data, onSuccess));
          break;
        case 'legal_basis_assessment':
          dispatch(
            IventoryActions.createLegalBasisAssessmentRequest(data, onSuccess)
          );
          break;
        case 'process_update_frequency':
          dispatch(
            IventoryActions.createProcessUpdateFrequencyRequest(data, onSuccess)
          );
          break;
        case 'in_out_transmission':
          dispatch(
            IventoryActions.createInOutTransmissionRequest(data, onSuccess)
          );
          break;
        case 'storage_forms':
          dispatch(IventoryActions.createStorageFormRequest(data, onSuccess));
          break;
        default:
          break;
      }
      // cadastrar
      // dispatch();
    }
  };

  return (
    <Modal
      title={editOption?.id ? `editar` : `cadastrar`}
      noPadding
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: editOption?.id ? editOption.name : '',
          description: editOption?.description ? editOption.description : '',
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextInput
                  label={i18next.t(
                    'configurations.departments.registrationModal.name'
                  )}
                  placeholder={i18next.t('common.typeIt')}
                  name="name"
                  value={values.name}
                  touched={touched.name}
                  error={errors.name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <WrapperButton>
              <PrimaryButton
                label={
                  isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
                }
                padding="0 5.8rem"
                width="null"
                onClick={handleSubmit}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateOptionModal;

CreateOptionModal.propTypes = {
  editOption: objectOf.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
};
