import React from 'react';
import { func, bool } from 'prop-types';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import { PrimaryButton } from '~/components/Button';

import {
  Container,
  WrapperButton,
  Title,
  Subtitle,
  TitleContainer,
} from './styles';

function SuccessModal({ onClose, open }) {
  function handleCloseModal() {
    window.location.reload();
    onClose();
  }

  return (
    <Modal title="" open={open} onClose={onClose}>
      <Container>
        <TitleContainer>
          <Title>
            {i18next.t('callOpening.demandOpeningSuccessModal.title')}
          </Title>
          <Subtitle>
            {i18next.t('callOpening.demandOpeningSuccessModal.subtitle')}
          </Subtitle>
        </TitleContainer>

        <WrapperButton>
          <PrimaryButton
            label={i18next.t('callOpening.demandOpeningSuccessModal.button')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleCloseModal()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default SuccessModal;

SuccessModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
};
