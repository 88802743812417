import { put, all, takeLatest, call, select } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import PeopleActions, { PeopleTypes } from '../ducks/people';

function* getPeople({ page, search = '' }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    let response;
    if (page) {
      response = yield call(
        api.get,
        `api/people/${userActive.activeCompany.id}?limit=${200}&page=${parseInt(
          page
        )}&search=${search}`
      );
    } else {
      response = yield call(
        api.get,
        `api/people/${userActive.activeCompany.id}`
      );
    }

    yield put(PeopleActions.getPeopleSuccess(response.data));
  } catch (error) {
    yield put(PeopleActions.getPeopleFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchPeople({ query }) {
  try {
    const response = yield call(api.get, `/users/?search=${query}`);

    yield put(PeopleActions.searchPeopleSuccess(response.data));
  } catch (error) {
    yield put(PeopleActions.searchPeopleFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getPersonDetails({ id }) {
  try {
    const response = yield call(api.get, `api/person/${id}`);
    yield put(PeopleActions.getPersonDetailsSuccess(response.data));
  } catch (error) {
    yield put(PeopleActions.getPersonDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createPerson({
  data: {
    companyId,
    role,
    firstName,
    lastName,
    email,
    department,
    office,
    phone,
    document,
    avatar,
    userSystem
  },
  reloadPeople,
}) {
  try {
    const formData = convertFormData({
      company_id: companyId,
      role_id: role,
      first_name: firstName,
      last_name: lastName,
      email,
      departaments: JSON.stringify(department),
      office,
      phone,
      document,
      person_avatar: avatar,
      userSystem: userSystem
    });

    // if (avatar) formData.append('avatar', avatar);

    yield call(api.post, '/api/person', formData);

    yield put(PeopleActions.createPersonSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário criado!',
      })
    );
    reloadPeople();
  } catch (error) {
    yield put(PeopleActions.createPersonFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação de usuário',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* inviteNewDPO({ name, email, reloadPeople }) {
  try {
    yield call(api.post, '/users/invite_dpo', {
      name,
      email,
    });

    yield put(PeopleActions.inviteNewDpoSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'DPO convidado!',
      })
    );
    reloadPeople();
  } catch (error) {
    yield put(PeopleActions.inviteNewDpoFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no envio do convite ao DPO',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* updatePerson({
  data: {
    id,
    companyId,
    role,
    firstName,
    lastName,
    email,
    department,
    office,
    phone,
    document,
    imageUser,
    userSystem
  },
  reloadTable,
}) {
  try {
    const formData = convertFormData({
      company_id: companyId,
      role_id: role,
      first_name: firstName,
      last_name: lastName,
      email,
      departament_id: department,
      office,
      phone,
      document,
      person_avatar: imageUser,
      userSystem: userSystem
    });

    yield call(api.put, `/api/person/${id}`, formData);

    yield put(PeopleActions.updatePersonSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário atualizado!',
      })
    );

    reloadTable();
  } catch (error) {
    yield put(PeopleActions.updatePersonFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização de usuário',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* deletePerson({ id, reloadPeople }) {
  try {
    yield call(api.delete, `/api/person/${id}`);

    yield put(PeopleActions.deletePersonSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário deletado!',
      })
    );
    reloadPeople();
  } catch (error) {
    yield put(PeopleActions.deletePersonFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao deletar usuário',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getPeopleEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `api/people/${userActive.activeCompany.id}`
    );

    const formatResponse = yield response.data.map((item) => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`,
    }));

    // const formattedAvatarUrls = response.data.map((person) => ({
    //   ...person,
    //   avatar: person.avatar
    //     ? `${
    //         process.env.NODE_ENV === 'development'
    //           ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
    //           : process.env.REACT_APP_API_PROD_ENDPOINT
    //       }users/avatar?avatar=${person.avatar}`
    //     : null,
    // }));

    yield put(PeopleActions.getPeopleEnumSuccess(formatResponse));
  } catch (error) {
    yield put(PeopleActions.getPeopleEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadPeople() {
  try {
    const response = yield call(api.get, `/users/download_xlsx`, {
      params: {
        user: ['id', 'name', 'phone', 'email'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Ativos-Pessoas.xlsx');

    yield put(PeopleActions.downloadPeopleSuccess());
  } catch (error) {
    yield put(PeopleActions.downloadPeopleFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadPeople({ data }) {
  try {
    yield call(api.post, '/users/upload_xlsx', data);
    yield put(PeopleActions.uploadPeopleSuccess());
  } catch (error) {
    yield put(PeopleActions.uploadPeopleFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no upload dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(PeopleTypes.GET_PEOPLE_REQUEST, getPeople),
  takeLatest(PeopleTypes.SEARCH_PEOPLE_REQUEST, searchPeople),
  takeLatest(PeopleTypes.GET_PERSON_DETAILS_REQUEST, getPersonDetails),
  takeLatest(PeopleTypes.CREATE_PERSON_REQUEST, createPerson),
  takeLatest(PeopleTypes.INVITE_NEW_DPO_REQUEST, inviteNewDPO),
  takeLatest(PeopleTypes.UPDATE_PERSON_REQUEST, updatePerson),
  takeLatest(PeopleTypes.DELETE_PERSON_REQUEST, deletePerson),
  takeLatest(PeopleTypes.GET_PEOPLE_ENUM_REQUEST, getPeopleEnum),
  takeLatest(PeopleTypes.DOWNLOAD_PEOPLE_REQUEST, downloadPeople),
  takeLatest(PeopleTypes.UPLOAD_PEOPLE_REQUEST, uploadPeople),
]);
