import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getProcessesRequest: ['page', 'limit', 'search'],
  getProcessesSuccess: ['processes'],
  getProcessesFailure: [''],

  searchProcessesRequest: ['query'],
  searchProcessesSuccess: ['results'],
  searchProcessesFailure: [''],

  createProcessRequest: ['data', 'handleGoBack'],
  createProcessSuccess: [''],
  createProcessFailure: [''],

  updateProcessRequest: ['data', 'id'],
  updateProcessSuccess: [''],
  updateProcessFailure: [''],

  deleteProcessRequest: ['id'],
  deleteProcessSuccess: ['id'],
  deleteProcessFailure: [''],

  getProcessDetailsRequest: ['id'],
  getProcessDetailsSuccess: ['process'],
  getProcessDetailsFailure: [''],

  getProcessesEnumRequest: [''],
  getProcessesEnumSuccess: ['processesEnum'],
  getProcessesEnumFailure: [''],

  downloadProcessesRequest: [''],
  downloadProcessesSuccess: [''],
  downloadProcessesFailure: [''],

  uploadProcessesRequest: ['data'],
  uploadProcessesSuccess: [''],
  uploadProcessesFailure: [''],

  setSidebarMenuDataRequest: ['data'],
});

export const ProcessTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  processes: {
    count: 0,
    rows: [],
  },
  sidebarMenuData: {},
  processDetails: {},
  processesEnum: [],
});

/* Reducers */

const getProcessesRequest = (state) => state.merge({ isLoading: true });
const getProcessesSuccess = (state, { processes }) =>
  state.merge({
    isLoading: false,
    error: false,
    processes,
  });
const getProcessesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    processes: {
      count: 0,
      rows: [],
    },
  });

const searchProcessesRequest = (state) => state.merge({ isLoading: true });
const searchProcessesSuccess = (state, { results }) =>
  state.merge({
    isLoading: false,
    error: false,
    processes: results,
  });
const searchProcessesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    processes: {
      count: 0,
      rows: [],
    },
  });

const createProcessRequest = (state) => state.merge({ isLoading: true });
const createProcessSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateProcessRequest = (state) => state.merge({ isLoading: true });
const updateProcessSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteProcessRequest = (state) => state.merge({ isLoading: true });
const deleteProcessSuccess = (state, { id }) => {
  const fitred = [...state.processes.rows.filter((item) => item.id !== id)];
  const countFormated = state.processes.count - 1;
  return state.merge({
    isLoading: false,
    error: false,
    processes: {
      ...state.processes,
      rows: fitred,
      count: countFormated,
    },
  });
};
const deleteProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getProcessDetailsRequest = (state) => state.merge({ isLoading: true });
const getProcessDetailsSuccess = (state, { process }) =>
  state.merge({
    isLoading: false,
    error: false,
    processDetails: process,
  });
const getProcessDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    processDetails: {},
  });

const getProcessesEnumRequest = (state) => state.merge({ isLoading: true });
const getProcessesEnumSuccess = (state, { processesEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    processesEnum,
  });
const getProcessesEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    processesEnum: [],
  });

const downloadProcessesRequest = (state) => state.merge({ isLoading: true });
const downloadProcessesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadProcessesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const uploadProcessesRequest = (state) => state.merge({ isLoading: true });
const uploadProcessesSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const uploadProcessesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const setSidebarMenuDataRequest = (state, { data }) =>
  state.merge({ sidebarMenuData: data });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROCESSES_REQUEST]: getProcessesRequest,
  [Types.GET_PROCESSES_SUCCESS]: getProcessesSuccess,
  [Types.GET_PROCESSES_FAILURE]: getProcessesFailure,

  [Types.SEARCH_PROCESSES_REQUEST]: searchProcessesRequest,
  [Types.SEARCH_PROCESSES_SUCCESS]: searchProcessesSuccess,
  [Types.SEARCH_PROCESSES_FAILURE]: searchProcessesFailure,

  [Types.CREATE_PROCESS_REQUEST]: createProcessRequest,
  [Types.CREATE_PROCESS_SUCCESS]: createProcessSuccess,
  [Types.CREATE_PROCESS_FAILURE]: createProcessFailure,

  [Types.UPDATE_PROCESS_REQUEST]: updateProcessRequest,
  [Types.UPDATE_PROCESS_SUCCESS]: updateProcessSuccess,
  [Types.UPDATE_PROCESS_FAILURE]: updateProcessFailure,

  [Types.DELETE_PROCESS_REQUEST]: deleteProcessRequest,
  [Types.DELETE_PROCESS_SUCCESS]: deleteProcessSuccess,
  [Types.DELETE_PROCESS_FAILURE]: deleteProcessFailure,

  [Types.GET_PROCESS_DETAILS_REQUEST]: getProcessDetailsRequest,
  [Types.GET_PROCESS_DETAILS_SUCCESS]: getProcessDetailsSuccess,
  [Types.GET_PROCESS_DETAILS_FAILURE]: getProcessDetailsFailure,

  [Types.GET_PROCESSES_ENUM_REQUEST]: getProcessesEnumRequest,
  [Types.GET_PROCESSES_ENUM_SUCCESS]: getProcessesEnumSuccess,
  [Types.GET_PROCESSES_ENUM_FAILURE]: getProcessesEnumFailure,

  [Types.DOWNLOAD_PROCESSES_REQUEST]: downloadProcessesRequest,
  [Types.DOWNLOAD_PROCESSES_SUCCESS]: downloadProcessesSuccess,
  [Types.DOWNLOAD_PROCESSES_FAILURE]: downloadProcessesFailure,

  [Types.UPLOAD_PROCESSES_REQUEST]: uploadProcessesRequest,
  [Types.UPLOAD_PROCESSES_SUCCESS]: uploadProcessesSuccess,
  [Types.UPLOAD_PROCESSES_FAILURE]: uploadProcessesFailure,

  [Types.SET_SIDEBAR_MENU_DATA_REQUEST]: setSidebarMenuDataRequest,
});
