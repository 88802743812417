import React from 'react';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import { SecondaryButton, PrimaryButton } from '~/components/Button';
import {
  Container,
  Title,
  Subtitle,
  Description,
  ButtonsArea,
  FailureIcon,
  IconArea,
} from './styles';

const SucessCancel = ({ open, onClose }) => {
  return (
    <Modal onClose={onClose} open={open} noPadding color="#fffff">
      <Container>
        <IconArea>
          <FailureIcon />
        </IconArea>
        <Title> {i18next.t('configurations.failurePay.title')}</Title>
        <Description>
          {i18next.t('configurations.failurePay.description')}
        </Description>
        <ButtonsArea>
          <SecondaryButton
            style={{ width: '50%', padding: 0, backgroundColor: 'white' }}
            label={i18next.t('configurations.failurePay.buttonTry')}
            onClick={onClose}
          />
        </ButtonsArea>
      </Container>
    </Modal>
  );
};

export default SucessCancel;
