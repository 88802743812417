import * as Yup from 'yup';
import { isValid as isValidCpf } from '@fnando/cpf';

export default Yup.object().shape({
  role: Yup.string().required('Campo obrigatório'),
  firstName: Yup.string().required('Campo obrigatório'),
  lastName: Yup.string().required('Campo obrigatório'),
  document: Yup.string()
    .required('Campo obrigatório')
    .test('cpf', 'CPF inválido', (cpf) => isValidCpf(cpf)),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  phone: Yup.string()
    .min(10, 'Telefone inválido')
    .required('Campo obrigatório'),
  department: Yup.string().required('Campo obrigatório'),
  office: Yup.string().required('Campo obrigatório'),
});
