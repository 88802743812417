import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { string, element } from 'prop-types';

function Permission({ action, children }) {
  const [allowed, setAllowed] = useState(false);
  const [role, setRole] = useState('');
  const { userActive, companyId } = useSelector((state) => state.auth);

  useEffect(() => {
    userActive.companies.map(
      (item) => item.company.id === companyId && setRole(item.role)
    );
  }, [companyId]);

  // ROLES
  // company
  // gedReviewer
  // treatmentAgent
  // dpo

  function hasPermission() {
    let permission = false;
    switch (true) {
      // OPERATIONAL DOCUMENTS
      case action === 'operationalDocument.download' && role === 'company':
      case action === 'operationalDocument.download' && role === 'gedReviewer':
        permission = true;
        break;
      case action === 'operationalDocument.upload' && role === 'company':
      case action === 'operationalDocument.upload' && role === 'gedReviewer':
        permission = true;
        break;
      case action === 'operationalDocument.review&aprove' && role === 'company':
      case action === 'operationalDocument.review&aprove' &&
        role === 'gedReviewer':
        permission = true;
        break;

      // DEMANDS
      case action === 'demandDocument.download' && role === 'company':
      case action === 'demandDocument.download' && role === 'treatmentAgent':
        permission = true;
        break;
      case action === 'demandDocument.upload' && role === 'company':
      case action === 'demandDocument.upload' && role === 'treatmentAgent':
      case action === 'demandDocument.upload' && role === 'dpo':
        permission = true;
        break;

      case action === 'demand.answer' && role === 'company':
      case action === 'demand.answer' && role === 'treatmentAgent':
      case action === 'demand.answer' && role === 'dpo':
        permission = true;
        break;

      // FOLDERS
      case action === 'folder.create' && role === 'company':
        permission = true;
        break;
      // case action === 'folder.delete' && role === 'company':
      //   permission = true;
      //   break;
      case action === 'folder.uploadDocument' && role === 'company':
        permission = true;
        break;
      case action === 'folder.moveDocument' && role === 'company':
        permission = true;
        break;
      case action === 'folder.deleteDocument' && role === 'company':
        permission = true;
        break;

      default:
        permission = false;
    }
    return permission;
  }

  useEffect(() => {
    if (role !== '') {
      if (hasPermission()) {
        setAllowed(true);
      } else {
        setAllowed(false);
      }
    }
  }, [role]);

  return <>{allowed ? children : null}</>;
}

export default Permission;

Permission.propTypes = {
  action: string.isRequired,
  children: element.isRequired,
};
