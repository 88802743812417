import { put, all, takeLatest, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import HoldersActions, { HoldersTypes } from '../ducks/holders';

function* loadHolders({ page, query }) {
  try {
    let response;
    if (page) {
      response = yield call(api.get, `/clients?page=${page}`);
    } else if (query) {
      response = yield call(api.get, `/clients?search=${query}`);
    } else {
      response = yield call(api.get, '/clients');
    }

    yield put(HoldersActions.getHoldersSuccess(response.data));
  } catch (error) {
    yield put(HoldersActions.getHoldersFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* loadLastTicket({ id }) {
  try {
    const response = yield call(api.get, `/clients/${id}/lastTicket`);

    yield put(HoldersActions.loadLastestTicketSuccess(response.data));
  } catch (error) {
    yield put(HoldersActions.loadLastestTicketFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* loadTickets({ id, page }) {
  try {
    let response;
    if (page) {
      response = yield call(api.get, `/clients/${id}/tickets?page=${page}`);
    } else {
      response = yield call(api.get, `/clients/${id}/tickets`);
    }

    yield put(HoldersActions.loadTicketsSuccess(response.data));
  } catch (error) {
    yield put(HoldersActions.loadTicketsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* setTicketPriority({ id, priority }) {
  try {
    yield call(api.put, `/tickets/${id}/priority`, {
      priority,
    });

    yield put(HoldersActions.setTicketPrioritySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Prioridade atualizada!',
      })
    );
  } catch (error) {
    yield put(HoldersActions.setTicketPriorityFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na definição de prioridade',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* setTicketDueDate({ id, dueDate }) {
  try {
    yield call(api.put, `/tickets/${id}/due_date`, {
      due_date: dueDate,
    });

    yield put(HoldersActions.setTicketDueDateSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Prazo atualizado!',
      })
    );
  } catch (error) {
    yield put(HoldersActions.setTicketDueDateFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na definição de data',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* reOpenTicket({ id, justification }) {
  try {
    yield call(api.put, `/tickets/${id}/reopen`, {
      justification,
    });

    yield put(HoldersActions.reOpenTicketSuccess());
    yield put(push(`/holders/demands/attendance-detail/${id}`));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Demanda reaberta!',
      })
    );
  } catch (error) {
    yield put(HoldersActions.reOpenTicketFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na reabertura da demanda',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* answerTicket({
  data: { id, subject, email, description, priority, dueDate, file },
}) {
  try {
    const answerData = convertFormData({
      subject,
      cc: email,
      description,
      priority,
      due_date: dueDate,
    });

    if (file) answerData.append('attachment', file);

    yield call(api.post, `/tickets/${id}/reply`, answerData);

    yield put(HoldersActions.answerTicketSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Tudo certo!',
        message: 'Resposta do atendimento efetuada com sucesso!',
      })
    );
  } catch (error) {
    yield put(HoldersActions.answerTicketFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na resposta ao atendimento',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* downloadHolders() {
  try {
    const response = yield call(api.get, `/clients/download_xlsx`, {
      params: {
        client: ['id', 'name', 'phone', 'createdAt', 'email'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Titulares.xlsx');

    yield put(HoldersActions.downloadHoldersSuccess());
  } catch (error) {
    yield put(HoldersActions.downloadHoldersFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(HoldersTypes.GET_HOLDERS_REQUEST, loadHolders),
  takeLatest(HoldersTypes.LOAD_LASTEST_TICKET_REQUEST, loadLastTicket),
  takeLatest(HoldersTypes.LOAD_TICKETS_REQUEST, loadTickets),
  takeLatest(HoldersTypes.RE_OPEN_TICKET_REQUEST, reOpenTicket),
  takeLatest(HoldersTypes.ANSWER_TICKET_REQUEST, answerTicket),
  takeLatest(HoldersTypes.SET_TICKET_PRIORITY_REQUEST, setTicketPriority),
  takeLatest(HoldersTypes.SET_TICKET_DUE_DATE_REQUEST, setTicketDueDate),
  takeLatest(HoldersTypes.DOWNLOAD_HOLDERS_REQUEST, downloadHolders),
]);
