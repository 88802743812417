import React from 'react';
import { bool } from 'prop-types';

function RisksIcon({ active }) {
  return active ? (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85794 1.33157L15.3095 10.7929C15.4295 11.0753 15.482 11.305 15.497 11.5435C15.527 12.1009 15.332 12.6427 14.9496 13.0596C14.5671 13.475 14.0497 13.7203 13.4873 13.75H2.50918C2.27672 13.7359 2.04426 13.6831 1.82679 13.6014C0.739476 13.1629 0.214565 11.9292 0.65699 10.8598L6.14606 1.32488C6.33353 0.989708 6.61848 0.700614 6.97093 0.51482C7.99075 -0.0507348 9.28804 0.320852 9.85794 1.33157ZM8.65064 7.56679C8.65064 7.92352 8.35819 8.22153 7.99825 8.22153C7.63831 8.22153 7.33836 7.92352 7.33836 7.56679V5.46436C7.33836 5.10689 7.63831 4.8178 7.99825 4.8178C8.35819 4.8178 8.65064 5.10689 8.65064 5.46436V7.56679ZM7.99825 10.7632C7.63831 10.7632 7.33836 10.4652 7.33836 10.1092C7.33836 9.75173 7.63831 9.45446 7.99825 9.45446C8.35819 9.45446 8.65064 9.74504 8.65064 10.101C8.65064 10.4652 8.35819 10.7632 7.99825 10.7632Z"
        fill="#4347A2"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4375 10.2023C7.4375 9.89175 7.6895 9.63525 8 9.63525C8.3105 9.63525 8.5625 9.8835 8.5625 10.194V10.2023C8.5625 10.5128 8.3105 10.7648 8 10.7648C7.6895 10.7648 7.4375 10.5128 7.4375 10.2023Z"
        fill="#606474"
      />
      <path
        d="M2.59651 13.3135H13.458C14.3183 13.2685 14.9798 12.5357 14.9355 11.6755C14.9258 11.5015 14.8883 11.3297 14.8223 11.1692L9.36451 1.61723C8.94751 0.863482 7.99876 0.590483 7.24501 1.00823C6.98851 1.14923 6.77701 1.36073 6.63526 1.61723L1.17751 11.1692C0.853514 11.9672 1.23751 12.8762 2.03476 13.201C2.19676 13.2662 2.36776 13.3037 2.54176 13.3135"
        stroke="#606474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99219 8.04717V5.72217"
        stroke="#606474"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RisksIcon;

RisksIcon.propTypes = {
  active: bool.isRequired,
};
