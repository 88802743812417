import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any } from 'prop-types';
import i18next from 'i18next';
import Modal from '~/components/Modal';
import EditFolderModal from '../../../EditFolderModal';
import DeleteActionModal from '../../../DeleteActionModal';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  CloseIcon,
  EditIcon,
} from './styles';

function ActionButton({ folder }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <MoreIcon onClick={handleClick} />
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Row onClick={() => setIsEditModalOpen((oldState) => !oldState)}>
          <WrapperIcon>
            <EditIcon />
          </WrapperIcon>
          Editar
        </Row>

        <Row onClick={() => setIsDeleteModalOpen((oldState) => !oldState)}>
          <WrapperIcon>
            <CloseIcon />
          </WrapperIcon>
          Excluir
        </Row>
      </Popover>
      <Modal
        title={i18next.t('documents.editFolderName')}
        open={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      >
        <EditFolderModal
          folder={folder}
          onClose={() => setIsEditModalOpen(false)}
        />
      </Modal>

      <DeleteActionModal
        folder={folder}
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  folder: objectOf(oneOfType([any])).isRequired,
};
