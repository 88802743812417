import React, { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { arrayOf, objectOf, oneOfType, any, func } from 'prop-types';

import {
  Container,
  Table,
  THead,
  HeadRow,
  Row,
  TH,
  TD,
  TBody,
  Sort,
  Sorted,
  SortedDesc,
} from './styles';

const TertiaryTable = ({ data, columns, onRowClick }) => {
  const dataMemo = useMemo(() => data, [data]);

  const columnsMemo = useMemo(() => columns, [columns]);

  const tableInstance = useTable(
    { columns: columnsMemo, data: dataMemo },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Container>
      <Table {...getTableProps()}>
        <THead>
          {headerGroups.map((headerGroup) => (
            <HeadRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TH {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <SortedDesc show={column.isSorted && column.isSortedDesc} />
                  <Sorted show={column.isSorted && !column.isSortedDesc} />
                  {!column.isSorted && !column.disableSortBy && <Sort />}
                </TH>
              ))}
            </HeadRow>
          ))}
        </THead>
        <TBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Row
                {...row.getRowProps()}
                onClick={() => onRowClick(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>
                  );
                })}
              </Row>
            );
          })}
        </TBody>
      </Table>
    </Container>
  );
};

export default TertiaryTable;

TertiaryTable.propTypes = {
  columns: arrayOf(objectOf(oneOfType([any]))).isRequired,
  data: arrayOf(objectOf(oneOfType([any]))).isRequired,
  onRowClick: func,
};

TertiaryTable.defaultProps = {
  onRowClick: () => {},
};
