import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

import NotFound from '~/pages/NotFound';
import CallOpening from '~/pages/CallOpeningManagement/CallOpening';

// ** Guest Routes
import SignIn from '~/pages/SignIn';
import SignInClient from '~/pages/SignInClient';
import SignInCompanySelect from '~/pages/SignInCompanySelect';
import SignUp from '~/pages/SignUp/Main';
import SignUpCompany from '~/pages/SignUp/Company';
import SignUpUser from '~/pages/SignUp/User';
import SignUpDPO from '~/pages/SignUp/DPO';
import SignUpClient from '~/pages/SignUp/Client';

// ** Client Routes
import DemandsHistory from '~/pages/CallOpeningManagement/DemandsHistory';
import ClientDemandDetails from '~/pages/CallOpeningManagement/DemandDetails';
import ClientDemandInteractionDetails from '~/pages/CallOpeningManagement/InteractionDetails';
import CreateClientDemandInteraction from '~/pages/CallOpeningManagement/NewInteraction';

// ** Private Routes
import Dashboard from '~/pages/Dashboard';
import DemandManagement from '~/pages/DemandManagement/Demands';
import ServiceDetails from '~/pages/DemandManagement/ServiceDetails';
import ServiceResponse from '~/pages/DemandManagement/ServiceResponse';
import Chat from '~/pages/DemandManagement/Chat';
import DocumentFolders from '~/pages/DocumentsManagement/Documents/Folders';
import FolderDetails from '~/pages/DocumentsManagement/Documents/FolderDetails';
import DocumentDetails from '~/pages/DocumentsManagement/Documents/DocumentDetails';
import DocumentReview from '~/pages/DocumentsManagement/DocumentReview/Documents';
import DocumentReviewDetails from '~/pages/DocumentsManagement/DocumentReview/DocumentDetails';
import RisksManagement from '~/pages/RisksManagement/Risks';
import RisksManagementDetail from '~/pages/RisksManagement/Detail';
import BusinessUnits from '~/pages/AssetsManagement/BusinessUnits/Main';
import BusinessUnitsDetails, { BusinessUnitsLayout } from '~/pages/AssetsManagement/BusinessUnits/Details';
import Actives from '~/pages/AssetsManagement/Actives/Main';
import ActivesDetails, { ActivesLayout } from '~/pages/AssetsManagement/Actives/Details';
import BusinessProcess from '~/pages/AssetsManagement/BusinessProcess/Main';
import BusinessProcessDetails, { BusinessProcessLayout } from '~/pages/AssetsManagement/BusinessProcess/Details';
import Holders from '~/pages/Holders';
import Collaborators from '~/pages/Configs/Collaborators';
import RegisteredEmails from '~/pages/Configs/RegisteredEmails';
import Company from '~/pages/Configurations/Company';
import User from '~/pages/Configurations/User';
import ForgotPassword from '~/pages/ForgotPassword';
import People from '~/pages/AssetsManagement/People';
import DemandDetails from '~/pages/Holders/DemandDetails';
import AttendanceDetails from '~/pages/Holders/AttendanceDetails';
import ConfirmEmailReceipt from '~/pages/ConfirmEmailReceipt';
import UserPanel from '~/pages/UserPanel';
import CheckoutStep1 from '~/pages/UserPanel/CheckoutStep1';
import CheckoutPay from '~/pages/UserPanel/CheckoutPay';
import PanelLayout from '~/layouts/PanelLayout';
import CheckoutStep3 from '~/pages/UserPanel/CheckoutStep3';
import CompanyUsers from '~/pages/Configurations/CompanyUsers/Main';
import Signature from '~/pages/Configurations/Signature/Main';
import Departaments from '~/pages/Configurations/Departaments';
import IventoryRecords from '~/pages/Iventory/IventoryRecords';
import SelectOptions from '~/pages/Iventory/SelectOptions';
import ThirdPartyManagement from '~/pages/ThirdPartyManagement';

// ** Routes
import Client from '~/routes/client';
import Guest from '~/routes/guest';
import Private from '~/routes/private';
import history from '~/routes/history';

const Routes = () => {
  const { userSignedIn, clientSignedIn } = useSelector((state) => state.auth);

  return (
    <ConnectedRouter history={history}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          <Switch>
            <Route component={NotFound} exact path="/not-found" />
            <Route component={CallOpening} exact path="/call-opening" />

            <Guest component={SignIn} exact path="/" />
            <Guest component={SignInClient} exact path="/login" />
            {/* <Guest component={SignUp} exact path="/sign-up" /> */}
            <Guest component={SignUpUser} exact path="/sign-up" />
            <Guest component={SignUpDPO} exact path="/sign-up/dpo" />
            <Guest component={SignUpClient} exact path="/sign-up/client" />
            <Guest component={ConfirmEmailReceipt} exact path="/confirm-email" />
            <Guest component={ForgotPassword} exact path="/forgot-password" />

            {clientSignedIn && (
              <>
                <Client component={DemandsHistory} exact noDrawer path="/demands-history" />
                <Client component={ClientDemandDetails} exact noDrawer path="/demands-history/details/:id" />
                <Client component={ClientDemandInteractionDetails} exact noDrawer path="/demands-history/interaction/:id" />
                <Client component={CreateClientDemandInteraction} exact noDrawer path="/demands-details/new-interaction" />
              </>
            )}

            {userSignedIn && (
              <>
                <Private component={SignInCompanySelect} exact path="/sign-in/company-select" />
                <Private component={UserPanel} exact panel path="/panel/:inviteToken?" CustomLayout={PanelLayout} />
                <Private component={ThirdPartyManagement} exact path="/third-party-risks-management" />
                <Private component={CheckoutStep1} exact checkout path="/checkout" CustomLayout={PanelLayout} />
                <Private component={CheckoutPay} exact path="/pay-checkout/:result?" CustomLayout={PanelLayout} />
                <Private component={CheckoutStep3} exact path="/finish-checkout" CustomLayout={PanelLayout} />
                <Private component={Dashboard} exact path="/dashboard" />
                <Private component={DemandManagement} exact path="/demand-management" />
                <Private component={ServiceDetails} exact path="/service-details/:id" />
                <Private component={ServiceResponse} backButton exact path="/service-response/:id" />
                <Private component={Chat} backButton exact path="/chat" />
                <Private component={DocumentFolders} exact path="/document-management/documents/folders" />
                <Private component={FolderDetails} exact path="/document-management/documents/folder-details/:id" />
                <Private component={DocumentDetails} exact path="/document-management/documents/document-details/:id" />
                <Private component={DocumentReview} exact path="/document-management/review" />
                <Private component={DocumentReviewDetails} exact path="/document-management/review/:id" />
                <Private component={RisksManagement} exact path="/risks-management" />
                <Private component={RisksManagementDetail} exact path="/risks-management/:id" />
                <Private component={People} exact path="/assets-management/people" />
                <Private component={BusinessUnits} exact path="/assets-management/business-units" />
                <Private component={BusinessUnitsDetails} backButton noDrawer exact CustomLayout={BusinessUnitsLayout} path="/assets-management/business-units/new" />
                <Private component={BusinessUnitsDetails} backButton noDrawer exact CustomLayout={BusinessUnitsLayout} path="/assets-management/business-units/:id" />
                <Private component={Actives} exact path="/assets-management/actives" />
                <Private component={ActivesDetails} backButton noDrawer exact CustomLayout={ActivesLayout} path="/assets-management/actives/:id" />
                <Private component={BusinessProcess} exact path="/assets-management/business-process" />
                <Private component={BusinessProcessDetails} backButton noDrawer exact CustomLayout={BusinessProcessLayout} path="/assets-management/business-process/:id" />
                <Private component={BusinessProcessDetails} backButton noDrawer exact CustomLayout={BusinessProcessLayout} path="/assets-management/business-process/:new" />
                <Private component={Holders} exact path="/holders" />
                <Private component={DemandDetails} exact path="/holders/demands/:id" />
                <Private component={AttendanceDetails} exact path="/holders/demands/attendance-detail/:id" />
                <Private component={IventoryRecords} exact path="/iventory/records" />
                <Private component={SelectOptions} exact path="/iventory/:iventoryOption" />
                <Private component={Company} exact path="/configs/company" />
                <Private CustomLayout={PanelLayout} component={User} exact noInfo path="/configs/user" />
                <Private component={Departaments} exact noInfo path="/configs/departments" />
                <Private component={CompanyUsers} exact noInfo path="/enterprise/user" />
                <Private component={Signature} exact noInfo path="/config/signature" />
                {/* <Private component={Collaborators} exact path="/configs/collaborators" />
                <Private component={RegisteredEmails} exact path="/configs/emails" /> */}
              </>
            )}

            <Redirect to="/not-found" />
          </Switch>
        </AnimatePresence>
      </AnimateSharedLayout>
    </ConnectedRouter>
  );
};

export default Routes;
