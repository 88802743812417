import styled from 'styled-components';

export const Container = styled.div``;

export const UserImage = styled.img`
  object-fit: cover;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;

  @media (max-width: 670px) {
    margin-right: 5px;
  }
`;
