import { AiFillPlusCircle } from 'react-icons/ai';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const WrapperFatherOption = styled.div`
  background: ${({ theme }) => theme.colors.base[200]};
  border-radius: 5px;
  padding: 0.45rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;
  gap: 0.7rem;
  z-index: 2;
  cursor: pointer;
`;

export const CheckFatterWrapper = styled.div`
  position: relative;
`;

export const LabelCheck = styled.label`
  background: ${({ active }) => (active ? '#4347a2' : '#606474')};
  border-radius: 0.1rem;
  cursor: pointer;
  height: 0.8rem;
  width: 0.8rem;
  left: 0px;
  top: 0.3rem;
  position: absolute;
`;

export const FatherCheck = styled.input`
  height: 0.8rem;
  width: 0.8rem;
  visibility: hidden;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  font-weight: 400;
`;

export const WrapperChildrenOptions = styled.div`
  transition: 200ms ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;

  ${({ open, length }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
      height: calc((${length} * 4rem) + 4rem);
    `}
`;

export const NewOptionButton = styled.div`
  background: ${({ theme }) => theme.colors.base[100]};
  border: 1px solid #4347a2;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4.8rem;
  margin: 0.3rem 0 0 4.8rem;
  padding: 0.45rem;
  cursor: pointer;
  gap: 1.85rem;

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;

  color: ${({ theme }) => theme.colors.base[400]};
  transition: transform 300ms;

  :hover {
    box-shadow: 0px 4px 10px rgba(83, 154, 218, 0.4);
    transform: scale(1.002);
  }
`;

export const IconPlus = styled(AiFillPlusCircle).attrs({
  size: 15,
  color: '#4347a2',
})``;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7rem;
`;
