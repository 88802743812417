import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, css } from 'styled-components';

const theme = {
  notSelectable: css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
  clickable: css`
    cursor: pointer;

    :hover {
      transform: scale(1.03);
      box-shadow: 0 0 30px #00000006;
    }
    transition: 200ms ease;
  `,
  colors: {
    base: {
      100: '#FFFFFF',
      200: '#f6f6f6',
      250: '#f4f4f8',
      300: '#C6CCD2',
      350: '#EDF0F0',
      400: '#606474',
      500: '#262A3A',
    },
    primary: {
      100: '#FEC56C',
      200: '#FD922D',
      300: '#FD5D40',
      400: '#E52849',
    },
    white: '#fff',
    yellow: '#FFBB38',
    blue: '#4347A2',
    blueSecondary: '#539ADA',
    green: '#5CEEA7',
    red: '#e74c3c',
    pink: '#FDB7C2',
    offWhite: '#F8F4EA',
    cardBackground: '#F7F7F7',
    cardText: '#262A3A',
  },
  fonts: {
    Poppins: "'Poppins', sans-serif",
    Roboto: "'Roboto', sans-serif",
    SF: "'SF Pro Text', sans-serif",
  },
  fontSizes: {
    text: {
      sm: '1.4rem',
      md: '1.6rem',
      lg: '1.7rem',
      xl: '2.6rem',
    },
    heading: {
      sm: '1.6rem',
      md: '1.7rem',
      lg: '2.6rem',
      xl: '4rem',
    },
    button: {
      sm: '1.3rem',
      md: '1.8rem',
    },
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    bold: 700,
    heavy: 900,
    400: 400,
    600: 600,
    500: 500,
    700: 700,
    900: 900,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Theme;
