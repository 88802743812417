import { AiOutlineEdit } from 'react-icons/ai';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3.3rem;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-right: 1.5rem;
`;

export const Label = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const ID = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const NoData = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: #322e39;
  margin-top: 4rem;
  text-align: center;
`;

export const IconEdit = styled(AiOutlineEdit).attrs({ size: 18 })``;
