import styled, { css } from 'styled-components';
import { IconClip } from '~/assets/svgs';

export const Container = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
  padding-bottom: ${({ error }) => (error ? 2.5 : 0)}rem;
  transition: all 300ms ease;
`;

export const Label = styled.div`
  font-size: ${({ sm }) => (sm ? 1.2 : 1.4)}rem;
  color: ${({ theme, sm }) =>
    sm ? theme.colors.base[400] : theme.colors.base[500]};
  line-height: 2.1rem;
  font-weight: ${({ sm }) => (sm ? 500 : 400)};
  margin-bottom: 1.6rem;
  ${({ oneLineLabel }) =>
    oneLineLabel &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4rem;
    `};

  width: 100%;
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;

export const GrayWrapper = styled.div`
  height: 6rem;
  background-color: #f7f7f7;
  border-radius: 1rem;
  display: flex;
  padding: 0.5rem 1.7rem;
  align-items: center;
  cursor: pointer;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      box-shadow: inset 11px 11px 22px #ededed, inset -11px -11px 22px #ffffff;
      transform: scale(0.99);
      cursor: drop;
    `};

  transition: 200ms ease;
`;

export const Placeholder = styled.div`
  flex: 1;
  font-size: 1.4rem;
  color: #c6ccd2;
  font-weight: 400;
  margin-right: 1rem;
`;

export const FileIcon = styled(IconClip)`
  fill: #606474;
`;

export const FileName = styled.div`
  flex: 1;
  font-size: 1.4rem;
  color: #606474;
  font-weight: 500;
  margin-right: 1rem;
`;

export const FileSize = styled.div`
  flex: 1;
  font-size: 1rem;
  color: #c6ccd2;
  font-weight: 400;
`;
