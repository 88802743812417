import styled from 'styled-components';

export const Container = styled.div`
  height: 3.9rem;
  padding: 2.2rem;

  border-radius: 0.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
  cursor: pointer;

  :hover {
    box-shadow: 0px 4px 10px rgba(83, 154, 218, 0.4);
    transform: scale(1.007);
  }

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.base[400] : theme.colors.blueSecondary};

  transition: 300ms ease;
`;

export const WrapperIcon = styled.div`
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
