import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import api from '~/services/api';
import translateError from '~/utils/translateError';
import { getNumbers } from '~/utils/formatters';
import ConfigActions, { ConfigTypes } from '../ducks/config';

function* getUsers({ page }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    const response = yield call(
      api.get,
      `/api/company/users/${userActive.activeCompany.id}?page=${page}`
    );

    yield put(ConfigActions.getUsersCompanySuccess(response.data.data));
  } catch (error) {
    yield put(ConfigActions.getUsersCompanyFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getSignature({ companyId, page, limit }) {
  try {
    const response = yield call(
      api.get,
      `/api/company/transactions/${companyId}?page=${page}&limit=${limit}`
    );

    yield put(ConfigActions.getCompanySignatureSuccess(response.data.data));
  } catch (error) {
    yield put(ConfigActions.getCompanySignatureFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchUsers({ query }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    const response = yield call(
      api.get,
      `/api/company/users/${userActive.activeCompany.id}?search=${query}`
    );

    yield put(ConfigActions.getUsersCompanySuccess(response.data.data));
  } catch (error) {
    yield put(ConfigActions.getUsersCompanyFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getUsersDetails({ id, setOpenEditModal }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/company/users?company_id=${userActive.activeCompany.id}&id=${id}`
    );

    yield put(ConfigActions.getUsersDetailsSuccess(response.data.data));
    setOpenEditModal(true);
  } catch (error) {
    yield put(ConfigActions.getUsersDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateUser({ data, onSuccess }) {
  try {
    yield call(api.put, '/api/admin/edit-user', data);

    yield put(ConfigActions.updateUserSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário atualizado!',
      })
    );
  } catch (error) {
    yield put(ConfigActions.updateUserFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização de usuário',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  } finally {
    onSuccess();
  }
}

function* updateCompData({ logo, data, updateCompanyData }) {
  try {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('company_id', data.companyId);
    formData.append('fantasy_name', data.fantasy_name);
    formData.append('email', data.email);
    formData.append('primary_color', data.primaryColor);
    formData.append('cnpj', getNumbers(data.cnpj));
    formData.append('phone', data.phone);

    if (logo && typeof logo !== 'string') {
      formData.append('logo', logo);
    }

    if (data.bylaw && typeof data.bylaw !== 'string') {
      formData.append('bylaw', data.bylaw);
    }

    if (
      data.last_contractual_alteration &&
      typeof data.last_contractual_alteration !== 'string'
    ) {
      formData.append(
        'last_contractual_alteration',
        data.last_contractual_alteration
      );
    }
    if (data.address_proof && typeof data.address_proof !== 'string')
      formData.append('address_proof', data.address_proof);

    const response = yield call(
      api.put,
      '/api/company/update-details',
      formData
    );

    yield put(ConfigActions.updateCompanyDataSuccess(response.data.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Atualização realizada com sucesso.',
      })
    );
    updateCompanyData(response.data.data);
  } catch (error) {
    yield put(ConfigActions.updateCompanyDataFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    }
    // else {
    // yield put(
    // toastrActions.add({
    // type: 'error',
    // title: 'Falha na atualização dos dados',
    // message: translateError(`APIerrors.${error.response.data.errorCode}`),
    // })
    // );
    // }
  }
}

function* updateResponsibleData({ data, updateRespData }) {
  try {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('name', data.name);
    formData.append('cpf', data.cpf);
    formData.append('email', data.email);
    formData.append('office', data.office);
    formData.append('phone', data.phone);

    if (data.id_document && typeof data.id_document !== 'string') {
      formData.append('id_document', data.id_document);
    }

    if (data.cpf_document && typeof data.cpf_document !== 'string') {
      formData.append('cpf_document', data.cpf_document);
    }

    if (data.address_proof && typeof data.address_proof !== 'string') {
      formData.append('address_proof', data.address_proof);
    }

    if (
      data.letter_of_attorney &&
      typeof data.letter_of_attorney !== 'string'
    ) {
      formData.append('letter_of_attorney', data.letter_of_attorney);
    }

    yield call(api.put, '/users/company/legal-responsible', formData);

    yield put(ConfigActions.updateResponsibleDataSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Atualização realizada com sucesso.',
      })
    );

    updateRespData();
  } catch (error) {
    yield put(ConfigActions.updateResponsibleDataFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    }
    // else {
    // yield put(
    // toastrActions.add({
    // type: 'error',
    // title: 'Falha na atualização dos dados',
    // message: translateError(`APIerrors.${error.response.data.errorCode}`),
    // })
    // );
    // }
  }
}

function* getCompanyData({ id }) {
  try {
    const response = yield call(api.get, `/api/company-details/${id}`);

    yield put(ConfigActions.getCompanyDataSuccess(response.data.data));
  } catch (error) {
    yield put(ConfigActions.getCompanyDataFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getTypesRoles() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/admin/roles/${userActive.activeCompany.id}`
    );

    yield put(ConfigActions.getRolesSuccess(response.data.data));
  } catch (error) {
    yield put(ConfigActions.getRolesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateUserAvatar({ avatar, id, updateUser }) {
  try {
    const formData = new FormData();
    if (avatar) formData.append('avatar', avatar);

    const response = yield call(api.put, `/users/${id}`, formData);

    yield put(ConfigActions.updateUserAvatarSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Foto de perfil atualizada!',
      })
    );

    updateUser(response.data);
  } catch (error) {
    yield put(ConfigActions.updateUserAvatarFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha na atualização da foto de perfil',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createUser({ data, onSuccess }) {
  try {
    yield call(api.post, `/api/company/user-invite`, data);

    yield put(ConfigActions.createCompanyUserSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário cadastrado com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ConfigActions.createCompanyUserFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro a cadastrar usuário.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createCategory({ data, onSuccess }) {
  try {
    const response = yield call(api.post, `/api/departament/category`, data);

    yield put(ConfigActions.departamentCategorySuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Categoria cadastrada com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ConfigActions.departamentCategoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar categoria.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createDepartament({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    const formatedData = { ...data, company_id: userActive.activeCompany.id };

    const response = yield call(api.post, `/api/departament`, formatedData);

    yield put(ConfigActions.createDepartamentSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Departamento criado com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ConfigActions.createDepartamentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar um novo departamento.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createDepartamentProcess({ departamentId, data }) {
  try {
    const response = yield call(
      api.post,
      `/api/departament/process/${departamentId}`,
      data
    );

    yield put(ConfigActions.createDepartamentProcessSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Processo criado com sucesso!',
      })
    );
  } catch (error) {
    yield put(ConfigActions.createDepartamentProcessFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao cadastrar um novo processo.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateDepartament({ id, data, onSuccess }) {
  try {
    const response = yield call(api.put, `api/departament/${id}`, data);

    yield put(ConfigActions.updateDepartamentSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Departamento atualizado com sucesso!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(ConfigActions.updateDepartamentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar um novo departamento.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateDepartamentProcess({ id, data }) {
  try {
    const response = yield call(
      api.put,
      `/api/departament/process-update/${id}`,
      data
    );

    yield put(ConfigActions.updateDepartamentProcessSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Departamento atualizado com sucesso!',
      })
    );
  } catch (error) {
    yield put(ConfigActions.updateDepartamentProcessFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao atualizar o processo.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteDepartament({ id }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    yield call(
      api.delete,
      `/api/departament/${userActive.activeCompany.id}/${id}`
    );

    yield put(ConfigActions.deleteDepartamentSuccess(id));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Departamento excluído com sucesso!',
      })
    );
  } catch (error) {
    yield put(ConfigActions.deleteDepartamentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao tentar excluir um departamento.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteDepartamentProcess({ id }) {
  try {
    yield call(api.delete, `api/departament/process/delete/${id}`);

    yield put(ConfigActions.deleteDepartamentProcessSuccess(id));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Processo excluído com sucesso!',
      })
    );
  } catch (error) {
    yield put(ConfigActions.deleteDepartamentProcessFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro ao tentar excluir o processo.',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCategory({ companyId }) {
  try {
    const response = yield call(
      api.get,
      `/api/departament/categories/${companyId}`
    );

    yield put(ConfigActions.getDepartamentCategorySuccess(response.data));
  } catch (error) {
    yield put(ConfigActions.getRolesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getDepartaments() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/departaments/${userActive.activeCompany.id}`
    );

    yield put(ConfigActions.getDepartamentsSuccess(response.data));
  } catch (error) {
    yield put(ConfigActions.getDepartamentsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getDepartamentDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/departament/${id}`);

    yield put(ConfigActions.getDepartamentDetailsSuccess(response.data));
  } catch (error) {
    yield put(ConfigActions.getDepartamentDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getProcessDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/departament/process/${id}`);

    yield put(ConfigActions.getDepartamentProcessDetailsSuccess(response.data));
  } catch (error) {
    yield put(ConfigActions.getDepartamentProcessDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* updateStatusUser({ data, reloadTable }) {
  try {
    yield call(api.put, '/api/admin/handle-user-status', data);

    yield put(ConfigActions.updateUserStatusSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Usuário atualizado!',
      })
    );
    reloadTable();
  } catch (error) {
    yield put(ConfigActions.updateUserStatusFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na atualização de usuário',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getDepartamentsEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/departaments/${userActive.activeCompany.id}`
    );

    const formatResponse = yield response.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    yield put(ConfigActions.getDepartamentsEnumSuccess(formatResponse));
  } catch (error) {
    yield put(ConfigActions.getDepartamentsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getInvitationsSent({ page }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    const response = yield call(
      api.get,
      `api/admin/invites/${
        userActive?.activeCompany.id
      }?page=${page}&limit=${4}`
    );

    yield put(
      ConfigActions.getInvitationHistorySuccess(response?.data.invites)
    );
  } catch (error) {
    yield put(ConfigActions.getInvitationHistoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(ConfigTypes.UPDATE_COMPANY_DATA_REQUEST, updateCompData),
  takeLatest(
    ConfigTypes.UPDATE_RESPONSIBLE_DATA_REQUEST,
    updateResponsibleData
  ),
  takeLatest(ConfigTypes.GET_COMPANY_DATA_REQUEST, getCompanyData),

  takeLatest(ConfigTypes.UPDATE_USER_AVATAR_REQUEST, updateUserAvatar),

  takeLatest(ConfigTypes.CREATE_COMPANY_USER_REQUEST, createUser),

  takeLatest(ConfigTypes.GET_USERS_COMPANY_REQUEST, getUsers),

  takeLatest(ConfigTypes.SEARCH_USERS_COMPANY_REQUEST, searchUsers),

  takeLatest(ConfigTypes.GET_USERS_DETAILS_REQUEST, getUsersDetails),

  takeLatest(ConfigTypes.UPDATE_USER_REQUEST, updateUser),

  takeLatest(ConfigTypes.GET_COMPANY_SIGNATURE_REQUEST, getSignature),

  takeLatest(ConfigTypes.UPDATE_USER_STATUS_REQUEST, updateStatusUser),

  takeLatest(ConfigTypes.GET_ROLES_REQUEST, getTypesRoles),

  takeLatest(ConfigTypes.DEPARTAMENT_CATEGORY_REQUEST, createCategory),

  takeLatest(ConfigTypes.GET_DEPARTAMENT_CATEGORY_REQUEST, getCategory),

  takeLatest(ConfigTypes.CREATE_DEPARTAMENT_REQUEST, createDepartament),

  takeLatest(
    ConfigTypes.CREATE_DEPARTAMENT_PROCESS_REQUEST,
    createDepartamentProcess
  ),

  takeLatest(ConfigTypes.UPDATE_DEPARTAMENT_REQUEST, updateDepartament),

  takeLatest(
    ConfigTypes.UPDATE_DEPARTAMENT_PROCESS_REQUEST,
    updateDepartamentProcess
  ),

  takeLatest(ConfigTypes.DELETE_DEPARTAMENT_REQUEST, deleteDepartament),

  takeLatest(ConfigTypes.GET_DEPARTAMENTS_REQUEST, getDepartaments),

  takeLatest(
    ConfigTypes.GET_DEPARTAMENT_DETAILS_REQUEST,
    getDepartamentDetails
  ),

  takeLatest(
    ConfigTypes.GET_DEPARTAMENT_PROCESS_DETAILS_REQUEST,
    getProcessDetails
  ),

  takeLatest(
    ConfigTypes.DELETE_DEPARTAMENT_PROCESS_REQUEST,
    deleteDepartamentProcess
  ),

  takeLatest(ConfigTypes.GET_INVITATION_HISTORY_REQUEST, getInvitationsSent),

  takeLatest(ConfigTypes.GET_DEPARTAMENTS_ENUM_REQUEST, getDepartamentsEnum),
]);
