import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HoldersActions from '~/store/ducks/holders';

import DatePicker from '~/components/DatePicker';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { PrimaryButton } from '~/components/Button';
import { TertiarySelect } from '~/components/Select';
import DateInput from './components/DateInput';
import AttachFile from './components/AttachFile';

import schema from './validationSchema';

import {
  Container,
  Header,
  Title,
  Text,
  HeaderRight,
  WrapperSelect,
  WrapperSelect2,
  WrapperButton,
  WrapperForm,
  WrapperCard,
  DeadlinePlaceholder,
  DeadlinePlaceholderWrapper,
} from './styles';

const AttendanceDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [protocol, setProtocol] = useState('');
  const [answerFile, setAnswerFile] = useState(null);
  const [priority, setPriority] = useState(null);
  const [dueDate, setDueDate] = useState(null);

  const id = history.location.pathname.replace(
    '/holders/demands/attendance-detail/',
    ''
  );

  useEffect(() => {
    setProtocol(
      history.location.pathname.replace(
        '/holders/demands/attendance-detail/',
        ''
      )
    );
  }, []);

  const submit = (values) => {
    const data = {
      id,
      subject: values.subject,
      email: values.email,
      description: values.description,
      priority,
      dueDate,
      file: answerFile,
    };
    dispatch(HoldersActions.answerTicketRequest(data));
    history.goBack();
  };

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>
            <Title>Resposta do atendimento</Title>
          </Header>
        </Grid>
        <Formik
          enableReinitialize
          initialValues={{
            subject: '',
            email: '',
            description: '',
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <Grid container spacing={5}>
              <Grid item md={9} xs={12}>
                <WrapperForm>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Header>
                        <Text>#{protocol}</Text>
                        <HeaderRight>
                          <WrapperButton>
                            <PrimaryButton
                              label="Enviar"
                              padding="0 5.8rem"
                              width="null"
                              onClick={handleSubmit}
                            />
                          </WrapperButton>
                        </HeaderRight>
                      </Header>
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Assunto"
                        placeholder="Insira o assunto da sua mensagem"
                        name="subject"
                        value={values.subject}
                        touched={touched.subject}
                        error={errors.subject}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Copiar"
                        placeholder="Ex.: meuemail@email.com.br"
                        name="email"
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextArea
                        label="Descrição"
                        placeholder="Insira a descrição aqui"
                        name="description"
                        value={values.description}
                        touched={touched.description}
                        error={errors.description}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </WrapperForm>
              </Grid>
              <Grid item md={3} xs={12}>
                <HeaderRight>
                  <WrapperSelect>
                    <TertiarySelect
                      options={[
                        { label: 'Alta', value: 'high' },
                        { label: 'Média', value: 'medium' },
                        { label: 'Baixa', value: 'low' },
                      ]}
                      value={priority}
                      onChange={setPriority}
                      placeholder="Prioridade"
                    />
                  </WrapperSelect>
                  <WrapperSelect2>
                    <DatePicker
                      value={dueDate}
                      placeholder="DD/MM/AA"
                      name="dueDate"
                      onChange={(e) => setDueDate(e)}
                      formatMask="dd/MM/yy"
                      shouldHaveMinDate
                    >
                      {dueDate ? (
                        <DateInput
                          placeholder="DD/MM/AA"
                          error={errors.dueDate}
                          touched={touched.dueDate}
                        />
                      ) : (
                        <DeadlinePlaceholderWrapper>
                          <DeadlinePlaceholder>Prazo</DeadlinePlaceholder>
                        </DeadlinePlaceholderWrapper>
                      )}
                    </DatePicker>
                  </WrapperSelect2>
                </HeaderRight>
                <WrapperCard height="25rem">
                  <AttachFile setAnswerFile={setAnswerFile} />
                </WrapperCard>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Grid>
    </Container>
  );
};

export default AttendanceDetails;
