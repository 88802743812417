import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { useDispatch, useSelector } from 'react-redux';
import { func, string, bool } from 'prop-types';
import { format } from 'date-fns';
import convertRisk from '~/utils/convertRisk';
import TextArea from '~/components/TextArea';
import DatePicker from '~/components/DatePicker';
import AddButton from '~/components/Button/AddButton';
import { SecondarySelect } from '~/components/Select';
import TextInput from '~/components/TextInput';
import FileInput from '~/components/FileInput';
import CheckBox from '~/components/CheckBox';
import { Column, Row } from '~/styles/components';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import RiskActions from '~/store/ducks/risks';

import validationSchema from './validationSchema';
import ResidualRiskSession from '../ResidualRiskSession';

import {
  Form,
  BottomRow,
  WrapperInput,
  WrapperMath,
  MultiplyIcon,
  EqualsIcon,
  Label,
  WrapperResult,
  RiskMitigationTitle,
} from './styles';

const riskValues = {
  insignificant: 1,
  possible: 2,
  moderate: 3,
  high: 4,
  catastrophic: 5,
};

const detectionValues = {
  very_low: 1,
  low: 2,
  medium: 3,
  high: 4,
  very_high: 5,
};
function EvaluationModal({ onClose, id, newTreatment, setNewTreatment }) {
  const { riskTreatmentDetails } = useSelector((state) => state.risks);

  const [residualRisk, setResidualRisk] = useState(
    !newTreatment && riskTreatmentDetails?.residual_risk
      ? riskTreatmentDetails.residual_risk
      : [
          {
            description: '',
            evidence: null,
            business_units: [],
            processes: [],
            assets: [],
            users: [],
          },
        ]
  );
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    if (id) {
      dispatch(RiskActions.getRiskDetailRequest(id));
      dispatch(RiskActions.getRiskHistoryRequest(id));
      dispatch(RiskActions.getRiskAssociationsRequest(id));
    }
  }, []);

  const handleSubmitData = async (data) => {
    // let convertedRiskValue = '';
    // switch (values.risk_result) {
    //   case 'Catastrófico':
    //     convertedRiskValue = 'catastrophic';
    //     break;
    //   case 'Elevado':
    //     convertedRiskValue = 'high';
    //     break;
    //   case 'Moderado':
    //     convertedRiskValue = 'moderate';
    //     break;
    //   case 'Possível':
    //     convertedRiskValue = 'possible';
    //     break;
    //   case 'Insignificante':
    //     convertedRiskValue = 'insignificant';
    //     break;
    //   default:
    //     convertedRiskValue = '';
    //     break;
    // }

    dispatch(
      RiskActions.treatRiskRequest({ ...data }, residualRisk, id, loadData)
    );

    onClose();
  };

  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        description:
          !newTreatment && riskTreatmentDetails?.description
            ? riskTreatmentDetails.description
            : '',
        reanalysis:
          !newTreatment && riskTreatmentDetails?.reanalysis
            ? riskTreatmentDetails.reanalysis
            : new Date(),
        evidence:
          !newTreatment && riskTreatmentDetails?.evidence
            ? riskTreatmentDetails.evidence
            : '',
        probability:
          !newTreatment && riskTreatmentDetails?.probability
            ? riskTreatmentDetails.probability
            : '',
        impact:
          !newTreatment && riskTreatmentDetails?.impact
            ? riskTreatmentDetails.impact
            : '',
        risk_result: '',
        detection:
          !newTreatment && riskTreatmentDetails?.detection
            ? riskTreatmentDetails.detection
            : '',
        rfd: '',
        residual_risk_after_mitigation:
          !newTreatment && riskTreatmentDetails?.residual_risk_after_mitigation
            ? riskTreatmentDetails.residual_risk_after_mitigation
            : false,
      },
      validationSchema,
      onSubmit: async (data) => {
        await handleSubmitData(data);
      },
    });

  const handleAddResidualRisk = () => {
    setResidualRisk([
      ...residualRisk,
      {
        description: '',
        evidence: null,
        business_units: [],
        processes: [],
        assets: [],
        users: [],
      },
    ]);
  };

  useEffect(() => {
    switch (true) {
      case values.probability === 'very_low' && values.impact === 'negligible':
      case values.probability === 'very_low' && values.impact === 'little':
      case values.probability === 'low' && values.impact === 'negligible':
        setFieldValue('risk_result', 'insignificant');
        break;
      case values.probability === 'very_low' && values.impact === 'medium':
      case values.probability === 'very_low' && values.impact === 'larger':
      case values.probability === 'low' && values.impact === 'little':
      case values.probability === 'low' && values.impact === 'medium':
      case values.probability === 'medium' && values.impact === 'negligible':
      case values.probability === 'medium' && values.impact === 'little':
      case values.probability === 'high' && values.impact === 'negligible':
        setFieldValue('risk_result', 'possible');
        break;
      case values.probability === 'very_low' && values.impact === 'extreme':
      case values.probability === 'low' && values.impact === 'larger':
      case values.probability === 'low' && values.impact === 'extreme':
      case values.probability === 'medium' && values.impact === 'medium':
      case values.probability === 'medium' && values.impact === 'larger':
      case values.probability === 'high' && values.impact === 'little':
      case values.probability === 'high' && values.impact === 'medium':
      case values.probability === 'very_high' && values.impact === 'negligible':
      case values.probability === 'very_high' && values.impact === 'little':
        setFieldValue('risk_result', 'moderate');
        break;
      case values.probability === 'medium' && values.impact === 'extreme':
      case values.probability === 'high' && values.impact === 'larger':
      case values.probability === 'very_high' && values.impact === 'medium':
        setFieldValue('risk_result', 'high');
        break;
      case values.probability === 'high' && values.impact === 'extreme':
      case values.probability === 'very_high' && values.impact === 'larger':
      case values.probability === 'very_high' && values.impact === 'extreme':
        setFieldValue('risk_result', 'catastrophic');
        break;
      default:
        setFieldValue('risk_result', '');
    }
  }, [values.probability, values.impact]);

  useEffect(() => {
    if (values.risk_result !== '' && values.detection !== '') {
      setFieldValue(
        'rfd',
        String(
          riskValues[values.risk_result] * detectionValues[values.detection]
        )
      );
    }
  }, [values.risk_result, values.detection]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextArea
            label={i18next.t(
              'risks.riskDetails.createEvaluationModal.descriptionLabel'
            )}
            value={values.description}
            error={errors.description}
            touched={touched.description}
            name="description"
            onChange={handleChange}
            editable={newTreatment}
          />
        </Grid>
        <Grid item xs={4}>
          {!newTreatment ? (
            <>
              <Label>
                {i18next.t(
                  'risks.riskDetails.createEvaluationModal.reanalisysLabel'
                )}
              </Label>
              <WrapperResult>
                {format(
                  values?.reanalysis
                    ? new Date(values?.reanalysis)
                    : new Date(),
                  'dd/MM/yyyy'
                )}
              </WrapperResult>
            </>
          ) : (
            <DatePicker
              value={values.reanalysis}
              onChange={(e) => setFieldValue('reanalysis', e)}
              formatMask="dd/MM/yy"
            >
              <TextInput
                label={i18next.t(
                  'risks.riskDetails.createEvaluationModal.reanalisysLabel'
                )}
                placeholder="DD/MM/AA"
                name="reanalysis"
                error={errors.reanalysis}
                touched={touched.reanalysis}
              />
            </DatePicker>
          )}
        </Grid>
        <Grid item xs={4}>
          {newTreatment && (
            <FileInput
              label={i18next.t(
                'risks.riskDetails.createEvaluationModal.evidenceLabel'
              )}
              value={values.evidence}
              onChange={(e) => setFieldValue('evidence', e)}
              error={errors.evidence}
              touched={touched.evidence}
              name="evidence"
            />
          )}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={12}>
          <BottomRow>
            <WrapperInput>
              <SecondarySelect
                label={i18next.t(
                  'risks.riskDetails.createEvaluationModal.probabilityLabel'
                )}
                options={[
                  {
                    label: i18next.t('risks.board.probability.veryLow'),
                    value: 'very_low',
                  },
                  {
                    label: i18next.t('risks.board.probability.low'),
                    value: 'low',
                  },
                  {
                    label: i18next.t('risks.board.probability.medium'),
                    value: 'medium',
                  },
                  {
                    label: i18next.t('risks.board.probability.high'),
                    value: 'high',
                  },
                  {
                    label: i18next.t('risks.board.probability.veryHigh'),
                    value: 'very_high',
                  },
                ]}
                value={values.probability}
                error={errors.probability}
                touched={touched.probability}
                name="probability"
                onChange={(e) => setFieldValue('probability', e)}
                disabled={!newTreatment}
              />
            </WrapperInput>
            <WrapperMath>
              <MultiplyIcon />
            </WrapperMath>
            <WrapperInput>
              <SecondarySelect
                label={i18next.t(
                  'risks.riskDetails.createEvaluationModal.impactLabel'
                )}
                options={[
                  {
                    label: i18next.t('risks.board.impact.negligible'),
                    value: 'negligible',
                  },
                  {
                    label: i18next.t('risks.board.impact.little'),
                    value: 'little',
                  },
                  {
                    label: i18next.t('risks.board.impact.medium'),
                    value: 'medium',
                  },
                  {
                    label: i18next.t('risks.board.impact.bigger'),
                    value: 'larger',
                  },
                  {
                    label: i18next.t('risks.board.impact.extreme'),
                    value: 'extreme',
                  },
                ]}
                value={values.impact}
                error={errors.impact}
                touched={touched.impact}
                name="impact"
                onChange={(e) => setFieldValue('impact', e)}
                disabled={!newTreatment}
              />
            </WrapperInput>
            <WrapperMath>
              <EqualsIcon />
            </WrapperMath>
            <WrapperInput>
              <Label>
                {i18next.t('risks.riskDetails.createEvaluationModal.riskLabel')}
              </Label>
              <WrapperResult>
                {convertRisk(values.probability, values.impact)}
              </WrapperResult>
            </WrapperInput>
          </BottomRow>
        </Grid>
        <Grid item xs={12}>
          <BottomRow>
            <WrapperInput>
              <Label>
                {i18next.t('risks.riskDetails.createEvaluationModal.riskLabel')}
              </Label>
              <WrapperResult>
                {convertRisk(values.probability, values.impact)}
              </WrapperResult>
            </WrapperInput>
            <WrapperMath>
              <MultiplyIcon />
            </WrapperMath>
            <WrapperInput>
              <SecondarySelect
                label={i18next.t(
                  'risks.riskDetails.createEvaluationModal.detectionLevelLabel'
                )}
                options={[
                  {
                    label: i18next.t('risks.board.probability.veryLow'),
                    value: 'very_low',
                  },
                  {
                    label: i18next.t('risks.board.probability.low'),
                    value: 'low',
                  },
                  {
                    label: i18next.t('risks.board.probability.medium'),
                    value: 'medium',
                  },
                  {
                    label: i18next.t('risks.board.probability.high'),
                    value: 'high',
                  },
                  {
                    label: i18next.t('risks.board.probability.veryHigh'),
                    value: 'very_high',
                  },
                ]}
                value={values.detection}
                error={errors.detection}
                touched={touched.detection}
                name="detection"
                onChange={(e) => setFieldValue('detection', e)}
                disabled={!newTreatment}
              />
            </WrapperInput>
            <WrapperMath>
              <EqualsIcon />
            </WrapperMath>
            <WrapperInput>
              <Label>
                {i18next.t('risks.riskDetails.createEvaluationModal.rfdLabel')}
              </Label>
              <WrapperResult>{values.rfd}</WrapperResult>
            </WrapperInput>
          </BottomRow>
        </Grid>
        {newTreatment ? (
          <Grid item xs={12}>
            <Column>
              <Label>
                {i18next.t(
                  'risks.riskDetails.createEvaluationModal.residualRiskQuestion'
                )}
              </Label>
              <Row>
                <CheckBox
                  label={i18next.t(
                    'risks.riskDetails.createEvaluationModal.yesLabel'
                  )}
                  selected={values.residual_risk_after_mitigation}
                  onSelect={() =>
                    setFieldValue('residual_risk_after_mitigation', true)
                  }
                  margin="0 2.7rem 0 0"
                />
                <CheckBox
                  label={i18next.t(
                    'risks.riskDetails.createEvaluationModal.noLabel'
                  )}
                  selected={!values.residual_risk_after_mitigation}
                  onSelect={() =>
                    setFieldValue('residual_risk_after_mitigation', false)
                  }
                />
              </Row>
            </Column>
          </Grid>
        ) : (
          <RiskMitigationTitle>
            {i18next.t('risks.riskDetails.createEvaluationModal.residualRisk')}
          </RiskMitigationTitle>
        )}

        <Grid item xs={12} />
        {values.residual_risk_after_mitigation &&
          residualRisk.map((residual, index) => (
            <>
              <Grid item xs={12}>
                <ResidualRiskSession
                  residualRisk={residualRisk}
                  setResidualRisk={setResidualRisk}
                  currentResidualRisk={residual}
                  index={index}
                  newTreatment={newTreatment}
                />
              </Grid>
              <Grid item xs={12} />
            </>
          ))}
        {values.residual_risk_after_mitigation && newTreatment && (
          <Grid item xs={12}>
            <AddButton onClick={handleAddResidualRisk}>
              {i18next.t(
                'risks.riskDetails.createEvaluationModal.addResidualRiskButton'
              )}
            </AddButton>
          </Grid>
        )}

        <Grid container spacing={4} justifyContent="flex-end">
          <Grid item xs={3}>
            <SecondaryButton
              label={
                newTreatment
                  ? i18next.t('common.buttons.cancel')
                  : i18next.t('common.buttons.close')
              }
              onClick={() => {
                setNewTreatment(true);
                onClose();
              }}
            />
          </Grid>
          {newTreatment && (
            <Grid item xs={3}>
              <PrimaryButton
                label={i18next.t('common.buttons.save')}
                onCLick={handleSubmit}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}

export default EvaluationModal;

EvaluationModal.propTypes = {
  onClose: func.isRequired,
  id: string.isRequired,
  setNewTreatment: func.isRequired,
  newTreatment: bool.isRequired,
};
