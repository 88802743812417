import React from 'react';
import { bool } from 'prop-types';

function HomeIcon({ active }) {
  return active ? (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8578 14.5866V12.2864C5.85779 11.7036 6.33175 11.23 6.91883 11.2264H9.07446C9.66416 11.2264 10.1422 11.701 10.1422 12.2864V14.5799C10.1422 15.0855 10.553 15.4963 11.0622 15.5H12.5329C13.2197 15.5017 13.8791 15.2321 14.3654 14.7505C14.8517 14.269 15.125 13.6151 15.125 12.9331V6.39938C15.125 5.84854 14.8791 5.32603 14.4535 4.97263L9.45723 1.0057C8.58388 0.311842 7.33653 0.334256 6.48904 1.05904L1.60026 4.97263C1.15456 5.31562 0.888164 5.83967 0.875 6.39938V12.9265C0.875 14.3478 2.03554 15.5 3.46713 15.5H4.90422C5.14938 15.5018 5.38512 15.4063 5.5591 15.2348C5.73309 15.0633 5.83094 14.83 5.83094 14.5866H5.8578Z"
        fill="#4347A2"
      />
    </svg>
  ) : (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.86791 15.5786V13.2785C5.8679 12.6935 6.34484 12.2181 6.93576 12.2142H9.10032C9.69406 12.2142 10.1754 12.6907 10.1754 13.2785V13.2785V15.5857C10.1752 16.0824 10.5757 16.4884 11.0773 16.5H12.5203C13.9588 16.5 15.125 15.3455 15.125 13.9214V13.9214V7.37838C15.1173 6.81812 14.8516 6.29201 14.4035 5.94977L9.46829 2.01398C8.6037 1.32867 7.37465 1.32867 6.51006 2.01398L1.59652 5.95692C1.14669 6.29777 0.88054 6.82475 0.875 7.38552V13.9214C0.875 15.3455 2.04116 16.5 3.47968 16.5H4.92272C5.43677 16.5 5.85348 16.0875 5.85348 15.5786V15.5786"
        stroke="#606474"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HomeIcon;

HomeIcon.propTypes = {
  active: bool.isRequired,
};
