import React from 'react';
import { useFormikContext } from 'formik';
import i18next from 'i18next';
import TextInput from '~/components/TextInput';
import { FormArea, Container, AreaInput } from './styles';

function Voucher() {
  const { values, errors, touched, handleChange } = useFormikContext();

  return (
    <>
      <Container>
        <FormArea>
          <AreaInput>
            <TextInput
              placeholder={i18next.t('configurations.voucher.placeholder')}
              label={i18next.t('configurations.voucher.label')}
              name="voucher"
              value={values.voucher}
              touched={touched.voucher}
              error={errors.voucher}
              onChange={handleChange}
              margin="0 0 2.1rem 0"
            />
          </AreaInput>
        </FormArea>
      </Container>
    </>
  );
}
export default Voucher;
