import { CgSpinnerTwo } from 'react-icons/cg';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  `;

export const SppinerIcon = styled(CgSpinnerTwo).attrs({
  size: 24,
  color: '#ffff',
})`
  animation: ${rotate} 2s linear infinite;
`;
