import * as Yup from 'yup';

export default Yup.object().shape({
  id: Yup.string().required('Campo obrigatório'),
  name: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('E-mail inválido').required('Campo obrigatório'),
  account_type: Yup.string().required('Campo obrigatório'),
  message_in_server: Yup.string().required('Campo obrigatório'),
  message_out_server: Yup.string().required('Campo obrigatório'),
  logon_name: Yup.string().required('Campo obrigatório'),
  password: Yup.string().required('Campo obrigatório'),
  require_secure: Yup.boolean().required('Campo obrigatório'),
  email_signature: Yup.string(),
  signature: Yup.string(),
});
