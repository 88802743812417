import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
  position: relative;
  padding-bottom: ${({ error }) => (error ? '1.5rem' : '0')};

  transition: all 300ms ease 100ms;
`;

export const Input = styled.textarea`
  background: ${({ theme }) => theme.colors.base[200]};
  border: ${({ error, theme }) =>
    error ? `1px solid ${theme.colors.red}` : 'none'};
  padding: 1.7rem;
  border-radius: 0.5rem;

  flex: 1;
  min-height: 14rem;
  max-height: ${({ maxHeight }) => maxHeight || '50rem'};
  width: 100%;
  resize: vertical;
  position: relative;
  /* z-index: 2; */

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  :-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.base[400]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
  caret-color: ${({ theme }) => theme.colors.blue};

  /* :focus {
    transform: scale(1.01);
  }

  :hover {
    transform: scale(1.01);
  } */

  transition: transform 300ms ease;

  ${({ readOnly }) =>
    readOnly &&
    css`
      cursor: default;
      color: ${({ theme }) => theme.colors.base[500]};
    `}
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${({ error }) => (error ? 1 : 0)};
  transform: ${({ error }) =>
    error ? 'translateY(1.5rem) scale(1)' : 'translateY(-1rem) scale(0.9)'};
  text-align: left;
  transition: all 300ms ease 100ms;
  position: absolute;
  bottom: 0;
  z-index: 1;
`;

export const Label = styled.div`
  font-size: ${({ sm }) => (sm ? 1.2 : 1.4)}rem;
  color: ${({ theme, sm }) =>
    sm ? theme.colors.base[400] : theme.colors.base[500]};
  font-weight: ${({ sm }) => (sm ? 500 : 400)};
  line-height: 1.4rem;
  margin-bottom: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: red;
      }
    `}
`;
