import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-bottom: 3.3rem;
`;

export const SubTitle = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-bottom: 0.5rem;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.base[300]};
  margin-bottom: 2rem;
`;
