import React from 'react';
import { bool, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import { SecondaryButton, PrimaryButton } from '~/components/Button';
import { SecondarySelect } from '~/components/Select';

import { Container, WrapperTop, InputTitle } from './styles';

const userRoleModal = ({
  open,
  onClose,
  setOpenCreateUserModal,
  setNewUserRole,
  setOpenInviteDPOModal,
}) => {
  function submit(values) {
    if (values.role === 'dpo') {
      onClose();
      setOpenInviteDPOModal(true);
    } else if (values.role !== '') {
      setNewUserRole(values.role);
      onClose();
      setOpenCreateUserModal(true);
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t('peopleAsset.selectNewUserRoleModal.title')}
      noPadding
    >
      <Container>
        <Formik
          initialValues={{
            role: '',
          }}
          onSubmit={submit}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <WrapperTop>
                <Grid container spacing={2} justify="center">
                  <Grid item xs={17} md={9} lg={8} justify="space-between">
                    <InputTitle>
                      {i18next.t('peopleAsset.selectNewUserRoleModal.subtitle')}
                    </InputTitle>
                    <SecondarySelect
                      placeholder={i18next.t(
                        'peopleAsset.selectNewUserRoleModal.placeholder'
                      )}
                      name="role"
                      options={[
                        {
                          value: 'company',
                          label: i18next.t('common.roles.administrator'),
                        },
                        {
                          value: 'gedReviewer',
                          label: i18next.t('common.roles.gedReviewer'),
                        },
                        { value: 'dpo', label: i18next.t('common.roles.dpo') },
                        {
                          value: 'treatmentAgent',
                          label: i18next.t('common.roles.internTreatmentAgent'),
                        },
                        {
                          value: 'other',
                          label: i18next.t('common.roles.others'),
                        },
                      ]}
                      value={values.role}
                      onChange={(e) => setFieldValue('role', e)}
                    />
                  </Grid>
                </Grid>
              </WrapperTop>

              <Grid container spacing={3} justify="center">
                <Grid item xs={14} md={7} lg={5}>
                  <SecondaryButton
                    label={i18next.t('common.buttons.cancel')}
                    type="secondary"
                    onClick={onClose}
                  />
                </Grid>
                <Grid item xs={14} md={7} lg={5}>
                  <PrimaryButton
                    label={i18next.t('common.buttons.continue')}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default userRoleModal;

userRoleModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  setOpenCreateUserModal: func.isRequired,
  setNewUserRole: func.isRequired,
};
