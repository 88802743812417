import styled from 'styled-components';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 2rem;
`;

export const WrapperContent = styled.div`
  min-width: 22.5rem;
  height: 5rem;
  background: #539ada;
  border-radius: 10px;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  cursor: pointer;

  :hover {
    transform: scale(1.03);
    box-shadow: 0 0 30px #00000006;
  }
  transition: 200ms ease;
`;

export const UserImage = styled.img`
  object-fit: cover;
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
`;

export const Name = styled.div`
  flex: 1;
  margin: 1.7rem;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: left;
  align-items: flex-start;
`;

export const ArrowDown = styled(IoIosArrowDown).attrs(({ theme }) => ({
  size: '1.5rem',
  color: 'white',
}))``;

export const ArrowUp = styled(IoIosArrowUp).attrs(({ theme }) => ({
  size: '1.5rem',
  color: 'white',
}))``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
  padding-left: 2.4rem;
  padding-right: 1.2rem;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.cardText};
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 400;
  ${({ theme }) => theme.notSelectable};

  :first-child {
    margin-top: 0.8rem;
  }
  :last-child {
    margin-bottom: 0.8rem;
  }

  :hover {
    background-color: rgba(0, 0, 0, 0.03);
  }

  button {
    height: 4rem;
    font-size: 1.5rem;
  }

  transition: 150ms ease;
`;

export const CompanyLogoWrapper = styled.div`
  width: 1.8rem;
  margin-right: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyLogo = styled.img`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin-right: 1rem;
  object-fit: contain;
`;

export const SwitchCompanyText = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem;
  color: #539ada;
  text-align: center;
`;
