import styled from 'styled-components';

export const Container = styled.div``;

export const FormArea = styled.div``;

export const AreaInput = styled.div`
  padding: 0 80px;
  @media (max-width: 500px) {
    padding: 0 30px;
  }
`;
