import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import Tabs from '~/components/Tabs';

import { PrimaryButton } from '~/components/Button';

import ProcessActions from '~/store/ducks/processes';
import PeopleActions from '~/store/ducks/people';
// import TechnologiesActions from '~/store/ducks/technologies';
// import DocumentActions from '~/store/ducks/documents';
import ConfigActions from '~/store/ducks/config';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import Spinner from '~/components/Spinner';
import BusinessUnitActions from '~/store/ducks/businessUnits';
import { Container, ButtonWrapper, Button, Title } from './styles';
// import Threats from './Tabs/Threats';
// import Actives from './Tabs/Actives';

import DetailsTab from './Tabs/Details';
import ExecutionPlan from './Tabs/ExecutionPlan';
import Threats from './Tabs/Threats';

const Details = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sidebarMenuData, processDetails } = useSelector(
    (state) => state.processes
  );

  const { userActive } = useSelector((state) => state.auth);
  // const { people } = useSelector((state) => state.people);
  // const { businessUnitsEnum } = useSelector((state) => state.businessUnits);
  const {
    isLoading,
    //  processesEnum
  } = useSelector((state) => state.processes);
  // const { technologiesEnum } = useSelector((state) => state.technologies);
  // const { documentsEnum } = useSelector((state) => state.documents);

  const [id, setId] = useState(
    history.location.pathname.replace(
      '/assets-management/business-process/',
      ''
    )
  );

  useEffect(() => {
    if (id !== 'new') {
      dispatch(ProcessActions.getProcessDetailsRequest(id));
    }
  }, []);

  const [executionPlan, setExecutionPlan] = useState({
    responsibles: [],
    processNature: '',
    graphicSchema: null,
    processes: [],
  });

  const [detailsTab, setDetailsTab] = useState({
    maxTime: { max_time_without_operating: 1 },
  });

  const [threats, setThreats] = useState([]);

  useEffect(() => {
    if (id !== 'new' && processDetails?.id) {
      setExecutionPlan({
        responsibles: processDetails.execution_plan[0].responsibles.map(
          (item) => {
            return item.person_id;
          }
        ),
        processNature: processDetails.execution_plan[0]?.process_nature,
        graphicSchema: processDetails?.execution_plan[0]?.graphic_esqueme,
        localities: processDetails?.localities.map((item) => item.locality.id),
        processes: processDetails?.steps,
      });

      setDetailsTab({
        maxTime: {
          max_time_without_operating:
            processDetails?.contingency[0].max_time_without_operating,
          timetypeOfTimeMeasurement: processDetails?.contingency[0].time_unit,
        },
        impact: processDetails?.contingency[0].impact,
        consequences: processDetails?.contingency[0].consequences,
      });

      setThreats(processDetails?.threats);
    }
  }, [processDetails]);

  // const [actives, setActives] = useState(
  //   id !== 'new' && processDetails?.process?.id
  //     ? processDetails.process.association_assets.map((asset) => ({
  //         type: asset.type,
  //         active: asset.user_id || asset.technology_id || asset.process_id,
  //       }))
  //     : []
  // );

  const updateId = (business) => {
    setId(business.process.id);
    history.push(`/assets-management/business-process/${business.process.id}`);
  };

  const submit = () => {
    // executionPlan.localities = businessUnit || [];
    const data = {
      execution: {
        localities: executionPlan?.localities,
        responsibles: executionPlan?.responsibles,
        departments: executionPlan?.departments,
        process_phases: executionPlan?.processes,
        process_nature: executionPlan?.processNature,
      },
      graphicSchema: executionPlan?.graphicSchema,
      contingency: {
        consequences: detailsTab.consequences,
        impact: detailsTab?.impact,
        time_unit: detailsTab?.maxTime?.timetypeOfTimeMeasurement,
        max_time_without_operating:
          detailsTab?.maxTime?.max_time_without_operating,
      },
      // actives,
      companyId: userActive.activeCompany.id,
      threats,
      sideBar: sidebarMenuData,
    };

    if (id !== 'new') dispatch(ProcessActions.updateProcessRequest(data, id));
    else dispatch(ProcessActions.createProcessRequest(data, updateId));
  };

  useEffect(() => {
    dispatch(PeopleActions.getPeopleEnumRequest());
    dispatch(ConfigActions.getDepartamentsEnumRequest());
    dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
  }, []);

  // useEffect(() => {
  //   if (businessUnitsEnum.length === 0)
  //     dispatch(BusinessUnitActions.getBusinessUnitsEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (processesEnum.length === 0)
  //     dispatch(ProcessActions.getProcessesEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (technologiesEnum.length === 0)
  //     dispatch(TechnologiesActions.getTechnologiesEnumRequest());
  // }, []);

  // useEffect(() => {
  //   if (documentsEnum.length === 0)
  //     dispatch(DocumentActions.getDocumentsEnumRequest());
  // }, []);

  if (id !== 'new' && isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Title>
        {id === 'new'
          ? i18next.t('processAsset.newTitle')
          : i18next.t('processAsset.editTitle')}
      </Title>
      <Tabs
        tabs={[
          i18next.t('processAsset.tabs.executionPlan.label'),
          i18next.t('processAsset.tabs.contingencyDetails.label'),
          // i18next.t('processAsset.tabs.associatedActives.label'),
          i18next.t('processAsset.tabs.associatedThreats.label'),
        ]}
      >
        <ExecutionPlan
          executionPlan={executionPlan}
          setExecutionPlan={setExecutionPlan}
        />
        <DetailsTab detailsTab={detailsTab} setDetailsTab={setDetailsTab} />
        {/* <Actives actives={actives} setActives={setActives} /> */}
        <Threats threats={threats} setThreats={setThreats} />
      </Tabs>

      <ButtonWrapper>
        <Button>
          <PrimaryButton
            label={
              isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
            }
            onClick={submit}
          />
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default Details;
export { default as BusinessProcessLayout } from '../Layout';
