import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4;
  margin-bottom: 10rem;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #eceef0;
  margin: 3.4rem 0;
`;
