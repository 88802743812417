import { IoCloudOfflineOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const Container = styled.div`
  height: 18.4rem;
  width: 13.5rem;
  background: ${({ cardColor }) => cardColor};
  border-radius: 16px;
  padding: 1.1rem 1.3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
`;

export const CompanyImg = styled.img`
  width: 40px;
  object-fit: cover;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
`;

export const ImageWrapper = styled.div`
  background: #d9d9d9;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloudOffIcon = styled(IoCloudOfflineOutline)`
  width: 16px;
  height: 21.33px;
  color: #707070;
`;

export const CompanyDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  font-family: ${({ theme }) => theme.fonts.Poppins};
  color: ${({ theme }) => theme.colors.base[100]};
  padding: ${({ active }) =>
    active ? '0.7rem 0 3.8rem 0' : '0.7rem 0 2rem 0'}; ;
`;

export const Title = styled.strong`
  font-size: 1.8rem;
  padding: 0 auto;
  font-weight: 600;
  font-style: normal;
  line-height: 2.7rem;

  max-width: 12rem;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const Status = styled.strong`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2rem;
`;

export const EnterButton = styled.div`
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  padding: 1.3rem 1.1rem;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.base[100]};

  color: ${({ active, theme }) => (active ? theme.colors.blue : '#707070')};
  font-weight: 600;
  font-size: 1.4rem;

  cursor: pointer;

  :hover {
    filter: brightness(1.1);
    transform: scale(0.99);
  }
`;
