import styled from 'styled-components';
import { IconDownload } from '~/assets/svgs';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 7.5rem;
  padding: 0 3.2rem;
  border-bottom: 1px solid #f7f7f7;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 2.2rem;
  font-weight: 400;
  max-width: 47rem;
  word-wrap: break-word;
`;

export const DownloadButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  height: 4rem;
  border: 1px solid #f7f7f7;

  border-radius: 10px;

  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  font-weight: 400;

  ${({ theme }) => theme.clickable}
`;

export const DownloadIcon = styled(IconDownload)`
  fill: ${({ theme }) => theme.colors.base[400]};
  margin-right: 2rem;
`;

export const WrapperInfo = styled.div`
  padding: 1.7rem 3.2rem;
  border-bottom: 1px solid #f7f7f7;
`;

export const Responsible = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
`;

export const CreatedAt = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.3rem;
  font-weight: 400;
`;

export const Content = styled.div`
  padding: 3.2rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-wrap;
`;
