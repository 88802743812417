import styled from 'styled-components';

import { ForgotPasswordBG, LogoIcon } from '~/assets/svgs/index';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;

  @media (max-width: 600px) {
    justify-content: center;
  }

  @media (min-width: 600px) {
    margin-left: 12rem;
    margin-right: 12rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  flex-wrap: wrap;
`;

export const BG = styled(ForgotPasswordBG)`
  position: absolute;
  z-index: 0;

  width: 100%;
  height: 100%;

  opacity: 0.2;
`;

export const Logo = styled(LogoIcon)`
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  height: 15rem;
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  margin-top: 7.5rem;
`;

export const TitleWrapper = styled.div`
  @media (min-width: 600px) {
    flex: 1 1 30%;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blue};

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 7.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: 600px) {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  flex: 0 1 30%;
`;

export const WrapperProgress = styled.div`
  margin-bottom: 2.7rem;
`;
