import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, string } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { SecondarySelect, MultiSelect } from '~/components/Select';
import { PrimaryButton } from '~/components/Button';
import PeopleActions from '~/store/ducks/people';
import ConfigActions from '~/store/ducks/config';
// import iventory from '~/store/ducks/iventory';
import AvatarDropzone from '~/components/AvatarDropzone';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import schema from './validationSchema';

import { Container, WrapperButton } from './styles';

function CreateUserModal({ open, newUserRole, onClose }) {
  const dispatch = useDispatch();
  const { userActive, companyId } = useSelector((state) => state.auth);
  const { peopleResponsibleEnum } = useSelector((state) => state.iventory);
  const { isLoading } = useSelector((state) => state.people);
  const { roleEnum, departaments } = useSelector((state) => state.config);
  const [avatar, setAvatar] = useState(null);

  function reloadRole() {
    dispatch(ConfigActions.getRolesRequest());
  }

  function reloadDepartaments() {
    dispatch(ConfigActions.getDepartamentsRequest(userActive.activeCompany.id));
  }

  useEffect(() => {
    reloadRole();
    reloadDepartaments();
  }, []);

  function reloadPeople() {
    dispatch(PeopleActions.getPeopleRequest('0', ''));
    onClose();
  }

  const submit = (values) => {
    if (!Boolean(values.systemUser)) {
      const nonUserRole = roleEnum.find(role => role.type === 'NON_USER');
      if (nonUserRole) {
        values.role = nonUserRole.id;
      }
    }
    
    const formData = {
      companyId: userActive.activeCompany.id,
      role: values.role,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      department: values.department,
      office: values.office,
      phone: values.phone
        .replace(/\s/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/-/g, ''),
      document: values.document,
      userSystem: Boolean(values.systemUser),
      avatar
    };

    dispatch(PeopleActions.createPersonRequest(formData, reloadPeople));
  };

  

  return (
    <Modal
      title={`${i18next.t('peopleAsset.createNewUserModal.title')}`}
      noPadding
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          role: '',
          firstName: '',
          lastName: '',
          document: '',
          email: '',
          phone: '',
          department: '',
          office: '',
          systemUser: false
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <AvatarDropzone setAvatar={setAvatar} />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  label={i18next.t('common.fields.name')}
                  placeholder="João"
                  required
                  name="firstName"
                  value={values.firstName}
                  touched={touched.firstName}
                  error={errors.firstName}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={i18next.t('common.fields.lastName')}
                  placeholder="Santos"
                  required
                  name="lastName"
                  value={values.lastName}
                  touched={touched.lastName}
                  error={errors.lastName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextInput
                  // label={i18next.t('common.fields.document')}
                  label="CPF"
                  placeholder="Ex.: CPF"
                  required
                  mask="999.999.999-99"
                  name="document"
                  value={values.document}
                  touched={touched.document}
                  error={errors.document}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={7}>
                <TextInput
                  label={i18next.t('common.fields.email')}
                  placeholder="Ex.: meuemail@email.com.br"
                  name="email"
                  required
                  value={values.email}
                  touched={touched.email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={5}>
                <TextInput
                  label={i18next.t('common.fields.phone')}
                  placeholder="Ex.: (21) 99999-9999"
                  required
                  mask={
                    values.phone.length < 15
                      ? '(99) 9999-9999*'
                      : '(99) 99999-9999'
                  }
                  name="phone"
                  value={values.phone}
                  touched={touched.phone}
                  error={errors.phone}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={3}>
                  <SecondarySelect
                    label={i18next.t(
                      'peopleAsset.createNewUserModal.systemUser'
                    )}
                    placeholder={i18next.t(
                      'peopleAsset.selectSystemUser.placeholder'
                    )}
                    name="systemUser"
                    required
                    options={[{label: "Sim", value: true},{label: "Não", value: false}]}
                    value={values.systemUser}
                    touched={touched.systemUser}
                    error={errors.systemUser}
                    onChange={(e) => setFieldValue('systemUser', e)}
                  />
                </Grid>

              <Grid item xs={4}>
                <SecondarySelect
                  label= {i18next.t(
                    'peopleAsset.createNewUserModal.userProfileLabel'
                  )}
                  placeholder={values.systemUser ? i18next.t(
                    'peopleAsset.selectNewUserRoleModal.placeholder'
                  ) : i18next.t('common.roles.others')}
                  name="role"
                  options={roleEnum.map((role) => {
                    return {
                      value: role.id,
                      label:
                        role.type === 'ADMIN'
                          ? i18next.t('common.roles.administrator')
                          : role.type === 'GED'
                          ? i18next.t('common.roles.gedReviewer')
                          : role.type === 'TREATMENT_AGENT'
                          ? i18next.t('common.roles.internTreatmentAgent')
                          : role.type === 'DPO'
                          ? i18next.t('common.roles.dpo')
                          : role.type === 'PAYING_ADMIN'
                          ? i18next.t('common.roles.payingAdmin')
                          : role.type === 'OWNER'
                          ? i18next.t('common.roles.owner')
                          : i18next.t('common.roles.others'),
                    };
                  })}
                  disabled={!values.systemUser}
                  required={values.systemUser}
                  value={values.role}
                  touched={touched.role}
                  error={errors.role}
                  onChange={(e) => setFieldValue('role', e)}
                />
              </Grid>

              <Grid item xs={4}>
                <MultiSelect
                  label={i18next.t('common.fields.department')}
                  options={departaments.map((depart) => {
                    return {
                      value: depart.id,
                      label: depart.name,
                    };
                  })}
                  required
                  hasModal
                  value={values.department}
                  touched={touched.department}
                  error={errors.department}
                  onChange={(e) => setFieldValue('department', e)}
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={i18next.t('common.fields.role')}
                  placeholder="Ex.: CTO"
                  name="office"
                  required
                  value={values.office}
                  touched={touched.office}
                  error={errors.office}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <WrapperButton>
              <PrimaryButton
                type="button"
                disabled={isLoading}
                label={
                  isLoading ? <SppinerIcon /> : i18next.t('common.buttons.save')
                }
                padding="0 5.8rem"
                width="null"
                onClick={handleSubmit}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateUserModal;

CreateUserModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  newUserRole: string.isRequired,
};
