import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 2.8rem;
  line-height: normal;
`;

export const WrapperItem = styled.div`
  margin-bottom: 1.4rem;
`;

export const Label = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`;

export const WrapperContent = styled.div`
  background: #fafbfb;
  border-radius: 1rem;
  height: 5.5rem;
  padding: 0 1.7rem;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.3rem;
  font-weight: 400;
`;
