import styled from 'styled-components';
import { Link as LinkRouter } from 'react-router-dom';
import { LogoIcon, IconShowPassword, IconHidePassword } from '~/assets/svgs';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 4rem 0;
  margin: 0 5%;
  height: 100vh;
  max-width: 600px;

  @media (max-width: 720px) {
    width: 100%;
    max-width: 100%;
  }
  @media (min-width: 1600px) {
    width: 100%;
    max-width: 100%;
    padding: 15rem 0;
  }
`;

export const Logo = styled(LogoIcon)`
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  min-height: 150px;
  height: 150px;
`;

export const ForgotPassword = styled(LinkRouter)`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  color: ${({ theme }) => theme.colors.base[400]};
  text-decoration: underline;
  text-decoration-color: transparent;

  :hover {
    text-decoration-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.blue};
    transform: scale(1.01);
  }

  transition: all 100ms ease;
`;

export const SocialButton = styled.div`
  background: rgba(241, 241, 241, 0.5);
  border: 2px solid rgba(241, 241, 241, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  height: 5.5rem;
  width: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SocialIcon = styled.img``;

export const PasswordWrapper = styled.div`
  position: relative;
`;

export const ShowPasswordIcon = styled(IconShowPassword)`
  position: absolute;
  bottom: 16px;
  right: 1.5rem;
  cursor: pointer;
`;

export const HidePasswordIcon = styled(IconHidePassword)`
  position: absolute;
  right: 1.5rem;
  bottom: 16px;
  cursor: pointer;
`;
