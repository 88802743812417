import * as Yup from 'yup';

export default Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
  evidence: Yup.string().required('Campo obrigatório'),
  probability: Yup.string().required('Campo obrigatório'),
  impact: Yup.string().required('Campo obrigatório'),
  detection: Yup.string().required('Campo obrigatório'),

  // reviewDate: Yup.string().required('Campo obrigatório'),
  // risk: Yup.string().required('Campo obrigatório'),
  // facility: Yup.string().required('Campo obrigatório'),
  // rfd: Yup.string().required('Campo obrigatório'),
  // isThereRisk: Yup.boolean().required('Campo obrigatório'),
});
