import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Step1 from './Step1';
import Step2 from './Step2';

function Steps({ step, recaptchaCompleted, onNext }) {
  const [dpoData, setDpoData] = useState({});

  switch (step) {
    case 1:
      return (
        <Step1
          recaptchaCompleted={recaptchaCompleted}
          onSubmit={onNext}
          setDpoData={setDpoData}
        />
      );
    case 2:
      return <Step2 onSubmit={onNext} dpoData={dpoData} />;

    default:
      return <div />;
  }
}
Steps.propTypes = {
  step: PropTypes.number.isRequired,
  recaptchaCompleted: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Steps;
