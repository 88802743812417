import i18next from 'i18next';
import * as Yup from 'yup';

import { isValid as isValidCnpj } from '@fnando/cnpj';
import { isValid as isValidCpf } from '@fnando/cpf';

export default Yup.object().shape({
  provider: Yup.object().shape({
    name: Yup.string().required(i18next.t('common.validation.required')),
    landline: Yup.string().required(i18next.t('common.validation.required')),
    cell_phone: Yup.string().required(i18next.t('common.validation.required')),
    email: Yup.string()
      .email('E-mail inválido')
      .required(i18next.t('common.validation.required')),
    cpf_cnpj: Yup.string()
      .required('Campo obrigatório')
      .min(14, 'Campo inválido')
      .test('cpf', 'CPF inválido', (cpf) => cpf?.length > 14 || isValidCpf(cpf))
      .test(
        'cpf',
        'CNPJ inválido',
        (cnpj) => cnpj?.length < 15 || isValidCnpj(cnpj)
      ),
    site: Yup.string().required(i18next.t('common.validation.required')),
  }),

  adresses: Yup.object().shape({
    street: Yup.string().required(i18next.t('common.validation.required')),
    number: Yup.number().required(i18next.t('common.validation.required')),
    neighborhood_sector: Yup.string().required(
      i18next.t('common.validation.required')
    ),
    complement: Yup.string().required(i18next.t('common.validation.required')),
    city: Yup.string().required(i18next.t('common.validation.required')),
    state: Yup.string().required(i18next.t('common.validation.required')),
    country: Yup.string().required(i18next.t('common.validation.required')),
    zip_code: Yup.string().required(i18next.t('common.validation.required')),
  }),
});
