export default {
  translations: {
    menu: {
      home: 'Home',
      holdersManagement: 'Holders management',
      demandManagement: 'Demand management',
      holders: 'Holders',
      documentManagement: 'Document management',
      documents: 'Documents',
      documentReview: 'Document Review',
      assetManagement: 'Assets management',
      people: 'People',
      businessUnit: 'Business Unit',
      processes: 'Processes',
      technologies: 'Technologies',
      riskManagement: 'Risk management',
      config: 'Configuration',
      companyData: 'Company data',
      personalInformation: 'Personal information',
    },
    APIerrors: {
      400: 'Algum erro aconteceu! Tente novamente!',
      404: 'Não encontramos o que você estava procurando!',
      401: 'Você não possui permissão para essa ação!',
      500: 'Aconteceu um erro interno do sistema. Tente novamente mais tarde!',
      1001: 'Email já existe!',
      1002: 'Hash inválido!',
      1003: 'Usuário não encontrado!',
      1004: 'Senha anterior está incorreta!',
      1005: 'Empresa não encontrada!',
      1006: 'Usuário já existe na empresa!',
      1007: 'Usuário não é um DPO!',
      1008: 'Usuário ainda não definiu sua senha!',
      1009: 'Necessário confirmar ativação de conta por email',
      1010: 'Email e senha não conferem.',
      1011: 'CPF já existe!',
      1012: 'Empresa já cadastrada, favor entrar em contato com o nosso suporte para maiores informações',
      2001: 'Senha inválida!',
      3004: 'Tecnologia não encontrada!',
      3005: 'Estágio de contingência não existe!',
      4004: 'Localidade não existe!',
      5003: 'Titular não vinculado a empresa atual!',
      5004: 'Titular não encontrado!',
      6001: 'E-mail já cadastrado, esqueceu sua senha? Tente recuperá-la clicando em recuperar senha abaixo',
      7001: 'Arquivo obrigatório não enviado!',
      7002: 'Nota não encontrada!',
      7003: 'Pasta não encontrada!',
      7004: 'Documento operacional não encontrado!',
      7005: 'Token de download inválido!',
      7006: 'Arquivo não encontrado no servidor de armazenamento!',
      7007: 'Empresa já possui pasta com este nome!',
      7008: 'Pasta para mover arquivos ao deletar não encontrada',
      9003: 'Avaliação de risco não encontrada!',
      9004: 'Risco não encontrado!',
      8004: 'Processo não encontrado!',
      1101: 'Empresa não informada!',
      1102: 'Empresa não encontrada!',
      1103: 'Documento obrigatório não informado!',
      1104: 'Chamado não existe!',
      1105: 'Documento do chamado não encontrado!',
      1106: 'Documento do chamado não encontrado no servidor de armazenamento',
      1107: 'Resposta do chamado não encontrada!',
      'title is a required field': 'Título é um campo obrigatório!',
      'primary_function is a required field':
        'Função primária é um campo obrigatório!',
      'responsible is a required field': 'Responsáveis é um campo obrigatório!',
      'email is a required field': 'Email é um campo obrigatório!',
      'name is a required field': 'Nome é um campo obrigatório!',
      'role is a required field': 'Cargo é um campo obrigatório',
      'role must be one of the following values: company, gedReviewer, collaborator, dpo, treatmentAgent':
        'Cargo deve ser um dos seguintes valores: Administrador, Revisor GED, Colaborador, DPO Externo e Agente de Tratamento',
    },
  },
};
