import React from 'react';
import Search from '~/components/TextInput/Search';
import ProgressBar from '~/components/ProgressBar';
import i18next from 'i18next';
import { bool, func, number } from 'prop-types';

import history from '~/routes/history';
import UserMenu from '../../pages/UserPanel/components/UserMenu';
import {
  Container,
  SearchWrapper,
  ProgressBarContainer,
  ButtonReturn,
  Arrow,
  ButtonText,
  SelectorAndDateAndUser,
} from './styles';
import ProfileMenu from '../ProfileMenu';
// import DateViewer from '../DateViewer';
// import LanguageSelector from '../LanguageSelector';

export default function UserHeader({
  isSearch,
  progress,
  progressStatus,
  returnPage,
}) {
  return (
    <Container isSearch={isSearch}>
      {progress ? (
        <ProgressBarContainer>
          <ProgressBar
            label={`${i18next.t(
              'common.signUp.stepLabel1'
            )} ${progressStatus} ${i18next.t('common.signUp.stepLabel2')} 2`}
            actualValue={progressStatus}
            total={3}
            initialColor="#539ADA"
            finalColor="#539ADA"
          />
        </ProgressBarContainer>
      ) : null}

      {returnPage ? (
        <ButtonReturn>
          {' '}
          <Arrow />{' '}
          <ButtonText onClick={() => history.goBack()}>
            {i18next.t('common.buttons.goBack')}
          </ButtonText>
        </ButtonReturn>
      ) : null}

      {false ? (
        <SearchWrapper>
          <Search variant="secondary" placeholder="Buscar" />
        </SearchWrapper>
      ) : (
        <div />
      )}

      <SelectorAndDateAndUser>
        {/* {!returnPage ? <DateViewer /> : null} */}
        {/* <LanguageSelector privateLayout /> */}
        <ProfileMenu />
      </SelectorAndDateAndUser>
    </Container>
  );
}

UserHeader.propTypes = {
  isSearch: bool,
  progress: bool,
  progressStatus: number,
  returnPage: func,
};

UserHeader.defaultProps = {
  progress: null,
  isSearch: null,
  progressStatus: '',
  returnPage: null,
};
