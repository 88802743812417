import React from 'react';
import { objectOf, string } from 'prop-types';
import { useSelector } from 'react-redux';

import Card from '~/components/Card';
import { Text } from '~/styles/components';

import {
  Container,
  Title,
  WrapperItems,
  WrapperItem,
  WrapperDocument,
  WrapperDownload,
  DocumentIcon,
  DownloadIcon,
} from './styles';

const FilesItem = ({ item }) => {
  return (
    <WrapperItem>
      <WrapperDocument>
        <DocumentIcon />
      </WrapperDocument>
      <Text width="100%" fontWeight={400} fontSize="1.2rem">
        {item.title}
      </Text>
      <WrapperDownload>
        <DownloadIcon />
      </WrapperDownload>
    </WrapperItem>
  );
};

FilesItem.propTypes = {
  item: objectOf(string).isRequired,
};

function Files() {
  const { interactionDetails } = useSelector((state) => state.demands);

  return (
    <Card>
      <Container>
        <Title>Arquivos anexados</Title>
        <WrapperItems>
          {interactionDetails?.attachment && (
            <FilesItem item={interactionDetails?.attachment} />
          )}
        </WrapperItems>
      </Container>
    </Card>
  );
}

export default Files;
