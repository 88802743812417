import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, objectOf } from 'prop-types';
import { useFormik } from 'formik';
import i18next from 'i18next';
import ConfigActions from '~/store/ducks/config';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import { MultiSelect } from '~/components/Select';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import TextArea from '~/components/TextArea';
import PeopleActions from '~/store/ducks/people';

import CancellationModal from '~/components/CancellationModal';
import schema from './validationSchema';
import {
  Container,
  GridContainer,
  IconPlus,
  NewProcessButton,
  ProcessWrapper,
  Title,
  TrashIcon,
  WrapperButton,
  WrapperFotterButton,
} from './styles';

function DepartamentModal({ open, onClose, selectedDepartament }) {
  const dispatch = useDispatch();
  const { peopleEnum } = useSelector((state) => state.people);
  const { isLoading, departament } = useSelector((state) => state.config);
  const [cancelModal, setCancelModal] = useState(false);

  useEffect(() => {
    dispatch(PeopleActions.getPeopleEnumRequest());
  }, []);

  useEffect(() => {
    if (selectedDepartament?.id) {
      dispatch(
        ConfigActions.getDepartamentDetailsRequest(selectedDepartament.id)
      );
    }
  }, [selectedDepartament]);

  const onSuccess = (resetForm) => {
    setCancelModal(false);
    resetForm();
    onClose();
  };

  const submit = (data, { resetForm }) => {
    if (!selectedDepartament) {
      dispatch(
        ConfigActions.createDepartamentRequest(data, () => onSuccess(resetForm))
      );
    } else {
      dispatch(
        ConfigActions.updateDepartamentRequest(departament.id, data, () =>
          onSuccess(resetForm)
        )
      );
    }
  };

  const {
    values,
    initialValues,
    touched,
    errors,
    handleChange,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: {
      name: selectedDepartament ? departament?.name : '',
      managers: selectedDepartament
        ? departament?.managers?.map((manager) => manager.person.id)
        : [],
      description: selectedDepartament ? departament?.description : '',
      processes: [],
    },
    onSubmit: submit,
    validationSchema: schema,
    enableReinitialize: true,
  });

  const handleAddProcess = () => {
    setFieldValue('processes', [...values.processes, {}]);
  };

  const handleRemoveProcess = (index) => {
    const newProcesses = [...values.processes];
    newProcesses.splice(index, 1);
    setFieldValue('processes', newProcesses);
  };

  const handleOpenCancelModal = () => {
    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      setCancelModal(true);
    } else {
      setCancelModal(false);
      onClose();
    }
  };

  const handleCloseCancelModal = () => {
    setCancelModal(false);
  };

  return (
    <Modal
      title={
        !selectedDepartament
          ? `${i18next.t('configurations.departments.registrationModal.label')}`
          : `${i18next.t(
              'configurations.departments.registrationModal.label2'
            )}`
      }
      noPadding
      open={open}
      onClose={handleOpenCancelModal}
    >
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextInput
              label={i18next.t(
                'configurations.departments.registrationModal.name'
              )}
              required
              placeholder={i18next.t('common.typeIt')}
              name="name"
              value={values.name}
              touched={touched.name}
              error={errors.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <MultiSelect
              label={i18next.t(
                'configurations.departments.registrationModal.managers'
              )}
              options={peopleEnum}
              required
              hasModal
              value={values.managers}
              error={errors.managers}
              touched={touched.managers}
              onChange={(e) => setFieldValue('managers', e)}
              margin="0 0 1.8rem"
            />
          </Grid>
          <Grid item xs={12}>
            <TextArea
              label={i18next.t(
                'configurations.departments.registrationModal.description'
              )}
              required
              placeholder={i18next.t(
                'configurations.departments.registrationModal.placeholderDescription'
              )}
              name="solution"
              value={values.description}
              touched={touched.description}
              error={errors.description}
              onChange={(e) => setFieldValue('description', e.target.value)}
            />
          </Grid>
        </Grid>

        {!selectedDepartament && (
          <ProcessWrapper>
            <Title>Cadastrar processo(s)</Title>

            {values.processes.map((process, index) => (
              <GridContainer container spacing={4}>
                <TrashIcon
                  onClick={() => {
                    handleRemoveProcess(index);
                  }}
                />

                <Grid item xs={6}>
                  <TextInput
                    label={i18next.t(
                      'configurations.departments.registrationModal.processes.name'
                    )}
                    required
                    placeholder={i18next.t('common.typeIt')}
                    name="processName"
                    value={process.name}
                    touched={touched?.processes && !!touched.processes[index]}
                    error={errors?.processes && errors.processes[index]?.name}
                    onChange={(e) =>
                      setFieldValue(`processes[${index}].name`, e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MultiSelect
                    label={i18next.t(
                      'configurations.departments.registrationModal.processes.responsible'
                    )}
                    options={peopleEnum}
                    required
                    hasModal
                    value={process.responsibles}
                    touched={touched?.processes && !!touched.processes[index]}
                    error={
                      errors?.processes &&
                      errors?.processes[index]?.responsibles
                    }
                    onChange={(e) =>
                      setFieldValue(`processes[${index}].responsibles`, e)
                    }
                    margin="0 0 1.8rem"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextArea
                    label={i18next.t(
                      'configurations.departments.registrationModal.processes.description'
                    )}
                    name="description"
                    value={process.description}
                    onChange={(e) =>
                      setFieldValue(
                        `processes[${index}].description`,
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </GridContainer>
            ))}

            <WrapperButton>
              <NewProcessButton onClick={handleAddProcess}>
                <IconPlus />
                Adicionar Processo
              </NewProcessButton>
            </WrapperButton>
          </ProcessWrapper>
        )}
        <WrapperFotterButton>
          <PrimaryButton
            disabled={isLoading}
            type="submit"
            label={
              isLoading ? (
                <SppinerIcon />
              ) : !selectedDepartament ? (
                i18next.t('common.buttons.save')
              ) : (
                i18next.t('common.buttons.update')
              )
            }
            padding="0 5.8rem"
            width="null"
            onClick={handleSubmit}
          />
        </WrapperFotterButton>
        <CancellationModal
          open={cancelModal}
          onClose={handleCloseCancelModal}
          title={i18next.t('configurations.cancellationModal.title')}
          handleNo={() => onSuccess(resetForm)}
          handleYes={handleSubmit}
          isSubmitting={isLoading}
        />
      </Container>
    </Modal>
  );
}

export default DepartamentModal;

DepartamentModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  selectedDepartament: objectOf.isRequired,
};
