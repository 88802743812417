import styled, { css } from 'styled-components';

export const Container = styled.button`
  border: none;
  border-radius: 0.5rem;
  background: ${({ theme, background }) => theme.colors[background]};
  font-size: ${({ small }) => (small ? 1.4 : 1.8)}rem;
  font-weight: ${({ small }) => (small ? 400 : 600)};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  height: ${({ small }) => (small ? 4 : 6)}rem;
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding};
  transition: all 0.2s ease;
  margin: ${(props) => props.margin};

  ${({ disabled }) =>
    disabled &&
    css`
      filter: brightness(0.7);
      cursor: not-allowed;
    `}

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }
`;
