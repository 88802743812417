import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import { func, bool, string } from 'prop-types';
import { Formik } from 'formik';
import i18next from 'i18next';
import ConfigActions from '~/store/ducks/config';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import { SecondarySelect } from '~/components/Select';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import schema from './validationSchema';
import { Container, WrapperButton } from './styles';

function CreateUserModal({ open, newUserRole, onClose }) {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  const { roleEnum, isLoading } = useSelector((state) => state.config);

  const onSuccess = () => {
    onClose();
  };

  function reloadRole() {
    dispatch(ConfigActions.getRolesRequest());
  }

  useEffect(() => {
    reloadRole();
  }, []);

  const submit = (values) => {
    const data = {
      user_email: values.email,
      role_id: values.role,
      company_id: userActive.activeCompany.id,
    };

    dispatch(ConfigActions.createCompanyUserRequest(data, onSuccess));
  };

  return (
    <Modal
      title={`${i18next.t('peopleAsset.createNewUserModal.title')}`}
      noPadding
      open={open}
      onClose={onClose}
    >
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          role: '',
        }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
          handleSubmit,
        }) => (
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextInput
                  label={i18next.t('common.fields.email')}
                  placeholder="Ex.: meuemail@email.com.br"
                  name="email"
                  value={values.email}
                  touched={touched.email}
                  error={errors.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <SecondarySelect
                  label={i18next.t('peopleAsset.selectNewUserRoleModal.title')}
                  shadow
                  placeholder={i18next.t(
                    'peopleAsset.selectNewUserRoleModal.placeholder'
                  )}
                  name="role"
                  options={roleEnum.map((role) => {
                    return {
                      value: role.id,
                      label:
                        role.type === 'ADMIN'
                          ? i18next.t('common.roles.administrator')
                          : role.type === 'GED'
                          ? i18next.t('common.roles.gedReviewer')
                          : role.type === 'TREATMENT_AGENT'
                          ? i18next.t('common.roles.internTreatmentAgent')
                          : role.type === 'DPO'
                          ? i18next.t('common.roles.dpo')
                          : role.type === 'PAYING_ADMIN'
                          ? i18next.t('common.roles.payingAdmin')
                          : role.type === 'OWNER'
                          ? i18next.t('common.roles.owner')
                          : i18next.t('common.roles.others'),
                    };
                  })}
                  value={values.role}
                  touched={touched.role}
                  error={errors.role}
                  onChange={(e) => setFieldValue('role', e)}
                />
              </Grid>
            </Grid>
            <WrapperButton>
              <PrimaryButton
                label={
                  isLoading ? (
                    <SppinerIcon />
                  ) : (
                    i18next.t('peopleAsset.createNewUserModal.inviteEmail')
                  )
                }
                padding="0 5.8rem"
                width="null"
                onClick={handleSubmit}
              />
            </WrapperButton>
          </Container>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateUserModal;

CreateUserModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  newUserRole: string.isRequired,
};
