import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 2rem;
  width: 2rem;
  border: 1px solid ${({ theme }) => theme.colors.base[400]};
  box-sizing: border-box;
  box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 0.6rem;
  padding: 0.3rem;
`;

export const Inner = styled.div`
  background-color: ${({ theme }) => theme.colors.base[400]};
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  border-radius: 0.4rem;
  flex: 1;
  transition: all 100ms ease;
`;

export const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  color: ${({ theme }) => theme.colors.base[400]};
  margin-left: 0.9rem;
`;
