import React from 'react';
import i18next from 'i18next';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import Card from '~/components/Card';
import DocumentActions from '~/store/ducks/documents';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import {
  Header,
  Title,
  DownloadButton,
  DownloadIcon,
  WrapperInfo,
  Responsible,
  CreatedAt,
  Content,
} from './styles';

function DocumentDetails() {
  const dispatch = useDispatch();
  const { documentDetails } = useSelector((state) => state.documents);

  function handleDocumentDownload() {
    dispatch(
      DocumentActions.downloadLastestDocumentRequest(
        documentDetails?.data?.doc?.id
      )
    );
  }

  const url = documentDetails?.fileUrl;

  return (
    <Card style={{ height: '400%' }} padding="0">
      <Header>
        <Title>{documentDetails ? documentDetails?.data?.doc?.name : ''}</Title>
        <DownloadButton onClick={handleDocumentDownload}>
          <DownloadIcon />
          {i18next.t('documents.documentDetails.download')}
        </DownloadButton>
      </Header>
      <WrapperInfo>
        {documentDetails?.users && (
          <Responsible>{`${i18next.t(
            'documents.documentDetails.responsible'
          )}: ${
            documentDetails ? documentDetails.users[0]?.name : ''
          }`}</Responsible>
        )}

        <CreatedAt>
          {/* {documentDetails?.createdAt ? (
            <Content>
              {i18next.t('documents.documentDetails.creationDate')}
              {format(new Date(`${documentDetails?.createdAt}`), 'dd/MM/yyyy')}
            </Content>
          ) : null} */}
        </CreatedAt>
      </WrapperInfo>
      {documentDetails?.data?.last_version?.file_extension === 'pdf' ? (
        <div>
          <iframe src={url} title="file" width="100%" height="600" />
        </div>
      ) : (
        <div>
          {/* <DocViewer
            style={{ height: 800 }}
            pluginRenderers={DocViewerRenderers}
            documents={[
              {
                uri: `${documentDetails?.fileUrl}`,
                fileType: `${documentDetails?.data?.last_version?.file_extension}`,
              },
            ]}
          /> */}
        </div>
      )}
    </Card>
  );
}

export default DocumentDetails;
