import React, { useState } from 'react';
import { func, bool, string } from 'prop-types';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DemandActions from '~/store/ducks/demand';

import Modal from '~/components/Modal';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { VeryAngry, Angry, Thinking, Fine, Happy } from '~/assets/icons';

import TextInput from '~/components/TextInput';

import {
  Container,
  WrapperButton,
  Title,
  ScoreQuestion,
  ScoreWrapper,
  ScoreIconButton,
  ScoreIcon,
  OpinionQuestion,
} from './styles';

function EvaluationModal({ onClose, open, name, protocol, id }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [score, setScore] = useState(null);
  const [comment, setComment] = useState('');

  function handleCloseModal() {
    onClose();
    history.push('/demands-history');
  }

  function handleSubmit() {
    dispatch(DemandActions.evaluateDemandRequest(score, comment, id));
    onClose();
    history.push('/demands-history');
  }

  return (
    <Modal
      title={i18next.t('callOpening.closeDemandModal.title')}
      open={open}
      onClose={onClose}
    >
      <Container>
        <Title>
          {i18next.t('callOpening.evaluationModal.description', {
            name,
            protocol,
          })}
        </Title>
        <ScoreQuestion>
          {i18next.t('callOpening.evaluationModal.question')}
        </ScoreQuestion>

        <ScoreWrapper>
          <ScoreIconButton onClick={() => setScore(1)} selected={score === 1}>
            <ScoreIcon
              src={VeryAngry}
              alt={i18next.t('callOpening.evaluationModal.horrible')}
            />
          </ScoreIconButton>
          <ScoreIconButton onClick={() => setScore(2)} selected={score === 2}>
            <ScoreIcon
              src={Angry}
              alt={i18next.t('callOpening.evaluationModal.bad')}
            />
          </ScoreIconButton>
          <ScoreIconButton onClick={() => setScore(3)} selected={score === 3}>
            <ScoreIcon
              src={Thinking}
              alt={i18next.t('callOpening.evaluationModal.medium')}
            />
          </ScoreIconButton>
          <ScoreIconButton onClick={() => setScore(4)} selected={score === 4}>
            <ScoreIcon
              src={Fine}
              alt={i18next.t('callOpening.evaluationModal.good')}
            />
          </ScoreIconButton>
          <ScoreIconButton onClick={() => setScore(5)} selected={score === 5}>
            <ScoreIcon
              src={Happy}
              alt={i18next.t('callOpening.evaluationModal.excelent')}
            />
          </ScoreIconButton>
        </ScoreWrapper>

        <OpinionQuestion>
          {i18next.t('callOpening.evaluationModal.commentLabel')}
        </OpinionQuestion>
        <TextInput
          placeholder={i18next.t(
            'callOpening.evaluationModal.commentPlaceholder'
          )}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ height: '11.7rem' }}
          textArea
        />

        <WrapperButton>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleCloseModal()}
          />
          <PrimaryButton
            label={i18next.t('common.buttons.evaluate')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleSubmit()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default EvaluationModal;

EvaluationModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  name: string.isRequired,
  protocol: string.isRequired,
  id: string.isRequired,
};
