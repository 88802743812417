import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div``;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 1.4rem;
  font-weight: 400;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 1.6rem;
`;
