import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const Modal = styled.div`
  width: 600px;
  @media (max-width: 500px) {
    width: 250px;
    height: 400px;
    align-items: center;
    justify-content: center;
  }
`;

export const ModalSecondary = styled.div`
  width: 600px;
  height: 485px;
  margin-top: 30px;
  padding: 30px;
  border: 1px solid #ebebeb;
  align-items: center;
  display: grid;
  border-radius: 20px;
  background-color: white;
  @media (max-width: 500px) {
    width: 250px;
    height: 400px;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
`;

export const Subtitle = styled.span`
  font-size: 18px;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  display: grid;
  margin-top: 5%;
`;

export const FormArea = styled.div`
  @media (max-width: 500px) {
    width: 180px;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`;

export const RadioButtonArea = styled.div`
  width: 100%;
  height: 100px;
  /* background-color: red; */
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-evenly;
`;

export const RadioArea = styled.div``;

export const RadioButtonLabel = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 21px;
  margin-left: 10px;
  color: #606474;
`;

export const PCSLogo = styled.img`
  width: 300px;
  @media (max-width: 500px) {
    width: 210px;
  }
`;

export const AreaLogo = styled.div`
  width: 100%;
  height: 200px;
  align-items: center;
  justify-content: center;
  display: grid;
  border-bottom: 1px solid #cccccc;
  @media (max-width: 500px) {
    width: 200px;
  }
`;

export const ButtonArea = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: grid;
`;

export const ButtonAreaCancelAndNext = styled.div`
  height: 100%;
  justify-content: space-between;
  display: flex;
  margin-top: 50px;
  width: 100%;

  @media (max-width: 500px) {
    width: 250px;
    height: 120px;
    align-items: center;
    justify-content: center;
    display: grid;
  }
`;
