import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const Container = styled.div`
  flex: 1;
  min-height: calc(100vh - 17rem);
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4rem;
`;

export const SearchWrapper = styled.div`
  width: 35%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 1.6rem;
`;

export const Content = styled.div`
  margin-top: 5%;

`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const NoFolderText = styled.h2`
  text-align: center;
`;

export const WrapperNavigator = styled.div`
  display: flex;
  width: 100%;
  margin: 5rem 0 3.2rem 0;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const TotalItems = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.8rem;
  font-weight: 600;
`;
