import React, { useState } from 'react';
import { element, bool } from 'prop-types';

import Header from './components/Header';

import { Container, WrapperContent, PaddingTop } from './styles';

export default function ClientLayout({ children, backButton }) {
  const [drawerOpened, setDrawerOpened] = useState(false);

  return (
    <>
      <Container>
        <WrapperContent>
          <Header
            backButton={backButton}
            drawerOpened={drawerOpened}
            setDrawerOpened={setDrawerOpened}
          />
          <PaddingTop />
          {children}
        </WrapperContent>
      </Container>
    </>
  );
}

ClientLayout.propTypes = {
  children: element.isRequired,
  backButton: bool,
};

ClientLayout.defaultProps = {
  backButton: false,
};
