import styled, { css } from 'styled-components';
import { CgFile } from 'react-icons/cg';

export const Container = styled.div.attrs({ layout: true })`
  width: 80vw;
  max-width: 100%;
  margin-bottom: 2rem;
`;

export const Dropzone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fafbfb;
  border: 2px dashed #c6ccd2;
  box-sizing: border-box;
  border-radius: 10px;
  height: 8.2rem;
  max-width: 100%;
  cursor: pointer;

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      box-shadow: inset 6px 6px 15px #f5f5f5, inset -6px -6px 15px #ffffff;
      transform: scale(0.99);
      cursor: drop;
    `};

  transition: 200ms ease;
`;

export const FileIcon = styled(CgFile).attrs({ color: '#606474', size: 24 })``;

export const DropText = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 400;
  margin-top: 0.8rem;
  text-align: center;
  max-width: 18rem;
`;
