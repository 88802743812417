import React from 'react';
import Grid from '@material-ui/core/Grid';
import { func, bool } from 'prop-types';
import i18next from 'i18next';

import { PrimaryButton, SecondaryButton } from '~/components/Button';

import { Container, Title } from './styles';

const TreatModal = ({ onClose, onTreat, newTreatment }) => {
  return (
    <Container>
      <Title>
        {newTreatment
          ? i18next.t('risks.riskDetails.treatResidualRiskModal.question')
          : i18next.t('risks.riskDetails.treatResidualRiskModal.questionView')}
      </Title>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            onClick={onClose}
          />
        </Grid>
        <Grid item xs={6}>
          <PrimaryButton
            label={
              newTreatment
                ? i18next.t(
                    'risks.riskDetails.treatResidualRiskModal.treatButton'
                  )
                : i18next.t(
                    'risks.riskDetails.treatResidualRiskModal.viewButton'
                  )
            }
            onClick={onTreat}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TreatModal;

TreatModal.propTypes = {
  onClose: func.isRequired,
  onTreat: func.isRequired,
  newTreatment: bool.isRequired,
};
