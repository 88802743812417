import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import i18next from 'i18next';

import { Row } from '~/styles/components';
import ReviewItem from '../ReviewItem';

import { Container, Title, WrapperItems } from './styles';

function UpcomingReviews(props) {
  const { nextRevisions } = useSelector((state) => state.dashboard);

  return (
    <Grid item {...props}>
      <Container>
        <Row align="center" justify="space-between">
          <Title>{i18next.t('dashboard.nextRevisions.title')}</Title>
        </Row>
        <WrapperItems>
          {nextRevisions && nextRevisions.length > 0 ? (
            nextRevisions.map((reviewItem) => (
              <ReviewItem
                id={reviewItem.id}
                name={reviewItem.title}
                date={reviewItem.next_revision}
              />
            ))
          ) : (
            <p>{i18next.t('dashboard.nextRevisions.noData')}</p>
          )}
        </WrapperItems>
      </Container>
    </Grid>
  );
}

export default UpcomingReviews;
