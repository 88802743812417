/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import DashboardActions from '~/store/ducks/dashboard';

import Card from '~/components/Card';
import { PrimaryTable } from '~/components/Table';

import { dateSortMethod } from '~/utils';

import convertImpact from '~/utils/convertImpact';
import convertProbability from '~/utils/convertProbability';
import RiskTag from '../RiskTag';

import { Header, Title } from './styles';

function MainRisks(props) {
  const dispatch = useDispatch();
  const { mainRisks } = useSelector((state) => state.dashboard);
  const [currentPage, setCurrentPage] = useState(1);

  function handlePagination(newPage) {
    dispatch(DashboardActions.getMainRisksRequest(newPage));
  }

  return (
    <Grid item {...props}>
      <Title>{i18next.t('dashboard.risksTitle')}</Title>
      <Card>
        <Header />
        <PrimaryTable
          columns={[
            {
              Header: i18next.t('dashboard.riskTable.active'),
              accessor: 'title',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('dashboard.riskTable.threat'),
              accessor: 'impact',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return convertImpact(e.value);
              },
            },
            {
              Header: i18next.t('dashboard.riskTable.occurence'),
              accessor: 'createdAt',
              Cell: (e) => format(new Date(e.value), "HH:mm '|' dd-MM-yy"),
              sortType: (a, b) => {
                return dateSortMethod(a.values, b.values);
              },
            },
            {
              Header: i18next.t('dashboard.riskTable.risk'),
              accessor: 'probability',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return <RiskTag item={convertProbability(e.value)} />;
              },
              disableSortBy: true,
            },
          ]}
          data={mainRisks?.rows.length > 0 ? mainRisks.rows : []}
          total={mainRisks?.count}
          pageCount={mainRisks?.total_pages}
          handlePagination={handlePagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Card>
    </Grid>
  );
}

export default MainRisks;
