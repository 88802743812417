import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IconArrowDown } from '~/assets/svgs';

export const Container = styled.div``;

export const Header = styled(motion.div)`
  display: flex;
  flex-direction: row;
  margin-bottom: 3.3rem;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: #262a3a;
  margin-right: 2.3rem;
`;

export const Arrow = styled(IconArrowDown)`
  transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0)')};
  transition: 200ms ease;
`;

export const WrapperContent = styled(motion.div)``;
