import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
`;

export const Button = styled.div`
  width: 30%;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-bottom: 3.3rem;
`;
