import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IoTrashOutline } from 'react-icons/io5';

export const Container = styled(motion.div)`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  cursor: default !important;
`;

export const DeleteIcon = styled(IoTrashOutline).attrs({ size: 24 })`
  cursor: pointer;

  :hover {
    color: ${({ theme }) => theme.colors.red};
  }
`;

export const MoveWrapper = styled.div``;

export const PositionWrapper = styled.div``;

export const DescriptionWrapper = styled.div``;

export const TypeWrapper = styled.div`
  min-width: 15rem;
`;

export const AssetWrapper = styled.div``;

export const DocumentWrapper = styled.div``;
