import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import TicketActions from '~/store/ducks/tickets';

import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import { dateSortMethod } from '~/utils';

import Spinner from '~/components/Spinner';
import SearchInput from '~/components/TextInput/Search';
import DueTag from './components/DueTag';
import PriorityTag from './components/PriorityTag';
import StatusTag from './components/StatusTag';

import {
  Container,
  Header,
  Title,
  HeaderActions,
  SearchWrapper,
  HeaderRight,
  DownloadButton,
  DownloadIcon,
  DownloadText,
  // UploadButton,
  // UploadIcon,
} from './styles';

const DemandManagement = () => {
  const dispatch = useDispatch();
  const { isLoading, tickets } = useSelector((state) => state.tickets);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (searchValue === '') {
      dispatch(TicketActions.getAllTicketsRequest(null));
    } else {
      dispatch(TicketActions.searchTicketsRequest(searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue !== '' && !isLoading && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchValue, isLoading]);

  function handlePagination(newPage) {
    dispatch(TicketActions.getAllTicketsRequest(newPage));
  }

  function handleDownload() {
    dispatch(TicketActions.downloadTicketsRequest());
  }

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header>
                <Title>{i18next.t('demandManagement.title')}</Title>
                <HeaderActions>
                  <SearchWrapper>
                    <SearchInput
                      inputRef={searchInputRef}
                      placeholder={i18next.t('common.search')}
                      value={searchValue}
                      onChange={setSearchValue}
                      delay={1000}
                      hasDebounce
                    />
                  </SearchWrapper>
                  <HeaderRight>
                    <DownloadButton onClick={handleDownload}>
                      <DownloadIcon />
                      <DownloadText>
                        {i18next.t('common.buttons.export')}
                      </DownloadText>
                    </DownloadButton>
                    {/* <UploadButton>
                      <UploadIcon />
                    </UploadButton> */}
                  </HeaderRight>
                </HeaderActions>
              </Header>
            </Grid>
            <Grid item xs={12}>
              <Card padding="2rem 0">
                <Table
                  columns={[
                    {
                      Header: i18next.t('demandManagement.table.date'),
                      accessor: 'createdAt',
                      Cell: (e) =>
                        format(new Date(`${e.value}T00:00`), 'dd/MM'),
                      sortType: (a, b) => {
                        return dateSortMethod(a.values.date, b.values.date);
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.protocol'),
                      accessor: 'protocol',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.subject'),
                      accessor: 'subject',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.holder'),
                      accessor: 'client.name',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.responsible'),
                      accessor: 'responsible.name',
                      Cell: (e) => {
                        if (!e.value) {
                          return '-';
                        }
                        return e.value;
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.deadline'),
                      accessor: 'due_date',
                      Cell: (e) =>
                        e.value !== null ? (
                          <DueTag item={new Date(e.value.replace(/-/g, '/'))} />
                        ) : (
                          <span>-</span>
                        ),
                      sortType: (a, b) => {
                        return dateSortMethod(
                          a.values.due_date,
                          b.values.due_date
                        );
                      },
                    },
                    {
                      Header: i18next.t('demandManagement.table.priority'),
                      accessor: 'priority',
                      Cell: (e) =>
                        e.value ? (
                          <PriorityTag item={e.value} />
                        ) : (
                          <span>-</span>
                        ),
                    },
                    {
                      Header: i18next.t('demandManagement.table.status'),
                      accessor: 'status',
                      Cell: (e) =>
                        e.value ? <StatusTag item={e.value} /> : <span>-</span>,
                    },
                  ]}
                  data={tickets && tickets.rows.length > 0 ? tickets.rows : []}
                  onRowClick={(original) => {
                    dispatch(
                      TicketActions.getTicketDetailsRequest(original.id)
                    );
                  }}
                  total={tickets?.count}
                  pageCount={tickets?.total_pages}
                  handlePagination={handlePagination}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default DemandManagement;
