import i18next from 'i18next';
import { func, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import i18next from 'i18next';

import { TertiaryButton } from '~/components/Button';
import ConfigActions from '~/store/ducks/config';
import DrawerOptions from '../DrawerOptions';

import {
  Container,
  Title,
  Content,
  ButtonWrapper,
  IconPlus,
  DepartamentsWrapper,
} from './styles';

const ListDepartament = ({
  handleOpenModal,
  handleOpenEditModal,
  departament,
  setActive,
}) => {
  const dispatch = useDispatch();
  const { departaments } = useSelector((state) => state.config);

  useEffect(() => {
    dispatch(ConfigActions.getDepartamentsRequest());
  }, []);

  return (
    <Container>
      <Title>Departamento</Title>
      <Content>
        <ButtonWrapper>
          <TertiaryButton
            label={i18next.t('common.buttons.new')}
            leftIcon={<IconPlus />}
            style={{ width: '9rem', height: '3.5' }}
            onClick={handleOpenModal}
          />
        </ButtonWrapper>
        <DepartamentsWrapper>
          {departaments.map((item) => (
            <DrawerOptions
              activeOption={departament}
              setActiveOption={setActive}
              handleOpenEditModal={handleOpenEditModal}
              item={item}
            />
          ))}
        </DepartamentsWrapper>
      </Content>
    </Container>
  );
};

export default ListDepartament;

ListDepartament.propTypes = {
  handleOpenModal: func.isRequired,
  handleOpenEditModal: func.isRequired,
  departament: string.isRequired,
  setActive: func.isRequired,
};
