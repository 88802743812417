import styled from 'styled-components';
import { GiftedChat } from 'react-web-gifted-chat';

import { Send } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1rem solid rgba(198, 204, 210, 0.3);
  padding: 0 0 1.3rem 4rem;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.div`
  font-size: 1.4rem;
  color: #262a3a;
  font-weight: 500;
`;

export const Title = styled.div`
  font-size: 1.8rem;
  color: #262a3a;
  font-weight: 700;
`;

export const WrapperChat = styled.div`
  height: 56rem;
  margin: 0 3.5rem;
`;

export const Chat = styled(GiftedChat)`
  display: flex;
`;

export const ChatAvatar = styled.img`
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
`;

export const Bubble = styled.div`
  background-color: ${({ shouldShowUsername, owner }) =>
    !shouldShowUsername ? '#fff' : owner ? '#539ada' : '#f7f7f7'};
  border-radius: ${({ owner }) => (owner ? '0.5rem 0' : '0 0.5rem')} 0.5rem
    0.5rem;
  padding: ${({ shouldShowUsername }) =>
    shouldShowUsername ? '1rem 2.4rem 0.5rem 2.4rem' : '0'};
  margin-bottom: 2rem;
`;

export const Image = styled.img`
  width: 35.6rem;
  cursor: default;
`;

export const Message = styled.div`
  max-width: 35.6rem;
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${({ owner }) => (owner ? '#fff' : '#262a3a')};
  padding: ${({ shouldShowUsername }) =>
    shouldShowUsername ? '1rem 2.4rem 0.5rem 2.4rem' : '0'};
  margin-bottom: 0.8rem;
`;

export const Time = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ owner }) => (owner ? 'flex-end' : 'flex-start')};
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ owner }) => (owner ? '#fff' : '#262a3a')};
`;

export const InputToolbar = styled.div`
  display: flex;
  background: #ffffff;
  border: 0.1rem solid #c6ccd2;
  box-sizing: border-box;
  border-radius: 1rem;
`;

export const MathPlusButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  margin: 0.8rem 2rem;
  color: '#4347A2';
  background: #fff;
  ${({ theme }) => theme.clickable};
`;

export const Composer = styled.input`
  width: 100%;
  border-width: 0;
  background: #0000;
  font-size: 1.2rem;
  line-height: 1.3rem;
  padding: 2rem 0;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.base[300]};
    font-size: ${({ theme }) => theme.fontSizes.text.sm};
  }
`;

export const SendButton = styled(MathPlusButton)`
  background: #539ada;
  border-radius: 0.5rem;
  margin: 0.8rem 1.3rem;
  padding: 0.8rem;
`;

export const SendIcon = styled(Send)`
  height: 2rem;
  width: 2rem;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ shouldShowUsername }) =>
    !shouldShowUsername ? '1rem 2.4rem 0.5rem 2.4rem' : '0'};

  border-radius: ${({ owner }) => (owner ? '0.5rem 0' : '0 0.5rem')} 0.5rem
    0.5rem;

  background-color: ${({ owner }) => (owner ? '#539ada' : '#f7f7f7')};

  cursor: ${({ isFile }) => (isFile ? 'pointer' : 'default')};
`;

export const UsernameWrapper = styled.div`
  display: flex;
  width: 120%;
  margin-top: -1px;
  margin-left: -1px;
  align-items: flex-start;
  background: white;
  padding: 0.5rem 0 0.5rem;
  outline: none;
`;

export const UsernameText = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  margin: 0 0.5rem 0;
  color: rgb(178, 178, 178);
`;

export const FileIcon = styled.img`
  width: 4rem;
  height: 4rem;
`;

export const FilenameWrapper = styled.span`
  max-width: 35.6rem;
  font-size: 1.5rem;
  line-height: 1rem;

  margin-top: 1rem;

  color: ${({ owner }) => (owner ? '#fff' : '#262a3a')};
  margin-bottom: 0.8rem;
`;
