import { BiEditAlt } from 'react-icons/bi';
import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.3rem;
`;

export const Content = styled.div`
  margin-top: 2rem;
  flex: 1;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 2rem;
  height: 70vh;

  overflow: auto;
`;

export const DepartamentDetails = styled.div`
  background: #edf0f0;
  border-radius: 5px;
  padding: 1.4rem 2.2rem;

  position: relative;
`;

export const DepartamentItem = styled.p`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.2rem;
  line-height: 2.1rem;
`;

export const DepartamentTitle = styled.strong`
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2.1rem;

  :after {
    content: ': ';
  }
`;

export const WrapperManager = styled.div`
  display: flex;
  gap: 2rem;
`;

export const Manager = styled.div`
  background: rgba(83, 154, 218, 0.1);
  border-radius: 10px;
  padding: 1rem 2rem;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const FormContaier = styled.div`
  margin-top: 4.4rem;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.4rem;
  margin-right: 1.6rem;
  font-weight: 500;
`;

export const WrapperFotterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 3.4rem;
`;

export const EditIcon = styled(BiEditAlt).attrs({
  size: '2rem',
})`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.base[500]};

  position: absolute;
  top: 1rem;
  right: 1rem;

  :hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  z-index: 20;

  :after {
    content: '';
    border-radius: 50%;
    width: 10em;
    height: 10em;
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(83, 154, 218, 0.2);
    border-right: 1.1em solid rgba(83, 154, 218, 0.2);
    border-bottom: 1.1em solid rgba(83, 154, 218, 0.2);
    border-left: 1.1em solid #539ada;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
