import { arrayOf, func, number, objectOf, string } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import ConfigActions from '~/store/ducks/config';
import ActionButton from '../ActionButton';

import {
  Check,
  CheckWrapper,
  Container,
  Content,
  LabelCheck,
  Title,
} from './styles';

const Option = ({
  item,
  departamentId,
  position,
  activeOption,
  setActiveOption,
}) => {
  const dispatch = useDispatch();

  const handleSwitchOption = (id) => {
    if (activeOption.process === id) {
      setActiveOption({});
    } else {
      setActiveOption({ departament: departamentId, process: id });
    }
  };

  const checked = activeOption.process === item.id;

  const handleDeleteOption = (id) => {
    dispatch(ConfigActions.deleteDepartamentProcessRequest(id));
  };

  return (
    <Container
      active={checked}
      position={position}
      onClick={() => handleSwitchOption(item.id)}
    >
      <Content>
        <CheckWrapper>
          <Check
            type="checkbox"
            checked={checked}
            onChange={() => handleSwitchOption(item.id)}
            id={item.id}
          />
          <LabelCheck active={checked} htmlFor={item.name} />
        </CheckWrapper>
        <Title>{item.name}</Title>
      </Content>
      <ActionButton dataId={item.id} dispatchDelete={handleDeleteOption} />
    </Container>
  );
};

export default Option;

Option.propTypes = {
  item: objectOf.isRequired,
  activeOption: arrayOf.isRequired,
  setActiveOption: func.isRequired,
  position: number.isRequired,
  departamentId: string.isRequired,
};
