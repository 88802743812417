import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import i18next from 'i18next';
import ReCAPTCHA from 'react-google-recaptcha';

// import { LogoFacebook, LogoGoogle } from '~/assets/icons';
import AuthActions from '~/store/ducks/auth';
import history from '~/routes/history';
import { Row, Column } from '~/styles/components';
import TextInput from '~/components/TextInput';
import CheckBox from '~/components/CheckBox';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { MessageBox } from '~/pages/SignIn/MessgeBox';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import { clearAuth, getAuth, rememberAuth } from '~/utils/authHandler';
import schema from '~/pages/SignIn/Form/validationSchema';

import {
  Container,
  Logo,
  ForgotPassword,
  SocialButton,
  SocialIcon,
  PasswordWrapper,
  ShowPasswordIcon,
  HidePasswordIcon,
} from './styles';

function Form() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const [auth, setAuth] = useState({});
  const [rememberPassword, setRememberPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [accountActivationMessage, setAccountActivationMessage] = useState(false);
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);

  const token = history.location.search.replace('?invite_token=', '');
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const data = getAuth();
    if (data.name || data.password) {
      setAuth(data);
      setRememberPassword(true);
    }
  }, []);

  const IsActiveAccount = (code) => {
    if (code === '1009') {
      setAccountActivationMessage(true);
    }
  };

  const submit = (values) => {
    if (rememberPassword) {
      rememberAuth(values.email, values.password);
    } else {
      clearAuth();
    }

    if (process.env.REACT_APP_PROFILE !== 'develop') {
      recaptchaRef.current.execute();

      if(!recaptchaCompleted) {
        
        return;
      }
    }

    dispatch(
      AuthActions.signInRequest(
        values.email,
        values.password,
        token,
        IsActiveAccount
      )
    );
  };

  const handleResendEmail = (userEmail) => {
    dispatch(AuthActions.resendConfirmEmailRequest(userEmail));
  };

  const { values, touched, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { email: auth.email, password: auth.password },
    onSubmit: submit,
    validationSchema: schema,
    enableReinitialize: true,
  });

  return (
    <Container>
      <Logo />
      <Formik>
        <>
          <Column width="80%">
            <TextInput
              // label={i18next.t('common.fields.email')}
              placeholder={i18next.t('login.emailLabel')}
              name="email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              onChange={handleChange}
              onSubmit={handleSubmit}
              margin="0 0 2.8rem 0"
            />
            <PasswordWrapper>
              <TextInput
                // label={i18next.t('common.fields.password')}
                placeholder={i18next.t('login.passwordLabel')}
                type={showPassword ? 'text' : 'password'}
                name="password"
                value={values.password}
                touched={touched.password}
                error={errors.password}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0"
              />
              {showPassword ? (
                <HidePasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <ShowPasswordIcon
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </PasswordWrapper>
            <Row
              align="center"
              width="100%"
              margin="3.3rem 0 5.7rem"
              justify="space-between"
            >
              <CheckBox
                label={i18next.t('login.rememberPassword')}
                selected={rememberPassword}
                onSelect={() => setRememberPassword((e) => !e)}
              />
              <ForgotPassword to="/forgot-password">
                {i18next.t('login.forgotPassword')}
              </ForgotPassword>
            </Row>
            <MessageBox
              title={i18next.t('login.erroEmailActivation')}
              message={i18next.t('login.erroMessageEmailActivation')}
              linkText={i18next.t('confirmEmail.resend')}
              action={() => handleResendEmail(values.email)}
              onClose={setAccountActivationMessage}
              isOpen={accountActivationMessage}
            />
          </Column>
          <Column width="80%" margin="0 0 4.8rem">
            <PrimaryButton
              type="submit"
              disabled={isLoading}
              label={
                isLoading ? <SppinerIcon /> : i18next.t('login.loginButton')
              }
              onClick={handleSubmit}
            />
            <SecondaryButton
              label={i18next.t('login.signupButton')}
              margin="1.6rem 0 0"
              onClick={() => history.push('/sign-up')}
            />
          </Column>
          {/* <Row justify="center" width="100%">
              <SocialButton>
                <SocialIcon src={LogoFacebook} />
              </SocialButton>
              <SocialButton>
                <SocialIcon src={LogoGoogle} />
              </SocialButton>
            </Row> */}
        </>
      </Formik>
      {process.env.REACT_APP_PROFILE !== 'develop' ? (
        <ReCAPTCHA
          theme="light"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => setRecaptchaCompleted(true)}
          size="invisible"
        />
      ) : (
        <></>
      )}
    </Container>
  );
}

export default Form;
