import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { SecondarySelect } from '~/components/Select';
import AddMore from '~/components/Button/AddButton';
import { useFormikContext } from 'formik';
import incrementDate from '~/utils/incrementDate';
import { Container, Title, Divider } from './styles';

const Actives = () => {
  const { peopleEnum } = useSelector((state) => state.people);
  const { processesEnum } = useSelector((state) => state.processes);
  const { businessUnitsEnum } = useSelector((state) => state.businessUnits);
  const {
    values,
    //  handleChange, , errors, handleBlur,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();

  const addNew = () => {
    const newProcess = [
      ...values.contingencyAssets?.actives,
      { operation_time: { time: '1' }, solution: '' },
    ];
    setFieldValue('contingencyAssets.actives', newProcess);
  };

  const types = [
    {
      label: i18next.t('businessUnitAsset.tabs.assets.user'),
      value: 'user',
    },
    {
      label: i18next.t('businessUnitAsset.tabs.assets.process'),
      value: 'process',
    },
    {
      label: i18next.t('businessUnitAsset.tabs.assets.locality'),
      value: 'locality',
    },
  ];

  const updateTime = (index, value) => {
    const updatedProcesses = values.contingencyAssets?.actives;
    const registrationDate = new Date();

    const operationTime = {
      ...updatedProcesses[index].operation_time,
      time: value,
      registrationDate: registrationDate.getTime(),
      maximumDate: updatedProcesses[index].operation_time.typeOfTimeMeasurement
        ? incrementDate(
            registrationDate,
            value,
            updatedProcesses[index].operation_time.typeOfTimeMeasurement
          ).getTime()
        : '',
    };

    setFieldValue(
      `contingencyAssets.actives[${index}].operation_time`,
      operationTime
    );
  };

  const updateTypeOfTimeMeasurement = (index, value) => {
    const updatedProcesses = values.contingencyAssets?.actives;
    const registrationDate = new Date();

    const operationTime = {
      ...updatedProcesses[index].operation_time,
      typeOfTimeMeasurement: value,
      registrationDate: registrationDate.getTime(),
      maximumDate: incrementDate(
        registrationDate,
        updatedProcesses[index].operation_time.time,
        value
      ).getTime(),
    };

    setFieldValue(
      `contingencyAssets.actives[${index}].operation_time`,
      operationTime
    );
  };

  const updateSolution = (index, value) => {
    setFieldValue(`contingencyAssets.actives[${index}].solution`, value);
  };

  return (
    <Container>
      <Title>
        {i18next.t('technologiesAsset.tabs.contingencyAssets.label')}
      </Title>
      <Grid container style={{ marginBottom: '10rem' }}>
        {values.contingencyAssets?.actives.map((t, index) => (
          <>
            <Grid container spacing={4} style={{ margin: 10 }}>
              <Grid item xs={3}>
                <TextInput
                  margin={5}
                  label={i18next.t(
                    'technologiesAsset.tabs.contingencyAssets.maxTime'
                  )}
                  value={t.operation_time?.time}
                  type="number"
                  min="1"
                  onChange={(e) => updateTime(index, e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <SecondarySelect
                  label={i18next.t(
                    'technologiesAsset.tabs.contingencyAssets.unitOfMeasurement'
                  )}
                  placeholder="selecionar"
                  name="impact"
                  touched={touched?.contingencyAssets}
                  error={
                    errors.contingencyAssets?.actives[index]?.operation_time
                      ?.typeOfTimeMeasurement
                  }
                  value={t.operation_time?.typeOfTimeMeasurement}
                  onChange={(e) => updateTypeOfTimeMeasurement(index, e)}
                  options={[
                    {
                      label: `${i18next.t(
                        'common.typeOfTimeMeasurement.minutes'
                      )}`,
                      value: 'minutes',
                    },
                    {
                      label: `${i18next.t(
                        'common.typeOfTimeMeasurement.hour'
                      )}`,
                      value: 'hour',
                    },
                    {
                      label: `${i18next.t('common.typeOfTimeMeasurement.day')}`,
                      value: 'day',
                    },
                    {
                      label: `${i18next.t(
                        'common.typeOfTimeMeasurement.week'
                      )}`,
                      value: 'week',
                    },
                    {
                      label: `${i18next.t(
                        'common.typeOfTimeMeasurement.month'
                      )}`,
                      value: 'month',
                    },
                  ]}
                />
              </Grid>
              <Grid container spacing={4} style={{ marginBottom: 10 }}>
                <Grid item xs={4}>
                  <SecondarySelect
                    label={i18next.t(
                      'technologiesAsset.tabs.contingencyAssets.type'
                    )}
                    value={
                      values?.contingencyAssets?.actives[index]?.asset_type
                    }
                    options={types}
                    name="type"
                    onChange={(e) =>
                      setFieldValue(
                        `contingencyAssets.actives[${index}].asset_type`,
                        e
                      )
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <SecondarySelect
                    label={i18next.t(
                      'technologiesAsset.tabs.contingencyAssets.assetLabel'
                    )}
                    name="asset_id"
                    value={values.contingencyAssets.actives[index].asset_id}
                    onChange={(e) =>
                      setFieldValue(
                        `contingencyAssets.actives[${index}].asset_id`,
                        e
                      )
                    }
                    options={
                      values.contingencyAssets?.actives[index]?.asset_type ===
                      'user'
                        ? peopleEnum
                        : values.contingencyAssets?.actives[index]
                            ?.asset_type === 'process'
                        ? processesEnum
                        : values.contingencyAssets?.actives[index]
                            ?.asset_type === 'locality'
                        ? businessUnitsEnum
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <SecondarySelect
                    label={i18next.t(
                      'technologiesAsset.tabs.impact.impactLabel'
                    )}
                    name="impact"
                    value={values.contingencyAssets.actives[index].impact}
                    onChange={(e) =>
                      setFieldValue(
                        `contingencyAssets.actives[${index}].impact`,
                        e
                      )
                    }
                    options={[
                      {
                        label: `1 - ${i18next.t('common.impacts.negligible')}`,
                        value: 1,
                      },
                      {
                        label: `2 - ${i18next.t('common.impacts.little')}`,
                        value: 2,
                      },
                      {
                        label: `3 - ${i18next.t('common.impacts.medium')}`,
                        value: 3,
                      },
                      {
                        label: `4 - ${i18next.t('common.impacts.larger')}`,
                        value: 4,
                      },
                      {
                        label: `5 - ${i18next.t('common.impacts.extreme')}`,
                        value: 5,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={9}>
              <TextArea
                label={i18next.t(
                  'technologiesAsset.tabs.contingencyAssets.solutionLabel'
                )}
                placeholder="Ex: Servidor"
                name="solution"
                value={t.solution}
                onChange={(e) => updateSolution(index, e.target.value)}
              />
            </Grid>
            <Divider />
          </>
        ))}
        <Grid item xs={10}>
          <AddMore onClick={addNew} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Actives;
