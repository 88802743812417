import React, { useCallback, useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { useDispatch } from 'react-redux';
import { number, func, objectOf } from 'prop-types';
import i18next from 'i18next';

import { useDropzone } from 'react-dropzone';
import TicketsActions from '~/store/ducks/tickets';

import Permission from '~/components/Permission';

import {
  Container,
  MathPlusIcon,
  Row,
  WrapperIcon,
  Attachment1Icon,
  Attachment2Icon,
  ButtonWrapper,
} from './styles';

function ActionButton({ protocol, file, setFile, handleSendFileThroughChat }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [choice, setChoice] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  useEffect(() => {
    if (file) {
      if (choice === 1) {
        dispatch(TicketsActions.uploadTicketDocumentRequest(file, protocol));
        setAnchorEl(null);
      } else {
        handleSendFileThroughChat();
        setAnchorEl(null);
      }
    }
  }, [choice, file]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
    });

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <MathPlusIcon onClick={handleClick} />
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '24rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Row
          onClick={() => {}}
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <Permission action="demandDocument.upload">
            <ButtonWrapper type="button" onClick={() => setChoice(1)}>
              <WrapperIcon>
                <Attachment1Icon />
              </WrapperIcon>
              {i18next.t('demandManagement.chat.attachToDemand')}
              <input {...getInputProps()} type="file" />
            </ButtonWrapper>
          </Permission>
        </Row>

        <Row
          onClick={() => {}}
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <ButtonWrapper type="button" onClick={() => setChoice(2)}>
            <WrapperIcon>
              <Attachment2Icon />
            </WrapperIcon>
            {i18next.t('demandManagement.chat.attachToChat')}
            <input {...getInputProps()} type="file" />
          </ButtonWrapper>
        </Row>
      </Popover>
    </>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  protocol: number.isRequired,
  file: objectOf.isRequired,
  setFile: func.isRequired,
  handleSendFileThroughChat: func.isRequired,
};
