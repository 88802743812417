import React, { useState } from 'react';
import { objectOf, oneOfType, any, func, bool } from 'prop-types';
import { useLocation } from 'react-router-dom';

import {
  Container,
  WrapperTop,
  TopText,
  IconArrow,
  WrapperAccordion,
  AccordionItem,
  AccordionText,
  Indicator,
  TopIndicator,
} from './styles';

function Accordion({ data, onClick, active }) {
  const [opened, setOpened] = useState(active);
  const { pathname } = useLocation();
  return (
    <Container>
      <WrapperTop
        onClick={() => {
          setOpened((e) => !e);
        }}
        opened={opened}
      >
        <data.icon active={active} />
        <TopText active={active}>{data.name}</TopText>
      </WrapperTop>
      <IconArrow opened={opened} />
      {!opened && active && (
        <TopIndicator
          layoutId="drawerIndicator"
          initial={false}
          transition={{
            duration: 100,
            type: 'spring',
            stiffness: 500,
            damping: 30,
          }}
        />
      )}
      <WrapperAccordion opened={opened} qty={data.accordion_items.length}>
        {data.accordion_items.map((item) => (
          <AccordionItem
            onClick={() => (item.path ? onClick(item.path) : item.onClick())}
            active={item.path === pathname}
          >
            <AccordionText active={item.path === pathname}>
              {item.name}
            </AccordionText>
            {opened && item.path === pathname && (
              <Indicator
                layoutId="drawerIndicator"
                initial={false}
                transition={{
                  duration: 100,
                  type: 'spring',
                  stiffness: 500,
                  damping: 30,
                }}
              />
            )}
          </AccordionItem>
        ))}
      </WrapperAccordion>
    </Container>
  );
}

export default Accordion;

Accordion.propTypes = {
  data: objectOf(oneOfType([any])).isRequired,
  onClick: func.isRequired,
  active: bool.isRequired,
};
