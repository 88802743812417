/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import DemandActions from '~/store/ducks/demand';

import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import SearchInput from '~/components/TextInput/Search';
import { dateSortMethod } from '~/utils';

import Spinner from '~/components/Spinner';
import StatusTag from './components/StatusTag';

import { Container, Header, Title, SearchWrapper } from './styles';

const DemandManagement = () => {
  const dispatch = useDispatch();
  const { isLoading, demandsHistory } = useSelector((state) => state.demands);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (searchValue === '') {
      dispatch(DemandActions.getDemandsHistoryRequest());
    } else {
      dispatch(DemandActions.searchDemandsHistoryRequest(searchValue));
    }
  }, [searchValue]);

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Header>
            <Title>{i18next.t('callOpening.demandHistory.title')}</Title>
            <SearchWrapper>
              <SearchInput
                placeholder={i18next.t('common.search')}
                value={searchValue}
                onChange={setSearchValue}
                delay={1500}
                hasDebounce
              />
            </SearchWrapper>
          </Header>
        </Grid>
        <Grid item xs={12}>
          <Card padding="2rem 0">
            <Table
              columns={[
                {
                  Header: i18next.t('callOpening.demandHistory.date'),
                  accessor: 'updatedAt',
                  Cell: (e) => format(new Date(e.value), 'dd/MM'),
                  sortType: (a, b) => {
                    return dateSortMethod(a.values.date, b.values.date);
                  },
                },
                {
                  Header: i18next.t('callOpening.demandHistory.protocol'),
                  accessor: 'id',
                },
                {
                  Header: i18next.t('callOpening.demandHistory.problem'),
                  accessor: 'subject',
                },
                {
                  Header: i18next.t('callOpening.demandHistory.responsible'),
                  accessor: 'responsible.name',
                  Cell: (e) => {
                    if (!e.value) {
                      return '-';
                    }
                    return e.value;
                  },
                },
                {
                  Header: i18next.t('callOpening.demandHistory.status'),
                  accessor: 'status',
                  Cell: (e) => <StatusTag item={e.value} />,
                },
              ]}
              data={
                typeof demandsHistory.count !== 'undefined' &&
                demandsHistory.count > 0
                  ? demandsHistory.rows
                  : []
              }
              onRowClick={(original) => {
                dispatch(DemandActions.getDemandDetailsRequest(original.id));
              }}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DemandManagement;
