import React from 'react';
import { string } from 'prop-types';

import { Container, Wrapper } from './styles';

const ImpactTag = ({ item }) => {
  return (
    <Container>
      <Wrapper status={item}>{item}</Wrapper>
    </Container>
  );
};

export default ImpactTag;

ImpactTag.propTypes = {
  item: string.isRequired,
};
