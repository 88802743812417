import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { LogoIcon } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  background-color: #fafafc;
`;

export const WrapperContent = styled.div`
  flex: 1;
  position: relative;
  padding-top: 0;
  transition: 300ms ease;
  margin: 2rem;

  @media (max-width: 1120px) {
    position: absolute;
    width: 100%;
    margin: auto;
  }
`;

export const Content = styled.div``;

export const Drawer = styled.div`
  flex: 1;
  max-width: 48.2rem;
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
  display: flex;
  line-height: 41px;

  @media (max-width: 1120px) {
    transform: ${({ drawerOpened }) =>
      drawerOpened ? 'translateX(0)' : 'translateX(-48.2rem)'};
  }

  ${({ noDrawer }) =>
    noDrawer &&
    css`
      transform: translateX(-48.2rem)};
    `}

  transition: 300ms ease;
`;

export const LogoRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  justify-content: center;
  align-items: center;
`;

export const UserWrapper = styled.div`
  margin-top: 8.2rem;
  padding: 2rem;
  width: 100%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.cardText};
  font-size: 4.2rem;
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.Poppins};
  hyphens: auto;
`;

export const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  margin-top: 2.8rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.6rem;
  font-family: ${({ theme }) => theme.fonts.Poppins};
`;

export const Logo = styled(LogoIcon)`
  width: 26.2rem;
`;
