import { FaPlusSquare } from 'react-icons/fa';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
  padding-bottom: ${({ error }) => (error ? 2.5 : 0)}rem;
  transition: all 300ms ease;
`;

export const Label = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.base[400]};
  font-weight: 500;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;

  ${({ oneLineLabel }) =>
    oneLineLabel &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4rem;
    `};

  width: 100%;
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;

export const PlusIcon = styled(FaPlusSquare).attrs({
  size: 20,
  color: '#539ADA',
})`
  margin-right: 1rem;
`;

export const ExpandWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const ExpandIcon = styled(MdExpandMore).attrs({
  size: 20,
  color: '#539ADA',
})`
  margin-right: 1rem;
  cursor: pointer;
  min-width: 20px;
`;

export const ExpandLessIcon = styled(MdExpandLess).attrs({
  size: 20,
  color: '#539ADA',
})`
  margin-right: 1rem;
  cursor: pointer;
  min-width: 20px;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionLabel = styled.span`
  margin-left: 1.5rem;
  font-weight: 500;
  font-size: 1.4rem;
`;

export const Square = styled.div`
  width: 15px;
  height: 15px;
  background: #ffffff;
  border: 1px solid #262a3a;
  border-radius: 5px;
`;

export const SquareSelected = styled.div`
  width: 15px;
  height: 15px;
  background: #00bc61;
  border-radius: 5px;
`;
