/* eslint-disable react/destructuring-assignment */
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { string, number } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
// import { useSocket } from '~/hooks/socket';
import { FileIconImage } from '~/assets/icons';

import TicketsActions from '~/store/ducks/tickets';

import Card from '~/components/Card';
import ActionButton from './components/ActionButton';

import {
  Container,
  Header,
  Column,
  Subtitle,
  Title,
  WrapperChat,
  Chat,
  ChatAvatar,
  Bubble,
  Image,
  UsernameText,
  UsernameWrapper,
  ContentWrapper,
  Message,
  Time,
  InputToolbar,
  MathPlusButton,
  Composer,
  SendButton,
  SendIcon,
  FileIcon,
  FilenameWrapper,
} from './styles';

function RenderMessage(e, shouldShowUsername, userActive, handleDownloadFile) {
  switch (true) {
    case e.currentMessage.messageType === 'image' && !shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <UsernameWrapper>
            <UsernameText
              owner={e.currentMessage.user.id === userActive.user.id}
            >
              {e.currentMessage.user.name}
            </UsernameText>
          </UsernameWrapper>

          <ContentWrapper
            shouldShowUsername={shouldShowUsername}
            owner={e.currentMessage.user.id === userActive.user.id}
            // onClick={() => {
            //   if (e.currentMessage.messageType === 'image') {
            //     handleDownloadFile(e.currentMessage.text, e.currentMessage?.id);
            //   }
            // }}
            isFile={e.currentMessage.messageType === 'image'}
          >
            <Image
              src={`${
                process.env.NODE_ENV === 'development'
                  ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                  : process.env.REACT_APP_API_PROD_ENDPOINT
              }chat/img?file=${e.currentMessage.text}`}
            />

            <Time owner={e.currentMessage.user.id === userActive.user.id}>
              {format(
                e.currentMessage.createdAt
                  ? new Date(e.currentMessage.createdAt)
                  : new Date(),
                'HH:mm'
              )}
            </Time>
          </ContentWrapper>
        </Bubble>
      );
    case e.currentMessage.messageType === 'image' && shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <ContentWrapper
            shouldShowUsername={shouldShowUsername}
            owner={e.currentMessage.user.id === userActive.user.id}
            // onClick={() => {
            //   if (e.currentMessage.messageType === 'file') {
            //     handleDownloadFile(e.currentMessage.text, e.currentMessage?.id);
            //   }
            // }}
            isFile={e.currentMessage.messageType === 'file'}
          >
            <Image
              src={`${
                process.env.NODE_ENV === 'development'
                  ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                  : process.env.REACT_APP_API_PROD_ENDPOINT
              }chat/img?file=${e.currentMessage.text}`}
            />
            <Time owner={e.currentMessage.user.id === userActive.user.id}>
              {format(
                e.currentMessage.createdAt
                  ? new Date(e.currentMessage.createdAt)
                  : new Date(),
                'HH:mm'
              )}
            </Time>
          </ContentWrapper>
        </Bubble>
      );
    case e.currentMessage.messageType === 'file' && !shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <UsernameWrapper>
            <UsernameText
              owner={e.currentMessage.user.id === userActive.user.id}
            >
              {e.currentMessage.user.name}
            </UsernameText>
          </UsernameWrapper>

          <ContentWrapper
            shouldShowUsername={shouldShowUsername}
            owner={e.currentMessage.user.id === userActive.user.id}
            onClick={() => {
              if (e.currentMessage.messageType === 'file') {
                handleDownloadFile(e.currentMessage.text, e.currentMessage?.id);
              }
            }}
            isFile={e.currentMessage.messageType === 'file'}
          >
            <FileIcon src={FileIconImage} alt="Arquivo" />
            <FilenameWrapper
              owner={e.currentMessage.user.id === userActive.user.id}
              shouldShowUsername={shouldShowUsername}
            >
              {e.currentMessage.text}
            </FilenameWrapper>
            <Time owner={e.currentMessage.user.id === userActive.user.id}>
              {format(
                e.currentMessage.createdAt
                  ? new Date(e.currentMessage.createdAt)
                  : new Date(),
                'HH:mm'
              )}
            </Time>
          </ContentWrapper>
        </Bubble>
      );
    case e.currentMessage.messageType === 'file' && shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <ContentWrapper
            shouldShowUsername={shouldShowUsername}
            owner={e.currentMessage.user.id === userActive.user.id}
            onClick={() => {
              if (e.currentMessage.messageType === 'file') {
                handleDownloadFile(e.currentMessage.text, e.currentMessage?.id);
              }
            }}
            isFile={e.currentMessage.messageType === 'file'}
          >
            <FileIcon src={FileIconImage} alt="Arquivo" />
            <FilenameWrapper
              owner={e.currentMessage.user.id === userActive.user.id}
              shouldShowUsername={shouldShowUsername}
            >
              {e.currentMessage.text}
            </FilenameWrapper>
            <Time owner={e.currentMessage.user.id === userActive.user.id}>
              {format(
                e.currentMessage.createdAt
                  ? new Date(e.currentMessage.createdAt)
                  : new Date(),
                'HH:mm'
              )}
            </Time>
          </ContentWrapper>
        </Bubble>
      );
    case e.currentMessage.messageType === 'text' && !shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <UsernameWrapper>
            <UsernameText
              owner={e.currentMessage.user.id === userActive.user.id}
            >
              {e.currentMessage.user.name}
            </UsernameText>
          </UsernameWrapper>

          <ContentWrapper
            shouldShowUsername={shouldShowUsername}
            owner={e.currentMessage.user.id === userActive.user.id}
          >
            <Message owner={e.currentMessage.user.id === userActive.user.id}>
              {e.currentMessage.text}
            </Message>
            <Time owner={e.currentMessage.user.id === userActive.user.id}>
              {format(
                e.currentMessage.createdAt
                  ? new Date(e.currentMessage.createdAt)
                  : new Date(),
                'HH:mm'
              )}
            </Time>
          </ContentWrapper>
        </Bubble>
      );
    case e.currentMessage.messageType === 'text' && shouldShowUsername:
      return (
        <Bubble
          shouldShowUsername={shouldShowUsername}
          owner={e.currentMessage.user.id === userActive.user.id}
        >
          <Message owner={e.currentMessage.user.id === userActive.user.id}>
            {e.currentMessage.text}
          </Message>
          <Time owner={e.currentMessage.user.id === userActive.user.id}>
            {format(
              e.currentMessage.createdAt
                ? new Date(e.currentMessage.createdAt)
                : new Date(),
              'HH:mm'
            )}
          </Time>
        </Bubble>
      );
    default:
      return <div />;
  }
}
function MessagesBox({ protocol, id }) {
  const dispatch = useDispatch();
  const { userActive } = useSelector((state) => state.auth);
  // const { socket } = useSocket();
  const [file, setFile] = useState(undefined);
  const [messages, setMessages] = useState([]);
  // const [lastMessage, setLastMessage] = useState({});

  // useEffect(() => {
  //   socket.on('messages', (data) => {
  //     const loadedMessages = data.messages.map((current) => {
  //       return {
  //         id: current.id,
  //         chat_id: current.chat_id,
  //         text:
  //           current.messageType === 'file'
  //             ? current.message.title
  //             : current.messageType === 'image'
  //             ? current.message.file
  //             : current.message.message,
  //         createdAt: current.createdAt,
  //         user: {
  //           id: current.user.id,
  //           name: current.user.name,
  //           avatar: current.user.avatar
  //             ? `${
  //                 process.env.NODE_ENV === 'development'
  //                   ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
  //                   : process.env.REACT_APP_API_PROD_ENDPOINT
  //               }users/avatar?avatar=${current.user.avatar}`
  //             : null,
  //         },
  //         user_id: data.user_id,
  //         messageType: current.messageType,
  //       };
  //     });

  //     setMessages(loadedMessages);
  //   });
  // }, []);

  // useEffect(() => {
  //   socket.on('message', (data) => {
  //     setLastMessage({
  //       id: data.id,
  //       chat_id: data.chat_id,
  //       text:
  //         data.messageType === 'file'
  //           ? data.message.title
  //           : data.messageType === 'image'
  //           ? data.message.file
  //           : data.message.message,
  //       createdAt: data.createdAt,
  //       user: {
  //         id: data.user.id,
  //         name: data.user.name,
  //         avatar: data.user.avatar
  //           ? `${
  //               process.env.NODE_ENV === 'development'
  //                 ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
  //                 : process.env.REACT_APP_API_PROD_ENDPOINT
  //             }users/avatar?avatar=${data.user.avatar}`
  //           : null,
  //       },
  //       user_id: data.user_id,
  //       messageType: data.messageType,
  //     });
  //   });
  // }, [socket]);

  // useEffect(() => {
  //   if (messages.length === 0 || messages[0].id !== lastMessage?.id) {
  //     const newMessages = [...messages];
  //     newMessages.unshift(lastMessage);
  //     setMessages(newMessages);
  //   }
  // }, [lastMessage]);

  function onSend(message, resetForm) {
    // socket.emit('send-message', {
    //   chat_id: id,
    //   messageType: 'text',
    //   message: { message },
    //   user_id: userActive.user.id,
    // });

    if (resetForm) resetForm({});
  }

  // function handleSendFileThroughChat() {
  //   const fileExtension = file.name.split('.').pop();
  //   if (
  //     fileExtension === 'jpg' ||
  //     fileExtension === 'jpeg' ||
  //     fileExtension === 'png' ||
  //     fileExtension === 'gif' ||
  //     fileExtension === 'webp'
  //   ) {
  //     dispatch(TicketsActions.uploadChatDocumentRequest(file, 'image', id));
  //   } else {
  //     dispatch(TicketsActions.uploadChatDocumentRequest(file, 'file', id));
  //   }
  // }

  function handleDownloadFile(fileName, messageId) {
    dispatch(
      TicketsActions.downloadChatDocumentRequest(fileName, messageId, id)
    );
  }

  const { values, handleChange, handleSubmit } = useFormik({
    onSubmit: (v, { resetForm }) => onSend(v.message, resetForm),
    initialValues: {
      message: '',
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(i18next.t('common.validation.required')),
    }),
    enableReinitialize: true,
  });

  return (
    <Card padding="0.9rem 0 2.7rem 0">
      <Container>
        <Header>
          <Column>
            <Subtitle>{i18next.t('demandManagement.chat.chatTitle')}</Subtitle>
            <Title>{`#${protocol}`}</Title>
          </Column>
        </Header>
        <WrapperChat>
          <Chat
            messages={messages}
            user={userActive.user}
            dateFormat="DD/MM/YYYY"
            isAnimated
            showUserAvatar
            renderAvatar={(e) => (
              <ChatAvatar src={e.currentMessage.user.avatar} />
            )}
            renderAvatarOnTop
            renderBubble={(e) => {
              const shouldShowUsername =
                e.isSameUser(e.currentMessage, e.previousMessage) &&
                e.isSameDay(e.currentMessage, e.previousMessage);
              return RenderMessage(
                e,
                shouldShowUsername,
                userActive,
                handleDownloadFile
              );
            }}
            renderInputToolbar={() => (
              <InputToolbar>
                <MathPlusButton>
                  <ActionButton
                    protocol={id}
                    setFile={setFile}
                    file={file}
                    // handleSendFileThroughChat={handleSendFileThroughChat}
                  />
                </MathPlusButton>
                <Composer
                  name="message"
                  placeholder={i18next.t('demandManagement.chat.instruction')}
                  autoComplete={false}
                  value={values.message}
                  onChange={handleChange}
                  onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                />
                <SendButton type="submit" onClick={handleSubmit}>
                  <SendIcon />
                </SendButton>
              </InputToolbar>
            )}
          />
        </WrapperChat>
      </Container>
    </Card>
  );
}

export default MessagesBox;

MessagesBox.propTypes = {
  protocol: string.isRequired,
  id: number.isRequired,
};
