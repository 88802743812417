import { Formik } from 'formik';
import { bool, func } from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import TermsModal from '~/components/Modal';
import TextArea from '~/components/TextArea';
import AuthActions from '~/store/ducks/auth';
import TextInput from '~/components/TextInput';
import i18next from 'i18next';
import { ButtonWrapper, Container, Content, Desciption } from './styles';
import schema from './validationSchema';
import { SppinerIcon } from '../../Company/CompanyData/styles';

export default function ModalDeleteAccount({ open, onClose }) {
  const { isLoadingDeleteAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const submit = (values) => {
    dispatch(
      AuthActions.deleteUserRequest(values.description, values.password)
    );
  };

  return (
    <TermsModal title="Excluir conta" open={open} onClose={onClose}>
      <Container>
        <Desciption>
          Você esta prestes a excluir sua conta. Gostariamos de saber o motivo
        </Desciption>

        <Formik
          initialValues={{
            description: '',
            password: '',
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <Content>
              <TextArea
                label="Descrição"
                placeholder="Digite aqui"
                name="description"
                value={values.description}
                onChange={handleChange}
                TextArea
              />

              <TextInput
                label="Confirme sua senha"
                name="password"
                onChange={handleChange}
                value={values.password}
                touched={touched.password}
                error={errors.password}
                onSubmit={handleSubmit}
                type="password"
              />

              <ButtonWrapper>
                <SecondaryButton
                  width="22rem"
                  type="button"
                  label="Cancelar"
                  onClick={onClose}
                />

                <PrimaryButton
                  type="submit"
                  width="22rem"
                  label={
                    isLoadingDeleteAccount ? (
                      <SppinerIcon />
                    ) : (
                      i18next.t('common.buttons.deleteAccount')
                    )
                  }
                  onClick={handleSubmit}
                />
              </ButtonWrapper>
            </Content>
          )}
        </Formik>
      </Container>
    </TermsModal>
  );
}

ModalDeleteAccount.propTypes = {
  onClose: func,
  open: bool,
};

ModalDeleteAccount.defaultProps = {
  onClose: () => {},
  open: false,
};
