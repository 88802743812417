import { put, all, takeLatest, call } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';

import api from '~/services/api';
import TicketActions, { TicketTypes } from '../ducks/tickets';

function* loadAllTickets({ page }) {
  try {
    let response;
    if (page) {
      response = yield call(api.get, `/tickets?page=${page}`);
    } else {
      response = yield call(api.get, '/tickets');
    }

    yield put(TicketActions.getAllTicketsSuccess(response.data));
  } catch (error) {
    yield put(TicketActions.getAllTicketsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchTickets({ query }) {
  try {
    const response = yield call(api.get, `/tickets?search=${query}`);

    yield put(TicketActions.searchTicketsSuccess(response.data));
  } catch (error) {
    yield put(TicketActions.searchTicketsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* loadTicketDetails({ id }) {
  try {
    const response = yield call(api.get, `/tickets/${id}`);

    yield put(TicketActions.getTicketDetailsSuccess(response.data));
    yield put(push(`/service-details/${id}`));
  } catch (error) {
    yield put(TicketActions.getTicketDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* loadTicketHistory({ id }) {
  try {
    const response = yield call(api.get, `/tickets/${id}/history`);

    yield put(TicketActions.getTicketHistorySuccess(response.data));
  } catch (error) {
    yield put(TicketActions.getTicketHistoryFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* ticketReply({ data: { id, message, file } }) {
  try {
    const answerData = convertFormData({
      description: message,
    });

    if (file) answerData.append('attachment', file);

    yield call(api.post, `/tickets/${id}/reply`, answerData);

    yield put(TicketActions.ticketReplySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Resposta ao atendimento enviada!',
      })
    );
    yield put(push(`/demand-management/`));
  } catch (error) {
    yield put(TicketActions.ticketReplyFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha ao enviar resposta',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* downloadTickets() {
  try {
    const response = yield call(api.get, `/tickets/download_xlsx`, {
      params: {
        ticket: [
          'createdAt',
          'id',
          'subject',
          'due_date',
          'priority',
          'status',
        ],
        client: ['name', 'email'],
        responsible: ['name'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'demandas.xlsx');

    yield put(TicketActions.downloadTicketsSuccess());
  } catch (error) {
    yield put(TicketActions.downloadTicketsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadTicketDocument({ file, ticketId }) {
  try {
    const formData = new FormData();

    if (file) formData.append('attachment', file);

    yield call(api.put, `/tickets/${ticketId}/uploadDocument`, formData);

    yield put(TicketActions.uploadTicketDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Upload efetuado!',
      })
    );
  } catch (error) {
    yield put(TicketActions.uploadTicketDocumentFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha ao fazer upload de documento',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* downloadTicketDocument({ documentName, documentId, ticketId }) {
  try {
    const response = yield call(
      api.get,
      `/tickets/${ticketId}/document/${documentId}/download`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, `${documentName}`);

    yield put(TicketActions.downloadTicketDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Download iniciado!',
      })
    );
  } catch (error) {
    yield put(TicketActions.downloadTicketDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao fazer download do documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadChatDocument({ file, fileType, ticketId }) {
  try {
    const formData = new FormData();
    formData.append('chat_id', ticketId);

    formData.append('message_type', fileType);

    if (file) formData.append('file', file);

    yield call(api.post, '/chat/uploadFile', formData);

    yield put(TicketActions.uploadChatDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Upload efetuado!',
      })
    );
  } catch (error) {
    yield put(TicketActions.uploadChatDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao fazer upload de documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadChatDocument({ fileName, messageId, ticketId }) {
  try {
    const response = yield call(
      api.get,
      `/chat/${ticketId}/downloadFile/${messageId}`,
      {
        responseType: 'blob',
      }
    );

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, `${fileName}`);

    yield put(TicketActions.downloadChatDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Download iniciado!',
      })
    );
  } catch (error) {
    yield put(TicketActions.downloadChatDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao fazer download do documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(TicketTypes.GET_ALL_TICKETS_REQUEST, loadAllTickets),
  takeLatest(TicketTypes.GET_TICKET_DETAILS_REQUEST, loadTicketDetails),
  takeLatest(TicketTypes.GET_TICKET_HISTORY_REQUEST, loadTicketHistory),
  takeLatest(TicketTypes.TICKET_REPLY_REQUEST, ticketReply),
  takeLatest(TicketTypes.SEARCH_TICKETS_REQUEST, searchTickets),
  takeLatest(TicketTypes.DOWNLOAD_TICKETS_REQUEST, downloadTickets),
  takeLatest(TicketTypes.UPLOAD_TICKET_DOCUMENT_REQUEST, uploadTicketDocument),
  takeLatest(
    TicketTypes.DOWNLOAD_TICKET_DOCUMENT_REQUEST,
    downloadTicketDocument
  ),
  takeLatest(TicketTypes.UPLOAD_CHAT_DOCUMENT_REQUEST, uploadChatDocument),
  takeLatest(TicketTypes.DOWNLOAD_CHAT_DOCUMENT_REQUEST, downloadChatDocument),
]);
