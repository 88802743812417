import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFormikContext } from 'formik';
import TextInput from '~/components/TextInput';
import Accordion from '~/components/Accordion';
import DatePicker from '~/components/DatePicker';
import { SecondarySelect, MultiSelect } from '~/components/Select';
import AddMore from '~/components/Button/AddButton';
import ProcessCard from '~/components/ProcessCard';

import { Container, Divider, WrapperMulti } from './styles';

const ContingencyActivationProcess = () => {
  const { peopleEnum } = useSelector((state) => state.people);
  const {
    values,
    //  handleChange, touched, errors, handleBlur,
    setFieldValue,
  } = useFormikContext();

  const addNew = () => {
    const newProcess = [
      ...values.pcn?.activationProcess.processes,
      { description: '', asset_id: '', document_id: '' },
    ];
    setFieldValue('pcn.activationProcess.processes', newProcess);
    // setPcn({
    //   ...pcn,
    //   activationProcess: { ...pcn.activationProcess, processes: newProcess },
    // });
  };

  const updateProcess = (index, updated) => {
    const newProcesses = values.pcn?.activationProcess.processes;
    newProcesses[index] = { ...newProcesses[index], ...updated };
    // setPcn({
    //   ...pcn,
    //   activationProcess: { ...pcn.activationProcess, processes: newProcesses },
    // });
    setFieldValue('pcn.activationProcess.processes', newProcesses);
  };

  const moveCardHandler = (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const dragItem = values.pcn?.activationProcess.processes[source.index];
    const newProcesses = [...values.pcn?.activationProcess.processes];
    newProcesses.splice(source.index, 1);
    newProcesses.splice(destination.index, 0, dragItem);

    // setPcn((oldState) => ({
    //   ...oldState,
    //   activationProcess: {
    //     ...oldState.activationProcess,
    //     processes: newProcesses,
    //   },
    // })
    setFieldValue('pcn.activationProcess.processes', newProcesses);
  };

  const removeItem = (pcnIndex) => {
    const result = values.pcn?.activationProcess.processes;
    result.splice(pcnIndex, 1);
    setFieldValue('pcn.activationProcess.processes', result);
  };

  return (
    <Accordion
      title={i18next.t(
        'technologiesAsset.tabs.pcn.contingencyActivation.title'
      )}
    >
      <Grid container spacing={4}>
        <Grid item xs={4}>
          <WrapperMulti>
            <MultiSelect
              label={i18next.t(
                'technologiesAsset.tabs.pcn.contingencyActivation.contingencyTriggerResponsiblesLabel'
              )}
              oneLineLabel={false}
              options={peopleEnum}
              onChange={(e) =>
                setFieldValue('pcn.activationProcess.actuation', e)
              }
              value={values.pcn?.activationProcess?.actuation}
            />
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <MultiSelect
              label={i18next.t(
                'technologiesAsset.tabs.pcn.contingencyActivation.contingencyTesteResponsiblesLabel'
              )}
              oneLineLabel={false}
              options={peopleEnum}
              onChange={(e) => setFieldValue('pcn.activationProcess.tests', e)}
              value={values.pcn?.activationProcess?.tests}
            />
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <DatePicker
              value={values.pcn?.activationProcess?.revisionDate}
              onChange={(e) =>
                setFieldValue('pcn.activationProcess.revisionDate', e)
              }
              formatMask="dd/MM/yy"
            >
              <TextInput
                label={i18next.t(
                  'technologiesAsset.tabs.pcn.contingencyActivation.revisionDateLabel'
                )}
                placeholder="DD/MM/AA"
              />
            </DatePicker>
          </WrapperMulti>
        </Grid>
        <Grid item xs={4}>
          <WrapperMulti>
            <SecondarySelect
              label={i18next.t(
                'technologiesAsset.tabs.pcn.contingencyActivation.revisionPeriodLabel'
              )}
              options={[
                {
                  label: i18next.t(
                    'technologiesAsset.tabs.pcn.contingencyActivation.weekly'
                  ),
                  value: 'Semanal',
                },
                {
                  label: i18next.t(
                    'technologiesAsset.tabs.pcn.contingencyActivation.yearly'
                  ),
                  value: 'Anual',
                },
              ]}
              value={values.pcn?.activationProcess?.period}
              onChange={(e) => setFieldValue('pcn.activationProcess.period', e)}
            />
          </WrapperMulti>
        </Grid>
      </Grid>
      <Divider />
      <DragDropContext onDragEnd={moveCardHandler}>
        <Droppable droppableId="Processo de ativação da contingência">
          {(provided) => (
            <Container
              ref={provided.innerRef}
              {...provided.droppableProps}
              container
              spacing={4}
              style={{ marginBottom: '10rem' }}
            >
              {values.pcn?.activationProcess.processes.map((process, index) => (
                <ProcessCard
                  index={index}
                  process={process}
                  onRemove={removeItem}
                  updateProcess={updateProcess}
                  moveCardHandler={moveCardHandler}
                />
              ))}
              {provided.placeholder}
              <Grid item xs={12} style={{ marginTop: '2rem' }}>
                <AddMore onClick={addNew} />
              </Grid>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    </Accordion>
  );
};

export default ContingencyActivationProcess;
