import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import i18next from 'i18next';
import { func } from 'prop-types';
import React from 'react';
import { PrimaryButton } from '~/components/Button';
import { SecondarySelect } from '~/components/Select';
import TextInput from '~/components/TextInput';
// import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import {
  Button,
  Container,
  Footer,
  Hr,
  PlusIcon,
  ThirdPartiesWrapper,
  Title,
  WrapperButton,
} from './styles';
import MultiMappinSelect from '../SelectMappin';
import { ThirdPartieCard } from './ThirdPartieCard';

const ProcessDetails = ({ handleNext }) => {
  const theme = useTheme();
  // const [selectedDepartament, setSelectedDepartament] = useState([]);
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  // const dispatch = useDispatch();
  const { departaments } = useSelector((state) => state.config);

  const {
    purposesEnum,
    formsEnum,
    categoriesCollectedEnum,
    transparencyHolderEnum,
    collectedDataEnum,
    legalBasisAssessmentEnum,
    frequenciesEnum,
    inOutTransmissionsEnum,
    storageFormsEnum,
  } = useSelector((state) => state.iventory);

  const { processesEnum } = useSelector((state) => state.processes);

  const {
    values,
    errors,
    touched,
    handleChange,
    // handleBlur,
    setFieldValue,
  } = useFormikContext();

  // useEffect(() => {
  //   console.log('asdfasdf');
  // }, [values.departament]);

  // const { peopleResponsibleEnum, departmentEnum, businessCriticalityEnum } =
  //   useSelector((state) => state.iventory);
  // const { companyId } = useSelector((state) => state.auth);
  // const [newUserRole, setNewUserRole] = useState('');

  // const updateDepartments = () => {
  //   dispatch(IventoryActions.getDepartmentEnumRequest(companyId));
  // };

  // useEffect(() => {
  //   dispatch(IventoryActions.getPeopleResponsibleEnumRequest(companyId));
  //   updateDepartments();
  //   dispatch(IventoryActions.getBusinessCriticalityEnumRequest());
  // }, []);

  const handleAddProvider = () => {
    setFieldValue('processIdentification.providers', [
      ...values.processIdentification.providers,
      {},
    ]);
  };

  return (
    <Container>
      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12} sm={6} md={4}>
          <SecondarySelect
            label={i18next.t('iventory.tab.processDetails.department')}
            onChange={(e) => {
              setFieldValue('processIdentification.departament_id', e);
              // setSelectedDepartament(
              //   departaments.find((d) => d.id === e)?.processes
              // );
            }}
            options={departaments.map((d) => ({ label: d.name, value: d.id }))}
            value={values.processIdentification?.departament_id}
            error={errors.processIdentification?.departament_id}
            touched={touched.processIdentification?.departament_id}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SecondarySelect
            label={i18next.t('iventory.tab.processDetails.process')}
            onChange={(e) =>
              setFieldValue('processIdentification.process_id', e)
            }
            options={processesEnum}
            // options={selectedDepartament.map((d) => ({
            //   label: d.name,
            //   value: d.id,
            // }))}
            value={values.processIdentification?.process_id}
            error={errors.processIdentification?.process_id}
            touched={touched.processIdentification?.process_id}
          />
        </Grid>
      </Grid>

      <Hr />

      <Title>
        {i18next.t('iventory.tab.processDetails.collectionProcess.label')}
      </Title>
      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.collectionProcess.purposeOfCollection'
            )}
            options={[...purposesEnum]}
            value={values.processIdentification.purposeOfCollection}
            onChange={(e) =>
              setFieldValue('processIdentification.purposeOfCollection', e)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.collectionProcess.collectionForms'
            )}
            options={[...formsEnum]}
            value={values.processIdentification.collectionForms}
            onChange={(e) =>
              setFieldValue('processIdentification.collectionForms', e)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.collectionProcess.categoryOfDataCollected'
            )}
            options={[...categoriesCollectedEnum]}
            value={values.processIdentification.categoryOfDataCollected}
            onChange={(e) =>
              setFieldValue('processIdentification.categoryOfDataCollected', e)
            }
          />
        </Grid>
      </Grid>

      <Title>
        {i18next.t('iventory.tab.processDetails.legalFramework.label')}
      </Title>
      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12} sm={6} md={3}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.transparencyToTheHolder'
            )}
            options={[...transparencyHolderEnum]}
            value={values.processIdentification.transparencyToTheHolder}
            onChange={(e) =>
              setFieldValue('processIdentification.transparencyToTheHolder', e)
            }
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.collectedData'
            )}
            options={[...collectedDataEnum]}
            value={values.processIdentification.collectedData}
            onChange={(e) =>
              setFieldValue('processIdentification.collectedData', e)
            }
          />
        </Grid>

        <Grid item xs={2}>
          <TextInput
            margin={5}
            sm
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.retentionTime'
            )}
            name="processIdentification.retentionTime"
            value={values.processIdentification.retentionTime}
            type="number"
            min="1"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={2}>
          <SecondarySelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.timeUnit'
            )}
            sm
            name="processIdentification.timeUnit"
            touched={touched?.timeUnit}
            value={values.processIdentification.timeUnit}
            onChange={(e) => setFieldValue('processIdentification.timeUnit', e)}
            options={[
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.minutes')}`,
                value: 'minutes',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.hour')}`,
                value: 'hour',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.day')}`,
                value: 'day',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.week')}`,
                value: 'week',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.month')}`,
                value: 'month',
              },
            ]}
          />
        </Grid>
      </Grid>

      <Hr />

      <Grid container spacing={isSmall ? 2 : 8}>
        <Grid item xs={12} sm={6} md={3}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.legalBasis'
            )}
            sm
            options={[...legalBasisAssessmentEnum]}
            value={values.processIdentification.legalBasis}
            onChange={(e) =>
              setFieldValue('processIdentification.legalBasis', e)
            }
            error={errors.legalBasis}
            touched={touched.legalBasis}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.processUpdateFrequency'
            )}
            sm
            onChange={(e) =>
              setFieldValue('processIdentification.processUpdateFrequency', e)
            }
            options={[...frequenciesEnum]}
            value={values.processIdentification.processUpdateFrequency}
            error={errors.processIdentification?.processUpdateFrequency}
            touched={touched.processIdentification?.processUpdateFrequency}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <SecondarySelect
            label={i18next.t(
              'iventory.tab.processDetails.legalFramework.alignedAdjustments'
            )}
            sm
            onChange={(e) =>
              setFieldValue('processIdentification.alignedAdjustments', e)
            }
            options={[
              { label: 'Sim', value: 'yes' },
              { label: 'Não', value: 'no' },
            ]}
            value={values.processIdentification?.alignedAdjustments}
            error={errors.processIdentification?.alignedAdjustments}
            touched={touched.processIdentification?.alignedAdjustments}
          />
        </Grid>
      </Grid>

      <Hr />

      <Title>
        {i18next.t('iventory.tab.processDetails.dataInMotion.label')}
      </Title>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            options={[...inOutTransmissionsEnum]}
            label={i18next.t(
              'iventory.tab.processDetails.dataInMotion.iNTransmission'
            )}
            sm
            name="processIdentification.iNTransmission"
            onChange={(e) =>
              setFieldValue('processIdentification.iNTransmission', e)
            }
            value={values.processIdentification.iNTransmission}
            // touched={touched.processIdentification?.criticality}
            // error={errors.processIdentification?.criticality}
            // onBlur={handleBlur('processIdentification.criticality')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            options={[...inOutTransmissionsEnum]}
            label={i18next.t(
              'iventory.tab.processDetails.dataInMotion.oUTTransmission'
            )}
            sm
            name="processIdentification.oUTTransmission"
            onChange={(e) =>
              setFieldValue('processIdentification.oUTTransmission', e)
            }
            value={values.processIdentification.oUTTransmission}
            // touched={touched.processIdentification?.criticality}
            // error={errors.processIdentification?.criticality}
            // onBlur={handleBlur('processIdentification.criticality')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.dataInMotion.collectionMedium'
            )}
            options={[
              { label: 'Opção 1', value: '1' },
              { label: 'Opção 2', value: '2' },
            ]}
            value={values.processIdentification.collectionMedium}
            onChange={(e) =>
              setFieldValue('processIdentification.collectionMedium', e)
            }
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <MultiMappinSelect
            label={i18next.t(
              'iventory.tab.processDetails.dataInMotion.storageforms'
            )}
            options={[...storageFormsEnum]}
            value={values.processIdentification.storageforms}
            onChange={(e) =>
              setFieldValue('processIdentification.storageforms', e)
            }
          />
        </Grid>
      </Grid>

      <Hr />

      <Title>
        {i18next.t('iventory.tab.processDetails.thirdParties.label')}
      </Title>

      <ThirdPartiesWrapper>
        {values.processIdentification.providers.map((provider, index) => (
          <ThirdPartieCard
            position={index}
            value={provider}
            setFieldValue={setFieldValue}
          />
        ))}
        <WrapperButton>
          <Button onClick={handleAddProvider}>
            <PlusIcon />
            {i18next.t('common.buttons.add')}
          </Button>
        </WrapperButton>
      </ThirdPartiesWrapper>

      <Hr />
      <Footer>
        <Grid container spacing={isSmall ? 1 : 4} justify="flex-end">
          <Grid item xs={12} md={2} lg={2}>
            <PrimaryButton
              label={i18next.t('iventory.tab.next')}
              onClick={handleNext}
            />
          </Grid>
        </Grid>
      </Footer>
    </Container>
  );
};

export default ProcessDetails;

ProcessDetails.propTypes = {
  handleNext: func.isRequired,
};
