import React, { useState } from 'react';
import { objectOf, func, bool, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import DocumentActions from '~/store/ducks/documents';

import Modal from '~/components/Modal';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { TertiarySelect } from '~/components/Select';

import { Container, WrapperButton, Title, TitleContainer } from './styles';

function MoveDocumentModal({
  document,
  onClose,
  open,
  reloadDocuments,
  oldId,
}) {
  const dispatch = useDispatch();
  const [newFolder, setNewFolder] = useState();
  const { folders } = useSelector((state) => state.documents);

  function handleMoveDocument() {
    dispatch(
      DocumentActions.moveDocumentRequest(
        document.id,
        oldId,
        newFolder,
        reloadDocuments
      )
    );
    onClose();
  }

  return (
    <Modal
      title={i18next.t('documents.moveDocumentModal.title')}
      open={open}
      onClose={onClose}
    >
      <Container>
        <TitleContainer>
          <Title>{i18next.t('documents.moveDocumentModal.chooseFolder')}</Title>
          <TertiarySelect
            options={folders?.rows?.map((item) => {
              return {
                value: item?.id,
                label: item?.name,
              };
            })}
            onChange={(e) => setNewFolder(e)}
          />
        </TitleContainer>

        <WrapperButton>
          <SecondaryButton
            label={i18next.t('common.buttons.cancel')}
            padding="0 5.8rem"
            width="null"
            onClick={() => onClose()}
          />
          <PrimaryButton
            label={i18next.t('common.buttons.move')}
            padding="0 5.8rem"
            width="null"
            onClick={() => handleMoveDocument()}
          />
        </WrapperButton>
      </Container>
    </Modal>
  );
}

export default MoveDocumentModal;

MoveDocumentModal.propTypes = {
  document: objectOf.isRequired,
  onClose: func.isRequired,
  open: bool.isRequired,
  reloadDocuments: func.isRequired,
  oldId: string.isRequired,
};
