import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  getHoldersRequest: ['page', 'query'],
  getHoldersSuccess: ['holders'],
  getHoldersFailure: [''],

  loadLastestTicketRequest: ['id'],
  loadLastestTicketSuccess: ['ticket'],
  loadLastestTicketFailure: [''],

  loadTicketsRequest: ['id', 'page'],
  loadTicketsSuccess: ['tickets'],
  loadTicketsFailure: [''],

  setTicketPriorityRequest: ['id', 'priority'],
  setTicketPrioritySuccess: [''],
  setTicketPriorityFailure: [''],

  setTicketDueDateRequest: ['id', 'dueDate'],
  setTicketDueDateSuccess: [''],
  setTicketDueDateFailure: [''],

  reOpenTicketRequest: ['id', 'justification'],
  reOpenTicketSuccess: [''],
  reOpenTicketFailure: [''],

  answerTicketRequest: ['data'],
  answerTicketSuccess: [''],
  answerTicketFailure: [''],

  downloadHoldersRequest: [''],
  downloadHoldersSuccess: [''],
  downloadHoldersFailure: [''],
});

export const HoldersTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  holders: {
    count: null,
    rows: [],
  },
  lastTicket: {
    id: null,
    subject: null,
    description: null,
    priority: null,
    due_date: null,
    status: null,
    attachment: null,
    createdAt: null,
    updatedAt: null,
    client_id: null,
    responsible_id: null,
    client: {
      name: null,
      company_id: null,
      cpf: null,
      email: null,
      phone: null,
    },
    replies: [],
    documents: [],
  },
  tickets: {
    count: null,
    rows: [
      {
        id: null,
        subject: null,
        description: null,
        priority: null,
        due_date: null,
        status: null,
        attachment: null,
        createdAt: null,
        updatedAt: null,
        client_id: null,
        responsible_id: null,
        client: {
          name: null,
          company_id: null,
        },
        responsible: {
          name: null,
          id: null,
        },
      },
    ],
  },
});

/* Reducers */

const getHoldersRequest = (state) => state.merge({ isLoading: true });
const getHoldersSuccess = (state, { holders }) =>
  state.merge({
    isLoading: false,
    error: false,
    holders,
  });
const getHoldersFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    holders: {
      count: null,
      rows: [],
    },
  });

const loadLastestTicketRequest = (state) => state.merge({ isLoading: true });
const loadLastestTicketSuccess = (state, { ticket }) =>
  state.merge({
    isLoading: false,
    error: false,
    lastTicket: ticket,
  });
const loadLastestTicketFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    lastTicket: {
      id: null,
      subject: null,
      description: null,
      priority: null,
      due_date: null,
      status: null,
      attachment: null,
      createdAt: null,
      updatedAt: null,
      client_id: null,
      responsible_id: null,
      client: {
        name: null,
        company_id: null,
        cpf: null,
        email: null,
        phone: null,
      },
      replies: [],
      documents: [],
    },
  });

const loadTicketsRequest = (state) => state.merge({ isLoading: true });
const loadTicketsSuccess = (state, { tickets }) =>
  state.merge({
    isLoading: false,
    error: false,
    tickets,
  });
const loadTicketsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    tickets: {
      count: null,
      rows: [
        {
          id: null,
          subject: null,
          description: null,
          priority: null,
          due_date: null,
          status: null,
          attachment: null,
          createdAt: null,
          updatedAt: null,
          client_id: null,
          responsible_id: null,
          client: {
            name: null,
            company_id: null,
          },
          responsible: {
            name: null,
            id: null,
          },
        },
      ],
    },
  });

const setTicketPriorityRequest = (state) => state.merge({ isLoading: true });
const setTicketPrioritySuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const setTicketPriorityFailure = (state) => state;

const setTicketDueDateRequest = (state) => state.merge({ isLoading: true });
const setTicketDueDateSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const setTicketDueDateFailure = (state) => state;

const reOpenTicketRequest = (state) => state.merge({ isLoading: true });
const reOpenTicketSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const reOpenTicketFailure = (state) => state;

const answerTicketRequest = (state) => state.merge({ isLoading: true });
const answerTicketSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const answerTicketFailure = (state) => state;

const downloadHoldersRequest = (state) => state.merge({ isLoading: true });
const downloadHoldersSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadHoldersFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_HOLDERS_REQUEST]: getHoldersRequest,
  [Types.GET_HOLDERS_SUCCESS]: getHoldersSuccess,
  [Types.GET_HOLDERS_FAILURE]: getHoldersFailure,

  [Types.LOAD_LASTEST_TICKET_REQUEST]: loadLastestTicketRequest,
  [Types.LOAD_LASTEST_TICKET_SUCCESS]: loadLastestTicketSuccess,
  [Types.LOAD_LASTEST_TICKET_FAILURE]: loadLastestTicketFailure,

  [Types.LOAD_TICKETS_REQUEST]: loadTicketsRequest,
  [Types.LOAD_TICKETS_SUCCESS]: loadTicketsSuccess,
  [Types.LOAD_TICKETS_FAILURE]: loadTicketsFailure,

  [Types.SET_TICKET_PRIORITY_REQUEST]: setTicketPriorityRequest,
  [Types.SET_TICKET_PRIORITY_SUCCESS]: setTicketPrioritySuccess,
  [Types.SET_TICKET_PRIORITY_FAILURE]: setTicketPriorityFailure,

  [Types.SET_TICKET_DUE_DATE_REQUEST]: setTicketDueDateRequest,
  [Types.SET_TICKET_DUE_DATE_SUCCESS]: setTicketDueDateSuccess,
  [Types.SET_TICKET_DUE_DATE_FAILURE]: setTicketDueDateFailure,

  [Types.RE_OPEN_TICKET_REQUEST]: reOpenTicketRequest,
  [Types.RE_OPEN_TICKET_SUCCESS]: reOpenTicketSuccess,
  [Types.RE_OPEN_TICKET_FAILURE]: reOpenTicketFailure,

  [Types.ANSWER_TICKET_REQUEST]: answerTicketRequest,
  [Types.ANSWER_TICKET_SUCCESS]: answerTicketSuccess,
  [Types.ANSWER_TICKET_FAILURE]: answerTicketFailure,

  [Types.DOWNLOAD_HOLDERS_REQUEST]: downloadHoldersRequest,
  [Types.DOWNLOAD_HOLDERS_SUCCESS]: downloadHoldersSuccess,
  [Types.DOWNLOAD_HOLDERS_FAILURE]: downloadHoldersFailure,
});
