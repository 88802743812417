import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Action Creators */
const { Types, Creators } = createActions({
  openNewDemandRequest: ['data', 'setSucessModalOpen', 'handleFormReset'],
  openNewDemandSuccess: ['hash'],
  openNewDemandFailure: [''],

  getDemandsHistoryRequest: [''],
  getDemandsHistorySuccess: ['demandsHistory'],
  getDemandsHistoryFailure: [''],

  searchDemandsHistoryRequest: ['query'],
  searchDemandsHistorySuccess: ['results'],
  searchDemandsHistoryFailure: [''],

  getDemandDetailsRequest: ['protocol'],
  getDemandDetailsSuccess: ['demandDetails'],
  getDemandDetailsFailure: [''],

  getDemandInteractionsRequest: ['protocol'],
  getDemandInteractionsSuccess: ['demandInteractions'],
  getDemandInteractionsFailure: [''],

  closeDemandRequest: ['protocol'],
  closeDemandSuccess: [''],
  closeDemandFailure: [''],

  getInteractionDetailsRequest: ['protocol', 'interactionId'],
  getInteractionDetailsSuccess: ['interactionDetails'],
  getInteractionDetailsFailure: [''],

  createNewInteractionRequest: ['protocol', 'interactionDescription', 'file'],
  createNewInteractionSuccess: [''],
  createNewInteractionFailure: [''],

  downloadDemandDocumentRequest: ['title', 'documentId', 'demandId'],
  downloadDemandDocumentSuccess: [''],
  downloadDemandDocumentFailure: [''],

  evaluateDemandRequest: ['score', 'comment', 'demandId'],
  evaluateDemandSuccess: [''],
  evaluateDemandFailure: [''],
});

export const DemandTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  demandsHistory: {},
  demandDetails: {},
  demandInteractions: {},
  interactionDetails: {},
});

/* Reducers */

const openNewDemandRequest = (state) => state.merge({ isLoading: true });
const openNewDemandSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const openNewDemandFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDemandsHistoryRequest = (state) => state.merge({ isLoading: true });
const getDemandsHistorySuccess = (state, { demandsHistory }) =>
  state.merge({
    isLoading: false,
    error: false,
    demandsHistory,
  });
const getDemandsHistoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    demandsHistory: {},
  });

const searchDemandsHistoryRequest = (state) => state.merge({ isLoading: true });
const searchDemandsHistorySuccess = (state, { results }) =>
  state.merge({
    isLoading: false,
    error: false,
    demandsHistory: results,
  });
const searchDemandsHistoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    demandsHistory: {},
  });

const getDemandDetailsRequest = (state) => state.merge({ isLoading: true });
const getDemandDetailsSuccess = (state, { demandDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    demandDetails,
  });
const getDemandDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    demandDetails: {},
  });

const getDemandInteractionsRequest = (state) =>
  state.merge({ isLoading: true });
const getDemandInteractionsSuccess = (state, { demandInteractions }) =>
  state.merge({
    isLoading: false,
    error: false,
    demandInteractions,
  });
const getDemandInteractionsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    demandInteractions: {},
  });

const closeDemandRequest = (state) => state.merge({ isLoading: true });
const closeDemandSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const closeDemandFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getInteractionDetailsRequest = (state) =>
  state.merge({ isLoading: true });
const getInteractionDetailsSuccess = (state, { interactionDetails }) =>
  state.merge({
    isLoading: false,
    error: false,
    interactionDetails,
  });
const getInteractionDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    interactionDetails: {},
  });

const createNewInteractionRequest = (state) => state.merge({ isLoading: true });
const createNewInteractionSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const createNewInteractionFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const downloadDemandDocumentRequest = (state) =>
  state.merge({ isLoading: true });
const downloadDemandDocumentSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const downloadDemandDocumentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const evaluateDemandRequest = (state) => state.merge({ isLoading: true });
const evaluateDemandSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const evaluateDemandFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.OPEN_NEW_DEMAND_REQUEST]: openNewDemandRequest,
  [Types.OPEN_NEW_DEMAND_SUCCESS]: openNewDemandSuccess,
  [Types.OPEN_NEW_DEMAND_FAILURE]: openNewDemandFailure,

  [Types.GET_DEMANDS_HISTORY_REQUEST]: getDemandsHistoryRequest,
  [Types.GET_DEMANDS_HISTORY_SUCCESS]: getDemandsHistorySuccess,
  [Types.GET_DEMANDS_HISTORY_FAILURE]: getDemandsHistoryFailure,

  [Types.SEARCH_DEMANDS_HISTORY_REQUEST]: searchDemandsHistoryRequest,
  [Types.SEARCH_DEMANDS_HISTORY_SUCCESS]: searchDemandsHistorySuccess,
  [Types.SEARCH_DEMANDS_HISTORY_FAILURE]: searchDemandsHistoryFailure,

  [Types.GET_DEMAND_DETAILS_REQUEST]: getDemandDetailsRequest,
  [Types.GET_DEMAND_DETAILS_SUCCESS]: getDemandDetailsSuccess,
  [Types.GET_DEMAND_DETAILS_FAILURE]: getDemandDetailsFailure,

  [Types.GET_DEMAND_INTERACTIONS_REQUEST]: getDemandInteractionsRequest,
  [Types.GET_DEMAND_INTERACTIONS_SUCCESS]: getDemandInteractionsSuccess,
  [Types.GET_DEMAND_INTERACTIONS_FAILURE]: getDemandInteractionsFailure,

  [Types.CLOSE_DEMAND_REQUEST]: closeDemandRequest,
  [Types.CLOSE_DEMAND_SUCCESS]: closeDemandSuccess,
  [Types.CLOSE_DEMAND_FAILURE]: closeDemandFailure,

  [Types.GET_INTERACTION_DETAILS_REQUEST]: getInteractionDetailsRequest,
  [Types.GET_INTERACTION_DETAILS_SUCCESS]: getInteractionDetailsSuccess,
  [Types.GET_INTERACTION_DETAILS_FAILURE]: getInteractionDetailsFailure,

  [Types.CREATE_NEW_INTERACTION_REQUEST]: createNewInteractionRequest,
  [Types.CREATE_NEW_INTERACTION_SUCCESS]: createNewInteractionSuccess,
  [Types.CREATE_NEW_INTERACTION_FAILURE]: createNewInteractionFailure,

  [Types.DOWNLOAD_DEMAND_DOCUMENT_REQUEST]: downloadDemandDocumentRequest,
  [Types.DOWNLOAD_DEMAND_DOCUMENT_SUCCESS]: downloadDemandDocumentSuccess,
  [Types.DOWNLOAD_DEMAND_DOCUMENT_FAILURE]: downloadDemandDocumentFailure,

  [Types.EVALUATE_DEMAND_REQUEST]: evaluateDemandRequest,
  [Types.EVALUATE_DEMAND_SUCCESS]: evaluateDemandSuccess,
  [Types.EVALUATE_DEMAND_FAILURE]: evaluateDemandFailure,
});
