import React, { useRef } from 'react';
import { element, bool } from 'prop-types';
import { useSelector } from 'react-redux';

import i18n from '~/i18n';
import { formaterCnpj } from '~/utils/formatters';
import {
  Container,
  WrapperContent,
  Drawer,
  LogoRow,
  Logo,
  Title,
  Subtitle,
  Content,
  UserWrapper,
} from './styles';

export default function PanelLayout({
  children,
  noDrawer,
  noInfo,
  panel,
  checkout,
}) {
  const { userActive, newCompany } = useSelector((state) => state.auth);

  let formatedCnpj;

  const menuDrawer = useRef();
  
  if (newCompany?.cnpj) {
    formatedCnpj = formaterCnpj(newCompany?.cnpj);
  }

  return (
    <Content>
      <Container id="menu-drawer" ref={menuDrawer}>
        <Drawer noDrawer={noDrawer}>
          <LogoRow>
            <Logo />
            {!noInfo && (
              <UserWrapper>
                {panel ? (
                  <Title>
                    {i18n.t('panelLayout.title')}, {userActive.user?.first_name}
                  </Title>
                ) : checkout ? (
                  <Title> {i18n.t('panelLayout.newEnterprise')}</Title>
                ) : (
                  <Title>{newCompany.name}</Title>
                )}

                {panel ? (
                  <Subtitle>{i18n.t('panelLayout.subtitle')}</Subtitle>
                ) : checkout ? (
                  <Subtitle> {i18n.t('panelLayout.signupMessage')} </Subtitle>
                ) : (
                  <Subtitle>{formatedCnpj}</Subtitle>
                )}
              </UserWrapper>
            )}
          </LogoRow>
        </Drawer>

        <WrapperContent noDrawer={noDrawer}>{children}</WrapperContent>
      </Container>
    </Content>
  );
}

PanelLayout.propTypes = {
  children: element.isRequired,
  noDrawer: bool,
  noInfo: bool,
  panel: bool,
  checkout: bool,
};

PanelLayout.defaultProps = {
  noDrawer: false,
  noInfo: false,
  panel: false,
  checkout: false,
};
