import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import i18next from 'i18next';
import { Formik } from 'formik';
import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { SecondaryButton, PrimaryButton } from '~/components/Button';

import {
  Container,
  FormArea,
  Description,
  ButtonsArea,
  RadioButtonArea,
  RadioArea,
  RadioButtonLabel,
} from './styles';

const submit = (values) => {};

const CancelOptions = ({ open, onClose }) => {
  const [otherOptions, setOtherOptions] = useState('');
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t('configurations.cancelOptionsModal.title')}
      noPadding
    >
      <Container>
        <Description>
          {i18next.t('configurations.cancelOptionsModal.description')}
        </Description>
        <Formik
          initialValues={{
            cancelSelect: '',
            otherText: otherOptions,
          }}
          onSubmit={submit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <FormArea>
              <RadioButtonArea>
                <RadioArea>
                  <input
                    type="radio"
                    name="cancelSelect"
                    value="option1"
                    onChange={handleChange}
                  />
                  <RadioButtonLabel>
                    {i18next.t('configurations.cancelOptionsModal.option1')}
                  </RadioButtonLabel>
                </RadioArea>
                <RadioArea>
                  <input
                    type="radio"
                    name="cancelSelect"
                    value="option2"
                    onChange={handleChange}
                  />

                  <RadioButtonLabel>
                    {' '}
                    {i18next.t('configurations.cancelOptionsModal.option2')}
                  </RadioButtonLabel>
                </RadioArea>
                <RadioArea>
                  <input
                    type="radio"
                    name="cancelSelect"
                    value="option3"
                    onChange={handleChange}
                  />

                  <RadioButtonLabel>
                    {' '}
                    {i18next.t('configurations.cancelOptionsModal.option3')}
                  </RadioButtonLabel>
                </RadioArea>
                <RadioArea>
                  <input
                    type="radio"
                    name="cancelSelect"
                    value="option4"
                    onChange={handleChange}
                  />
                  <RadioButtonLabel>
                    {' '}
                    {i18next.t('configurations.cancelOptionsModal.option4')}
                  </RadioButtonLabel>
                </RadioArea>
              </RadioButtonArea>
              {values.cancelSelect === 'option4' ? (
                <TextInput
                  placeholder="Digite o seu motivo."
                  onChange={handleChange}
                  value={values.otherText}
                />
              ) : null}
              <ButtonsArea>
                <SecondaryButton
                  style={{ width: '45%', padding: 0 }}
                  label={i18next.t('configurations.cancelModal.buttonReturn')}
                />
                <PrimaryButton
                  style={{ width: '45%', padding: 0 }}
                  label={i18next.t('configurations.cancelModal.buttonCancel')}
                  onClick={handleSubmit}
                />
              </ButtonsArea>
            </FormArea>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default CancelOptions;
