import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: ${(props) => props.margin};
  padding-bottom: ${({ error }) => (error ? 2.5 : 0)}rem;
  transition: all 300ms ease;
`;

export const Label = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
  line-height: 2.1rem;
  margin-bottom: 1.6rem;
  ${({ oneLineLabel }) =>
    oneLineLabel &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4rem;
    `};

  width: 100%;
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;
