import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  departamentCategoryRequest: ['data', 'onSuccess'],
  departamentCategorySuccess: ['category'],
  departamentCategoryFailure: [''],

  getDepartamentCategoryRequest: ['companyId'],
  getDepartamentCategorySuccess: ['categories'],
  getDepartamentCategoryFailure: [''],

  createDepartamentRequest: ['data', 'onSuccess'],
  createDepartamentSuccess: ['departament'],
  createDepartamentFailure: [''],

  updateDepartamentRequest: ['id', 'data', 'onSuccess'],
  updateDepartamentSuccess: ['departament'],
  updateDepartamentFailure: [''],

  updateDepartamentProcessRequest: ['id', 'data'],
  updateDepartamentProcessSuccess: ['process'],
  updateDepartamentProcessFailure: [''],

  createDepartamentProcessRequest: ['departamentId', 'data'],
  createDepartamentProcessSuccess: ['process'],
  createDepartamentProcessFailure: [''],

  getDepartamentsRequest: [''],
  getDepartamentsSuccess: ['departaments'],
  getDepartamentsFailure: [''],

  getDepartamentDetailsRequest: ['id'],
  getDepartamentDetailsSuccess: ['departament'],
  getDepartamentDetailsFailure: [''],

  getDepartamentProcessDetailsRequest: ['id'],
  getDepartamentProcessDetailsSuccess: ['process'],
  getDepartamentProcessDetailsFailure: [''],

  clearProcessDetailsRequest: [''],

  deleteDepartamentProcessRequest: ['id'],
  deleteDepartamentProcessSuccess: ['id'],
  deleteDepartamentProcessFailure: [''],

  getDepartamentsEnumRequest: [''],
  getDepartamentsEnumSuccess: ['departamentsEnum'],
  getDepartamentsEnumFailure: [''],

  deleteDepartamentRequest: ['id'],
  deleteDepartamentSuccess: ['id'],
  deleteDepartamentFailure: [''],

  updateCompanyDataRequest: ['logo', 'data', 'updateCompanyData'],
  updateCompanyDataSuccess: ['companyData'],
  updateCompanyDataFailure: [''],

  updateResponsibleDataRequest: ['data', 'updateRespData'],
  updateResponsibleDataSuccess: [''],
  updateResponsibleDataFailure: [''],

  getCompanyDataRequest: ['id'],
  getCompanyDataSuccess: ['companyData'],
  getCompanyDataFailure: [''],

  updateUserAvatarRequest: ['avatar', 'id', 'updateUser'],
  updateUserAvatarSuccess: [''],
  updateUserAvatarFailure: [''],

  createCompanyUserRequest: ['data', 'onSuccess'],
  createCompanyUserSuccess: [''],
  createCompanyUserFailure: [''],

  getUsersCompanyRequest: ['page', 'searchValue'],
  getUsersCompanySuccess: ['listUsers'],
  getUsersCompanyFailure: [],

  getInvitationHistoryRequest: ['page', 'searchValue'],
  getInvitationHistorySuccess: ['listHistory'],
  getInvitationHistoryFailure: [],

  searchUsersCompanyRequest: ['query'],
  searchUsersCompanySuccess: ['result'],
  searchUsersCompanyFailure: [],

  getUsersDetailsRequest: ['id', 'setOpenEditModal'],
  getUsersDetailsSuccess: ['user'],
  getUsersDetailsFailure: [''],

  updateUserRequest: ['data', 'onSuccess'],
  updateUserSuccess: [''],
  updateUserFailure: [''],

  getCompanySignatureRequest: ['companyId', 'page', 'limit'],
  getCompanySignatureSuccess: ['subscriptions'],
  getCompanySignatureFailure: [],

  updateUserStatusRequest: ['data', 'reloadTable'],
  updateUserStatusSuccess: [''],
  updateUserStatusFailure: [''],

  getRolesRequest: [''],
  getRolesSuccess: ['roleEnum'],
  getRolesFailure: [''],
});

export const ConfigTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  isLoadingDetails: false,
  error: false,
  companyData: {},
  listUsers: {},
  listHistory: {},
  categories: [],
  departament: {},
  process: {},
  departaments: [],
  departamentsEnum: [],
  user: {},
  roleEnum: {},
  subscriptions: {},
  sendEmail: '',
});

/* Reducers */

const getDepartamentsEnumRequest = (state) => state.merge({ isLoading: true });
const getDepartamentsEnumSuccess = (state, { departamentsEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    departamentsEnum,
  });
const getDepartamentsEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    departamentsEnum: [],
  });

const departamentCategoryRequest = (state) => state.merge({ isLoading: true });
const departamentCategorySuccess = (state, { category }) => {
  const formatCategory = {
    value: category.id,
    label: category.name,
  };

  return state.merge({
    isLoading: false,
    error: false,
    categories: [...state.categories, formatCategory],
  });
};

const departamentCategoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDepartamentCategoryRequest = (state) =>
  state.merge({ isLoading: true });

const getDepartamentCategorySuccess = (state, { categories }) => {
  const formatCategory = categories.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return state.merge({
    isLoading: false,
    error: false,
    categories: formatCategory,
  });
};
const getDepartamentCategoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const createDepartamentRequest = (state) => state.merge({ isLoading: true });

const createDepartamentSuccess = (state, { departament }) => {
  const newDepartaments = [...state.departaments, departament];

  return state.merge({
    isLoading: false,
    error: false,
    departaments: newDepartaments,
  });
};

const createDepartamentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const createDepartamentProcessRequest = (state) =>
  state.merge({ isLoading: true });

const createDepartamentProcessSuccess = (state, { process }) => {
  const departamentsCopy = state.departaments;

  const DepartamentListWithNewProcess = departamentsCopy.map((departament) => {
    if (departament.id === process.departament_id) {
      return {
        ...departament,
        processes: [...departament.processes, process],
      };
    }
    return departament;
  });

  return state.merge({
    isLoading: false,
    error: false,
    departaments: DepartamentListWithNewProcess,
    process: {
      ...process,
      responsibles: process.responsibles.map((r) => ({
        person: { id: r.person_id },
      })),
    },
  });
};

const createDepartamentProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateDepartamentRequest = (state) => state.merge({ isLoading: true });

const updateDepartamentSuccess = (state, { departament }) => {
  const updatedDepartamentsList = state.departaments.map((oldDepartament) => {
    if (oldDepartament.id === departament.result.id) {
      return {
        ...oldDepartament,
        name: departament.result.name,
      };
    }
    return oldDepartament;
  });

  const newDep = state.departament
    ? {
        ...departament.result,
        managers: departament.managers,
      }
    : {};

  return state.merge({
    isLoading: false,
    error: false,
    departaments: updatedDepartamentsList,
    departament: newDep,
  });
};

const updateDepartamentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateDepartamentProcessRequest = (state) =>
  state.merge({ isLoading: true });

const updateDepartamentProcessSuccess = (state, { process }) => {
  const departamentsCopy = state.departaments;

  const DepartamentListWithUpdatedProcess = departamentsCopy.map(
    (departament) => {
      if (departament.id === process.departament_id) {
        const updatedProcesses = departament.processes.map((p) => {
          if (p.id === process.id) return process;
          return p;
        });

        return {
          ...departament,
          processes: updatedProcesses,
        };
      }

      return departament;
    }
  );

  return state.merge({
    isLoading: false,
    error: false,
    departaments: DepartamentListWithUpdatedProcess,
  });
};

const updateDepartamentProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDepartamentsRequest = (state) => state.merge({ isLoading: true });

const getDepartamentsSuccess = (state, { departaments }) =>
  state.merge({
    isLoading: false,
    error: false,
    departaments,
  });

const getDepartamentsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getDepartamentDetailsRequest = (state) =>
  state.merge({ isLoadingDetails: true });

const getDepartamentDetailsSuccess = (state, { departament }) =>
  state.merge({
    isLoadingDetails: false,
    error: false,
    departament,
  });

const getDepartamentDetailsFailure = (state) =>
  state.merge({
    isLoadingDetails: false,
    error: true,
  });

const getDepartamentProcessDetailsRequest = (state) =>
  state.merge({ isLoadingDetails: true });

const getDepartamentProcessDetailsSuccess = (state, { process }) =>
  state.merge({
    isLoadingDetails: false,
    error: false,
    process,
  });

const clearProcessDetailsRequest = (state) =>
  state.merge({
    process: {},
  });

const getDepartamentProcessDetailsFailure = (state) =>
  state.merge({
    isLoadingDetails: false,
    error: true,
  });

const deleteDepartamentProcessRequest = (state) =>
  state.merge({ isLoading: true });

const deleteDepartamentProcessSuccess = (state, { id }) => {
  const filtredProces = state.departaments.map((item) => {
    return {
      ...item,
      processes: item.processes.filter((process) => process.id !== id),
    };
  });

  return state.merge({
    isLoading: false,
    error: false,
    departaments: filtredProces,
  });
};

const deleteDepartamentProcessFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const deleteDepartamentRequest = (state) => state.merge({ isLoading: true });

const deleteDepartamentSuccess = (state, { id }) => {
  const filtredDepartaments = state.departaments.filter(
    (departament) => departament.id !== id
  );

  return state.merge({
    isLoading: false,
    error: false,
    departaments: filtredDepartaments,
  });
};

const deleteDepartamentFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateCompanyDataRequest = (state) => state.merge({ isLoading: true });
const updateCompanyDataSuccess = (state, { companyData }) =>
  state.merge({
    isLoading: false,
    error: false,
    companyData,
  });
const updateCompanyDataFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateResponsibleDataRequest = (state) =>
  state.merge({ isLoading: true });
const updateResponsibleDataSuccess = (state) => updateCompanyDataSuccess(state);
const updateResponsibleDataFailure = (state) => updateCompanyDataFailure(state);

const getCompanyDataRequest = (state) => state.merge({ isLoading: true });
const getCompanyDataSuccess = (state, { companyData }) =>
  state.merge({
    isLoading: false,
    error: false,
    companyData,
  });
const getCompanyDataFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    companyData: {},
  });

const updateUserAvatarRequest = (state) => state.merge({ isLoading: true });
const updateUserAvatarSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateUserAvatarFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const createCompanyUserRequest = (state, { data }) =>
  state.merge({ isLoading: true, sendEmail: data.user_email });
const createCompanyUserSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    sendEmail: '',
  });
const createCompanyUserFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    sendEmail: '',
  });

const getUsersCompanyRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getUsersCompanySuccess = (state, { listUsers }) =>
  state.merge({
    isLoading: false,
    error: true,
    listUsers,
  });

const getUsersCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    listUsers: {},
  });

const searchUsersCompanyRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const searchUsersCompanySuccess = (state, { result }) =>
  state.merge({
    isLoading: false,
    error: true,
    result,
  });

const searchUsersCompanyFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    listUsers: {},
  });

const getUsersDetailsRequest = (state) => state.merge({ isLoading: true });

const getUsersDetailsSuccess = (state, { user }) =>
  state.merge({
    isLoading: false,
    error: false,
    user,
  });
const getUsersDetailsFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    user: {},
  });

const updateUserRequest = (state) => state.merge({ isLoading: true });
const updateUserSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateUserFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateUserStatusRequest = (state) => state.merge({ isLoading: true });
const updateUserStatusSuccess = (state) =>
  state.merge({
    isLoading: false,
    error: false,
  });
const updateUserStatusFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const getRolesRequest = (state) => state.merge({ isLoading: true });
const getRolesSuccess = (state, { roleEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    roleEnum,
  });
const getRolesFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    types: {},
  });

const getCompanySignatureRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getCompanySignatureSuccess = (state, { subscriptions }) =>
  state.merge({
    isLoading: false,
    error: true,
    subscriptions,
  });

const getCompanySignatureFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    subscriptions: {},
  });

const getInvitationHistoryRequest = (state) =>
  state.merge({
    isLoading: true,
  });

const getInvitationHistorySuccess = (state, { listHistory }) =>
  state.merge({
    isLoading: false,
    error: true,
    listHistory,
  });

const getInvitationHistoryFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    listHistory: {},
  });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DEPARTAMENT_CATEGORY_REQUEST]: departamentCategoryRequest,
  [Types.DEPARTAMENT_CATEGORY_SUCCESS]: departamentCategorySuccess,
  [Types.DEPARTAMENT_CATEGORY_FAILURE]: departamentCategoryFailure,

  [Types.GET_DEPARTAMENT_CATEGORY_REQUEST]: getDepartamentCategoryRequest,
  [Types.GET_DEPARTAMENT_CATEGORY_SUCCESS]: getDepartamentCategorySuccess,
  [Types.GET_DEPARTAMENT_CATEGORY_FAILURE]: getDepartamentCategoryFailure,

  [Types.DELETE_DEPARTAMENT_REQUEST]: deleteDepartamentRequest,
  [Types.DELETE_DEPARTAMENT_SUCCESS]: deleteDepartamentSuccess,
  [Types.DELETE_DEPARTAMENT_FAILURE]: deleteDepartamentFailure,

  [Types.CREATE_DEPARTAMENT_REQUEST]: createDepartamentRequest,
  [Types.CREATE_DEPARTAMENT_SUCCESS]: createDepartamentSuccess,
  [Types.CREATE_DEPARTAMENT_FAILURE]: createDepartamentFailure,

  [Types.CREATE_DEPARTAMENT_PROCESS_REQUEST]: createDepartamentProcessRequest,
  [Types.CREATE_DEPARTAMENT_PROCESS_SUCCESS]: createDepartamentProcessSuccess,
  [Types.CREATE_DEPARTAMENT_PROCESS_FAILURE]: createDepartamentProcessFailure,

  [Types.UPDATE_DEPARTAMENT_REQUEST]: updateDepartamentRequest,
  [Types.UPDATE_DEPARTAMENT_SUCCESS]: updateDepartamentSuccess,
  [Types.UPDATE_DEPARTAMENT_FAILURE]: updateDepartamentFailure,

  [Types.UPDATE_DEPARTAMENT_PROCESS_REQUEST]: updateDepartamentProcessRequest,
  [Types.UPDATE_DEPARTAMENT_PROCESS_SUCCESS]: updateDepartamentProcessSuccess,
  [Types.UPDATE_DEPARTAMENT_PROCESS_FAILURE]: updateDepartamentProcessFailure,

  [Types.GET_DEPARTAMENTS_REQUEST]: getDepartamentsRequest,
  [Types.GET_DEPARTAMENTS_SUCCESS]: getDepartamentsSuccess,
  [Types.GET_DEPARTAMENTS_FAILURE]: getDepartamentsFailure,

  [Types.GET_DEPARTAMENT_DETAILS_REQUEST]: getDepartamentDetailsRequest,
  [Types.GET_DEPARTAMENT_DETAILS_SUCCESS]: getDepartamentDetailsSuccess,
  [Types.GET_DEPARTAMENT_DETAILS_FAILURE]: getDepartamentDetailsFailure,

  [Types.GET_DEPARTAMENT_PROCESS_DETAILS_REQUEST]:
    getDepartamentProcessDetailsRequest,
  [Types.GET_DEPARTAMENT_PROCESS_DETAILS_SUCCESS]:
    getDepartamentProcessDetailsSuccess,
  [Types.GET_DEPARTAMENT_PROCESS_DETAILS_FAILURE]:
    getDepartamentProcessDetailsFailure,

  [Types.CLEAR_PROCESS_DETAILS_REQUEST]: clearProcessDetailsRequest,

  [Types.DELETE_DEPARTAMENT_PROCESS_REQUEST]: deleteDepartamentProcessRequest,
  [Types.DELETE_DEPARTAMENT_PROCESS_SUCCESS]: deleteDepartamentProcessSuccess,
  [Types.DELETE_DEPARTAMENT_PROCESS_FAILURE]: deleteDepartamentProcessFailure,

  [Types.UPDATE_COMPANY_DATA_REQUEST]: updateCompanyDataRequest,
  [Types.UPDATE_COMPANY_DATA_SUCCESS]: updateCompanyDataSuccess,
  [Types.UPDATE_COMPANY_DATA_FAILURE]: updateCompanyDataFailure,

  [Types.UPDATE_RESPONSIBLE_DATA_REQUEST]: updateResponsibleDataRequest,
  [Types.UPDATE_RESPONSIBLE_DATA_SUCCESS]: updateResponsibleDataSuccess,
  [Types.UPDATE_RESPONSIBLE_DATA_FAILURE]: updateResponsibleDataFailure,

  [Types.GET_COMPANY_DATA_REQUEST]: getCompanyDataRequest,
  [Types.GET_COMPANY_DATA_SUCCESS]: getCompanyDataSuccess,
  [Types.GET_COMPANY_DATA_FAILURE]: getCompanyDataFailure,

  [Types.UPDATE_USER_AVATAR_REQUEST]: updateUserAvatarRequest,
  [Types.UPDATE_USER_AVATAR_SUCCESS]: updateUserAvatarSuccess,
  [Types.UPDATE_USER_AVATAR_FAILURE]: updateUserAvatarFailure,

  [Types.CREATE_COMPANY_USER_REQUEST]: createCompanyUserRequest,
  [Types.CREATE_COMPANY_USER_SUCCESS]: createCompanyUserSuccess,
  [Types.CREATE_COMPANY_USER_FAILURE]: createCompanyUserFailure,

  [Types.GET_USERS_COMPANY_REQUEST]: getUsersCompanyRequest,
  [Types.GET_USERS_COMPANY_SUCCESS]: getUsersCompanySuccess,
  [Types.GET_USERS_COMPANY_FAILURE]: getUsersCompanyFailure,

  [Types.GET_USERS_COMPANY_REQUEST]: getUsersCompanyRequest,
  [Types.GET_USERS_COMPANY_SUCCESS]: getUsersCompanySuccess,
  [Types.GET_USERS_COMPANY_FAILURE]: getUsersCompanyFailure,

  [Types.SEARCH_USERS_COMPANY_REQUEST]: searchUsersCompanyRequest,
  [Types.SEARCH_USERS_COMPANY_SUCCESS]: searchUsersCompanySuccess,
  [Types.SEARCH_USERS_COMPANY_FAILURE]: searchUsersCompanyFailure,

  [Types.GET_USERS_DETAILS_REQUEST]: getUsersDetailsRequest,
  [Types.GET_USERS_DETAILS_SUCCESS]: getUsersDetailsSuccess,
  [Types.GET_USERS_DETAILS_FAILURE]: getUsersDetailsFailure,

  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [Types.UPDATE_USER_FAILURE]: updateUserFailure,

  [Types.UPDATE_USER_STATUS_REQUEST]: updateUserStatusRequest,
  [Types.UPDATE_USER_STATUS_SUCCESS]: updateUserStatusSuccess,
  [Types.UPDATE_USER_STATUS_FAILURE]: updateUserStatusFailure,

  [Types.GET_ROLES_REQUEST]: getRolesRequest,
  [Types.GET_ROLES_SUCCESS]: getRolesSuccess,
  [Types.GET_ROLES_FAILURE]: getRolesFailure,

  [Types.GET_COMPANY_SIGNATURE_REQUEST]: getCompanySignatureRequest,
  [Types.GET_COMPANY_SIGNATURE_SUCCESS]: getCompanySignatureSuccess,
  [Types.GET_COMPANY_SIGNATURE_FAILURE]: getCompanySignatureFailure,

  [Types.GET_INVITATION_HISTORY_REQUEST]: getInvitationHistoryRequest,
  [Types.GET_INVITATION_HISTORY_SUCCESS]: getInvitationHistorySuccess,
  [Types.GET_INVITATION_HISTORY_FAILURE]: getInvitationHistoryFailure,

  [Types.GET_DEPARTAMENTS_ENUM_REQUEST]: getDepartamentsEnumRequest,
  [Types.GET_DEPARTAMENTS_ENUM_SUCCESS]: getDepartamentsEnumSuccess,
  [Types.GET_DEPARTAMENTS_ENUM_FAILURE]: getDepartamentsEnumFailure,
});
