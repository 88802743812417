import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';

import 'react-image-crop/dist/ReactCrop.css';
import { useDropzone } from 'react-dropzone';

import { useMediaQuery } from '@material-ui/core';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ConfigActions from '~/store/ducks/config';
import AuthActions from '~/store/ducks/auth';

import { useHistory } from 'react-router-dom';
import Spinner from '~/components/Spinner';
import { PhonInput } from '~/components/PhoneInput';
import Dropzone from '../../components/Dropzone';
import validation from './validationSchema';

import {
  ColorTitle,
  ColorWrapper,
  Container,
  DropzoneAvatar,
  Image,
  ImageIcon,
  InputColor,
  InputColorCintainer,
  Padding,
  SppinerIcon,
  Title,
  TopWrapper,
} from './styles';
import ImageCrop from '../../components/ImageCrop';
import CancellationModal from '../../../../components/CancellationModal';

const CompanyData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [copiedLink, setCopiedLink] = useState(false);
  const [image, setImage] = useState(null);
  const { isLoading, companyData } = useSelector((state) => state.config);
  const { userActive } = useSelector((state) => state.auth);
  const [cancelModal, setCancelModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateCompanyData = () => {
    dispatch(
      ConfigActions.getCompanyDataRequest(userActive?.activeCompany?.id)
    );
  };

  const handleToHome = useCallback(() => {
    history.push('/');
  }, []);

  const submit = (values) => {
    const data = { ...values, companyId: userActive?.activeCompany?.id };

    setIsSubmitting(true);
    dispatch(
      ConfigActions.updateCompanyDataRequest(image, data, updateCompanyData)
    );

    if (cancelModal) {
      handleToHome();
    }
    // setIsSubmitting(false);
  };

  useEffect(() => {
    dispatch(
      ConfigActions.getCompanyDataRequest(userActive?.activeCompany?.id)
    );
  }, []);

  useEffect(() => {
    if (companyData.logo) {
      setImage(companyData.logo);
    }
  }, [companyData]);

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [nonCroppedImage, setNonCroppedImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setCropModalOpen(true);

    if (acceptedFiles[0]) {
      setNonCroppedImage(URL.createObjectURL(acceptedFiles[0]));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: ['image/jpeg', 'image/jpg', 'image/png'],
    });

  const handleCloseModal = () => {
    setCropModalOpen(false);
  };

  const handleOpenCancelModal = (initialValues, values) => {
    if (initialValues !== values) {
      setCancelModal(true);
    } else {
      handleToHome();
    }
  };

  const handleCloseCancelModal = () => {
    setCancelModal(false);
  };

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(`${companyData.call_opening_url}`);
  //   setCopiedLink(true);
  // };

  if (isLoading && !isSubmitting) {
    return <Spinner loading={isLoading} />;
  }

  // const dragActiveTitle = () => {
  //   return isDragActive
  //     ? i18next.t('configurations.company.attachmentDrop')
  //     : i18next.t('configurations.company.attachmentInsert');
  // };

  const baseImgUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_API_HOMOLOG_ENDPOINT;
    }
    return process.env.REACT_APP_API_PROD_ENDPOINT;
  };

  const img = () => {
    if (image) {
      if (typeof image === 'string') {
        return <Image src={`${baseImgUrl()}/public/image?image=${image}`} />;
      }
      return <Image src={URL.createObjectURL(image)} />;
    }
    return <ImageIcon />;
  };

  return (
    <Formik
      initialValues={{
        name: companyData.name || '',
        primaryColor: companyData?.styles?.primary_color || '#CCCCCC',
        fantasy_name: companyData.fantasy_name || '',
        cnpj: companyData.cnpj || '',
        email: companyData.email || '',
        phone: companyData.phone || '',
        bylaw: companyData?.files?.bylaw || null,
        last_contractual_alteration:
          companyData?.files?.last_contractual_alteration || null,
        address_proof: companyData?.files?.address_proof || null,
      }}
      onSubmit={submit}
      validationSchema={validation}
      enableReinitialize
    >
      {({
        initialValues,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => (
        <Container>
          <Title>
            {i18next.t('configurations.company.tabs.company.label')}
          </Title>
          {/* <CopyButton active={copiedLink} onClick={handleCopy}>
            <CopyIcon />
            {i18next.t('configurations.company.linkToOpenDemand')}
          </CopyButton> */}

          <Grid
            spacing={2}
            container
            direction={largeScreen ? 'row' : 'column'}
            alignItems="center"
          >
            <Grid item xs={12}>
              <TopWrapper>
                <DropzoneAvatar
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                  hasImage={Boolean(image)}
                >
                  <input {...getInputProps()} multiple={false} />
                  {img()}
                </DropzoneAvatar>
                <ColorWrapper>
                  <ColorTitle>Cor:</ColorTitle>
                  <InputColorCintainer>
                    <InputColor
                      type="color"
                      name="primaryColor"
                      value={values.primaryColor}
                      onChange={(e) =>
                        setFieldValue('primaryColor', e.target.value)
                      }
                    />
                  </InputColorCintainer>
                </ColorWrapper>
              </TopWrapper>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextInput
                label={i18next.t(
                  'configurations.company.tabs.company.socialReasonLabel'
                )}
                placeholder={i18next.t(
                  'configurations.company.tabs.company.socialReasonLabel'
                )}
                required
                name="name"
                value={values.name}
                error={errors.name}
                touched={touched.name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                label={i18next.t(
                  'configurations.company.tabs.company.fantasyNameLabel'
                )}
                placeholder={i18next.t(
                  'configurations.company.tabs.company.fantasyNameLabel'
                )}
                name="fantasy_name"
                required
                value={values.fantasy_name}
                error={errors.fantasy_name}
                touched={touched.fantasy_name}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                label={i18next.t(
                  'configurations.company.tabs.company.cnpjLabel'
                )}
                required
                placeholder="Ex.: 99.999.999/9999-99"
                mask="99.999.999/9999-99"
                name="cnpj"
                value={values.cnpj}
                error={errors.cnpj}
                touched={touched.cnpj}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextInput
                label={i18next.t(
                  'configurations.company.tabs.company.emailLabel'
                )}
                placeholder="Ex.: meuemail@email.com.br"
                required
                name="email"
                value={values.email}
                error={errors.email}
                touched={touched.email}
                onBlur={handleBlur}
                onChange={handleChange}
                editable
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PhonInput
                label={i18next.t(
                  'configurations.company.tabs.company.phoneLabel'
                )}
                name="phone"
                value={values.phone}
                onChange={(e) => setFieldValue('phone', e)}
              />
            </Grid>
          </Grid>

          <Title>
            {i18next.t('configurations.company.tabs.company.attachments.title')}
          </Title>

          <Grid container spacing={3} columns={12} alignItems="center">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropzone
                label={i18next.t('configurations.company.bylaw')}
                name="bylaw"
                value={values.bylaw || null}
                onSetFile={(e) => setFieldValue('bylaw', e[0])}
                error={touched.bylaw && errors.bylaw ? errors.bylaw : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropzone
                label={i18next.t(
                  'configurations.company.last_contractual_alteration'
                )}
                name="last_contractual_alteration"
                value={values.last_contractual_alteration || null}
                onSetFile={(e) =>
                  setFieldValue('last_contractual_alteration', e[0])
                }
                error={
                  touched.last_contractual_alteration &&
                  errors.last_contractual_alteration
                    ? errors.last_contractual_alteration
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Dropzone
                label={i18next.t('configurations.company.address_proof')}
                name="address_proof"
                value={values.address_proof || null}
                onSetFile={(e) => setFieldValue('address_proof', e[0])}
                error={
                  touched.address_proof && errors.address_proof
                    ? errors.address_proof
                    : ''
                }
              />
            </Grid>
          </Grid>
          <Padding>
            <Grid container spacing={4} justify="flex-end">
              <Grid item xs={12} md={4} lg={3}>
                <SecondaryButton
                  label={i18next.t('common.buttons.cancel')}
                  type="secondary"
                  onClick={() => handleOpenCancelModal(initialValues, values)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <PrimaryButton
                  type="submit"
                  disabled={isSubmitting}
                  label={
                    isSubmitting ? (
                      <SppinerIcon />
                    ) : (
                      i18next.t('common.buttons.save')
                    )
                  }
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
          </Padding>
          <ImageCrop
            isOpen={cropModalOpen}
            onClose={handleCloseModal}
            title={i18next.t('cropModal.title')}
            nonCroppedImage={nonCroppedImage}
            setImage={setImage}
            fileName={values.fantasy_name}
          />
          <CancellationModal
            open={cancelModal}
            onClose={handleCloseCancelModal}
            title={i18next.t('configurations.cancellationModal.title')}
            handleNo={handleToHome}
            handleYes={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </Container>
      )}
    </Formik>
  );
};

export default CompanyData;
