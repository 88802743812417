import React from 'react';
import { func, element, string, oneOfType, any } from 'prop-types';

import { Container, WrapperIcon } from './styles';

function OutlineButton({ onClick, leftIcon, label, style, className }) {
  return (
    <Container onClick={onClick} style={style} className={className}>
      {leftIcon && <WrapperIcon>{leftIcon}</WrapperIcon>}
      {label}
    </Container>
  );
}

export default OutlineButton;

OutlineButton.propTypes = {
  onClick: func,
  leftIcon: element,
  label: string,
  style: oneOfType([any]),
  className: string,
};

OutlineButton.defaultProps = {
  onClick: () => {},
  leftIcon: undefined,
  label: 'Label',
  style: {},
  className: null,
};
