import { bool, func, string } from 'prop-types';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import pt from 'react-phone-input-2/lang/pt.json';
import { useTheme } from 'styled-components';
import { Label, Container, Error } from './styles';

export function PhonInput({
  value,
  label,
  onChange,
  margin,
  sm,
  onBlur,
  error,
  touched,
}) {
  const theme = useTheme();
  const isError = Boolean(touched && error);

  return (
    <Container margin={margin} label={label}>
      {label && <Label sm={sm}>{label}</Label>}
      <PhoneInput
        onBlur={onBlur}
        localization={pt}
        country="br"
        value={value}
        onChange={onChange}
        containerStyle={{
          margin,
        }}
        inputStyle={{
          width: '100%',
          height: '6rem',
          border: error ? `1px solid ${theme.colors.red}` : 'none',
          background: theme.colors.base[200],
          fontSize: '1.4rem',
          color: theme.colors.base[500],
          lineHeight: '1.4rem',
          fontWeight: '400',
        }}
      />
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

PhonInput.propTypes = {
  onChange: func,
  sm: bool,
  onBlur: func,
  touched: bool,
  value: string,
  label: string,
  margin: string,
  error: string,
};

PhonInput.defaultProps = {
  sm: false,
  onBlur: null,
  error: '',
  touched: false,
  onChange: () => {},
  value: '',
  label: '',
  margin: '0.2rem 0',
};
