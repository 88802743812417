import styled from 'styled-components';

export const Container = styled.div`
  width: 80vw;
  max-width: 45rem;
  padding-top: 4.7rem;
`;

export const WrapperBottom = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5rem;
`;

export const Divider = styled.div`
  width: 2rem;
`;
