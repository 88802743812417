import React from 'react';
import { string } from 'prop-types';
import i18next from 'i18next';

import { Container, Wrapper } from './styles';

const PriorityTag = ({ item }) => {
  function getPriorityName() {
    switch (item) {
      case 'high':
        return i18next.t('demandManagement.table.priorityTag.high');
      case 'medium':
        return i18next.t('demandManagement.table.priorityTag.medium');
      case 'low':
        return i18next.t('demandManagement.table.priorityTag.low');
      default:
        return null;
    }
  }

  const name = getPriorityName();

  return (
    <Container>
      <Wrapper status={item}>{name}</Wrapper>
    </Container>
  );
};

export default PriorityTag;

PriorityTag.propTypes = {
  item: string.isRequired,
};
