import React from 'react';
import { instanceOf } from 'prop-types';
import { format, isToday, isBefore } from 'date-fns';

import { Container, Wrapper } from './styles';

const DueTag = ({ item }) => {
  const status = () => {
    if (isToday(item)) {
      return 'today';
    }
    if (isBefore(item, new Date())) {
      return 'normal';
    }
    return 'delayed';
  };

  return (
    <Container>
      <Wrapper status={status()}>{format(item, 'dd/MM/yyyy')}</Wrapper>
    </Container>
  );
};

export default DueTag;

DueTag.propTypes = {
  item: instanceOf(Date).isRequired,
};
