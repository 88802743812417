import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Home,
  Folder,
  Documents,
  //  Users, Risks,
  Configs,
  PieChart,
  Risks,
} from '../icons';

export const Sessions = () => {
  const { userActive } = useSelector((state) => state.auth);
  const [userSessions, setUserSessions] = useState([
    {
      id: 0,
      icon: Home,
      name: i18next.t('menu.home'),
      path: '/dashboard',
    },
  ]);

  const role = userActive.activeCompany.user_role[0].role.permissions;

  useEffect(() => {
    const newSession = userSessions;
    if (role?.documents_management) {
      newSession.push({
        id: 4,
        icon: Documents,
        name: i18next.t('menu.documentManagement'),
        accordion: true,
        accordion_items: [
          {
            id: 0,
            name: i18next.t('menu.documents'),
            path: '/document-management/documents/folders',
          },
          {
            id: 1,
            name: i18next.t('menu.documentReview'),
            path: '/document-management/review',
          },
        ],
      });
    }

    if (role?.assets_management) {
      newSession.push({
        id: 3,
        icon: Folder,
        name: i18next.t('menu.assetManagement'),
        accordion: true,
        accordion_items: [
          {
            id: 0,
            name: i18next.t('menu.people'),
            path: '/assets-management/people',
          },
          {
            id: 1,
            name: i18next.t('menu.businessUnit'),
            path: '/assets-management/business-units',
          },
          {
            id: 2,
            name: i18next.t('menu.technologies'),
            path: '/assets-management/actives',
          },
          {
            id: 3,
            name: i18next.t('menu.processes'),
            path: '/assets-management/business-process',
          },
        ],
      });
    }

    if (true) {
      newSession.push({
        id: 4,
        icon: Risks,
        name: i18next.t('menu.thirdPartyManagement'),
        path: '/third-party-risks-management',
      });
    }

    if (role?.data_mapping) {
      newSession.push({
        id: 5,
        icon: PieChart,
        name: i18next.t('menu.iventory'),
        accordion: true,
        accordion_items: [
          {
            id: 0,
            name: i18next.t('menu.iventoryRecords'),
            path: '/iventory/records',
          },
          {
            id: 1,
            name: i18next.t('menu.collectionPurpose'),
            path: '/iventory/collection_purpose',
          },
          {
            id: 2,
            name: i18next.t('menu.collectionForms'),
            path: '/iventory/collection_forms',
          },
          {
            id: 3,
            name: i18next.t('menu.categoryOfDataCollected'),
            path: '/iventory/category_of_data_collected',
          },
          {
            id: 4,
            name: i18next.t('menu.transparencyToTheHolder'),
            path: '/iventory/transparency_to_the_holder',
          },
          {
            id: 5,
            name: i18next.t('menu.collectedData'),
            path: '/iventory/collected_data',
          },
          {
            id: 6,
            name: i18next.t('menu.legalBasisAssessment'),
            path: '/iventory/legal_basis_assessment',
          },
          {
            id: 7,
            name: i18next.t('menu.processUpdateFrequency'),
            path: '/iventory/process_update_frequency',
          },
          {
            id: 8,
            name: i18next.t('menu.InOutTransmission'),
            path: '/iventory/in_out_transmission',
          },
          {
            id: 8,
            name: i18next.t('menu.storageForms'),
            path: '/iventory/storage_forms',
          },
          // {
          //   id: 7,
          //   name: i18next.t('menu.collectionMedium'),
          //   path: '/iventory/collection_medium',
          // },
        ],
      });
    }

    if (role?.config) {
      newSession.push({
        id: 6,
        icon: Configs,
        name: i18next.t('menu.config'),
        accordion: true,
        accordion_items: [
          {
            id: 0,
            name: i18next.t('menu.companyData'),
            path: '/configs/company',
          },
          {
            id: 1,
            name: i18next.t('menu.departments'),
            path: '/configs/departments',
          },
          {
            id: 2,
            name: i18next.t('menu.companyUsers'),
            path: '/enterprise/user',
          },
          {
            id: 3,
            name: i18next.t('menu.signature'),
            path: '/config/signature',
          },
        ],
      });
    }

    setUserSessions(newSession);
  }, [role]);

  return userSessions;

  // return [
  //   // {
  //   //   id: 1,
  //   //   icon: Users,
  //   //   name: i18next.t('menu.holdersManagement'),
  //   //   accordion: true,
  //   //   accordion_items: [
  //   //     {
  //   //       id: 0,
  //   //       name: i18next.t('menu.demandManagement'),
  //   //       path: '/demand-management',
  //   //     },
  //   //     {
  //   //       id: 1,
  //   //       name: i18next.t('menu.holders'),
  //   //       path: '/holders',
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: 2,
  //   //   icon: Documents,
  //   //   name: i18next.t('menu.documentManagement'),
  //   //   accordion: true,
  //   //   accordion_items: [
  //   //     {
  //   //       id: 0,
  //   //       name: i18next.t('menu.documents'),
  //   //       path: '/document-management/documents/folders',
  //   //     },
  //   //     {
  //   //       id: 1,
  //   //       name: i18next.t('menu.documentReview'),
  //   //       path: '/document-management/review',
  //   //     },
  //   //   ],
  //   // },
  //   // {
  //   //   id: 4,
  //   //   icon: Risks,
  //   //   name: i18next.t('menu.riskManagement'),
  //   //   path: '/risks-management',
  //   // },
  // ];
};
