/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';

import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import RisksActions from '~/store/ducks/risks';

import convertImpact from '~/utils/convertImpact';
import convertRisk from '~/utils/convertRisk';
import RiskTag from '../RiskTag';
import ImpactTag from '../ImpactTag';
import ActionButton from '../ActionButton';

const TableComp = () => {
  const dispatch = useDispatch();
  const { risks } = useSelector((state) => state.risks);
  const [currentPage, setCurrentPage] = useState(risks.currentPage + 1);

  const handlePagination = (newPage) => {
    dispatch(RisksActions.getRisksRequest(newPage));
  };

  return (
    <Card padding="2rem 0">
      <Table
        columns={[
          {
            Header: i18next.t('risks.risksTable.id'),
            accessor: 'code',
            Cell: (e) => {
              if (!e.value) {
                return '-';
              }
              return e.value;
            },
          },
          {
            Header: i18next.t('risks.risksTable.asset'),
            accessor: 'title',
            Cell: (e) => {
              if (!e.value) {
                return '-';
              }
              return e.value;
            },
          },
          {
            Header: i18next.t('risks.risksTable.businessUnit'),
            accessor: 'business_unit.title',
            Cell: (e) => {
              if (!e.value) {
                return '-';
              }
              return e.value;
            },
          },
          {
            Header: i18next.t('risks.risksTable.threat'),
            accessor: 'threat',
            Cell: (e) => {
              if (!e.value) {
                return '-';
              }
              return e.value;
            },
          },
          {
            Header: i18next.t('risks.risksTable.risk'),
            Cell: (e) => {
              if (!e.row.original.impact || !e.row.original.probability) {
                return '-';
              }

              return (
                <RiskTag
                  item={convertRisk(
                    e.row.original.probability,
                    e.row.original.impact
                  )}
                />
              );
            },
          },
          {
            Header: i18next.t('risks.risksTable.impact'),
            accessor: 'impact',
            Cell: (e) => {
              if (!e.value) {
                return '-';
              }
              return <ImpactTag item={convertImpact(e.value)} />;
            },
          },
          {
            Header: i18next.t('risks.risksTable.status.label'),
            accessor: 'status',
            Cell: (e) => {
              switch (e.value) {
                case 'unmitigated':
                  return i18next.t('risks.risksTable.status.unmitigated');
                case 'mitigated':
                  return i18next.t('risks.risksTable.status.mitigated');
                default:
                  return '-';
              }
            },
          },
          {
            Header: i18next.t('risks.risksTable.action'),
            accessor: 'action',
            Cell: (e) =>
              e.row.original.id ? <ActionButton item={e.row.original} /> : null,
          },
        ]}
        data={
          risks.count > 0
            ? risks.rows
            : [
                {
                  title: i18next.t('risks.risksTable.noData'),
                },
              ]
        }
        total={risks?.count}
        pageCount={risks?.total_pages}
        handlePagination={handlePagination}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </Card>
  );
};

export default TableComp;
