import React from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import i18next from 'i18next';

import Card from '~/components/Card';

import {
  Container,
  Title,
  WrapperItem,
  Label,
  WrapperContent,
  Content,
} from './styles';

function Informations() {
  const { revisionDocumentDetail } = useSelector((state) => state.documents);

  return (
    <Container>
      <Card>
        <Title>
          {i18next.t('documentReview.documentDetails.informations')}
        </Title>
        <WrapperItem>
          <Label>{i18next.t('documentReview.table.editedBy')}</Label>
          <WrapperContent>
            <Content>
              {
                revisionDocumentDetail?.data?.change_history[0]?.author
                  ?.first_name
              }
            </Content>
          </WrapperContent>
        </WrapperItem>
        <WrapperItem>
          <Label>{i18next.t('documentReview.table.lastUpdate')}</Label>
          <WrapperContent>
            {revisionDocumentDetail?.data ? (
              <Content>
                {format(
                  new Date(`${revisionDocumentDetail?.data?.doc?.updatedAt}`),
                  'dd/MM/yyyy'
                )}
              </Content>
            ) : null}
          </WrapperContent>
        </WrapperItem>
        <WrapperItem>
          <Label>{i18next.t('documentReview.table.version')}</Label>
          <WrapperContent>
            <Content>{`v${revisionDocumentDetail?.data?.doc?.version}`}</Content>
          </WrapperContent>
        </WrapperItem>
      </Card>
    </Container>
  );
}

export default Informations;
