import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4;
  margin-bottom: 10rem;
`;

export const AddMoreWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;
