import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { func, arrayOf } from 'prop-types';
import { SecondarySelect } from '~/components/Select';
import { TertiaryButton } from '~/components/Button';

import {
  Container,
  Title,
  Label,
  ID,
  IconPlus,
  DeleteButton,
  DeleteIcon,
  WrapperAddButton,
} from './styles';

import {
  naturalOptions,
  humanOptions,
  techOptions,
  physicOptions,
  politicalEconomicsOptions,
} from './threatOptions';

function Threats({ threats, setThreats }) {
  const newActive = () => {
    const updatedThreats = [...threats];
    updatedThreats.push({ type: '', threat: '' });
    setThreats(updatedThreats);
  };

  const deleteActive = (index) => {
    const newArray = [...threats];
    newArray.splice(index, 1);
    setThreats(newArray);
  };

  const updateActive = (index, updatedActive) => {
    const newArray = [...threats];
    newArray[index] = { ...newArray[index], threat: updatedActive };
    setThreats(newArray);
  };

  const updateType = (index, updatedType) => {
    // const newArray = Immutable.asMutable(threats, { deep: true });
    const newArray = [...threats];
    newArray[index].type = updatedType;
    newArray[index].threat = '';

    setThreats(newArray);
  };

  return (
    <Container>
      <Title>{i18next.t('processAsset.tabs.associatedThreats.label')}</Title>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t('processAsset.tabs.associatedThreats.typeLabel')}
          </Label>
        </Grid>
        <Grid item xs={4}>
          <Label>
            {i18next.t('processAsset.tabs.associatedThreats.threatLabel')}
          </Label>
        </Grid>
        <Grid item xs={3} />
        {threats?.map((item, index) => (
          <>
            <Grid item xs={1}>
              <ID>
                {Number(index + 1).toLocaleString('pt-BR', {
                  minimumIntegerDigits: 2,
                })}
              </ID>
            </Grid>
            <Grid item xs={4}>
              <SecondarySelect
                options={[
                  {
                    label: i18next.t(
                      'processAsset.tabs.associatedThreats.threats.nature'
                    ),
                    value: 'Naturais',
                  },
                  {
                    label: i18next.t(
                      'processAsset.tabs.associatedThreats.threats.human'
                    ),
                    value: 'Humanas',
                  },
                  {
                    label: i18next.t(
                      'processAsset.tabs.associatedThreats.threats.technologies'
                    ),
                    value: 'Tecnologicas',
                  },
                  {
                    label: i18next.t(
                      'processAsset.tabs.associatedThreats.threats.physics'
                    ),
                    value: 'Fisicas',
                  },
                  {
                    label: i18next.t(
                      'processAsset.tabs.associatedThreats.threats.economy'
                    ),
                    value: 'Politicas/Economicas',
                  },
                ]}
                onChange={(e) => updateType(index, e)}
                value={threats[index].type}
              />
            </Grid>
            <Grid item xs={4}>
              <SecondarySelect
                options={
                  threats[index].type === 'Naturais'
                    ? naturalOptions
                    : threats[index].type === 'Humanas'
                    ? humanOptions
                    : threats[index].type === 'Tecnologicas'
                    ? techOptions
                    : threats[index].type === 'Fisicas'
                    ? physicOptions
                    : politicalEconomicsOptions
                }
                onChange={(e) => updateActive(index, e)}
                value={threats[index].threat}
              />
            </Grid>
            <Grid item xs={3}>
              <DeleteButton onClick={() => deleteActive(index)}>
                <DeleteIcon />
              </DeleteButton>
            </Grid>
          </>
        ))}
      </Grid>
      <WrapperAddButton>
        <TertiaryButton
          onClick={newActive}
          label={i18next.t('common.buttons.add')}
          leftIcon={<IconPlus />}
        />
      </WrapperAddButton>
    </Container>
  );
}

export default Threats;

Threats.propTypes = {
  threats: arrayOf.isRequired,
  setThreats: func.isRequired,
};
