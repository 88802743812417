import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func } from 'prop-types';
import { Grid } from '@material-ui/core';
import { Formik } from 'formik';
import i18next from 'i18next';

import Modal from '~/components/Modal';
import TextInput from '~/components/TextInput';
import { SecondaryButton, PrimaryButton } from '~/components/Button';

import PeopleActions from '~/store/ducks/people';
import iventory from '~/store/ducks/iventory';
import schema from './validationSchema';

import { Container, WrapperTop } from './styles';

const InviteDPOModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.auth);
  function reloadPeople() {
    dispatch(PeopleActions.getPeopleRequest());
    dispatch(iventory.getPeopleResponsibleEnumRequest(companyId));
  }

  const submit = (values) => {
    dispatch(
      PeopleActions.inviteNewDpoRequest(values.name, values.email, reloadPeople)
    );
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18next.t('peopleAsset.inviteDPOModal.title')}
      noPadding
    >
      <Container>
        <Formik
          initialValues={{
            name: '',
            email: '',
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <>
              <WrapperTop>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={5}>
                    <TextInput
                      label={i18next.t('peopleAsset.inviteDPOModal.name')}
                      placeholder="João Santos"
                      name="name"
                      value={values.name}
                      touched={touched.name}
                      error={errors.name}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={7}>
                    <TextInput
                      label={i18next.t('common.fields.email')}
                      placeholder="Ex.: meuemail@email.com.br"
                      name="email"
                      value={values.email}
                      touched={touched.email}
                      error={errors.email}
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </WrapperTop>

              <Grid container spacing={3} justify="center">
                <Grid item xs={12} md={6} lg={4}>
                  <SecondaryButton
                    label={i18next.t('common.buttons.cancel')}
                    type="secondary"
                    onClick={onClose}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <PrimaryButton
                    label={i18next.t('common.buttons.add')}
                    onClick={handleSubmit}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Container>
    </Modal>
  );
};

export default InviteDPOModal;

InviteDPOModal.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
