import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* max-width: 1120px; */
  width: 100%;
  margin: auto;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-bottom: 2.5rem;
`;

export const GoBackWrapper = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 2rem;
  cursor: pointer;

  margin-top: -50px;
  position: absolute;

  ${({ show }) =>
    show
      ? css`
          visibility: hidden;
          max-height: 0;
        `
      : css`
          visibility: visible;
          max-height: none;
        `}

  span {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.base[400]};
    font-family: ${({ theme }) => theme.fonts.Poppins};

    font-size: 1.6rem;

    & + span {
      margin-left: 1.5rem;
    }
  }
`;
