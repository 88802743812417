import styled from 'styled-components';

export const Container = styled.div``;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 21px 32px 100px 32px;
  background: #ffffff;
  border-radius: 10px;
`;

export const ResponsibleWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #f8f8f8;
  span {
    margin-left: 12px;
  }
`;
export const ResponsibleAvatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 5px;
`;

export const ResponsibleName = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins';
`;
