import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import translateError from '~/utils/translateError';

import api from '~/services/api';
import TechnologyActions, { TechnologiesTypes } from '../ducks/technologies';

function* getTechnologies({ page, limit, search }) {
  try {
    const { userActive } = yield select((state) => state.auth);

    const response = yield call(
      api.get,
      `/api/technologies/list/${userActive.activeCompany.id}?limit=${limit}&page=${page}&search=${search}`
    );

    yield put(TechnologyActions.getTechnologiesSuccess(response.data));
  } catch (error) {
    yield put(TechnologyActions.getTechnologiesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchTechnologies({ query }) {
  try {
    const response = yield call(api.get, `/assets?search=${query}`);

    yield put(TechnologyActions.searchTechnologiesSuccess(response.data));
  } catch (error) {
    yield put(TechnologyActions.searchTechnologiesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createTechnology({
  data: { sidebarData, processes, impact },
  handleGoBack,
}) {
  const { userActive } = yield select((state) => state.auth);
  const data = {
    company_id: userActive?.activeCompany?.id,
    locality_id: sidebarData.businessUnit || null,
    departament_id: sidebarData.department || '',
    status: sidebarData.status,
    title: sidebarData.title || '',
    primary_function: sidebarData?.primaryFunction || '',
    technology_responsables: sidebarData?.responsible || '',
    associated_processes_technology: processes?.associatedProcesses || [],
    storage_technologies: {
      repository: processes?.repository || '',
      international_storage: processes?.internationalStorage,
      external_storage: processes?.externalTreatment,
    },
    impact_inoperability_technologies: impact?.maxTime
      ?.typeOfTimeMeasurement && {
      maximum_time: impact?.maxTime?.time,
      unit_of_time: impact?.maxTime?.typeOfTimeMeasurement,
      impact: impact?.impact,
      consequence: impact?.description,
    },
    // contingency_details_technologies: actives?.map((item) => ({
    //   active_type: item?.asset_type,
    //   active_id: item?.asset_id,
    //   maximum_time: item.operation_time?.time,
    //   unit_of_time: item.operation_time?.typeOfTimeMeasurement,
    //   impact: item?.impact,
    //   consequence: item?.solution,
    // })),
    // standard_processes: pcn.defaultProcess,
  };

  try {
    const response = yield call(api.post, '/api/technologies', data);

    yield put(TechnologyActions.createTechnologiesSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Tecnologia criada!',
      })
    );

    handleGoBack(response.data);
  } catch (error) {
    yield put(TechnologyActions.createTechnologiesFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação da tecnologia',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* updateTechnology({ data: { sidebarData, processes, impact }, id }) {
  try {
    const { userActive } = yield select((state) => state.auth);
    const data = {
      company_id: userActive?.activeCompany?.id,
      locality_id: sidebarData.businessUnit || '',
      departament_id: sidebarData.department || '',
      status: sidebarData.status,
      title: sidebarData.title || '',
      primary_function: sidebarData?.primaryFunction || '',
      technology_responsables: sidebarData?.responsible || '',
      associated_processes_technology: processes?.associatedProcesses || [],
      storage_technologies: {
        repository: processes?.repository || '',
        international_storage: processes?.internationalStorage,
        external_storage: processes?.externalTreatment,
      },
      impact_inoperability_technologies: impact?.maxTime
        ?.typeOfTimeMeasurement && {
        maximum_time: impact?.maxTime?.time,
        unit_of_time: impact?.maxTime?.typeOfTimeMeasurement,
        impact: impact?.impact,
        consequence: impact?.description,
      },
    };

    yield call(api.put, `/api/technology/${id}`, {
      ...data,
    });

    yield put(TechnologyActions.updateTechnologiesSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Tecnologia atualizada!',
      })
    );
  } catch (error) {
    yield put(TechnologyActions.updateTechnologiesFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha ao atualizar tecnologia',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getTechnologyDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/technology/${id}`);

    yield put(TechnologyActions.getTechnologyDetailsSuccess(response.data));
  } catch (error) {
    yield put(TechnologyActions.getTechnologyDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getTechnologiesEnum() {
  try {
    const response = yield call(api.get, '/assets/enum_type');

    yield put(TechnologyActions.getTechnologiesEnumSuccess(response.data));
  } catch (error) {
    yield put(TechnologyActions.getTechnologiesEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de tecnologias',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadTechnologies() {
  try {
    const response = yield call(api.get, `/assets/download_xlsx`, {
      params: {
        asset: ['title', 'createdAt'],
        responsible: ['name'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Ativos-Tecnologias.xlsx');

    yield put(TechnologyActions.downloadTechnologiesSuccess());
  } catch (error) {
    yield put(TechnologyActions.downloadTechnologiesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadTechnologiesRequest({ data }) {
  try {
    yield call(api.post, '/assets/upload_xlsx', data);
    yield put(TechnologyActions.uploadTechnologiesSuccess());
  } catch (error) {
    yield put(TechnologyActions.uploadTechnologiesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de tecnologias',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteTechnologies({ id }) {
  try {
    yield call(api.delete, `api/technology/${id}`);
    yield put(TechnologyActions.deleteTechnologiesSuccess(id));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Tecnologia excluída!',
      })
    );
    yield put(push('/assets-management/actives'));
  } catch (error) {
    yield put(TechnologyActions.deleteTechnologiesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao excluir tecnologia',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(TechnologiesTypes.GET_TECHNOLOGIES_REQUEST, getTechnologies),
  takeLatest(TechnologiesTypes.SEARCH_TECHNOLOGIES_REQUEST, searchTechnologies),
  takeLatest(TechnologiesTypes.CREATE_TECHNOLOGIES_REQUEST, createTechnology),
  takeLatest(TechnologiesTypes.UPDATE_TECHNOLOGIES_REQUEST, updateTechnology),
  takeLatest(TechnologiesTypes.DELETE_TECHNOLOGIES_REQUEST, deleteTechnologies),
  takeLatest(
    TechnologiesTypes.GET_TECHNOLOGY_DETAILS_REQUEST,
    getTechnologyDetails
  ),
  takeLatest(
    TechnologiesTypes.GET_TECHNOLOGIES_ENUM_REQUEST,
    getTechnologiesEnum
  ),
  takeLatest(
    TechnologiesTypes.DOWNLOAD_TECHNOLOGIES_REQUEST,
    downloadTechnologies
  ),
  takeLatest(
    TechnologiesTypes.UPLOAD_TECHNOLOGIES_REQUEST,
    uploadTechnologiesRequest
  ),
]);
