import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import { PrimaryButton } from '~/components/Button';
import CheckBox from '~/components/CheckBox';
// import { LogoFacebook, LogoGoogle } from '~/assets/icons';
import AuthActions from '~/store/ducks/auth';
import { Row, Column } from '~/styles/components';
import getQueryStringParams from '~/utils/getQueryStringParams';
import schema from './validationSchema';

import {
  Container,
  Logo,
  ForgotPassword,
  // SocialButton,
  // SocialIcon,
  PasswordWrapper,
  ShowPasswordIcon,
  HidePasswordIcon,
} from './styles';

function Form() {
  const [rememberPassword, setRememberPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const params = getQueryStringParams(location.search);

  function Redirects() {
    if (params.redirect) {
      history.push(params.redirect);
    } else {
      history.push('/demands-history');
    }
  }

  const submit = useCallback(
    (values) => {
      dispatch(
        AuthActions.signInClientRequest(
          values.email,
          values.password,
          Redirects
        )
      );
    },
    [dispatch]
  );

  return (
    <Container>
      <Logo />
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={submit}
        validationSchema={schema}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => (
          <>
            <Column width="100%">
              <TextInput
                label={i18next.t('common.fields.email')}
                placeholder="Seu e-mail"
                name="email"
                value={values.email}
                touched={touched.email}
                error={errors.email}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 4.8rem 0"
              />
              <PasswordWrapper>
                <TextInput
                  label="Senha"
                  placeholder="Sua senha"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={values.password}
                  touched={touched.password}
                  error={errors.password}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  margin="0"
                />
                {showPassword ? (
                  <HidePasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <ShowPasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </PasswordWrapper>
              <Row
                align="center"
                width="100%"
                margin="3.3rem 0 7.7rem"
                justify="space-between"
              >
                <CheckBox
                  label="Lembrar senha"
                  selected={rememberPassword}
                  onSelect={() => setRememberPassword((e) => !e)}
                />
                <ForgotPassword to="/forgot-password">
                  Esqueceu a senha?
                </ForgotPassword>
              </Row>
            </Column>
            <Column width="100%" margin="0 0 4.8rem">
              <PrimaryButton
                label="Entrar"
                margin="0 0 1.6rem"
                onClick={handleSubmit}
              />
            </Column>
            {/* <Row justify="center" width="100%">
              <SocialButton>
                <SocialIcon src={LogoFacebook} />
              </SocialButton>
              <SocialButton>
                <SocialIcon src={LogoGoogle} />
              </SocialButton>
            </Row> */}
          </>
        )}
      </Formik>
    </Container>
  );
}

export default Form;
