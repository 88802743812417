import { bool } from 'prop-types';
import React from 'react';
import { AiFillPieChart } from 'react-icons/ai';

export default function PieChart({ active }) {
  return <AiFillPieChart size={18} color={active ? '#4347A2' : '#606474'} />;
}

PieChart.propTypes = {
  active: bool.isRequired,
};
