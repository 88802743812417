import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
/* Types & Action Creators */

const { Types, Creators } = createActions({
  getProvidersRequest: ['page', 'limit', 'search'],
  getProvidersSuccess: ['providers'],
  getProvidersFailure: [''],

  getProvidersEnumRequest: [''],
  getProvidersEnumSuccess: ['providersEnum'],
  getProvidersEnumFailure: [''],

  createProviderRequest: ['data', 'onSuccess'],
  createProviderSuccess: ['provider'],
  createProviderFailure: [''],

  updateProviderRequest: ['id', 'data', 'onSuccess'],
  updateProviderSuccess: ['provider'],
  updateProviderFailure: [''],

  deleteProviderRequest: ['id'],
  deleteProviderSuccess: ['id'],
  deleteProviderFailure: [''],

  detailsProviderRequest: ['id'],
  detailsProviderSuccess: ['provider'],
  detailsProviderFailure: [''],

  clearDetailsProviderRequest: [''],
});

export const ProviderTypes = Types;
export default Creators;

/* Initial State */

export const INITIAL_STATE = Immutable({
  isLoading: false,
  error: false,
  providers: [],
  providersEnum: [],
  provider: {},
});

/* Reducers */

const getProvidersRequest = (state) => state.merge({ isLoading: true });
const getProvidersSuccess = (state, { providers }) =>
  state.merge({
    isLoading: false,
    error: false,
    providers,
  });
const getProvidersFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    providers: [],
  });

const getProvidersEnumRequest = (state) => state.merge({ isLoading: true });
const getProvidersEnumSuccess = (state, { providersEnum }) =>
  state.merge({
    isLoading: false,
    error: false,
    providersEnum,
  });
const getProvidersEnumFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    providers: [],
  });

const createProviderRequest = (state) => state.merge({ isLoading: true });
const createProviderSuccess = (state, { provider }) => {
  return state.merge({
    isLoading: false,
    error: false,
    providers: {
      ...state.businessUnits,
      rows: [provider.provider, ...state.providers.rows],
      count: state.providers.count + 1,
    },
    provider: {},
  });
};
const createProviderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const updateProviderRequest = (state) => state.merge({ isLoading: true });
const updateProviderSuccess = (state, { provider }) => {
  const updatedProviders = state.providers.rows.map((item) => {
    if (item.id === provider.updateProvider.id) {
      return provider.updateProvider;
    }
    return item;
  });

  return state.merge({
    isLoading: false,
    error: false,
    providers: {
      ...state.businessUnits,
      rows: updatedProviders,
    },
    provider: {},
  });
};
const updateProviderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

const clearDetailsProviderRequest = (state) =>
  state.merge({
    provider: {},
  });

const detailsProviderRequest = (state) => state.merge({ isLoading: true });

const detailsProviderSuccess = (state, { provider }) => {
  return state.merge({
    isLoading: false,
    error: false,
    provider,
  });
};
const detailsProviderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
    provider: {},
  });

const deleteProviderRequest = (state) => state.merge({ isLoading: true });

const deleteProviderSuccess = (state, { id }) => {
  const fitred = [...state.providers.rows.filter((item) => item.id !== id)];
  const countFormated = state.providers.count - 1;

  return state.merge({
    isLoading: false,
    error: false,
    providers: {
      ...state.businessUnits,
      rows: fitred,
      count: countFormated,
    },
  });
};

const deleteProviderFailure = (state) =>
  state.merge({
    isLoading: false,
    error: true,
  });

/* Reducers to types */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_PROVIDERS_REQUEST]: getProvidersRequest,
  [Types.GET_PROVIDERS_SUCCESS]: getProvidersSuccess,
  [Types.GET_PROVIDERS_FAILURE]: getProvidersFailure,

  [Types.GET_PROVIDERS_ENUM_REQUEST]: getProvidersEnumRequest,
  [Types.GET_PROVIDERS_ENUM_SUCCESS]: getProvidersEnumSuccess,
  [Types.GET_PROVIDERS_ENUM_FAILURE]: getProvidersEnumFailure,

  [Types.CREATE_PROVIDER_REQUEST]: createProviderRequest,
  [Types.CREATE_PROVIDER_SUCCESS]: createProviderSuccess,
  [Types.CREATE_PROVIDER_FAILURE]: createProviderFailure,

  [Types.UPDATE_PROVIDER_REQUEST]: updateProviderRequest,
  [Types.UPDATE_PROVIDER_SUCCESS]: updateProviderSuccess,
  [Types.UPDATE_PROVIDER_FAILURE]: updateProviderFailure,

  [Types.DETAILS_PROVIDER_REQUEST]: detailsProviderRequest,
  [Types.DETAILS_PROVIDER_SUCCESS]: detailsProviderSuccess,
  [Types.DETAILS_PROVIDER_FAILURE]: detailsProviderFailure,

  [Types.DELETE_PROVIDER_REQUEST]: deleteProviderRequest,
  [Types.DELETE_PROVIDER_SUCCESS]: deleteProviderSuccess,
  [Types.DELETE_PROVIDER_FAILURE]: deleteProviderFailure,

  [Types.CLEAR_DETAILS_PROVIDER_REQUEST]: clearDetailsProviderRequest,
});
