import React from 'react';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import { string, bool, func } from 'prop-types';
import Modal from '~/components/Modal';
import Card from '~/components/Card';
import { TertiaryButton } from '~/components/Button';
import TextInput from '~/components/TextInput';
import { SecondarySelect } from '~/components/Select';

import convertRisk from '~/utils/convertRisk';
import convertImpact from '~/utils/convertImpact';
import EvaluationModal from '../EvaluationModal';

import {
  Container,
  Subtitle,
  HeaderRow,
  Title,
  BottomRow,
  WrapperInput,
  WrapperMath,
  MultiplyIcon,
  EqualsIcon,
  Label,
  WrapperResult,
  ContainerButton,
} from './styles';

function ActiveInfo({
  id,
  openModal,
  setOpenModal,
  newTreatment,
  setNewTreatment,
}) {
  const { riskDetails } = useSelector((state) => state.risks);

  return (
    <>
      <Card>
        <Container>
          <Subtitle>{i18next.t('risks.riskDetails.title')}</Subtitle>
          <HeaderRow>
            <Title>{`#${riskDetails?.code} - ${riskDetails?.title}`}</Title>
          </HeaderRow>
          <BottomRow>
            <WrapperInput>
              <SecondarySelect
                label={i18next.t('risks.riskDetails.manifestationProbability')}
                placeholder=""
                options={[
                  {
                    label: i18next.t('risks.board.probability.veryLow'),
                    value: 'very_low',
                  },
                  {
                    label: i18next.t('risks.board.probability.low'),
                    value: 'low',
                  },
                  {
                    label: i18next.t('risks.board.probability.medium'),
                    value: 'medium',
                  },
                  {
                    label: i18next.t('risks.board.probability.high'),
                    value: 'high',
                  },
                  {
                    label: i18next.t('risks.board.probability.veryHigh'),
                    value: 'very_high',
                  },
                ]}
                disabled
                value={riskDetails?.probability}
              />
            </WrapperInput>
            <WrapperMath>
              <MultiplyIcon />
            </WrapperMath>
            <WrapperInput>
              <TextInput
                editable={false}
                style={{ background: '#C6CCD2' }}
                label={i18next.t('risks.riskDetails.processImpact')}
                value={convertImpact(riskDetails?.impact)}
              />
            </WrapperInput>
            <WrapperMath>
              <EqualsIcon />
            </WrapperMath>
            <WrapperInput>
              <Label>{i18next.t('risks.riskDetails.resultRisk')}</Label>
              <WrapperResult>
                {convertRisk(riskDetails?.probability, riskDetails?.impact)}
              </WrapperResult>
            </WrapperInput>
          </BottomRow>
          <ContainerButton>
            <TertiaryButton
              label={i18next.t('risks.riskDetails.createEvaluation')}
              onClick={() => setOpenModal(true)}
            />
          </ContainerButton>
        </Container>
      </Card>

      <Modal
        open={openModal}
        onClose={() => {
          setNewTreatment(true);
          setOpenModal(false);
        }}
        title={i18next.t('risks.riskDetails.createEvaluationModal.title')}
      >
        <EvaluationModal
          onClose={() => setOpenModal(false)}
          id={id}
          newTreatment={newTreatment}
          setNewTreatment={setNewTreatment}
        />
      </Modal>
    </>
  );
}

export default ActiveInfo;

ActiveInfo.propTypes = {
  id: string.isRequired,
  openModal: bool.isRequired,
  setOpenModal: func.isRequired,
  setNewTreatment: func.isRequired,
  newTreatment: bool.isRequired,
};
