import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';

import {
  dateSortMethod,
  // formatters
} from '~/utils';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import IventoryActions from '~/store/ducks/iventory';
import { format } from 'date-fns';
import {
  // func,
  string,
} from 'prop-types';
// import ActionButton from '../ActionButton';
import { useParams } from 'react-router-dom';
import { Container, Spinner } from './styles';

const TableComp = ({
  search,
  //  setEditDepartament, setDepartamentModal
}) => {
  const dispatch = useDispatch();
  const { iventoryOption } = useParams();
  const [currentPage, setCurrentPage] = useState(0);
  const { iventoryOptions, isGetLoading } = useSelector(
    (state) => state.iventory
  );
  const limit = 10;

  const handlePagination = (nextPage) => {
    setCurrentPage(nextPage);
  };

  // const handleEditDepartament = (departamentEdit) => {
  // setEditDepartament(departamentEdit);
  // setDepartamentModal(true);
  // };

  // const handleDelete = (id) => {
  // dispatch(
  //   ConfigActions.deleteDepartamentRequest(userActive.activeCompany.id, id)
  // );
  // };

  useEffect(() => {
    switch (iventoryOption) {
      case 'collection_purpose':
        dispatch(
          IventoryActions.getCollectionPurposeRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'collection_forms':
        dispatch(
          IventoryActions.getCollectionFormsRequest(currentPage, limit, search)
        );
        break;
      case 'category_of_data_collected':
        dispatch(
          IventoryActions.getCategoryOfDataCollectedRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'transparency_to_the_holder':
        dispatch(
          IventoryActions.getTransparencyToTheHolderRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'collected_data':
        dispatch(
          IventoryActions.getCollectedDataRequest(currentPage, limit, search)
        );
        break;
      case 'legal_basis_assessment':
        dispatch(
          IventoryActions.getLegalBasisAssessmentRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'process_update_frequency':
        dispatch(
          IventoryActions.getProcessUpdateFrequenciesRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'in_out_transmission':
        dispatch(
          IventoryActions.getInOutTransmissionsRequest(
            currentPage,
            limit,
            search
          )
        );
        break;
      case 'storage_forms':
        dispatch(
          IventoryActions.getStorageFormsRequest(currentPage, limit, search)
        );
        break;
      default:
        break;
    }
  }, [search, currentPage, iventoryOption]);

  return (
    <Container>
      {isGetLoading ? (
        <Spinner />
      ) : (
        <Card padding="2rem 0">
          <Table
            columns={[
              {
                Header: i18next.t('iventory.createOptionModal.name'),
                accessor: 'type',
                Cell: (e) => {
                  if (!e.value) {
                    return '-';
                  }
                  return e.value;
                },
              },
              {
                Header: i18next.t('iventory.createOptionModal.date'),
                accessor: 'createdAt',
                Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
                sortType: (a, b) => {
                  return dateSortMethod(a.values.date, b.values.date);
                },
              },
              // {
              //   Header: i18next.t('peopleAsset.table.action.label'),
              //   accessor: 'action',
              //   Cell: (e) => {
              //     if (!e.value) {
              //       return (
              //         <ActionButton
              //           dataId={e.row.original?.id}
              //           dispatchDelete={handleDelete}
              //         />
              //       );
              //     }
              //     return null;
              //   },
              // },
            ]}
            data={iventoryOptions.rows || []}
            // onRowClick={handleEditDepartament}
            total={iventoryOptions?.count ?? 0}
            pageCount={iventoryOptions.total_page}
            handlePagination={handlePagination}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage + 1}
          />
        </Card>
      )}
    </Container>
  );
};

TableComp.propTypes = {
  search: string,
  // setEditDepartament: func.isRequired,
  // setDepartamentModal: func.isRequired,
};

TableComp.defaultProps = {
  search: null,
};

export default TableComp;
