import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as toastrActions } from 'react-redux-toastr';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import { useSelector } from 'react-redux';
import DocumentActions, { DocumentTypes } from '../ducks/documents';

function* loadFolders({ page = 0, limit = 6 }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/documents/list-folders/${userActive.activeCompany.id}?page=${page}&limit=${limit}`
    );

    yield put(DocumentActions.getFoldersSuccess(response?.data));
  } catch (error) {
    yield put(DocumentActions.getFoldersFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* loadGlobalFolders({ page = 0, limit = 6 }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/folders/global?page=${page}&limit=${limit}`
    );

    yield put(DocumentActions.getFoldersGlobalSuccess(response?.data));
  } catch (error) {
    yield put(DocumentActions.getFoldersGlobalFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchFolders({ query, page = 0, limit = 6 }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/documents/list-folders/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${query}`
    );

    yield put(DocumentActions.searchFoldersSuccess(response.data));
  } catch (error) {
    yield put(DocumentActions.searchFoldersFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchFoldersGlobal({ query, page = 0, limit = 6 }) {
  try {
    const response = yield call(
      api.get,
      `//api/documents/folders/global?page=${page}&limit=${limit}&search=${query}`
    );

    yield put(DocumentActions.searchFoldersGlobalSuccess(response.data));
  } catch (error) {
    yield put(DocumentActions.searchFoldersGlobalFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createFolder({ name, companyId, departmentId, reloadFolders }) {
  try {
    const response = yield call(api.post, '/api/documents/folder', {
      name,
      company_id: companyId,
      departament_id: departmentId,
    });

    yield put(DocumentActions.getFoldersSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Tudo certo!',
        message: 'Pasta criada com sucesso!',
      })
    );
    reloadFolders();
  } catch (error) {
    yield put(DocumentActions.getFoldersFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no carregamento dos dados',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* editFolderName({ folderId, newName, reloadFolders }) {
  try {
    yield call(api.put, `/api/documents/update-folder/${folderId}`, {
      new_name: newName,
    });

    yield put(DocumentActions.editFolderNameSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Tudo certo!',
        message: 'Pasta atualizada com sucesso!',
      })
    );
    reloadFolders();
  } catch (error) {
    yield put(DocumentActions.editFolderNameFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no carregamento dos dados',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* deleteFolder({ folderId, newFolderId, reloadFolders }) {
  try {
    if (newFolderId) {
      yield call(api.put, `/api/documents/move-all-docs-delete-folder`, {
        old_folder_id: folderId,
        new_folder_id: newFolderId,
      });
    } else {
      yield call(api.delete, `/api/documents/delete-folder/${folderId}`);
    }

    yield put(DocumentActions.deleteFolderSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Tudo certo!',
        message: 'Pasta deletada com sucesso!',
      })
    );
    reloadFolders();
  } catch (error) {
    yield put(DocumentActions.deleteFolderFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha no carregamento dos dados',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* createNewDocument({ title, folderId, document, reloadDocuments }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const formData = convertFormData({
      name: title,
      folder_id: folderId,
      file: document,
      company_id: `${userActive.activeCompany.id}`,
    });

    yield call(api.post, '/api/documents', formData);

    yield put(DocumentActions.createNewDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Tudo certo!',
        message: 'Documento criado com sucesso!',
      })
    );

    reloadDocuments();
  } catch (error) {
    yield put(DocumentActions.createNewDocumentFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação do documento',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getFolderDetails({ id, page = 0, limit = 6 }) {
  const { userActive } = yield select((state) => state.auth);

  const folders = yield call(
    api.get,
    `/api/documents/list-folders/${userActive?.activeCompany?.id}`
  );

  const result = folders?.data?.rows.find((item) => item.id === id);

  try {
    const response = yield call(
      api.get,
      `/api/documents/docs-folder/${id}?page=${page}&limit=${limit}`
    );

    yield put(
      DocumentActions.getFolderDetailsSuccess(response?.data, result?.name)
    );
  } catch (error) {
    yield put(DocumentActions.getFolderDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchFolderDetails({ id, query }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/docs-folder/${id}?search=${query}`
    );

    yield put(DocumentActions.searchFolderDetailsSuccess(response?.data));
  } catch (error) {
    yield put(DocumentActions.searchFolderDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchMyFolderDetails({ id, query }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/list-my/${id}?search=${query}`
    );

    yield put(DocumentActions.searchMyFolderDetailsSuccess(response?.data));
  } catch (error) {
    yield put(DocumentActions.searchMyFolderDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getMyDocuments({ id, page = 0, limit = 6 }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/list-my/${id}?page=${page}&limit=${limit}`
    );

    yield put(DocumentActions.getMyDocumentsSuccess(response?.data));
  } catch (error) {
    yield put(DocumentActions.getMyDocumentsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* moveDocument({
  documentId,
  oldFolderId,
  newFolderId,
  reloadDocuments,
}) {
  try {
    yield call(api.put, `/api/documents/move-doc-folder`, {
      old_folder_id: oldFolderId,
      new_folder_id: newFolderId,
      document_id: documentId,
    });

    yield put(DocumentActions.moveDocumentSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Documento movido.',
      })
    );

    reloadDocuments();
  } catch (error) {
    yield put(DocumentActions.moveDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no mover de documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteDocument({ documentId, reloadDocuments }) {
  try {
    yield call(api.delete, `/api/documents/remove/${documentId}`);

    yield put(DocumentActions.deleteDocumentSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Documento deletado.',
      })
    );

    reloadDocuments();
  } catch (error) {
    yield put(DocumentActions.deleteDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao deletar documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getDocumentDetails({ documentId }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/last-version/${documentId}`
    );

    const getUrlFiles = yield call(
      api.get,
      `/public/doc?doc=${response?.data?.data?.last_version?.file}`
    );

    const result = {
      ...response?.data,
      fileUrl: getUrlFiles?.data.replace(/"/g, ''),
    };

    yield put(DocumentActions.getDocumentDetailsSuccess(result));
  } catch (error) {
    yield put(DocumentActions.getDocumentDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados do documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getSharedDocumentDetails({ documentId }) {
  try {
    const response = yield call(api.get, `/documents/default/${documentId}`);

    yield put(
      DocumentActions.getSharedDocumentDetailsSuccess({
        ...response.data,
        default: true,
      })
    );

    yield put(
      push(`/document-management/documents/document-details/${documentId}`)
    );
  } catch (error) {
    yield put(DocumentActions.getSharedDocumentDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados do documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getAllRevisionDocuments({ page }) {
  const { userActive } = yield select((state) => state.auth);

  try {
    let response;
    if (page) {
      response = yield call(
        api.get,
        `/api/documents/all-docs/${userActive?.activeCompany?.id}?page=${page}`
      );
    } else {
      response = yield call(
        api.get,
        `/api/documents/all-docs/${userActive?.activeCompany?.id}`
      );
    }

    yield put(DocumentActions.getAllRevisionDocumentsSuccess(response.data));
  } catch (error) {
    yield put(DocumentActions.getAllRevisionDocumentsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar os dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchRevisionDocuments({ query }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/documents/all-docs/${userActive?.activeCompany?.id}?search=${query}`
    );

    yield put(DocumentActions.searchRevisionDocumentsSuccess(response.data));
  } catch (error) {
    yield put(DocumentActions.searchRevisionDocumentsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar os dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getRevisionDocumentDetails({ documentId }) {
  try {
    const response = yield call(
      api.get,
      `/api/documents/last-version/${documentId}`
    );

    const getUrlFiles = yield call(
      api.get,
      `/public/doc?doc=${response?.data?.data?.last_version?.file}`
    );

    const result = {
      ...response?.data,
      fileUrl: getUrlFiles?.data.replace(/"/g, ''),
    };

    yield put(DocumentActions.getRevisionDocumentDetailsSuccess(result));
    yield put(push(`/document-management/review/${documentId}`));
  } catch (error) {
    yield put(DocumentActions.getRevisionDocumentDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar os dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* approveRevisionDocument({ documentId }) {
  try {
    yield call(api.put, `/api/documents/approve/${documentId}`, {
      status: 'approved',
    });

    yield put(DocumentActions.approveRevisionDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Documento aprovado!',
      })
    );
  } catch (error) {
    yield put(DocumentActions.approveRevisionDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao aprovar documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* askForRevision({ documentId, justification }) {
  try {
    yield call(api.put, `/api/documents/disapprove/${documentId}`, {
      status: 'review',
      description: justification,
    });

    yield put(DocumentActions.askForDocumentRevisionSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Revisão solicitada!',
      })
    );
  } catch (error) {
    yield put(DocumentActions.askForDocumentRevisionFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao solicitar revisão',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadLastestDocument({ idDoc }) {
  try {
    const resultFiles = yield call(
      api.get,
      `/api/documents/last-version/${idDoc}`
    );

    const getUrlFiles = yield call(
      api.get,
      `/public/doc?doc=${resultFiles?.data?.data?.last_version?.file}`
    );

    const win = window.open(`${getUrlFiles?.data.replace(/"/g, '')}`, '_blank');
    win.focus();

    yield put(DocumentActions.downloadLastestDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Download iniciado!',
      })
    );
  } catch (error) {
    yield put(DocumentActions.downloadLastestDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao baixar documento',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadDocument({ document, documentId }) {
  try {
    const formData = convertFormData({
      document_id: documentId,
      file: document,
    });

    yield call(api.post, `/api/documents/new-version`, formData);

    yield put(DocumentActions.uploadDocumentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Upload realizado!',
      })
    );
  } catch (error) {
    yield put(DocumentActions.uploadDocumentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao fazer upload',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getDocumentsEnum() {
  try {
    const response = yield call(api.get, `/documents/enum_type`);

    yield put(DocumentActions.getDocumentsEnumSuccess(response.data));
  } catch (error) {
    yield put(DocumentActions.getDocumentsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro!',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(DocumentTypes.GET_FOLDERS_REQUEST, loadFolders),
  takeLatest(DocumentTypes.GET_FOLDERS_GLOBAL_REQUEST, loadGlobalFolders),
  takeLatest(DocumentTypes.SEARCH_FOLDERS_REQUEST, searchFolders),
  takeLatest(DocumentTypes.SEARCH_FOLDERS_GLOBAL_REQUEST, searchFoldersGlobal),
  takeLatest(DocumentTypes.CREATE_FOLDER_REQUEST, createFolder),
  takeLatest(DocumentTypes.EDIT_FOLDER_NAME_REQUEST, editFolderName),
  takeLatest(DocumentTypes.DELETE_FOLDER_REQUEST, deleteFolder),
  takeLatest(DocumentTypes.CREATE_NEW_DOCUMENT_REQUEST, createNewDocument),
  takeLatest(DocumentTypes.GET_FOLDER_DETAILS_REQUEST, getFolderDetails),
  takeLatest(DocumentTypes.SEARCH_FOLDER_DETAILS_REQUEST, searchFolderDetails),
  takeLatest(
    DocumentTypes.SEARCH_MY_FOLDER_DETAILS_REQUEST,
    searchMyFolderDetails
  ),

  takeLatest(DocumentTypes.MOVE_DOCUMENT_REQUEST, moveDocument),
  takeLatest(DocumentTypes.DELETE_DOCUMENT_REQUEST, deleteDocument),
  takeLatest(DocumentTypes.GET_DOCUMENT_DETAILS_REQUEST, getDocumentDetails),
  takeLatest(
    DocumentTypes.GET_SHARED_DOCUMENT_DETAILS_REQUEST,
    getSharedDocumentDetails
  ),
  takeLatest(
    DocumentTypes.GET_ALL_REVISION_DOCUMENTS_REQUEST,
    getAllRevisionDocuments
  ),
  takeLatest(
    DocumentTypes.SEARCH_REVISION_DOCUMENTS_REQUEST,
    searchRevisionDocuments
  ),
  takeLatest(
    DocumentTypes.GET_REVISION_DOCUMENT_DETAILS_REQUEST,
    getRevisionDocumentDetails
  ),
  takeLatest(
    DocumentTypes.APPROVE_REVISION_DOCUMENT_REQUEST,
    approveRevisionDocument
  ),
  takeLatest(DocumentTypes.ASK_FOR_DOCUMENT_REVISION_REQUEST, askForRevision),
  takeLatest(DocumentTypes.GET_MY_DOCUMENTS_REQUEST, getMyDocuments),
  takeLatest(
    DocumentTypes.DOWNLOAD_LASTEST_DOCUMENT_REQUEST,
    downloadLastestDocument
  ),
  takeLatest(DocumentTypes.UPLOAD_DOCUMENT_REQUEST, uploadDocument),
  takeLatest(DocumentTypes.GET_DOCUMENTS_ENUM_REQUEST, getDocumentsEnum),
]);
