import styled from 'styled-components';
import { FiMoreVertical } from 'react-icons/fi';

export const Container = styled.div`
  height: 7.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem 3.2rem;
  background: #539ada;
  border-radius: 10px;
  margin: 1.6rem 0;
`;

export const DateText = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 0.4rem;
  line-height: 19px;
`;

export const Type = styled.div`
  font-size: 1.3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  line-height: 19px;
`;

export const MoreIcon = styled(FiMoreVertical).attrs({
  color: '#fff',
  size: 16,
})`
  cursor: pointer;
`;
