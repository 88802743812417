import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import { SecondarySelect } from '~/components/Select';

import OutlineButton from '~/components/Button/MainButtons/Outline';
import {
  Container,
  Header,
  Title,
  Label,
  ID,
  NoData,
  IconEdit,
} from './styles';

function Threats() {
  const types = ['Natural', 'Tecnológica'];
  const actives = ['Queda de Raio', 'Queimar equipamento'];

  const { riskDetails } = useSelector((state) => state.risks);

  return (
    <Container>
      <Header>
        <Title>
          {i18next.t('risks.riskDetails.associations.threats.title')}
        </Title>
        <OutlineButton
          onClick={() => {}}
          label={i18next.t('common.buttons.edit')}
          leftIcon={<IconEdit />}
        />
      </Header>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <Label>
            {i18next.t('risks.riskDetails.associations.threats.threatType')}
          </Label>
        </Grid>
        <Grid item xs={4}>
          <Label>
            {i18next.t('risks.riskDetails.associations.threats.threat')}
          </Label>
        </Grid>
        <Grid item xs={3} />
        {riskDetails?.threats && riskDetails?.threats.length > 0 ? (
          riskDetails.threats.map((item, index) => (
            <>
              <Grid key={item.id} item xs={1}>
                <ID>
                  {Number(index + 1).toLocaleString('pt-BR', {
                    minimumIntegerDigits: 2,
                  })}
                </ID>
              </Grid>
              <Grid item xs={4}>
                <SecondarySelect
                  options={types.map((type) => ({
                    label: type,
                    value: type,
                  }))}
                  value={item?.type}
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <SecondarySelect
                  options={actives.map((active) => ({
                    label: active,
                    value: active,
                  }))}
                  value={item?.active}
                  disabled
                />
              </Grid>
              <Grid item xs={3} />
            </>
          ))
        ) : (
          <NoData>{i18next.t('risks.risksTable.noData')}</NoData>
        )}
      </Grid>
    </Container>
  );
}

export default Threats;
