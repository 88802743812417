import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
// import { push } from 'connected-react-router';
import FileSaver from 'file-saver';
import convertFormData from '~/utils/convertFormData';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import ProcessActions, { ProcessTypes } from '../ducks/processes';

function* getProcesses({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/process/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(ProcessActions.getProcessesSuccess(response.data));
  } catch (error) {
    yield put(ProcessActions.getProcessesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* searchProcesses({ query }) {
  try {
    const response = yield call(api.get, `/processes?search=${query}`);

    yield put(ProcessActions.searchProcessesSuccess(response.data));
  } catch (error) {
    yield put(ProcessActions.searchProcessesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createProcess({
  data: {
    execution,
    contingency,
    // actives,
    threats,
    graphicSchema,
    sideBar,
    companyId,
  },
  handleGoBack,
}) {
  try {
    const formData = convertFormData({
      title: sideBar.title,
    });

    if (sideBar.primaryFunction)
      formData.append('primary_function', sideBar.primaryFunction);
    if (companyId) formData.append('company_id', companyId);

    formData.append('execution_plan', JSON.stringify(execution));
    formData.append('contingency', JSON.stringify(contingency));
    formData.append('threats', JSON.stringify(threats));

    // if (sideBar.responsible.length > 0)
    //   formData.append('responsible', JSON.stringify(sideBar.responsible));
    // if (sideBar.businessUnit)
    //   formData.append('business_unit_id', sideBar.businessUnit);

    // if (executionPlan.processNature)
    //   formData.append('nature', executionPlan.processNature);

    // formData.append('threats', JSON.stringify(threats));

    // if (actives.length > 0) formData.append('assets', JSON.stringify(actives));
    // if (detailsTab.maxTime)
    //   formData.append('max_stop_time', detailsTab.maxTime);
    // if (detailsTab.impact) formData.append('impact', detailsTab.impact);
    // if (detailsTab.description)
    //   formData.append('consequences', detailsTab.description);
    // if (executionPlan.processes.length > 0)
    //   formData.append(
    //     'stages',
    //     JSON.stringify(
    //       executionPlan.processes.map((process, index) => {
    //         return {
    //           order: index + 1,
    //           description: process.description,
    //           asset_id: process.asset_id,
    //           document_id: process.document_id,
    //         };
    //       })
    //     )
    //   );

    if (graphicSchema) formData.append('graphic_esqueme', graphicSchema);

    const response = yield call(api.post, '/api/process', formData);

    yield put(ProcessActions.createProcessSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Processo criado com sucesso!',
      })
    );

    handleGoBack(response.data);
  } catch (error) {
    yield put(ProcessActions.createProcessFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha na criação da unidade de negócio',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* updateProcess({
  data: {
    execution,
    contingency,
    // actives,
    threats,
    graphicSchema,
    sideBar,
    companyId,
  },
  id,
}) {
  try {
    const formData = convertFormData({
      title: sideBar.title,
    });

    if (sideBar.primaryFunction)
      formData.append('primary_function', sideBar.primaryFunction);
    if (companyId) formData.append('company_id', companyId);

    formData.append('execution_plan', JSON.stringify(execution));
    formData.append('contingency', JSON.stringify(contingency));
    formData.append('threats', JSON.stringify(threats));

    if (graphicSchema) formData.append('graphic_esqueme', graphicSchema);

    yield call(api.put, `/api/process/${id}`, formData);

    yield put(ProcessActions.updateProcessSuccess());

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Unidade de Negócio atualizada!',
      })
    );
  } catch (error) {
    yield put(ProcessActions.updateProcessFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro de validação!',
          message: translateError(
            `APIerrors.${error.response.data.validations.message}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Falha ao atualizar unidade de negócio',
          message: translateError(`APIerrors.${error.response.data.errorCode}`),
        })
      );
    }
  }
}

function* getProcessDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/process/details/${id}`);

    yield put(ProcessActions.getProcessDetailsSuccess(response.data));
  } catch (error) {
    yield put(ProcessActions.getProcessDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getProcessesEnum() {
  const { userActive } = yield select((state) => state.auth);

  try {
    const response = yield call(
      api.get,
      `/api/process/${userActive.activeCompany.id}`
    );
    const formatResponse = yield response.data.map((item) => ({
      value: item.id,
      label: item.title,
      nature: item.execution_plan[0].process_nature,
    }));

    yield put(ProcessActions.getProcessesEnumSuccess(formatResponse));
  } catch (error) {
    yield put(ProcessActions.getProcessesEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de processos',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* downloadProcesses() {
  try {
    const response = yield call(api.get, `/processes/download_xlsx`, {
      params: {
        process: ['title', 'createdAt'],
        responsible: ['name'],
      },
      responseType: 'blob',
    });

    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, 'Ativos-Processos.xlsx');

    yield put(ProcessActions.downloadProcessesSuccess());
  } catch (error) {
    yield put(ProcessActions.downloadProcessesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no download dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* uploadProcesses({ data }) {
  try {
    yield call(api.post, '/processes/upload_xlsx', data);
    yield put(ProcessActions.uploadProcessesSuccess());
  } catch (error) {
    yield put(ProcessActions.uploadProcessesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no upload dos dados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* deleteProcessDetails({ id }) {
  try {
    yield call(api.delete, `/api/process/${id}`);

    yield put(ProcessActions.deleteProcessSuccess(id));

    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Processo excluído!',
      })
    );
  } catch (error) {
    yield put(ProcessActions.deleteProcessFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao excluir unidade de negócio',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(ProcessTypes.GET_PROCESSES_REQUEST, getProcesses),
  takeLatest(ProcessTypes.SEARCH_PROCESSES_REQUEST, searchProcesses),
  takeLatest(ProcessTypes.CREATE_PROCESS_REQUEST, createProcess),
  takeLatest(ProcessTypes.UPDATE_PROCESS_REQUEST, updateProcess),
  takeLatest(ProcessTypes.DELETE_PROCESS_REQUEST, deleteProcessDetails),
  takeLatest(ProcessTypes.GET_PROCESS_DETAILS_REQUEST, getProcessDetails),
  takeLatest(ProcessTypes.GET_PROCESSES_ENUM_REQUEST, getProcessesEnum),
  takeLatest(ProcessTypes.DOWNLOAD_PROCESSES_REQUEST, downloadProcesses),
  takeLatest(ProcessTypes.UPLOAD_PROCESSES_REQUEST, uploadProcesses),
]);
