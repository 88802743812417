import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import DemandActions from '~/store/ducks/demand';

import { IconProfilePlaceholder } from '~/assets/icons';

import i18next from 'i18next';
import {
  Container,
  Title,
  MessageContainer,
  MessageTextWrapper,
  MessageText,
  UserAvatar,
  FileWrapper,
  File,
  DocumentIcon,
  DownloadIcon,
} from './styles';

export default function InteractionMessages() {
  const dispatch = useDispatch();
  const { demandInteractions } = useSelector((state) => state.demands);

  return (
    <Container>
      <Title>{i18next.t('callOpening.interactions.title')}</Title>
      {demandInteractions.count > 0 ? (
        demandInteractions.rows.map((reply) => (
          <MessageContainer
            key={reply.id}
            isResponsibleMsg={reply.responsible !== null}
          >
            {reply.responsible !== null ? (
              <span>
                <strong>{reply.responsible.name}</strong> -{' '}
                {format(new Date(reply.updatedAt), "P 'às' HH:mm", {
                  locale: ptBR,
                })}
              </span>
            ) : (
              <span>
                {format(new Date(reply.updatedAt), "P 'às' HH:mm", {
                  locale: ptBR,
                })}
              </span>
            )}
            <MessageTextWrapper>
              {reply.responsible !== null && (
                <UserAvatar
                  src={
                    reply?.responsible?.avatar
                      ? `${
                          process.env.NODE_ENV === 'development'
                            ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                            : process.env.REACT_APP_API_PROD_ENDPOINT
                        }/users/avatar?avatar=${reply.responsible.avatar}`
                      : IconProfilePlaceholder
                  }
                  alt={reply.responsible.name}
                />
              )}
              <MessageText isResponsibleMsg={reply.responsible !== null}>
                {reply.description}
                {reply?.ticketDocument && (
                  <FileWrapper>
                    <File
                      onClick={() =>
                        dispatch(
                          DemandActions.downloadDemandDocumentRequest(
                            reply?.ticketDocument?.title,
                            reply?.ticketDocument?.id,
                            reply.id
                          )
                        )
                      }
                    >
                      <DocumentIcon />
                      <span>{reply.ticketDocument.title}</span>
                      <DownloadIcon />
                    </File>
                  </FileWrapper>
                )}
              </MessageText>
            </MessageTextWrapper>
          </MessageContainer>
        ))
      ) : (
        <div />
      )}
    </Container>
  );
}
