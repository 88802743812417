import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
  padding: 4.5rem 3.8rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7.6rem;
`;
