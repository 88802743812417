import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDropzone } from 'react-dropzone';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import TextInput from '~/components/TextInput';
import Spinner from '~/components/Spinner';
import AuthActions from '~/store/ducks/auth';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import UserHeader from '~/components/UserHeader';
import { PhonInput } from '~/components/PhoneInput';
import schema from './validationSchema';

import {
  Container,
  InfoWrapper,
  Title,
  ImageIcon,
  Image,
  DropzoneAvatar,
  ButtonWrapper,
  Content,
  ConfigWrapper,
  Padding,
  CameraIcon,
  AreaUploadImage,
  AreaCameraIcon,
} from './styles';
import ImageCrop from '../components/ImageCrop';
import { SppinerIcon } from '../Company/CompanyData/styles';
import ModalDeleteAccount from './ModalDeleteAccount';

const User = () => {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const { isLoading, isLoadingUser, userActive } = useSelector(
    (state) => state.auth
  );

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const { user } = userActive;

  const baseImgUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return process.env.REACT_APP_API_HOMOLOG_ENDPOINT;
    }
    return process.env.REACT_APP_API_PROD_ENDPOINT;
  };

  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [nonCroppedImage, setNonCroppedImage] = useState(null);
  const [image, setImage] = useState(null);

  const handleCloseModal = () => {
    setCropModalOpen(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setCropModalOpen(true);

    if (acceptedFiles[0]) {
      setNonCroppedImage(URL.createObjectURL(acceptedFiles[0]));
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      accept: ['image/jpeg', 'image/jpg', 'image/png'],
    });

  const submit = (data) => {
    dispatch(AuthActions.updateUserDataRequest(user.id, image, data));
  };

  useEffect(() => {
    if (user?.avatar) {
      setImage(user?.avatar);
    }
  }, []);

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <UserHeader returnPage />
      <ConfigWrapper>
        <Title>{i18next.t('configurations.user.title')}</Title>
        <Content>
          <InfoWrapper>
            <Formik
              initialValues={{
                first_name: user?.first_name || '',
                last_name: user?.last_name || '',
                name: user?.first_name || '',
                phone: user?.phone || '',
                email: user?.email || '',
                // role: person?.office || '',
              }}
              onSubmit={submit}
              validationSchema={schema}
            >
              {({ values, touched, errors, handleChange, handleSubmit }) => (
                <>
                  <Padding>
                    <Grid item xs={12} sm={12} md container spacing={4}>
                      <Grid item xs={12}>
                        <DropzoneAvatar
                          {...getRootProps()}
                          isDragActive={isDragActive}
                          isDragReject={isDragReject}
                          hasImage={Boolean(image)}
                        >
                          <input {...getInputProps()} multiple={false} />
                          {image ? (
                            typeof image === 'string' ? (
                              <Image
                                src={`${baseImgUrl()}/public/image?image=${image}`}
                              />
                            ) : (
                              <Image src={URL.createObjectURL(image)} />
                            )
                          ) : (
                            <>
                              <AreaUploadImage>
                                <ImageIcon />
                                <AreaCameraIcon>
                                  <CameraIcon />
                                </AreaCameraIcon>
                              </AreaUploadImage>
                              {/* {isDragReject
                                ? i18next.t(
                                    'configurations.user.attachmentError'
                                  )
                                : isDragActive
                                ? i18next.t(
                                    'configurations.user.attachmentDrop'
                                  )
                                : i18next.t(
                                    'configurations.user.attachmentInsert'
                                  )} */}
                              {/* <Instructions>
                                {i18next.t(
                                  'configurations.user.attachmentDescription'
                                )}
                              </Instructions> */}
                            </>
                          )}
                        </DropzoneAvatar>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextInput
                          label={i18next.t('common.fields.name')}
                          placeholder="João Santos"
                          name="first_name"
                          value={values.first_name}
                          touched={touched.first_name}
                          error={errors.first_name}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextInput
                          label={i18next.t('common.fields.lastName')}
                          placeholder="Sobrenome"
                          name="last_name"
                          value={values.last_name}
                          touched={touched.last_name}
                          error={errors.last_name}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextInput
                          label={i18next.t('common.fields.email')}
                          placeholder="Ex.: meuemail@email.com.br"
                          name="email"
                          value={values.email}
                          touched={touched.email}
                          error={errors.email}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          editable={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <PhonInput
                          label={i18next.t('common.fields.phone')}
                          name="phone"
                          value={values.phone}
                          touched={touched.phone}
                          error={errors.phone}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6}>
                        <TextInput
                          label={i18next.t('common.fields.role')}
                          placeholder="Ex.: atendimento"
                          name="role"
                          value={values.role}
                          touched={touched.role}
                          error={errors.role}
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                        />
                      </Grid> */}
                    </Grid>
                  </Padding>

                  <ButtonWrapper>
                    <SecondaryButton
                      style={{ padding: 5 }}
                      width="22rem"
                      type="button"
                      label={i18next.t('common.buttons.deleteAccount')}
                      onClick={handleOpenDeleteModal}
                    />

                    <PrimaryButton
                      type="submit"
                      width="22rem"
                      label={
                        isLoadingUser ? (
                          <SppinerIcon />
                        ) : (
                          i18next.t('common.buttons.save')
                        )
                      }
                      onClick={handleSubmit}
                    />
                  </ButtonWrapper>
                </>
              )}
            </Formik>
          </InfoWrapper>
        </Content>
        <ImageCrop
          isOpen={cropModalOpen}
          onClose={handleCloseModal}
          title={i18next.t('cropModal.title')}
          nonCroppedImage={nonCroppedImage}
          setImage={setImage}
        />
      </ConfigWrapper>
      <ModalDeleteAccount open={deleteModal} onClose={handleCloseDeleteModal} />
    </Container>
  );
};

export default User;
