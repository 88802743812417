import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { SecondarySelect } from '~/components/Select';
import { useFormikContext } from 'formik';
import incrementDate from '~/utils/incrementDate';
import { Container, Title } from './styles';

const Impact = () => {
  const {
    values,
    //   touched, errors, handleBlur,
    // handleChange,
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (
      values.impact.maxTime?.time &&
      values.impact.maxTime?.typeOfTimeMeasurement
    ) {
      const registrationDate = new Date();
      setFieldValue(
        'impact.maxTime.registrationDate',
        registrationDate.getTime()
      );

      setFieldValue(
        'impact.maxTime.maximumDate',
        incrementDate(
          registrationDate,
          values.impact.maxTime?.time,
          values.impact.maxTime?.typeOfTimeMeasurement
        ).getTime()
      );
    }
  }, [
    values.impact.maxTime?.time,
    values.impact.maxTime?.typeOfTimeMeasurement,
  ]);

  return (
    <Container>
      <Title>{i18next.t('technologiesAsset.tabs.impact.title')}</Title>
      <Grid container spacing={4} style={{ marginBottom: 10 }}>
        <Grid item xs={3}>
          <TextInput
            margin={5}
            label={i18next.t('technologiesAsset.tabs.impact.maxTime')}
            value={values.impact.maxTime.time}
            type="number"
            min="1"
            onChange={(e) =>
              setFieldValue('impact.maxTime.time', e.target.value)
            }
          />
        </Grid>
        <Grid item xs={3}>
          <SecondarySelect
            label={i18next.t('technologiesAsset.tabs.impact.unitOfMeasurement')}
            placeholder="selecionar"
            name="impact"
            value={values.impact.maxTime?.typeOfTimeMeasurement}
            onChange={(e) =>
              setFieldValue('impact.maxTime.typeOfTimeMeasurement', e)
            }
            options={[
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.minutes')}`,
                value: 'minutes',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.hour')}`,
                value: 'hour',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.day')}`,
                value: 'day',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.week')}`,
                value: 'week',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.month')}`,
                value: 'month',
              },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <SecondarySelect
            label={i18next.t('technologiesAsset.tabs.impact.impactLabel')}
            placeholder="selecionar"
            name="impact"
            value={values.impact?.impact}
            onChange={(e) => setFieldValue('impact.impact', e)}
            options={[
              {
                label: `1 - ${i18next.t('common.impacts.negligible')}`,
                value: 1,
              },
              {
                label: `2 - ${i18next.t('common.impacts.little')}`,
                value: 2,
              },
              {
                label: `3 - ${i18next.t('common.impacts.medium')}`,
                value: 3,
              },
              {
                label: `4 - ${i18next.t('common.impacts.larger')}`,
                value: 4,
              },
              {
                label: `5 - ${i18next.t('common.impacts.extreme')}`,
                value: 5,
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <TextArea
          label={i18next.t('technologiesAsset.tabs.impact.consequencesLabel')}
          placeholder="Descrição aqui"
          name="description"
          value={values.impact?.description}
          onChange={(e) => setFieldValue('impact.description', e.target.value)}
        />
      </Grid>
    </Container>
  );
};

export default Impact;
