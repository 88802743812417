import React from 'react';
import { bool } from 'prop-types';

function DocumentsIcon({ active }) {
  return active ? (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8575 0.5H10.1432C12.46 0.5 13.75 1.835 13.75 4.1225V11.87C13.75 14.195 12.46 15.5 10.1432 15.5H3.8575C1.5775 15.5 0.25 14.195 0.25 11.87V4.1225C0.25 1.835 1.5775 0.5 3.8575 0.5ZM4.06 3.995V3.9875H6.30175C6.625 3.9875 6.8875 4.25 6.8875 4.57175C6.8875 4.9025 6.625 5.165 6.30175 5.165H4.06C3.73675 5.165 3.475 4.9025 3.475 4.58C3.475 4.2575 3.73675 3.995 4.06 3.995ZM4.06 8.555H9.94C10.2625 8.555 10.525 8.2925 10.525 7.97C10.525 7.6475 10.2625 7.38425 9.94 7.38425H4.06C3.73675 7.38425 3.475 7.6475 3.475 7.97C3.475 8.2925 3.73675 8.555 4.06 8.555ZM4.06 11.9825H9.94C10.2393 11.9525 10.465 11.6967 10.465 11.3975C10.465 11.09 10.2393 10.835 9.94 10.805H4.06C3.835 10.7825 3.6175 10.8875 3.4975 11.0825C3.3775 11.27 3.3775 11.5175 3.4975 11.7125C3.6175 11.9 3.835 12.0125 4.06 11.9825Z"
        fill="#4347A2"
      />
    </svg>
  ) : (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1035 15.5C3.7945 15.5 3.50125 15.476 3.22525 15.4288L3.06175 15.398C1.2835 15.0283 0.25 13.6663 0.25 11.5933V4.40675C0.25 4.15625 0.265 3.91625 0.295 3.68675C0.55675 1.673 1.9405 0.5 4.1035 0.5H9.89725C12.2943 0.5 13.7275 1.94 13.75 4.349V4.40675V11.5933C13.75 14.036 12.313 15.5 9.89725 15.5H4.1035ZM4.1035 1.59275C2.2615 1.59275 1.32775 2.54 1.32775 4.40675V11.5933C1.32775 13.46 2.2615 14.4073 4.1035 14.4073H9.8905C11.7317 14.4073 12.6595 13.46 12.6595 11.5933V4.40675C12.6595 2.54 11.7317 1.59275 9.8905 1.59275H4.1035ZM10.2295 11.1755C10.2295 10.8755 9.99025 10.6325 9.69475 10.6325H4.2865C3.991 10.6325 3.751 10.8755 3.751 11.1755C3.751 11.4755 3.991 11.7185 4.2865 11.7185H9.69475C9.99025 11.7185 10.2295 11.4755 10.2295 11.1755ZM9.69475 7.45325C9.901 7.43075 10.1027 7.52975 10.213 7.7075C10.3232 7.88675 10.3232 8.11325 10.213 8.2925C10.1027 8.47025 9.901 8.56925 9.69475 8.54675H4.2865C4.012 8.51525 3.80425 8.2805 3.80425 8C3.80425 7.7195 4.012 7.484 4.2865 7.45325H9.69475ZM6.83275 4.82825C6.83275 4.54775 6.625 4.31225 6.3505 4.2815H4.29325C4.018 4.31225 3.81025 4.54775 3.81025 4.82825C3.81025 5.108 4.018 5.3435 4.29325 5.37425H6.3505C6.625 5.3435 6.83275 5.108 6.83275 4.82825Z"
        fill="#606474"
      />
    </svg>
  );
}

export default DocumentsIcon;

DocumentsIcon.propTypes = {
  active: bool.isRequired,
};
