import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IconArrowDown, IconEye } from '~/assets/svgs';

export const Container = styled(motion.div)`
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  border-radius: 10px;
  height: 100%;
  flex: 1;

  padding: 2rem 2.5rem;

  :hover {
    transform: scale(1.01);
    box-shadow: 0 0 30px #00000004;
  }
  transition: 200ms ease;
`;

export const ContentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: #262a3a;
  margin-right: 1.5rem;
`;

export const WrapperSearch = styled.div`
  margin: 1.6rem 0;
`;

export const WrapperSession = styled(motion.div)`
  margin: 1.2rem 0;
`;

export const SessionHeader = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.4rem;
`;

export const SessionTitle = styled.div`
  color: #262a3a;
  font-size: 1.4rem;
  font-weight: 700;
`;

export const Arrow = styled(IconArrowDown)`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: 200ms ease;
`;

export const WrapperItem = styled(motion.div)`
  min-height: 7.6rem;
  background-color: #fafbfb;
  border-radius: 1rem;
  overflow: hidden;
  margin: 1.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1.4rem 2rem 1.4rem 4rem;
`;

export const ItemIndicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.8rem;
  background-color: #539ada;
`;

export const ItemColumn = styled.div`
  flex: 1;
`;

export const ItemTitle = styled.div`
  color: #606273;
  font-size: 1.4rem;
  font-weight: 700;
`;

export const ItemSubtitle = styled.div`
  color: #262a3a;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const WrapperIcon = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  background-color: #f7f7f7;
  ${({ theme }) => theme.clickable};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`;

export const EyeIcon = styled(IconEye)``;

export const NoData = styled.span`
  font-size: 1.3rem;
  color: #606273;
  padding-left: 0.5rem;
`;
