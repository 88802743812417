import styled from 'styled-components';
import { IoIosArrowBack, IoMdMenu } from 'react-icons/io';
import { IconLogout } from '~/assets/svgs/index';

export const Container = styled.div`
  background: linear-gradient(#fafafc 80%, #ffffff00);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3.8rem 3.5%;
  z-index: 100;
  display: flex;
  align-items: center;

  @media (min-width: 1280px) {
    margin-left: ${({ noDrawer }) => (noDrawer ? 0 : 25.7)}rem;
  }

  transition: 300ms ease;
`;

export const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

export const BackIcon = styled(IoIosArrowBack).attrs({
  size: '1.4rem',
  color: '#200E32',
})`
  margin-right: 2rem;
`;

export const BackText = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.8rem;
  font-weight: 400;
`;

export const WrapperMenu = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconMenu = styled.div`
  cursor: pointer;
  width: 2rem;
`;

export const Hamburger = styled(IoMdMenu).attrs(({ theme }) => {
  return {
    color: theme.colors.blue,
    size: 26,
  };
})``;

export const PCSLogo = styled.img``;

export const LogOutButton = styled.button`
  background: ${({ theme }) => theme.colors.blueSecondary};
  color: white;
  border: none;
  margin-left: 2rem;
  display: flex;
  align-items: center;
  height: 4rem;
  width: 8rem;
  border-radius: 8px;
  padding-left: 0.8rem;
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.blue};
  }
`;

export const LogOutText = styled.span`
  margin-left: 0.8rem;
`;

export const LogOutIcon = styled(IconLogout).attrs(() => {
  return {
    size: 24,
  };
})``;

export const ClientMenuWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
`;

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: ${({ backButton }) => (backButton ? 'auto' : '95%')};

  @media (min-width: 1280px) {
    width: ${({ backButton }) => (backButton ? '87%' : '100%')};
  }

  @media (max-width: 670px) {
    width: '100%';
  }
`;
