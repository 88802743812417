import { css } from 'highcharts';
import styled from 'styled-components';

export const Container = styled.div`
  height: ${(props) => (props.error ? 8.5 : 6) + (props.label ? 3 : 0)}rem;
  width: 100%;
  margin: ${(props) => props.margin};
  transition: all 300ms ease;
`;

export const Label = styled.div`
  font-size: ${({ sm }) => (sm ? 1.2 : 1.4)}rem;
  color: ${({ theme, sm }) =>
    sm ? theme.colors.base[400] : theme.colors.base[500]};
  line-height: 1.4rem;
  font-weight: ${({ sm }) => (sm ? 500 : 400)};
  padding-bottom: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: ' *';
        color: red;
      }
    `}
`;

export const Error = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.text.sm};
  line-height: ${({ theme }) => theme.fontSizes.text.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: red;
  margin-top: 0.5rem;
  opacity: ${(props) => (props.error ? 1 : 0)};
  transform: ${(props) => (props.error ? 'scale(1)' : 'scale(0.9)')};
  text-align: left;
  transition: all 300ms ease 100ms;
`;
