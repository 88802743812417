import * as Yup from 'yup';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import i18next from 'i18next';

export default Yup.object().shape({
  name: Yup.string().required(i18next.t('common.validation.required')),
  email: Yup.string()
    .email(i18next.t('common.validation.email'))
    .required(i18next.t('common.validation.required')),
  phone: Yup.string()
    .required(i18next.t('common.validation.required'))
    .min(14, i18next.t('common.validation.invalidNumber')),
  document: Yup.string()
    .required(i18next.t('common.validation.required'))
    .test('isCNPJ', i18next.t('common.validation.cnpj'), (document) =>
      isValidCnpj(document)
    ),
  fantasy_name: Yup.string().required(i18next.t('common.validation.required')),
  password: Yup.string()
    .min(8, i18next.t('common.validation.minPassword'))
    .required(i18next.t('common.validation.required')),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      i18next.t('common.validation.matchPassword')
    )
    .required(i18next.t('common.validation.required')),
});
