import React from 'react';
import Popover from '@material-ui/core/Popover';
import { objectOf, oneOfType, any, func } from 'prop-types';

import history from '~/routes/history';

import {
  Container,
  MoreIcon,
  Row,
  WrapperIcon,
  EyeIcon,
  CloseIcon,
  DemandIcon,
} from './styles';

function ActionButton({ item, setOpenModal, setSelectedHolder }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModel = () => {
    setSelectedHolder(item);
    setOpenModal(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <Container>
        <MoreIcon onClick={handleClick} />
      </Container>
      <Popover
        id={id}
        PaperProps={{ style: { width: '16rem', paddingVertical: '0.8rem' } }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Row onClick={() => handleOpenModel()}>
          <WrapperIcon>
            <EyeIcon />
          </WrapperIcon>
          Visualizar
        </Row>
        <Row onClick={() => history.push(`/holders/demands/${item.id}`)}>
          <WrapperIcon>
            <DemandIcon />
          </WrapperIcon>
          Demandas
        </Row>
        <Row onClick={() => console.log(`hide ${item.id}`)}>
          <WrapperIcon>
            <CloseIcon />
          </WrapperIcon>
          Ocultar
        </Row>
      </Popover>
    </>
  );
}

export default ActionButton;

ActionButton.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
  setOpenModal: func.isRequired,
  setSelectedHolder: func.isRequired,
};
