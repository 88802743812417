import * as Yup from 'yup';

export default Yup.object().shape({
  contingencyAssets: Yup.object().shape({
    actives: Yup.array().of(
      Yup.object().shape({
        contingency_id: Yup.number().required('Contingencia é obrigatória'),
        operation_time: Yup.object().shape({
          typeOfTimeMeasurement: Yup.string().required(
            'Unidade de tempo é obrigatório'
          ),
        }),
      })
    ),
  }),
});
