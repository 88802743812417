import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
// import DashboardActions from '~/store/ducks/dashboard';

import Spinner from '~/components/Spinner';

import i18next from 'i18next';
import TermsModal from '~/components/Modal';
import { useHistory } from 'react-router-dom';
// import ConfigActions from '~/store/ducks/config';
import { PrimaryButton, SecondaryButton } from '~/components/Button';
import Greetings from './components/Greetings';
import NumberOfTickets from './components/NumberOfTickets';
import NeedRevision from './components/NeedRevision';
import MainRisks from './components/MainRisks';
import UpcomingReviews from './components/UpcomingReviews';

import { Container, Hr, ModalContent, Footer } from './styles';

export default function Dashboard() {
  // const dispatch = useDispatch();
  const history = useHistory();
  const { userActive } = useSelector((state) => state.auth);
  const [welcomeModalOpen, setWelcomeModalOpen] = useState(false);
  const {
    isLoading,
    //  companyData
  } = useSelector((state) => state.config);

  useEffect(() => {
    if (!userActive.activeCompany) {
      history.push('/panel');
    }
  }, []);

  // useEffect(() => {
  //   dispatch(ConfigActions.getCompanyDataRequest());
  // }, []);

  // useEffect(() => {
  //   if (!companyData?.legal_responsible?.cpf || !companyData?.cnpj) {
  //     setWelcomeModalOpen(true);
  //   } else {
  //     setWelcomeModalOpen(false);
  //   }
  // }, [companyData]);

  const handleConfigCompanyProfile = () => {
    history.push('/configs/company');
  };

  // useEffect(() => {
  //   if (userActive?.user?.role === 'company') {
  //     dispatch(DashboardActions.getMainRisksRequest());
  //     dispatch(DashboardActions.getNextRevisionsRequest());
  //     dispatch(DashboardActions.getPendingRevisionRequest());
  //   }

  //   dispatch(DashboardActions.getTotalTicketsCompletedRequest());
  // }, [companyId, userActive]);

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }
  return (
    <Container>
      <Grid container spacing={6}>
        <Greetings lg={12} md={12} xs={12} />
        <NumberOfTickets lg={8} md={8} xs={12} />
        <NeedRevision lg={4} md={4} xs={12} />
        <MainRisks lg={8} md={8} xs={12} />
        <UpcomingReviews lg={4} md={4} xs={12} />
      </Grid>
      <TermsModal
        title={`${i18next.t('welcomeModal.titleLabel')}, ${
          userActive?.user?.first_name
        }!`}
        noCloseText
        onClose={() => setWelcomeModalOpen(false)}
        open={welcomeModalOpen}
      >
        <ModalContent>
          <Hr />
          <p>{i18next.t('welcomeModal.question')}</p>
          <Footer>
            <SecondaryButton
              padding="1.18rem 0.93rem"
              label={i18next.t('welcomeModal.skip')}
              width="13.3rem"
              onClick={() => setWelcomeModalOpen(false)}
            />
            <PrimaryButton
              padding="1.18rem 0.93rem"
              label={i18next.t('welcomeModal.confimLabel')}
              width="13.3rem"
              onClick={handleConfigCompanyProfile}
            />
          </Footer>
        </ModalContent>
      </TermsModal>
    </Container>
    //  <BigFreePlanWarningModal
    //   onClose={() => handleFreePlanWarningModalClose()}
    //   open={isPlanWarningOpen}
    //   name={userActive?.user?.name}
    //   days={14}
    // />
  );
}
