import React, { useState } from 'react';
// import Spinner from '~/components/Spinner';
import i18next from 'i18next';

import { Container, GoBackWrapper, Title } from './styles';
import IventoryList from './IventoryList';
import IventoryData from './IventoryData';

const IventoryRecords = () => {
  const [newIventory, setNewIventory] = useState(false);
  const [editableIventory, setEditableIventory] = useState();

  const handleSwitchNewIventory = () => {
    setEditableIventory();
    setNewIventory(!newIventory);
  };

  return (
    <Container>
      <GoBackWrapper show={!newIventory} onClick={handleSwitchNewIventory}>
        <span>{'<'}</span>
        <span>{i18next.t('common.buttons.goBack')}</span>
      </GoBackWrapper>
      <Title>
        {!newIventory
          ? i18next.t('iventory.title')
          : editableIventory
          ? i18next.t('iventory.tab.titleEdit')
          : i18next.t('iventory.tab.title')}
      </Title>
      {!newIventory ? (
        <IventoryList
          setEditableIventory={setEditableIventory}
          handleSwitchNewIventory={handleSwitchNewIventory}
        />
      ) : (
        <IventoryData
          editIventory={editableIventory}
          onBack={handleSwitchNewIventory}
        />
      )}
    </Container>
  );
};

export default IventoryRecords;
