import React from 'react';
import { string, func, oneOfType, number, bool, any } from 'prop-types';

import { Container } from './styles';

function SecondaryButton({
  label,
  onClick,
  margin,
  padding,
  width,
  style,
  small,
}) {
  return (
    <Container
      onClick={onClick}
      margin={margin}
      padding={padding}
      width={width}
      small={small}
      style={style}
    >
      {label}
    </Container>
  );
}

SecondaryButton.propTypes = {
  label: string,
  onClick: func,
  margin: oneOfType([string, number]),
  padding: string,
  width: string,
  small: bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: any,
};

SecondaryButton.defaultProps = {
  label: '',
  onClick: () => {},
  margin: '0.2rem 0',
  padding: '0 9rem',
  width: '100%',
  small: false,
  style: any,
};

export default SecondaryButton;
