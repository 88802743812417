import styled from 'styled-components';
import { IconDownload } from '~/assets/svgs';

export const Container = styled.div``;

export const Title = styled.span`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
`;

export const HeaderCard = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 3rem;
`;

export const AreaSubtitle = styled.div`
  width: auto;
  height: 30px;
  display: flex;
  justify-content: space-between;
`;

export const DownloadButton = styled.div`
  width: 12rem;
  height: 40px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.clickable};
`;

export const DownloadIcon = styled(IconDownload)`
  fill: ${({ theme }) => theme.colors.blueSecondary};
`;

export const DownloadText = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #606474;
  margin-left: 1rem;
`;
