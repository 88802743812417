import styled from 'styled-components';
import { IoHelpCircleSharp } from 'react-icons/io5';

export const Container = styled.div`
  width: 20px;
  height: 20px;
  background: white;
  transition: 300ms ease;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  display: flex;
  z-index: 99999;
  margin-left: 2px;
  padding-left: 1px;

  span {
    display: none;
    transition: 300ms ease;
    color: #262a3a;
    align-items: center;
    justify-content: center;
  }

  :hover {
    width: auto;
    padding: 25px;
    background: #f6f6f6;
    margin-left: 40px;
    margin-bottom: 60px;
    height: auto;
    position: absolute;
    box-shadow: rgba(149, 157, 165, 0.7) 0px 24px 24px;
    span {
      font-size: 12px;
      display: inline;
    }
  }
`;

export const InfoIcon = styled(IoHelpCircleSharp).attrs({
  size: 18,
  color: '#539ada',
})`
  cursor: pointer;
  margin-right: 2px;
`;
