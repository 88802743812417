import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  background: ${({ status }) =>
    status === 'open' ? '#E8F1F8' : 'rgba(209, 232, 218, 1)'};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 2.5rem;
  padding: 0 1rem;
  min-width: 7.7rem;

  font-size: 1.2rem;
  color: ${({ status }) => (status === 'open' ? '#0E067D' : '#038A00')};

  font-weight: 400;
`;
