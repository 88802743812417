import i18next from 'i18next';

export default function convertRisk(probability, impact) {
  switch (true) {
    case probability === 'very_low' && impact === 'negligible':
    case probability === 'very_low' && impact === 'little':
    case probability === 'low' && impact === 'negligible':
      return i18next.t('common.risks.insignificant');

    case probability === 'very_low' && impact === 'medium':
    case probability === 'very_low' && impact === 'larger':
    case probability === 'low' && impact === 'little':
    case probability === 'low' && impact === 'medium':
    case probability === 'medium' && impact === 'negligible':
    case probability === 'medium' && impact === 'little':
    case probability === 'high' && impact === 'negligible':
      return i18next.t('common.risks.possible');

    case probability === 'very_low' && impact === 'extreme':
    case probability === 'low' && impact === 'larger':
    case probability === 'low' && impact === 'extreme':
    case probability === 'medium' && impact === 'medium':
    case probability === 'medium' && impact === 'larger':
    case probability === 'high' && impact === 'little':
    case probability === 'high' && impact === 'medium':
    case probability === 'very_high' && impact === 'negligible':
    case probability === 'very_high' && impact === 'little':
      return i18next.t('common.risks.moderate');

    case probability === 'medium' && impact === 'extreme':
    case probability === 'high' && impact === 'larger':
    case probability === 'very_high' && impact === 'medium':
      return i18next.t('common.risks.high');

    case probability === 'high' && impact === 'extreme':
    case probability === 'very_high' && impact === 'larger':
    case probability === 'very_high' && impact === 'extreme':
      return i18next.t('common.risks.catastrophic');

    default:
      return '';
  }
}
