import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: ${({ theme }) => theme.colors.cardBackground};

  max-height: 24rem;
  width: 15rem;
  border-radius: 0.6rem;
  padding-top: 1rem;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
  padding-bottom: 1.5rem;
`;

export const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  height: 104px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 7rem;
  height: 7rem;
`;

export const CompanyName = styled.p`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.text.md};
  font-weight: ${({ theme }) => theme.fontWeight[600]};

  color: ${({ theme }) => theme.colors.cardText};

  margin-top: 2px;
  margin-bottom: 7px;
`;
