import styled from 'styled-components';

import { ForgotPasswordBG, LogoIcon } from '~/assets/svgs/index';

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    justify-content: center;
  }

  @media (min-width: 600px) {
    margin-left: 12rem;
    margin-right: 12rem;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  flex-wrap: wrap;
`;

export const BG = styled(ForgotPasswordBG)`
  position: absolute;
  z-index: 0;

  width: 100%;
  height: 90vh;

  opacity: 0.2;
`;

export const Logo = styled(LogoIcon)`
  margin-bottom: 5rem;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  height: 15rem;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: auto;
  z-index: 2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    justify-content: space-between;
  }

  margin-top: 7.5rem;
`;

export const TitleWrapper = styled.div`
  @media (min-width: 600px) {
    flex: 1 1 30%;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.blue};

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 7.5rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: 600px) {
    display: none;
  }
`;

export const TitleModal = styled.span`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
`;

export const BoxContainer = styled.div`
  width: 460px;
  padding: 40px;
  height: 332px;
  background: #ffff;
  border-radius: 20px;
  box-shadow: 0px 4px 40px 4px rgba(0, 0, 0, 0.1);
  display: grid;
`;

export const FormWrapper = styled.div`
  flex: 0 1 40%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const ForgotPasswordText = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.blue};

  margin-top: 4rem;
`;

export const ForgotPasswordInstruction = styled.p`
  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-size: 1.4rem;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 2rem;

  margin-bottom: 4.5rem;
  margin-top: 1rem;
`;
