import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding-left: 2%;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  margin-bottom: 2rem;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 500;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const WrapperSelect = styled.div`
  width: 16rem;
  height: 5rem;
  margin-right: 1.6rem;
  display: flex;
`;

export const WrapperSelect2 = styled(WrapperSelect)`
  width: 12rem;
  margin-right: 0;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperForm = styled.div`
  display: flex;
  /* width: 70rem; */
  padding: 21px 32px 100px 32px;
  background: #ffffff;
  border-radius: 10px;
`;

export const WrapperCard = styled.div`
  height: ${({ height }) => height || '30rem'};
  margin-bottom: 5rem;
`;

export const DeadlinePlaceholderWrapper = styled.div`
  width: 100%;
  border: 1px solid rgba(198, 204, 210, 0.3);
  background: white;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  border-radius: 8px;
`;

export const DeadlinePlaceholder = styled.span`
  font-size: 1.4rem;
  color: #606474;
  font-weight: 400;
  line-height: 2.1rem;
`;
