import React from 'react';
import Grid from '@material-ui/core/Grid';
import { func, objectOf } from 'prop-types';
import i18next from 'i18next';

import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
import { SecondarySelect } from '~/components/Select';

import { Container, Title } from './styles';

const Location = ({ detailsTab, setDetailsTab }) => {
  return (
    <Container>
      <Title>{i18next.t('processAsset.tabs.contingencyDetails.label')}</Title>
      <Grid container spacing={4} style={{ marginBottom: '10rem' }}>
        <Grid item xs={3}>
          <TextInput
            margin={5}
            label={i18next.t(
              'processAsset.tabs.contingencyDetails.maxTimeLabel'
            )}
            value={detailsTab.maxTime.max_time_without_operating}
            type="number"
            min="1"
            onChange={(e) =>
              setDetailsTab({
                ...detailsTab,
                maxTime: {
                  ...detailsTab.maxTime,
                  max_time_without_operating: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={3}>
          <SecondarySelect
            label={i18next.t('technologiesAsset.tabs.impact.unitOfMeasurement')}
            placeholder="selecionar"
            name="impact"
            value={detailsTab.maxTime.timetypeOfTimeMeasurement}
            onChange={(e) =>
              setDetailsTab({
                ...detailsTab,
                maxTime: {
                  ...detailsTab.maxTime,
                  timetypeOfTimeMeasurement: e,
                },
              })
            }
            options={[
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.minutes')}`,
                value: 'minutes',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.hour')}`,
                value: 'hour',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.day')}`,
                value: 'day',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.week')}`,
                value: 'week',
              },
              {
                label: `${i18next.t('common.typeOfTimeMeasurement.month')}`,
                value: 'month',
              },
            ]}
          />
        </Grid>
        <Grid item xs={3}>
          <SecondarySelect
            label={i18next.t(
              'processAsset.tabs.contingencyDetails.impactLabel'
            )}
            placeholder="selecionar"
            name="impact"
            value={detailsTab.impact}
            onChange={(e) => setDetailsTab({ ...detailsTab, impact: e })}
            options={[
              {
                label: `1 - ${i18next.t('common.impacts.negligible')}`,
                value: 'negligible',
              },
              {
                label: `2 - ${i18next.t('common.impacts.little')}`,
                value: 'little',
              },
              {
                label: `3 - ${i18next.t('common.impacts.medium')}`,
                value: 'medium',
              },
              {
                label: `4 - ${i18next.t('common.impacts.larger')}`,
                value: 'larger',
              },
              {
                label: `5 - ${i18next.t('common.impacts.extreme')}`,
                value: 'extreme',
              },
            ]}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={12}>
          <TextArea
            label={i18next.t(
              'processAsset.tabs.contingencyDetails.consequencesLabel'
            )}
            placeholder="Descrição aqui"
            name="consequences"
            style={{ zIndex: 1 }}
            value={detailsTab.consequences}
            onChange={(e) =>
              setDetailsTab({ ...detailsTab, consequences: e.target.value })
            }
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Location;

Location.propTypes = {
  detailsTab: objectOf.isRequired,
  setDetailsTab: func.isRequired,
};
