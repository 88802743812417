import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IconArrowDown } from '~/assets/svgs';

export const Container = styled(motion.div).attrs({ layout: true })`
  width: 80vw;
  max-width: 82rem;
  padding: 4.5rem 0 0;
  display: flex;
  flex-direction: column;
`;

export const Title = styled(motion.div).attrs({ layout: true })`
  color: #262a3a;
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 6.2rem;
`;

export const WrapperSearch = styled(motion.div).attrs({ layout: true })`
  margin-bottom: 5rem;
`;

export const WrapperSession = styled(motion.div)`
  margin: 1.2rem 0;
`;

export const SessionHeader = styled(motion.div)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.4rem;
`;

export const SessionTitle = styled.div`
  color: #262a3a;
  font-size: 1.4rem;
  font-weight: 700;
`;

export const Arrow = styled(IconArrowDown)`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  transition: 200ms ease;
`;

export const WrapperItem = styled(motion.div)`
  min-height: 7.6rem;
  background-color: #fafbfb;
  border-radius: 1rem;
  overflow: hidden;
  margin: 1.2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 1.4rem 2rem 1.4rem 4rem;
`;

export const ItemIndicator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.8rem;
  background-color: #539ada;
`;

export const ItemColumn = styled.div`
  flex: 1;
`;

export const ItemTitle = styled.div`
  color: #606273;
  font-size: 1.4rem;
  font-weight: 700;
`;

export const ItemSubtitle = styled.div`
  color: #262a3a;
  font-size: 1.4rem;
  font-weight: 400;
`;

export const WrapperCheck = styled.div`
  width: 3.1rem;
  height: 3.1rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: 0.5rem solid #c6ccd2;
  ${({ theme }) => theme.clickable};
  margin-right: 5.3rem;
  :active {
    transform: scale(0.98);
  }
`;

export const CheckIndicator = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0e067d;
  opacity: ${({ selected }) => (selected ? 1 : 0)};
  transition: 100ms ease;
`;

export const WrapperBottomButtons = styled(motion.div)`
  margin-top: 7.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
