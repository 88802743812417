import { all } from 'redux-saga/effects';

import Auth from './auth';
import Holders from './holders';
import iventory from './iventory';
import Documents from './documents';
import Tickets from './tickets';
import People from './people';
import Demand from './demand';
import Dashboard from './dashboard';
import BusinessUnits from './businessUnits';
import Processes from './processes';
import Technologies from './technologies';
import Risks from './risks';
import Config from './config';
import Provider from './provider';

export default function* rootSaga() {
  yield all([
    Auth,
    Holders,
    iventory,
    Documents,
    Tickets,
    People,
    Demand,
    Dashboard,
    BusinessUnits,
    Processes,
    Technologies,
    Risks,
    Config,
    Provider,
  ]);
}
