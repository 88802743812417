import React from 'react';
import { element, func, bool, string } from 'prop-types';
import { Modal } from '@material-ui/core';
import i18next from 'i18next';

import {
  Container,
  Card,
  MotionCard,
  Header,
  CloseButton,
  CloseIcon,
  BigCloseIcon,
  WrapperContent,
  ButtonWrapper,
  Button,
} from './styles';

function ModalComp({
  children,
  checkboxSelected,
  open,
  noCloseButton,
  onClose,
  title,
  noPadding,
  motion,
}) {
  const CardComp = motion ? MotionCard : Card;
  return (
    <Modal open={open}>
      <Container open={open}>
        <CardComp>
          {(!noCloseButton || title) && (
            <Header position={title ? 'space-between' : 'flex-end'}>
              {title}
              {!noCloseButton && (
                <CloseButton onClick={onClose}>
                  <CloseIcon />
                  {/* {i18next.t('common.buttons.close')} */}
                </CloseButton>
              )}
              <BigCloseIcon onClick={onClose} />
            </Header>
          )}
          <WrapperContent noPadding={noPadding}>{children}</WrapperContent>
          <ButtonWrapper>
            <Button checkboxSelected={checkboxSelected} onClick={onClose}>
              OK
            </Button>
          </ButtonWrapper>
        </CardComp>
      </Container>
    </Modal>
  );
}

export default ModalComp;

ModalComp.propTypes = {
  children: element.isRequired,
  checkboxSelected: bool,
  noCloseButton: bool,
  onClose: func,
  open: bool,
  title: string,
  noPadding: bool,
  motion: bool,
};

ModalComp.defaultProps = {
  onClose: () => {},
  checkboxSelected: false,
  noCloseButton: false,
  open: false,
  title: '',
  noPadding: false,
  motion: false,
};
