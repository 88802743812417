import React from 'react';

import i18next from 'i18next';
// import { useSocket } from '~/hooks/socket';
import ChatItem from '../ChatItem';
// import SearchInput from '~/components/TextInput/Search';

import { Container, Row, Title, Scroll } from './styles';

function ChatList() {
  // const { attendances } = useSocket();

  return (
    <Container>
      <Row>
        <Title>{i18next.t('demandManagement.chat.collaborators')}</Title>
      </Row>
      <Row width="100%" />
      {/* <Scroll>
        {attendances && attendances.length > 0 ? (
          attendances.map((item) => <ChatItem key={item.id} item={item} />)
        ) : (
          <div />
        )}
      </Scroll> */}
    </Container>
  );
}

export default ChatList;
