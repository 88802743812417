import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { motion } from 'framer-motion';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { LogoIcon } from '~/assets/svgs';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: space-between;
  background-color: #fafafc;
`;

export const WrapperContent = styled.div`
  flex: 1;
  padding: 3.8rem 3.5%;
  position: relative;
  padding-top: 0;

  @media (max-width: 728px) {
    padding-top: 8rem;
  }

  transition: 300ms ease;
`;

export const PaddingTop = styled.div`
  padding-top: 14rem;
`;

export const Drawer = styled.div`
  height: 100vh;
  width: 100vw;
  max-width: 25.7rem;
  background-color: #fff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.04);
  display: flex;
  position: fixed;
  z-index: 200;
  line-height: 41px;

  @media (max-width: 1280px) {
    transform: ${({ drawerOpened }) =>
      drawerOpened ? 'translateX(0)' : 'translateX(-25.7rem)'};
  }

  ${({ noDrawer }) =>
    noDrawer &&
    css`
      transform: translateX(-25.7rem)};
    `}

  transition: 300ms ease;
`;

export const Scroll = styled(PerfectScrollbar)`
  flex: 1;
  padding: 20% 5% 20% 10%;
  position: relative;
`;

export const LogoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled(LogoIcon)`
  width: 13rem;
  object-fit: contain;
`;

export const ContainerItem = styled.div`
  position: relative;
  margin: 2.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  cursor: pointer;
  position: relative;

  :hover {
    transform: scale(1.05) translateX(3%);
  }

  transition: 200ms ease;

  p {
    font-size: 1.2rem;
    color: ${({ theme, active }) =>
      active ? theme.colors.blue : theme.colors.base[500]};
    font-weight: 400;
    margin-left: 1.5rem;
    ${({ theme }) => theme.notSelectable};
  }
`;

export const Indicator = styled(motion.div)`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.blue};
  width: 0.5rem;
  height: 3.1rem;
  right: -6%;
  border-radius: 0.25rem;
`;

export const Arrow = styled(BiLeftArrowAlt).attrs(({ theme }) => ({
  color: theme.colors.blue,
  size: 26,
}))``;
