import React from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import DefaultProcess from './Items/DefaultProcess';
import ContingencyActivationProcess from './Items/ContingencyActivationProcess';
import OperationalReturnProcess from './Items/OperationalReturnProcess';

import { Container } from './styles';

const PCN = () => {
  return (
    <AnimateSharedLayout>
      <Container layout>
        <DefaultProcess />
        <ContingencyActivationProcess />
        <OperationalReturnProcess />
      </Container>
    </AnimateSharedLayout>
  );
};

export default PCN;
