/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';

import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';
import { formatters } from '~/utils';
import PeopleActions from '~/store/ducks/people';

import Table from '~/components/Table/Secondary';
import Card from '~/components/Card';
import Spinner from '~/components/Spinner';
import { TertiaryButton } from '~/components/Button';
import { IconProfilePlaceholder } from '~/assets/icons';
import SearchInput from '~/components/TextInput/Search';
import ActionButton from './components/ActionButton';

import EditModal from './components/EditModal';
import CreateUserModal from '../../../components/CreateUserModal';

import {
  Container,
  Header,
  Title,
  HeaderTitleWrapper,
  HeaderActions,
  SearchWrapper,
  IconPlus,
  UserImage,
} from './styles';

const Users = () => {
  const dispatch = useDispatch();
  const { people, isLoading } = useSelector((state) => state.people);
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);

  const [openEditModal, setOpenEditModal] = useState(false);

  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const [csvData, setCsvData] = useState([]);
  const [userId, setUserId] = useState();

  useEffect(() => {
    dispatch(PeopleActions.getPeopleRequest(String(currentPage), searchValue));
  }, [currentPage, searchValue]);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
  };

  function validation(str, pattern) {
    switch (pattern) {
      case 'email':
        const rgxEmail =
          /^(\S+)@((?:(?:(?!-)[a-zA-Z0-9-]{1,62}[a-zA-Z0-9])\.)+[a-zA-Z0-9]{2,12})$/;
        return rgxEmail.test(str);
      case 'phone':
        const rgxPhone = /(\((\d{2})\)\s?)?(\d{4,5})[-]?(\d{4})/gm;
        return rgxPhone.test(str);
      case 'cpf':
        const rgxCpf = /(\d{3})[.]?(\d{3})[.]?(\d{3})[-]?(\d{2})/gm;
        return rgxCpf.test(str);
      case 'name':
        const rgxName = /[a-zA-Z]/;
        return rgxName.test(str);
      default:
        return '';
    }
  }

  const validationDataPeople = (data) => {
    const peoples = data.filter((item) => {
      const name = !!validation(item.name, 'name');
      const email = !!validation(item.email, 'email');
      const phone = !!validation(item.phone, 'phone');
      const cpf = !!validation(item.cpf, 'cpf');

      if (name && email && phone && cpf) {
        return {
          cpf: item.cpf,
          email: item.email,
          name: item.name,
          phone: item.phone,
        };
      }
      return {};
    });

    dispatch(PeopleActions.uploadPeopleRequest(peoples));
  };

  useEffect(() => {
    if (csvData.length > 0) {
      validationDataPeople(csvData);
    }
  }, [csvData]);

  return (
    <>
      <Spinner loading={isLoading} />
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header>
              <HeaderTitleWrapper>
                <Title>{i18next.t('peopleAsset.title')}</Title>
                <TertiaryButton
                  onClick={() => setOpenCreateUserModal(true)}
                  label={i18next.t('common.buttons.new')}
                  leftIcon={<IconPlus />}
                />
              </HeaderTitleWrapper>
              <HeaderActions>
                <SearchWrapper>
                  <SearchInput
                    inputRef={searchInputRef}
                    placeholder={i18next.t('common.search')}
                    value={searchValue}
                    onChange={setSearchValue}
                    delay={1500}
                    hasDebounce
                  />
                </SearchWrapper>
              </HeaderActions>
            </Header>
          </Grid>
          <Grid item xs={12}>
            <Card padding="2rem 0">
              <Table
                columns={[
                  {
                    Header: '',
                    accessor: 'person_avatar[0].url',
                    Cell: (e) => {
                      return (
                        <UserImage
                          src={
                            e.value
                              ? `${
                                  process.env.NODE_ENV === 'development'
                                    ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                                    : process.env.REACT_APP_API_PROD_ENDPOINT
                                }/public/image?image=${e.value}`
                              : IconProfilePlaceholder
                          }
                        />
                      );
                    },
                  },
                  {
                    Header: i18next.t('peopleAsset.table.name'),
                    accessor: 'first_name',
                    Cell: (e) => {
                      if (!e.value) {
                        return '-';
                      }
                      return e.value;
                    },
                  },

                  {
                    Header: i18next.t('peopleAsset.table.email'),
                    accessor: 'email',
                    Cell: (e) => {
                      if (!e.value) {
                        return '-';
                      }
                      return e.value;
                    },
                  },
                  {
                    Header: i18next.t('peopleAsset.table.phone'),
                    accessor: 'phone',
                    Cell: (e) => {
                      if (!e.value) {
                        return '-';
                      }
                      return formatters.toPhone(e.value);
                    },
                  },
                  {
                    Header: i18next.t('peopleAsset.table.function'),
                    accessor: 'Role.type',
                    Cell: (e) => {
                      if (!e.value) {
                        return '-';
                      }
                      switch (e.value) {
                        case 'ADMIN':
                          return i18next.t('common.roles.administrator');
                        case 'GED':
                          return i18next.t('common.roles.gedReviewer');
                        case 'TREATMENT_AGENT':
                          return i18next.t('common.roles.internTreatmentAgent');
                        case 'DPO':
                          return i18next.t('common.roles.dpo');
                        case 'PAYING_ADMIN':
                          return i18next.t('common.roles.payingAdmin');
                        case 'OWNER':
                          return i18next.t('common.roles.owner');
                        default:
                          return '-';
                      }
                    },
                  },
                  {
                    Header: i18next.t('peopleAsset.table.action.label'),
                    accessor: 'action',
                    Cell: (e) => {
                      if (!e.value) {
                        return <ActionButton item={e.row.original} />;
                      }
                      return null;
                    },
                  },
                ]}
                data={people && people?.count > 0 ? people?.rows : []}
                onRowClick={(item) => {
                  setUserId(item.id);
                  setOpenEditModal(true);
                }}
                total={people?.count}
                pageCount={people?.total_page}
                handlePagination={handlePagination}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage + 1}
                loading
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
      <EditModal
        open={!!openEditModal}
        id={userId}
        search={searchValue}
        onClose={() => setOpenEditModal(false)}
      />
      <CreateUserModal
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
    </>
  );
};

export default Users;
