import { IoTrashOutline } from 'react-icons/io5';
import styled from 'styled-components';

export const WrapperFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div``;

export const FileName = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
  margin-right: 2rem;
  flex: 1;
  overflow: hidden;
`;

export const FileSize = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
  white-space: nowrap;
`;

export const FileDate = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #c4c4c4;
`;

export const DeleteButton = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: #f7f7f7;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ theme }) => theme.clickable};
  margin-left: 3rem;
`;

export const DeleteIcon = styled(IoTrashOutline).attrs({ size: 20 })`
  &:hover {
    color: ${({ theme }) => theme.colors.primary[400]};
  }
`;
