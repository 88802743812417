import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';

import { PrimaryButton, SecondaryButton } from '~/components/Button';
import StepAnimated from '~/components/StepAnimated';
import ValidationCodeInput from '~/components/ValidationCodeInput';

import SpinnerPrimary from '~/components/SppinerPrimary';
import schema from './validationSchema';

import {
  Container,
  CheckIcon,
  EmailSentText,
  CodeInstructionText,
  WrapperValidation,
} from './styles';

function Step2({ onSubmit, setHash, email }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const submit = (values) => {
    setHash(values.hash);
    dispatch(
      AuthActions.forgotHashValidationRequest(values.hash, email, onSubmit)
    );
  };

  return (
    <StepAnimated>
      <Container>
        <CheckIcon />
        <EmailSentText>
          {i18next.t('forgotPassword.step2.subtitle')}
        </EmailSentText>
        <CodeInstructionText>
          {i18next.t('forgotPassword.step2.description')}
        </CodeInstructionText>

        <Formik
          initialValues={{ hash: '' }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values, touched, errors, setFieldValue, handleSubmit }) => (
            <>
              <WrapperValidation>
                <ValidationCodeInput
                  name="hash"
                  value={values.hash}
                  setFieldValue={setFieldValue}
                  error={touched.hash && errors.hash ? errors.hash : undefined}
                />
              </WrapperValidation>

              <PrimaryButton
                label={
                  isLoading ? (
                    <SpinnerPrimary />
                  ) : (
                    i18next.t('forgotPassword.step2.buttonValidate')
                  )
                }
                disabled={isLoading}
                margin="0 0 1.6rem"
                onClick={handleSubmit}
                type="submit"
              />
              <SecondaryButton
                label={i18next.t('forgotPassword.step2.buttonSupport')}
              />
            </>
          )}
        </Formik>
      </Container>
    </StepAnimated>
  );
}

Step2.propTypes = {
  onSubmit: func.isRequired,
  setHash: func.isRequired,
  email: string.isRequired,
};

export default Step2;
