import React from 'react';
import { objectOf, oneOfType, any, func, string } from 'prop-types';
import i18next from 'i18next';

import { useDispatch } from 'react-redux';
import RiskActions from '~/store/ducks/risks';
import {
  Container,
  Header,
  Content,
  Avatar,
  Name,
  State,
  Arrow,
} from './styles';

function HistoryItem({ item, setNewTreatment, riskId, setOpenModal }) {
  const dispatch = useDispatch();

  const handleLoadTreatment = () => {
    setNewTreatment(false);

    dispatch(
      RiskActions.getRiskTreatmentDetailsRequest(riskId, item.id, setOpenModal)
    );
  };

  function convertName(name) {
    switch (name) {
      case 'unmitigated':
        return i18next.t('risks.risksTable.status.unmitigated');
      case 'mitigated':
        return i18next.t('risks.risksTable.status.mitigated');
      case 'catastrophic':
        return i18next.t('risks.captions.catastrophic');
      case 'high':
        return i18next.t('risks.captions.high');
      case 'moderate':
        return i18next.t('risks.captions.moderate');
      case 'possible':
        return i18next.t('risks.captions.possible');
      case 'insignificant':
        return i18next.t('risks.captions.insignificant');
      default:
        return '';
    }
  }

  function handleColor(state) {
    switch (state) {
      case 'catastrophic':
        return { background: 'rgba(14, 6, 125, 0.15)', color: '#0E067D' };
      case 'high':
        return { background: '#FAE3E5', color: '#FF2900' };
      case 'moderate':
        return { background: 'rgba(214, 171, 20, 0.15)', color: '#D6AB14' };
      case 'possible':
        return { background: '#E6F8EB', color: '#0ACF59' };
      case 'insignificant':
        return { background: '#E8F7FE', color: '#0E709A' };
      default:
        return { background: '#FAE3E5', color: '#FF2900' };
    }
  }
  return (
    <Container onClick={handleLoadTreatment}>
      <Header>
        <Avatar
          src={
            item?.user?.avatar
              ? `${
                  process.env.NODE_ENV === 'development'
                    ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                    : process.env.REACT_APP_API_PROD_ENDPOINT
                }users/avatar?avatar=${item?.user?.avatar}`
              : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMzSURBVHgBvZjPSxtBFMffjibSRItEUrAUuoG2FlJoRBR6Mp7rIa1g8aZg9Wj9C5L+BUZoEetBvAqRHOw58VQo2FrQQ+MhWyj9YVBDNalJzE7f2yYhhrgz2UQ/MCy7+93Nd2bnvXkTBRogsLLvZwDDiq778NQHiqJWbnKu4bnGOd/SGYtHX96PgySKSBBY/dzNzjvnFM5f4Wk3yKMBY6HI9L01kdDUxNjy1wBKFlClgnWEZuqaKPU+WOp9S+AcwhuzD+ZBxoRhoOCMKfTNWwwH2NFtmZHoVH+6+jqrFV6VAYLeS++njl5q4vnK/sJVGbhgJO8M1lwrGVhKTCptsCp6ibf3BgyqThi62wW3utqNa8nDHGjY1rcPIXV6DlJw/iwy2xe9YGJsOZE0iwKHncGLgR54+sg8Sjd304aZbF4HAVrRlumn+WF8DhoFURi+Hr0jNECMooa0ZFqAyvIOI/oMpcIgaKaeeuIGT08HyELacRw1EYqizBlHSsVtnMcuE7o7bbA0oYIVgpvfYe/nX1ONUiyOMKbrfjPR+IALrEITWASuM36GQzJsJvK47GAVb69DqMGs7GOYxlQzkfumdRNO8eTEWckeM1FUZHLCUGsWVWg1m5NMPnWQTVxCEx+/ZcAqycMzKR3NCc1M8H43DVaRfFbDOaHsmCkymH5XP6SgUdY/4TpyIvU5NMa5/kWkoh7FEn9AFtKubx9JaakmpWQVlxG/3fptLExmUCTRqJFWFvp9YxUde5c4Bski1t1lw2W8E4YwG1JKJzRjKT+Dzb00RlMjIc21yEyfxygIcEgWMXMGZR5LnRTw8xwbrWkYhP4fEN1uDxv7hmsFR2G6b61iIjrloViah+ukNArEhWob50YYD3Nmz5ZLuvJ8KEOhnM0X4UAqLPXFyMzDynaivfpW0WYLsUJhuFzsUj3pve0AFYsU1dVRMSCCokQ7ysHej6wRrtXpm8r+jSoDRJ19R7Lb62qPTQz2+MhEK6DC5k38Fxycntfdd1wKRkyYt5DUST5cu9+QNTKJLcmbI4ktAM1i0cwxthA2Ye+Ffw3UmPHjgRqVhGqpldGw0WJIa1Eck18cJPkHcqoKUN1/6RkAAAAASUVORK5CYII='
          }
        />
        <div>
          <Name>{item?.user?.name}</Name>
          <span>Dia 08/09/20</span>
        </div>
      </Header>

      <Content>
        <State color={handleColor(item?.previous_result)}>
          {convertName(item?.previous_result)}
        </State>
        <Arrow />
        <State color={handleColor(item?.risk_result)}>
          {convertName(item?.risk_result)}
        </State>
      </Content>
    </Container>
  );
}

export default HistoryItem;

HistoryItem.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
  setNewTreatment: func.isRequired,
  setOpenModal: func.isRequired,
  riskId: string.isRequired,
};
