import React from 'react';
import Grid from '@material-ui/core/Grid';
import i18next from 'i18next';

import { useSelector } from 'react-redux';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';

import OutlineButton from '~/components/Button/MainButtons/Outline';
import { Container, Header, Title, NoData } from './styles';
import { IconEdit } from '../Threats/styles';

const Details = () => {
  const { riskDetails } = useSelector((state) => state.risks);
  return (
    <Container>
      <Header>
        <Title>
          {i18next.t('risks.riskDetails.associations.contingency.title')}
        </Title>
        <OutlineButton
          onClick={() => {}}
          label={i18next.t('common.buttons.edit')}
          leftIcon={<IconEdit />}
        />
      </Header>
      {riskDetails?.contingency_details &&
      riskDetails?.contingency_details.length > 0 ? (
        riskDetails?.contingency_details.map((item) => (
          <Grid
            key={item.id}
            container
            spacing={4}
            style={{ marginBottom: '10rem' }}
          >
            <Grid item xs={4}>
              <TextInput
                label={i18next.t(
                  'risks.riskDetails.associations.contingency.maxTime'
                )}
                placeholder="DD/MM/AA"
                value={item?.max_stop_time}
                editable={false}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                label={i18next.t(
                  'risks.riskDetails.associations.contingency.impact'
                )}
                placeholder=""
                value={item?.impact}
                editable={false}
              />
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={12}>
              <TextArea
                label={i18next.t(
                  'risks.riskDetails.associations.contingency.consequences'
                )}
                placeholder="Descrição aqui"
                value={item?.consequences}
                editable={false}
              />
            </Grid>
          </Grid>
        ))
      ) : (
        <NoData>{i18next.t('risks.risksTable.noData')}</NoData>
      )}
    </Container>
  );
};

export default Details;
