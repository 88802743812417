import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs({ layout: true })`
  width: 80vw;
  max-width: 110rem;
  padding: 4.5rem 3.8rem;
`;

export const Title = styled(motion.div).attrs({ layout: true })`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 700;
  line-height: 3.6rem;
`;

export const Subtitle = styled(motion.div).attrs({ layout: true })`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.base[500]};
  font-weight: 400;
  margin-bottom: 2.8rem;
`;

export const WrapperButtons = styled(motion.div).attrs({ layout: true })``;
