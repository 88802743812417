import React from 'react';
import { string } from 'prop-types';

import { Container, Wrapper } from './styles';

const StatusTag = ({ item }) => {
  return (
    <Container>
      <Wrapper status={item}>{item === 'open' ? 'aberto' : 'fechado'}</Wrapper>
    </Container>
  );
};

export default StatusTag;

StatusTag.propTypes = {
  item: string.isRequired,
};
