import React from 'react';
import { string } from 'prop-types';

import { Container, Label } from './styles';

const PositionIndicator = ({ children }) => {
  return (
    <Container>
      <Label>{children}</Label>
    </Container>
  );
};

export default PositionIndicator;

PositionIndicator.propTypes = {
  children: string,
};

PositionIndicator.defaultProps = {
  children: '',
};
