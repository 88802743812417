import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs({ layout: true })`
  width: 100%;
`;

export const WrapperScroll = styled(motion.div).attrs({ layout: true })``;

export const Scroll = styled(PerfectScrollbar).attrs({
  options: { suppressScrollY: true, useBothWheelAxes: false },
})`
  height: 5.2rem;
  width: 100%;
  padding: 0 2rem;
`;

export const WrapperTabs = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  height: 5.2rem;
  min-width: 15.6rem;
  max-width: 20rem;
  margin: 0 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #f7f7f7;
  border-radius: 10px 10px 0px 0px;
  color: ${({ theme }) => theme.colors.base[400]};

  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      background-color: #539ada;
      color: ${({ theme }) => theme.colors.base[500]};
      cursor: default;
    `};

  transition: 200ms ease;

  p {
    font-size: 1.4rem;
    font-weight: 400;
    overflow: hidden;
    white-space: normal;
    text-overflow: -o-ellipsis-lastline;
    text-align: center;
  }
`;

export const WrapperContent = styled(motion.div).attrs({ layout: true })`
  border-top: 1px solid #c6ccd2;
  padding: 3.2rem;
`;
