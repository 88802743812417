import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const WrapperHorizontalLabel = styled.div`
  width: 100%;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #fafafc;
  border-radius: 5px;
  padding: 0 1rem;
`;

export const HorizontalLabel = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.base[400]};
`;

export const WrapperVerticalLabel = styled(WrapperHorizontalLabel)`
  height: auto;
  width: 2.5rem;
  padding: 1rem 0;
`;

export const VerticalLabel = styled(HorizontalLabel)`
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg);
`;

export const WrapperContent = styled.div`
  flex: 1;
  margin-left: 2rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const TR = styled.tr``;

export const TH = styled.th``;

export const TD = styled.td`
  height: 2.5rem;
  text-align: ${({ align }) => align || 'center'};
  color: #606474;
  font-size: 1.2rem;
  font-weight: 400;
  padding-bottom: 1rem;
`;
