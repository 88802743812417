import { func, number, objectOf, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';
import DocumentActions from '~/store/ducks/documents';
import i18next from 'i18next';
import PageNavigator from '~/components/PageNavigator';
import Spinner from '~/components/Spinner';
import { SecondarySelect } from '~/components/Select';
import Folder from '../../Folder';

import {
  NoFolderText,
  AreaFolders,
  WrapperNavigator,
  TotalItems,
  AreaSelect,
} from './styles';

export default function AllDocs({ search }) {
  const dispatch = useDispatch();
  const { folders, isLoading } = useSelector((state) => state.documents);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(6);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(DocumentActions.getFoldersRequest(currentPage - 1, limitPage));
  }, [limitPage]);

  useEffect(() => {
    if (search === '') {
      dispatch(DocumentActions.getFoldersRequest(currentPage - 1, limitPage));
    } else {
      dispatch(
        DocumentActions.searchFoldersRequest(search, currentPage - 1, limitPage)
      );
    }
  }, [search, currentPage]);

  return (
    <>
      {isLoading ? (
        <Spinner loading={isLoading} />
      ) : (
        <AreaFolders>
          <Grid container spacing={4}>
            {folders?.rows?.length > 0 ? (
              folders?.rows?.map((item, index) => (
                <Grid item xs={12} md={6} lg={4} key={item.name}>
                  <Folder item={item} data={folders} index={index} />
                </Grid>
              ))
            ) : (
              <Grid item xs alignItems="center">
                <NoFolderText>{i18next.t('documents.noData')}</NoFolderText>
              </Grid>
            )}
          </Grid>
        </AreaFolders>
      )}
      {folders?.count === 0 ? (
        ''
      ) : (
        <WrapperNavigator>
          <TotalItems>Total: {folders?.count}</TotalItems>

          <PageNavigator
            count={folders?.total_pages}
            onChangePage={(newPage) => handlePagination(newPage)}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handlePagination={handlePagination}
          />
          <AreaSelect>
            <SecondarySelect
              label={i18next.t('documents.limitPerPage')}
              placeholder="ex: 6"
              name="limit"
              options={[
                { label: '6', value: 6 },
                { label: '12', value: 12 },
                { label: '24', value: 24 },
                { label: '36', value: 36 },
              ]}
              value={limitPage}
              onChange={(e) => setLimitPage(e)}
            />
          </AreaSelect>
        </WrapperNavigator>
      )}
    </>
  );
}
