import styled from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const Container = styled.div``;

export const ModalContent = styled.div`
  width: 100%;
  max-width: 50rem;

  p {
    text-align: center;
    margin: 5.8rem 8.2rem 6.5rem;
  }
`;

export const Hr = styled.hr`
  margin-top: 2.5rem;
  border: 1px solid #ebebeb;
`;

export const Header = styled.div`
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.75rem;
`;
