import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
// import { useSocket } from '~/hooks/socket';

import Card from '~/components/Card';
import { PrimaryButton } from '~/components/Button';
import ChatItem from '../ChatItem';

import {
  Container,
  Title,
  Scroll,
  WrapperButton,
  Header,
  HeaderRight,
  NoData,
} from './styles';

function Chat() {
  const { ticketDetails } = useSelector((state) => state.tickets);
  const history = useHistory();
  // const { attendances } = useSocket();

  return (
    <Card>
      <Container>
        <Header>
          <Title>{i18next.t('demandManagement.demandDetails.chat')}</Title>
          <HeaderRight>
            <WrapperButton>
              <PrimaryButton
                small
                label={i18next.t('demandManagement.chat.access')}
                padding="0 2rem"
                width="null"
                onClick={() =>
                  history.push({
                    pathname: '/chat/',
                    state: {
                      protocol: ticketDetails?.protocol,
                      id: ticketDetails?.id,
                    },
                  })
                }
              />
            </WrapperButton>
          </HeaderRight>
        </Header>
        {/* <Scroll>
          {attendances && attendances.length > 0 ? (
            attendances.map((item) => <ChatItem key={item.id} item={item} />)
          ) : (
            <NoData>{i18next.t('demandManagement.chat.noData')}</NoData>
          )}
        </Scroll> */}
      </Container>
    </Card>
  );
}

export default Chat;
