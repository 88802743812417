import React, { useState, useRef } from 'react';
import { Formik } from 'formik';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';
import TermsModal from '~/components/TermsModal';
import history from '~/routes/history';
import TextInput from '~/components/TextInput';
import StepAnimated from '~/components/StepAnimated';
import { PrimaryButton } from '~/components/Button';
import ReCAPTCHA from 'react-google-recaptcha';
import jwt from 'jwt-decode';
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import { PhonInput } from '~/components/PhoneInput';
import schema from './validationSchema';

import {
  CheckboxWrapper,
  CheckBox,
  CheckBoxLabel,
  CheckboxButton,
  DocumentTextWrapper,
  PasswordWrapper,
  HidePasswordIcon,
  ShowPasswordIcon,
} from './styles';

const Registration = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false);
  const { isLoading } = useSelector((state) => state.auth);

  const token = history.location.search.replace('?invite_token=', '');
  const userMail = token ? jwt(token).user_email : null;

  const recaptchaRef = useRef(null);

  const submit = (values) => {
    const data = {
      ...values,
      phone: values.phone.replace(/\D/g, ''),
      agreements: {
        privacy_policy: privacyAgreement,
        terms_of_use: termsAgreement,
      },
      token,
    };

    if (process.env.REACT_APP_PROFILE !== 'develop') {
      recaptchaRef.current.execute();
      if (recaptchaCompleted) {
        localStorage.setItem(
          '@horus-pcs:create-account-email-1.0.0',
          data.email
        );

        dispatch(AuthActions.signUpRequest(data, onSubmit));
      }
    } else if (process.env.REACT_APP_PROFILE === 'develop') {
      localStorage.setItem('@horus-pcs:create-account-email-1.0.0', data.email);

      dispatch(AuthActions.signUpRequest(data, onSubmit));
    }
  };

  return (
    <>
      <StepAnimated noStart>
        <Formik
          initialValues={{
            first_name: '',
            last_name: '',
            email: userMail || '',
            phone: '',
            password: '',
            confirm_password: '',
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <TextInput
                placeholder={i18next.t('companySignUp.step1.nameLabel')}
                name="first_name"
                value={values.first_name}
                touched={touched.first_name}
                error={errors.first_name}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <TextInput
                placeholder={i18next.t('companySignUp.step1.lastNameLabel')}
                name="last_name"
                value={values.last_name}
                touched={touched.last_name}
                error={errors.last_name}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <TextInput
                placeholder={i18next.t('companySignUp.step1.emailLabel')}
                name="email"
                value={values.email}
                touched={touched.email}
                disabled={userMail}
                error={errors.email}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <PhonInput
                placeholder={i18next.t('companySignUp.step1.phoneLabel')}
                value={values.phone}
                onChange={(e) => setFieldValue('phone', e)}
                margin="0 0 2.1rem 0"
              />
              <PasswordWrapper>
                <TextInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder={i18next.t('companySignUp.step4.passwordLabel')}
                  name="password"
                  value={values.password}
                  touched={touched.password}
                  error={errors.password}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  margin="0 0 2.1rem 0"
                />
                {showPassword ? (
                  <HidePasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <ShowPasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </PasswordWrapper>

              <PasswordWrapper>
                <TextInput
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  placeholder={i18next.t(
                    'companySignUp.step4.passwordConfirmationLabel'
                  )}
                  name="confirm_password"
                  value={values.confirm_password}
                  touched={touched.confirm_password}
                  error={errors.confirm_password}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  margin="0 0 3.1rem 0"
                />
                {showPasswordConfirmation ? (
                  <HidePasswordIcon
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  />
                ) : (
                  <ShowPasswordIcon
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  />
                )}
              </PasswordWrapper>

              <CheckboxWrapper>
                <CheckBox
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={termsAgreement}
                  onChange={() => setTermsAgreement((e) => !e)}
                />
                <CheckBoxLabel>
                  {i18next.t('companySignUp.step1.termsLabel')}{' '}
                  <CheckboxButton onClick={() => setIsTermsModalOpen(true)}>
                    {i18next.t('companySignUp.step1.termsButton')} e a{' '}
                    {i18next.t('companySignUp.step1.privacyPolicyButton')}
                  </CheckboxButton>
                </CheckBoxLabel>
              </CheckboxWrapper>

              {/* <CheckboxWrapper>
                <CheckBox
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={privacyAgreement}
                  onChange={() => setPrivacyAgreement((e) => !e)}
                />
                <CheckBoxLabel>
                  {i18next.t('companySignUp.step1.privacyPolicyLabel')}{' '}
                  <CheckboxButton onClick={() => setIsPrivacyModalOpen(true)}>
                    {i18next.t('companySignUp.step1.privacyPolicyButton')}
                  </CheckboxButton>
                </CheckBoxLabel>
              </CheckboxWrapper> */}

              <PrimaryButton
                type="submit"
                disabled={isLoading || !termsAgreement || !privacyAgreement}
                label={
                  isLoading ? (
                    <SppinerIcon />
                  ) : (
                    i18next.t('companySignUp.step1.buttonSubmit')
                  )
                }
                onClick={handleSubmit}
              />
            </>
          )}
        </Formik>
      </StepAnimated>
      <TermsModal
        open={isTermsModalOpen}
        title= {i18next.t('companySignUp.step1.title')}
        checkboxSelected={termsAgreement}
        onClose={() => setIsTermsModalOpen(false)}
      >
        <DocumentTextWrapper>
          <iframe
            src="https://docs.google.com/gview?url=https://pcs-publics.s3.amazonaws.com/TermsOfUseAndPrivacyPolicy/termosdeuso.pdf&embedded=true"
            title="file"
            width="100%"
            height="100%"
          />
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setTermsAgreement((e) => !e)}
            checked={termsAgreement}
          />
          <CheckBoxLabel>
            {i18next.t('companySignUp.step1.termsLabel')}{' '}
            {i18next.t('companySignUp.step1.termsButton')} e a{' '}
            {i18next.t('companySignUp.step1.privacyPolicyButton')}
          </CheckBoxLabel>
        </CheckboxWrapper>
      </TermsModal>
      {/* <TermsModal
        open={isPrivacyModalOpen}
        title="Política de privacidade"
        checkboxSelected={privacyAgreement}
        onClose={() => setIsPrivacyModalOpen(false)}
      >
        <DocumentTextWrapper>
          <iframe
            src="https://docs.google.com/gview?url=https://pcs-publics.s3.amazonaws.com/TermsOfUseAndPrivacyPolicy/termosdeuso.pdf&embedded=true"
            title="file"
            width="100%"
            height="100%"
          />
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setPrivacyAgreement((e) => !e)}
            checked={privacyAgreement}
          />
          <CheckBoxLabel>
            Li a concordo com a Política de privacidade
          </CheckBoxLabel>
        </CheckboxWrapper> */}
      {/* </TermsModal> */}
      {process.env.REACT_APP_PROFILE !== 'develop' ? (
        <ReCAPTCHA
          theme="light"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={() => setRecaptchaCompleted(true)}
          size="invisible"
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default Registration;

Registration.propTypes = {
  onSubmit: func.isRequired,
  // recaptchaCompleted: bool.isRequired,
};
