import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import i18next from 'i18next';

import { useSelector, useDispatch } from 'react-redux';
import { dateSortMethod } from '~/utils';
// import { SecondaryButton } from '~/components/Button';
import Card from '~/components/Card';
import Table from '~/components/Table/Secondary';
import ProcessActions from '~/store/ducks/processes';
import ActionButtonTable from '~/components/ActionButtonTable';

import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

const TableComp = ({ search }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { processes } = useSelector((state) => state.processes);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(ProcessActions.getProcessesRequest(currentPage - 1, 10, search));
  }, [search, currentPage]);

  const handleEdit = (id) => {
    history.push(`/assets-management/business-process/${id}`);
  };

  // const reloadBusinessProcess = () => {
  //   dispatch(ProcessActions.getProcessesRequest());
  // };

  const handleDelete = (id) => {
    dispatch(ProcessActions.deleteProcessRequest(id));
  };
  const handlePagination = (newPage) => {
    dispatch(ProcessActions.getProcessesRequest(newPage));
  };

  return (
    <Container>
      <Card padding="2rem 0">
        <Table
          columns={[
            {
              Header: i18next.t('processAsset.table.name'),
              accessor: 'title',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },
            {
              Header: i18next.t('businessUnitAsset.table.author'),
              accessor: 'author.first_name',
              Cell: (e) => {
                if (!e.value) {
                  return '-';
                }
                return e.value;
              },
            },

            {
              Header: i18next.t('processAsset.table.date'),
              accessor: 'createdAt',
              Cell: (e) => format(new Date(`${e.value}`), 'dd/MM/yyyy'),
              sortType: (a, b) => {
                return dateSortMethod(a.values.date, b.values.date);
              },
            },
            {
              Header: i18next.t('processAsset.table.action'),
              accessor: 'action',
              Cell: (e) => {
                if (e.row.original?.id) {
                  return (
                    <ActionButtonTable
                      dataId={e.row.original?.id}
                      dispatchDelete={handleDelete}
                      dispatchEdit={handleEdit}
                    />
                    // <SecondaryButton
                    //   onClick={() => handleProcessLoad(e.row.original?.id)}
                    //   label={i18next.t('processAsset.table.actionButton')}
                    //   small
                    //   width="8.6rem"
                    //   padding="0"
                    // />
                  );
                }
                return null;
              },
            },
          ]}
          data={processes.rows || []}
          onRowClick={(item) => {
            handleEdit(item.id);
          }}
          total={processes?.count}
          pageCount={processes?.total_pages}
          handlePagination={handlePagination}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </Card>
    </Container>
  );
};

export default TableComp;

TableComp.defaultProps = {
  search: '',
};

TableComp.propTypes = {
  search: string,
};
