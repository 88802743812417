import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3.3rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 2.2rem;
  font-weight: 700;
  margin-right: 1.6rem;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const SearchAndNewButton = styled.div`
  margin-top: 3rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WrapperDep = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 420px;
`;
