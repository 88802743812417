import React, { useState } from 'react';
import { arrayOf, objectOf, string, bool, func } from 'prop-types';
import ReactSelect from 'react-select';
import i18next from 'i18next';

import { Container, Label, Error, ButtonAdd, IconPlus } from './styles';

function Select({
  required,
  options,
  placeholder,
  label,
  touched,
  error,
  onChange,
  value,
  margin,
  oneLineLabel,
  disabled,
  onBlur,
  hasStore,
  hasModal,
  onStore,
  shadow,
  sm,
}) {
  const colourStyles = {
    control: () => ({
      label: 'control',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      borderRadius: '1rem',
      boxShadow: shadow ? 'rgba(149, 157, 165, 0.2) 0px 8px 24px ' : null,
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: '6rem',
      outline: '0 !important',
      position: 'relative',
      transition: 'all 200ms',

      border: error && touched ? '1px solid red' : 'none',
      // 1px solid #C6CCD2

      boxSizing: 'border-box',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#262A3A',
      '&:hover': { color: '#0E067D' },
      opacity: disabled ? 0 : 1,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: '#606474',
      fontSize: '1.4rem',
      fontWeight: 400,
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      color: isSelected ? '#fff' : '#606474',
      fontSize: '1.4rem',
      fontWeight: isSelected ? 700 : 400,
      cursor: 'pointer',
    }),
    singleValue: (styles) => ({
      ...styles,
      color: '#262A3A',
      fontSize: '1.4rem',
      fontWeight: 500,
      opacity: disabled ? 0.5 : 1,
    }),
  };

  const isError = Boolean(touched && error);
  const [inputValue, setInputSelect] = useState('');

  const handleNew = () => {
    if (hasModal) {
      onStore(true);
    } else {
      onStore(inputValue);
    }
  };

  const createNew = () => {
    if (hasStore) {
      return (
        <ButtonAdd onClick={handleNew}>
          <IconPlus />
          {i18next.t('common.buttons.new')}
        </ButtonAdd>
      );
    }
    return i18next.t('common.noData');
  };

  return (
    <Container error={isError} label={label} margin={margin}>
      {label && (
        <Label sm={sm} oneLineLabel={oneLineLabel} required={required}>
          {label}
        </Label>
      )}

      <ReactSelect
        options={[...options]}
        required={required}
        placeholder={placeholder}
        styles={colourStyles}
        onBlur={onBlur}
        shadow={shadow}
        onChange={(e) => onChange(e.value)}
        noOptionsMessage={createNew}
        isDisabled={disabled}
        value={
          options.find(
            (e) => JSON.stringify(e.value) === JSON.stringify(value)
          ) || null
        }
      />
      <Error error={isError}>{error}</Error>
    </Container>
  );
}

export default Select;

Select.propTypes = {
  required: bool,
  hasStore: bool,
  hasModal: bool,
  onStore: func,
  options: arrayOf(objectOf(string)),
  placeholder: string,
  label: string,
  touched: bool,
  error: string,
  onChange: func,
  value: string,
  margin: string,
  oneLineLabel: bool,
  disabled: bool,
  onBlur: bool,
  shadow: bool,
  sm: bool,
};

Select.defaultProps = {
  sm: false,
  required: false,
  hasStore: false,
  hasModal: false,
  onStore: null,
  options: [],
  placeholder: 'Selecione',
  label: '',
  touched: false,
  error: '',
  onChange: () => {},
  value: '',
  margin: '0',
  oneLineLabel: true,
  disabled: false,
  onBlur: false,
  shadow: false,
};
