import styled, { css } from 'styled-components';
import { IconImage } from '~/assets/svgs';
import { FiCamera } from 'react-icons/fi';

export const Container = styled.div.attrs({ layout: true })`
  width: 100%;
  max-width: 1120px;
  margin: auto;
`;

export const ConfigWrapper = styled.div`
  margin-top: 8.5rem;
`;

export const Content = styled.div`
  padding: 2rem 0;
  background: white;
`;

export const InfoWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  font-weight: 700;
  color: #262a3a;
  line-height: 3.6rem;
  margin-bottom: 3rem;
`;

export const Subtitle = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: #262a3a;
  margin-bottom: 5rem;
`;

export const DropzoneAvatar = styled.div`
  width: 8rem;
  height: 8rem;
  /* margin: 0 auto; */
  background: #fafbfb;
  border-radius: 10rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.1rem;
  cursor: pointer;
  overflow: hidden;

  text-align: center;
  color: #606474;
  font-weight: 400;
  font-size: 1.8rem;

  ${({ hasImage }) =>
    hasImage &&
    css`
      box-shadow: 0px 2px 16px 2px rgba(0, 0, 0, 0.25);
    `};

  ${({ isDragActive, isDragReject }) =>
    isDragActive &&
    !isDragReject &&
    css`
      transform: scale(0.98);
      -webkit-box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
      box-shadow: inset 0.7rem 0.7rem 2.6rem #f5f5f5,
        inset -0.7rem -0.7rem 2.6rem #ffffff;
      border-color: #0e067d;
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      border-color: #e74c3c;
    `};

  transition: 200ms ease;
`;

export const ImageIcon = styled(IconImage)`
  /* margin-bottom: 3rem; */
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Instructions = styled.span`
  margin-top: 1rem;
  color: #c6ccd2;
  font-weight: 500;
  font-size: 1rem;
`;

export const Padding = styled.div`
  padding: 0 3.8rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  gap: 3rem;

  margin: 8rem;
  width: 100%;
`;
export const AreaCameraIcon = styled.div`
  padding: 8px;
  margin-left: 70px;
  margin-top: 35px;
  background-color: #c6ccd2;
  border-radius: 999px;

  position: absolute;
  border: 3px solid white;
  display: flex;
`;

export const CameraIcon = styled(FiCamera).attrs({
  color: '#606474',
  size: 20,
})``;

export const AreaUploadImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
