import styled, { css } from 'styled-components';

export const Container = styled.div`
  background: ${({ theme, active }) =>
    active ? theme.colors.base[350] : theme.colors.base[200]};
  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  position: relative;
  margin-left: 4.8rem;
  padding: 0.3rem 1rem;
  margin: 0.3rem 0 0 4.8rem;

  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.base[350]};
  }

  ${({ position, active }) =>
    active &&
    css`
      &::before {
        border: 1.2px solid #4347a2;
        content: '';
        height: calc(${position * 3.5}rem + 3rem);
        width: 4rem;
        z-index: 1;
        border-top: none;
        border-right: none;
        left: -4rem;
        top: calc(-${position * 3.5}rem - 1.5rem);
        position: absolute;
        display: block;
      }
    `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7rem;
`;

export const CheckWrapper = styled.div`
  position: relative;
`;

export const Check = styled.input`
  height: 0.8rem;
  width: 0.8rem;
  visibility: hidden;
`;

export const LabelCheck = styled.label`
  background: ${({ active }) => (active ? '#4347a2' : '#606474')};
  border-radius: 0.1rem;
  cursor: pointer;
  height: 0.8rem;
  width: 0.8rem;
  left: 0px;
  top: 0.3rem;
  position: absolute;
  transform: rotate(-45deg);
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  font-weight: 400;
`;
