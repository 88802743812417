import styled, { css } from 'styled-components';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RiCloseCircleLine } from 'react-icons/ri';
import { motion } from 'framer-motion';

export const Container = styled(PerfectScrollbar)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  background-color: #0a1d3b66;

  opacity: ${({ open }) => (open ? 1 : 0)};

  transition: 300ms ease;
`;

export const Card = styled.div`
  background-color: #fff;
  border-radius: 2rem;
  position: relative;
  max-width: 90vw;

  min-height: 80.8rem;

  padding: 0 7rem;
`;

export const MotionCard = styled(motion.div).attrs({ layout: true })`
  background-color: #fff;
  border-radius: 2rem;
  position: relative;
  max-width: 90vw;
`;

export const Header = styled.div`
  padding: 4.5rem 4rem 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.6rem;
  font-weight: 700;

  position: relative;
  justify-content: center;

  @media (max-width: 720px) {
    padding: 2rem 2rem 0;
  }
`;

export const CloseButton = styled.div`
  height: 5rem;

  background-color: #f8f8f8;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 3.8rem;


  align-items: center;
  justify-content: space-between;
  padding: 0 2.6rem;

  color: ${({ theme }) => theme.colors.base[500]};
  font-size: 1.4rem;
  font-weight: 400;

  :hover {
    background-color: #f7f7f7;
    transform: scale(1.02);
  }

  transition: all 200ms ease;

  @media (max-width: 720px) {
    display: none;
  }
`;

export const CloseIcon = styled(RiCloseCircleLine).attrs({
  size: '2rem',
})`
  color: #2e3a59;
  cursor: pointer;
`;

export const BigCloseIcon = styled(RiCloseCircleLine).attrs({
  size: '3rem',
})`
  color: #2e3a59;
  cursor: pointer;
  @media (min-width: 720px) {
    display: none;
  }
`;

export const WrapperContent = styled.div`
  ${({ noPadding }) =>
    !noPadding &&
    css`
      padding: 0 3.8rem 3.8rem;
      @media (max-width: 720px) {
        padding: 0 2rem 2rem;
      }
    `};
`;

export const ButtonWrapper = styled.div`
  width: 21.3rem;
  margin: -4rem auto;
`;

export const Button = styled.button`
  height: 6.5rem;
  width: 21.3rem;
  border-radius: 1rem;
  border: none;

  background: ${(props) => (props.checkboxSelected ? '#4347A2' : '#B8BABA')};

  font-family: ${({ theme }) => theme.fonts.Poppins};
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.7rem;
  color: ${({ theme }) => theme.colors.white};

  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.8);
  }
`;
