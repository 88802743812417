import React from 'react';
import { objectOf, oneOfType, any } from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

import { IconProfilePlaceholder } from '~/assets/icons';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import ActionButton from './components/ActionButton';

import {
  Container,
  WrapperTop,
  WrapperBottom,
  Subtitle,
  Title,
  DocumentAmount,
} from './styles';

const Folder = ({ item, data, isGlobal }) => {
  const history = useHistory();

  return (
    <Container>
      <WrapperTop>
        <AvatarGroup max={4}>
          {item?.length > 0 ? (
            item?.map((user) => (
              <Avatar
                alt={user?.name}
                src={
                  user?.avatar
                    ? `${
                        process.env.NODE_ENV === 'development'
                          ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                          : process.env.REACT_APP_API_PROD_ENDPOINT
                      }users/avatar?avatar=${user?.avatar}`
                    : IconProfilePlaceholder
                }
                key={user.id}
              />
            ))
          ) : (
            <div />
          )}
        </AvatarGroup>
      </WrapperTop>
      <WrapperBottom
        onClick={() =>
          history.push({
            pathname: `/document-management/documents/folder-details/${item.id}`,
            state: { global: isGlobal },
          })
        }
      >
        <Title>{item.name}</Title>
        <Subtitle>
          {i18next.t('common.fields.department')}: {item?.departament?.name}
        </Subtitle>
        <DocumentAmount>{`${
          item?.docs_amount === 0 ? '' : item?.docs_amount
        }  ${
          item?.docs_amount === 1
            ? `${i18next.t('documents.file')}`
            : item?.docs_amount === 0
            ? `${i18next.t('documents.noFiles')}`
            : `${i18next.t('documents.files')}`
        }`}</DocumentAmount>
      </WrapperBottom>
      {item.id !== 0 && <ActionButton folder={item} />}
    </Container>
  );
};

export default Folder;

Folder.propTypes = {
  item: objectOf(oneOfType([any])).isRequired,
};
