import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import { func, string } from 'prop-types';
import i18next from 'i18next';

import { Container, Dropzone, FileIcon, DropText } from './styles';
import File from '../File';

const AttachFile = ({ onDone, placeholder }) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    onDone(file);
  }, [file]);

  return (
    <Container>
      <Grid item xs={12}>
        {file ? (
          <File file={file} onDelete={() => setFile(null)} />
        ) : (
          <Dropzone {...getRootProps()} isDragActive={isDragActive}>
            <input {...getInputProps()} multiple={false} />
            <FileIcon />
            <DropText>{placeholder}</DropText>
          </Dropzone>
        )}
      </Grid>
    </Container>
  );
};

export default AttachFile;

AttachFile.propTypes = {
  onDone: func,
  placeholder: string,
};

AttachFile.defaultProps = {
  onDone: () => {},
  placeholder: i18next.t('callOpening.demandOpening.attachmentInstruction'),
};
