import React from 'react';
import Grid from '@material-ui/core/Grid';

import { useSelector } from 'react-redux';
import { IconProfilePlaceholder } from '~/assets/icons';

import DateViewer from '~/components/DateViewer';
import TextArea from '~/components/TextArea';
import Spinner from '~/components/Spinner';
import Files from './components/Files';

import {
  Container,
  Header,
  TopWrapper,
  Title,
  Wrapper,
  Text,
  ResponsibleAvatar,
  ResponsibleName,
  ResponsibleWrapper,
} from './styles';

const InteractionDetails = () => {
  const { isLoading, interactionDetails } = useSelector(
    (state) => state.demands
  );

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <Container>
      <Grid item xs={12}>
        <Header>
          <Title>
            Detalhes da interação - Protocolo {interactionDetails?.ticket_id}
          </Title>
        </Header>
      </Grid>
      <Wrapper>
        <Grid container>
          <TopWrapper>
            <Grid container xs={8} spacing={4}>
              <Grid item xs={6}>
                <Text>Respondido por:</Text>
                <ResponsibleWrapper>
                  <ResponsibleAvatar
                    src={
                      interactionDetails?.responsible?.avatar
                        ? `${
                            process.env.NODE_ENV === 'development'
                              ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                              : process.env.REACT_APP_API_PROD_ENDPOINT
                          }users/avatar?avatar=${
                            interactionDetails?.responsible?.avatar
                          }`
                        : IconProfilePlaceholder
                    }
                  />

                  <ResponsibleName>
                    {interactionDetails?.responsible?.name || ''}
                  </ResponsibleName>
                </ResponsibleWrapper>
              </Grid>
              <Grid item align="center" xs={6}>
                <Text>Data da Resposta</Text>
                <DateViewer insertedDate={interactionDetails?.createdAt} />
              </Grid>

              <Grid item xs={12}>
                <TextArea
                  label="Interação"
                  placeholder="Sem mensagem na interação."
                  name="description"
                  value={interactionDetails?.description || ''}
                  editable={false}
                  editableui
                  textArea
                />
              </Grid>
            </Grid>
            <Grid container xs={4}>
              <Files />
            </Grid>
          </TopWrapper>
        </Grid>
      </Wrapper>
    </Container>
  );
};

export default InteractionDetails;
