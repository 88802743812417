import { put, all, takeLatest, call, select } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import translateError from '~/utils/translateError';
import api from '~/services/api';
import IventoryActions, { IventoryTypes } from '../ducks/iventory';

function* createIventory({ data }) {
  const { userActive } = yield select((state) => state.auth);

  const formData = new FormData();
  formData.append('company_id', userActive.activeCompany.id);
  formData.append('user_id', userActive.user.id);
  formData.append('user_email', userActive.user.email);

  if (data.processIdentification.departament_id)
    formData.append(
      'departament_id',
      data.processIdentification.departament_id
    );

  if (data.processIdentification.process_id)
    formData.append('process_id', data.processIdentification.process_id);

  formData.append(
    'collection_process',
    JSON.stringify({
      collection_purpose: data.processIdentification?.purposeOfCollection || [],
      collection_forms: data.processIdentification?.collectionForms || [],
      collection_categories:
        data.processIdentification?.categoryOfDataCollected || [],
    })
  );

  formData.append(
    'legal_framework',
    JSON.stringify({
      holder_transparency:
        data.processIdentification?.transparencyToTheHolder || [],
      collected_data: data.processIdentification?.collectedData || [],
      retention_time: {
        unit_time: data.processIdentification?.timeUnit || '',
        time: data.processIdentification?.retentionTime || 1,
      },
      legal_basis_assesment: data.processIdentification?.legalBasis || [],
      process_update_frequency:
        data.processIdentification?.processUpdateFrequency || [],
      adequacy_privacy_committe: {
        value: data.processIdentification?.alignedAdjustments,
      },
    })
  );

  formData.append(
    'transaction_data',
    JSON.stringify({
      input_transmission: data.processIdentification?.iNTransmission || [],
      output_transmission: data.processIdentification?.oUTTransmission || [],
      storage_forms: data.processIdentification?.storageforms || [],
    })
  );

  if (data.processIdentification?.providers.length > 0)
    formData.append(
      'third_parties',
      JSON.stringify(data.processIdentification?.providers)
    );

  formData.append(
    'information_security_control',
    JSON.stringify({
      has_controll_access: {
        it_has: data.controls.accessControl?.itHas,
        description: data.controls.accessControl?.description,
      },
      has_authentication: {
        it_has: data.controls.authentication.itHas,
        description: data.controls.authentication?.description,
      },
      has_encryption: {
        it_has: data.controls.encryption?.itHas,
        description: data.controls.encryption?.description,
      },
      has_personal_data_masking: {
        it_has: data.controls.masking?.itHas,
        description: data.controls.masking?.description,
      },
      has_pseudo_anonymization: {
        it_has: data.controls.anonymization?.itHas,
        description: data.controls.anonymization?.description,
      },
      has_backup: {
        it_has: data.controls.backup?.itHas,
        description: data.controls.backup?.description,
      },
      has_protection_dlp: {
        it_has: data.controls.dlp?.itHas,
        description: data.controls.dlp?.description,
      },
      observation: data.controls.description || '',
    })
  );

  if (data.controls.accessControl?.itHas)
    formData.append('controll_access_file', data.controls.accessControl?.file);

  if (data.controls.authentication?.itHas)
    formData.append(
      'controll_authentication',
      data.controls.authentication?.file
    );

  if (data.controls.encryption?.itHas)
    formData.append('encryption_file', data.controls.encryption?.file);

  if (data.controls.masking?.itHas)
    formData.append('personal_data_masking_file', data.controls.masking?.file);

  if (data.controls.anonymization?.itHas)
    formData.append(
      'pseudo_anonymization_file',
      data.controls.anonymization?.file
    );

  if (data.controls.backup?.itHas)
    formData.append('backup_file', data.controls.backup?.file);

  if (data.controls.dlp?.itHas)
    formData.append('protection_dlp_file', data.controls.dlp?.file);

  try {
    yield call(api.post, '/api/inventory', formData);
    yield put(IventoryActions.createIventorySuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Iventário criado!',
      })
    );
  } catch (error) {
    yield put(IventoryActions.createIventoryFailure());
    if (error.response?.data?.validations?.message) {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro na criação do data mapping',
          message: translateError(
            `APIerrors.${error.response.data?.errorCode}`
          ),
        })
      );
    } else {
      yield put(
        toastrActions.add({
          type: 'error',
          title: 'Erro na criação do data mapping',
          message: 'Tente novamente mais tarde.',
        })
      );
    }
  }
}

// function* deleteIventory({ id }) {
//   try {
//     yield call(api.delete, `/api/person/${id}`);

//     yield put(PeopleActions.deletePersonSuccess());
//     yield put(
//       toastrActions.add({
//         type: 'success',
//         title: 'Sucesso!',
//         message: 'Usuário deletado!',
//       })
//     );
//     reloadPeople();
//   } catch (error) {
//     yield put(PeopleActions.deletePersonFailure());
//     yield put(
//       toastrActions.add({
//         type: 'error',
//         title: 'Falha ao deletar usuário',
//         message: translateError(`APIerrors.${error.response.data.errorCode}`),
//       })
//     );
//   }
// }

function* getIventories({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/list/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getIventoriesSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getIventoriesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento do data mapping',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getIventoryDetails({ id }) {
  try {
    const response = yield call(api.get, `/api/inventory/${id}`);
    yield put(IventoryActions.getIventoryDetailsSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getIventoryDetailsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos dados do data mapping',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getPeopleResponsibleEnum({ companyId }) {
  try {
    const response = yield call(api.get, `people/${companyId}`);

    const formattedResponsibles = response.data.data.map((responsible) => {
      return {
        value: responsible.user.id,
        label: responsible.user.name,
      };
    });

    yield put(
      IventoryActions.getPeopleResponsibleEnumSuccess(formattedResponsibles)
    );
  } catch (error) {
    yield put(IventoryActions.getPeopleResponsibleEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getBusinessCriticalityEnum() {
  try {
    const response = yield call(api.get, `/business-criticality`);

    const formattedBusinessCriticality = response.data.data.map(
      (responsible) => {
        return {
          value: responsible.id,
          label: responsible.name,
        };
      }
    );

    yield put(
      IventoryActions.getBusinessCriticalityEnumSuccess(
        formattedBusinessCriticality
      )
    );
  } catch (error) {
    yield put(IventoryActions.getBusinessCriticalityEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createNewDepartment({ name, companyId, updateDepartments }) {
  try {
    yield call(api.post, `/departments/${companyId}`, { name });

    yield put(IventoryActions.createDepartmentSuccess());
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Departamento criado!',
      })
    );
    updateDepartments();
  } catch (error) {
    yield put(IventoryActions.createDepartmentFailure());
  }
}

function* getDepartmentEnum({ companyId }) {
  try {
    const response = yield call(api.get, `departments/${companyId}`);

    const formattedDepartments = response.data.data.map((departament) => {
      return {
        value: departament.id,
        label: departament.name,
      };
    });

    yield put(IventoryActions.getDepartmentEnumSuccess(formattedDepartments));
  } catch (error) {
    yield put(IventoryActions.getDepartmentEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createCollectionPurpose({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/collection-purpose/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createCollectionPurposeSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Finalidade de coleta criada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createCollectionPurposeFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao tentar cadastrar uma nova finalidade de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectionPurpose({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-purpose/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getCollectionPurposeSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getCollectionPurposeFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar dados de pessoas',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectionPurposeEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-purpose/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getCollectionPurposeEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getCollectionPurposeEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das finalidades de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createCollectionForms({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/collection-forms/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createCollectionFormsSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Forma de coleta criada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createCollectionPurposeFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao tentar cadastrar uma nova forma de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectionForms({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-forms/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getCollectionFormsSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getCollectionFormsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao carregar as formas de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectionFormsEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-forms/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getCollectionFormsEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getCollectionFormsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das formas de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createCategoryOfDataCollected({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/collection-categories/${userActive.activeCompany.id}`,
      data
    );

    yield put(
      IventoryActions.createCategoryOfDataCollectedSuccess(response.data)
    );
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Categoria criada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createCategoryOfDataCollectedFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha ao tentar cadastrar uma nova categoria de dados de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCategoriesOfDataCollected({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-categories/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getCategoryOfDataCollectedSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getCategoryOfDataCollectedFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das categorias',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCategoriesOfDataCollectedEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collection-categories/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getCategoryOfDataCollectedEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getCategoryOfDataCollectedEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das categorias de dados coletados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createTransparencyToTheHolder({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/holder-transparency/${userActive.activeCompany.id}`,
      data
    );

    yield put(
      IventoryActions.createTransparencyToTheHolderSuccess(response.data)
    );
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Transparência criada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createTransparencyToTheHolderFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao tentar cadastrar uma nova transparência',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getTransparencyToTheHolder({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/holder-transparency/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getTransparencyToTheHolderSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getTransparencyToTheHolderFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da lista de transparências',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}
function* getTransparencyToTheHolderEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/holder-transparency/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getTransparencyToTheHolderEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getTransparencyToTheHolderEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da lista de transparências',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createCollectedData({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/collected-data/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createCollectedDataSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Dado de coleta criado!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createCollectedDataFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao tentar cadastrar uma nova dado de coleta',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectedData({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collected-data/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getCollectedDataSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getCollectedDataFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da listagem de dados coletados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getCollectedDataEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/collected-data/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getCollectedDataEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getCollectedDataEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da listagem de dados coletados',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createLegalBasis({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/legal-basis-assessment/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createLegalBasisAssessmentSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Avaliação de Base Legal adicionada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createLegalBasisAssessmentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha ao tentar cadastrar uma nova avaliação de Base Legal',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getLocalBasisAssessment({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/legal-basis-assessment/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getLegalBasisAssessmentSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getLegalBasisAssessmentFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da listagem de avaliações de base legal',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}
function* getLocalBasisAssessmentEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/legal-basis-assessment/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getLegalBasisAssessmentEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getLegalBasisAssessmentEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das avaliações de base legal',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createProcessUpdateFrequency({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/process-update-frequency/${userActive.activeCompany.id}`,
      data
    );

    yield put(
      IventoryActions.createProcessUpdateFrequencySuccess(response.data)
    );
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Periodicidade de atualização do processo criada!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createProcessUpdateFrequencyFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha ao tentar cadastrar uma nova periodicidade de atualização do processo',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getProcessUpdateFrequencies({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/process-update-frequency/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(
      IventoryActions.getProcessUpdateFrequenciesSuccess(response.data)
    );
  } catch (error) {
    yield put(IventoryActions.getProcessUpdateFrequenciesFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha no carregamento da listagem das periodicidades de atualização do processo',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}
function* getProcessUpdateFrequenciesEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/process-update-frequency/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getProcessUpdateFrequenciesEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getProcessUpdateFrequenciesEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha no carregamento das periodicidades de atualização do processo',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* createInOutTransmission({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/transmission-in-and-out/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createInOutTransmissionSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Transmissão de entrada/saída criado!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createInOutTransmissionFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha ao tentar cadastrar uma nova transmissão de entrada/saída',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getInOutTransmissions({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/transmission-in-and-out/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getInOutTransmissionsSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getInOutTransmissionsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha no carregamento da listagem das transmissões de entrada e saída',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getInOutTransmissionsEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/transmission-in-and-out/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getInOutTransmissionsEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getInOutTransmissionsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento das transmissões de entrada e saída',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* creteStorageForm({ data, onSuccess }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.post,
      `/api/inventory/storage-forms/${userActive.activeCompany.id}`,
      data
    );

    yield put(IventoryActions.createStorageFormSuccess(response.data));
    yield put(
      toastrActions.add({
        type: 'success',
        title: 'Sucesso!',
        message: 'Transmissão de entrada/saída criado!',
      })
    );
    onSuccess();
  } catch (error) {
    yield put(IventoryActions.createStorageFormFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title:
          'Falha ao tentar cadastrar uma nova transmissão de entrada/saída',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getStorageForms({ page, limit, search }) {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/storage-forms/${userActive.activeCompany.id}?page=${page}&limit=${limit}&search=${search}`
    );

    yield put(IventoryActions.getStorageFormsSuccess(response.data));
  } catch (error) {
    yield put(IventoryActions.getStorageFormsFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento da listagem dos armazenamentos',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

function* getStorageFormsEnum() {
  const { userActive } = yield select((state) => state.auth);
  try {
    const response = yield call(
      api.get,
      `/api/inventory/storage-forms/${userActive.activeCompany.id}`
    );

    yield put(
      IventoryActions.getStorageFormsEnumSuccess(
        response.data.map((d) => ({ label: d.type, value: d.id }))
      )
    );
  } catch (error) {
    yield put(IventoryActions.getStorageFormsEnumFailure());
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no carregamento dos armazenamentos',
        message: translateError(`APIerrors.${error.response.data.errorCode}`),
      })
    );
  }
}

export default all([
  takeLatest(IventoryTypes.GET_IVENTORIES_REQUEST, getIventories),
  takeLatest(
    IventoryTypes.GET_PEOPLE_RESPONSIBLE_ENUM_REQUEST,
    getPeopleResponsibleEnum
  ),
  takeLatest(
    IventoryTypes.GET_BUSINESS_CRITICALITY_ENUM_REQUEST,
    getBusinessCriticalityEnum
  ),
  takeLatest(IventoryTypes.GET_DEPARTMENT_ENUM_REQUEST, getDepartmentEnum),

  takeLatest(IventoryTypes.CREATE_DEPARTMENT_REQUEST, createNewDepartment),

  takeLatest(
    IventoryTypes.CREATE_COLLECTION_PURPOSE_REQUEST,
    createCollectionPurpose
  ),
  takeLatest(
    IventoryTypes.GET_COLLECTION_PURPOSE_REQUEST,
    getCollectionPurpose
  ),
  takeLatest(
    IventoryTypes.GET_COLLECTION_PURPOSE_ENUM_REQUEST,
    getCollectionPurposeEnum
  ),
  takeLatest(
    IventoryTypes.CREATE_COLLECTION_FORMS_REQUEST,
    createCollectionForms
  ),
  takeLatest(IventoryTypes.GET_COLLECTION_FORMS_REQUEST, getCollectionForms),
  takeLatest(
    IventoryTypes.GET_COLLECTION_FORMS_ENUM_REQUEST,
    getCollectionFormsEnum
  ),
  takeLatest(
    IventoryTypes.CREATE_CATEGORY_OF_DATA_COLLECTED_REQUEST,
    createCategoryOfDataCollected
  ),

  takeLatest(
    IventoryTypes.GET_CATEGORY_OF_DATA_COLLECTED_REQUEST,
    getCategoriesOfDataCollected
  ),

  takeLatest(
    IventoryTypes.GET_CATEGORY_OF_DATA_COLLECTED_ENUM_REQUEST,
    getCategoriesOfDataCollectedEnum
  ),

  takeLatest(
    IventoryTypes.CREATE_TRANSPARENCY_TO_THE_HOLDER_REQUEST,
    createTransparencyToTheHolder
  ),

  takeLatest(
    IventoryTypes.GET_TRANSPARENCY_TO_THE_HOLDER_REQUEST,
    getTransparencyToTheHolder
  ),

  takeLatest(
    IventoryTypes.GET_TRANSPARENCY_TO_THE_HOLDER_ENUM_REQUEST,
    getTransparencyToTheHolderEnum
  ),

  takeLatest(IventoryTypes.CREATE_COLLECTED_DATA_REQUEST, createCollectedData),

  takeLatest(IventoryTypes.GET_COLLECTED_DATA_REQUEST, getCollectedData),

  takeLatest(
    IventoryTypes.GET_COLLECTED_DATA_ENUM_REQUEST,
    getCollectedDataEnum
  ),

  takeLatest(
    IventoryTypes.CREATE_LEGAL_BASIS_ASSESSMENT_REQUEST,
    createLegalBasis
  ),

  takeLatest(
    IventoryTypes.GET_LEGAL_BASIS_ASSESSMENT_REQUEST,
    getLocalBasisAssessment
  ),

  takeLatest(
    IventoryTypes.GET_LEGAL_BASIS_ASSESSMENT_ENUM_REQUEST,
    getLocalBasisAssessmentEnum
  ),

  takeLatest(
    IventoryTypes.CREATE_PROCESS_UPDATE_FREQUENCY_REQUEST,
    createProcessUpdateFrequency
  ),

  takeLatest(
    IventoryTypes.GET_PROCESS_UPDATE_FREQUENCIES_REQUEST,
    getProcessUpdateFrequencies
  ),

  takeLatest(
    IventoryTypes.GET_PROCESS_UPDATE_FREQUENCIES_ENUM_REQUEST,
    getProcessUpdateFrequenciesEnum
  ),

  takeLatest(
    IventoryTypes.CREATE_IN_OUT_TRANSMISSION_REQUEST,
    createInOutTransmission
  ),

  takeLatest(
    IventoryTypes.GET_IN_OUT_TRANSMISSIONS_REQUEST,
    getInOutTransmissions
  ),

  takeLatest(
    IventoryTypes.GET_IN_OUT_TRANSMISSIONS_ENUM_REQUEST,
    getInOutTransmissionsEnum
  ),

  takeLatest(IventoryTypes.CREATE_STORAGE_FORM_REQUEST, creteStorageForm),

  takeLatest(IventoryTypes.GET_STORAGE_FORMS_REQUEST, getStorageForms),
  takeLatest(IventoryTypes.GET_STORAGE_FORMS_ENUM_REQUEST, getStorageFormsEnum),
  takeLatest(IventoryTypes.CREATE_IVENTORY_REQUEST, createIventory),
  takeLatest(IventoryTypes.GET_IVENTORY_DETAILS_REQUEST, getIventoryDetails),
  // takeLatest(IventoryTypes.DELETE_IVENTORY_REQUEST, deleteIventory),
]);
