import styled from 'styled-components';
import { AiOutlineCloudUpload, AiOutlinePlusCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { FiPaperclip } from 'react-icons/fi';

export const Container = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-bottom: 3.3rem;
`;

export const Label = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const ID = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const InputFile = styled.div`
  max-width: 100%;
  border: 0;
  background: ${({ theme }) => theme.colors.blueSecondary};
  color: ${({ theme }) => theme.colors.base[100]};
  border-radius: 5px;
  padding: 1.2rem 1rem;
  font-size: 1.6rem;

  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;

  :hover {
    filter: brightness(1.1);
  }
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;
export const IconUpload = styled(AiOutlineCloudUpload).attrs({ size: 18 })``;

export const ButtonName = styled.strong`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const ClipIcon = styled(FiPaperclip).attrs({
  size: 18,
})``;

export const DeleteButton = styled.div`
  width: 6rem;
  height: 4rem;
  /* background-color: #f7f7f7; */
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  :hover {
    color: red;
  }
  ${({ theme }) => theme.clickable};
`;

export const Description = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.4rem;
  font-weight: 500;
`;

export const DeleteIcon = styled(IoTrashOutline).attrs({ size: 24 })``;
export const SaveIcon = styled(IoTrashOutline).attrs({ size: 24 })``;

export const AttachIcon = styled(FaCloudUploadAlt).attrs({ size: 24 })``;

export const WrapperAddButton = styled.div`
  margin-top: 3rem;
  width: 15rem;
  margin-bottom: 8rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const FileSize = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  color: #606474;
  margin-left: 1rem;
`;

export const FileDate = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  color: #c4c4c4;
`;
