import React, { useState, useRef } from 'react';
import i18next from 'i18next';

import { TertiaryButton } from '~/components/Button';
import SearchInput from '~/components/TextInput/Search';
// import Spinner from '~/components/Spinner';
import Table from './components/Table';

import {
  Container,
  Header,
  TitleWrapper,
  Title,
  IconPlus,
  ActionsWrapper,
  SearchAndNewButton,
  WrapperDep,
} from './styles';
import CreateModal from './components/CreateModal';

const ThirdPartyManagement = () => {
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef(null);
  const [optionModal, setOptionModal] = useState(false);
  const [editOption, setEditOption] = useState({});

  const handleCloseModal = () => {
    setOptionModal(false);
    setEditOption({});
  };

  return (
    <Container>
      <Header>
        <TitleWrapper>
          <Title>{i18next.t('thirdPartyManagement.label')}</Title>
        </TitleWrapper>
        <ActionsWrapper>
          <SearchAndNewButton>
            <WrapperDep>
              <SearchInput
                inputRef={searchInputRef}
                placeholder={i18next.t('common.search')}
                value={searchValue}
                onChange={setSearchValue}
                delay={1500}
                hasDebounce
              />
              <TertiaryButton
                onClick={() => setOptionModal(true)}
                label={i18next.t('common.buttons.new')}
                leftIcon={<IconPlus />}
              />
            </WrapperDep>
          </SearchAndNewButton>
        </ActionsWrapper>
      </Header>
      <Table
        search={searchValue}
        setEditProvider={setEditOption}
        setProviderModal={setOptionModal}
      />
      <CreateModal
        open={!!optionModal}
        editOption={editOption}
        onClose={handleCloseModal}
      />
    </Container>
  );
};

export default ThirdPartyManagement;
