import React from 'react';
import { string, number, bool } from 'prop-types';
import interpolate from 'color-interpolate';

import { Container, Label, WrapperBar, Bar } from './styles';

function ProgressBar({
  label,
  total,
  actualValue,
  progressiveColor,
  initialColor,
  midColor,
  finalColor,
}) {
  const colorMap = interpolate([initialColor, midColor, finalColor]);
  const color = progressiveColor ? colorMap(actualValue / total) : finalColor;

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <WrapperBar color={color}>
        <Bar size={actualValue / total} color={color} />
      </WrapperBar>
    </Container>
  );
}

export default ProgressBar;

ProgressBar.propTypes = {
  label: string,
  total: number,
  actualValue: number,
  progressiveColor: bool,
  initialColor: string,
  midColor: string,
  finalColor: string,
};

ProgressBar.defaultProps = {
  label: '',
  total: 1,
  actualValue: 1,
  progressiveColor: false,
  initialColor: '#FF2900',
  midColor: '#f6c517',
  finalColor: '#539ada',
};
