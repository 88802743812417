import React, { useState } from 'react';
import { Formik } from 'formik';
import {
  func,
  // bool
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import AuthActions from '~/store/ducks/auth';
import TermsModal from '~/components/TermsModal';

import TextInput from '~/components/TextInput';
import StepAnimated from '~/components/StepAnimated';
import { PrimaryButton } from '~/components/Button';

import { useParams } from 'react-router-dom';
import { SppinerIcon } from '~/pages/Configurations/Company/CompanyData/styles';
import schema from './validationSchema';

import {
  CheckboxWrapper,
  CheckBox,
  CheckBoxLabel,
  CheckboxButton,
  DocumentTextWrapper,
  PasswordWrapper,
  HidePasswordIcon,
  ShowPasswordIcon,
} from './styles';

const Step1 = ({ onSubmit }) => {
  const dispatch = useDispatch();
  const { role } = useParams();
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [termsAgreement, setTermsAgreement] = useState(false);
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const { isLoading } = useSelector((state) => state.auth);

  const submit = (values) => {
    const data = {
      ...values,
      role,
      termsAgreement,
      privacyAgreement,
    };

    localStorage.setItem('@horus-pcs:create-account-email-1.0.0', data.email);

    dispatch(AuthActions.signUpRequest(data, onSubmit));

    // dispatch(
    //   AuthActions.signUpCompanyEmailValidationRequest(values.email, onSubmit)
    // );
    // }
  };
  return (
    <>
      <StepAnimated noStart>
        <Formik
          initialValues={{
            name: '',
            email: '',
            phone: '',
            document: '',
            fantasy_name: '',
            password: '',
            confirm_password: '',
          }}
          onSubmit={submit}
          validationSchema={schema}
        >
          {({ values, touched, errors, handleChange, handleSubmit }) => (
            <>
              <TextInput
                placeholder={i18next.t('companySignUp.step1.nameLabel')}
                name="name"
                value={values.name}
                touched={touched.name}
                error={errors.name}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <TextInput
                placeholder={i18next.t('companySignUp.step1.emailLabel')}
                name="email"
                value={values.email}
                touched={touched.email}
                error={errors.email}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <TextInput
                placeholder={i18next.t('companySignUp.step1.phoneLabel')}
                name="phone"
                mask={
                  values.phone.length < 15
                    ? '(99) 9999-9999*'
                    : '(99) 99999-9999'
                }
                value={values.phone}
                touched={touched.phone}
                error={errors.phone}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />
              <TextInput
                placeholder={i18next.t('companySignUp.step1.cnpjLabel')}
                name="document"
                mask="99.999.999/9999-99"
                value={values.document}
                touched={touched.document}
                error={errors.document}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />

              <TextInput
                placeholder={i18next.t('companySignUp.step1.fantasyNameLabel')}
                name="fantasy_name"
                value={values.fantasy_name}
                touched={touched.fantasy_name}
                error={errors.fantasy_name}
                onChange={handleChange}
                onSubmit={handleSubmit}
                margin="0 0 2.1rem 0"
              />

              <PasswordWrapper>
                <TextInput
                  type={showPassword ? 'text' : 'password'}
                  placeholder={i18next.t('companySignUp.step4.passwordLabel')}
                  name="password"
                  value={values.password}
                  touched={touched.password}
                  error={errors.password}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  margin="0 0 2.1rem 0"
                />
                {showPassword ? (
                  <HidePasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <ShowPasswordIcon
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </PasswordWrapper>

              <PasswordWrapper>
                <TextInput
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  placeholder={i18next.t(
                    'companySignUp.step4.passwordConfirmationLabel'
                  )}
                  name="confirm_password"
                  value={values.confirm_password}
                  touched={touched.confirm_password}
                  error={errors.confirm_password}
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  margin="0 0 3.1rem 0"
                />
                {showPasswordConfirmation ? (
                  <HidePasswordIcon
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  />
                ) : (
                  <ShowPasswordIcon
                    onClick={() =>
                      setShowPasswordConfirmation(!showPasswordConfirmation)
                    }
                  />
                )}
              </PasswordWrapper>

              <CheckboxWrapper>
                <CheckBox
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={termsAgreement}
                  onChange={() => setTermsAgreement((e) => !e)}
                />
                <CheckBoxLabel>
                  {i18next.t('companySignUp.step1.termsLabel')}{' '}
                  <CheckboxButton onClick={() => setIsTermsModalOpen(true)}>
                    {i18next.t('companySignUp.step1.termsButton')}
                  </CheckboxButton>
                </CheckBoxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <CheckBox
                  type="checkbox"
                  id="privacy"
                  name="privacy"
                  checked={privacyAgreement}
                  onChange={() => setPrivacyAgreement((e) => !e)}
                />
                <CheckBoxLabel>
                  {i18next.t('companySignUp.step1.privacyLabel')}{' '}
                  <CheckboxButton onClick={() => setIsPrivacyModalOpen(true)}>
                    {i18next.t('companySignUp.step1.privacyButton')}
                  </CheckboxButton>
                </CheckBoxLabel>
              </CheckboxWrapper>
              <PrimaryButton
                type="submit"
                disabled={isLoading || !termsAgreement}
                label={
                  isLoading ? (
                    <SppinerIcon />
                  ) : (
                    i18next.t('companySignUp.step1.buttonSubmit')
                  )
                }
                onClick={handleSubmit}
              />
            </>
          )}
        </Formik>
      </StepAnimated>
      <TermsModal
        open={isTermsModalOpen}
        title="Termo de uso"
        checkboxSelected={termsAgreement}
        onClose={() => setIsTermsModalOpen(false)}
      >
        <DocumentTextWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            sagittis ac neque vel hendrerit. Fusce quis augue mollis, finibus
            tortor eget, sodales lacus. Sed leo magna, fringilla eu lorem non,
            convallis accumsan arcu. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. In dignissim eget urna quis tempor. Vestibulum
            finibus ipsum in erat dictum rhoncus. Sed pellentesque tempor nulla,
            non aliquam urna venenatis vel. Proin semper neque ut mauris tempor
            tristique.
          </p>

          <p>
            Sed nulla quam, condimentum nec malesuada nec, hendrerit vel augue.
            Donec eget odio accumsan, laoreet nisl id, semper nisl. Vivamus
            vulputate sed velit sed suscipit. Pellentesque aliquam mi non nisi
            egestas, nec fringilla risus pellentesque. Maecenas ultrices
            condimentum feugiat. Cras ultrices in leo aliquet sagittis.
            Vestibulum cursus nibh orci, in condimentum arcu finibus non. Mauris
            placerat iaculis sapien, eget ornare lorem blandit consequat.
            Quisque at venenatis enim. Vivamus tempus vehicula mi, vel porttitor
            ipsum vestibulum eget. Vestibulum finibus, lectus eu euismod
            interdum, sapien ipsum consectetur urna, quis fermentum massa leo et
            lectus. Curabitur nec purus mi. Morbi leo risus, ultrices ac risus
            ac, convallis euismod arcu. Donec rutrum dolor vel congue tempor.
            Aenean dictum erat et nunc tristique gravida.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setTermsAgreement((e) => !e)}
            checked={termsAgreement}
          />
          <CheckBoxLabel>Li e concordo com os Termos de uso</CheckBoxLabel>
        </CheckboxWrapper>
      </TermsModal>
      <TermsModal
        open={isPrivacyModalOpen}
        title="Política de privacidade"
        checkboxSelected={privacyAgreement}
        onClose={() => setIsPrivacyModalOpen(false)}
      >
        <DocumentTextWrapper>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            sagittis ac neque vel hendrerit. Fusce quis augue mollis, finibus
            tortor eget, sodales lacus. Sed leo magna, fringilla eu lorem non,
            convallis accumsan arcu. Interdum et malesuada fames ac ante ipsum
            primis in faucibus. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. In dignissim eget urna quis tempor. Vestibulum
            finibus ipsum in erat dictum rhoncus. Sed pellentesque tempor nulla,
            non aliquam urna venenatis vel. Proin semper neque ut mauris tempor
            tristique.
          </p>

          <p>
            Sed nulla quam, condimentum nec malesuada nec, hendrerit vel augue.
            Donec eget odio accumsan, laoreet nisl id, semper nisl. Vivamus
            vulputate sed velit sed suscipit. Pellentesque aliquam mi non nisi
            egestas, nec fringilla risus pellentesque. Maecenas ultrices
            condimentum feugiat. Cras ultrices in leo aliquet sagittis.
            Vestibulum cursus nibh orci, in condimentum arcu finibus non. Mauris
            placerat iaculis sapien, eget ornare lorem blandit consequat.
            Quisque at venenatis enim. Vivamus tempus vehicula mi, vel porttitor
            ipsum vestibulum eget. Vestibulum finibus, lectus eu euismod
            interdum, sapien ipsum consectetur urna, quis fermentum massa leo et
            lectus. Curabitur nec purus mi. Morbi leo risus, ultrices ac risus
            ac, convallis euismod arcu. Donec rutrum dolor vel congue tempor.
            Aenean dictum erat et nunc tristique gravida.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>

          <p>
            Donec volutpat mauris ut dui condimentum tempus. Cras condimentum
            sapien in sapien vehicula pellentesque. Nulla mauris arcu, hendrerit
            consequat purus nec, dictum pulvinar sem. Pellentesque quis mattis
            neque, sit amet sagittis dui. Aliquam elit risus, semper vitae quam
            ut, consectetur aliquet purus. Ut eu justo at dolor imperdiet auctor
            vel vel est. Etiam tempus posuere maximus. Integer luctus mi sit
            amet mattis pulvinar. Duis sed nulla purus. Suspendisse ornare sem
            libero, sed elementum velit volutpat sit amet. Morbi tempus
            tristique maximus. Integer at congue nisl.
          </p>

          <p>
            Donec aliquet luctus dictum. Nam facilisis odio eget dignissim
            varius. Morbi nisi purus, blandit ac aliquet vel, egestas id erat.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Sed sit amet commodo turpis, vel placerat mi.
            Suspendisse varius ante nec leo pharetra egestas. Nulla facilisi.
            Integer eu iaculis tellus. Sed auctor velit justo, quis posuere eros
            gravida ac. Mauris eget blandit erat, bibendum posuere nisl.
          </p>

          <p>
            Quisque ut leo non nisi malesuada fringilla nec sed dolor.
            Vestibulum quis magna eu nisi tincidunt placerat. Pellentesque et
            libero at dolor tempor varius non vel nisi. Praesent eros enim,
            vehicula a metus et, tempus cursus eros. Nam fermentum nunc sed
            finibus pharetra. Aenean mattis tortor a justo aliquet varius. Donec
            molestie iaculis leo, sit amet volutpat felis dignissim a. Nulla
            facilisi. Suspendisse ut dignissim nulla. Fusce vitae dui et arcu
            gravida egestas sed vel risus. Suspendisse non orci ut magna
            lobortis dignissim. Duis pharetra accumsan tempor. Pellentesque
            iaculis laoreet libero, ut ultrices elit sagittis in. Praesent
            dignissim metus elit, ac placerat mauris mollis eu. Sed nisl dolor,
            viverra vel ipsum vel, scelerisque feugiat dui.
          </p>
        </DocumentTextWrapper>
        <CheckboxWrapper>
          <CheckBox
            type="checkbox"
            id="terms"
            name="terms"
            onChange={() => setPrivacyAgreement((e) => !e)}
            checked={privacyAgreement}
          />
          <CheckBoxLabel>
            Li e concordo com a Política de privacidade
          </CheckBoxLabel>
        </CheckboxWrapper>
      </TermsModal>
    </>
  );
};

export default Step1;

Step1.propTypes = {
  onSubmit: func.isRequired,
  // recaptchaCompleted: bool.isRequired,
};
