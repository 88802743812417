import React from 'react';
import { string, func, oneOfType, number, bool, node } from 'prop-types';

import { Container } from './styles';

function PrimaryButton({
  label,
  disabled,
  onClick,
  margin,
  padding,
  width,
  small,
  background,
  ...rest
}) {
  return (
    <Container
      onClick={!disabled ? onClick : null}
      disabled={disabled}
      margin={margin}
      padding={padding}
      width={width}
      small={small}
      background={background}
      {...rest}
    >
      {label}
    </Container>
  );
}

PrimaryButton.propTypes = {
  label: node,
  onClick: func,
  disabled: bool,
  margin: oneOfType([string, number]),
  padding: string,
  width: string,
  small: bool,
  background: string,
};

PrimaryButton.defaultProps = {
  label: null,
  disabled: false,
  onClick: () => {},
  margin: '0.2rem 0',
  padding: '0 9rem',
  width: '100%',
  small: false,
  background: 'blue',
};

export default PrimaryButton;
