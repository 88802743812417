import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { any, oneOfType, objectOf } from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import RiskActions from '~/store/ducks/risks';

import Spinner from '~/components/Spinner';
import ActiveInfo from './components/ActiveInfo';
import History from './components/History';
import Resume from './components/Resume';
import Elements from './components/Elements';

function Detail({ match }) {
  const { isLoading } = useSelector((state) => state.risks);
  const { params } = match;
  const { id } = params;

  const dispatch = useDispatch();
  const [newTreatment, setNewTreatment] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(RiskActions.getRiskAssociationsRequest(id));
      dispatch(RiskActions.getRiskDetailRequest(id));
      dispatch(RiskActions.getRiskHistoryRequest(id));
    }
  }, []);

  if (isLoading) {
    return <Spinner loading={isLoading} />;
  }

  return (
    <>
      <Grid container spacing={5}>
        <Grid item md={8} xs={12}>
          <ActiveInfo
            id={id}
            openModal={openModal}
            setOpenModal={setOpenModal}
            newTreatment={newTreatment}
            setNewTreatment={setNewTreatment}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <History
            setNewTreatment={setNewTreatment}
            riskId={id}
            setOpenModal={setOpenModal}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Resume />
        </Grid>
        <Grid item md={12} xs={12}>
          <Elements />
        </Grid>
      </Grid>
    </>
  );
}

export default Detail;

Detail.propTypes = {
  match: objectOf(oneOfType([any])).isRequired,
};
