import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

function StepAnimated({ children, noStart }) {
  return (
    <motion.div
      initial={noStart ? {} : { opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: '0' }}
      exit={{ opacity: 0, x: '-100%' }}
      transition={{ duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
}

StepAnimated.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  noStart: PropTypes.bool,
};

StepAnimated.defaultProps = {
  noStart: false,
};

export default React.memo(StepAnimated);
