import styled from 'styled-components';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';

export const Container = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.base[500]};
  margin-bottom: 3.3rem;
`;

export const Label = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const ID = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;

export const IconPlus = styled(AiOutlinePlusCircle).attrs({ size: 18 })``;

export const DeleteButton = styled.div`
  width: 6rem;
  height: 6rem;
  background-color: #f7f7f7;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  ${({ theme }) => theme.clickable};
`;

export const DeleteIcon = styled(IoTrashOutline).attrs({ size: 24 })``;

export const WrapperAddButton = styled.div`
  margin-top: 3rem;
  width: 15rem;
  margin-bottom: 8rem;
`;

export const Nature = styled.div`
  height: 6rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: #322e39;
`;
