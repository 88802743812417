import * as Yup from 'yup';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import i18next from 'i18next';

export default Yup.object().shape({
  name: Yup.string().required(i18next.t('common.validation.required')),
  fantasy_name: Yup.string().required(i18next.t('common.validation.required')),
  cnpj: Yup.string()
    .required(i18next.t('common.validation.required'))
    .test('cnpj', 'CNPJ inválido', (cnpj) => isValidCnpj(cnpj)),
  email: Yup.string()
    .email('E-mail inválido')
    .required(i18next.t('common.validation.required')),
  // phone: Yup.string()
  //   .required(i18next.t('common.validation.required'))
  //   .min(10, i18next.t('common.validation.phone')),

  // company_bylaw: Yup.mixed().required(i18next.t('common.validation.required')),
  // company_last_contractual_alteration:
  //   Yup.mixed().required(i18next.t('common.validation.required')),
  // company_address_proof: Yup.mixed().required(i18next.t('common.validation.required')),
});
