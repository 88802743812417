import React from 'react';
import { string, func, bool } from 'prop-types';
import { Row } from '~/styles/components';

import { Container, Inner, Label } from './styles';

const CheckBox = ({ label, onSelect, selected, margin }) => {
  return (
    <Row cursor="pointer" onClick={onSelect} align="center" margin={margin}>
      <Container selected={selected}>
        <Inner selected={selected} />
      </Container>
      {label && <Label>{label}</Label>}
    </Row>
  );
};

export default CheckBox;

CheckBox.propTypes = {
  label: string,
  onSelect: func,
  selected: bool,
  margin: string,
};

CheckBox.defaultProps = {
  label: '',
  onSelect: () => {},
  selected: false,
  margin: '0',
};
