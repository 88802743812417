import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import { format } from 'date-fns';
import { func } from 'prop-types';

import { byteToSize } from '~/utils/formatters';

import {
  Container,
  Label,
  Dropzone,
  FileIcon,
  DropText,
  WrapperFile,
  Row,
  FileName,
  FileSize,
  FileDate,
  Column,
  DeleteButton,
  DeleteIcon,
} from './styles';

const AttachFile = ({ setAnswerFile }) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setAnswerFile(acceptedFiles[0]);
    },
    [setFile]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container>
      <Grid item xs={12}>
        <Label>Anexar arquivo</Label>
        {file ? (
          <WrapperFile>
            <Column>
              <Row>
                <FileName>
                  {file.name.length > 20
                    ? `${file.name.slice(0, 10)}...${file.name.slice(
                        file.name.length - 10
                      )}`
                    : file.name}
                </FileName>
                <FileSize>{byteToSize(file.size)}</FileSize>
              </Row>
              <FileDate>{format(new Date(), 'dd/MM/yyyy')}</FileDate>
            </Column>
            <DeleteButton onClick={() => setFile(null)}>
              <DeleteIcon />
            </DeleteButton>
          </WrapperFile>
        ) : (
          <Dropzone {...getRootProps()} isDragActive={isDragActive}>
            <input {...getInputProps()} multiple={false} />
            <FileIcon />
            <DropText>Arraste ou adicione</DropText>
          </Dropzone>
        )}
      </Grid>
    </Container>
  );
};

export default AttachFile;

AttachFile.propTypes = {
  setAnswerFile: func.isRequired,
};
