import React, { useState, useEffect } from 'react';

import i18next from 'i18next';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import TextInput from '~/components/TextInput';
import TextArea from '~/components/TextArea';
// import { SecondarySelect } from '~/components/Select';
import ProcessActions from '~/store/ducks/processes';
// import iventory from '~/store/ducks/iventory';
// import BusinessUnitActions from '~/store/ducks/businessUnits';
import NewUserTypeModalGeral from '~/components/NewUserTypeModal';
import InviteDPOModalGeral from '~/components/InviteDPOModal';
import CreateUserModalGeral from '~/components/CreateUserModal';
import CreateNewLocality from '~/components/CreateNewLocality';

import { Container, Scroll, WrapperBottom, BottomText, Title } from './styles';

const Info = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { processDetails } = useSelector((state) => state.processes);
  const [openNewUserTypeModal, setOpenNewUserTypeModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openCreateLocality, setOpenCreateLocality] = useState(false);
  const [openInviteDPOModal, setOpenInviteDPOModal] = useState(false);
  const [newUserRole, setNewUserRole] = useState('');

  const id = history.location.pathname.replace(
    '/assets-management/business-process/',
    ''
  );

  const [data, setData] = useState({});

  useEffect(() => {
    if (id !== 'new') {
      setData({
        title: processDetails?.title,
        primaryFunction: processDetails?.primary_function,
      });
    }
  }, [processDetails]);

  useEffect(() => {
    dispatch(ProcessActions.setSidebarMenuDataRequest(data));
  }, [data]);

  return (
    <Container>
      <Scroll>
        <Title>{i18next.t('processAsset.sidebar.title')}</Title>
        <TextInput
          label={i18next.t('processAsset.sidebar.titleLabel')}
          margin="0 0 1.8rem"
          required
          value={data.title}
          onChange={(e) => setData({ ...data, title: e.target.value })}
        />

        <TextArea
          label={i18next.t('processAsset.sidebar.primaryFunctionLabel')}
          placeholder="Descreva a função"
          margin="0 0 1.8rem"
          maxHeight="30rem"
          value={data.primaryFunction}
          onChange={(e) =>
            setData({ ...data, primaryFunction: e.target.value })
          }
        />
        {/* <MultiSelect
          label={i18next.t('processAsset.sidebar.responsiblesLabel')}
          options={peopleResponsibleEnum}
          value={responsible}
          hasStore
          hasModal
          onStore={setOpenNewUserTypeModal}
          onChange={setResponsible}
          margin="0 0 1.8rem"
        /> */}
      </Scroll>

      {processDetails?.process?.id && (
        <WrapperBottom>
          <BottomText>
            {i18next.t('processAsset.sidebar.identifier')}:{' '}
            {`${processDetails.process.code}`}
          </BottomText>
          <BottomText>
            {i18next.t('processAsset.sidebar.creationDate')}:{' '}
            {format(
              new Date(`${processDetails.process.createdAt}T00:00`),
              'dd/MM/yyyy'
            )}
          </BottomText>
          <BottomText>
            {i18next.t('processAsset.sidebar.creationTime')}:{' '}
            {format(new Date(processDetails.process.createdAt), 'HH:mm')}
          </BottomText>
          {processDetails?.last_updated.length > 0 && (
            <>
              <BottomText>
                {i18next.t('technologiesAsset.sidebar.editBy')}:{' '}
                {processDetails?.last_updated[0]?.user.name.split(' ')[0]}
              </BottomText>
              <BottomText>
                {i18next.t('technologiesAsset.sidebar.editDate')}:{' '}
                {format(
                  new Date(`${processDetails?.process?.updatedAt}`),
                  'dd/MM/yyyy'
                )}
              </BottomText>
            </>
          )}
        </WrapperBottom>
      )}
      <NewUserTypeModalGeral
        open={!!openNewUserTypeModal}
        onClose={() => setOpenNewUserTypeModal(false)}
        setOpenCreateUserModal={setOpenCreateUserModal}
        setNewUserRole={setNewUserRole}
        setOpenInviteDPOModal={setOpenInviteDPOModal}
      />
      <CreateUserModalGeral
        open={!!openCreateUserModal}
        newUserRole={newUserRole}
        onClose={() => setOpenCreateUserModal(false)}
      />
      <InviteDPOModalGeral
        open={!!openInviteDPOModal}
        onClose={() => setOpenInviteDPOModal(false)}
      />
      <CreateNewLocality
        open={!!openCreateLocality}
        onClose={() => setOpenCreateLocality(false)}
      />
    </Container>
  );
};

export default Info;
