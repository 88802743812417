import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthActions from '~/store/ducks/auth';
import InformativeAbout from '~/components/informativeAbout';
import UserHeader from '~/components/UserHeader';
import Tabs from '~/components/Tabs';
import i18next from 'i18next';
import Spinner from '~/components/Spinner';
import { Container, TabWrapper, AreaLoadingOrOnlyMyCompanies } from './styles';
import MyCompanies from './Tabs/MyCompanies';

export default function ListCompanies({ onNewCompany }) {
  const totalPerPage = 15;
  const dispatch = useDispatch();
  const { isLoading, companies, myCompanies, associatedCompanies } =
    useSelector((state) => state.auth);
  const [currentPageTab1, setCurrentPageTab1] = useState(1);
  const [currentPageTab2, setCurrentPageTab2] = useState(1);
  const [currentPageTab3, setCurrentPageTab3] = useState(1);

  useEffect(() => {
    dispatch(
      AuthActions.getCompaniesRequest(currentPageTab1 - 1, totalPerPage)
    );
  }, [currentPageTab1]);

  useEffect(() => {
    dispatch(
      AuthActions.getMyCompaniesRequest(currentPageTab2 - 1, totalPerPage)
    );
  }, [currentPageTab2]);

  useEffect(() => {
    dispatch(
      AuthActions.getAssociatedCompaniesRequest(
        currentPageTab3 - 1,
        totalPerPage
      )
    );
  }, [currentPageTab3]);

  return (
    <Container>
      <UserHeader />

      <TabWrapper>
        {isLoading ? (
          <AreaLoadingOrOnlyMyCompanies style={{ height: '100vh' }}>
            <Spinner loading={isLoading} />
          </AreaLoadingOrOnlyMyCompanies>
        ) : (
          <>
            {associatedCompanies?.data?.count === 0 ? (
              <AreaLoadingOrOnlyMyCompanies>
                <MyCompanies
                  addNew
                  onNewCompany={onNewCompany}
                  companies={myCompanies}
                  currentPage={currentPageTab2}
                  setCurrentPageTab={setCurrentPageTab2}
                  isLoading={isLoading}
                />
              </AreaLoadingOrOnlyMyCompanies>
            ) : (
              <Tabs
                tabs={[
                  i18next.t('panel.tabs.allCompanies'),
                  i18next.t('panel.tabs.myCompanies'),

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      zIndex: 9999,
                    }}
                  >
                    <span>{i18next.t('panel.tabs.associatedCompanies')}</span>
                    <InformativeAbout
                      description={i18next.t(
                        'panel.info.CompaniesIparticipateInfo'
                      )}
                    />
                  </div>,
                ]}
              >
                <MyCompanies
                  addNew
                  onNewCompany={onNewCompany}
                  companies={companies}
                  currentPage={currentPageTab1}
                  setCurrentPageTab={setCurrentPageTab1}
                  isLoading={isLoading}
                />
                <MyCompanies
                  addNew
                  onNewCompany={onNewCompany}
                  companies={myCompanies}
                  currentPage={currentPageTab2}
                  setCurrentPageTab={setCurrentPageTab2}
                  isLoading={isLoading}
                />
                <MyCompanies
                  onNewCompany={onNewCompany}
                  companies={associatedCompanies}
                  currentPage={currentPageTab3}
                  setCurrentPageTab={setCurrentPageTab3}
                  isLoading={isLoading}
                />
              </Tabs>
            )}
          </>
        )}
      </TabWrapper>
    </Container>
  );
}

ListCompanies.propTypes = {
  onNewCompany: func.isRequired,
};
