/* eslint-disable no-param-reassign */
import React from 'react';
import i18next from 'i18next';

import { number, func, objectOf } from 'prop-types';

import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import TextInput from '~/components/TextInput';
import { SecondarySelect } from '~/components/Select';
import MoveButton from './components/MoveButton';
import PositionIndicator from './components/PositionIndicator';

import {
  Container,
  MoveWrapper,
  PositionWrapper,
  DescriptionWrapper,
  AssetWrapper,
  // DocumentWrapper,
  DeleteIcon,
  TypeWrapper,
} from './styles';

export default function ProcessCard({
  index,
  process,
  updateProcess,
  onRemove,
}) {
  // const { technologiesEnum } = useSelector((state) => state.technologies);
  const { peopleEnum } = useSelector((state) => state.people);
  const { processesEnum } = useSelector((state) => state.processes);

  // const { documentsEnum } = useSelector((state) => state.documents);

  const handleRemove = () => {
    onRemove(index);
  };

  const types = [
    {
      label: i18next.t('businessUnitAsset.tabs.assets.user'),
      value: 'user',
    },
    {
      label: i18next.t('businessUnitAsset.tabs.assets.process'),
      value: 'process',
    },
    // {
    //   label: i18next.t('businessUnitAsset.tabs.assets.asset'),
    //   value: 'technology',
    // },
  ];

  return (
    <Draggable draggableId={String(index)} index={index}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          ref={provided.innerRef}
          {...provided.dragHandleProps}
        >
          <MoveWrapper>
            <MoveButton />
          </MoveWrapper>
          <PositionWrapper>
            <PositionIndicator>{String(index + 1)}</PositionIndicator>
          </PositionWrapper>
          <DescriptionWrapper>
            <TextInput
              name="description"
              onChange={(e) =>
                updateProcess(index, { description: e.target.value })
              }
              value={process.description}
              label={i18next.t(
                'technologiesAsset.tabs.pcn.common.descriptionLabel'
              )}
              placeholder="Ex.: Data center"
            />
          </DescriptionWrapper>
          <TypeWrapper>
            <SecondarySelect
              label={i18next.t('technologiesAsset.tabs.pcn.common.type')}
              options={types}
              name="type"
              onChange={(e) =>
                updateProcess(index, { ...process, assets_type: e })
              }
              value={process.assets_type}
            />
          </TypeWrapper>
          <AssetWrapper>
            <SecondarySelect
              label={i18next.t('technologiesAsset.tabs.pcn.common.assetLabel')}
              placeholder="Ex.: Data center"
              name="asset_id"
              value={process.assets_id}
              onChange={(e) =>
                updateProcess(index, { ...process, assets_id: e })
              }
              options={
                process.assets_type === 'user'
                  ? peopleEnum
                  : process.assets_type === 'process'
                  ? processesEnum
                  : []
              }
            />
          </AssetWrapper>
          {/* <DocumentWrapper>
            <SecondarySelect
              label={i18next.t(
                'technologiesAsset.tabs.pcn.common.documentLabel'
              )}
              placeholder="Ex.: Data center"
              name="document_id"
              value={process.document_id}
              onChange={(e) => updateProcess(index, { document_id: e })}
              options={documentsEnum}
            />
          </DocumentWrapper> */}
          <DeleteIcon onClick={handleRemove} />
        </Container>
      )}
    </Draggable>
  );
}

ProcessCard.propTypes = {
  index: number.isRequired,
  process: objectOf.isRequired,
  updateProcess: func.isRequired,
  moveCardHandler: func.isRequired,
  onRemove: func.isRequired,
};
