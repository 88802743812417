import styled from 'styled-components';

export const Container = styled.div`
  max-width: 17.4rem;
  min-width: 16rem;
  height: 5rem;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.2rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  @media (max-width: 670px) {
    padding: 0 0.2rem;
    min-width: 4rem;
  }
`;

export const DateOfDay = styled.div`
  color: #464955;
  font-weight: 400;
  font-size: 1.2rem;
`;

export const Hour = styled.div`
  color: #464955;
  font-weight: 400;
  font-size: 1.2rem;

  @media (max-width: 670px) {
    display: none;
  }
`;
