import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1120px;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;

  @media (max-width: 500px) {
    padding: 20px;
  }
`;

export const Modal = styled.div`
  height: 485px;
  width: 600px;
  border-radius: 20px;
  padding: 40px;
  border: 1px solid #ebebeb;
  align-items: center;
  display: grid;
  margin-top: 30px;
  background-color: white;

  @media (max-width: 500px) {
    width: 250px;
    height: 400px;
    padding: 15px;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
`;

export const Subtitle = styled.span`
  font-size: 18px;
`;

export const Content = styled.div`
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  display: grid;
  margin-top: 5%;

  @media (max-width: 500px) {
    width: 250px;
  }
`;

export const ButtonsArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  @media (max-width: 500px) {
    display: grid;
    padding-left: 60px;
  }
`;

export const FormArea = styled.div`
  @media (max-width: 500px) {
    width: 180px;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`;
