import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #fafafc;
`;

export const WrapperLeft = styled.div`
  position: fixed;
  background-color: #ffffff;
  width: 25%;
  height: 100vh;
  padding: 0rem 4.3rem;
  display: flex;
  flex-direction: column;
`;

export const WrapperRight = styled.div`
  margin-left: 25%;
  width: 100%;
`;

export const WrapperBack = styled.div`
  display: flex;
  align-items: center;
  height: 7.8rem;
  padding-top: 4.6rem;
  padding-bottom: 1rem;
  padding-left: 5.4rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(#fff 90%, #ffffff00);
  cursor: pointer;
  z-index: 5;
`;

export const BackIcon = styled(IoIosArrowBack).attrs({
  size: '1.4rem',
  color: '#200E32',
})`
  margin-right: 2rem;
`;

export const BackText = styled.div`
  color: ${({ theme }) => theme.colors.base[400]};
  font-size: 1.8rem;
  font-weight: 400;
`;

export const WrapperHeader = styled.div`
  background: linear-gradient(#fafafc 80%, #ffffff00);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 3.8rem 6rem;
  z-index: 100;
  display: flex;
  align-items: center;
  margin-left: 25%;
  justify-content: flex-end;
  flex-wrap: wrap;

  transition: 300ms ease;
`;

export const WrapperChildren = styled(PerfectScrollbar)`
  padding: 0 6rem;
  width: 100%;
  height: calc(100vh);
  padding-top: 14rem;
`;
