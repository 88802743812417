import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';

import { IconProfilePlaceholder } from '~/assets/icons';
import TicketActions from '~/store/ducks/tickets';

import {
  Container,
  Title,
  MessageContainer,
  MessageTextWrapper,
  MessageText,
  UserAvatar,
  FileWrapper,
  File,
  DocumentIcon,
  DownloadIcon,
} from './styles';

export default function InteractionMessages() {
  const dispatch = useDispatch();
  const { ticketDetails } = useSelector((state) => state.tickets);
  return (
    <Container>
      <Title>Interações</Title>
      {ticketDetails?.replies &&
        ticketDetails?.replies.map((reply) => (
          <MessageContainer
            key={reply.id}
            isResponsibleMsg={reply.responsible !== null}
          >
            {reply.responsible !== null ? (
              <span>
                <strong>{reply.responsible.name}</strong> -{' '}
                {format(new Date(reply.updatedAt), "P 'às' HH:mm", {
                  locale: ptBR,
                })}
              </span>
            ) : (
              <span>
                {format(new Date(reply.updatedAt), "P 'às' HH:mm", {
                  locale: ptBR,
                })}
              </span>
            )}
            <MessageTextWrapper>
              <MessageText isResponsibleMsg={reply.responsible !== null}>
                {reply.description}
                {reply?.ticketDocument && (
                  <FileWrapper>
                    <File
                      onClick={() =>
                        dispatch(
                          TicketActions.downloadTicketDocumentRequest(
                            reply?.ticketDocument?.title,
                            reply?.ticketDocument?.id,
                            ticketDetails.id
                          )
                        )
                      }
                    >
                      <DocumentIcon />
                      <span>{reply.ticketDocument.title}</span>
                      <DownloadIcon />
                    </File>
                  </FileWrapper>
                )}
              </MessageText>
              {reply.responsible !== null && (
                <UserAvatar
                  src={
                    reply?.responsible?.avatar
                      ? `${
                          process.env.NODE_ENV === 'development'
                            ? process.env.REACT_APP_API_HOMOLOG_ENDPOINT
                            : process.env.REACT_APP_API_PROD_ENDPOINT
                        }/users/avatar?avatar=${reply.responsible.avatar}`
                      : IconProfilePlaceholder
                  }
                  alt={reply.responsible.name}
                />
              )}
            </MessageTextWrapper>
          </MessageContainer>
        ))}
    </Container>
  );
}
